import React from 'react'

import { FormBuilder, TextBuilder } from 'meta/form'
import { LoginData } from 'meta/pages/login'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import Form from 'components/Forms/Form'
import { useLoginData, useLoginStep } from 'hooks/useLogin'

import { IStepProps, Step } from '../StepCommon'

const Who: React.FC<IStepProps> = () => {
  const dispatch = useAppDispatch()
  const data = useLoginData()
  const step = useLoginStep()

  const firstName = new TextBuilder('firstName')
    .addRequiredNameValidation()
    .addValue(data.firstName)
    .addPlaceholder('form:firstName')
    .build()
  const lastName = new TextBuilder('lastName')
    .addRequiredNameValidation()
    .addValue(data.lastName)
    .addPlaceholder('form:lastName')
    .build()
  const formFields = new FormBuilder().addField(firstName).addField(lastName).build()

  //@ts-ignore
  const onSuccess = (values) => {
    const dataUpdate: LoginData = { ...data, firstName: values.firstName, lastName: values.lastName }
    dispatch(LoginActions.updateData({ data: dataUpdate, step: step + 1 }))
  }

  return (
    <>
      <Step icon="/login/step_email.svg" labelKey="registerV2:who_are_you">
        <Form
          action={undefined}
          formFields={formFields}
          labelSubmit="registerV2:next_step_label"
          onSuccess={onSuccess}
        />
      </Step>
    </>
  )
}

export default Who
