import React from 'react'
import { useTranslation } from 'react-i18next'

import { IEventFAQ } from 'meta/backendControlled/structure'
import { IFaqSection } from 'meta/pages/faq'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Content from 'components/Pages/FAQ/Content'

const EventFAQ: React.FC<IPropsComponent<IEventFAQ>> = (props) => {
  const { title, uuid } = props

  const { t } = useTranslation()

  const sectionsMeta: Record<string, number> = { covid19: 1 }

  const sections: Array<IFaqSection> = Object.entries(sectionsMeta).map(([section, noQuestions]) => ({
    title: String(t(`faq:${section}`)),
    questions: Array.from(Array(noQuestions).keys()).map((idx) => ({
      question: String(t(`faq:${section}_q${idx}`)),
      answer: String(t(`faq:${section}_a${idx}`)),
    })),
  }))

  return (
    <GridContainer id={getComponentId(uuid)}>
      <Grid>
        <GridColumn gridColumnEnd={17}>
          <h2>{title}</h2>
        </GridColumn>
      </Grid>

      <Grid>
        <GridColumn gridColumnEnd={17}>
          <Content data={sections} hideMenu hideTitle fullWidth />
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default EventFAQ
