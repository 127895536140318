import css from './Satispay.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CheckoutStep, ICheckoutDetails, ICheckoutPayment, PaymentGateway } from 'meta/pages/checkout'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { addAuthTokenToRoutePath } from 'components/Checkout/Steps/utils'
import { useUserData } from 'hooks/useAuth'
import { useCheckoutDetails, useCheckoutPayment } from 'hooks/useCheckout'
import { useUrlBase } from 'hooks/useEnv'

const Satispay: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const details = useCheckoutDetails() as ICheckoutDetails
  const { gateway, gatewayData } = useCheckoutPayment() as ICheckoutPayment
  const userData = useUserData()
  const urlBase = useUrlBase()

  const { transactionCode, insideIFrame } = details
  const paymentId = gatewayData?.['paymentId']

  const checkoutProcessPath = Routes.CheckoutProcess.generatePath(undefined, {
    transactionCode: transactionCode as string,
    paymentGateway: gateway as PaymentGateway,
    paymentId,
  })

  const redirectUrl = `${urlBase}${
    insideIFrame && userData ? addAuthTokenToRoutePath(checkoutProcessPath) : checkoutProcessPath
  }`

  // @ts-ignore
  const satispay = SatispayWebButton.configure({
    // @ts-ignore
    paymentId: paymentId,
    completed: () => {
      satispay.close()

      dispatch(CheckoutActions.close())

      if (insideIFrame) {
        setTimeout(() => {
          // @ts-ignore
          window.top.location.href = redirectUrl
        }, 2000)
      } else {
        navigate(checkoutProcessPath)
      }
    },
  })

  useEffect(() => {
    satispay.open()
  }, [])

  return (
    <div className={css.checkout_steps_satispay_container}>
      <div className={css.satispay_error_body}>
        <div className={css.title}>{t('common:satispay_transaction_msg')}</div>

        <div
          className={css.go_back}
          onClick={() => {
            dispatch(CheckoutActions.updateDetails({ details: { ...details, transactionStarted: false } }))
            dispatch(CheckoutActions.updateStep({ step: CheckoutStep.select }))
          }}
        >
          {t('common:satispay_go_back')}
        </div>
      </div>
    </div>
  )
}

export default Satispay
