import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { RouteParams, Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'

import BackendControlled from 'components/BackendControlled'

interface IProps {}

const PromoterPage: React.FC<IProps> = () => {
  const { codeUrl } = useParams<RouteParams['Event']>()

  if (!codeUrl) {
    return <Navigate to={Routes.Home.generatePath()} />
  }

  return (
    <BackendControlled
      // banner={{
      //   imageUrl: '/banner/banner_header_home.png',
      //   imageMobileUrl: '/banner/banner_header_home_mobile.png',
      //   type: BannerType.top,
      //   type: BannerType.top,
      // }}
      bannerTop={{
        imageURLs: {
          desktop: `/banner/banner_2024_header_home_desktop.png`,
          laptop: `/banner/banner_2024_header_home_laptop.png`,
          mobile: `/banner/banner_2024_header_home_mobile.png`,
          tabletLandscape: `/banner/banner_2024_header_home_tablet-landscape.png`,
          tabletPortrait: `/banner/banner_2024_header_home_tablet-portrait.png`,
        },
      }}
      path={ApiEndpoint.promoter.components(String(codeUrl))}
    />
  )
}

export default PromoterPage
