import css from './Timer.module.scss'
import React from 'react'
import Countdown from 'react-countdown'
import { CountdownRendererFn } from 'react-countdown/dist/Countdown'

import { CheckoutStep } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Img from 'components/Media/Img'

interface IProps {
  milliseconds?: number
}

const DEFAULT_COUNTDOWN_MILLISECONDS = 60000 * 25

const Timer: React.FC<IProps> = (props) => {
  const { milliseconds } = props

  const dispatch = useAppDispatch()

  const renderer: CountdownRendererFn = ({ hours, minutes, seconds }) => {
    return (
      <div>
        <span>
          {minutes}:{seconds < 10 ? 0 : null}
          {seconds}
        </span>{' '}
        Min.
      </div>
    )
  }

  const onComplete = () => {
    dispatch(CheckoutActions.updateStep({ step: CheckoutStep.timeoutError }))
  }

  return (
    <div className={css.checkout_recapbox_countdown_container}>
      <div className={css.countDown}>
        <div className={css.icon}>
          <Img alt="" src="/checkout/time-left-red.svg" />
        </div>
        <div className={css.timer}>
          <Countdown onComplete={onComplete} date={Date.now() + (milliseconds as number)} renderer={renderer} />
        </div>
      </div>
    </div>
  )
}

Timer.defaultProps = {
  milliseconds: DEFAULT_COUNTDOWN_MILLISECONDS,
}

export default Timer
