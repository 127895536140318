import css from './BalloonBox.module.scss'
import React, { PropsWithChildren } from 'react'
import { TextBoxInfoTypes } from 'types/layout'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Img from 'components/Media/Img'

interface IProps {
  bgColor?: ComponentColor
  infoType: TextBoxInfoTypes
  title?: string
}

const BalloonBox: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { children, bgColor, infoType, title } = props

  return (
    <BalloonWrapper color={bgColor as ComponentColor} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
      <div className={css.textboxes_balloonbox}>
        <div className={css.infoImg}>
          {infoType === 'info' && <Img src="/textboxes/textboxes_info.svg" />}
          {infoType === 'warning' && <Img src="/textboxes/textboxes_warning.svg" />}
          {infoType === 'error' && <Img src="/textboxes/textboxes_error.svg" />}
          {infoType === 'success' && <Img src="/textboxes/textboxes_success.svg" />}
        </div>

        {title && <div className={css.title}>{title}</div>}

        <div className={css.content}>{children}</div>
      </div>
    </BalloonWrapper>
  )
}

BalloonBox.defaultProps = {
  bgColor: ComponentColor.white,
}

export default BalloonBox
