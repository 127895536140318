import css from './Description.module.scss'
import React from 'react'

import { ComponentType, IVenueInfo } from 'meta/backendControlled/structure'

import Captions, { AlignCaption } from 'components/BackendControlled/Component/common/Captions'
import DescriptionReadMore from 'components/BackendControlled/Component/common/DescriptionReadMore'
import { IPropsComponent } from 'components/BackendControlled/Component/index'

const VenueInfoDescription: React.FC<IPropsComponent<IVenueInfo>> = (props) => {
  const { name, description, attributes } = props

  return (
    <div className={css.event_body_location_description_container}>
      <h3 className={css.name}>{name}</h3>
      <DescriptionReadMore component={ComponentType.venueInfo} description={description} />

      <div className={css.captions_wrapper}>
        {attributes && <Captions data={attributes} itemsPerRow={4} align={AlignCaption.left} />}
      </div>
    </div>
  )
}

export default VenueInfoDescription
