import css from 'components/common/Support/Support.module.scss'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportSkeleton } from 'components/common/Support/SupportSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'

const Support: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { t } = useTranslation()

  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={css.supportWrapper}>
      <div className={css.support}>
        <WithAppInitialized skeleton={<SupportSkeleton />} showChildren={namespacesLoaded}>
          <div className={css.header}>
            <Img src="/support/header_help_2.svg" />
            {t('support:header')}
          </div>
        </WithAppInitialized>

        {React.Children.toArray(children)}
      </div>
    </div>
  )
}

export default Support
