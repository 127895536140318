import { AxiosError } from 'axios'

export class ResponseError extends Error {
  private readonly _axiosError: AxiosError
  private readonly _status: number

  constructor(message: string, axiosError: AxiosError, status?: number | undefined) {
    super(message)
    this._axiosError = axiosError
    this._status = status ?? -1
  }

  get axiosError(): AxiosError {
    return this._axiosError
  }

  get status(): number {
    return this._status
  }
}
