import css from './CCList.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { ICheckoutCC } from 'meta/pages/checkout'
import { ICardItem } from 'meta/pages/profile'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import CCAddNew from 'components/Checkout/PaymentMethods/CCRecap/CCAddNew'
import CCRecapItem from 'components/Checkout/PaymentMethods/CCRecap/CCRecapItem'
import Img from 'components/Media/Img'

interface IProps {
  cardList: ICardItem[]

  goBackCallback(): void

  setCCDataCallback(data: ICheckoutCC): void

  setShowCardList(value: boolean): void
}

const CCList: React.FC<IProps> = (props) => {
  const { goBackCallback, setCCDataCallback, setShowCardList, cardList } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <div className={css.cardsListContainer}>
      <div className={css.header_wrapper}>
        <div className={css.goBackWrapper} onClick={goBackCallback}>
          <div className={css.goBack}>
            <Img alt="" src="/arrows/white-left.svg" />
          </div>
          <h3 data-testid={DataTestId.checkout.selectCreditCard}>{t('checkout:stripe_select_card')}</h3>
        </div>
      </div>

      {cardList.map((card, key) => {
        return (
          <div key={key} className={css.cardItemWrapper}>
            <CCRecapItem
              selectCallback={() => {
                dispatch(CheckoutActions.updateStripe({ stripe: { paymentMethod: card.id, paymentIntent: undefined } }))

                setCCDataCallback({
                  description: `${card.brand.toUpperCase()} ****${card.lastDigits}`,
                  expiry: card.expiry,
                  brand: card.brand,
                })
              }}
              data={{
                description: `${card.brand.toUpperCase()} / ****${card.lastDigits}`,
                expiry: card.expiry,
                brand: card.brand,
              }}
            />
          </div>
        )
      })}

      <div className={css.separator}></div>

      <CCAddNew onClick={() => setShowCardList(false)} />
    </div>
  )
}

export default CCList
