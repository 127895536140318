import css from './PaymentMethods.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGet } from 'api'

import { Breakpoints } from 'meta/layout/breakpoints'
import {
  CardGateway,
  CouponCodeStatus,
  ICheckoutCC,
  ICheckoutDetails,
  ICheckoutPayment,
  PaymentBox,
  PaymentMethod,
} from 'meta/pages/checkout'
import { CreditCardsAdapter, ICardItem } from 'meta/pages/profile'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import {
  ApplePay,
  CartaMeritoCultura,
  CreditCard,
  DiciottoApp,
  IPropsPayment,
  PayPal,
  SatisPay,
  ScalaPay,
  Voucher,
} from 'components/Checkout/PaymentMethods/Boxes'
import CCRecap from 'components/Checkout/PaymentMethods/CCRecap'
import Installments from 'components/Checkout/PaymentMethods/Installments'
import StripeBox from 'components/Checkout/PaymentMethods/StripeBox'
import VoucherBox from 'components/Checkout/PaymentMethods/VoucherBox'
import VoucherRecap from 'components/Checkout/PaymentMethods/VoucherRecap'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useAuthToken } from 'hooks/useAuth'
import {
  useCheckoutCartTickets,
  useCheckoutDetails,
  useCheckoutPayment,
  useCheckoutRecapExtra,
  useCheckoutStripe,
} from 'hooks/useCheckout'
import { useOnUpdate } from 'hooks/useOnUpdate'

const paymentComponents: Record<string, React.FC<IPropsPayment>> = {
  [PaymentMethod.creditCard]: CreditCard,
  [PaymentMethod.scalaPay]: ScalaPay,
  [PaymentMethod.diciottoApp]: DiciottoApp,
  [PaymentMethod.cartaMeritoCultura]: CartaMeritoCultura,
  [PaymentMethod.voucher]: Voucher,
  [PaymentMethod.payPal]: PayPal,
  [PaymentMethod.applePay]: ApplePay,
  [PaymentMethod.satisPay]: SatisPay,
}

const PaymentMethods: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const payment = useCheckoutPayment() as ICheckoutPayment
  const stripe = useCheckoutStripe()
  const extra = useCheckoutRecapExtra()
  const cartTickets = useCheckoutCartTickets()
  const { eventTicket } = useCheckoutDetails() as ICheckoutDetails

  const authToken = useAuthToken()
  const userLoggedIn = Boolean(authToken)
  const { fetch, response } = useGet()

  const [cardList, setCardList] = useState<Array<ICardItem>>()

  const { couponCode, methodCurrent, methodsAvailable, cardGateway } = payment
  const [activeBox, setActiveBox] = useState<string>(PaymentBox.methodsList)
  const [ccData, setCCData] = useState<ICheckoutCC>()
  const [showInstallments, setShowInstallments] = useState<boolean>(false)
  const [hideBox, setHideBox] = useState<boolean>(false)
  const firstShowInstallments = useRef<boolean>(true)

  const onlyFreeTicketsInCart = (): boolean => {
    let onlyFreeTickets = true

    cartTickets?.forEach((ticket) => {
      const price = eventTicket.data.find((x) => x.id === ticket.typeTicketId)?.priceNumber ?? 0

      if (price > 0) {
        onlyFreeTickets = false
      }
    })

    return onlyFreeTickets
  }

  useEffect(() => {
    CheckoutActions.updateStripe({ stripe: { paymentMethod: undefined, paymentIntent: undefined } })
  }, [])

  useEffect(() => {
    if (userLoggedIn) {
      fetch({ authToken: authToken as string, urlResource: ApiEndpoint.users.cards() })
    }
  }, [])
  useOnUpdate(() => {
    if (response) {
      setCardList(CreditCardsAdapter.convertResponse(response.data.cardList))
    }
  }, [response])

  useEffect(() => {
    const isCartEmpty = cartTickets && cartTickets.length > 0
    const isEventFree = !eventTicket.data.find((x) => x.priceNumber > 0)

    const freeTicketsOnly = isEventFree || (isCartEmpty && onlyFreeTicketsInCart()) ? true : false

    if (freeTicketsOnly) {
      dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.list } }))
    } else {
      if (methodCurrent === PaymentMethod.list) {
        dispatch(
          CheckoutActions.updatePayment({
            payment: { ...payment, methodCurrent: ccData ? PaymentMethod.creditCard : PaymentMethod.none },
          })
        )
      }
    }

    setHideBox(freeTicketsOnly)
  }, [cartTickets?.length])

  useOnUpdate(() => {
    setShowInstallments(Boolean(extra && extra.installments))
  }, [extra])

  useOnUpdate(() => {
    if (showInstallments) firstShowInstallments.current = false
  }, [showInstallments])

  if (hideBox) {
    return null
  }

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_paymentmethods_container}>
            <h2 className={css.title}>{t('checkout:select_payment_method')}</h2>

            {activeBox === PaymentBox.methodsList && !showInstallments && (
              <div className={css.boxes_container}>
                {methodsAvailable.map((paymentMethod: PaymentMethod, idx: number) => {
                  if (!paymentComponents[paymentMethod]) {
                    return null
                  }

                  return React.createElement(paymentComponents[paymentMethod], {
                    key: String(idx),
                    toggleVoucherActive: () => {
                      setActiveBox(PaymentBox.voucher)
                    },
                    toggleCreditCardActive: () => {
                      if (cardGateway === CardGateway.virtualPay) {
                        return
                      }

                      stripe?.paymentMethod && ccData
                        ? setActiveBox(PaymentBox.ccRecap)
                        : setActiveBox(PaymentBox.creditCard)
                    },
                  })
                })}
              </div>
            )}

            {activeBox === PaymentBox.ccRecap && !showInstallments && (
              <CCRecap
                data={ccData}
                editCallback={() => {
                  dispatch(
                    CheckoutActions.updateStripe({ stripe: { paymentMethod: undefined, paymentIntent: undefined } })
                  )
                  setActiveBox(PaymentBox.creditCard)
                }}
                goBackCallback={() => {
                  setActiveBox(PaymentBox.methodsList)
                }}
                resetCCDataCallback={() => {
                  setCCData(undefined)
                }}
              />
            )}

            {activeBox === PaymentBox.creditCard && cardGateway === CardGateway.stripe && !showInstallments && (
              <div className={css.credit_card_container}>
                <StripeBox
                  cardList={cardList as ICardItem[]}
                  currentCCData={ccData as ICheckoutCC}
                  setCCDataCallback={(data: ICheckoutCC) => {
                    setCCData(data)
                    setActiveBox(PaymentBox.ccRecap)
                  }}
                  resetCCDataCallback={() => {
                    setCCData(undefined)
                  }}
                  goBackCallback={() => {
                    setActiveBox(PaymentBox.methodsList)
                  }}
                />
              </div>
            )}

            {activeBox === PaymentBox.voucher && (
              <div className={css.voucher_container}>
                <VoucherBox
                  goBackCallback={() => {
                    setActiveBox(PaymentBox.methodsList)
                  }}
                  confirmCallback={(couponCode: string) => {
                    const paymentUpdated = { ...payment, couponCode: couponCode }
                    dispatch(CheckoutActions.updatePayment({ payment: paymentUpdated }))
                    setActiveBox(PaymentBox.voucherRecap)
                  }}
                />
              </div>
            )}

            {activeBox === PaymentBox.voucherRecap && (
              <VoucherRecap
                editCallback={() => {
                  const paymentUpdated = {
                    ...payment,
                    couponCode: couponCode,
                    couponCodeStatus: CouponCodeStatus.unchecked,
                  }
                  dispatch(CheckoutActions.updatePayment({ payment: paymentUpdated }))
                  setActiveBox(PaymentBox.voucher)
                }}
                goBackCallback={() => {
                  setActiveBox(PaymentBox.methodsList)
                }}
              />
            )}

            {showInstallments && (
              <Installments firstShow={firstShowInstallments.current} onClose={() => setShowInstallments(false)} />
            )}
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default PaymentMethods
