import css from './NewHolderData.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PersonalDataField } from 'types/tickets'

import {
  FormBuilder,
  FormDataType,
  FormFieldType,
  HiddenBuilder,
  IFormField,
  InputDateBuilder,
  PhoneBuilder,
  SelectBuilder,
  TextBuilder,
} from 'meta/form'
import { ITicketExtraField } from 'meta/pages/tickets'
import { ApiEndpoint } from 'controller/endpoint'
import { getBirthDate, getBirthplaceCity, getBirthplaceCountry, getGender } from 'helpers/form/fields/user'
import { DATALIST_CITY_DEFAULT_VALUE } from 'utils/constants/form'

import Form, { FormButtonSubmit, FormField, IFormValues } from 'components/Forms/Form'
import { linkCountriesAndCities } from 'components/Forms/Form/FormField/LinkedDataList/utils'
import { useBlogApiBase } from 'hooks/useEnv'
import { useTicketChangeNameData } from 'hooks/useProfile'

import { IPropsChangeTicketNameStep } from '../types'

const NewHolderData: React.FC<IPropsChangeTicketNameStep> = (props) => {
  const { authToken, onSuccess, additionalInfos } = props

  const { t } = useTranslation()

  //@ts-ignore
  const [country, setCountry] = useState<string>(null)

  const { data } = useTicketChangeNameData()

  const blogBase = useBlogApiBase()

  //@ts-ignore
  const fiscalSeal = new HiddenBuilder('fiscalSeal').addRequiredValidation().addValue(data.fiscalSeal).build()
  const firstName = new TextBuilder('firstName').addRequiredNameValidation().addPlaceholder('form:firstName').build()
  const lastName = new TextBuilder('lastName').addRequiredNameValidation().addPlaceholder('form:lastName').build()
  const phone = new PhoneBuilder('phone', 'phonePrefix').addRequiredValidation().addPlaceholder('form:phone').build()
  const formFields = new FormBuilder()
    .addField(fiscalSeal)
    .addField(firstName)
    .addField(lastName)
    .addField(phone)
    .build()

  //@ts-ignore
  if (data.isPersonalDataRequired) {
    const birthDate = getBirthDate(PersonalDataField.dateOfBirth)
    const gender = getGender(PersonalDataField.gender)
    const birthplaceCountry = getBirthplaceCountry(PersonalDataField.birthplaceCountry)
    const birthplaceCity = getBirthplaceCity(PersonalDataField.birthplaceCity, DATALIST_CITY_DEFAULT_VALUE)

    formFields.push(birthDate)
    formFields.push(gender)
    formFields.push(birthplaceCountry)
    formFields.push(birthplaceCity)
  }

  //@ts-ignore
  const convertExtraField = (info): ITicketExtraField => {
    const extraField: ITicketExtraField = {
      fieldType: info.fieldType === 0 ? FormFieldType.text : FormFieldType.select,
      dataType: info.dataType,
      label: info.fieldName,
      name: info.fieldName,
      placeholder: info.fieldDescription,
      id: info.id,
      required: true,
    }
    if (extraField.fieldType === FormFieldType.select) {
      const fieldValues = Array.isArray(info.fieldValues) ? info.fieldValues : JSON.parse(info.fieldValues)
      //@ts-ignore
      extraField.options = fieldValues.map((value) => ({ value, label: value }))
    }
    return extraField
  }

  //@ts-ignore
  additionalInfos.map((rawField: ITicketExtraField) => {
    const extraField = convertExtraField(rawField)

    const fieldName = `extra_${extraField.id}_${extraField.name}`
    let formField: IFormField<FormFieldType>

    if (extraField.fieldType === FormFieldType.text) {
      if (extraField.dataType === FormDataType.fiscalCode) {
        formField = new TextBuilder(fieldName)
          .addRequiredFiscalCodeValidation('tickets:errors_form_required_extra_field')
          .addLabel(extraField.name)
          .addPlaceholder('form:fiscalCode')
          .build()
      } else if (extraField.dataType === FormDataType.birthDate) {
        formField = new InputDateBuilder(fieldName)
          .addRequiredBeforeNowValidation('tickets:errors_form_required_extra_field')
          .addLabel(extraField.name)
          //@ts-ignore
          .addPlaceholder(extraField.placeholder)
          .build()
      } else if (extraField.dataType === FormDataType.date) {
        formField = new InputDateBuilder(fieldName)
          .addRequiredDateValidation('tickets:errors_form_required_extra_field')
          .addLabel(extraField.name)
          //@ts-ignore
          .addPlaceholder(extraField.placeholder)
          .build()
      } else {
        formField = new TextBuilder(fieldName)
          .addRequiredValidation('tickets:errors_form_required_extra_field')
          .addLabel(extraField.name)
          //@ts-ignore
          .addPlaceholder(extraField.placeholder)
          .build()
      }
    }

    if (extraField.fieldType === FormFieldType.select) {
      formField = new SelectBuilder(fieldName, extraField.options)
        .addLabel(extraField.name)
        .addRequiredValidation('tickets:errors_form_required_extra_field')
        .build()
    }

    //@ts-ignore
    formFields.push(formField)

    //@ts-ignore
    if (!formField) throw new Error(`Extra field of type ${extraField.fieldType} is not supported`)
  })

  //@ts-ignore
  const getAdditionalInfos = (values) => {
    const filtered = Object.keys(values)
      .filter((x) => x.includes('extra_'))
      .reduce((obj, key) => {
        //@ts-ignore
        obj[key] = values[key]
        return obj
      }, {})

    return Object.keys(filtered).map((x) => {
      return {
        id: x.split('_')[1],
        //@ts-ignore
        value: filtered[x],
      }
    })
  }

  const bodyParser = (values: IFormValues) => {
    const additionalInfos = getAdditionalInfos(values)

    let body = {
      fiscalSeal: values.fiscalSeal,
      buyerData: {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        phonePrefix: values.phonePrefix,
      },
      additionalInfos: [...additionalInfos],
    }

    //@ts-ignore
    if (data.isPersonalDataRequired) {
      //@ts-ignore
      body['personalData'] = {
        gender: values.gender,
        dateOfBirth: values.dateOfBirth,
        birthplaceCountry: values.birthplaceCountry,
      }

      if (values.birthplaceCity !== DATALIST_CITY_DEFAULT_VALUE) {
        //@ts-ignore
        body['personalData']['birthplaceCity'] = values.birthplaceCity
      }
    }

    return body
  }

  return (
    <>
      <div data-testid={'profile-change-name-newticketdata'}>{t('profile:change_ticket_data_verified')}</div>
      <div>{t('profile:change_ticket_insert_new_name')}</div>
      {/*@ts-ignore */}
      {!data.isPersonalDataRequired && <div>{t('profile:change_ticket_once_completed')}</div>}
      {/*@ts-ignore */}
      {data.isPersonalDataRequired && <div>{t('profile:change_ticket_once_completed_personal_data')}</div>}

      <a className={css.guideLink} href={`${blogBase}/changename/`}>
        {t('profile:change_name_guide_link')}
      </a>

      <Form
        action={ApiEndpoint.users.changeNameConfirm()}
        bodyParser={bodyParser}
        authToken={authToken}
        formFields={formFields}
        labelSubmit="register:submit_label"
        //@ts-ignore
        onSuccess={() => onSuccess(data)}
        renderFields={false}
      >
        {formFields.map((field) => {
          if (field.type == FormFieldType.linked) {
            field = linkCountriesAndCities(field, setCountry, country)
          }

          return <FormField key={field.name} formField={field} />
        })}

        <FormButtonSubmit labelSubmit={'register:submit_label'} />
      </Form>
    </>
  )
}

export default NewHolderData
