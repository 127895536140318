import css from './Cover.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

export const CoverSkeleton: React.FC = () => {
  return (
    <div className={css.checkout_eventdetails_cover_container}>
      <Skeleton
        className={css.image}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
      />
    </div>
  )
}
