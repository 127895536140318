import css from './InitError.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { useCheckoutDetails } from 'hooks/useCheckout'

const InitError: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { transactionErrorMessage } = useCheckoutDetails() as ICheckoutDetails

  return (
    <div className={css.checkout_steps_initerror_container}>
      <div className={css.initerror_error_body}>
        <div className={css.title}>{t('common:init_error_msg')}</div>

        <div>{transactionErrorMessage}</div>

        <div className={css.go_back} onClick={() => dispatch(CheckoutActions.close())}>
          {t('common:notickets_close_checkout')}
        </div>
      </div>
    </div>
  )
}

export default InitError
