import css from './FAQ.module.scss'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IFaqSection } from 'meta/pages/faq'

import Content from 'components/Pages/FAQ/Content'
import { useOnUpdate } from 'hooks/useOnUpdate'

type Props = {
  setShowForm: Dispatch<SetStateAction<boolean>>
  topicId?: number
}

const sectionsMeta: Record<number, Record<string, number>> = {
  5: { purchase: 3 },
  6: { sms: 1 },
  7: { refund: 1 },
}

const FAQ: React.FC<Props> = (props) => {
  const { topicId, setShowForm } = props

  const { t } = useTranslation()

  const getSections = useCallback(() => {
    if (!topicId) return []

    const sectionMeta = sectionsMeta[topicId]
    return Object.entries(sectionMeta).map(([section, noQuestions]) => ({
      title: String(t(`support-form:${section}`)),
      questions: [
        ...Array.from(Array(noQuestions).keys()).map((idx) => ({
          question: String(t(`support-form:${section}_q${idx}`)),
          answer: String(t(`support-form:${section}_a${idx}`)),
        })),
        {
          question: String(t(`support-form:faq_not_found`)),
          answer: String(t(`support-form:fillTheForm`)),
        },
      ],
    }))
  }, [t, topicId])

  const [sections, setSections] = useState<Array<IFaqSection>>(getSections)

  useOnUpdate(() => {
    setSections(getSections())
  }, [topicId])

  if (!topicId) return null

  return (
    <div className={css.faq_container}>
      <Content
        data={sections}
        hideMenu
        hideTitle
        noMargin
        onChange={(item) => {
          if (item.openSection !== undefined) {
            setShowForm(item.openItem === sections[item.openSection]?.questions?.length - 1)
          }
        }}
      />
    </div>
  )
}

export default FAQ
