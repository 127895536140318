import { createSlice } from '@reduxjs/toolkit'

import i18n from 'i18n'
import { LanguageCode } from 'meta/app/translate'
import { fetchData } from 'store/pages/collection/actions/fetchData'
import { initialState } from 'store/pages/collection/state'

import { getCollectionKey } from '../_utils'

export const CollectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch
    builder.addCase(fetchData.fulfilled, (state, action) => {
      const { id, details } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getCollectionKey({ id, language })

      state[path] = { ...state[path], details }
    })
  },
})
