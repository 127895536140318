import cssProfile from 'components/Auth/Profile/Profile.module.scss'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { ActionColor, Button } from 'components/Actions/Action'
import Img from 'components/Media/Img'

const ResaleText: React.FC = () => {
  const { t } = useTranslation()

  const [showAll, setShowAll] = useState<boolean>()

  const PartOne = useMemo(() => {
    const Component: React.FC = () => (
      <>
        <div>{t('profile:resale_resell_here')}</div>
        <div>{t('profile:resale_enter_data')}</div>
      </>
    )
    return Component
  }, [t])

  const PartTwo = useMemo(() => {
    const Component: React.FC = () => (
      <>
        <div>{t('profile:resale_once_verified')}</div>
        <div>{t('profile:resale_if_successful')}</div>
      </>
    )
    return Component
  }, [t])

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile} className={cssProfile.profileWrapper__section}>
        <PartOne />
        {showAll && <PartTwo />}

        <Button
          backgroundColor={ActionColor.white}
          borderColor={ActionColor.transparent}
          hover={ActionColor.white}
          onClick={() => setShowAll((prev) => !prev)}
        >
          <div>
            <strong style={{ marginRight: '8px' }}>{showAll ? t('profile:show_less') : t('profile:show_all')}</strong>
          </div>
          <Img alt="" src={showAll ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
        </Button>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile} className={cssProfile.profileWrapper__section}>
        <PartOne />
        {showAll && <PartTwo />}
      </MediaQuery>
    </>
  )
}

export default ResaleText
