import React from 'react'

import MetaOGImages from 'components/BackendControlled/Component/common/MetaOGImages'
import Head from 'components/common/Head/Head'

type Props = {
  description: string
  name: string
  title: string
}

const VenueHead: React.FC<Props> = (props) => {
  const { description, name, title } = props

  return (
    <>
      <Head>
        <title>{title}</title>

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
      </Head>

      <MetaOGImages path={`/venue/${name}`} title={title} />
    </>
  )
}

export default VenueHead
