import React from 'react'

import withTranslation from 'components/HOC/WithTranslation'
import useInitCssVarAssets from 'components/Pages/hooks/useInitCssVarAssets'
import { useRouter } from 'components/Pages/hooks/useRouter'

const namespaces = [
  'checkout',
  'common',
  'dates',
  'error',
  'footer',
  'form',
  'header',
  'login',
  'loginV2',
  'otp',
  'otpV2',
  'register',
  'registerV2',
  'search',
]

const Pages: React.FC = () => {
  useInitCssVarAssets()
  const { Router } = useRouter()

  return Router
}

export default withTranslation(namespaces)(Pages)
