import css from './CCRecap.module.scss'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { ICheckoutCC } from 'meta/pages/checkout'

import CCRecapItem from 'components/Checkout/PaymentMethods/CCRecap/CCRecapItem'
import Img from 'components/Media/Img'

interface IProps {
  data?: ICheckoutCC
  editCallback(): void
  goBackCallback(): void
  resetCCDataCallback(): void
}

const CCRecap: React.FC<IProps> = (props) => {
  const { data, goBackCallback, editCallback, resetCCDataCallback } = props

  const { t } = useTranslation()

  const onBack = useCallback(() => {
    resetCCDataCallback()
    goBackCallback()
  }, [goBackCallback, resetCCDataCallback])

  return (
    <div className={css.ccrecap_container}>
      <div className={css.header_wrapper}>
        <div className={css.goBackWrapper} onClick={onBack}>
          <div className={css.goBack}>
            <Img src="/arrows/white-left.svg" />
          </div>
          <h3>{t('checkout:cc_recap_header')}</h3>
        </div>
        <div className={css.editWrapper} onClick={editCallback}>
          <div className={css.text} data-testid={DataTestId.checkout.editLabel}>
            {t('checkout:cc_edit')}
          </div>
          <Img src="/icons/edit_pencil_icon.svg" />
        </div>
      </div>
      <CCRecapItem data={data} />
      {/* <CCAddNew t={t} onClick={() => {}}/> */}
    </div>
  )
}

export default CCRecap
