import { createAsyncThunk } from '@reduxjs/toolkit'

import { IMapSection, IMapSectors } from 'meta/map'
import { CollectionController } from 'controller/collection'

type Returned = {
  id: string
  seatsBusy: Array<string>
  sectionsEnabled: Array<IMapSection>
  linkedSeats: Record<string, Array<string>>
  sectors?: IMapSectors
}

type Params = { codeUrl: string; typeTicketCollectionId: string | number; id: string }

export const initSeats = createAsyncThunk<Returned, Params>(
  'pages/event/initSeats',
  async ({ codeUrl, typeTicketCollectionId, id }) => {
    const [seatsBusy, { linkedSeats, sectors, sectionsEnabled }] = await Promise.all([
      CollectionController.getBusySeats({ codeUrl, typeTicketCollectionId }),
      CollectionController.getSectionsEnabled({ codeUrl, typeTicketCollectionId }),
    ])

    return { id, seatsBusy, sectionsEnabled, linkedSeats, sectors }
  }
)
