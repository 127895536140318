import css from 'components/Pages/PurchaseForm/Tickets/Ticket/TicketFormFields/TicketFormFields.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { cssForm } from 'components/Forms/Form'

const TicketFormFields: React.FC = () => {
  return (
    <div className={classNames(cssForm.form, css.ticketFormFields)}>
      <Skeleton
        className={css.fieldsSkeleton}
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_WHITE}
        inline
      />
      <Skeleton
        className={css.fieldsSkeleton}
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_WHITE}
        inline
      />
      <Skeleton
        className={css.fieldsSkeleton}
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_WHITE}
        inline
      />
      <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
        <Skeleton
          className={css.fieldsSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          highlightColor={TICKETSMS_COLORS_WHITE}
          inline
        />
      </MediaQuery>
    </div>
  )
}

export default TicketFormFields
