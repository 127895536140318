import { createAsyncThunk } from '@reduxjs/toolkit'

import { ProfileController } from 'controller/profile/controller'

import { FetchTransactionsParams, TransactionsState } from '../slice/types'

export const fetchTransactions = createAsyncThunk<TransactionsState, FetchTransactionsParams>(
  'profile/transactions/fetch',
  async (params) => {
    const transactionsData = await ProfileController.fetchTransactions(params)

    return transactionsData
  }
)
