import css from './CurrencySelector.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencyCode, CurrencySymbol } from 'meta/currency'
import { Breakpoints } from 'meta/layout/breakpoints'
import { useAppDispatch } from 'store'
import { CurrencyActions } from 'store/currency/actions'
import { deleteCurrencyCookie, setCurrencyCookie } from 'utils/cookie/client'

import { GridColumn } from 'components/Layout/Grid'
import { useActiveCurrency } from 'hooks/useActiveCurrency'

const CurrencySelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const { activeCurrency } = useActiveCurrency()
  const { t } = useTranslation()

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 11, [Breakpoints.laptop]: 11 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 8, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
      className={css.currencySelector}
    >
      <select
        value={activeCurrency}
        onChange={(e) => {
          const code = e.target.value as CurrencyCode

          if (code == CurrencyCode.NONE) {
            deleteCurrencyCookie()
          } else {
            setCurrencyCookie(code)
          }

          dispatch(CurrencyActions.updateCurrency({ code }))
        }}
      >
        <option value={CurrencyCode.NONE}>{`${t('common:change_currency')}`}</option>

        {Object.keys(CurrencySymbol)
          .filter((x) => x !== CurrencyCode.NONE)
          .map((elem, key) => (
            <option key={key} value={elem}>{`${CurrencySymbol[elem as CurrencyCode]} - ${elem}`}</option>
          ))}
      </select>
    </GridColumn>
  )
}

export default CurrencySelector
