import css from './ModalSelector.module.scss'
import React from 'react'

// import { useLocation } from 'react-router-dom'
import { LanguageCode, Languages } from 'meta/app/translate'
import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, ActionFormat, ButtonWhite } from 'components/Actions/Action'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'
import Img from 'components/Media/Img'
import { useChangeLanguage } from 'hooks/useI18n'

interface IPropsModalSelector {
  onClose(): void
}

const ModalSelector: React.FC<IPropsModalSelector> = (props) => {
  const { onClose } = props

  // const pathName = useLocation().pathname

  const changeLanguage = useChangeLanguage()

  return (
    <Modal handleClose={onClose} hideCloseIcon transparent show>
      <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
        <div className={css.languageSelectorModal}>
          {Object.entries(Languages).map(([code, { label }]) => {
            return (
              <ButtonWhite
                key={code}
                className={css.languageSelectorModal__button}
                onClick={async () => {
                  // window.location.href = `/${code}${pathName}`
                  await changeLanguage(code as LanguageCode)
                  onClose()
                }}
                format={ActionFormat.fullWidth}
                hover={ActionColor.white}
              >
                <Img src={`/flags/${code}.png`} />
                <div>{label}</div>
              </ButtonWhite>
            )
          })}
        </div>
      </BalloonWrapper>
    </Modal>
  )
}

export default ModalSelector
