import css from './Select.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ICheckoutDetails } from 'meta/pages/checkout'

import { EventDetailsSkeleton } from 'components/Checkout/EventDetails'
import { PaymentMethodsSkeleton } from 'components/Checkout/PaymentMethods'
import SeasonSkeleton from 'components/Checkout/Season/SeasonSkeleton'
import { TicketSkeleton } from 'components/Checkout/TicketSelection/Automatic/Ticket'
import { TitleBarSkeleton } from 'components/Checkout/TitleBar'
import { useCheckoutDetails, useCheckoutTypeTicketCollectionId } from 'hooks/useCheckout'

export const SelectSkeleton: React.FC = () => {
  const { insideIFrame } = useCheckoutDetails() as ICheckoutDetails

  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()

  return (
    <div className={classNames(css.checkout_steps_select_container)}>
      {!insideIFrame && <EventDetailsSkeleton />}

      {typeTicketCollectionId && <SeasonSkeleton />}

      <div className={css.select_body}>
        <TitleBarSkeleton />

        {[0, 1, 2].map((idx) => (
          <TicketSkeleton key={idx} />
        ))}

        <PaymentMethodsSkeleton />
      </div>
    </div>
  )
}
