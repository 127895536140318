import { useEffect } from 'react'

import { CurrencyCode } from 'meta/currency'
import { useAppDispatch } from 'store'
import { CurrencyActions } from 'store/currency'
import { CookieClient, CookieName } from 'utils/cookie'

export const useInitFromCookies = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const activeCurrency = CookieClient.get(CookieName.CURRENCY)

    if (activeCurrency) {
      dispatch(CurrencyActions.updateCurrency({ code: activeCurrency as CurrencyCode }))
    }
  }, [dispatch])
}
