import css from './Mobile.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IEventCollectionMenu } from 'meta/backendControlled/structure'
import { IEventCollectionByDay } from 'meta/backendControlled/structure/eventCollectionMenu'
import { mapCollectionByDay } from 'meta/backendControlled/structureBackend/adapter/eventCollectionMenu'
import { Dates } from 'utils/dates'

import CalendarButton from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/CalendarButton'
import DateButton from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/DateButton'
import Img from 'components/Media/Img'

interface IProps {
  onDateChange(codeUrl: string): void
  data: IEventCollectionMenu
  removeContainer?: boolean
  insideCheckout?: boolean
}

const Mobile: React.FC<IProps> = (props) => {
  const { onDateChange, data, removeContainer, insideCheckout } = props
  const { current, list, rawList, fullpass } = data

  const { t } = useTranslation()

  const [openButton, setOpenButton] = useState<string | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const marginWidth = 8

  const getButtonKey = (dateStr: string) => {
    let buttonKey = 0

    Object.keys(listByDay).map((dateString, key) => {
      if (dateString === dateStr) {
        buttonKey = key
      }
    })

    return buttonKey
  }

  const handleOpenButton = (openDateString: string) => {
    setOpenButton(openDateString)

    if (openDateString) {
      const elementWidth = getElementWidth()
      const spacerWidth = getSpacerWidth()

      containerRef?.current?.scrollTo(spacerWidth + elementWidth * getButtonKey(openDateString), 0)
    }
  }

  const getSpacerWidth = () => {
    return (containerRef?.current?.children[0] as HTMLElement).offsetWidth - 32
  }

  const getElementWidth = () => {
    return (containerRef?.current?.children[1] as HTMLElement).offsetWidth + marginWidth
  }

  // const getCurrentIndex = () => {
  //   let currentIndex = 0
  //
  //   Object.keys(listByDay).map((dateString, key) => {
  //     if (dateString === Dates.format(new Date(current.datetime), t('dates:common:yearMonthDay'))) {
  //       currentIndex = key
  //     }
  //   })
  //
  //   return currentIndex
  // }

  const goToPrev = () => {
    const elementWidth = getElementWidth()
    const currentPos = containerRef?.current?.scrollLeft
    if (currentPos) {
      containerRef?.current?.scrollTo(currentPos - elementWidth, 0)
    }
  }

  const goToNext = () => {
    const elementWidth = getElementWidth()
    const currentPos = containerRef?.current?.scrollLeft
    if (currentPos) {
      containerRef?.current?.scrollTo(currentPos + elementWidth, 0)
    }
  }

  useEffect(() => {
    if (containerRef && containerRef.current && containerRef.current.children[1]) {
      const elementWidth = getElementWidth()
      const spacerWidth = getSpacerWidth()
      containerRef.current.scrollTo(
        spacerWidth +
          elementWidth * getButtonKey(Dates.format(new Date(current.datetime), t('dates:common:yearMonthDay'))),
        0
      )
    }
  }, [])

  const getFullPass = () => {
    if (!fullpass) {
      return null
    }

    const dateString = Dates.format(new Date(fullpass.datetime), t('dates:common:yearMonthDay'))
    const sliceObj: IEventCollectionByDay = {
      [dateString]: [fullpass],
    }

    return (
      <DateButton
        dateString={dateString}
        isOpen={false}
        setOpenButton={() => {}}
        isCurrent={false}
        currentCodeUrl={current.codeUrl}
        isCurrentFullpass={current.fullpass}
        onDateChange={onDateChange}
        eventList={sliceObj[dateString]}
      />
    )
  }

  const mobileDatesLimit = 3
  const listByDay = mapCollectionByDay(rawList)

  return (
    <div
      className={classNames(css.event_date_list_mobile, {
        [css.removeContainer]: removeContainer,
        [css.insideCheckout]: insideCheckout,
      })}
    >
      {list.length > mobileDatesLimit && (
        <div className={css.calendar}>
          <CalendarButton data={data} onDateChange={onDateChange} />
        </div>
      )}

      <div className={css.fakeBg}></div>

      <div className={css.leftArrow} onClick={goToPrev}>
        <Img className={css.arrow} src="/arrows/arrow_multidate_left.svg" />
      </div>

      <div className={css.leftCover}></div>

      <div className={css.rightArrow} onClick={goToNext}>
        <Img className={css.arrow} src="/arrows/arrow_multidate_right.svg" />
      </div>

      <div className={css.rightCover}></div>

      <div className={css.container} ref={containerRef}>
        <div className={css.spacer}></div>
        {getFullPass()}
        {Object.keys(listByDay).map((dateString, key) => {
          return (
            <DateButton
              key={key}
              dateString={dateString}
              isOpen={dateString === openButton}
              setOpenButton={handleOpenButton}
              isCurrent={dateString === Dates.format(new Date(current.datetime), t('dates:common:yearMonthDay'))}
              currentCodeUrl={current.codeUrl}
              isCurrentFullpass={current.fullpass}
              onDateChange={onDateChange}
              eventList={listByDay[dateString]}
            />
          )
        })}
        <div className={css.spacer}></div>
      </div>
    </div>
  )
}

export default Mobile
