export const venueSkeleton = [
  {
    'color': 'black',
    'componentType': 'balloon',
    'size': 'big',
    'tipPosition': 'right',
    'list': [
      {
        'componentType': 'venueDetailsSkeleton',
        'uuid': 'f9e2abfe-f1b8-45e8-a1c8-25ea25439895',
      },
      {
        'componentType': 'menuSkeleton',
        'uuid': 'b0ea06f0-e983-4318-9b21-d3f6af55aa47',
      },
    ],
    'uuid': '31340ea9-a49e-4267-9910-dda12c973a58',
  },
  {
    'button': null,
    'componentType': 'horizontalScroll',
    'layout': 'square',
    'category': {
      'button': null,
      'componentType': 'categorySkeleton',
      'data': [
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton1-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton2-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton3-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton4-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton5-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton6-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton7-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton8-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton9-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton10-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton11-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton12-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton13-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton14-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton15-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton16-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton17-1',
        },
      ],
      'title': 'Title',
      'showMode': 'gallery',
      'titleColor': 'black',
      'size': 'm',
      'uuid': 'b08581ac-81cf-4c3e-942f-901a28cc5ee4',
    },
    'scrollBehaviour': 'many',
    'showMode': 'gallery',
    'size': 'm',
    'uuid': '2f90af25-4ed9-4b49-80b2-111f509f319a',
  },
]
