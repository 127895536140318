import { BalloonSize } from '../../structure'
import { ComponentTypeBackend, IComponentNestedBackend } from './component'

export enum BalloonSizeResponse {
  big = BalloonSize.big,
  small = BalloonSize.small,
}

export enum BalloonStyleResponse {
  ticketWhiteRight = 'ticketWhiteRight',
  ticketWhiteLeft = 'ticketWhiteLeft',
  ticketBlackRight = 'ticketBlackRight',
  ticketBlackLeft = 'ticketBlackLeft',
  ticketRedLeft = 'ticketRedLeft',
  ticketRedRight = 'ticketRedRight',
  ticketBlueLeft = 'ticketBlueLeft',
  ticketBlueRight = 'ticketBlueRight',
}

export interface IBalloonResponse extends IComponentNestedBackend<ComponentTypeBackend.balloon> {
  backgroundUrl?: string
  size?: BalloonSizeResponse
  style?: BalloonStyleResponse
}
