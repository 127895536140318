import { useEffect } from 'react'

import { useAppDispatch } from 'store'
import { AppActions } from 'store/app'
import { AuthActions } from 'store/auth'
import { CookieClient, CookieName } from 'utils/cookie'

import { useIsAppInitialized } from 'hooks/useApp'
import { useOnUpdate } from 'hooks/useOnUpdate'

export const useInitApp = (): void => {
  const dispatch = useAppDispatch()

  const initialized = useIsAppInitialized()

  // on mount, init app
  useEffect(() => {
    dispatch(AppActions.init())
  }, [dispatch])

  // when app is initialized => fetch user if token exists
  useOnUpdate(() => {
    if (initialized) {
      const token = CookieClient.get(CookieName.TOKEN)
      if (token) {
        dispatch(AuthActions.login({ token }))
      }
    }
  }, [initialized])
}
