import css from './BalloonBoxModalNoPortal.module.scss'
import React, { PropsWithChildren } from 'react'
import { TextBoxInfoTypes } from 'types/layout'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Img from 'components/Media/Img'

interface Props extends PropsWithChildren {
  bgColor: ComponentColor
  infoType: TextBoxInfoTypes
  title?: string
  onClose?: () => void
}

const BalloonBoxModalNoPortal: React.FC<Props> = (props) => {
  const { bgColor, infoType, title, onClose, children } = props

  return (
    <div className={css.balloonBoxModal} data-modalclose="skip">
      <BalloonWrapper color={bgColor} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
        {onClose && (
          <ButtonTransparent
            format={ActionFormat.circle}
            onClick={onClose}
            className={css.balloonBoxModal__btnClose}
            hover={ActionColor.transparent}
          >
            <Img src="/icons/close_icon_white.svg" />
          </ButtonTransparent>
        )}
        <div className={css.balloonBoxModal__container}>
          <Img src={`/textboxes/textboxes_${infoType}.svg`} className={css.balloonBoxModal__img} />
          <div>
            {title && (
              <div>
                <strong>{title}</strong>
              </div>
            )}
            {React.Children.toArray(children)}
          </div>
        </div>
      </BalloonWrapper>
    </div>
  )
}

export default BalloonBoxModalNoPortal
