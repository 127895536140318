import cssProfile from 'components/Auth/Profile/Profile.module.scss'
import css from 'components/Pages/ProfileResale/ResaleForm/ModalConfirm/ModalConfirm.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { ApiEndpoint } from 'controller/endpoint'
import { Dates } from 'utils/dates'

import Form from 'components/Forms/Form'
import Modal from 'components/Layout/Modal'
import { useAuthToken } from 'hooks/useAuth'
import { useTicketResaleData } from 'hooks/useProfile'

type Props = {
  onClose(): void
  onSuccess(): void
}

const ModalConfirm: React.FC<Props> = (props) => {
  const { onClose, onSuccess } = props

  const { t } = useTranslation()

  const authToken = useAuthToken()
  const {
    amount,
    eventDate,
    eventLocation,
    eventName,
    firstName,
    fiscalSeal,
    iban,
    lastName,
    ibanHolderFirstName,
    ibanHolderLastName,
  } = useTicketResaleData()

  return (
    <Modal show={true} fullscreenOnMobile handleClose={onClose}>
      <div className={classNames(cssProfile.profileWrapper__section, css.resaleModalConfirm)}>
        <div data-testid={DataTestId.profile.resaleModal}>
          {t('profile:resale_are_you_sure', {
            eventDate: Dates.format(eventDate ? new Date(eventDate) : new Date(), t('dates:common:longDate')),
            eventName,
            eventLocation,
          })}
        </div>

        <div>{t('profile:resale_refund_amount', { amount })}</div>

        <Form
          action={ApiEndpoint.users.resellConfirm()}
          authToken={authToken}
          bodyParser={() => ({
            iban,
            firstName,
            fiscalSeal,
            lastName,
            ibanHolderFirstName,
            ibanHolderLastName,
          })}
          formFields={[]}
          labelSubmit="common:confirm"
          onSuccess={() => {
            window && window.scrollTo(0, 0)
            onSuccess()
          }}
        />
      </div>
    </Modal>
  )
}

export default ModalConfirm
