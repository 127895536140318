import css from './CardItem.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ICCBrand, ICheckoutCC } from 'meta/pages/checkout'

import Img from 'components/Media/Img'

type Props = {
  data: ICheckoutCC
  deleteCallback?(): void
}

const CardItem: React.FC<Props> = (props) => {
  const { data, deleteCallback } = props

  const { t } = useTranslation()

  const Brand: React.FC = useMemo(() => {
    const Component: React.FC = () => (
      <div className={`${css.brand} ${css[data.brand]}`}>
        {data.brand === ICCBrand.visa && <Img src="/payment_methods/footer/visa.svg" />}
        {data.brand === ICCBrand.mastercard && <Img src="/payment_methods/footer/mastercard.svg" />}
        {data.brand !== ICCBrand.visa && data.brand !== ICCBrand.mastercard && data.brand}
      </div>
    )
    return Component
  }, [data.brand])
  const Description = useMemo(() => {
    const Component: React.FC = () => <h4 className={css.description}>{data.description}</h4>
    return Component
  }, [data.description])
  const Expiry = useMemo(() => {
    const Component: React.FC = () => (
      <div className={css.expiry}>
        <div className={css.label}>{t('profile:cc_expiry')}</div>
        <div className={css.text}>{data.expiry}</div>
      </div>
    )
    return Component
  }, [data.expiry, t])

  const Delete = useMemo(() => {
    const Component: React.FC = () => (
      <div className={css.delete} onClick={deleteCallback}>
        <Img alt="" src="/checkout/card_delete.svg" />
      </div>
    )
    return Component
  }, [deleteCallback])

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <div className={css.cardItem}>
          <div className={css.left}>
            <Description />
            <Expiry />
          </div>
          <div className={css.right}>
            <Brand />
            <Delete />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <div className={css.cardItem}>
          <div className={css.left}>
            <Brand />
            <Description />
          </div>
          <div className={css.right}>
            <Expiry />
            <Delete />
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default CardItem
