import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Routes } from 'meta/routes'
import { SmartLook as SmartLooks } from 'utils/smartlook'

import { useUserData } from 'hooks/useAuth'
import { useAppEnv, useSmartLookKey } from 'hooks/useEnv'

const recordedRoutes: Record<string, boolean> = {
  [Routes.CheckoutProcess.generatePath()]: true,
  [Routes.ProfileResale.generatePath()]: true,
  [Routes.ProfileTransactions.generatePath()]: true,
  [Routes.ProfileUndoResale.generatePath()]: true,
}

const SmartLook: React.FC = () => {
  const { pathname } = useLocation()
  const appEnv = useAppEnv()
  const smartlookKey = useSmartLookKey()
  const userData = useUserData()

  const record = useMemo<boolean>(() => {
    // /refund => temp hack. generatePath gives error because /refund has a path parameter
    return recordedRoutes[pathname] || pathname.startsWith('/refund')
  }, [pathname])

  useEffect(() => {
    if (record && smartlookKey && appEnv) {
      SmartLooks.init(smartlookKey, appEnv, userData)
    }
  }, [appEnv, record, smartlookKey, userData])

  return null
}

export default SmartLook
