import css from './Installments.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencySymbol } from 'meta/currency'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'

import { ButtonRed } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutRecapExtra } from 'hooks/useCheckout'

type Props = {
  firstShow: boolean
  onClose(): void
}

const Installments: React.FC<Props> = (props) => {
  const { firstShow, onClose } = props

  const { t } = useTranslation()
  const extra = useCheckoutRecapExtra()
  const { activeCurrency } = useActiveCurrency()

  const [showDetails, setShowDetails] = useState<boolean>(firstShow)

  if (!extra) return null

  const { installments } = extra

  const eventCurrencyCode =
    installments && installments.length >= 1
      ? getCodeFromSymbol(installments[0].installment.symbol as CurrencySymbol)
      : ''

  return (
    <div className={css.installments}>
      <div className={css.installments__header}>
        <button className={css.installments__btnBack} onClick={onClose}>
          <Img alt="" src="/icons/arrow_left_white.svg" />
        </button>
        <h3>{t('checkout:monthly_pay')}</h3>
        {!showDetails && (
          <button className={css.installments__btnInfo} onClick={() => setShowDetails(true)}>
            <Img alt="" src="/icons/info_white.svg" />
          </button>
        )}
      </div>

      {showDetails ? (
        <>
          <div className={css.installments__grid}>
            <h4>{t('checkout:debit_date')}</h4>
            <h4>{t('checkout:amount')}</h4>
            <div className={css.installments__gridSep} />
            {installments?.map((installment, i) => {
              const dueDate = Dates.format(new Date(installment.dueDate), 'd MMMM yyyy')
              const today = Dates.isSameDay(new Date(installment.dueDate), new Date())
              return (
                <React.Fragment key={String(i)}>
                  <div className={css.installment_date}>
                    <h4 className={css.no}>{i + 1}</h4>
                    <h4>{today ? t('checkout:today') : dueDate}</h4>
                  </div>
                  <h4>{convertAndFormat(installment.installment.amount, eventCurrencyCode, activeCurrency, true)}</h4>
                </React.Fragment>
              )
            })}
            <div className={css.installments__gridSep} />
          </div>
          <div className={css.installments__description}>
            {t('checkout:scalapay_info', {
              installments: installments?.length,
              amount: convertAndFormat(
                installments?.[0].installment?.amount ?? 0,
                eventCurrencyCode,
                activeCurrency,
                true
              ),
            })}
          </div>
          <ButtonRed className={css.installments__btnConfirm} onClick={() => setShowDetails(false)}>
            {t('checkout:stripe_confirm_cc')}
          </ButtonRed>
        </>
      ) : (
        <div className={css.installments__grid}>
          <div className={css.installments__gridSep} />
          <h4>{t('checkout:pay_today')}</h4>
          <h4>
            {convertAndFormat(installments?.[0]?.installment?.amount ?? 0, eventCurrencyCode, activeCurrency, true)}
          </h4>
          <div className={css.installments__gridSep} />
          <h4>{t('checkout:outstanding_balance')}</h4>
          <h4>
            {convertAndFormat(
              (installments?.[1]?.installment?.amount ?? 0) + (installments?.[2]?.installment?.amount ?? 0),
              eventCurrencyCode,
              activeCurrency,
              true
            )}
          </h4>
          <div className={css.installments__gridSep} />
        </div>
      )}
    </div>
  )
}

export default Installments
