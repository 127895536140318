import { fetchData } from 'store/pages/collection/actions/fetchData'

import { CollectionSlice } from '../slice'
import { initSeats } from './initSeats'

export const CollectionActions = {
  ...CollectionSlice.actions,
  fetchData,
  initSeats,
}
