import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { RouteParams, Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'

import BackendControlled from 'components/BackendControlled'
import withTranslation from 'components/HOC/WithTranslation'

const Location: React.FC = () => {
  const { locationId } = useParams<RouteParams['Location']>()

  if (!locationId) {
    return <Navigate to={Routes.Home.generatePath()} />
  }

  return <BackendControlled path={ApiEndpoint.venue.components(locationId)} />
}

const namespaces = ['location']

export default withTranslation(namespaces)(Location)
