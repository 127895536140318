import css from './UsersPassword.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import withTranslation from 'components/HOC/WithTranslation'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Video from 'components/Media/Video'

const UsersPassword: React.FC = () => {
  const color = ComponentColor.black

  return (
    <div className={classNames(css.usersPassword, css[color])}>
      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Video url="/dj.mp4" />
      </MediaQuery>

      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <div className={css.usersPassword__content}>
          <Outlet />
        </div>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <div className={css.usersPassword__balloonContainer}>
          <BalloonWrapper color={color} tipPosition={BalloonTipPosition.right} size={BalloonSize.small}>
            <div className={css.usersPassword__content}>
              <Outlet />
            </div>
          </BalloonWrapper>
        </div>
      </MediaQuery>
    </div>
  )
}

const namespaces = ['password']
export default withTranslation(namespaces)(UsersPassword)
