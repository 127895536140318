import css from './Ita.module.scss'
import React from 'react'

import Head from 'components/common/Head'
import Img from 'components/Media/Img'

const ResaleIta: React.FC = () => {
  const meta_title = 'Come rivendere i biglietti acquistati su TicketSms'
  const meta_desc =
    "Un imprevisto ti impedisce di partecipare all'evento acquistato? Recupera il tuo denaro: rivendere biglietti acquistati non è mai stato così semplice. Leggi di più."

  return (
    <>
      <Head>
        <title>{meta_title}</title>

        <meta property="og:title" content={meta_title} />
        <meta name="description" content={meta_desc} />
        <meta property="og:description" content={meta_desc} />
      </Head>

      <div className={css.resale_ita_container}>
        <div className={css.static_page_title}>
          <Img src="/resale/resale.svg" />
          <div>Rivendita</div>
        </div>
        <div className={css.static_page_text}>
          <p>
            In caso di impossibilità a partecipare ad un evento, potrà essere attivata la procedura di rivendita del
            biglietto di cui si è intestatari o dei biglietti che si sono acquistati (anche di un singolo biglietto in
            caso di acquisti multipli) accedendo all’apposita sezione dal profilo utente o dal riepilogo delle
            transazioni.
          </p>

          <p>
            <b>Per effettuare la rimessa in vendita sul sito www.ticketsms.it</b> sarà necessario indicare il{' '}
            <b>Nome</b>, il <b>Cognome</b> ed il <b>Sigillo fiscale</b> presenti sul biglietto originale di cui si è
            acquirenti o intestatari (queste informazioni sono tutte indicate all'interno del biglietto stesso).
            Inoltre, sarà necessario indicare, oltre alle proprie generalità, un <b>IBAN</b>
            sul quale verrà effettuato un bonifico in caso di avvenuta rivendita.
          </p>

          <p>
            Una volta verificati i dati e il diritto a procedere, verrà visualizzato un riepilogo dell’offerta e sarà
            chiesta un’ultima conferma dopo la quale il biglietto sarà rimesso in vendita allo stesso prezzo del
            biglietto originale all’interno di TicketSms stesso. Quando un nuovo acquirente vorrà acquistare un
            biglietto dello stesso genere di quello rimesso in vendita, verrà data priorità a quest’ultimo rispetto ai
            biglietti ordinari dello stesso genere ancora, a tale momento, invenduti.
          </p>

          <p>
            La rimessa in vendita può essere effettuata anche tramite <b>App TicketSms</b> accedendo dal proprio profilo
            alla sezione “Le tue transazioni” ove sarà possibile visionare l’elenco delle transazioni effettuate.
            Cliccando sulla transazione scelta sarà possibile visualizzare la totalità dei biglietti acquistati e
            decidere quali rimettere in vendita. Per effettuare la rimessa in vendita sarà necessario inserire{' '}
            <b>Nome</b>, <b>Cognome</b> ed <b>IBAN</b>
            dell’intestatario del biglietto. Cliccando su “Gestione rivendita” sarà possibile monitorare la rivendita e
            visualizzarne i dettagli.
          </p>

          <p>
            Nel caso in cui la rivendita avvenga con successo, il venditore riceverà notifica di annullamento del
            biglietto per rivendita mediante SMS inviato al numero di telefono associato al biglietto rivenduto ed entro
            15 giorni lavorativi riceverà un bonifico pari al valore del singolo biglietto rivenduto, al netto delle
            commissioni e del costo di servizio che ammonta al 10% del prezzo del biglietto, oltre ai diritti di
            prevendita. Nessuna commissione o tassa di inserimento è prevista. La commissione viene applicata solo a
            rivendita ultimata. In caso di mancata rivendita del biglietto, non verrà effettuato nessun accredito.
          </p>

          <p>
            La funzione della rivendita segue le{' '}
            <b>stesse regole temporali della vendita classica fino ad inizio evento</b>. Sarà, quindi, disponibile per
            tutta la durata della messa in vendita dei biglietti ordinari fino all'apertura dei cancelli dell'evento. Da
            quel momento in poi non sarà più possibile rivendere i propri biglietti o acquistare i biglietti rimessi in
            vendita da altri utenti.
          </p>

          <p>
            Qualora si desiderasse <b>cancellare la rimessa in vendita</b> di un proprio biglietto basterà accedere
            all'apposita sezione dal menù di rimessa in vendita del proprio profilo dove sarà necessario inserire
            nuovamente i dati relativi al biglietto (Nominativo e Sigillo fiscale). Tale cancellazione potrà essere
            effettuata anche attraverso App TicketSms. La cancellazione sarà, tuttavia, possibile solo fin quando il
            biglietto in questione non sarà inserito nel carrello di un nuovo acquirente.
          </p>

          <p>
            Il biglietto originale <b>non potrà essere utilizzato finché resterà nel mercato di rivendita</b>, pertanto,
            anche in caso di mancata rivendita entro i tempi limite, per poter utilizzare il proprio biglietto per
            accedere all'evento sarà necessario attuare la procedura di cancellazione della rivendita.
          </p>

          <p>
            Si precisa che è sempre a discrezione del solo organizzatore la facoltà di poter limitare temporalmente e/o
            bloccare la rivendita dei biglietti.
          </p>

          <p>
            Si noti, infine, che <b>non è possibile rivendere i biglietti acquistati utilizzando 18APP</b> né quelli non
            soggetti alla legislazione italiana della SIAE.
          </p>
        </div>
      </div>
    </>
  )
}

export default ResaleIta
