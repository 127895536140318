import css from './Artist.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionArtist } from 'meta/pages/collection'

import { Grid, GridColumn } from 'components/Layout/Grid'
import { MockCollectionAnchorable } from 'components/Pages/Collection/mock'

import Multiple from './Multiple/Multiple'
import Single from './Single/Single'

const Artist: React.FC<CollectionArtist & MockCollectionAnchorable> = (props) => {
  const { artists, anchorId } = props

  const isSingleArtist: boolean = artists && artists.length == 1

  const { t } = useTranslation()

  return (
    <Grid>
      <GridColumn
        gridColumnStart={1}
        gridColumnEnd={{ [Breakpoints.tablet]: isSingleArtist ? 9 : 17, [Breakpoints.mobile]: 17 }}
      >
        <div className={css.artistContainer}>
          <h2 className={css.title} id={anchorId}>
            {t('collection:artist_title')}
          </h2>
          {artists && artists.length == 1 && <Single data={artists[0]} />}
          {artists && artists.length > 1 && <Multiple data={artists} />}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default Artist
