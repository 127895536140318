import css from './Single.module.scss'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'

import {
  CategoryShowMode,
  ComponentSize,
  ComponentType,
  IAsset,
  IComponent,
  IStory,
} from 'meta/backendControlled/structure'

import { ActionFormat, ButtonWhite } from 'components/Actions/Action'
import { getCardMarginSize } from 'components/BackendControlled/Component/Category/Showcase/Single/marginHelper'
import useSwiperScroll from 'components/BackendControlled/Component/Category/Showcase/Single/useSwiperScroll'
import Component from 'components/BackendControlled/Component/index'
import { Flexbox } from 'components/Layout/Grid'
import ImageGallery from 'components/Media/ImageGallery'
import Img from 'components/Media/Img'
import InstaStories from 'components/Media/InstaStories'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IPropsSingle {
  data: Array<IComponent<ComponentType>>
  setVisibleIdx: (idx: Array<number>) => void
  showMode?: CategoryShowMode
  size: ComponentSize
}

const Single: React.FC<IPropsSingle> = (props) => {
  const { data, setVisibleIdx, showMode, size } = props
  const isDesktop = !isMobile && !isTablet

  const [gallery, setGallery] = useState<{ startIndex: number }>()
  const [stories, setStories] = useState<{ startIndex: number }>()
  const swiperRef = useRef<HTMLDivElement>(null)
  const swiperEdges = useSwiperScroll(swiperRef)

  const [scrollCount, setScrollCount] = useState<number>(0)
  const [scrollData, setScrollData] = useState<Array<IComponent<ComponentType>>>([])

  const scrollTo = (element: HTMLDivElement): void => {
    swiperRef.current?.scrollTo({ left: element.offsetLeft, behavior: 'smooth', top: 0 })
  }
  const onPrev = (): void => {
    if (swiperEdges.first?.node) scrollTo(swiperEdges.first.node)
  }

  const onNext = (): void => {
    if (swiperEdges.last?.node) scrollTo(swiperEdges.last.node)
  }

  const scrollToNextCard = (element: HTMLDivElement): void => {
    if (swiperRef.current) {
      const marginSize = getCardMarginSize()
      const scrollSize = swiperRef.current.scrollLeft + element.clientWidth + marginSize
      swiperRef.current.scrollTo({ left: scrollSize, behavior: 'smooth', top: 0 })
    }
  }

  const onGalleryClose = useCallback(() => setGallery(undefined), [])

  const galleryItems: Array<IAsset> =
    showMode && showMode === CategoryShowMode.gallery
      ? data.reduce<Array<IAsset>>((items, datum) => {
          if (datum.componentType === ComponentType.asset) {
            const asset = datum as IAsset
            return [...items, asset]
          }
          return items
        }, [])
      : []

  const isSquareGallery =
    data.filter((x) => x.componentType !== ComponentType.square && x.componentType !== ComponentType.parentSquare)
      .length < 1

  useOnUpdate(() => {
    const idxStart = swiperEdges.first ? swiperEdges.first.idx + 1 : 0
    const idxEnd = swiperEdges.last ? swiperEdges.last.idx : data.length
    const visibleIdx = []
    for (let i = idxStart; i < idxEnd; i++) {
      visibleIdx.push(i)
    }
    setVisibleIdx(visibleIdx)
  }, [swiperEdges])

  // autoscroll effect on stories
  useEffect(() => {
    if (showMode === CategoryShowMode.story) {
      if (swiperEdges && (swiperEdges.first || swiperEdges.last)) {
        const interval = setInterval(() => {
          if (swiperEdges.last && !swiperEdges.first) {
            scrollToNextCard(swiperEdges.last.node)
          } else if (swiperEdges?.first?.node) {
            scrollToNextCard(swiperEdges.first.node)
          }

          if (scrollCount === 0 || !swiperEdges.last) {
            setScrollData(scrollData.concat(data))
          }

          setScrollCount((scrollCount) => (scrollCount < data.length ? scrollCount + 1 : 0))
        }, 3000)

        return () => clearInterval(interval)
      }
    }
  }, [swiperEdges, data, scrollCount, scrollData, showMode])

  return (
    <>
      <div className={css.swiperWrapper}>
        {isDesktop && (
          <>
            <ButtonWhite
              className={css.swiperBtnPrev}
              disabled={!swiperEdges.first}
              format={ActionFormat.circle}
              onClick={onPrev}
            >
              <Img src="/carousel/controls/prev.png" />
            </ButtonWhite>
            <ButtonWhite
              className={css.swiperBtnNext}
              disabled={!swiperEdges.last}
              format={ActionFormat.circle}
              onClick={onNext}
            >
              <Img src="/carousel/controls/next.png" />
            </ButtonWhite>
          </>
        )}

        <Flexbox className={css.swiper} ref={swiperRef} extraPadding={isSquareGallery}>
          {data.concat(scrollData).map((datum, idx) => {
            let onClick = null
            if (showMode === CategoryShowMode.gallery) onClick = () => setGallery({ startIndex: idx })
            if (showMode === CategoryShowMode.story)
              onClick = () => {
                const currentScrollRound = Math.trunc(idx / data.length)
                const correctIndex = idx - data.length * currentScrollRound

                setStories({ startIndex: correctIndex })
              }

            const componentProps = { ...datum, size, onClick }

            return (
              // @ts-expect-error something might be missing
              <Component key={String(idx)} {...componentProps} />
            )
          })}
        </Flexbox>
      </div>

      {gallery && <ImageGallery items={galleryItems} onClose={onGalleryClose} startIndex={gallery.startIndex} />}

      {stories && (
        <InstaStories
          onClose={() => {
            setStories(undefined)
          }}
          startIndex={stories.startIndex}
          stories={data as Array<IStory>}
        />
      )}
    </>
  )
}

export default Single
