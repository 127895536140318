import React, { useEffect, useRef } from 'react'

import { SearchParamUtils } from 'meta/pages/search'
import { QueryParams } from 'meta/routes'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import SearchForm from 'components/Pages/Search/SearchForm'
import SearchInfo from 'components/Pages/Search/SearchInfo'
import SearchResult from 'components/Pages/Search/SearchResult'
import SearchSkeleton from 'components/Pages/Search/SearchSkeleton'
import { useIsAppInitialized } from 'hooks/useApp'
import { usePrevious } from 'hooks/usePrevious'
import { useQueryParams } from 'hooks/useQueryParams'
import { useSearchIsFetching, useSearchParams } from 'hooks/useSearch'

const Search: React.FC = () => {
  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()
  const queryParams = JSON.stringify(useQueryParams<QueryParams['Search']>())
  const prevQueryParams = usePrevious(queryParams)
  const searchParams = useSearchParams()
  const fetching = useSearchIsFetching()
  const initializedRef = useRef<boolean>(false)

  useEffect(() => {
    if (appInitialized && (queryParams !== prevQueryParams || (queryParams && !initializedRef.current))) {
      SearchParamUtils.fromUrl().then((params) => {
        initializedRef.current = true
        dispatch(SearchActions.init(params))
      })
    }
  }, [appInitialized, dispatch, prevQueryParams, queryParams])

  return (
    <>
      <Head>
        <title>
          {`${searchParams.query && searchParams.query.trim().length > 0 ? `${searchParams.query} | ` : ''}`}
          TicketSms.it
        </title>
      </Head>

      <SearchForm />
      <WithAppInitialized skeleton={<SearchSkeleton />}>
        <SearchResult />
      </WithAppInitialized>
      {fetching && <SearchSkeleton />}
      <SearchInfo />
    </>
  )
}

export default Search
