import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Props = {
  codeUrl: string
}

type Response = {
  eventSchema: string
}

export const getMetadata = async (props: Props): Promise<Object | undefined> => {
  const { codeUrl } = props

  try {
    const { data } = await HttpRequest.get<Response>(ApiEndpoint.event.metadata(codeUrl))
    return data ? JSON.parse(data.eventSchema) : undefined
  } catch (error) {
    window.prerenderReady = true

    return undefined
  }
}
