import { EventPolicy } from 'meta/backendControlled/structure'
import {
  FormDataType,
  FormFieldType,
  HiddenBuilder,
  IFormField,
  InputDateBuilder,
  SelectBuilder,
  TextAreaBuilder,
  TextBuilder,
} from 'meta/form'
import { FormFieldBuilder } from 'meta/form/formFieldBuilder'
import { AppointmentStatus, ITicket, ITicketExtraField } from 'meta/pages/tickets'
import { ISpecialEventOptions } from 'meta/pages/tickets/types'
import { getHiddenSeatId } from 'helpers/form/fields/ticket'
import {
  getBirthDate,
  getBirthplaceCity,
  getBirthplaceCountry,
  getFirstName,
  getGender,
  getLastName,
  getPhone,
} from 'helpers/form/fields/user'
import { APPOINTMENTS_FIELD_IDENTIFIER, DATALIST_CITY_DEFAULT_VALUE } from 'utils/constants/form'

import { ICovid19Field, ITicketsFormFields, PersonalDataField } from '../types'
import { getTicketFieldsKey } from '../utils'

const DEFAULT_TEXTAREA_MAXLENGTH = 500

const getFieldName = (ticket: ITicket, idx: number, fieldName: string) => `${idx}_${ticket.id}_${fieldName}`

const getCommonFields = (ticket: ITicket, idx: number): Array<IFormField<FormFieldType>> => {
  const fields: Array<IFormField<FormFieldType>> = []

  fields.push(getFirstName(getFieldName(ticket, idx, 'firstName')))
  fields.push(getLastName(getFieldName(ticket, idx, 'lastName')))
  fields.push(getPhone(getFieldName(ticket, idx, 'phone'), getFieldName(ticket, idx, 'phonePrefix')))

  if (ticket.seatId) {
    fields.push(getHiddenSeatId(getFieldName(ticket, idx, 'seatId'), ticket.seatId))
  }

  return fields
}

const getPersonalDataFields = (ticket: ITicket, idx: number): Array<IFormField<FormFieldType>> => {
  const fields: Array<IFormField<FormFieldType>> = []

  fields.push(getBirthDate(getFieldName(ticket, idx, PersonalDataField.dateOfBirth)))
  fields.push(getGender(getFieldName(ticket, idx, PersonalDataField.gender)))

  // HACK: overwritten in TicketFormFields
  fields.push(getBirthplaceCountry(getFieldName(ticket, idx, PersonalDataField.birthplaceCountry)))

  // HACK: overwritten in TicketFormFields
  fields.push(
    getBirthplaceCity(getFieldName(ticket, idx, PersonalDataField.birthplaceCity), DATALIST_CITY_DEFAULT_VALUE)
  )

  return fields
}

const getExtraFields = (ticket: ITicket, idx: number): Array<IFormField<FormFieldType>> =>
  ticket.extraFields.map((extraField: ITicketExtraField) => {
    let builder: FormFieldBuilder<FormFieldType> | undefined = undefined
    const { dataType, fieldType, id, label, metadata, name, options, placeholder, required } = extraField
    const fieldName = getFieldName(ticket, idx, `extra_${id}_${name}`)

    const dataTypeConverter: Record<FormDataType, () => FormFieldBuilder<FormFieldType>> = {
      [FormDataType.birthDate]: () => {
        const validation = new InputDateBuilder(fieldName)
        if (required) {
          validation.addRequiredBeforeNowValidation('tickets:errors_form_required_extra_field')
        }
        return validation.addPlaceholder(placeholder ?? '')
      },
      [FormDataType.date]: () => {
        const validation = new InputDateBuilder(fieldName)
        if (required) {
          validation.addRequiredDateValidation('tickets:errors_form_required_extra_field')
        }
        return validation.addPlaceholder(placeholder ?? '')
      },
      [FormDataType.fiscalCode]: () => {
        const validation = new TextBuilder(fieldName)
        if (required) {
          validation.addRequiredFiscalCodeValidation('tickets:errors_form_required_extra_field')
        }
        return validation.addPlaceholder('form:fiscalCode')
      },
      [FormDataType.textArea]: () => {
        const validation = new TextAreaBuilder(fieldName)

        return validation
          .addTextAreaValidation(required)
          .addPlaceholder(placeholder ?? '')
          .addMaxLength(metadata?.maxLength ?? DEFAULT_TEXTAREA_MAXLENGTH)
      },
    }

    if (fieldType === FormFieldType.text) {
      const type: FormDataType | undefined = metadata ? metadata.type : dataType
      builder =
        dataTypeConverter[type as FormDataType]?.() ??
        new TextBuilder(fieldName)
          .addRequiredValidation('tickets:errors_form_required_extra_field')
          .addPlaceholder(placeholder ?? '')
    }

    if (fieldType === FormFieldType.select) {
      builder = new SelectBuilder(fieldName, options)
        .addRequiredValidation('tickets:errors_form_required_extra_field')
        .addPlaceholder(placeholder ?? '')
    }

    if (!builder) throw new Error(`Extra field of type ${fieldType} is not supported`)

    return builder.addLabel(label).build()
  })

const getCovid19Fields = (ticket: ITicket, idx: number): Array<ICovid19Field> | undefined =>
  ticket?.appointments?.reduce<Array<ICovid19Field>>((fieldsAcc, appointment) => {
    if (appointment.status === AppointmentStatus.enabled) {
      const hiddenFieldId = getFieldName(ticket, idx, `${APPOINTMENTS_FIELD_IDENTIFIER}_${appointment.uuid}`)
      fieldsAcc.push({
        appointment,
        hiddenFieldId,
        formField: new HiddenBuilder(hiddenFieldId)
          .addRequiredValidation('tickets:errors_form_required_extra_field')
          .build(),
      })
    }
    return fieldsAcc
  }, [])

export const useFormFields = (tickets: Array<ITicket>, options: ISpecialEventOptions): ITicketsFormFields =>
  tickets.reduce<ITicketsFormFields>(
    (ticketsAcc, ticket, idx) => {
      const commonFields = getCommonFields(ticket, idx)
      const extraFields = getExtraFields(ticket, idx)
      const covid19Fields =
        options.policy === EventPolicy.covid19 && ticket.appointments ? getCovid19Fields(ticket, idx) : []

      const personalDataFields = options.isPersonalDataRequired ? getPersonalDataFields(ticket, idx) : []

      const fieldsKey = getTicketFieldsKey(ticket, idx)
      ticketsAcc.commonFields = { ...ticketsAcc.commonFields, [fieldsKey]: commonFields }
      ticketsAcc.extraFields = { ...ticketsAcc.extraFields, [fieldsKey]: extraFields }
      ticketsAcc.covid19Fields = { ...ticketsAcc.covid19Fields, [fieldsKey]: covid19Fields }
      ticketsAcc.personalDataFields = { ...ticketsAcc.personalDataFields, [fieldsKey]: personalDataFields }

      return ticketsAcc
    },
    { commonFields: {}, extraFields: {}, covid19Fields: {}, personalDataFields: {} }
  )
