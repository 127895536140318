// import { useDispatch } from 'react-redux'
import css from './Phone.module.scss'
import React from 'react'

// import { LoginData, LoginStep } from 'meta/pages/login'
import { Breakpoints } from 'meta/layout/breakpoints'
import { LoginData, LoginStep } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

// import { loginActions, useLoginData } from '../../../../../store/pages/login'
import { GridColumn } from 'components/Layout/Grid'
import { useLoginData } from 'hooks/useLogin'

import { IStepProps, StepImg, StepLabel } from '../StepCommon'
import PhoneForm from './PhoneForm'

const Phone: React.FC<IStepProps> = () => {
  const dispatch = useAppDispatch()
  const data = useLoginData()

  const { phone, phonePrefix } = data

  //@ts-ignore
  const onSubmitSuccess = (values, response) => {
    const { hasUser, otpToken, otp } = response.data
    const { phone, phonePrefix } = values
    const dataUpdate: LoginData = { ...data, hasUser, otp, otpToken, phone, phonePrefix }

    dispatch(LoginActions.updateData({ data: dataUpdate, step: LoginStep.otp }))
  }

  return (
    <>
      <StepImg src="/login/step_phone.svg" />
      <StepLabel labelKey="registerV2:insert_phone_number" />

      <GridColumn
        gridColumnStart={2}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 10 }}
        className={css.loginPhone__phone}
      >
        <PhoneForm action={ApiEndpoint.users.otp()} phone={phone} prefix={phonePrefix} onSuccess={onSubmitSuccess} />
      </GridColumn>
    </>
  )
}

export default Phone
