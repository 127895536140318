import css from 'components/common/Support/Support.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const SupportSkeleton: React.FC = () => {
  return (
    <div className={classNames(css.header)}>
      <div className={classNames(css.img__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
      <div className={classNames(css.title__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
        />
      </div>
    </div>
  )
}
