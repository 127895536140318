import css from './Auth.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckoutAuthType, CheckoutStep, ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { CookieClient, CookieName } from 'utils/cookie'

import LoginV2 from 'components/Auth/LoginV2'
import EventDetails from 'components/Checkout/EventDetails'
import AuthBox from 'components/Checkout/Steps/Auth/AuthBox'
import FastCheckoutBox from 'components/Checkout/Steps/Auth/FastCheckoutBox'
import LoginBox from 'components/Checkout/Steps/Auth/LoginBox'
import TitleBar from 'components/Checkout/TitleBar'
import { useUserData } from 'hooks/useAuth'
import { useCheckoutDetails } from 'hooks/useCheckout'

const Auth: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { insideIFrame, needAuth } = useCheckoutDetails() as ICheckoutDetails
  const userData = useUserData()
  const userToken = CookieClient.get(CookieName.TOKEN)

  const [showLogin, setShowLogin] = useState<boolean>(false)
  const showInitialAuthScreen = !userToken && needAuth

  // @ts-ignore
  const onSubmitFastCheckout = ({ email }) => {
    dispatch(CheckoutActions.updateAuth({ auth: { type: CheckoutAuthType.fastCheckout, email } }))
    dispatch(CheckoutActions.updateStep({ step: CheckoutStep.transaction }))
  }

  const onCompleteLogin = () => {
    dispatch(CheckoutActions.updateAuth({ auth: { type: CheckoutAuthType.login } }))
    dispatch(CheckoutActions.updateStep({ step: CheckoutStep.transaction }))
  }

  return (
    <div className={css.checkout_steps_auth_container}>
      {!insideIFrame && <EventDetails showCloseButton={true} />}

      <div className={`${css.auth_body} ${showLogin && css.on_login}`}>
        <TitleBar title={t('checkout:complete_purchase')} />

        {userToken && (userData?.email ?? '') === '' && (
          <div className={css.auth_boxes}>
            {/* @ts-ignore*/}
            <FastCheckoutBox onSuccess={onSubmitFastCheckout} />
          </div>
        )}

        {!userToken &&
          !showLogin &&
          (showInitialAuthScreen ? (
            <div className={css.auth_boxes}>
              <LoginBox setShowLogin={setShowLogin} />
            </div>
          ) : (
            <div className={css.auth_boxes}>
              {/*@ts-ignore*/}
              <FastCheckoutBox onSuccess={onSubmitFastCheckout} />
              <AuthBox setShowLogin={setShowLogin} />
            </div>
          ))}

        {showLogin && <LoginV2 fromModal onComplete={onCompleteLogin} />}
      </div>
    </div>
  )
}

export default Auth
