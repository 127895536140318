import css from './Ticket.module.scss'
import React from 'react'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { ITicket } from 'meta/pages/tickets'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { GridColumn } from 'components/Layout/Grid'

import { ITicketsFormFields } from '../types'
import TicketFormFields from './TicketFormFields'
import TicketInfo from './TicketInfo'

interface IPropsTicket {
  formFields: ITicketsFormFields
  idx: number
  ticket: ITicket
}

const Ticket: React.FC<IPropsTicket> = (props) => {
  const { formFields, idx, ticket } = props

  return (
    <GridColumn
      className={css.ticket}
      id={`${idx}_${ticket.id}`}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
    >
      <BalloonWrapper color={ComponentColor.white} tipPosition={BalloonTipPosition.right} size={BalloonSize.small}>
        <div className={css.ticket__content}>
          <TicketInfo ticket={ticket} />
          <TicketFormFields formFields={formFields} idx={idx} ticket={ticket} />
        </div>
      </BalloonWrapper>
    </GridColumn>
  )
}

export default Ticket
