import css from './Actions.module.scss'
import React from 'react'
import { isMobileSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { ComponentColor, IEventDetails } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'
import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { ActionFormat, ActionSize, ButtonWhiteRed } from 'components/Actions/Action'
import { useButtonBuySticky } from 'components/BackendControlled/Component/EventDetails/Actions/hooks'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { Grid, GridColumn, GridType } from 'components/Layout/Grid'
import { ButtonMap, ButtonPhone, ButtonShare } from 'components/Social'
import { useIsEventFree } from 'hooks/useEvent'

const Actions: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const { codeUrl, infoPhone, location, name } = props
  const eventDetailsBtnBuy = 'event-details-btn-buy'

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const sticky = useButtonBuySticky(eventDetailsBtnBuy)

  const isEventFree = useIsEventFree()

  const openCheckout = () => {
    dispatch(CheckoutActions.init({ codeUrl }))
  }

  return (
    <>
      <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}>
        <Grid type={GridType.flex}>
          {infoPhone && (
            <GridColumn
              gridColumnSpan={{
                [Breakpoints.mobile]: 3,
                [Breakpoints.tablet]: 2,
                [Breakpoints.laptop]: 1,
              }}
            >
              <ButtonPhone color={ComponentColor.black} phone={infoPhone} />
            </GridColumn>
          )}

          <GridColumn
            gridColumnSpan={{
              [Breakpoints.mobile]: 3,
              [Breakpoints.tablet]: 2,
              [Breakpoints.laptop]: 1,
            }}
          >
            <ButtonShare color={ComponentColor.black} name={name} />
          </GridColumn>

          {location && (
            <GridColumn
              gridColumnSpan={{
                [Breakpoints.mobile]: 3,
                [Breakpoints.tablet]: 2,
                [Breakpoints.laptop]: 1,
              }}
            >
              <ButtonMap
                mapsPlaceId={location.mapsPlaceId}
                color={ComponentColor.black}
                latitude={location.latitude}
                longitude={location.longitude}
              />
            </GridColumn>
          )}
        </Grid>
      </GridColumn>

      <GridColumn
        gridColumnStart={{ [Breakpoints.tablet]: 13, [Breakpoints.mobile]: 1 }}
        gridColumnEnd={17}
        gridRowStart={2}
      >
        <ButtonWhiteRed
          data-testid={DataTestId.event.btnBuy}
          className={css.eventDetails_buttonBuy}
          id={eventDetailsBtnBuy}
          onClick={openCheckout}
          format={ActionFormat.fullWidth}
        >
          <h5>{isEventFree ? t('common:sign_up') : t('common:buy')}</h5>
        </ButtonWhiteRed>
      </GridColumn>

      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        <div
          className={classNames(css.buyTicketsContainerMobile, {
            [css.active]: sticky,
            [css.mobileSafari]: isMobileSafari,
          })}
        >
          <ButtonWhiteRed onClick={openCheckout} size={ActionSize.m} format={ActionFormat.fullWidth}>
            <strong>{isEventFree ? t('common:sign_up') : t('common:buy')}</strong>
          </ButtonWhiteRed>
        </div>
      </MediaQuery>
    </>
  )
}

export default Actions
