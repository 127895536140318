export const homeSkeleton = [
  {
    'color': 'white',
    'componentType': 'balloon',
    'size': 'big',
    'tipPosition': 'right',
    'list': [
      {
        'category': {
          'button': null,
          'componentType': 'category',
          'data': [
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton1',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton2',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton3',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton4',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton5',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton6',
            },
            {
              'componentType': 'storySkeleton',
              'uuid': 'storySkeleton7',
            },
          ],
          'showMode': 'story',
          'size': 'm',
          'uuid': 'f9e2abfe-f1b8-45e8-a1c8-25ea25439895',
        },
        'componentType': 'horizontalScroll',
        'uuid': 'f9e2abfe-f1b8-45e8-a1c8-25ea25439895',
        'insideBalloon': true,
      },
      {
        'componentType': 'menuSkeleton',
        'uuid': 'b0ea06f0-e983-4318-9b21-d3f6af55aa47',
      },
    ],
    'uuid': '38aa24dc-7ec1-4c48-b43d-d7b5799108e2',
  },
  {
    'color': 'black',
    'componentType': 'balloon',
    'size': 'big',
    'tipPosition': 'left',
    'list': [
      {
        'category': {
          'componentType': 'categorySkeleton',
          'data': [
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton1',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton2',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton3',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton4',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton5',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton6',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton7',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton8',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton9',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton10',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton11',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton12',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton13',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton14',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton15',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton16',
            },
            {
              'componentType': 'squareSkeleton',
              'uuid': 'squareSkeleton17',
            },
          ],
          'title': 'Title',
          'titleColor': 'white',
          'showMode': 'gallery',
          'size': 'm',
          'uuid': 'b08581ac-81cf-4c3e-942f-901a28cc5ee4',
        },
        'componentType': 'horizontalScroll',
        'uuid': 'b08581ac-81cf-4c3e-942f-901a28cc5ee4',
        'insideBalloon': true,
      },
    ],
    'uuid': '5fa5d910-6158-4ce8-a502-5c9e89f56219',
  },
  {
    'button': null,
    'componentType': 'horizontalScroll',
    'layout': 'square',
    'category': {
      'button': null,
      'componentType': 'categorySkeleton',
      'data': [
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton1-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton2-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton3-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton4-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton5-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton6-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton7-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton8-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton9-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton10-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton11-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton12-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton13-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton14-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton15-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton16-1',
        },
        {
          'componentType': 'squareSkeleton',
          'uuid': 'squareSkeleton17-1',
        },
      ],
      'title': 'Title',
      'showMode': 'gallery',
      'titleColor': 'black',
      'size': 'm',
      'uuid': 'b08581ac-81cf-4c3e-942f-901a28cc5ee4',
    },
    'scrollBehaviour': 'many',
    'showMode': 'gallery',
    'size': 'm',
    'uuid': '2f90af25-4ed9-4b49-80b2-111f509f319a',
  },
]
