import css from './LinkedDataList.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { IFormFieldLinkedDataList } from 'meta/form'
import { SelectSearchOption } from 'meta/form/linked'

import { ButtonWhite } from 'components/Actions/Action'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useBackendApiBase } from 'hooks/useEnv'

import { useFormField } from '../..'
import { IFormFieldProps } from '../props'

const LinkedDataList: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, placeholder, getData, options } = formField as IFormFieldLinkedDataList
  const { t } = useTranslation()
  const urlBase = useBackendApiBase()
  const locale = useCurrentLanguage()

  const { setValue } = useFormField(name)

  const disabled = options && options.disable

  const [dataList, setDataList] = useState<Array<SelectSearchOption>>([])
  const [showList, setShowList] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleDataList = async (e: React.FormEvent<HTMLInputElement>) => {
    const query = e.currentTarget.value

    const dataList = await getData(query, {
      urlBase: urlBase,
      locale: locale,
    })

    setDataList(dataList)
    setShowList(true)
  }

  useEffect(() => {
    if (options && options.disable && options.defaultValue) {
      setValue(options.defaultValue)
    }
  }, [])

  useEffect(() => {
    const { options } = formField as IFormFieldLinkedDataList

    const defaultValue = options && options.defaultValue

    //@ts-ignore
    inputRef.current.value = ''

    if (!disabled && defaultValue) {
      setValue('')
    }

    if (disabled && defaultValue) {
      setValue(defaultValue)
    }
  }, [disabled])

  return (
    <div className={css.linked_container}>
      <Field data-testid={name} id={name} name={name} type="hidden" />

      <input
        className={css.outerInput}
        disabled={disabled}
        onChange={handleDataList}
        placeholder={t(`${placeholder}`)}
        ref={inputRef}
      />

      <div className={css.dataList}>
        {!disabled &&
          showList &&
          dataList.map((datum, idx) => (
            <ButtonWhite
              key={String(idx)}
              onClick={() => {
                setValue(datum.value)

                //@ts-ignore
                inputRef.current.value = datum.name

                if (options && options.onChange) {
                  options.onChange(datum.value)
                }

                setShowList(false)
              }}
            >
              <div className={css.elem} dangerouslySetInnerHTML={{ __html: datum.name }} />
            </ButtonWhite>
          ))}
      </div>
    </div>
  )
}

export default LinkedDataList
