import css from './Totals.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CurrencySymbol } from 'meta/currency'
import { ICheckoutRecap } from 'meta/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'

import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutDetails } from 'hooks/useCheckout'

interface IProps {
  recapData: ICheckoutRecap
  continueCallback(): void
}

const Totals: React.FC<IProps> = (props) => {
  const { recapData, continueCallback } = props

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()

  const checkoutDetails = useCheckoutDetails()

  const eventCurrencyCode = getCodeFromSymbol(recapData.total.symbol as CurrencySymbol)

  const convertedAmount = convertAndFormat(recapData.total.amount, eventCurrencyCode, activeCurrency)

  const isCurrencyCodeLong = eventCurrencyCode.length > 1

  const isFreeEvent = !Boolean(checkoutDetails?.eventTicket.data.find((x) => x.priceNumber > 0))

  return (
    <div
      className={classNames(css.checkout_recapbox_mobile_totals_container, {
        [css.longCC]: Boolean(isCurrencyCodeLong),
      })}
    >
      <div className={css.total}>
        <div className={css.label}>{t('checkout:recap_total_mobile')}</div>
        <div className={css.amount}>{`${convertedAmount}`}</div>
      </div>
      <div className={css.buy} onClick={continueCallback}>
        <Img src="/checkout/checkout-mobile-buy-flip.svg" />
        <div className={css.label}>{isFreeEvent ? t('common:sign_up') : t('checkout:submit_label')}</div>
      </div>
    </div>
  )
}

export default Totals
