import css from 'components/Pages/PurchaseForm/Tickets/Recap/Recap.module.scss'
import React, { useEffect, useState } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { ITicket } from 'meta/pages/tickets'

import { useFormContext } from 'components/Forms/Form'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

import { scrollToTicket } from '../utils'
import ButtonStatus, { TicketStatus } from './ButtonStatus'
import ButtonSubmit from './ButtonSubmit'

interface IPropsTicketsRecap {
  tickets: Array<ITicket>
  secondaryTickets: number[]
}

const TicketsRecap: React.FC<IPropsTicketsRecap> = (props) => {
  const { secondaryTickets, tickets } = props
  const { t } = useTranslation()

  const { errors, submitCount } = useFormContext()
  const [smallButton, setSmallButton] = useState<boolean | null>(null)
  const [ticketPopup, setTicketPopup] = useState<{ ticket: ITicket; idx: number } | null>(null)

  const statuses: Array<TicketStatus | null> = tickets.map((ticket, idx) => {
    if (submitCount === 0) return null
    const hasError = Object.keys(errors).find((errorKey: string) => Number(errorKey.split('_')[0]) === idx)
    const inSecondaryTickets = secondaryTickets.includes(idx)
    return hasError || inSecondaryTickets ? TicketStatus.error : TicketStatus.valid
  })

  useEffect(() => {
    const smallButtonOffset = isMobileOnly ? 2 : 3
    setSmallButton(tickets.length > smallButtonOffset)
  }, [tickets, isMobileOnly])

  if (smallButton === null) return null

  return (
    <GridColumn
      className={css.ticketsRecap}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
    >
      <div className={css.ticketsRecap__statusContainer}>
        <Img alt="" className={css.ticketsRecap__statusContainerImgRecap} src="/icons/ticket_recap.svg" />
        <strong className={css.ticketsRecap__statusContainerTitle}>{t('tickets:recap')}</strong>

        <div
          className={classNames(css.ticketsRecap__statusContainerButtons, {
            [css.smallButtons]: smallButton,
          })}
        >
          {tickets.map((ticket, idx) => {
            return (
              <ButtonStatus
                key={String(idx)}
                highlight={smallButton && !isMobile && ticketPopup && ticketPopup.idx === idx}
                idx={idx}
                onMouseEnter={() => {
                  smallButton && !isMobile && setTicketPopup({ ticket, idx })
                }}
                small={smallButton}
                status={statuses[idx]}
                ticket={ticket}
              />
            )
          })}
        </div>

        {ticketPopup && (
          <div className={css.ticketsRecapPopup} onMouseLeave={() => setTicketPopup(null)}>
            <div
              className={css.ticketsRecapPopup__content}
              onClick={() => {
                scrollToTicket(ticketPopup.ticket, ticketPopup.idx)
                setTicketPopup(null)
              }}
            >
              <h5>{ticketPopup.idx + 1}</h5>
              <div>{ticketPopup.ticket.title}</div>
              <div>{ticketPopup.ticket.details}</div>

              {statuses[ticketPopup.idx] && (
                <Img
                  alt=""
                  className={css.ticketsRecapPopup__ImgStatus}
                  src={
                    statuses[ticketPopup.idx] === TicketStatus.valid
                      ? `/textboxes/textboxes_success.svg`
                      : `/textboxes/textboxes_error.svg`
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>

      <ButtonSubmit tickets={tickets} />
    </GridColumn>
  )
}

export default TicketsRecap
