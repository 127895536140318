import css from './CollectionBalloon.module.scss'
import React, { PropsWithChildren } from 'react'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BalloonBgPosition, BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_BLACK } from 'utils/constants/colors'

import Img from 'components/Media/Img'

interface IPropsBalloon {
  backgroundUrl?: string
  backgroundPosition?: BalloonBgPosition
  className?: string
  color: ComponentColor
  containsMenu?: boolean
  insideVerticalScroll?: boolean
  size: BalloonSize
  tipPosition: BalloonTipPosition
  noPadding?: boolean
}

const CollectionBalloon: React.FC<PropsWithChildren<IPropsBalloon>> = (props) => {
  const { backgroundUrl, backgroundPosition, children, className, containsMenu, color, size, tipPosition, noPadding } =
    props

  const getBgColor = (color: ComponentColor) => {
    switch (color) {
      case ComponentColor.black:
        return TICKETSMS_COLORS_BLACK
      default:
        return ComponentColor.white
    }
  }

  const bgColor = getBgColor(color)
  const extension = 'png'
  const bgDesktop = `linear-gradient(0deg, ${bgColor} 0%, transparent 30%, transparent 60%, ${bgColor} 105%), url('${backgroundUrl}') no-repeat`
  const bgMobile = `linear-gradient(0deg, ${bgColor} 10%, ${bgColor}40 20%, ${bgColor}40 80%, ${bgColor} 90%)`

  return (
    <div className={classNames(css.balloonContainer, className)}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
        <div
          className={classNames(css.balloon, css[`balloonColor_${color}`], css[`balloonTip_${tipPosition}`], {
            [css.containsMenu]: containsMenu,
            [css.nopadding]: noPadding,
            [css.medium]: size === BalloonSize.medium,
          })}
        >
          <div className={css.balloonChildren}>{React.Children.toArray(children)}</div>
          <div className={css.blurBg} style={{ background: bgMobile }}></div>
          <img className={css.imgBg} src={backgroundUrl} />
        </div>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
        <div
          className={classNames(css.balloon, css[`balloonColor_${color}`], css[`balloonTip_${tipPosition}`], {
            [css.containsMenu]: containsMenu,
            [css.nopadding]: noPadding,
            [css.medium]: size === BalloonSize.medium,
          })}
          style={backgroundUrl ? { background: bgDesktop, backgroundSize: 'cover', backgroundPosition } : undefined}
        >
          {React.Children.toArray(children)}
        </div>
      </MediaQuery>

      <div
        className={classNames(css[`balloonTipContainer_${tipPosition}`], { [css.medium]: size === BalloonSize.medium })}
      >
        <Img src={`/baloon/tip_${color}_${tipPosition}.${extension}`} />
      </div>
    </div>
  )
}

CollectionBalloon.defaultProps = {
  backgroundUrl: undefined,
  insideVerticalScroll: false,
  noPadding: false,
}

export default CollectionBalloon
