import css from './Company.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'
import { useUrlBusiness } from 'hooks/useEnv'

interface Props {}

const Company: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1, [Breakpoints.laptop]: 1 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 2, [Breakpoints.laptop]: 2 }}
        className={`${css.footerCompany} ${css.firstElem}`}
      >
        <a title={t('footer:about')} href={`${useUrlBusiness()}/about`} target="_blank" rel="noreferrer">
          {t('footer:about')}
        </a>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 2, [Breakpoints.laptop]: 2 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 9, [Breakpoints.tablet]: 3, [Breakpoints.laptop]: 3 }}
        className={css.footerCompany}
      >
        <a title={t('footer:business')} href={`${useUrlBusiness()}`} target="_blank" rel="noreferrer">
          {t('footer:business')}
        </a>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 9, [Breakpoints.tablet]: 3, [Breakpoints.laptop]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
        className={css.footerCompany}
      >
        <a title={t('footer:careers')} href="https://angel.co/company/ticketsms" target="_blank" rel="noreferrer">
          {t('footer:careers')}
        </a>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 5, [Breakpoints.laptop]: 5 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:faq')} to="/faq">
          {t('footer:faq')}
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 5, [Breakpoints.laptop]: 5 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 6 }}
        className={`${css.footerCompany} ${css.oneline}`}
      >
        <a title={t('footer:press-kit')} href={`${useUrlBusiness()}/press-kit`}>
          {t('footer:press-kit')}
        </a>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 6 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 9, [Breakpoints.tablet]: 7, [Breakpoints.laptop]: 7 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:governance')} to="/governance">
          Governance
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 9, [Breakpoints.tablet]: 7, [Breakpoints.laptop]: 7 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 8, [Breakpoints.laptop]: 8 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:privacy')} to="/privacy">
          {t('footer:privacy')}
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 8, [Breakpoints.laptop]: 8 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 15, [Breakpoints.tablet]: 9, [Breakpoints.laptop]: 9 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:resale')} to="/resale">
          {t('footer:resale')}
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 9, [Breakpoints.laptop]: 9 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 10, [Breakpoints.laptop]: 10 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:change-name')} to="/change-name">
          {t('footer:change-name')}
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 6, [Breakpoints.tablet]: 10, [Breakpoints.laptop]: 10 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 9, [Breakpoints.tablet]: 11, [Breakpoints.laptop]: 12 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:cookie-policy')} to="/cookie-policy">
          {t('footer:cookie-policy')}
        </Link>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 10, [Breakpoints.tablet]: 11, [Breakpoints.laptop]: 12 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 14 }}
        className={css.footerCompany}
      >
        <Link title={t('footer:terms-and-conditions')} to="/terms">
          {t('footer:terms-and-conditions')}
        </Link>
      </GridColumn>
    </>
  )
}

export default Company
