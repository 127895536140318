import css from './StepCommon.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'

type Props = {
  labelKey: string
}

export const StepLabel: React.FC<Props> = (props) => {
  const { labelKey } = props

  const { t } = useTranslation()

  return (
    <GridColumn
      gridColumnStart={2}
      gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 10 }}
      className={css.loginStepLabel}
    >
      <h2 dangerouslySetInnerHTML={{ __html: String(t(labelKey)) }} />
    </GridColumn>
  )
}
