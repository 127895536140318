import { BackendControlledComponentsArray, BackendControlledMetadata } from 'meta/backendControlled/structure'
import { useAppSelector } from 'store'
import { BackendControlledSelectors } from 'store/backendControlled'

import { eventSkeleton } from 'hooks/useBackendControlled/event'
import { homeSkeleton } from 'hooks/useBackendControlled/home'
import { venueSkeleton } from 'hooks/useBackendControlled/venue'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

type Props = {
  path: string
}

const skeletons: Record<string, BackendControlledComponentsArray> = {
  // @ts-ignore
  'home': homeSkeleton,
  // @ts-ignore
  'events': eventSkeleton,
  // @ts-ignore
  'venue': venueSkeleton,
}

export const useBackendControlledComponents = (props: Props): BackendControlledComponentsArray => {
  const { path } = props

  const language = useCurrentLanguage()

  const components = useAppSelector(BackendControlledSelectors.getComponents({ path: `${path}/${language}` }))
  const page = path.split('/')[1]

  return components ?? skeletons[page]
}

export const useBackendControlledComponentsMetadata = (props: Props): BackendControlledMetadata | undefined => {
  const { path } = props

  const language = useCurrentLanguage()

  return useAppSelector(BackendControlledSelectors.getComponentsMetadata({ path: `${path}/${language}` }))
}
