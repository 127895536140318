import css from './Artist.module.scss'
import React from 'react'

import { IArtist } from 'meta/backendControlled/structure'

import { useUrlCloudfront } from 'hooks/useEnv'

import { IPropsComponent } from '../index'

const Artist: React.FC<IPropsComponent<IArtist>> = (props) => {
  const { imageUrl, name } = props

  const urlCloudfront = useUrlCloudfront()
  const backgroundImage = imageUrl
    ? `url('${imageUrl}')`
    : `url('${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg')`

  return (
    <div className={css.structure_showcase_artist_container}>
      <div className={css.image} style={{ backgroundImage }} />
      <div className={css.name}>{name}</div>
    </div>
  )
}

export default Artist
