import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Languages } from 'meta/app/translate'
import { MIMETypes } from 'utils/mimeTypes'

import Head from 'components/common/Head'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useUrlCloudfront } from 'hooks/useEnv'

const MetaI18N: React.FC = () => {
  const { t } = useTranslation()
  const language = useCurrentLanguage()
  const urlCloudfront = useUrlCloudfront()

  const title = t('header:title_/')
  const description = t('common:head_og_description')
  const logoUrl = `${urlCloudfront}/images/logo/TicketSms_logo.svg`

  return (
    <Head>
      <meta httpEquiv="content-language" content={language} />
      <meta name="language" content={language} />

      <meta name="keywords" content={t('header:keywords')} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />

      <meta property="og:image" content={logoUrl} />
      <meta property="og:image:secure_url" content={logoUrl} />
      <meta property="og:image:type" content={MIMETypes.getMIMEType({ fileName: logoUrl })} />
      <meta property="og:image:alt" content={title} />

      <meta property="og:locale" content={Languages[language].locale} />

      {Object.keys(Languages).map((ln) => {
        if (ln !== language) {
          const { locale } = Languages[ln]
          const property = `og:locale:alternate`
          return <meta key={`${property}_${locale}`} property={property} content={locale} />
        }
        return null
      })}

      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
    </Head>
  )
}

export default memo(MetaI18N)
