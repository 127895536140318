import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useBasePath } from 'hooks/useBasePath'

export const useTitle = (): string => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const basePath = useBasePath()

  return useMemo<string>(() => {
    let title = t(`header:title_${basePath}`)
    if (pathname !== '/') title += ' | TicketSms.it'
    return title
  }, [basePath, pathname, t])
}
