import css from './Action.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export enum ActionAlignment {
  center = 'center',
  iconRight = 'iconRight',
  iconCenter = 'iconCenter',
}

export enum ActionColor {
  black = 'black',
  grey = 'grey',
  red = 'red',
  transparent = 'transparent',
  white = 'white',
}

export enum ActionFormat {
  circle = 'circle',
  fullWidth = 'fullWidth',
  regular = 'regular',
  unset = 'unset',
}

export enum ActionSize {
  m = 'm',
  s = 's',
}

export interface IPropsAction {
  alignment?: ActionAlignment
  backgroundColor?: ActionColor
  borderColor?: ActionColor
  className?: string
  color?: ActionColor
  disabled?: boolean
  element?: 'a' | 'button'
  format?: ActionFormat
  hover?: ActionColor
  size?: ActionSize
  onClick?: () => void
  [key: string]: any
}

export const Action: React.FC<IPropsAction> = (props) => {
  const {
    alignment,
    backgroundColor,
    borderColor,
    children,
    className: classNameProps,
    color,
    disabled,
    element: Element,
    format,
    hover,
    size,
    ...rest
  } = props

  const className = classNames(
    css.action,
    css[`actionAlignment_${alignment}`],
    css[`actionBackgroundColor_${backgroundColor}`],
    css[`actionBorderColor_${borderColor}`],
    css[`actionColor_${color}`],
    css[`actionFormat_${format}`],
    css[`actionHover_${hover}`],
    css[`actionSize_${size}`],
    { [css.disabled]: disabled },
    classNameProps
  )

  return Element === 'a' ? (
    <Link className={className} {...rest} to={props.href || ''}>
      {React.Children.toArray(children)}
    </Link>
  ) : (
    <button className={className} disabled={disabled} {...rest}>
      {React.Children.toArray(children)}
    </button>
  )
}

Action.defaultProps = {
  alignment: ActionAlignment.center,
  backgroundColor: ActionColor.white,
  borderColor: ActionColor.black,
  className: '',
  color: ActionColor.black,
  disabled: false,
  element: 'button',
  format: ActionFormat.regular,
  size: ActionSize.m,
}
