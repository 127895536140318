import css from './EventFeesDialog.module.scss'
import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import { TicketPrices } from 'meta/pages/tickets'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import withTranslation from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import { useVisibleEventFees, useVisibleEventFeesCodeUrl } from 'hooks/useEvent'

import { useClose } from './hooks/useClose'
import { useCloseOnRouteUpdate } from './hooks/useCloseOnRouteUpdate'
import { useHandleBodyScroll } from './hooks/useHandleBodyScroll'

const namespaces = ['wallet']

const EventFeesDialog: React.FC = () => {
  const { t } = useTranslation()
  const codeUrl = useVisibleEventFeesCodeUrl()
  const eventFees = useVisibleEventFees()
  useHandleBodyScroll()
  useCloseOnRouteUpdate()
  const close = useClose()

  const withSector = useMemo<boolean>(() => Boolean(eventFees && eventFees[0].sectorLabel), [eventFees])

  if (!codeUrl) return null

  return (
    <div className={css.feesDialog} data-modalclose="skip">
      <OutsideClickHandler onOutsideClick={close}>
        <button className={css.buttonClose} onClick={close}>
          <Img src="/icons/close_icon.svg" />
        </button>

        <h3>{t('checkout:priceDetail')}</h3>
        <div className={css.description}>{t('checkout:priceDetailDescription')}</div>

        <div className={css.feesContainer}>
          {eventFees && (
            <>
              <div className={classNames(css.feesHeader, { [css.withSector]: withSector })}>
                {withSector && <h5>{t('checkout:sector')}</h5>}
                <h5>{t('checkout:ticket')}</h5>
                <h5>{t('checkout:price_title')}</h5>
                <h5>{t('wallet:presale')}</h5>
                <h5>{t('checkout:recap_commissions')}</h5>
              </div>

              <div className={classNames(css.fees, { [css.withSector]: withSector })}>
                {eventFees.map((eventFee, index) => (
                  <Fragment key={`${eventFee.notes}_${index}`}>
                    {withSector && <div>{eventFee.sectorLabel}</div>}
                    <div>{eventFee.notes}</div>
                    <div>{TicketPrices.format(eventFee.price)}</div>
                    <div>{TicketPrices.format(eventFee.presale)}</div>
                    <div>{TicketPrices.format(eventFee.commission)}</div>
                  </Fragment>
                ))}
              </div>
            </>
          )}

          {!eventFees && (
            <Skeleton
              baseColor={TICKETSMS_COLORS_WHITE}
              borderRadius={12}
              className={css.skeleton}
              count={4}
              height={32}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
              inline
            />
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default withTranslation(namespaces)(EventFeesDialog)
