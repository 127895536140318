import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { LanguageCode } from 'meta/app/translate'

type Returned = (lang: LanguageCode) => Promise<void>

export const useChangeLanguage = (): Returned => {
  const { i18n } = useTranslation()

  return useCallback(
    async (lang: LanguageCode) => {
      await i18n.changeLanguage(lang)
    },
    [i18n]
  )
}
