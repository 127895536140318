import css from './EventCollectionMenu.module.scss'
import React from 'react'

import { IEventCollectionMenu } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

import { getComponentId } from '../utils'
import { IPropsComponent } from '..'
import EventDateList from './EventDateList/EventDateList'

const EventCollectionMenu: React.FC<IPropsComponent<IEventCollectionMenu>> = (props) => {
  const { uuid, title } = props

  const onDateChange = (codeUrl: string) => {
    window.location.href = `/event/${codeUrl}`
  }

  const castData = props as IEventCollectionMenu

  return (
    <div className={css.event_collection_menu_container}>
      <GridContainer id={getComponentId(uuid)}>
        <Grid>
          <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
            <h2>{title}</h2>
          </GridColumn>
        </Grid>
        <Grid>
          <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
            <EventDateList data={castData} onDateChange={onDateChange} />
          </GridColumn>
        </Grid>
      </GridContainer>
    </div>
  )
}

export default EventCollectionMenu
