import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CheckoutActions } from 'store/pages/checkout'
import { initSeats } from 'store/pages/event/actions/initSeats'
import { showEventFees } from 'store/pages/event/actions/showEventFees'
import { initialState } from 'store/pages/event/state'

import { fetchMetadata } from '../actions/fetchMetadata'

export const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setFreeEvent: (state, action: PayloadAction<{ isEventFree: boolean }>) => {
      const { isEventFree } = action.payload
      return {
        ...state,
        isEventFree: isEventFree,
      }
    },
    setEndedEvent: (state, action: PayloadAction<{ isEventEnded: boolean }>) => {
      const { isEventEnded } = action.payload
      return {
        ...state,
        isEventEnded: isEventEnded,
      }
    },
    closeEventFees: (state) => {
      state.fees.visibleEventCodeUrl = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initSeats.fulfilled, (state, action) => {
      const { id, sectionsEnabled, sectors, seatsBusy, linkedSeats } = action.payload

      const seats = state.seats[id] || {}

      return {
        ...state,
        seats: { ...state.seats, [id]: { ...seats, seatsBusy, linkedSeats, sectionsEnabled, sectors } },
      }
    })

    builder.addCase(showEventFees.pending, (state, action) => {
      const { codeUrl, typeTicketCollectionId } = action.meta.arg
      state.fees.visibleEventCodeUrl = codeUrl
      state.fees.visibleEventTypeTicketCollectionId = typeTicketCollectionId
    })

    builder.addCase(showEventFees.fulfilled, (state, action) => {
      const { codeUrl } = action.meta.arg
      state.fees.events[codeUrl] = action.payload
    })

    builder.addCase(fetchMetadata.fulfilled, (state, action) => {
      const { metadata } = action.payload
      state.metadata = metadata
    })

    builder.addCase(CheckoutActions.close, (state) => {
      delete state.seats['checkout']
    })
  },
})
