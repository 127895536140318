import i18n from 'i18n'

const defaultFormatOptions: Intl.NumberFormatOptions = {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const format = (number: number, options?: Intl.NumberFormatOptions): string => {
  return new Intl.NumberFormat(i18n.language, options ?? defaultFormatOptions).format(number)
}

const getRandomInRange = (from: number, to: number, fixed: number = 0): number => {
  const random = (Math.random() * (to - from) + from).toFixed(fixed)
  return Number(random)
}

const padStart = (num: number, totalLength: number) => {
  return String(num).padStart(totalLength, '0')
}

export const Numbers = {
  format,
  getRandomInRange,
  padStart,
}
