import css from './CalendarButton.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { BalloonSize, BalloonTipPosition, ComponentColor, IEventCollectionMenu } from 'meta/backendControlled/structure'
import { mapCollectionByDay } from 'meta/backendControlled/structureBackend/adapter/eventCollectionMenu'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { Dates } from 'utils/dates'

import { DatePicker } from 'components/Forms/DatePicker'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'
import Img from 'components/Media/Img'

interface IProps {
  data: IEventCollectionMenu

  onDateChange(codeUrl: string): void
}

const CalendarButton: React.FC<IProps> = (props) => {
  const { onDateChange, data } = props

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const listByDay = mapCollectionByDay(data.rawList)

  const dates: Array<Date> = Object.values(listByDay).reduce<Array<Date>>((datesAgg, elem) => {
    if (elem[0]) datesAgg.push(elem[0].datetime as Date)
    return datesAgg
  }, [])
  const minDate = Dates.min(dates)
  const maxDate = Dates.max(dates)
  const disabledDates = Dates.getDisabledDate(dates)

  const handleDateSelection = (date: Date) => {
    const dateString = Dates.format(date, t('dates:common:yearMonthDay'))
    if (listByDay[dateString]) {
      onDateChange(listByDay[dateString][0].codeUrl)
    }
  }

  return (
    <div className={css.event_date_list_calendarbutton_container}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        <div className={css.content} onClick={() => !isOpen && setIsOpen(true)}>
          <div className={css.elem}>
            <h4 className={css.text}>{t('common:calendar_mobile')}</h4>
            <Img alt="" src="/icons/calendar_white_mobile.svg" />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
        <div className={css.content} onClick={() => !isOpen && setIsOpen(true)}>
          <Img alt="" src="/icons/calendar_black.svg" />
          <div className={css.text}>{t('common:calendar')}</div>
        </div>
      </MediaQuery>

      {isOpen && (
        <Modal handleClose={() => setIsOpen(false)} hideCloseIcon transparent show>
          <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
            <h3 className={css.chooseDate}>{t('common:choose_date')}</h3>
            <DatePicker
              disabledDates={disabledDates}
              removePadding
              minimumDate={minDate}
              maximumDate={maxDate}
              date={data.current.datetime as Date}
              onChange={(date: Date) => {
                handleDateSelection(date as Date)
                setIsOpen(false)
              }}
            />
          </BalloonWrapper>
        </Modal>
      )}
    </div>
  )
}

export default CalendarButton
