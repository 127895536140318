import { IMap, IMapSeat, IMapSectors } from '../map'
import { MapEvent, MapEventSubscriber, MapViewerMediator } from '../mapViewer'
import { DVMMap2DViewer } from './map2DViewer'
import { DVMMap3DViewer } from './map3DViewer'

export class DVMMapViewerMediator implements MapViewerMediator {
  readonly map2DViewer: DVMMap2DViewer
  // @ts-ignore
  readonly map3DViewer: DVMMap3DViewer
  // @ts-ignore
  private highlightNodeId: string
  private readonly map: IMap
  private readonly persistSelectSeat: boolean
  private readonly subscribers: { [event in MapEvent]?: Array<MapEventSubscriber> }
  private readonly disabledNotifications: { [event in MapEvent]?: boolean }

  constructor(map: IMap, persistSelectSeat: boolean, sectors?: IMapSectors) {
    this.map = map
    this.persistSelectSeat = persistSelectSeat
    this.subscribers = {}
    this.disabledNotifications = {}
    this.map2DViewer = new DVMMap2DViewer(this.getMap2DCallbacks(), this, sectors)
    if (this.map.type === '3D') this.map3DViewer = new DVMMap3DViewer()
  }

  // https://docs.3ddvapis.com/js/dvm/modules/map_viewer/1.concepts/#callbacks
  getMap2DCallbacks(): any {
    return {
      click: this.onClick.bind(this),
      enter: this.onEnter.bind(this),
      leave: this.onLeave.bind(this),
      // @ts-ignore
      layer_level_changed: (obj) => {
        const { layer_level } = obj
        this.map2DViewer.setLevel(layer_level)
        if (layer_level === 0) {
          this.notify(MapEvent.SECTION_SELECT, null)
        }
      },
      // @ts-ignore
      select: (obj) => {
        const node = obj.nodes[0]
        const mapSeat: IMapSeat = { seatId: node.id, sectionId: node.parent }
        this.notify(MapEvent.SEAT_SELECT, mapSeat)
        // this.options.onSeatSelect({ seatId: node.id, sectionId: node.parent })
        if (!this.persistSelectSeat) {
          this.map2DViewer.unselectSeat(mapSeat.seatId)
        }
        this.show2DViewer()
      },
      zooming: () => {
        if (this.highlightNodeId) {
          this.onLeave()
        }
      },
    }
  }

  async init(container2D: HTMLDivElement, container3D?: HTMLDivElement): Promise<void> {
    await Promise.all([
      this.map2DViewer.loadView(this.map, container2D),
      // @ts-ignore
      this.map3DViewer ? this.map3DViewer.init(container3D) : null,
    ])
    this.show2DViewer(true)
  }

  show2DViewer(resetZoom?: boolean): void {
    if (this.map3DViewer) {
      this.map3DViewer.hide()
      this.notify(MapEvent.SEAT_3D_PREVIEW_CHANGE, null)
    }
    this.map2DViewer.show(resetZoom)
  }

  show3DViewer(): void {
    if (this.map3DViewer) {
      this.map2DViewer.hide()
      this.map3DViewer.show()
    }
  }

  enableNotification(event: MapEvent): void {
    delete this.disabledNotifications[event]
  }

  disableNotification(event: MapEvent): void {
    this.disabledNotifications[event] = true
  }

  subscribe(event: MapEvent, subscriber: MapEventSubscriber) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = new Array<MapEventSubscriber>()
    }
    // @ts-ignore
    this.subscribers[event].push(subscriber)
  }
  // @ts-ignore
  notify(event: MapEvent, ...args): void {
    if (this.disabledNotifications[event]) return

    const eventSubscribers = this.subscribers[event]
    if (eventSubscribers && eventSubscribers.length > 0) {
      eventSubscribers.forEach((subscriber) => subscriber(...args))
    }
  }

  async onClick(obj: any): Promise<void> {
    const node = obj.nodes[0]
    if (!node) return

    if (node.type === 'section') {
      this.map2DViewer.viewer.goTo(obj.point.scene, 8)
      this.notify(MapEvent.SECTION_SELECT, { sectionId: node.id })
    } else if (node.type === 'seat') {
      if (node.state === 'available') {
        if (this.map2DViewer.getSelectedSeats().length === 10) {
          alert('È possibile selezionare un massimo di 10 posti.')
          return
        }
        if (this.map3DViewer) {
          this.show3DViewer()
          const seat3DPreview: IMapSeat = { seatId: node.id, sectionId: node.parent }
          this.notify(MapEvent.SEAT_3D_PREVIEW_CHANGE, seat3DPreview)
          await this.map3DViewer.loadView(this.map, seat3DPreview)
          // await this.loadView3d(obj3d, node)
        } else {
          this.map2DViewer.selectSeat(node.id)
        }
      }
      // else if (node.state === 'selected') {
      //     this.map2DViewer.unselectSeat(node.id)
      // }
    }
  }

  onEnter(obj: any): void {
    const node = obj.nodes[0]
    if (node.type === 'seat' && ['available', 'selected'].includes(node.state)) {
      this.highlightNodeId = node.id
      const clientRect: DOMRectReadOnly = node.getBoundingClientRect()
      this.notify(MapEvent.SEAT_HIGHLIGHT, { clientRect, seatId: node.id, sectionId: node.parent } as IMapSeat)
    }
  }

  onLeave(): void {
    // @ts-ignore
    this.highlightNodeId = null
    this.notify(MapEvent.SEAT_HIGHLIGHT, null)
  }
}
