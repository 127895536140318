import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { atcb_action } from 'add-to-calendar-button'

import { DataDogLogType, LOGS_EVENT_CALENDAR_ADD } from 'meta/logger'
import { DataDogLogger } from 'helpers/debug/logger'
import { Dates } from 'utils/dates'

import { CalendarType } from 'components/BackendControlled/Component/EventDetails/Infos/Countdown/AddToCalendarModal/AddToCalendarModal.types'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useEventDetails, useEventInfo, useEventVenueInfo } from 'hooks/useEvent'

type Props = {
  calendarList: CalendarType[]
  codeUrl: string
}

export const useButtonsClickListener = (props: Props) => {
  const { calendarList, codeUrl } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()

  const { address } = useEventVenueInfo({ codeUrl, language })
  const eventDetails = useEventDetails({ codeUrl, language })
  const { endDatetime } = useEventInfo({ codeUrl, language })

  // eslint-disable-next-line @typescript-eslint/ban-types
  const globalCalendarButtonConfig: Object = useMemo(() => {
    if (!eventDetails?.datetime) return {}
    const description = `${eventDetails?.name}\n${eventDetails.location?.name} / ${eventDetails.location?.common}`

    const endDate = Dates.format(new Date(endDatetime), t('dates:common:yearMonthDay'))
    const endTime = Dates.format(new Date(endDatetime), t('dates:common:hoursMinutes'))
    const startDate = Dates.format(new Date(eventDetails.datetime), t('dates:common:yearMonthDay'))
    const startTime = Dates.format(new Date(eventDetails.datetime), t('dates:common:hoursMinutes'))

    return {
      description,
      endDate: `${endDate}`,
      endTime: `${endTime}`,
      hideBackground: true,
      hideButton: true,
      iCalFileName: `${eventDetails?.name}`,
      location: `${address}`,
      name: `${eventDetails?.name}`,
      startDate: `${startDate}`,
      startTime: `${startTime}`,
      timeZone: 'Europe/Rome', // TODO: in futuro cambiare con timeZone dell'evento (per eventi esteri)
      trigger: 'click',
    }
  }, [address, endDatetime, eventDetails])

  const handleButtonClick = useCallback(
    (buttonElement: HTMLElement, event: MouseEvent, type: CalendarType) => {
      event.stopImmediatePropagation()

      // eslint-disable-next-line @typescript-eslint/ban-types
      const config: Object = { ...globalCalendarButtonConfig, options: [type] }

      if (eventDetails?.datetime) {
        const eventDate = Dates.format(new Date(eventDetails.datetime), 'yyyy-MM-dd HH:mm')

        DataDogLogger(LOGS_EVENT_CALENDAR_ADD, DataDogLogType.info, {
          calendarType: type,
          eventTitle: eventDetails?.name,
          eventDate: eventDate,
          eventCodeUrl: codeUrl,
        })
      }

      atcb_action(config, buttonElement)
    },
    [codeUrl, eventDetails?.name, eventDetails?.datetime, globalCalendarButtonConfig]
  )

  useEffect(() => {
    calendarList.forEach((elem) => {
      const button = document.getElementById(elem)
      if (button) {
        button.addEventListener('click', (e) => handleButtonClick(button, e, elem))
      }
    })
  }, [calendarList, handleButtonClick])
}
