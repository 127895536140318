import css from './InstaStory.module.scss'
import React, { MutableRefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { AssetType, IAsset } from 'meta/backendControlled/structure'

import { ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import Img from 'components/Media/Img'

import { StoryProps } from './props'
import { useStoryState } from './state'

const InstaStory: React.FC<StoryProps> = (props) => {
  const { active, laptop, story, style } = props

  const { t } = useTranslation()

  const storyRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>
  const progressRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>
  const swipeUpRef = useRef<HTMLButtonElement>(null) as MutableRefObject<HTMLButtonElement>
  const videoRef = useRef<HTMLVideoElement>(null) as MutableRefObject<HTMLVideoElement>
  const { idxActive, playActions } = useStoryState(props, storyRef, progressRef, swipeUpRef, videoRef)

  const { gallery } = story
  const item: IAsset = gallery[idxActive || 0]

  return (
    <div className={classNames(css.instaStory, { [css.active]: active })} style={style}>
      <div className={css.instaStory__progress} ref={progressRef}>
        {gallery.map((item, idx) => (
          <div key={String(idx)} className={css.instaStory__progressItem} style={{ width: `${100 / gallery.length}%` }}>
            <div className={css.instaStory__progressItemShow} />
          </div>
        ))}
      </div>

      <div className={css.instaStory__mediaWrapper} ref={storyRef}>
        {item.type === AssetType.image ? (
          <img src={item.src[0].url} />
        ) : (
          <video controls={false} playsInline preload="auto" ref={videoRef}>
            {item.src
              .filter((source) => source.mimeType?.startsWith('video'))
              .map((source) => (
                <source key={source.uuid} src={`${source.url}#t=0.1`} type={source.mimeType} />
              ))}
          </video>
        )}
      </div>

      {active && laptop && (
        <>
          <ButtonTransparent
            className={css.instaStory__btnPrev}
            format={ActionFormat.circle}
            onClick={playActions.playPrevItem}
          >
            <Img src="/carousel/controls/prev.png" />
          </ButtonTransparent>
          <ButtonTransparent
            className={css.instaStory__btnNext}
            format={ActionFormat.circle}
            onClick={playActions.playNextItem}
          >
            <Img src="/carousel/controls/next.png" />
          </ButtonTransparent>
        </>
      )}

      {story.url && (
        <>
          <a className={css.instaStory__btnShowMore} href={story.url}>
            <h5>{t('common:show_more')}</h5>
          </a>

          <button className={css.instaStory__btnSwipeUp} ref={swipeUpRef}>
            <Img src="/icons/swipeup-white.gif" />
          </button>
        </>
      )}
    </div>
  )
}

export default InstaStory
