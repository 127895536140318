import css from './BalloonBoxModal.module.scss'
import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { TextBoxInfoTypes } from 'types/layout'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Img from 'components/Media/Img'

interface Props {
  bgColor?: ComponentColor
  infoType: TextBoxInfoTypes
  title?: string
  onClose: () => void
}

const BalloonBoxModal: React.FC<PropsWithChildren<Props>> = (props) => {
  const { bgColor, infoType, title, onClose, children } = props

  return ReactDOM.createPortal(
    <div className={css.balloonBoxModal} data-modalclose="skip">
      <BalloonWrapper color={bgColor as ComponentColor} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
        <ButtonTransparent
          format={ActionFormat.circle}
          onClick={onClose}
          className={css.balloonBoxModal__btnClose}
          hover={ActionColor.transparent}
        >
          <Img src="/icons/close_icon_white.svg" />
        </ButtonTransparent>
        <div className={css.balloonBoxModal__container}>
          <Img src={`/textboxes/textboxes_${infoType}.svg`} className={css.balloonBoxModal__img} />
          <div>
            {title && (
              <div>
                <strong>{title}</strong>
              </div>
            )}
            {React.Children.toArray(children)}
          </div>
        </div>
      </BalloonWrapper>
    </div>,
    document.getElementById('root')?.children[0] as HTMLElement
  )
}

export default BalloonBoxModal
