import css from './Email.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormBuilder, PasswordBuilder, TextBuilder } from 'meta/form'
import { LoginProvider, LoginStep } from 'meta/pages/login'
import { Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import { ActionColor, Button, Link } from 'components/Actions/Action'
import Form from 'components/Forms/Form'
import { useCheckoutDetails } from 'hooks/useCheckout'

import { IStepProps, Step, StepColumn } from '../StepCommon'

const Email: React.FC<IStepProps> = (props) => {
  const { onSuccess } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const formFields = new FormBuilder()
    .addField(new TextBuilder('email').addRequiredEmailValidation().addPlaceholder('form:email').build())
    .addField(new PasswordBuilder('password').addRequiredValidation().addPlaceholder('form:password').build())
    .build()

  //@ts-ignore
  const bodyParser = (fieldValues) => ({ provider: LoginProvider.TICKETSMS, providerData: fieldValues })

  const details = useCheckoutDetails()
  const loginActionPath = details?.insideIFrame ? ApiEndpoint.users.loginFromIframe() : ApiEndpoint.users.login()

  return (
    <>
      <Step icon="/login/step_email.svg" labelKey="loginV2:insert_email_password">
        <Form
          action={loginActionPath}
          bodyParser={bodyParser}
          formFields={formFields}
          labelSubmit="loginV2:submit_label"
          onSuccess={(body, response) => {
            const { token } = response.data
            onSuccess(token)
          }}
        />
      </Step>

      <StepColumn className={css.loginEmail__register}>
        <Button
          color={ActionColor.white}
          backgroundColor={ActionColor.transparent}
          borderColor={ActionColor.transparent}
          onClick={() => {
            dispatch(LoginActions.updateStep({ step: LoginStep.phone }))
          }}
        >
          <div>{t('loginV2:register_link')}</div>
        </Button>
      </StepColumn>

      <StepColumn className={css.loginEmail__forgotPwd}>
        <Link
          href={Routes.UsersPasswordReset.generatePath()}
          color={ActionColor.white}
          backgroundColor={ActionColor.transparent}
          onClick={() => {
            dispatch(LoginActions.reset())
          }}
        >
          <div>{t('loginV2:reset_password_link')}</div>
        </Link>
      </StepColumn>
    </>
  )
}

export default Email
