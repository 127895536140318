import css from '../Social.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'
import { addHttpsToUrlIfMissing } from 'utils/string'

import Img from 'components/Media/Img'

interface IPropsButtonFacebook {
  color: ComponentColor
  facebookLink?: string
}

export const ButtonFacebook: React.FC<IPropsButtonFacebook> = (props) => {
  const { color, facebookLink } = props

  if (!facebookLink) return null

  return (
    <a
      href={addHttpsToUrlIfMissing(facebookLink)}
      className={classNames(css.socialLink, css[color])}
      target="_blank"
      rel="noreferrer"
    >
      <Img alt="" src={`/social/facebook_${color}.svg`} />
    </a>
  )
}
