import css from './FormFieldError.module.scss'
import React from 'react'
import classNames from 'classnames'

import Img from '../../../../Media/Img'

type Props = {
  error: string
  name: string
}

const FormFieldError: React.FC<Props> = (props) => {
  const { error, name } = props

  const longErr = error.length > 30

  return (
    <div data-testid={`${name}-error`} className={classNames(css.formFieldError, { [css.longErr]: longErr })}>
      <Img alt="" src="/form/error.svg" />
      <div>{error}</div>
    </div>
  )
}

export default FormFieldError
