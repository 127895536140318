import { useEffect } from 'react'

import { useUrlCloudfront } from 'hooks/useEnv'

const assets = {
  'actions-facebook': '/icons/actions_facebook.svg',
  'actions-geoloc': '/icons/actions_map.svg',
  'actions-instagram': '/icons/actions_instagram.svg',
  'actions-phone': '/icons/actions_phone.svg',
  'actions-share': '/icons/actions_share.svg',
  'actions-whatsapp': '/icons/actions_whatsapp.svg',
  'arrow-down': '/icons/arrow.svg',
  'arrow-up': '/icons/arrow_up.svg',
  'banner-search-mobile': '/banner/banner_2024_search_mobile.png',
  'banner-search-tablet-portrait': '/banner/banner_2024_search_tablet_portrait.png',
  'banner-search-tablet-landscape': '/banner/banner_2024_search_tablet_landscape.png',
  'banner-search-laptop': '/banner/banner_2024_search_laptop.png',
  'banner-search-desktop': '/banner/banner_2024_search_desktop.png',
  'black-down': '/arrows/black-down.svg',
  'black-up': '/arrows/black-up.svg',
  'b2b-placeholder': '/infobox/b2b_placeholder.png',
  'b2b-placeholder-shadow': '/infobox/b2b_placeholder_shadow.png',
  'goback': '/icons/goback_icon.svg',
  'goback-inverted': '/icons/goback_inverted_icon.svg',
  'profile-headerbg': '/backgrounds/profile_header_bg.png',
  'show-lights-blackbg': '/backgrounds/show_lights_blackbg.jpg',
  'show-lights-whitebg': '/backgrounds/show_lights_whitebg.jpg',
  'static-bg': '/static_pages/static_pages_bg.svg',
  'static-bg-dark': '/static_pages/static_pages_dark_bg.svg',
  'ticket-black': '/tickets/ticket_black.svg',
  'ticket-black-mobile': '/tickets/ticket_black_mobile.svg',
  'ticket-grey': '/tickets/ticket_grey.svg',
  'ticket-white': '/tickets/ticket_white.svg',
  'ticket-button-grey': '/tickets/ticket_button_grey.svg',
  'ticket-button-red': '/tickets/ticket_button_red.svg',
  'ticket-icon': '/tickets/ticket_icon.svg',
  'ticket-icon-black': '/tickets/ticket_icon_black.svg',
  'tip-black-left': '/baloon/tip_black_left.svg',
  'tip-white-right-border': '/baloon/tip_white_right_border.svg',
  'white-down': '/arrows/white-down.svg',
  'white-left': '/arrows/white-left.svg',
  'white-up': '/arrows/white-up.svg',
}

const useInitCssVarAssets = () => {
  const urlCloudFront = useUrlCloudfront()

  useEffect(() => {
    if (urlCloudFront) {
      Object.entries(assets).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--image-${key}`, `url('${urlCloudFront}/images${value}')`)
      })
    }
  }, [urlCloudFront])
}

export default useInitCssVarAssets
