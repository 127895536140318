import { AssetType, IAssets } from 'meta/backendControlled/structure/asset'

import {
  BackendControlledOGImage,
  ComponentType,
  IVenue,
  IVenueDetails,
  IVenueInfo,
  IVenueSquare,
} from '../../structure'
import { IVenueBackend, IVenueDetailsBackend, IVenueInfoBackend, IVenueSquareBackend } from '../component/venue'
import ComponentAdapter from './componentAdapter'

const convertLocationAsset = (componentBackend: IVenueBackend<any> | IVenueSquareBackend): string => {
  const { locationAsset } = componentBackend
  const covers = locationAsset?.covers
  const logos = covers ? covers.find((x) => x.format === 'logo') : null

  // @ts-ignore
  return logos && covers && covers.length > 0 ? logos.src[0].url : null
}

const convertLocation = <C extends ComponentType.venueInfo | ComponentType.venueDetails>(
  componentBackend: IVenueBackend<any>,
  componentType: C
): IVenue<C> => {
  const { facebookLink, instagramLink, attributes, locationAsset } = componentBackend

  const attributesAsArray = attributes
    ? attributes.map((el) => {
        return {
          title: el.title,
          description: el.description,
          asset: { url: el.asset ? el.asset.src[0].url : null },
        }
      })
    : []

  return {
    asset: locationAsset as IAssets,
    // @ts-ignore
    address: componentBackend.address,
    // @ts-ignore
    attributes: attributesAsArray,
    captionData: componentBackend.captionData,
    city: componentBackend.city,
    common: componentBackend.common,
    componentType,
    description: componentBackend.description,
    email: componentBackend.email,
    // @ts-ignore
    facebookLink: facebookLink && facebookLink !== '' ? facebookLink : null,
    fullDescription: componentBackend.fullDescription,
    // @ts-ignore
    instagramLink: instagramLink && instagramLink !== '' ? instagramLink : null,
    id: componentBackend.id,
    identification: componentBackend.identification,
    imageUrl: convertLocationAsset(componentBackend),
    latitude: componentBackend.latitude,
    longitude: componentBackend.longitude,
    mapsPlaceId: componentBackend.mapsPlaceId,
    name: componentBackend.name,
    phone: componentBackend.phone,
    title: componentBackend.title,
    website: componentBackend.website,
  }
}

export class VenueInfoAdapter extends ComponentAdapter<IVenueInfo, IVenueInfoBackend> {
  convert(): IVenueInfo {
    return convertLocation<ComponentType.venueInfo>(this.componentBackend, ComponentType.venueInfo)
  }
}

export class VenueDetailsAdapter extends ComponentAdapter<IVenueDetails, IVenueDetailsBackend> {
  convert(): IVenueDetails {
    const venueDetails = convertLocation<ComponentType.venueDetails>(this.componentBackend, ComponentType.venueDetails)
    this.parseMetadata(venueDetails)
    return venueDetails
  }

  parseMetadata(venueDetails: IVenueDetails): void {
    venueDetails.asset?.covers?.forEach((cover) => {
      const url = cover?.src?.[0]?.url
      if (cover.type === AssetType.image && url) {
        const ogImage: BackendControlledOGImage = { url }
        this.componentsAdapter.metadata.og.images.push(ogImage)
      }
    })
  }
}

export class VenueSquareAdapter extends ComponentAdapter<IVenueSquare, IVenueSquareBackend> {
  convert(): IVenueSquare {
    const { address, city, common, id, identification, latitude, longitude, name } = this.componentBackend

    return {
      address,
      city,
      common,
      componentType: ComponentType.venueSquare,
      id,
      identification,
      imageUrl: convertLocationAsset(this.componentBackend),
      latitude,
      longitude,
      name,
    }
  }
}
