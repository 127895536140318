import { Routes } from 'meta/routes'

export const paths = [
  Routes.ProfileChangeData,
  Routes.ProfileChangePassword,
  Routes.ProfileTransactions,
  Routes.ProfileChangeTicketName,
  Routes.ProfileResale,
  Routes.ProfileUndoResale,
  Routes.ProfileCreditCards,
  Routes.Wallet,
].map((route) => route.path)
