import css from './Header.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IEventDetails } from 'meta/backendControlled/structure'
import { Dates } from 'utils/dates'

import { useScrollToVenueInfo } from 'components/BackendControlled/Component/EventDetails/Infos/Header/hooks/useScrollToVenueInfo'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import Img from 'components/Media/Img'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

const Header: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const { datetime, name, location, codeUrl } = props

  const { t } = useTranslation()

  const language = useCurrentLanguage()

  const { onLocationClick } = useScrollToVenueInfo({ codeUrl, language })

  const formattedDate = useMemo<string>(
    () => Dates.format(new Date(datetime), t('dates:common:eventDate')),
    [datetime, t]
  )
  const formattedTime = useMemo<string>(
    () => Dates.format(new Date(datetime), t('dates:common:eventTime')),
    [datetime, t]
  )

  return (
    <div className={css.event_essentials_infos_header_container}>
      <h4 className={css.daytime}>
        <span>{formattedDate}</span>
        <span className={css.time}>{` / ${formattedTime}`}</span>
      </h4>

      <h1 className={css.name}>{name}</h1>

      <div className={css.location_container} onClick={onLocationClick}>
        <Img src="/icons/location_marker_transparent.svg" />
        <h4 className={css.location_text}>{`${location?.name} / ${location?.common} `}</h4>
      </div>
    </div>
  )
}

export default Header
