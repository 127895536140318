import { useCallback } from 'react'

import { LanguageCode } from 'meta/app/translate'

import { getComponentId } from 'components/BackendControlled/Component/utils'
import { useEventVenueInfo } from 'hooks/useEvent'

type Props = {
  codeUrl: string
  language: LanguageCode
}

export const useScrollToVenueInfo = (props: Props) => {
  const { codeUrl, language } = props
  const { uuid: venueInfoAnchorId } = useEventVenueInfo({ codeUrl, language })

  const onLocationClick = useCallback(() => {
    if (venueInfoAnchorId) {
      const yOffset = -100
      const element = document.getElementById(getComponentId(venueInfoAnchorId))

      if (!element) return

      const y = element.getBoundingClientRect().top + window.scrollY + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [venueInfoAnchorId])

  return { onLocationClick }
}
