import css from './Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { AvatarBoxSkeleton } from 'components/Auth/Profile/Header/AvatarBox'

export const HeaderSkeleton: React.FC = () => {
  return (
    <>
      <div className={classNames(css.avatarContainer, css.avatarContainer__skeleton)}>
        <AvatarBoxSkeleton />
      </div>

      <div className={classNames(css.userDataContainer, css.profileHeaderDesktopContainer)}>
        <div className={css.name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.phone__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.email__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.birthPlace__skeleton}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={8}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>
      </div>
    </>
  )
}
