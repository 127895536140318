import css from './OTP.module.scss'
import React from 'react'
import { usePost } from 'api'

import { EnvType } from 'meta/app'
import { Breakpoints } from 'meta/layout/breakpoints'
import { LoginProvider, LoginStep } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import OTPForm from 'components/Auth/OTPV3/Form'
import { GridColumn } from 'components/Layout/Grid'
import { useCheckoutDetails } from 'hooks/useCheckout'
import { useAppEnv } from 'hooks/useEnv'
import { useLoginData, useLoginStep } from 'hooks/useLogin'
import { useOnUpdate } from 'hooks/useOnUpdate'

import { IStepProps, StepImg, StepLabel } from '../StepCommon'

const OTP: React.FC<IStepProps> = (props) => {
  const { onSuccess } = props

  const dispatch = useAppDispatch()

  const data = useLoginData()
  const step = useLoginStep()

  const env = useAppEnv()

  const { response, fetch } = usePost()
  const { hasUser, otpToken, phonePrefix, phone, otp } = data
  const login: boolean = hasUser && !data.provider

  const details = useCheckoutDetails()
  const loginActionPath = details?.insideIFrame ? ApiEndpoint.users.loginFromIframe() : ApiEndpoint.users.login()

  const bodyParser = (otp: string, otpToken: string) => {
    let body: any = { otp, otpToken }
    if (login) {
      body = {
        ...body,
        provider: LoginProvider.OTP,
        providerData: {
          otp,
          otpToken,
          phone,
          phonePrefix,
        },
      }
    }
    return body
  }

  //@ts-ignore
  const onSubmitSuccess = (values, response) => {
    const { existingEmail, token, otpToken } = response.data
    if (login) {
      onSuccess(token)
    } else {
      if (existingEmail) {
        if (existingEmail !== data.email) {
          dispatch(
            LoginActions.updateData({ data: { ...data, existingEmail, otpToken }, step: LoginStep.emailConfirm })
          )
        } else {
          const provider = data.provider

          let providerData = null
          if (provider === LoginProvider.APPLE) providerData = data.apple
          if (provider === LoginProvider.FACEBOOK) providerData = data.facebook
          if (provider === LoginProvider.GOOGLE) providerData = data.google

          const body = {
            email: data.email,
            otpToken: otpToken ? otpToken : data.otpToken,
            phone: data.phone,
            phonePrefix: data.phonePrefix,
            provider,
            providerData: { ...providerData, email: data.email },
          }

          //@ts-ignore
          fetch({ urlResource: ApiEndpoint.users.signupSocial(), body })
        }
      } else {
        dispatch(LoginActions.updateData({ data: { ...data, existingEmail, otpToken }, step: step + 1 }))
      }
    }
  }

  useOnUpdate(() => {
    if (response) {
      const { token } = response.data
      onSuccess(token)
    }
  }, [response])

  return (
    <>
      <StepImg src="/login/step_phone.svg" />
      <StepLabel labelKey="otpV2:insert_otp_code" />
      <GridColumn
        gridColumnStart={2}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 10 }}
        className={css.loginOTP__phone}
      >
        <h2>{`${phonePrefix} ${phone}`}</h2>
      </GridColumn>

      {[EnvType.development.toString(), EnvType.staging.toString()].includes(env) && (
        <GridColumn
          gridColumnStart={2}
          gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 10 }}
          className={css.loginOTP__phone}
        >
          <h3>OTP: {otp}</h3>
        </GridColumn>
      )}

      <GridColumn
        gridColumnStart={2}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 10 }}
        className={css.loginOTP__otp}
      >
        <OTPForm
          action={login ? loginActionPath : ApiEndpoint.users.otpVerify()}
          actionResend={ApiEndpoint.users.otp()}
          //@ts-ignore
          authToken={null}
          bodyParser={bodyParser}
          onSuccess={onSubmitSuccess}
          otpToken={otpToken}
          phone={Number(phone)}
          phonePrefix={phonePrefix}
        />
      </GridColumn>
    </>
  )
}

export default OTP
