import css from './Desktop.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const SearchBarDesktopSkeleton: React.FC = () => {
  return (
    <Skeleton
      className={css.headerSearchbar}
      baseColor={TICKETSMS_COLORS_LIGHTGREY}
      borderRadius={16}
      highlightColor={TICKETSMS_COLORS_WHITE}
      inline
    />
  )
}
