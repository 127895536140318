import css from './Mobile.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from 'react-input-range'

import PriceRange from 'components/Checkout/TicketSelection/Filters/PriceRange'
import Img from 'components/Media/Img'

interface IProps {
  setPriceRangeCallback(range: any): void
  priceRangeInitial: Range
  priceRangeCurrent: Range
}

const Mobile: React.FC<IProps> = (props) => {
  const { priceRangeCurrent, priceRangeInitial, setPriceRangeCallback } = props

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const activeFilters =
    priceRangeCurrent.min !== priceRangeInitial.min || priceRangeCurrent.max !== priceRangeInitial.max

  return (
    <div className={css.checkout_ticketselection_filters_mobile_container}>
      <div className={css.header_container}>
        <div
          className={`${css.header} ${activeFilters && css.active_filters}`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className={css.title}>
            {activeFilters ? t('checkout:deactivate_filters') : t('checkout:active_filters')}
          </div>
          <div className={css.icon}>
            <Img src="/checkout/filters.svg" />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className={css.filters}>
          {priceRangeInitial.min !== priceRangeInitial.max && (
            <PriceRange
              priceRangeInitial={priceRangeInitial}
              priceRangeCurrent={priceRangeCurrent}
              setPriceRangeCallback={setPriceRangeCallback}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Mobile
