import { IWalletTicket, IWalletTicketFilter } from 'meta/pages/wallet'
import { SliceSelector } from 'store/types'

const getWalletTickets: SliceSelector<Array<IWalletTicket> | undefined, { filter: IWalletTicketFilter }> =
  ({ filter }) =>
  (state) =>
    state.pages.wallet.tickets[filter]

export const WalletSelectors = {
  getWalletTickets,
}
