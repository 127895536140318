import css from './De.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

const ResaleDe: React.FC = () => {
  return (
    <div className={css.resale_de_container}>
      <div className={css.static_page_title}>
        <Img src="/resale/resale.svg" />
        <div>Wiederverkauf</div>
      </div>
      <div className={css.static_page_text}>
        <p>
          Über TicketSms gekaufte Tickets sind nominativ. Insbesondere die Leistungen von Großveranstaltungen mit mehr
          als 5000 Zuschauern erfordern nach der{' '}
          <a
            target="_blank"
            href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
            rel="noreferrer"
          >
            neue Reglung
          </a>
          , Die Verpflichtung zur <b>Benennung</b> und Überprüfung der Identität des Ticketinhabers. Aus diesem Grund
          wird bei den betreffenden Veranstaltungen auch ein gültiges Ausweisdokument verlangt, damit das mit der
          Kontrolle beauftragte Personal überprüfen kann, ob es sich auch um den Inhaber der Eintrittskarte handelt. Im
          Falle eine Nichtübereinstimmung können Sie nicht auf die Veranstaltung zugreifen und haben keinen Anspruch auf
          eine Rückerstattung. Auf der Seite, innerhalb der Veranstaltungsseite, wird angezeigt, ob die Veranstaltung
          nominierungspflichtig. Daher wird es <b>erforderlich</b> sein, ein gültiges Dokument vorzulegen, um eingehenzu
          können..
        </p>

        <p>
          Wenn Sie nicht in der Lage sind, eine Veranstaltung zu besuchen, können Sie das Verfahren für den
          Wiederverkauf des Tickets in Ihrem Namen oder der von Ihnen gekauften Tickets(auch eines einzelnen Tickets bei
          Mehrfachkäufen)aktivieren, , indem Sie in Ihrem Benutzerprofil oder in der Transaktionsübersicht den
          entsprechenden Abschnitt aufrufen.
        </p>

        <p>
          Um das Ticket weiterzuverkaufen, müssen Sie Ihren Vornamen, Nachnamen und das Steuersiegel vom Originalticket
          eingeben (diese Informationen sind alle auf dem Ticket selbst angegeben). Zusätzlich müssen Sie eine IBAN
          angeben, auf die im Falle eines Wiederverkaufs eine Überweisung durchgeführt werden soll.
        </p>

        <p>
          Sobald die Daten und die Berechtigung zur Durchführung überprüft wurden, wird eine Zusammenfassung des
          Angebots angezeigt und eine endgültige Bestätigung angefordert, nach der das Ticket wieder zum gleichen Preis
          wie das ursprüngliche Ticket innerhalb von TicketSms selbst zum Verkauf angeboten wird. Wenn ein neuer Käufer
          ein Ticket desselben Typs wie ein wieder in den Verkauf gebrachtes Ticket kaufen möchte, wird letzteres
          gegenüber den Standardtickets bevorzugt.
        </p>

        <p>
          Bei erfolgreichem Wiederverkauf erhält der Verkäufer innerhalb von 15 Werktagen eine Überweisung in Höhe des
          Wertes des wiederverkauften Einzeltickets ohne Provisionen und Servicegebühr, der 1,50 € beträgt. Es ist keine
          Provision oder Einfügegebühr vorgesehen, die Provision wird erst nach Abschluss des Weiterverkaufs erhoben.
        </p>

        <p>
          Die Wiederverkaufsfunktion folgt der{' '}
          <b>den gleichen zeitlichen Regeln wie der klassische Verkauf bis zum Beginn der Veranstaltungì </b>, d.h. sie
          steht für die gesamte Dauer des normalen Kartenverkaufs bis zur Öffnung der Tore der Veranstaltung zur
          Verfügung, danach ist es nicht mehr möglich, eigene Karten weiterzuverkaufen oder von anderen Nutzern zum
          Verkauf angebotene Karten zu kaufen
        </p>

        <p>
          Wenn Sie den Wiederverkauf eines Ihrer Tickets stornieren möchten, rufen Sie einfach den entsprechenden
          Abschnitt des Wiederverkaufsmenüs in Ihrem Profil auf, wo Sie die Daten des Tickets (Name und Steuersiegel)
          erneut eingeben müssen. Die Stornierung ist jedoch nur so lange möglich, bis das betreffende Ticket in den
          Warenkorb eines neuen Käufers gelegt wurde..
        </p>

        <p>
          Bitte beachten Sie, dass die Original-Eintrittskarte <b>nicht verwendet werden kann</b>, solange sie sich im
          Wiederverkaufsmarkt befindet, daher müssen Sie, auch wenn sie nicht innerhalb der Fristen weiterverkauft wird,
          das <b>Wiederverkaufsstornierungsverfahren</b> durchführen, um Ihre Eintrittskarte für den Zugang zur
          Veranstaltung zu verwenden.
        </p>

        <p>
          Beachten Sie abschließend, dass <b>Sie mit 18APP gekaufte Tickets nicht weiterverkaufen können</b>.
        </p>
      </div>
    </div>
  )
}

export default ResaleDe
