import { createAsyncThunk } from '@reduxjs/toolkit'

import { SearchController } from 'controller/search'
import { SearchActions } from 'store/pages/search/actions/index'
import { AppDispatch, RootState } from 'store/types'
import { Functions } from 'utils/functions'

type Params = { name: string }

const findCitiesThrottled = Functions.throttle(
  async (params: Params & { dispatch: AppDispatch }) => {
    const { name, dispatch } = params
    const cities = name.trim() === '' ? undefined : await SearchController.findCities({ name })
    dispatch(SearchActions.setCities({ cities }))
  },
  250,
  { leading: false, trailing: true }
)

export const findCities = createAsyncThunk<void, Params, { state: RootState; dispatch: AppDispatch }>(
  'search/cities/find',
  (params, { dispatch }) => {
    findCitiesThrottled({ dispatch, ...params })
  }
)
