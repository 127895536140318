import css from './CardFlipItem.module.scss'
import React, { PropsWithChildren } from 'react'

import { IWalletUserTicket } from 'meta/pages/wallet'

import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

type Props = {
  className: string
  userTicket: IWalletUserTicket
  changeShowDetail: () => void
  showDetails: boolean
}

const CardFlipItem: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, className, userTicket, changeShowDetail, showDetails } = props
  const urlCloudfront = useUrlCloudfront()

  return (
    <div className={className}>
      <div
        className={css.walletTicketCard}
        style={{ backgroundImage: `url(${urlCloudfront}/images/wallet/ticket_grey.svg)` }}
      >
        {userTicket.secret && (
          <button
            className={css.walletTicketCard__btnInfo}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              changeShowDetail()
            }}
          >
            <Img src={showDetails ? `/icons/arrow_left_white.svg` : `/icons/info_white.svg`} />
          </button>
        )}
        {React.Children.toArray(children)}
      </div>
    </div>
  )
}

export default CardFlipItem
