import React, { useState } from 'react'

import { ApiEndpoint } from 'controller/endpoint'
import { CookieClient, CookieName } from 'utils/cookie'

import OTPForm from 'components/Auth/OTPV3/Form'
import OTPFormHeader from 'components/Auth/OTPV3/Header'
import { useUserData } from 'hooks/useAuth'

import ChangePasswordForm from './ChangePasswordForm'
import Success from './Success'

interface IChangePassword {}

const ProfileChangePassword: React.FC<IChangePassword> = (props) => {
  const [otpToken, setOtpToken] = useState<string | null>(null)
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)

  const userData = useUserData()
  const authToken = CookieClient.get(CookieName.TOKEN)

  if (otpToken) {
    return (
      <>
        {userData && <OTPFormHeader phone={userData.phone} phonePrefix={userData.phonePrefix} />}
        {userData && (
          <OTPForm
            action={ApiEndpoint.users.otpConfirm()}
            actionResend={ApiEndpoint.users.otpResend()}
            authToken={authToken}
            onSuccess={() => {
              setOtpToken(null)
              setShowSuccessMsg(true)
            }}
            //@ts-ignore
            otpToken={otpToken}
            phone={userData.phone}
            phonePrefix={userData.phonePrefix}
          />
        )}
      </>
    )
  }

  return (
    <div>
      {showSuccessMsg && (
        <Success
          onClose={() => {
            setShowSuccessMsg(false)
          }}
        />
      )}

      {!showSuccessMsg && (
        <ChangePasswordForm
          authToken={authToken}
          //@ts-ignore
          onSuccess={(data, json) => setOtpToken(json.data.otpToken)}
          fromProfile={true}
        />
      )}
    </div>
  )
}

export default ProfileChangePassword
