import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ILoginData, Login, LoginStep } from 'meta/pages/login'
import { initialState } from 'store/pages/login/state'

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    show: (state: Draft<Login>, action: PayloadAction<{ pathname?: string } | undefined>) => {
      const { pathname } = action.payload ?? {}
      return { ...state, pathname, show: true }
    },
    reset: () => {
      return { ...initialState }
    },
    updateStep: (state: Draft<Login>, action: PayloadAction<{ step: LoginStep }>) => {
      const { step } = action.payload

      state.step = step
    },
    updateData: (state: Draft<Login>, action: PayloadAction<{ data: ILoginData; step: LoginStep }>) => {
      const { data, step } = action.payload

      state.data = data
      state.step = step
    },
  },
})
