import css from './ShowMoreBox.module.scss'
import React from 'react'
import classNames from 'classnames'
import { PageBgColors } from 'types/layout'

import Img from 'components/Media/Img'

interface IProps {
  toggleCallback(): void
  showAll: boolean
  showMoreText: string
  showLessText: string
  gradient?: PageBgColors
  noGradient?: boolean
}

const ShowMoreBox: React.FC<IProps> = (props) => {
  const { toggleCallback, showAll, showMoreText, showLessText, gradient, noGradient } = props

  return (
    <div className={classNames(css.showMoreContainer, { [css.showAll]: showAll })}>
      {!showAll && (
        <div
          className={`${css.showMoreBox} ${gradient === 'white' && css.whiteGradient} ${noGradient && css.noGradient}`}
        >
          <button onClick={toggleCallback}>
            <strong>{showMoreText}</strong>
            <Img alt="" src="/icons/arrow.svg" />
          </button>
        </div>
      )}
      {showAll && (
        <div className={css.showLessBox}>
          <button onClick={toggleCallback}>
            <strong>{showLessText}</strong>
            <Img alt="" src="/icons/arrow_up.svg" />
          </button>
        </div>
      )}
    </div>
  )
}

ShowMoreBox.defaultProps = {
  noGradient: false,
}

export default ShowMoreBox
