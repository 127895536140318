import css from '../Social.module.scss'
import React, { useState } from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'

import Img from 'components/Media/Img'
import ModalShare from 'components/Social/ButtonShare/ModalShare'

interface IPropsButtonShare {
  color: ComponentColor
  name: string
  shareLocation?: boolean
  noBorder?: boolean
}

export const ButtonShare: React.FC<IPropsButtonShare> = (props) => {
  const { color, name, noBorder, shareLocation } = props

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <button
        className={classNames(css.socialLink, css[color], { [css.noBorder]: noBorder })}
        onClick={() => setShowModal(true)}
      >
        <Img alt="" src={noBorder ? `/icons/share_black_noborder.svg` : `/social/share_${color}.svg`} />
      </button>

      {showModal && <ModalShare name={name} onClose={() => setShowModal(false)} shareLocation={shareLocation} />}
    </>
  )
}
