import css from 'components/Pages/ProfileTransactions/Transaction/Ticket/Actions/Actions.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import { t } from 'i18next'
import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ITransactionTicket, TransactionTicketStatus } from 'meta/pages/profile'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'
import { CookieClient, CookieName } from 'utils/cookie'

import Action from 'components/Pages/ProfileTransactions/Transaction/Actions/Action'
import { useFunUrl } from 'hooks/useEnv'

type Props = {
  data: ITransactionTicket
}

const Actions: React.FC<Props> = (props) => {
  const { data } = props
  const { eventId, firstName, fiscalSeal, lastName, status, secret, code, isResaleDisabled, isChangeNameDisabled } =
    data
  const valid = status === TransactionTicketStatus.valid

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const funUrl = useFunUrl()

  const changeHolderNameCallback = () => {
    dispatch(ProfileActions.setTicketChangeNameData({ data: { fiscalSeal, currentHolder: { firstName, lastName } } }))
    navigate(Routes.ProfileChangeTicketName.generatePath())
  }
  const resaleCallback = () => {
    dispatch(ProfileActions.setTicketResaleData({ data: { firstName, fiscalSeal, lastName } }))
    navigate(Routes.ProfileResale.generatePath())
  }
  const printCallback = async () => {
    const authToken = CookieClient.get(CookieName.TOKEN)
    window.open(`${funUrl}/pdf/index.php?e=${eventId}&s=${secret}&token=${authToken}`, '_blank')
  }
  const viewCallback = async () => {
    window.open(`${funUrl}/?k=${code}`, '_blank')
  }

  return (
    <div className={css.ticketActions}>
      {valid && fiscalSeal && (
        <>
          {!isChangeNameDisabled && (
            <Action
              callback={changeHolderNameCallback}
              color="red"
              imgUrl="/profile/change_holder_name_button_img.svg"
              paddingSmall
              extraTestId={DataTestId.profile.transactionTicketChangeName}
              text={String(t('profile:change_holder_name'))}
            />
          )}

          {!isResaleDisabled && (
            <Action
              callback={resaleCallback}
              color="black"
              imgUrl="/profile/resale_button_img.svg"
              paddingSmall
              extraTestId={DataTestId.profile.transactionTicketResale}
              text={String(t('profile:do_resale'))}
            />
          )}
        </>
      )}

      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        <div className={css.actionContainer}>
          <Action
            imgUrl="/profile/print_icon.svg"
            text={String(t('profile:print_ticket'))}
            color="black"
            callback={printCallback}
          />
          <Action
            imgUrl="/profile/see_tickets_button_img.svg"
            text={String(t('profile:view_ticket'))}
            color="black"
            callback={viewCallback}
          />
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
        <Action
          imgUrl="/profile/print_icon.svg"
          text={String(t('profile:print_ticket'))}
          color="black"
          callback={printCallback}
        />
      </MediaQuery>
    </div>
  )
}

export default Actions
