import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormBuilder, TextBuilder } from 'meta/form'

import Form, { IFormOnSuccess } from 'components/Forms/Form'

interface IProps {
  onSuccess: IFormOnSuccess
}

const FormFastCheckout: React.FC<IProps> = (props) => {
  const { onSuccess } = props

  const { t } = useTranslation()

  const email = new TextBuilder('email')
    .addRequiredEmailValidation()
    .addPlaceholder('checkout:email_placeholder')
    .build()
  const emailConfirm = new TextBuilder('emailConfirm')
    .addAutoCompleteOff()
    .addValidation({
      required: String(t('checkout:errors_form_required_email')),
      validate: {
        message: 'checkout:errors_email_not_matching',
        test: (value) => {
          const emailValue = (document.getElementById('email') as HTMLInputElement).value
          return value === emailValue
        },
      },
    })
    .addPlaceholder('checkout:email_confirm_placeholder')
    .build()

  const formFields = new FormBuilder().addField(email).addField(emailConfirm).build()

  return <Form onSuccess={onSuccess} formFields={formFields} action={undefined} />
}

export default FormFastCheckout
