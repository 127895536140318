import css from './Base.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

export const TicketCardBaseSkeleton: React.FC = () => {
  return (
    <>
      <div className={css.walletTicketCard__base}>
        <div className={classNames(css.walletTicketCard__qrCode)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>

        <div className={css.walletTicketCard__info}>
          <div className={classNames(css.username__skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={8}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
          <div className={classNames(css.sep, css.sep__skeleton)} />
          <div className={classNames(css.notes__skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </div>
      <div className={classNames(css.walletTicketCard__status)}>
        <div className={classNames(css.status__label__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
        <div className={classNames(css.status__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
    </>
  )
}
