import css from './EventList.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { Grid, GridColumn } from 'components/Layout/Grid'

import EventListItemSkeleton from './EventListItem/EventListItemSkeleton'

const EventListSkeleton: React.FC = () => {
  return (
    <Grid>
      <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
        <div className={css.eventListContainer}>
          <div className={css.title_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={4}
              height={'100%'}
              width={'20%'}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
          {[1, 2, 3].map((_elem, key) => (
            <EventListItemSkeleton key={key} />
          ))}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default EventListSkeleton
