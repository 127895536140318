import React from 'react'

import Head from 'components/common/Head'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { useShowOnlyContent } from 'components/Layout/hooks/useShowOnlyContent'
import { useEnableSalesforceChat, useMaintenanceMode } from 'hooks/useEnv'

const Chat: React.FC = () => {
  const { namespacesLoaded } = useI18NContext()
  const maintenanceMode = useMaintenanceMode()
  const isChatEnabled = useEnableSalesforceChat()
  const { onlyContent } = useShowOnlyContent()

  if (onlyContent || maintenanceMode || !isChatEnabled || !namespacesLoaded) return null

  return (
    <Head>
      {/*@ts-ignore*/}
      <script src="//eu.fw-cdn.com/12513394/558487.js" chat="true" />
    </Head>
  )
}

export default Chat
