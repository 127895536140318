import { useEffect } from 'react'

import { useGoogleAnalyticsId } from 'hooks/useEnv'

export const useInitReactGa4 = () => {
  const googleAnalytics4Id = useGoogleAnalyticsId()

  useEffect(() => {
    import('react-ga4')
      .then((module) => module.default)
      .then((ReactGA) => {
        if (googleAnalytics4Id) {
          ReactGA.initialize(googleAnalytics4Id)
          ReactGA.send('pageview')
        }
      })
  }, [googleAnalytics4Id])
}
