import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { fromEvent, merge, Subscription } from 'rxjs'

import { StoryProps } from '../props'
import { getTouchCoordinates } from './utils'

export const useSwipeUp = (props: StoryProps, swipeUpRef: MutableRefObject<HTMLButtonElement>): void => {
  const { story } = props
  const swipeUpStartY = useRef<number>(null)
  const moveSubscriptionRef = useRef<Subscription>(null)
  const swipeDone = useRef<boolean>(false)

  const subscribeToMove = useCallback((): void => {
    const mouseMove = fromEvent(window, 'mousemove')
    const touchMove = fromEvent(window, 'touchmove')

    //@ts-ignore
    moveSubscriptionRef.current = merge(mouseMove, touchMove).subscribe((e: MouseEvent | TouchEvent) => {
      const swipeUpEnd = getTouchCoordinates(e)?.y || (e as MouseEvent).clientY

      //@ts-ignore
      const swipeUpYDiff = swipeUpStartY.current - swipeUpEnd

      if (swipeUpYDiff > 0) {
        swipeUpRef.current.style.transform = `translate(-50%, -${32 + swipeUpYDiff}px)`
        swipeUpRef.current.style.opacity = `${1 - swipeUpYDiff / 50}`
      }
      if (swipeUpYDiff > 50) {
        window.location.href = story.url
        swipeDone.current = true

        //@ts-ignore
        moveSubscriptionRef.current.unsubscribe()
      }
    })
  }, [])

  useEffect(() => {
    if (story.url) {
      const mouseUp = fromEvent(swipeUpRef.current, 'mouseup')
      const mouseDown = fromEvent(swipeUpRef.current, 'mousedown')
      const touchEnd = fromEvent(swipeUpRef.current, 'touchend')
      const touchStart = fromEvent(swipeUpRef.current, 'touchstart')

      //@ts-ignore
      merge(mouseDown, touchStart).subscribe((e: MouseEvent | TouchEvent) => {
        e.preventDefault()
        //@ts-ignore
        swipeUpStartY.current = getTouchCoordinates(e)?.y || (e as MouseEvent).clientY
        subscribeToMove()
      })

      //@ts-ignore
      merge(mouseUp, touchEnd).subscribe((e: MouseEvent | TouchEvent) => {
        e.preventDefault()

        //@ts-ignore
        moveSubscriptionRef.current.unsubscribe()

        if (!swipeDone.current) {
          swipeUpRef.current.style.transform = `translate(-50%, -32px)`
          swipeUpRef.current.style.opacity = `1`
        }
      })
    }
  }, [])
}
