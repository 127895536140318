import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'

import i18n from 'i18n'
import { CookieClient, CookieName } from 'utils/cookie'
import { AxiosResponse } from 'utils/http/response'
import { ResponseError } from 'utils/http/responseError'

const axiosInstance = axios.create({
  // baseURL: Env.apiUrl,
  timeout: 20000,
  // TODO: check base headers
  headers: {
    'Content-Type': 'application/json',
    // 'Accept-Language': i18n.language.substring(0, 2),
  },
})

// TODO: check if needed
let abortController = new AbortController()

axiosRetry(axiosInstance, {
  retries: 3,
  // TODO: check retryCondition
  // retryCondition: ({ response }) => {
  //   // Using config.validateStatus but can be customized based on status codes
  //   return Boolean(response && (response.status < 200 || response.status === 504))
  // },
  retryDelay: (_) => 500,
})

// axiosInstance.interceptors.response.use((response) => response, HandleError.axios)

axiosInstance.interceptors.request.use(
  async (config) => {
    // if (config.url && config.url.includes('login')) {
    //   abortController = new AbortController()
    // }

    if (!config.headers?.Authorization) {
      const token = CookieClient.get(CookieName.TOKEN)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    const language = i18n.language.substring(0, 2)
    if (language !== config.headers['Accept-Language']) {
      config.headers['Accept-Language'] = language
    }

    // TODO: check if backend response errors must be validated
    // handle 422 backend response exceptions
    // config.validateStatus = (status) => {
    //   return status >= 200 && ![503, 504].includes(status)
    // }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// TODO: check if manifest exists and must be checked for any request
axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    // if (Manifests.isManifest(response.data.metadata)) {
    //   ManifestController.verify({ manifest: response.data.metadata as Manifest })
    // }

    if (response?.data.status === 404) {
      throw new ResponseError(response?.data.error ?? '', new AxiosError(), response?.data.status)
    }

    return response
  },
  (error: AxiosError) => {
    // if (error?.response?.status === 503) {
    //   AppEvents.emit(AppEventNames.manifestStatusUpdate, { manifestStatus: ManifestStatus.maintenance })
    // }

    // @ts-ignore
    const message = error?.response?.data?.error ?? error.message
    return Promise.reject(new ResponseError(message, error, error?.response?.status))
  }
)

export { abortController, axiosInstance }
