import css from './NotFound.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'

import NotFoundSkeleton from './NotFoundSkeleton'

interface Props {}

const NotFound: React.FC<Props> = () => {
  const { t } = useTranslation()

  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={css.notfound_container}>
      <WithAppInitialized skeleton={<NotFoundSkeleton />} showChildren={namespacesLoaded}>
        <div className={css.image_error_container}>
          <Img src="/404/404_not_found.svg" />
        </div>

        <div className={css.error_text_container}>
          <div>{t('not_found:attention')}</div>
          <div>{t('not_found:message')}</div>
        </div>
      </WithAppInitialized>
    </div>
  )
}

const namespaces = ['not_found']

export default withTranslation(namespaces)(NotFound)
