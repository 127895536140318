import React, { useEffect, useState } from 'react'

import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'

import Success from 'components/Pages/ProfileUndoResale/Success'
import UndoResaleForm from 'components/Pages/ProfileUndoResale/UndoResaleForm'
import { useAuthToken } from 'hooks/useAuth'

const ProfileUndoResale: React.FC = () => {
  const dispatch = useAppDispatch()
  const authToken = useAuthToken()

  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      dispatch(ProfileActions.resetTicketResale())
    }
  }, [dispatch])

  if (showSuccess) {
    return <Success onClose={() => setShowSuccess(false)} />
  }

  if (authToken) {
    return <UndoResaleForm authToken={authToken} onSuccess={() => setShowSuccess(true)} />
  }

  return null
}

export default ProfileUndoResale
