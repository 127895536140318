import css from './Mobile.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ITicket } from 'meta/backendControlled/structure'
import { CurrencySymbol } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { CheckoutActions } from 'store/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'

import EventFeesButton from 'components/BackendControlled/Component/common/EventFeesButton'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutCartTicket, useCheckoutDetails, useCheckoutEventCodeUrl } from 'hooks/useCheckout'

interface IProps {
  data: ITicket
  addTickets(): void
  removeTickets(): void
}

const Mobile: React.FC<IProps> = (props) => {
  const { addTickets, removeTickets } = props
  const { id, title, priceNumber, currency, status, availableUntil, details } = props.data

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()
  const { eventDetails } = useCheckoutDetails() as ICheckoutDetails
  const [seeDetails, setSeeDetails] = useState(false)
  const cartTicket = useCheckoutCartTicket(id)
  const dispatch = useDispatch()

  const codeUrlFromCheckout = useCheckoutEventCodeUrl()

  const getAvailableUntil = () => {
    return Dates.format(new Date(availableUntil), t('dates:common:ticket'))
  }

  const eventCurrencyCode = getCodeFromSymbol(currency as CurrencySymbol)

  const convertedPrice = convertAndFormat(priceNumber, eventCurrencyCode, activeCurrency)

  const codeUrl = eventDetails && eventDetails.codeUrl ? eventDetails.codeUrl : codeUrlFromCheckout

  return (
    <div
      className={`${css.checkout_ticketselection_automatic_ticket_mobile_container}
            ${status === 'active' ? css.green : css.yellow}`}
    >
      <div className={css.ticket_container}>
        <div className={css.left_data} onClick={() => setSeeDetails(!seeDetails)}>
          <div className={css.ticket_title}>
            <div className={`${css.title} ${seeDetails && css.open}`}>{title}</div>
          </div>

          <div className={css.ticket_price_box}>
            <div className={css.price}>{`${convertedPrice}`}</div>

            {codeUrl && <EventFeesButton codeUrl={codeUrl} />}
          </div>

          <div className={`${css.see_details} ${seeDetails && css.hidden}`}>
            <div className={css.text}>{t('checkout:see_ticket_details')}</div>
            <div className={css.arrow}>{<Img src="/arrows/black-down.svg" />}</div>
          </div>
        </div>

        <div className={css.ticket_select_box}>
          <div
            className={css.minus}
            onClick={() => {
              removeTickets()
              dispatch(CheckoutActions.removeFromCart({ id }))
            }}
            data-testid="checkout-ticket-remove"
          >
            <Img src={'/tickets/ticket_select_minus.svg'} />
          </div>

          <div className={css.quantity}>{cartTicket ? cartTicket.quantity : 0}</div>

          <div className={css.plus} onClick={addTickets} data-testid={DataTestId.checkout.btnTicketAdd}>
            <Img src={'/tickets/ticket_select_plus.svg'} />
          </div>
        </div>
      </div>

      <Img className={css.ticket_select_background} src={'/checkout/tickets-mobile-whitebox-smaller.svg'} />
      {/*the div below is necessary to cover the green border popping out because of the image border radius different than main container border radius*/}
      <div className={css.ticket_select_background_cover} />

      {seeDetails && (
        <div
          onClick={() => setSeeDetails(!seeDetails)}
          className={`${css.ticket_details} ${status === 'active' ? css.green : css.yellow}`}
        >
          {details && <div className={css.details}>{details}</div>}
          {availableUntil && (
            <div className={css.availableUntil}>{`${t(
              'checkout:purchase_available_until'
            )} ${getAvailableUntil()}`}</div>
          )}
          <div className={`${css.see_details} ${css.bottom}`}>
            <div className={css.text}>{t('checkout:hide_ticket_details')}</div>
            <div className={css.arrow}>
              <Img src="/arrows/black-up.svg" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Mobile
