import React, { useEffect } from 'react'

import { DOMs } from 'utils/doms'
import { MIMETypes } from 'utils/mimeTypes'

import { useBackendControlledComponentsMetadata } from 'hooks/useBackendControlled'

type Props = {
  path: string
  title: string
}

const MetaOGImages: React.FC<Props> = (props) => {
  const { path, title } = props

  const metadata = useBackendControlledComponentsMetadata({ path })

  useEffect(() => {
    const parentNode = document.head
    const removeNodes = () => {
      DOMs.removeNodes({ parentNode, selectors: `meta[property='og:image']` })
      DOMs.removeNodes({ parentNode, selectors: `meta[property='og:image:secure_url']` })
      DOMs.removeNodes({ parentNode, selectors: `meta[property='og:image:type']` })
      DOMs.removeNodes({ parentNode, selectors: `meta[property='og:image:alt']` })
    }

    removeNodes()

    if (metadata) {
      metadata.og.images.forEach((ogImage) => {
        const { url } = ogImage
        const mimeType = MIMETypes.getMIMEType({ fileName: url })

        DOMs.createNode({ attributes: { property: 'og:image', content: url }, name: 'meta', parentNode })
        DOMs.createNode({ attributes: { property: 'og:image:secure_url', content: url }, name: 'meta', parentNode })
        DOMs.createNode({ attributes: { property: 'og:image:type', content: mimeType }, name: 'meta', parentNode })
        DOMs.createNode({ attributes: { property: 'og:image:alt', content: title }, name: 'meta', parentNode })
      })
    }

    return removeNodes
  }, [metadata, title])

  return null
}

export default MetaOGImages
