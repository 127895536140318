import { useCallback, useState } from 'react'

export const useShowModal = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const toggleModal = useCallback(() => {
    setShowModal((prevState) => !prevState)
  }, [])

  return { showModal, toggleModal }
}
