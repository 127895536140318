import { useState } from 'react'

import { LanguageCode } from 'meta/app/translate'
import { IEventDetails, IEventInfo, IVenueInfo } from 'meta/backendControlled/structure'
import { IEventSeats } from 'meta/pages/event'
import { TicketFee } from 'meta/pages/tickets'
import { useAppSelector } from 'store'
import { BackendControlledSelectors } from 'store/backendControlled'
import { EventSelectors } from 'store/pages/event'

import { useOnUpdate } from 'hooks/useOnUpdate'

export const useEventDetails = ({
  codeUrl,
  language,
}: {
  codeUrl: string
  language: string
}): IEventDetails | undefined => useAppSelector(BackendControlledSelectors.getEventDetails({ codeUrl, language }))

export const useEventParentCodeUrl = ({
  codeUrl,
  language,
}: {
  codeUrl: string
  language: string
}): string | undefined => useAppSelector(BackendControlledSelectors.getEventParentCodeUrl({ codeUrl, language }))

export const useEventSeats = (id: string): IEventSeats => useAppSelector((state) => state.pages.event.seats[id] || {})

export const useIsEventFree = (): boolean => useAppSelector((state) => state.pages.event.isEventFree)

export const useIsEventEnded = (): boolean | undefined => useAppSelector((state) => state.pages.event.isEventEnded)

export const useSectionLabel = (id: string, sectionId: string | undefined | null): string | undefined => {
  const eventSeats = useEventSeats(id)
  const { sectionsEnabled, sectors } = eventSeats

  const getLabel = (): string | undefined => {
    if (!sectionId || !sectionsEnabled) return undefined

    const mapSection = sectionsEnabled.find((section) => section.sectionId === sectionId)
    if (mapSection) {
      const { orderCodeDescription, sectorUuid } = mapSection
      return sectorUuid && sectors ? sectors[sectorUuid].name : orderCodeDescription
    }

    return undefined
  }

  const [label, setLabel] = useState(getLabel())

  useOnUpdate(() => {
    setLabel(getLabel())
  }, [sectionId, sectionsEnabled])

  return label
}

export const useEventVenueInfo = ({ codeUrl, language }: { codeUrl: string; language: LanguageCode }): IVenueInfo =>
  useAppSelector((state) => BackendControlledSelectors.getEventVenueInfo(state, `/events/${codeUrl}/${language}`))

export const useEventInfo = ({ codeUrl, language }: { codeUrl: string; language: LanguageCode }): IEventInfo =>
  useAppSelector((state) => BackendControlledSelectors.getEventInfo(state, `/events/${codeUrl}/${language}`))

export const useVisibleEventFeesCodeUrl = (): string | undefined =>
  useAppSelector(EventSelectors.getVisibleEventFeesCodeUrl)

export const useVisibleEventFees = (): Array<TicketFee> | undefined =>
  useAppSelector(EventSelectors.getVisibleEventFees)

export const useEventMetadata = (): Object | undefined => useAppSelector(EventSelectors.getEventMetadata)
