import css from './Success.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'

import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import SuccessSkeleton from 'components/Pages/Success/SuccessSkeleton'

type IProps = {
  inProgress?: boolean
}

const Success: React.FC<IProps> = (props) => {
  const { inProgress } = props

  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.history.back()
    window.onpopstate = () => window.history.forward()
  }, [])

  return (
    <WithAppInitialized skeleton={<SuccessSkeleton />} showChildren={namespacesLoaded}>
      <div className={css.success_container}>
        <div className={css.success_flex_container}>
          <div className={css.image_success_container}>
            <Img src="/success/success.gif" />
          </div>

          <div className={css.success_text_container} data-testid={DataTestId.success.successPurchase}>
            {inProgress && <div className={css.success_message_container}>{t('success:in_progress')}</div>}
            {!inProgress && <div className={css.success_title_container}>{t('success:attention')}</div>}
            {!inProgress && <div className={css.success_message_container}>{t('success:message')}</div>}
          </div>
        </div>
      </div>
    </WithAppInitialized>
  )
}

const namespaces = ['success']
export default withTranslation<IProps>(namespaces)(Success)
