import css from 'components/Pages/PurchaseForm/Tickets/Ticket/TicketInfo/TicketInfo.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_DARKGREY, TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import Img from 'components/Media/Img'

const TicketInfo: React.FC = () => {
  return (
    <div className={css.ticketInfo}>
      <div className={css.ticketInfo__imgTicket}>
        <Img src="/tickets/ticket_logo_placeholder.svg" />
      </div>

      <div className={css.ticketInfo__desc}>
        <Skeleton
          wrapper={({ children }) => <>{React.Children.toArray(children)}</>}
          className={classNames(css.ticketInfo__descTitle, css.ticketInfo__descTitleSkeleton)}
          baseColor={TICKETSMS_COLORS_DARKGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
        <Skeleton
          className={classNames(css.ticketInfo__descDetails, css.ticketInfo__descDetailsSkeleton)}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_WHITE}
          inline
        />
      </div>
    </div>
  )
}

export default TicketInfo
