import css from './External.module.scss'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ICheckoutDetails, ICheckoutPayment, PaymentGateway } from 'meta/pages/checkout'

import Loader from 'components/Checkout/Steps/Loader'
import Img from 'components/Media/Img'
import { useCheckoutDetails, useCheckoutPayment } from 'hooks/useCheckout'
import { usePosBankRedirectUrl } from 'hooks/useEnv'

const External: React.FC = () => {
  const { t } = useTranslation()
  const { gateway, gatewayData } = useCheckoutPayment() as ICheckoutPayment
  const { insideIFrame } = useCheckoutDetails() as ICheckoutDetails

  const submitBtnRef = useRef<HTMLInputElement>(null)
  const posBankRedirectUrl = usePosBankRedirectUrl()

  const getRedirectUrl = (): string => {
    switch (gateway) {
      case PaymentGateway.virtualPay:
        return posBankRedirectUrl
      case 'PAYPAL':
        return gatewayData?.['paypalRedirectUrl'] as string
      case 'SCALAPAY':
        return gatewayData?.['checkoutUrl'] as string
    }
    throw new Error(`redirect url not found for gateway ${gateway}`)
  }
  const redirectUrl = getRedirectUrl()
  const formMethod = gateway === 'SCALAPAY' ? 'GET' : 'POST'

  useEffect(() => {
    setTimeout(() => {
      submitBtnRef.current?.click()
    }, 2000)
  }, [])

  return (
    <Loader>
      <div className={css.checkout_steps_external_container}>
        <div className={css.title}>
          {gateway === PaymentGateway.virtualPay && t('checkout:redirect_to_bank_site')}
          {gateway === 'PAYPAL' && t('checkout:redirect_to_paypal_site')}
          {gateway === 'SCALAPAY' && t('checkout:redirect_to_scalapay_site')}

          {gateway !== PaymentGateway.virtualPay &&
            gateway !== 'PAYPAL' &&
            gateway !== 'SCALAPAY' &&
            t('checkout:redirect_to_external_site')}
        </div>

        <div className={css.enable_popup_warning}>
          <div>{t('checkout:enable_popup_warning')}</div>
          <Img src="/icons/new_popup_icon.png" />
        </div>

        <div className={css.form}>
          <form method={formMethod} action={redirectUrl} target={insideIFrame ? '_parent' : '_self'}>
            <input ref={submitBtnRef} type="submit" />
            {gatewayData &&
              Object.keys(gatewayData).map((elem, key) => {
                return <input key={key} type="hidden" name={elem} value={gatewayData[elem]} />
              })}
          </form>
        </div>
      </div>
    </Loader>
  )
}

export default External
