import React from 'react'

import withTranslation from 'components/HOC/WithTranslation'

import Success from '../Success'

const TicketGeneration: React.FC = () => {
  return <Success inProgress />
}

const namespaces = ['success']

export default withTranslation(namespaces)(TicketGeneration)
