import css from './Desktop.module.scss'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { Breakpoints } from 'meta/layout/breakpoints'
import { ICheckoutDetails, ICheckoutRecap } from 'meta/pages/checkout'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useCheckoutDetails, useCheckoutRecap } from 'hooks/useCheckout'

import Tickets from '../Tickets'
import Totals from '../Totals'

interface IProps {
  timerComponent: ReactElement

  continueCallback(): void
}

const Desktop: React.FC<IProps> = (props) => {
  const { continueCallback, timerComponent } = props

  const { t } = useTranslation()
  const { hasMap } = useCheckoutDetails() as ICheckoutDetails
  const recap = useCheckoutRecap() as ICheckoutRecap

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_recapbox_container}>
            <div className={css.header}>
              <h3 data-testid={DataTestId.checkout.recapLabel}>{t('checkout:recap_header')}</h3>
              {timerComponent}
            </div>

            <Tickets data={recap.tickets} doesEventHaveMap={hasMap} />

            <Totals
              subtotal={recap.subtotal}
              commission={recap.commission}
              total={recap.total}
              continueCallback={continueCallback}
            />

            {/* {hasMap && (ticketSelection.type == TicketSelectionType.automatic) && (
                            <MapSections
                                t={t}
                                setTicketSelectionMethodCallback={setTicketSelectionMethodCallback}
                            />
                        )} */}
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default Desktop
