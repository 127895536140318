export const chunkArrayInGroups = <T extends any>(array: Array<T>, size: number): Array<Array<T>> => {
  const chunkedArray: Array<Array<T>> = []

  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size))
  }

  return chunkedArray
}

export const cloneIterableObject = (obj: any[]) => {
  return obj.map((a) => Object.assign({}, a))
}

export const isThereOnlyBuyAction = (actions: any[]) => {
  return actions && actions.length === 1 && actions.some((x) => x.type === 'buyTickets')
}

export const doesListContainsGallery = (list: any): boolean => {
  if (list && list[0] && list[0].category && list[0].category.data) {
    return list[0].category.data.every((x: { componentType: string }) => x.componentType === 'asset')
  }

  return false
}

export const swapObjectKeysAndValues = (obj: Object) => {
  const ret = {}
  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    ret[obj[key]] = key
  })
  return ret
}
