import { IMap, IMapSeat } from '../map'
import { Map3DViewer } from '../mapViewer'

export class DVMMap3DViewer implements Map3DViewer {
  // @ts-ignore
  private container: HTMLDivElement = null
  private viewer: any = null

  async init(container: HTMLDivElement): Promise<void> {
    this.container = container
    // @ts-ignore
    this.viewer = await DVM.loadModule('3d_viewer', { container })
    this.viewer.flags.fixed_aspect_ratio = false
    this.viewer.flags.show_fullscreen_button = false
  }

  async getThumbnail(map: IMap, seat: IMapSeat): Promise<any> {
    return this.viewer.getThumbnail({ venue_id: map.mapId, view_id: seat.seatId })
  }

  async loadView(map: IMap, seat: IMapSeat, container?: HTMLDivElement): Promise<void> {
    if (container) {
      this.container = container
      this.viewer.setContainer(container)
    }
    await this.viewer.loadView3d({ venue_id: map.mapId, view_id: seat.seatId })
  }

  reset(): void {
    this.viewer.reset()
  }

  hide(): void {
    this.container.style.display = 'none'
  }

  show(): void {
    this.container.style.display = 'block'
  }
}
