import { ComponentType, IComponent } from './component'

export interface IBanner extends IComponent<ComponentType.banner> {
  url: string
  mobileUrl: string
  type: BannerContentType
  link?: string
}

export enum BannerContentType {
  image = 'image',
  video = 'video',
}
