import css from './NavButtons.module.scss'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import MediaQuery from 'react-responsive'

import { IMenuItem } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { IShowNavButtons } from 'components/BackendControlled/Component/Menu/hooks'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import Img from 'components/Media/Img'

interface IProps {
  showNavButtons: IShowNavButtons
  menuItems: Array<IMenuItem>
  visibleSection?: string
}

const NavButtons: React.FC<IProps> = (props) => {
  const { showNavButtons, menuItems, visibleSection } = props
  const { showLeft, showRight } = showNavButtons
  const idxVisibleSection = menuItems.findIndex(
    (menuItem: IMenuItem) => getComponentId(menuItem.anchor) === visibleSection
  )

  const sectionPrev = showLeft && idxVisibleSection > 0 && menuItems[idxVisibleSection - 1]
  const sectionNext = showRight && idxVisibleSection < menuItems.length - 1 && menuItems[idxVisibleSection + 1]

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        {sectionPrev && (
          <div className={css.titlesMenu_navButtonBoxLeft}>
            <AnchorLink
              className={css.titlesMenu_navButton}
              offset="100"
              href={`#${getComponentId(sectionPrev.anchor)}`}
            >
              <Img alt="" src="/arrows/black-left.svg" />
            </AnchorLink>
          </div>
        )}

        {sectionNext && (
          <div className={css.titlesMenu_navButtonBoxRight}>
            <AnchorLink
              className={css.titlesMenu_navButton}
              offset="100"
              href={`#${getComponentId(sectionNext.anchor)}`}
            >
              <Img alt="" src="/arrows/black-right.svg" />
            </AnchorLink>
          </div>
        )}
      </MediaQuery>
    </>
  )
}

export default NavButtons
