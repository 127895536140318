import React from 'react'

import { FormBuilder, TextBuilder } from 'meta/form'

import Form, { IFormOnSuccess } from 'components/Forms/Form'

type Props = {
  action: string
  onSuccess: IFormOnSuccess
}

const PasswordResetForm: React.FC<Props> = (props) => {
  const { action, onSuccess } = props

  const email = new TextBuilder('email').addRequiredEmailValidation().addPlaceholder('form:email').build()
  const formFields = new FormBuilder().addField(email).build()

  return (
    <Form
      action={action}
      formFields={formFields}
      id="forgot-password-reset-form"
      labelSubmit="password:submit_label"
      onSuccess={onSuccess}
    />
  )

  return <div>PasswordResetForm</div>
}

export default PasswordResetForm
