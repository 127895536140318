import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataDogLogType, LOGS_CHECKOUT_PROCESS_ERROR } from 'meta/logger'
import { QueryParams, Routes } from 'meta/routes'
import { DataDogLogger } from 'helpers/debug/logger'

import { useIsAppInitialized } from 'hooks/useApp'
import { useCheckoutProcess } from 'hooks/useCheckout'
import { useQueryParams } from 'hooks/useQueryParams'

const SESSION_EXPIRED_ERROR_CODE = 409

export const useHandleProcessError = () => {
  const appInitialized = useIsAppInitialized()
  const navigate = useNavigate()
  const { paymentGateway, transactionCode } = useQueryParams<QueryParams['CheckoutProcess']>()
  const checkoutProcess = useCheckoutProcess()

  useEffect(() => {
    if (!appInitialized || !checkoutProcess?.error) return

    const { error } = checkoutProcess
    // TODO: handle response status code
    const status = 500

    // if (error && status !== 200) {
    if (error) {
      DataDogLogger(LOGS_CHECKOUT_PROCESS_ERROR, DataDogLogType.error, {
        transactionCode,
        paymentGateway,
        errorCode: status,
        errorMsg: error,
      })

      // TODO: handle SESSION_EXPIRED_ERROR_CODE
      //     if (status !== SESSION_EXPIRED_ERROR_CODE) {
      navigate(Routes.Error.generatePath())
      //     } else {
      // navigate(Routes.Error.generatePath(undefined,{comingFrom:ErrorSource.sessionExpired}))
      //     }
      //   }
    }
  }, [appInitialized, checkoutProcess, navigate, paymentGateway, transactionCode])
}
