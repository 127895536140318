import { createSlice } from '@reduxjs/toolkit'

import { init } from 'store/app/actions/init'
import { initialState } from 'store/app/state'

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(init.fulfilled, (state, action) => {
      const { env } = action.payload
      state.env = env
    })
  },
})
