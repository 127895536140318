import css from './ProgressBar.module.scss'
import React from 'react'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid/GridColumn'

interface Props {
  visible: number
  total: number
}

const ProgressBar: React.FC<Props> = (props) => {
  const { visible, total } = props

  const items = new Array(total).fill(0)

  return (
    <GridColumn
      className={css.gridContainer}
      gridColumnStart={{ [Breakpoints.tablet]: 12, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={{ [Breakpoints.tablet]: 15, [Breakpoints.mobile]: 17 }}
    >
      <div className={css.progressBarContainer}>
        <div className={css.progressBar}>
          {items.map((_, idx) => {
            return (
              <div
                key={String(idx)}
                className={classNames(css.progressItem, {
                  [css.active]: idx == visible,
                })}
              />
            )
          })}
        </div>
      </div>
    </GridColumn>
  )
}

export default ProgressBar
