import css from '../InstaStories.module.scss'
import { MutableRefObject, useEffect } from 'react'
import throttle from 'lodash.throttle'

import { StoriesProps } from '../props'
import { useStories } from './useStories'

export type StoriesState = ReturnType<typeof useStories>

export const useStoriesState = (props: StoriesProps, storiesRef: MutableRefObject<HTMLDivElement>): StoriesState => {
  const { idxActive, laptop, styles, updateIdxActive } = useStories(props, storiesRef)

  useEffect(() => {
    const resize = throttle(() => {
      updateIdxActive(0)
    }, 250)

    updateIdxActive(0)
    document.body.classList.add(css.userSelectNone)
    window.addEventListener('resize', resize)

    return () => {
      document.body.classList.remove(css.userSelectNone)
      window.removeEventListener('resize', resize)
    }
  }, [])

  return {
    laptop,
    idxActive,
    styles,
    updateIdxActive,
  }
}
