import css from 'components/Pages/ProfileCreditCards/ProfileCreditCards.module.scss'
import React from 'react'
import classNames from 'classnames'

import { CardItemSkeleton } from 'components/Pages/ProfileCreditCards/CardItem'

export const ProfileCreditCardsSkeleton: React.FC = () => {
  return (
    <>
      {[0, 1, 2].map((item, index) => (
        <CardItemSkeleton key={index} className={classNames(index !== 0 && css.card__item__skeleton)} />
      ))}
    </>
  )
}
