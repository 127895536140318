import css from './Desktop.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { IEventCollectionMenu } from 'meta/backendControlled/structure'
import { IEventCollectionByDay } from 'meta/backendControlled/structure/eventCollectionMenu'
import { getAutobalancingSlice } from 'meta/backendControlled/structureBackend/adapter/eventCollectionMenu'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { Dates } from 'utils/dates'

import CalendarButton from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/CalendarButton'
import DateButton from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/DateButton'

interface IProps {
  onDateChange(codeUrl: string): void
  data: IEventCollectionMenu
  removeContainer?: boolean
  insideCheckout?: boolean
}

const Desktop: React.FC<IProps> = (props) => {
  const { onDateChange, data, removeContainer, insideCheckout } = props
  const { current, list, rawList, fullpass } = data

  const { t } = useTranslation()

  const [openButton, setOpenButton] = useState<string | null>(null)

  const landscapeDateLimit = fullpass ? 2 : 3
  const tabletDatesLimit = fullpass ? 4 : 5
  const laptopDatesLimit = fullpass ? (insideCheckout ? 5 : 6) : insideCheckout ? 6 : 7

  const getFullPass = () => {
    if (!fullpass) {
      return null
    }

    const dateString = Dates.format(new Date(fullpass.datetime), t('dates:common:yearMonthDay'))
    const sliceObj: IEventCollectionByDay = {
      [dateString]: [fullpass],
    }

    return (
      <DateButton
        dateString={dateString}
        isOpen={false}
        setOpenButton={() => {}}
        isCurrent={false}
        currentCodeUrl={current.codeUrl}
        isCurrentFullpass={current.fullpass}
        onDateChange={onDateChange}
        eventList={sliceObj[dateString]}
      />
    )
  }

  const getButtonList = (slice: IEventCollectionByDay) => {
    return Object.keys(slice).map((dateString, key) => {
      return (
        <DateButton
          key={key}
          dateString={dateString}
          isOpen={dateString === openButton}
          setOpenButton={setOpenButton}
          isCurrent={dateString === Dates.format(new Date(current.datetime), t('dates:common:yearMonthDay'))}
          isCurrentFullpass={current.fullpass}
          currentCodeUrl={current.codeUrl}
          onDateChange={onDateChange}
          eventList={slice[dateString]}
        />
      )
    })
  }

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.landscape}>
        <div
          className={classNames(css.event_date_list_desktop_container, {
            [css.removeContainer]: removeContainer,
          })}
        >
          {getFullPass()}
          {getButtonList(getAutobalancingSlice(landscapeDateLimit, rawList, current))}
          {list.length > landscapeDateLimit && !openButton && (
            <CalendarButton data={data} onDateChange={onDateChange} />
          )}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.landscape} maxWidth={BreakpointsMaxWidth.laptop}>
        <div
          className={classNames(css.event_date_list_desktop_container, {
            [css.removeContainer]: removeContainer,
          })}
        >
          {getFullPass()}
          {getButtonList(getAutobalancingSlice(tabletDatesLimit, rawList, current))}
          {list.length > tabletDatesLimit && !openButton && <CalendarButton data={data} onDateChange={onDateChange} />}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <div
          className={classNames(css.event_date_list_desktop_container, {
            [css.removeContainer]: removeContainer,
          })}
        >
          {getFullPass()}
          {getButtonList(getAutobalancingSlice(laptopDatesLimit, rawList, current))}
          {list.length > laptopDatesLimit && !openButton && <CalendarButton data={data} onDateChange={onDateChange} />}
        </div>
      </MediaQuery>
    </>
  )
}

export default Desktop
