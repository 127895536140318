import React from 'react'

import { Grid } from 'components/Layout/Grid'

import CoverBoxSkeleton from './Coverbox/CoverboxSkeleton'
import HeaderSkeleton from './Header/HeaderSkeleton'

const CollectionDetailsSkeleton: React.FC = () => {
  return (
    <Grid>
      <HeaderSkeleton />
      <CoverBoxSkeleton />
    </Grid>
  )
}

export default CollectionDetailsSkeleton
