import css from './Form.module.scss'
import React, { PropsWithChildren } from 'react'
import { Form as FormikForm, Formik } from 'formik'

import { RequestMethod } from 'meta/api/requestMethod'

import FormButtonSubmit from './FormButtonSubmit'
import FormContext from './FormContext'
import FormError from './FormError'
import FormFields from './FormFields'
import { useInitialValues, useOnSubmit, useValidationSchema } from './hooks'
import { IFormContext, IFormProps } from './types'

const Form: React.FC<PropsWithChildren<IFormProps>> = (props) => {
  const { children, formContextRef, formFields, id, labelSubmit, renderError, renderFields } = props

  const initialValues = useInitialValues(props)
  const validationSchema = useValidationSchema(props)
  const { onSubmit, error } = useOnSubmit(props)

  return (
    <div className={css.formWrapper}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <FormikForm id={id} data-testid={id} className={css.form}>
          {/* @ts-ignore */}
          <FormContext formContextRef={formContextRef} />

          {renderError && <FormError error={error} />}

          {renderFields && (
            <>
              <FormFields formFields={formFields} />
              <FormButtonSubmit labelSubmit={labelSubmit} />
            </>
          )}

          {React.Children.toArray(children)}
        </FormikForm>
      </Formik>
    </div>
  )
}

Form.defaultProps = {
  // @ts-ignore
  formContextRef: React.createRef<IFormContext<any>>(),
  requestMethod: RequestMethod.post,
  recaptcha: undefined,
  renderError: true,
  renderFields: true,
}

export default Form
