import React from 'react'
import MediaQuery from 'react-responsive'

import { ITicket } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import Desktop from 'components/BackendControlled/Component/EventTicket/Ticket/Desktop'
import Mobile from 'components/BackendControlled/Component/EventTicket/Ticket/Mobile'

interface IProps {
  data: ITicket
  callback: Function
}

const Ticket: React.FC<IProps> = (props) => {
  const { callback, data } = props

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <Mobile data={data} callback={callback} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Desktop data={data} callback={callback} />
      </MediaQuery>
    </>
  )
}

export default Ticket
