import css from './Loader.module.scss'
import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import classNames from 'classnames'

import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

interface IProps {
  fixed?: boolean
}

const Loader: React.FC<IProps> = (props) => {
  const { fixed } = props

  return (
    <div className={classNames(css.layout_loader, { [css.fixed]: fixed })}>
      <div className={css.outside}>
        <section className={css.inside}>
          <BeatLoader color={TICKETSMS_COLORS_RED} />
        </section>
      </div>
    </div>
  )
}

Loader.defaultProps = {
  fixed: true,
}

export default Loader
