import { ICheckoutCartTicket } from 'meta/pages/checkout'

export const updateCart = (
  tickets: Array<ICheckoutCartTicket>,
  id: number,
  quantity: number,
  seatId?: string,
  reset: boolean = false
): Array<ICheckoutCartTicket> => {
  const cartTicketIdx = tickets.findIndex((item) => item.typeTicketId === id)

  let value = cartTicketIdx >= 0 ? tickets[cartTicketIdx].quantity : 0
  if (reset) {
    value = 0
  } else {
    value += quantity
  }

  value = value < 0 ? 0 : value

  if (value === 0 && cartTicketIdx >= 0) {
    tickets.splice(cartTicketIdx, 1)
  } else if (cartTicketIdx >= 0) {
    tickets[cartTicketIdx] = { ...tickets[cartTicketIdx], quantity: value }
  } else {
    tickets.push({ typeTicketId: id, quantity: value, seatId })
  }

  return [...tickets]
}
