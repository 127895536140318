import React from 'react'

import { Action, ActionColor, IPropsAction } from './Action'

export enum ButtonType {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

interface IPropsButton extends IPropsAction {
  type?: ButtonType
}

export const Button: React.FC<IPropsButton> = (props) => <Action {...props} />

Button.defaultProps = {
  type: ButtonType.button,
}

// ==== Commonly used Button - First color background color, second border color
export const ButtonBlack: React.FC<IPropsButton> = (props) => (
  <Button {...props} backgroundColor={ActionColor.black} borderColor={ActionColor.black} color={ActionColor.white} />
)

export const ButtonRed: React.FC<IPropsButton> = (props) => (
  <Button {...props} backgroundColor={ActionColor.red} borderColor={ActionColor.red} color={ActionColor.white} />
)

export const ButtonTransparent: React.FC<IPropsButton> = (props) => (
  <Button
    {...props}
    backgroundColor={ActionColor.transparent}
    borderColor={ActionColor.transparent}
    color={ActionColor.black}
  />
)

export const ButtonTransparentBlack: React.FC<IPropsButton> = (props) => (
  <Button
    {...props}
    backgroundColor={ActionColor.transparent}
    borderColor={ActionColor.black}
    color={ActionColor.black}
  />
)

export const ButtonWhite: React.FC<IPropsButton> = (props) => (
  <Button
    {...props}
    backgroundColor={ActionColor.white}
    borderColor={ActionColor.transparent}
    color={ActionColor.black}
  />
)

export const ButtonWhiteBlack: React.FC<IPropsButton> = (props) => (
  <Button {...props} backgroundColor={ActionColor.white} borderColor={ActionColor.black} color={ActionColor.black} />
)

export const ButtonWhiteRed: React.FC<IPropsButton> = (props) => (
  <Button {...props} backgroundColor={ActionColor.white} borderColor={ActionColor.red} color={ActionColor.red} />
)
