import css from './SuccessSkeleton.module.scss'
import React, { useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

const Paragraph: React.FC<{ size: number }> = ({ size }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.terms_skeleton__paragraph}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={4}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          className={css.success_skeleton__content__body}
        />
      ))}
    </div>
  )
}

const SuccessSkeleton: React.FC = () => {
  return (
    <div className={css.success_skeleton}>
      <div className={css.success_skeleton__img}>
        <Skeleton
          className={css.success_skeleton__img__item}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      </div>

      <div className={css.success_skeleton__content}>
        <Skeleton
          className={css.success_skeleton__content__title}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
        <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
          <Paragraph size={4} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
          <Paragraph size={3} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
          <Paragraph size={2} />
        </MediaQuery>
      </div>
    </div>
  )
}

export default SuccessSkeleton
