import axios from 'axios'

import { AppEnv } from 'meta/app'
import { ProcessEnv } from 'utils/env'

const getEnv = async (): Promise<AppEnv> => {
  if (!ProcessEnv.url) {
    throw new Error(`VITE_ENV_URL not found in import.meta.env. Make sure to set up your environment variables.`)
  }

  const { data } = await axios.get<AppEnv>(ProcessEnv.url)

  if (ProcessEnv.disableSatispay) {
    data['DISABLE_SATISPAY'] = Boolean(ProcessEnv.disableSatispay)
  }

  return data
}

export const AppController = {
  getEnv,
}
