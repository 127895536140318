import { RefObject, useCallback, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'
import { CookieClient, CookieName } from 'utils/cookie'

import { useAuthToken } from 'hooks/useAuth'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useTransactionData } from 'hooks/useProfile'

const useTransactions = (sentinel: RefObject<HTMLDivElement>) => {
  const dispatch = useAppDispatch()
  const language = useCurrentLanguage()
  const authToken = useAuthToken()
  const navigate = useNavigate()

  const { transactions, currentPage, hasMorePages } = useTransactionData()

  useEffect(() => {
    if (authToken && transactions.length === 0) {
      dispatch(ProfileActions.fetchTransactions({ page: 0 }))
    }
  }, [authToken, currentPage, dispatch, transactions.length])

  useEffect(() => {
    return () => {
      dispatch(ProfileActions.resetTransactions())
    }
  }, [])

  const handleTransactionError = useCallback(() => {
    CookieClient.remove(CookieName.TOKEN)
    navigate(Routes.ProfileTransactions.generatePath())
  }, [navigate])

  useLayoutEffect(() => {
    if (!sentinel.current) return
    if (!language || !authToken) return
    if (!hasMorePages) return

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (transactions.length > 0) {
              try {
                dispatch(ProfileActions.fetchTransactions({ page: currentPage + 1 }))
              } catch (e) {
                handleTransactionError()
              }
            }
          }
        })
      },
      { threshold: 1 }
    )
    intersectionObserver.observe(sentinel.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [authToken, currentPage, dispatch, handleTransactionError, language, hasMorePages, transactions.length, sentinel])
}

export default useTransactions
