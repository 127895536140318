import css from 'components/Pages/SupportConversation/ConversationMessageForm/ConversationMessageForm.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_RED, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const ConversationMessageFormSkeleton: React.FC = () => {
  return (
    <div className={css.messageFormWrapper}>
      <div className={css.hr} />
      <div className={css.writeMessage__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
      <div className={classNames(css.form__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
        <div className={classNames(css.form__submit__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_RED}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>
    </div>
  )
}
