import css from './Captions.module.scss'
import React from 'react'
import classNames from 'classnames'

import { IVenueAttributesCaption } from 'meta/backendControlled/structure'
import { chunkArrayInGroups } from 'utils/array'

interface IPropsCaptions {
  captions: Array<IVenueAttributesCaption>
  itemsPerRow: number
}

const Captions: React.FC<IPropsCaptions> = (props) => {
  const { captions, itemsPerRow } = props

  const chunkedCaptions = chunkArrayInGroups(captions, itemsPerRow)

  return (
    <div className={css.location_locationattributes_captions_container}>
      {chunkedCaptions.map((row, rKey) => {
        return (
          <div key={rKey} className={classNames(css.row, css[`row_${itemsPerRow}`])}>
            {row.map((elem, key) => {
              return (
                <div className={css.caption_data} key={key}>
                  <div className={css.icon}>
                    <img src={elem.imgUrl} />
                  </div>
                  <div className={css.description}>{elem.description}</div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Captions
