import css from 'components/Pages/ProfileTransactions/Transaction/Actions/Actions.module.scss'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ITransaction, TransactionTicketStatus } from 'meta/pages/profile'

import Action from 'components/Pages/ProfileTransactions/Transaction/Actions/Action'

type Props = {
  data: ITransaction
  toggleOpenTransactionCallback(code: string): void
  isOpen: boolean
}

const Actions: React.FC<Props> = (props) => {
  const { isOpen, toggleOpenTransactionCallback, data } = props
  const areTicketsAvailable = data.tickets && data.tickets.length > 0
  const showRefundButton =
    data.refundable &&
    ((data.tickets && data.tickets.length == 0) ||
      (areTicketsAvailable && data.tickets && data.tickets.filter((x) => x.status == 'valid').length > 0))
  const inRefundStatus =
    data.status == TransactionTicketStatus.refundInitialized || data.status == TransactionTicketStatus.refundCompleted

  const { t } = useTranslation()
  const navigate = useNavigate()

  const openTransitionCallback = useCallback(
    () => toggleOpenTransactionCallback(data.code),
    [data.code, toggleOpenTransactionCallback]
  )
  const assignTicketsCallback = useCallback(
    () => navigate(`/purchase/form?transactionCode=${data.code}&transactionToken=${data.token}`),
    [data.code, data.token, navigate]
  )
  const refundCallback = useCallback(() => navigate(`/refund/${data.code}`), [data.code, navigate])

  return (
    <div className={css.transactionActions}>
      {data.completed || inRefundStatus ? (
        areTicketsAvailable && (
          <Action
            imgUrl="/profile/see_tickets_button_img.svg"
            text={isOpen ? String(t('profile:hide_tickets')) : String(t('profile:see_tickets'))}
            color="white"
            callback={openTransitionCallback}
          />
        )
      ) : (
        <Action
          imgUrl="/profile/assign_tickets_button_img.svg"
          text={String(t('profile:assign_tickets'))}
          color="white"
          callback={assignTicketsCallback}
        />
      )}

      <MediaQuery minWidth={BreakpointsMaxWidth.landscape} className={css.transactionItems}>
        {inRefundStatus && <div className={css.emptyPlaceholder} />}
      </MediaQuery>

      {showRefundButton && (
        <div data-testid={DataTestId.profile.transactionRefundAction}>
          <Action
            imgUrl="/profile/refund_button_img.svg"
            text={String(t('profile:refund'))}
            color="white"
            callback={refundCallback}
          />
        </div>
      )}
    </div>
  )
}

export default Actions
