import { IMapSeat } from 'meta/map'
import { ITypeTicket } from 'meta/pages/checkout'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Params = { codeUrl: string; collectionId: string; seat: IMapSeat }

export const getTypeTickets = async (params: Params): Promise<{ typeTickets: Array<ITypeTicket> }> => {
  const { codeUrl, collectionId, seat } = params
  const { seatId, sectionId } = seat
  const { data } = await HttpRequest.get<{ typeTickets: Array<ITypeTicket> }>(
    ApiEndpoint.collection.mapSeatTypeTickets(codeUrl, String(collectionId), sectionId, seatId)
  )

  return data
}
