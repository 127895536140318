import { MutableRefObject, useState } from 'react'

import { StoryProps } from '../props'
import { usePlay } from './usePlay'
import { PlayActions, usePlayActions } from './usePlayActions'
import { usePlayEvents } from './usePlayEvents'
import { useSwipeUp } from './useSwipeUp'

export type StoryState = {
  idxActive: number
  playActions: PlayActions
}

export const useStoryState = (
  props: StoryProps,
  storyRef: MutableRefObject<HTMLDivElement>,
  progressRef: MutableRefObject<HTMLDivElement>,
  swipeUpRef: MutableRefObject<HTMLButtonElement>,
  videoRef: MutableRefObject<HTMLVideoElement>
): StoryState => {
  const [idxActive, setIdxActive] = useState<number>(0)
  const play = usePlay(storyRef)
  const playActions = usePlayActions(props, idxActive, setIdxActive, play, progressRef, videoRef)
  usePlayEvents(props, idxActive, play, playActions, videoRef)
  useSwipeUp(props, swipeUpRef)

  return { idxActive, playActions }
}
