import { IWalletTicket, IWalletTicketFilter } from 'meta/pages/wallet'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

export const getTickets = async (params: { eventsType: IWalletTicketFilter }): Promise<Array<IWalletTicket>> => {
  const { eventsType } = params

  const { data } = await HttpRequest.get<{ walletList: Array<IWalletTicket> }>(
    ApiEndpoint.users.walletEventsWithFilter(eventsType)
  )

  return data.walletList
}
