import React from 'react'

import { ConversationMessageSkeleton } from 'components/Pages/SupportConversation/ConversationMessage/ConversationMessageSkeleton'

export const SupportConversationSkeleton: React.FC = () => {
  return (
    <>
      {[0, 1].map((item) => (
        <ConversationMessageSkeleton key={item} isOperator={item % 2 !== 0} />
      ))}
    </>
  )
}
