import { ISearchParams } from 'meta/pages/search'
import { SearchController } from 'controller/search'
import { SearchActions } from 'store/pages/search/actions/index'
import { AppDispatch } from 'store/types'
import { Functions } from 'utils/functions'

export const throttleFindNext = Functions.throttle(
  async (params: ISearchParams & { dispatch: AppDispatch }) => {
    const { dispatch, ...searchParams } = params
    const result = await SearchController.find(searchParams)
    dispatch(SearchActions.updateResult(result))
  },
  250,
  { leading: true, trailing: false }
)
