import { createAsyncThunk } from '@reduxjs/toolkit'

import { IMapSection, IMapSectors } from 'meta/map'
import { EventController } from 'controller/event'

type Returned = {
  id: string
  seatsBusy: Array<string>
  sectionsEnabled: Array<IMapSection>
  linkedSeats: Record<string, Array<string>>
  sectors?: IMapSectors
}

type Params = { eventId: string | number; id: string }

export const initSeats = createAsyncThunk<Returned, Params>('pages/event/initSeats', async ({ eventId, id }) => {
  const [seatsBusy, { linkedSeats, sectors, sectionsEnabled }] = await Promise.all([
    EventController.getBusySeats({ eventId }),
    EventController.getSectionsEnabled({ eventId }),
  ])

  return { id, seatsBusy, sectionsEnabled, linkedSeats, sectors }
})
