import { BackendControlledComponents } from 'meta/backendControlled/structure'
import {
  ComponentsBackendResponseAdapter,
  IComponentsBackendResponseData,
  IEventMetadataResponse,
} from 'meta/backendControlled/structureBackend'
import { HttpRequest } from 'utils/http'

type Props = {
  path: string
}

const getComponents = async (props: Props): Promise<BackendControlledComponents> => {
  const { path } = props

  const { data, metadata: responseMetadata } = await HttpRequest.get<
    IComponentsBackendResponseData,
    IEventMetadataResponse
  >(path)

  const { components, metadata } = ComponentsBackendResponseAdapter.convertResponse(data)

  return { components, metadata: { ...metadata, ...responseMetadata } }
}

export const BackendControlledController = {
  getComponents,
}
