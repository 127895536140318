import css from './TransactionError.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckoutStep, ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import RoundedBox from 'components/TextBoxes/RoundedBox'
import { useCheckoutDetails } from 'hooks/useCheckout'

const TransactionError: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { transactionErrorMessage } = useCheckoutDetails() as ICheckoutDetails

  return (
    <div className={css.checkout_steps_transactionerror_container}>
      <div className={css.transaction_error_body}>
        <div className={css.title}>{t('checkout:transaction_error')}</div>

        <RoundedBox infoType="error">
          <div>{transactionErrorMessage}</div>
        </RoundedBox>

        <div
          className={css.go_back}
          onClick={() => dispatch(CheckoutActions.updateStep({ step: CheckoutStep.select }))}
        >
          {t('checkout:transaction_error_goback')}
        </div>
      </div>
    </div>
  )
}

export default TransactionError
