import css from './VoucherRecap.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CurrencyCode } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ICheckoutPayment, ICheckoutRecap, IVoucherRecap, PaymentMethod } from 'meta/pages/checkout'
import { convertAndFormat } from 'helpers/currency/converter'

import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutPayment, useCheckoutRecap } from 'hooks/useCheckout'

interface IProps {
  data?: IVoucherRecap
  goBackCallback(): void
  editCallback(): void
}

const VoucherRecap: React.FC<IProps> = (props) => {
  const { goBackCallback, editCallback } = props

  const { t } = useTranslation()
  const { couponCode, couponCodeAmount, methodCurrent } = useCheckoutPayment() as ICheckoutPayment

  const { activeCurrency } = useActiveCurrency()
  const { extra, total } = useCheckoutRecap() as ICheckoutRecap

  const getVoucherAmount = (): string => {
    if (!extra?.voucherDetails) {
      return couponCodeAmount as string
    }

    return convertAndFormat(extra.voucherDetails.amountAvailable.amount / 100, CurrencyCode.EUR, activeCurrency)
  }

  const isAmountSufficient = (): boolean => {
    if (!extra?.voucherDetails) {
      return true
    }

    return extra.voucherDetails.amountAvailable.amount / 100 - total.amount >= 0
  }

  return (
    <div className={css.voucher_recap_container}>
      <div className={css.header_wrapper}>
        <div className={css.goBackWrapper} onClick={goBackCallback}>
          <div className={css.goBack}>
            <Img src="/arrows/white-left.svg" />
          </div>
          {methodCurrent === PaymentMethod.voucher && <h3>{t('checkout:voucher')}</h3>}
          {methodCurrent === PaymentMethod.diciottoApp && <h3>{t('checkout:diciotto_app')}</h3>}
        </div>
        <div className={css.editWrapper} onClick={editCallback} data-testid={DataTestId.checkout.btnVoucherEdit}>
          <div className={css.text}>{t('checkout:cc_edit')}</div>
          <Img src="/icons/edit_pencil_icon.svg" />
        </div>
      </div>
      <div className={css.content}>
        <div className={css.infobox_container}>
          <div className={css.infobox_row}>
            <h4>{t('checkout:inserted_coupon_code')}</h4>
            <h4>{couponCode}</h4>
          </div>
          {methodCurrent === PaymentMethod.voucher && (
            <div className={css.infobox_row}>
              <h4>{t('checkout:residual_amount')}</h4>
              <h4 className={classNames({ [css.insufficientAmount]: !isAmountSufficient() })}>{getVoucherAmount()}</h4>
            </div>
          )}
          {Boolean(extra) && !isAmountSufficient() && (
            <div className={`${css.infobox_row} ${css.right}`}>
              <div className={css.insufficientAmount}>{t('checkout:insufficient_voucher_amount')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VoucherRecap
