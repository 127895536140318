import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import { IBlog, IBlogPost } from 'meta/backendControlled/structure'
import {
  BlogPostsBackendResponseAdapter,
  IBlogPostsBackendResponse,
} from 'meta/backendControlled/structureBackend/response/blog'

import Category from 'components/BackendControlled/Component/Category'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { GridContainer } from 'components/Layout/Grid'
import { useBlogApiBase, useBlogApiKey } from 'hooks/useEnv'

const Blog: React.FC<IPropsComponent<IBlog>> = (props) => {
  const { uuid, button } = props

  const { t } = useTranslation()
  const blogApiBase = useBlogApiBase()
  const blogApiKey = useBlogApiKey()

  const [blogPosts, setBlogPosts] = useState<Array<IBlogPost>>()

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${blogApiBase}${blogApiKey}`)

      //@ts-ignore
      setBlogPosts(BlogPostsBackendResponseAdapter.convertResponse(response.data as IBlogPostsBackendResponse))
    }

    fetchData()
  }, [])

  return (
    <GridContainer id={getComponentId(uuid)}>
      {blogPosts && <Category button={button} data={blogPosts} title={t('common:blog')} />}
    </GridContainer>
  )
}

export default Blog
