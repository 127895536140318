export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  DOP = 'DOP',
  HRK = 'HRK',
  GBP = 'GBP',
  NONE = 'NONE',
}

export enum CurrencySymbol {
  EUR = '€',
  USD = '$',
  CHF = 'CHF',
  DOP = 'RD$',
  HRK = 'kn',
  GBP = '£',
  NONE = '-',
}

export interface ICurrency {
  activeCurrency: CurrencyCode
}
