import css from './Cover.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

export const CoverSkeleton: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.tablet]: 13, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={17}
      gridRowStart={1}
    >
      <div className={classNames(css.image_skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
    </GridColumn>
  )
}
