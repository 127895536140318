import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'

export const StepColumn: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children } = props

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.laptop]: 3 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 9 }}
      {...props}
    >
      {React.Children.toArray(children)}
    </GridColumn>
  )
}
