import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldSelectOption {
  label: string
  value: string
}

export interface IFormFieldSelect extends IFormField<FormFieldType.select> {
  options: Array<IFormFieldSelectOption>
  translateLabels: boolean
}

export class SelectBuilder extends FormFieldBuilder<FormFieldType.select> {
  constructor(
    name: string,
    options?: Array<IFormFieldSelectOption>,
    translateLabels: boolean = false,
    nameValidation?: string
  ) {
    super(name, FormFieldType.select, nameValidation)
    if (options) {
      super.addField('options', options)
    }
    super.addField('translateLabels', translateLabels)
  }
}
