import React, { ImgHTMLAttributes } from 'react'

import { useUrlCloudfront } from 'hooks/useEnv'

const Img: React.FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const { src } = props

  const urlCloudfront = useUrlCloudfront()

  if (!urlCloudfront) {
    return null
  }

  return <img {...props} src={`${urlCloudfront}/images${src}`} />
}

Img.defaultProps = {
  alt: '',
  className: '',
  height: undefined,
  width: undefined,
}

export default Img
