import css from './Mobile.module.scss'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICheckoutDetails, ICheckoutRecap } from 'meta/pages/checkout'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useCheckoutDetails, useCheckoutExtraDetails, useCheckoutRecap } from 'hooks/useCheckout'

import Tickets from '../Tickets'
import Header from './Header'
import Help from './Help'
import Subtotals from './Subtotals'
import Terms from './Terms'
import Totals from './Totals'

interface IProps {
  continueCallback(): void
  timerComponent: ReactElement
}

const Mobile: React.FC<IProps> = (props) => {
  const { continueCallback, timerComponent } = props
  const [isOpen, toggleOpen] = useState<boolean>(false)

  const { t } = useTranslation()

  const recap = useCheckoutRecap() as ICheckoutRecap
  const { hasMap, insideIFrame } = useCheckoutDetails() as ICheckoutDetails

  const checkoutExtraDetails = useCheckoutExtraDetails()

  const [acceptExtraTermsAndConditions, setAcceptExtraTermsAndConditions] = useState<boolean>(false)

  const handleExtraTermsAndConditions = () => {
    setAcceptExtraTermsAndConditions(!acceptExtraTermsAndConditions)
  }

  const handleContinue = () => {
    if (
      checkoutExtraDetails &&
      checkoutExtraDetails.additionalTermsAndConditions &&
      checkoutExtraDetails.additionalTermsAndConditions.isRequired &&
      !acceptExtraTermsAndConditions
    ) {
      const alertMsg = String(t('checkout:accept_extra_terms_and_conditions'))

      alert(alertMsg)

      return
    }

    continueCallback()
  }

  return (
    <GridContainer>
      <Grid>
        <GridColumn gridColumnStart={1} gridColumnEnd={17}>
          <div className={css.checkout_recapbox_mobile_container}>
            <Header isOpen={isOpen} toggleOpen={() => toggleOpen((prev) => !prev)} />

            {isOpen && (
              <div className={css.content}>
                <Tickets data={recap.tickets} doesEventHaveMap={hasMap} />

                <Subtotals recapData={recap} />
              </div>
            )}

            {!insideIFrame && <Help timerComponent={timerComponent} />}

            <Terms handleExtraTermsAndConditions={handleExtraTermsAndConditions} />

            <Totals continueCallback={handleContinue} recapData={recap} />
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default Mobile
