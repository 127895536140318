import { cancel } from 'controller/checkout/cancel'
import { fetchRecap } from 'controller/checkout/fetchRecap'
import { getDetails } from 'controller/checkout/getDetails'
import { getSeasonEvents } from 'controller/checkout/getSeasonEvents'
import { prepare } from 'controller/checkout/prepare'
import { process } from 'controller/checkout/process'

export const CheckoutController = {
  cancel,
  fetchRecap,
  getDetails,
  getSeasonEvents,
  prepare,
  process,
}
