export enum FormFieldType {
  checkbox = 'checkbox',
  date = 'date',
  hidden = 'hidden',
  linked = 'linked',
  number = 'number',
  password = 'password',
  phone = 'phone',
  radio = 'radio',
  select = 'select',
  text = 'text',
  textArea = 'textArea',
}

export enum FormDataType {
  birthDate = 'birthDate',
  date = 'date',
  fiscalCode = 'fiscalCode',
  textArea = 'textArea',
}

export interface IFormFieldValidationPattern {
  value: RegExp
  message: string
}

export interface IFormFieldValidation {
  max?: number
  required?: string
  pattern?: IFormFieldValidationPattern
  validate?: { message: string; test: (value: string | boolean) => boolean }
}

export interface IFormField<T extends FormFieldType> {
  name: string
  fieldLabel?: string
  type: T
  validation?: IFormFieldValidation
  placeholder?: string
  value?: string | number | Date
  removeFutureDates?: boolean
}
