import { ISearchCity, ISearchEventCategory, ISearchParams, ISearchResult } from 'meta/pages/search'
import { SliceSelector } from 'store/types'

const getCities: SliceSelector<Array<ISearchCity> | undefined> = () => (state) => state.pages.search.cities

const getEventCategories: SliceSelector<Array<ISearchEventCategory> | undefined> = () => (state) =>
  state.pages.search.eventCategories

const getParams: SliceSelector<ISearchParams> = () => (state) => state.pages.search.params

const getResult: SliceSelector<ISearchResult | undefined> = () => (state) => state.pages.search.result

const isFetching: SliceSelector<boolean> = () => (state) => state.pages.search.fetching

export const SearchSelectors = {
  getCities,
  getEventCategories,
  getParams,
  getResult,
  isFetching,
}
