import css from './Login.module.scss'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { BeatLoader } from 'react-spinners'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { Routes } from 'meta/routes'
import { TICKETSMS_COLORS_BLACK } from 'utils/constants/colors'
import { shortenNameIfLongerThan } from 'utils/string'

import { ActionAlignment, ActionColor, Button, Link } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useAuthIsPending, useUserData } from 'hooks/useAuth'

type Props = {
  showLogin(): void
}

const Login: React.FC<Props> = (props) => {
  const { showLogin } = props

  const { t } = useTranslation()

  const userData = useUserData()
  const userLoggedIn = Boolean(userData)
  const authIsPending = useAuthIsPending()

  const LoginButton: React.FC<PropsWithChildren<{ backgroundColor: ActionColor; borderColor: ActionColor }>> = (
    props
  ) => {
    const { backgroundColor, borderColor, children } = props

    const Element = userLoggedIn ? Link : Button
    const color = userLoggedIn ? ActionColor.black : ActionColor.white
    const href = userLoggedIn ? Routes.Profile.generatePath() : '/login'
    const onClick = userLoggedIn ? null : showLogin
    const dataTestid = userLoggedIn ? DataTestId.header.btnLogged : DataTestId.header.btnLogin

    return React.createElement(
      // @ts-ignore
      Element,
      {
        alignment: ActionAlignment.iconRight,
        backgroundColor,
        borderColor,
        color,
        className: classNames(css.headerButtonLogin, { [css.pending]: authIsPending }),
        'data-testid': dataTestid,
        disabled: authIsPending,
        href,
        // @ts-ignore
        onClick,
      },
      authIsPending ? <BeatLoader color={TICKETSMS_COLORS_BLACK} size={6} /> : children
    )
  }

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        {/*@ts-ignore */}
        <LoginButton
          backgroundColor={userLoggedIn ? ActionColor.white : ActionColor.white}
          borderColor={userLoggedIn ? ActionColor.black : ActionColor.white}
        >
          <Img src={userLoggedIn ? '/profile/avatar_black.svg' : '/profile/avatar_grey.svg'} />
        </LoginButton>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        {/*@ts-ignore */}
        <LoginButton
          backgroundColor={userLoggedIn ? ActionColor.white : ActionColor.grey}
          borderColor={userLoggedIn ? ActionColor.black : ActionColor.grey}
        >
          <h5>
            {userLoggedIn && userData && userData.firstName && userData.lastName
              ? `${shortenNameIfLongerThan(userData.firstName, 8)} ${userData.lastName[0]}.`
              : t('header:login')}
          </h5>
          <Img src={userLoggedIn ? '/profile/avatar_black.svg' : '/profile/avatar.svg'} />
        </LoginButton>
      </MediaQuery>
    </>
  )
}

export default Login
