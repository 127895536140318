import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Loader from 'components/Layout/Loader'

const LocationRedirect: React.FC = () => {
  const navigate = useNavigate()

  const params = new URLSearchParams(window.location.search)
  const ln = params.get('ln')

  useEffect(() => {
    if (ln !== 'undefined') {
      navigate(`/location/${ln}`)
    } else {
      navigate(`/404`)
    }
  }, [ln, navigate])

  return <Loader />
}

export default LocationRedirect
