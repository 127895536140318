import { createAsyncThunk } from '@reduxjs/toolkit'

import { IMapSeat } from 'meta/map'
import { ITicketSeat, ITypeTicket } from 'meta/pages/checkout'
import { CollectionController } from 'controller/collection'
import { EventController } from 'controller/event'
import { CheckoutActions } from 'store/pages/checkout/actions/index'
import { RootState } from 'store/types'

type Returned = { eventId: number; ticketSeat: ITicketSeat } | undefined
type Params = { codeUrl: string; eventId: number; seat: IMapSeat; typeTicketId?: number }

type SeasonParams = { codeUrl: string; collectionId: string; seat: IMapSeat; typeTicketId?: number }

const getSelectedTypeTicketId = (params: { typeTickets: Array<ITypeTicket>; typeTicketId?: number }) => {
  const { typeTickets, typeTicketId } = params
  let selectedTypeTicketId = null
  if (typeTickets.length > 0) {
    selectedTypeTicketId = typeTicketId
    if (!selectedTypeTicketId) {
      const getPrice = (t: ITypeTicket): number => Number(t?.presale?.amount) + Number(t?.price?.amount)
      const typeTicket =
        typeTickets.find((typeTicket) => typeTicket.description === 'Intero') ||
        typeTickets.reduce((ticket, ticketCurrent) => {
          const price = getPrice(ticket)
          const priceCurrent = getPrice(ticketCurrent)
          return price >= priceCurrent ? ticket : ticketCurrent
        }, {} as ITypeTicket)
      selectedTypeTicketId = typeTicket.id
    }
  }
  return selectedTypeTicketId
}

export const selectSeat = createAsyncThunk<Returned, Params, { state: RootState }>(
  'checkout/seat/select',
  async (params, { dispatch }) => {
    const { seat, typeTicketId, codeUrl, eventId } = params
    const { seatId, sectionId } = seat

    const { typeTickets } = await EventController.getTypeTickets({ eventId, seat, codeUrl })
    const selectedTypeTicketId = getSelectedTypeTicketId({ typeTickets, typeTicketId })

    if (!selectedTypeTicketId) {
      alert('Nessun biglietto è attualmente disponibile per il posto selezionato')
      dispatch(CheckoutActions.deselectSeat({ seatId }))
      return undefined
    }

    const ticketSeat: ITicketSeat = {
      seatId,
      sectionId,
      selectedTypeTicketId,
      typeTickets,
    }

    return { eventId, ticketSeat }
  }
)

export const selectSeatForSeasonEvent = createAsyncThunk<Returned, SeasonParams, { state: RootState }>(
  'checkout/seat/select',
  async (params, { dispatch }) => {
    const { seat, typeTicketId, codeUrl, collectionId } = params
    const { seatId, sectionId } = seat

    const { typeTickets } = await CollectionController.getTypeTickets({ codeUrl, collectionId, seat })
    const selectedTypeTicketId = getSelectedTypeTicketId({ typeTickets, typeTicketId })

    if (!selectedTypeTicketId) {
      alert('Nessun biglietto è attualmente disponibile per il posto selezionato')
      dispatch(CheckoutActions.deselectSeat({ seatId }))
      return undefined
    }

    const ticketSeat: ITicketSeat = {
      seatId,
      sectionId,
      selectedTypeTicketId,
      typeTickets,
    }

    return { eventId: Number(collectionId), ticketSeat }
  }
)
