import { FormFieldType, IFormField } from 'meta/form'
import { ITicketCovidAppointment } from 'meta/pages/tickets'

export enum PersonalDataField {
  dateOfBirth = 'dateOfBirth',
  birthplaceCountry = 'birthplaceCountry',
  birthplaceCity = 'birthplaceCity',
  gender = 'gender',
}

export const PERSONAL_DATA_FIELDS = [
  PersonalDataField.dateOfBirth,
  PersonalDataField.birthplaceCountry,
  PersonalDataField.birthplaceCity,
  PersonalDataField.gender,
]

export interface ICovid19Field {
  appointment: ITicketCovidAppointment
  hiddenFieldId: string
  formField: IFormField<FormFieldType>
}

export interface ITicketsFormFields {
  commonFields: Record<number, Array<IFormField<FormFieldType>>>
  extraFields: Record<number, Array<IFormField<FormFieldType>>>
  covid19Fields: Record<number, Array<ICovid19Field>>
  personalDataFields: Record<number, Array<IFormField<FormFieldType>>>
}
