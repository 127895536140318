import css from './Subtotals.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CurrencySymbol } from 'meta/currency'
import { ICheckoutRecap } from 'meta/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'

import { useActiveCurrency } from 'hooks/useActiveCurrency'

interface IProps {
  recapData: ICheckoutRecap
}

const Subtotals: React.FC<IProps> = (props) => {
  const { recapData } = props
  const { subtotal, commission } = recapData

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()

  const eventCurrencyCode = getCodeFromSymbol(recapData.total.symbol as CurrencySymbol)

  const getTotalQuantities = (): number => {
    let totalQty = 0

    recapData.tickets.map((x) => {
      totalQty += parseInt(x.quantity.replace('x', ''))
    })

    return totalQty
  }

  return (
    <div className={css.checkout_recapbox_mobile_subtotals_container}>
      <div className={classNames(css.sbt_box, css.quantity)}>
        <div className={css.label}>{t('checkout:recap_quantity')}</div>
        <div className={css.amount}>{`x${getTotalQuantities()}`}</div>
      </div>

      <div className={css.sbt_box}>
        <div className={css.label}>{t('checkout:recap_subtotal')}</div>
        <div className={css.amount}>{convertAndFormat(subtotal.amount, eventCurrencyCode, activeCurrency, true)}</div>
      </div>

      <div className={css.sbt_box}>
        <div className={css.label}>{t('checkout:recap_commissions')}</div>
        <div className={css.amount}>{convertAndFormat(commission.amount, eventCurrencyCode, activeCurrency, true)}</div>
      </div>
    </div>
  )
}

export default Subtotals
