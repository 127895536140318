import css from 'components/Pages/ProfileTransactions/Transaction/Header/Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ITransaction } from 'meta/pages/profile'
import { Dates } from 'utils/dates'

import Img from 'components/Media/Img'

type Props = {
  data: ITransaction
}

const Header: React.FC<Props> = (props) => {
  const { data } = props

  const { t } = useTranslation()

  return (
    <div className={css.auth_profile_sections_transactions_transaction_header_container}>
      <div className={css.black_area}>
        <div className={`${css.voice} ${css.transaction_date}`}>
          <div className={css.day}>{Dates.format(data.date, t('dates:common:dayMonthYear'))}</div>
          <div className={css.hours}>{Dates.format(data.date, t('dates:common:hoursMinutes'))}</div>
        </div>

        <div className={`${css.voice} ${css.transaction_code}`}>
          <div className={css.description}>{t('profile:transaction_code')}</div>
          <div className={css.value}>{data.code}</div>
        </div>
      </div>

      <div className={css.tip_container}>
        <Img src="/profile/transaction_tip_white.svg" />
      </div>

      <div className={css.white_area}></div>
    </div>
  )
}

export default Header
