import cssForm from '../../Form.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { Dates } from 'utils/dates'

import { DatePicker } from 'components/Forms/DatePicker'
import Modal from 'components/Layout/Modal'

import { IFormFieldProps } from '../props'
import useFormField from '../useFormField'

interface IDateValue {
  raw?: Date
  input?: string
  display?: string
}

const InputDate: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, placeholder, value, removeFutureDates } = formField
  const { t } = useTranslation()

  const { setValue } = useFormField(name)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDateValue = (date: any): IDateValue => ({
    raw: date,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    input: date ? Dates.format(date, t('dates:common:yearMonthDay')) : null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    display: date ? Dates.format(date, t('dates:common:dayMonthYear')) : String(t(placeholder)),
  })
  const [dateValue, setDateValue] = useState<IDateValue>(() => getDateValue(value))
  const [showPicker, setShowPicker] = useState<boolean>(false)

  return (
    <>
      <button className={cssForm.inputDate} id={`${name}_button`} onClick={() => setShowPicker(true)} type="button">
        {dateValue.display}
      </button>

      <Field data-testid={name} id={name} name={name} placeholder={t(`${placeholder}`)} type="hidden" />

      {showPicker && (
        <Modal fullscreenOnMobile show={showPicker} handleClose={() => setShowPicker(false)}>
          <DatePicker
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            date={dateValue.raw}
            onChange={(date) => {
              const dateValueUpdate = getDateValue(date)
              setDateValue(dateValueUpdate)
              setValue(dateValueUpdate.input)
              setShowPicker(false)
            }}
            maximumDate={removeFutureDates ? new Date() : undefined}
          />
        </Modal>
      )}
    </>
  )
}

export default InputDate
