import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { StringSchema } from 'yup'

import { TFunction } from 'i18next'
import { FormFieldType, IFormFieldPhone, IFormFieldValidation } from 'meta/form'

import { IFormProps } from '../types'

const toValidationObject = (validation: IFormFieldValidation, t: TFunction): any => {
  const { max, pattern, required, validate } = validation

  let object: StringSchema = Yup.string().trim()
  if (max) {
    object = object.max(max)
  }
  if (pattern) {
    object = object.matches(pattern.value, t(pattern.message) as string)
  }
  if (required) {
    object = object.required(t(required) as string)
  }
  if (validate) {
    object = object.test({
      message: t(validate.message) as string,
      test: validate.test,
    })
  }
  return object
}

export const useValidationSchema = (props: IFormProps): Record<string, any> => {
  const { formFields } = props

  const { t } = useTranslation()
  const object = formFields.reduce<Record<string, any>>((schema, field) => {
    const { name, type, validation } = field
    if (validation) {
      schema[name] = toValidationObject(validation, t)
    }

    if (type === FormFieldType.phone) {
      const { namePrefix, validationPrefix } = field as IFormFieldPhone
      if (validationPrefix && namePrefix) {
        schema[namePrefix] = toValidationObject(validationPrefix, t)
      }
    }

    return schema
  }, {})

  return Yup.object(object)
}
