import css from './Copyright.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProcessEnv } from 'utils/env'

import { GridColumn } from 'components/Layout/Grid'

interface Props {}

const Copyright: React.FC<Props> = (props) => {
  const { t } = useTranslation(['footer'])

  return (
    <GridColumn gridColumnEnd={17} className={css.footerCopyright}>
      &copy; <b>TicketSms</b>_{t('footer:all_rights_reserved')}
      {` | v`}
      {ProcessEnv.version}
    </GridColumn>
  )
}

export default Copyright
