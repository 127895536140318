export interface ISupportFormTopic {
  icon: string
  title: string
  id: number
}

export interface ISupportForm {
  topics: Array<ISupportFormTopic>
}

// TODO: Usage not found
export interface ISupportFormResponse {
  data: ISupportForm
  status: number
}

// TODO: Usage not found
export enum SupportConversationStatus {
  NEW = 0,
  WAITING_REPLY = 1,
  PROGRESS = 2,
  CLOSED = 3,
  ISSUE = 4,
}

export interface ISupportConversationMessage {
  isOperator: boolean
  text: string
  dateCreation: string
  isNew?: boolean
}

export interface ISupportConversation {
  conversation: Array<ISupportConversationMessage>
  codeRequest: string
  firstName: string
  lastName: string
  status: SupportConversationStatus
  dateCreation: string
}

// TODO: Usage not found
export interface ISupportConversationResponse {
  data: ISupportConversation
  status: number
}
