import css from './Card.module.scss'
import React from 'react'
import classNames from 'classnames'

export enum CardImageType {
  background = 'background',
  top = 'top',
  cover = 'cover',
}

interface IPropsCard extends React.HTMLAttributes<HTMLDivElement> {
  imageType?: CardImageType
  title?: string
}

const Card: React.FC<IPropsCard> = (props) => {
  const { children, className, imageType, title, ...rest } = props

  return (
    <div
      title={title ?? ''}
      className={classNames(css.cardContainer, css[`cardImage_${imageType}`], className)}
      {...rest}
    >
      {React.Children.toArray(children)}
    </div>
  )
}

Card.defaultProps = {
  imageType: CardImageType.top,
}

export default Card
