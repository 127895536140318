import { generatePath } from 'react-router-dom'

import { Root } from 'meta/routes/root'
import { Route } from 'meta/routes/route'

type Params = {
  parent?: Route<any, any>
  path: string
}

const getGeneratePath =
  <PathParams, QueryParams>(path: string, parent: Route<any, any>): Route<PathParams, QueryParams>['generatePath'] =>
  (pathParams, queryParams) => {
    // @ts-ignore
    let generatedPath = generatePath(path, pathParams ?? {})
    if (queryParams) {
      const searchParams = new URLSearchParams(queryParams)
      generatedPath += `?${searchParams}`
    }
    const parentPath = `${parent.generatePath(pathParams)}`
    return `${parentPath === Root.path ? parentPath : `${parentPath}/`}${generatedPath}`
  }

export const createRoute = <PathParams = undefined, QueryParams extends Record<string, any> = {}>(
  params: Params
): Route<PathParams, QueryParams> => {
  const { parent = Root, path } = params

  const generatePath = getGeneratePath(path, parent)
  const parts = path.split('/')
  return { generatePath, parent, path, parts }
}
