import css from './Profile.module.scss'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Header from 'components/Auth/Profile/Header'
import Menu from 'components/Auth/Profile/Menu'
import { ProfileSkeleton } from 'components/Auth/Profile/ProfileSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation from 'components/HOC/WithTranslation'

const Profile: React.FC = () => {
  const pathNames = useLocation().pathname.split('/')
  const section = pathNames[pathNames.length - 1]

  return (
    <div className={css.profileWrapper}>
      <WithAppInitialized requireUser skeleton={<ProfileSkeleton />}>
        <>
          <Header />
          <Menu />
        </>
      </WithAppInitialized>
      <div className={css.profileWrapper__sectionWrapper}>
        <div className={classNames(css.profileWrapper__section, css[section])}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const namespaces = ['profile']

export default withTranslation(namespaces)(Profile)
