import React from 'react'

import { FormBuilder, InputDateBuilder, TextBuilder } from 'meta/form'
import { LoginData } from 'meta/pages/login'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import Form from 'components/Forms/Form'
import { useLoginData, useLoginStep } from 'hooks/useLogin'

import { IStepProps, Step } from '../StepCommon'

const WhenWhere: React.FC<IStepProps> = () => {
  const dispatch = useAppDispatch()
  const data = useLoginData()
  const step = useLoginStep()

  const birthDate = new InputDateBuilder('birthDate')
    .addRequiredBeforeNowValidation()
    .removeFutureDates()
    //@ts-ignore
    .addValue(data.birthDate === '' ? null : new Date(data.birthDate))
    .addPlaceholder('form:birthDate')
    .build()
  const birthPlace = new TextBuilder('birthPlace')
    .addRequiredNameValidation()
    .addValue(data.birthPlace)
    .addPlaceholder('form:birthPlace')
    .build()
  const formFields = new FormBuilder().addField(birthDate).addField(birthPlace).build()

  //@ts-ignore
  const onSuccess = (values) => {
    const dataUpdate: LoginData = { ...data, birthPlace: values.birthPlace, birthDate: values.birthDate }
    dispatch(LoginActions.updateData({ data: dataUpdate, step: step + 1 }))
  }

  return (
    <>
      <Step icon="/login/step_date.svg" labelKey="registerV2:when_where_born">
        <Form
          action={undefined}
          //@ts-ignore
          formFields={formFields}
          labelSubmit="registerV2:next_step_label"
          onSuccess={onSuccess}
        />
      </Step>
    </>
  )
}

export default WhenWhere
