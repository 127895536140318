import smartlookClient from 'smartlook-client'

import { EnvType } from 'meta/app'
import { IUserData } from 'meta/auth'

export const SmartLook = {
  track: (event_type_id: string, data: any) => {
    smartlookClient.track(event_type_id, data)
  },

  init: (smartlookKey: string, appEnv: string, userData?: IUserData) => {
    const startRecording = appEnv === EnvType.production

    if (smartlookKey && startRecording) {
      smartlookClient.init(smartlookKey)

      if (userData) {
        smartlookClient.identify(userData.email, {
          email: userData.email,
          name: userData.firstName,
        })
      }
    }
  },
}
