import { IBlogPost } from '../../structure'
import ComponentsAdapter from '../adapter/componentsAdapter'
import { IBlogPostBackend } from '../component/blog'
import { ComponentTypeBackend } from '../component/component'

export interface IBlogPostsBackendResponse {
  pagination: object
  posts: Array<IBlogPostBackend>
}

export abstract class BlogPostsBackendResponseAdapter {
  static convertResponse(response: IBlogPostsBackendResponse): Array<IBlogPost> {
    const componentsAdapter = new ComponentsAdapter()
    const blogPostsBackend = response.posts.map((blogPost) => ({
      ...blogPost,
      componentType: ComponentTypeBackend.blogPost,
    }))
    return componentsAdapter.convertComponents(blogPostsBackend) as Array<IBlogPost>
  }
}
