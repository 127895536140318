import React, { useCallback, useEffect, useState } from 'react'

import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'

import ResaleForm from 'components/Pages/ProfileResale/ResaleForm'
import Success from 'components/Pages/ProfileResale/Success'

const ProfileResale: React.FC = () => {
  const dispatch = useAppDispatch()

  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const onSuccessClose = useCallback(() => setShowSuccess(false), [])
  const onResaleFormSuccess = useCallback(() => setShowSuccess(true), [])

  useEffect(() => {
    return () => {
      dispatch(ProfileActions.resetTicketResale())
    }
  }, [dispatch])

  if (showSuccess) {
    return <Success onClose={onSuccessClose} />
  }

  return <ResaleForm onSuccess={onResaleFormSuccess} />
}

export default ProfileResale
