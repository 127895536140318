import { IMapSectors } from '../map'

export const mapStyles = (sectors?: IMapSectors) => [
  // Level 0. sections are visible, seats are hidden (no styles needed).
  {
    // Level 0 styles for nodes with "section" type
    section: {
      available: {
        normal: {
          none: {
            fillStyle: '#538e49',
            fillOpacity: 0.7,
            strokeStyle: '#538e49',
            lineWidth: 0.01,
            text: false,
            // textFontSize: 6,
            cursor: 'auto',
          },
          ...(sectors
            ? Object.keys(sectors).reduce((styles, sectorUuid) => {
                const sector = sectors[sectorUuid]
                const { color } = sector
                return {
                  ...styles,
                  [sectorUuid]: {
                    fillStyle: color,
                    fillOpacity: 0.7,
                    strokeStyle: color,
                    lineWidth: 0.01,
                    text: false,
                    // textFontSize: 6,
                    cursor: 'auto',
                  },
                }
              }, {})
            : {}),
        },
        hover: {
          none: {
            fillStyle: '#eb0029',
            fillOpacity: 0.7,
            strokeStyle: '#eb0029',
            lineWidth: 0.01,
            text: false,
            textFontSize: 6,
            cursor: 'pointer',
          },
          ...(sectors
            ? Object.keys(sectors).reduce((styles, sectorUuid) => {
                const sector = sectors[sectorUuid]
                const { color } = sector
                return {
                  ...styles,
                  [sectorUuid]: {
                    fillStyle: color,
                    fillOpacity: 0.9,
                    strokeStyle: color,
                    lineWidth: 0.01,
                    text: false,
                    textFontSize: 6,
                    cursor: 'pointer',
                  },
                }
              }, {})
            : {}),
        },
      },
      unavailable: {
        normal: {
          none: {
            fillStyle: '#2c2a25',
            fillOpacity: 0.8,
            strokeStyle: '#FFFFFF',
            lineWidth: 0.05,
            cursor: 'auto',
          },
        },
      },
      disabled: 'unavailable',
    },
    // Level 0 styles for nodes with "seat" type (no styles = not shown)

    general_admission: {},

    seat: null,
  },

  // Level 1. sections are "open", seats are visible
  {
    // Level 1 styles for nodes with "section" type
    section: {
      available: {
        normal: {
          none: {
            fillStyle: '#f5f5f5',
            fillOpacity: 0.8,
            // strokeStyle: '#FFFFFF',
            // strokeOpacity: 0.8,
            // lineWidth: 0.3,
            cursor: 'auto',
          },
        },
        // hover: {
        //     none: {
        //         fillStyle: '#eb0029',
        //         strokeStyle: '#FFFFFF',
        //         strokeOpacity: 0.8,
        //         lineWidth: 0.6,
        //         cursor: 'pointer',
        //     },
        // },
      },
      //     unavailable: {
      //         normal: {
      //             none: {
      //                 fillStyle: '#2c2a25',
      //                 fillOpacity: 0.8,
      //                 strokeStyle: '#FFFFFF',
      //                 strokeOpacity: 0.8,
      //                 lineWidth: 0.3,
      //                 cursor: 'auto',
      //             },
      //         },
      //     },
      //     disabled: 'unavailable',
    },

    // Level 1 styles for nodes with "seat" type
    seat: {
      available: {
        normal: {
          none: {
            fillStyle: '#538e49',
            fillOpacity: 0.8,
            strokeStyle: '#FFFFFF',
            strokeOpacity: 0.8,
            lineWidth: 0.01,
            cursor: 'auto',
          },
          wc: {
            fillStyle: '#538e49',
            fillOpacity: 0.8,
            strokeStyle: '#FFFFFF',
            strokeOpacity: 0.8,
            lineWidth: 0.01,
            cursor: 'auto',
            icon: 'icon-wc',
            iconFillStyle: '#000000',
            iconStrokeStyle: '#000000',
            iconOpacity: 1,
            iconFillOpacity: 1,
            iconStrokeOpacity: 1,
          },
          ...(sectors
            ? Object.keys(sectors).reduce((styles, sectorUuid) => {
                const sector = sectors[sectorUuid]
                const { color } = sector
                return {
                  ...styles,
                  [sectorUuid]: {
                    fillStyle: color,
                    fillOpacity: 0.8,
                    strokeStyle: '#FFFFFF',
                    strokeOpacity: 0.8,
                    lineWidth: 0.01,
                    cursor: 'auto',
                  },
                  [`${sectorUuid}-wc`]: {
                    fillStyle: color,
                    fillOpacity: 0.8,
                    strokeStyle: '#FFFFFF',
                    strokeOpacity: 0.8,
                    lineWidth: 0.01,
                    cursor: 'auto',
                    icon: 'icon-wc',
                    iconFillStyle: '#000000',
                    iconStrokeStyle: '#000000',
                    iconOpacity: 1,
                    iconFillOpacity: 1,
                    iconStrokeOpacity: 1,
                  },
                }
              }, {})
            : {}),
          // vip: {
          //     fillStyle: 'white',
          //     cursor: 'auto',
          //     icon: 'vipicon',
          // },
        },
        hover: {
          none: {
            fillStyle: '#FFFFFF',
            strokeStyle: '#FFFFFF',
            strokeOpacity: 0.8,
            lineWidth: 0.01,
            cursor: 'pointer',
          },
          wc: {
            fillStyle: '#FFFFFF',
            strokeStyle: '#FFFFFF',
            strokeOpacity: 0.8,
            lineWidth: 0.01,
            cursor: 'pointer',
            icon: 'icon-wc',
            iconFillStyle: '#000000',
            iconStrokeStyle: '#000000',
            iconOpacity: 1,
            iconFillOpacity: 1,
            iconStrokeOpacity: 1,
          },
          ...(sectors
            ? Object.keys(sectors).reduce((styles, sectorUuid) => {
                const sector = sectors[sectorUuid]
                const { color } = sector
                return {
                  ...styles,
                  [sectorUuid]: {
                    fillStyle: color,
                    fillOpacity: 1,
                    strokeStyle: color,
                    lineWidth: 0.01,
                    cursor: 'pointer',
                  },
                  [`${sectorUuid}-wc`]: {
                    fillStyle: color,
                    fillOpacity: 1,
                    strokeStyle: color,
                    lineWidth: 0.01,
                    cursor: 'pointer',
                    icon: 'icon-wc',
                    iconFillStyle: '#000000',
                    iconStrokeStyle: '#000000',
                    iconOpacity: 1,
                    iconFillOpacity: 1,
                    iconStrokeOpacity: 1,
                  },
                }
              }, {})
            : {}),
          // vip: {
          //     fillStyle: '#2E2925',
          //     strokeStyle: '#2E2925',
          //     lineWidth: 0.1,
          //     cursor: 'pointer',
          //     icon: 'vipicon',
          // },
        },
      },
      selected: {
        normal: {
          none: {
            fillStyle: '#eb0029',
            strokeStyle: '#ffffff',
            lineWidth: 0.05,
            cursor: 'pointer',
            // icon: 'selectedicon',
          },
          // vip: {
          //     fillStyle: '#ed1637',
          //     strokeStyle: '#ed1637',
          //     lineWidth: 0.1,
          //     cursor: 'pointer',
          //     icon: 'vipicon',
          // },
        },
      },
      unavailable: {
        normal: {
          none: {
            fillStyle: '#2c2a25',
            fillOpacity: 0.8,
            strokeStyle: '#FFFFFF',
            strokeOpacity: 0.8,
            lineWidth: 0.01,
            cursor: 'auto',
          },
          // vip: {
          //     fillStyle: 'grey',
          //     cursor: 'auto',
          //     icon: 'vipicon',
          // },
        },
      },
      disabled: 'unavailable',
    },
  },
]
