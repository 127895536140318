import css from './EventInfo.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const EventInfoSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid>
        <GridColumn gridColumnEnd={6}>
          <div className={classNames(css.title_skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'100%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </GridColumn>
      </Grid>

      <Grid>
        <GridColumn gridColumnEnd={{ [Breakpoints.laptop]: 10, [Breakpoints.mobile]: 17 }}>
          <div className={classNames(css.description_skeleton_container)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
            {[0, 1, 2, 3].map((item, key) => (
              <div key={key} className={classNames(css.description_skeleton)}>
                <Skeleton
                  baseColor={TICKETSMS_COLORS_LIGHTGREY}
                  borderRadius={4}
                  height={'100%'}
                  highlightColor={TICKETSMS_COLORS_WHITE}
                />
              </div>
            ))}

            <div className={classNames(css.description_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'100%'}
                width={'90%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.description_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'100%'}
                width={'90%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.description_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'100%'}
                width={'60%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}
