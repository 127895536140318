import { getDetails } from 'controller/collection/getDetails'

import { getBusySeats } from './getBusySeats'
import { getSectionsEnabled } from './getSectionsEnabled'
import { getTypeTickets } from './getTypeTickets'

export const CollectionController = {
  getDetails,
  getBusySeats,
  getSectionsEnabled,
  getTypeTickets,
}
