import React, { memo, PropsWithChildren, ReactNode } from 'react'

import { useIsAppInitialized } from 'hooks/useApp'
import { useUserData } from 'hooks/useAuth'

type Props = {
  showChildren?: boolean
  skeleton?: ReactNode
  requireUser?: boolean
}

const WithAppInitialized: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, showChildren, skeleton, requireUser } = props

  const isAppInitialized = useIsAppInitialized()
  const userData = useUserData()

  const renderChildren = isAppInitialized && showChildren && (!requireUser || Boolean(userData))

  return <>{React.Children.toArray(renderChildren ? children : skeleton)}</>
}

WithAppInitialized.defaultProps = {
  showChildren: true,
  requireUser: false,
}

export default memo(WithAppInitialized)
