import css from '../Social.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'

import Img from 'components/Media/Img'

interface IPropsButtonMap {
  mapsPlaceId: string
  color: ComponentColor
  latitude?: string | number
  longitude?: string | number
}

export const ButtonMap: React.FC<IPropsButtonMap> = (props) => {
  const { color, latitude, longitude, mapsPlaceId } = props

  if (!latitude || !longitude) return null

  const url = mapsPlaceId
    ? `https://www.google.com/maps/place/?q=place_id:${mapsPlaceId}`
    : `https://maps.google.com/?ll=${latitude},${longitude}`

  return (
    <a href={url} className={classNames(css.socialLink, css[color])} target="_blank" rel="noreferrer">
      <Img alt="" src={`/social/map_${color}.svg`} />
    </a>
  )
}
