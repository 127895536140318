import css from './Sections.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { IFaqSection } from 'meta/pages/faq'
import { TICKETSMS_COLORS_DARKGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Item, { ItemSkeleton } from 'components/Pages/FAQ/Item'

interface IProps {
  data: IFaqSection[]
  openItem?: number
  openSection?: number
  setActiveItemCallback(item: number, section: number): void
  hideTitle?: boolean
  noMargin?: boolean
  fullWidth?: boolean
}

const Sections: React.FC<IProps> = (props) => {
  const { data, openItem, openSection, setActiveItemCallback, hideTitle, noMargin, fullWidth } = props

  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={`${css.faq_sections_container} ${noMargin && css.noMargin}  ${fullWidth && css.fullWidth}`}>
      {data.map((section, section_num) => {
        if (section_num !== openSection) {
          return null
        }

        return (
          <div key={section_num}>
            {!hideTitle && (
              <WithAppInitialized
                showChildren={namespacesLoaded}
                skeleton={
                  <div className={css.titleSkeletonContainer}>
                    <Skeleton
                      className={css.titleSkeleton}
                      baseColor={TICKETSMS_COLORS_DARKGREY}
                      borderRadius={8}
                      highlightColor={TICKETSMS_COLORS_WHITE}
                      inline
                    />
                  </div>
                }
              >
                <div className={css.faq_section_name}>{section.title}</div>
              </WithAppInitialized>
            )}

            {section.questions.map((question, item_num) => {
              return (
                <WithAppInitialized key={String(item_num)} showChildren={namespacesLoaded} skeleton={<ItemSkeleton />}>
                  <Item
                    index={item_num}
                    indexblock={section_num}
                    question={question.question}
                    answer={question.answer}
                    isOpen={item_num === openItem && section_num === openSection}
                    setActiveFAQ={setActiveItemCallback}
                    fullWidth={fullWidth}
                  />
                </WithAppInitialized>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Sections
