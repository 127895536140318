import css from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { GridContainer } from 'components/Layout/Grid'
import { DesktopSkeleton } from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/Desktop'
import { MobileSkeleton } from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/Mobile'

export const TransactionItemsSkeleton: React.FC = () => {
  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        <GridContainer>
          <MobileSkeleton />
        </GridContainer>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <div className={css.transactionItems}>
          <DesktopSkeleton />
        </div>
      </MediaQuery>
    </>
  )
}
