import css from './Description.module.scss'
import React from 'react'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'

import { detectIfJSON } from 'utils/string'

interface IProps {
  description: string
}

const Description: React.FC<IProps> = (props) => {
  const { description } = props

  // detect object or html
  const isJSON = detectIfJSON(description)
  let parsedJSON
  let jsonToHtml

  // parse json
  if (isJSON) {
    parsedJSON = JSON.parse(description)
    jsonToHtml = new QuillDeltaToHtmlConverter(parsedJSON.ops, {}).convert()
  }

  return (
    <div>
      {Boolean(description) && !isJSON && (
        <div
          className={`${css.event_essentials_infos_description_container} ${css.isHTML}`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {Boolean(description) && isJSON && (
        <div
          className={`${css.event_essentials_infos_description_container} ${css.isJSON}`}
          dangerouslySetInnerHTML={{ __html: jsonToHtml as string }}
        />
      )}
    </div>
  )
}

export default Description
