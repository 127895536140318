import css from './VenueInfo.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'

import { ComponentColor, IVenueInfo } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import LocationSocial from 'components/BackendControlled/Component/common/LocationSocial'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import VenueInfoDescription from 'components/BackendControlled/Component/VenueInfo/Description'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const VenueInfo: React.FC<IPropsComponent<IVenueInfo>> = (props) => {
  const { address, common, city, identification, imageUrl, name, title, uuid } = props
  const urlCloudfront = useUrlCloudfront()

  const cardImageUrl = imageUrl
    ? imageUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`
  const locationUrl = typeof name === 'string' && identification ? `/location/${identification.name}` : `/`

  return (
    <GridContainer id={getComponentId(uuid)}>
      <Grid>
        <GridColumn gridColumnEnd={17}>
          <h2>{title}</h2>
        </GridColumn>

        <GridColumn gridColumnEnd={{ [Breakpoints.laptop]: 9, [Breakpoints.mobile]: 17 }}>
          <VenueInfoDescription {...props} />
        </GridColumn>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 13, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={17}
          gridRowStart={2}
          className={css.locationInfo__card}
        >
          <div className={css.locationInfo__cardContent}>
            <Link title={`${name} - ${common} - ${city} - TicketSms`} to={locationUrl}>
              <ImgSkeleton alt={name} src={cardImageUrl} className={css.locationInfo__cardContentImgCover} />
            </Link>

            <div className={css.locationInfo__cardLocation}>
              <Link title={`${name} - ${common} - ${city} - TicketSms`} to={locationUrl}>
                <h2>{name}</h2>
              </Link>
              <Link title={`${name} - ${common} - ${city} - TicketSms`} to={locationUrl}>
                <div className={css.locationInfo__cardContentAddress}>
                  <Img src="/icons/location_marker_transparent.svg" />
                  <div className={css.locationInfo__cardContentAddressText}>{address}</div>
                </div>
              </Link>
              <LocationSocial color={ComponentColor.black} {...props} />
            </div>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default VenueInfo
