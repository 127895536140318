import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Routes } from 'meta/routes'

import Logout from 'components/Auth/Profile/Menu/Logout'
import { paths } from 'components/Auth/Profile/Menu/paths'
import Voices from 'components/PageWithSubsections/Voices'

const Menu: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const activeSection = useMemo<string | undefined>(() => {
    return pathname.split('/').pop()
  }, [pathname])

  const onVoiceChange = useCallback(
    (path: string) => {
      const target = path === Routes.Wallet.path ? Routes.Wallet.generatePath() : path
      navigate(target)
    },
    [navigate]
  )

  if (!activeSection) return null

  return (
    <div>
      <Voices voices={paths} setActiveMenuVoiceCallback={onVoiceChange} activeSection={activeSection}>
        <Logout />
      </Voices>
    </div>
  )
}

export default Menu
