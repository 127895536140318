import css from 'components/Auth/Profile/ProfileTranslationsSkeleton/ProfileTranslationsSkeleton.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

type Props = {
  withSingleDescription?: boolean
  withMultipleDescriptions?: boolean
}

const ProfileTranslationsSkeleton: React.FC<Props> = (props) => {
  const { withSingleDescription, withMultipleDescriptions } = props
  return (
    <div className={classNames(css.container__skeleton)}>
      <div className={classNames(css.title__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        />
      </div>

      {withSingleDescription && (
        <div className={classNames(css.description__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_MEDIUMGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          />
        </div>
      )}

      {withMultipleDescriptions && (
        <>
          <div className={classNames(css.description__multiline__skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
          <div
            className={classNames(css.description__multiline__sibling__skeleton, css.description__multiline__spacing)}
          >
            <Skeleton
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
          <div className={classNames(css.description__multiline__sibling__skeleton, css.description__multiline__row)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
          <div className={classNames(css.description__multiline__sibling__skeleton, css.description__multiline__row)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ProfileTranslationsSkeleton
