import css from './Header.module.scss'
import React, { useCallback, useState } from 'react'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import WithAppInitialized from 'components/common/WithAppInitialized'
import SearchButtonMobile, { SearchButtonMobileSkeleton } from 'components/Layout/Header/SearchButtonMobile'
import Logo from 'components/Layout/LogoV2'

import SearchBarDesktop, { SearchBarDesktopSkeleton } from './SearchBar/Desktop'
import SearchBarMobile from './SearchBar/Mobile'
import MenuDesktop, { MenuDesktopSkeleton } from './MenuDesktop'
import MenuMobile, { MenuMobileSkeleton } from './MenuMobile'

const Header: React.FC = () => {
  const [showSearchMobile, setShowSearchMobile] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const showLogin = useCallback(() => {
    dispatch(LoginActions.show())
  }, [dispatch])

  return (
    <>
      <header className={classNames(css.layoutHeader, { [css.showSearchMobile]: showSearchMobile })}>
        <div className={css.layoutHeaderLeft}>
          <Logo />

          <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
            <WithAppInitialized skeleton={<SearchBarDesktopSkeleton />}>
              <SearchBarDesktop />
            </WithAppInitialized>
          </MediaQuery>
          <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
            <WithAppInitialized skeleton={<SearchButtonMobileSkeleton />}>
              <SearchButtonMobile onClick={() => setShowSearchMobile(true)} />
            </WithAppInitialized>
          </MediaQuery>
        </div>

        <WithAppInitialized skeleton={<MenuMobileSkeleton />}>
          <MenuMobile searchOpened={showSearchMobile} showLogin={showLogin} />
        </WithAppInitialized>
        <WithAppInitialized skeleton={<MenuDesktopSkeleton />}>
          <MenuDesktop showLogin={showLogin} />
        </WithAppInitialized>
      </header>

      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        <SearchBarMobile show={showSearchMobile} onClose={() => setShowSearchMobile(false)} />
      </MediaQuery>
    </>
  )
}

export default Header
