import css from '../Tickets.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MapIDs } from 'meta/map'

import { useCheckoutTicketSeats } from 'hooks/useCheckout'
import { useSectionLabel } from 'hooks/useEvent'

interface IProps {
  idx: number
  typeTicketId: number
}

const TitleManualSelection: React.FC<IProps> = (props) => {
  const { idx, typeTicketId } = props

  const { t } = useTranslation()
  const ticketSeats = useCheckoutTicketSeats()

  const ticketSeat = ticketSeats[idx]

  const typeTicket = ticketSeat && ticketSeat.typeTickets.find((typeTicket) => typeTicket.id === typeTicketId)

  const { seat, section, row } = MapIDs.toSeatIDs(ticketSeat ? ticketSeat.seatId : '--')
  const sectionLabel = useSectionLabel('checkout', section)
  const title = sectionLabel && typeTicket ? `${sectionLabel} - ${typeTicket.description}` : ''

  return (
    <>
      <div className={css.title}>{title}</div>
      {ticketSeat && <div className={css.seat}>{t('checkout:row_and_seat', { row, seat })}</div>}
    </>
  )
}

export default TitleManualSelection
