import css from './RefundSkeleton.module.scss'
import React, { useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

const RefundHeading: React.FC<{ size: number }> = ({ size }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.refund__heading}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          className={css.refund__heading__item}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      ))}
    </div>
  )
}

const RefundSubheading: React.FC<{ size: number }> = ({ size }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.refund__subheading}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          className={css.refund__subheading__item}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      ))}
    </div>
  )
}
const RefundTermsBody: React.FC<{ size: number }> = ({ size }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.refund__terms_body}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          className={css.refund__terms_body__item}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      ))}
    </div>
  )
}

const RefundSkeleton: React.FC = () => {
  return (
    <div className={css.refund}>
      <Skeleton
        className={css.refund__cover}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={8}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        inline
      />

      <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
        <RefundHeading size={2} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
        <RefundHeading size={1} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
        <RefundHeading size={1} />
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
        <RefundSubheading size={5} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
        <RefundSubheading size={3} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
        <RefundSubheading size={2} />
      </MediaQuery>

      <Skeleton
        className={css.refund__hr}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={8}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        inline
      />

      <Skeleton
        className={css.refund__terms_heading}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={8}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        inline
      />

      <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
        <RefundTermsBody size={25} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
        <RefundTermsBody size={17} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
        <RefundTermsBody size={12} />
      </MediaQuery>
    </div>
  )
}

export default RefundSkeleton
