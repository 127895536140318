import { createAsyncThunk } from '@reduxjs/toolkit'

import { ISearchEventCategory, ISearchParams } from 'meta/pages/search'
import { EventController } from 'controller/event'
import { throttleFindNext } from 'store/pages/search/actions/throttleFindNext'
import { SearchSelectors } from 'store/pages/search/selectors'
import { AppDispatch, RootState } from 'store/types'

type Params = ISearchParams

type Returned = Array<ISearchEventCategory> | undefined

export const init = createAsyncThunk<Returned, Params, { dispatch: AppDispatch; state: RootState }>(
  'search/init',
  async (params, { dispatch, getState }) => {
    const state = getState()
    const eventCategories = SearchSelectors.getEventCategories()(state)

    throttleFindNext({ dispatch, ...params })

    if (!eventCategories) {
      return EventController.getCategories()
    }
  }
)
