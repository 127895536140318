import { ComponentType, IComponent } from '../../structure'
import { ComponentTypeBackend, IComponentBackend } from '../component/component'
import ComponentsAdapter from './componentsAdapter'

export default abstract class ComponentAdapter<
  T extends IComponent<ComponentType>,
  R extends IComponentBackend<ComponentTypeBackend>
> {
  protected componentBackend: R
  protected componentsAdapter: ComponentsAdapter

  protected constructor(componentBackend: R, componentsAdapter: ComponentsAdapter) {
    this.componentBackend = componentBackend
    this.componentsAdapter = componentsAdapter
  }

  public abstract convert(): T
}
