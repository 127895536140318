import { ComponentType, IAssetSource } from '../../structure'
import { IAssetSourceBackend } from '../component/asset'
import ComponentAdapter from './componentAdapter'

export default class AssetSourceAdapter extends ComponentAdapter<IAssetSource, IAssetSourceBackend> {
  convert(): IAssetSource {
    const { mimeType, url } = this.componentBackend

    return {
      componentType: ComponentType.assetSource,
      mimeType,
      url,
    }
  }
}
