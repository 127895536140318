import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import CheckboxComponent from 'components/Forms/Checkbox'

import { IFormFieldCheckbox } from '../../../../../meta/form'
import { IFormFieldProps } from '../props'
import useFormField from '../useFormField'

const Checkbox: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, placeholder = '', useT, value } = formField as IFormFieldCheckbox
  const inputId = `${name}_hidden`
  const { t } = useTranslation()
  const { setValue } = useFormField(name)
  const [checked, setChecked] = useState<boolean>(Boolean(value) || false)
  const label = useT ? t(placeholder) : placeholder

  return (
    <>
      <CheckboxComponent
        checked={checked}
        id={name}
        label={<div dangerouslySetInnerHTML={{ __html: label }} />}
        onChange={(checked) => {
          setValue(checked ? 'true' : '', true)
          setChecked(checked)
        }}
      />

      <Field id={inputId} name={name} placeholder={t(`${placeholder}`)} type="hidden" />
    </>
  )
}

export default Checkbox
