import { TFunction } from 'i18next'
import { ComponentType, IMenuItem } from 'meta/backendControlled/structure'
import { ICollection } from 'meta/pages/collection'

export const getCollectionsMenuItems = (details: ICollection, t: TFunction): IMenuItem[] => {
  const menuItems: IMenuItem[] = []

  if (!details) {
    return menuItems
  }

  if (details.performers?.length > 0) {
    menuItems.push({
      title: t('collection:artist_title'),
      anchor: 'artisti',
      componentType: ComponentType.map,
    })
  }

  if (details.typeTicketCollections?.length > 0) {
    menuItems.push({
      title: t('collection:season_tickets_title'),
      anchor: 'abbonamenti',
      componentType: ComponentType.map,
    })
  }

  if (details.events?.length > 0) {
    menuItems.push({
      title: t('collection:event_title'),
      anchor: 'eventi',
      componentType: ComponentType.map,
    })
  }

  if (details.longDescription) {
    menuItems.push({
      title: t('collection:info_history_title'),
      anchor: 'info',
      componentType: ComponentType.map,
    })
  }

  // {
  //   title: 'FAQ',
  //   anchor: 'faq',
  //   componentType: ComponentType.map,
  // },
  // {
  //   title: 'Mag',
  //   anchor: 'mag',
  //   componentType: ComponentType.map,
  // },
  // {
  //   title: 'Potrebbe interessarti',
  //   anchor: 'potrebbe',
  //   componentType: ComponentType.map,
  // },

  return menuItems
}
