import { getValidPasswordRegex } from 'utils/regex'

import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldPassword extends IFormField<FormFieldType.password> {}

export class PasswordBuilder extends FormFieldBuilder<FormFieldType.password> {
  constructor(name: string) {
    super(name, FormFieldType.password)
  }

  addRequiredValidation(): FormFieldBuilder<FormFieldType.password> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: {
        value: getValidPasswordRegex(),
        message: `form:errors_invalid_${this.nameValidation}`,
      },
    }
    return super.addValidation(validation)
  }
}
