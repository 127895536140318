import css from 'components/Pages/SupportConversation/SupportConversation.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import {
  ISupportConversation,
  ISupportConversationMessage,
  ISupportConversationResponse,
  SupportConversationStatus,
} from 'meta/pages/support'
import { RouteParams } from 'meta/routes'

import Support from 'components/common/Support'
import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation from 'components/HOC/WithTranslation'
import ConversationMessage from 'components/Pages/SupportConversation/ConversationMessage'
import ConversationMessageForm, {
  ConversationMessageFormSkeleton,
} from 'components/Pages/SupportConversation/ConversationMessageForm'
import { SupportConversationSkeleton } from 'components/Pages/SupportConversation/SupportConversationSkeleton'
import { useBackendApiBase } from 'hooks/useEnv'

const SupportConversation: React.FC = () => {
  const { code } = useParams<RouteParams['SupportConversation']>()

  const { t } = useTranslation()
  const backendApiBase = useBackendApiBase()

  const [supportConversation, setSupportConversation] = useState<ISupportConversation>()
  const [messages, setMessages] = useState<Array<ISupportConversationMessage>>([])

  const closed = supportConversation && supportConversation.status === SupportConversationStatus.CLOSED

  const onMessageSent = (text: string) => {
    const messageNew = { dateCreation: new Date().toISOString(), isOperator: false, isNew: true, text }
    setMessages([...messages, messageNew])
  }

  useEffect(() => {
    axios
      .get(`${backendApiBase}/support/conversation/${code}`)
      .then((response) => response.data)
      .then((response: ISupportConversationResponse) => {
        setSupportConversation(response.data)
        setMessages(response.data.conversation)
      })
  }, [backendApiBase, code])

  return (
    <Support>
      {closed && <div className={css.conversationClosed}>{t('support-conversation:conversationClosed')}</div>}

      <WithAppInitialized
        showChildren={Boolean(supportConversation) && messages.length > 0}
        skeleton={<SupportConversationSkeleton />}
      >
        {supportConversation &&
          messages.map((conversationMessage: ISupportConversationMessage, idx: number) => (
            <ConversationMessage
              key={String(idx)}
              conversationMessage={conversationMessage}
              firstName={supportConversation!.firstName}
              lastName={supportConversation!.lastName}
            />
          ))}
      </WithAppInitialized>

      <WithAppInitialized showChildren={Boolean(supportConversation)} skeleton={<ConversationMessageFormSkeleton />}>
        {supportConversation && !closed && (
          <ConversationMessageForm codeRequest={supportConversation.codeRequest} onMessageSent={onMessageSent} />
        )}
      </WithAppInitialized>
    </Support>
  )
}

const namespaces = ['support', 'support-conversation']

export default withTranslation(namespaces)(SupportConversation)
