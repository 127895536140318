import css from 'components/Pages/ProfileTransactions/Transaction/Transaction.module.scss'
import React from 'react'

import { ITransaction } from 'meta/pages/profile'

import Actions from 'components/Pages/ProfileTransactions/Transaction/Actions'
import Header from 'components/Pages/ProfileTransactions/Transaction/Header'
import Ticket from 'components/Pages/ProfileTransactions/Transaction/Ticket'
import TransactionItems from 'components/Pages/ProfileTransactions/Transaction/TransactionItems'

type Props = {
  data: ITransaction
  isOpen: boolean
  toggleOpenTransactionCallback(code: string): void
}

const Transaction: React.FC<Props> = (props) => {
  const { data, toggleOpenTransactionCallback, isOpen } = props

  return (
    <div className={css.transactionContainer} data-testid={`transaction-${data.code}`}>
      <div className={css.transactionDataContainer}>
        <Header data={data} />

        <div className={css.transactionData}>
          <TransactionItems data={data} />

          <Actions data={data} isOpen={isOpen} toggleOpenTransactionCallback={toggleOpenTransactionCallback} />
        </div>
      </div>

      {isOpen && data.tickets && (
        <div>
          {data.tickets.map((elem, idx) => {
            return (
              <React.Fragment key={String(idx)}>
                <Ticket data={elem} listPosition={String(idx)} />
                {data.tickets && idx !== data.tickets.length - 1 && <div className={css.ticketsDataCover} />}
              </React.Fragment>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Transaction
