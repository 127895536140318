import css from './DatePicker.module.scss'
import React, { useMemo } from 'react'
import { DayPicker, DayPickerSingleProps, Matcher } from 'react-day-picker'
import classNames from 'classnames'

import { Functions } from 'utils/functions'

import { useDayPickerProps } from './hooks/useDayPickerProps'

interface Props {
  date: Date
  disabledDates?: Array<Date>
  maximumDate?: Date
  minimumDate?: Date
  onChange(date: Date): void
  removePadding?: boolean
}

export const DatePicker: React.FC<Props> = (props) => {
  const { date: selected, disabledDates, maximumDate: toDate, minimumDate: fromDate, onChange, removePadding } = props

  const disabled = useMemo<Matcher[]>(() => {
    const matcher: Matcher[] = []
    if (disabledDates) {
      disabledDates.forEach((disabledDate) => matcher.push(disabledDate))
    }
    if (fromDate) {
      matcher.push({ before: fromDate })
    }
    if (toDate) {
      matcher.push({ after: toDate })
    }
    return matcher
  }, [])

  const onSelect = (date?: Date) => {
    date && Functions.delay(() => onChange(date), 250)
  }

  const dayPickerProps = useDayPickerProps<DayPickerSingleProps>({
    defaultMonth: selected,
    disabled,
    fromDate,
    mode: 'single',
    onSelect,
    selected,
    toDate,
  })

  return (
    <div className={classNames(css.calendarWrapper, { [css.removePadding]: Boolean(removePadding) })}>
      <DayPicker {...dayPickerProps} />
    </div>
  )
}
