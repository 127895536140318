import React from 'react'

import { ComponentType, IComponent } from 'meta/backendControlled/structure'

import componentsMap from './componentsMap'

// @ts-ignore
export interface IPropsComponent<T extends IComponent<ComponentType>> extends T {
  componentType?: ComponentType
}

const Component: React.FC<IPropsComponent<IComponent<ComponentType>>> = (props) => {
  const { componentType, ...componentProps } = props

  const ComponentItem = componentsMap[componentType as ComponentType]

  if (!ComponentItem) {
    console.warn(`Unable to find component of type ${componentType}`)
    return null
  }

  return <ComponentItem componentType={componentType} {...componentProps} />
}

export default Component
