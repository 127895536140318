import css from './Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  phone: number
  phonePrefix: string
}

const OTPFormHeader: React.FC<IProps> = (props) => {
  const { phone, phonePrefix } = props

  const { t } = useTranslation()

  return (
    <div className={css.auth_otpV2_header_container}>
      <div className={css.text_container}>
        <div className={css.insert_code_text}>{t('otpV2:insert_otp_code')}</div>

        <div className={css.number}>{`${phonePrefix} ${phone}`}</div>
      </div>
    </div>
  )
}

export default OTPFormHeader
