import {
  ComponentsBackendResponseAdapter,
  IComponentsBackendResponseData,
} from 'meta/backendControlled/structureBackend'
import { ISearchParams, ISearchResult, SearchParamUtils } from 'meta/pages/search'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type ResponseMetadata = {
  hasMorePages: boolean
  page: number
  pages: number
  perPage: number
  total: number
}

export const find = async (params: ISearchParams): Promise<ISearchResult> => {
  const body = SearchParamUtils.toPostBody(params)
  const response = await HttpRequest.post<IComponentsBackendResponseData, typeof body, ResponseMetadata>(
    ApiEndpoint.search.find(),
    body
  )

  if (response && response.data && response.data.body) {
    const { components } = ComponentsBackendResponseAdapter.convertResponse(response.data)
    const { hasMorePages, total } = response.metadata
    return { hasMorePages, total, components }
  }

  return { hasMorePages: false, total: 0 }
}
