import css from './Desktop.module.scss'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SearchParamUtils } from 'meta/pages/search'

import { ActionFormat, ButtonTransparent, ButtonType } from 'components/Actions/Action'

const SearchBarDesktop: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    setQuery('')
    navigate(SearchParamUtils.toUrl({ query }))
  }

  return (
    <form className={css.headerSearchbar} onSubmit={onSubmit}>
      <input
        className={css.headerSearchbar__input}
        placeholder={String(t('header:searchbar_placeholder'))}
        onChange={(event) => setQuery(event.target.value)}
        value={query}
      />

      <ButtonTransparent
        className={css.headerSearchbar__btnSearch}
        format={ActionFormat.circle}
        type={ButtonType.submit}
      >
        <div />
      </ButtonTransparent>
    </form>
  )
}

export default SearchBarDesktop
