import { createAsyncThunk } from '@reduxjs/toolkit'

import { CheckoutAuthType, CheckoutStep, PaymentGateway } from 'meta/pages/checkout'
import { CheckoutController } from 'controller/checkout'
import { AuthSelectors } from 'store/auth'
import { CheckoutActions } from 'store/pages/checkout/actions/index'
import { CheckoutSelectors } from 'store/pages/checkout/selectors'
import { RootState } from 'store/types'

type Returned =
  | {
      transactionCode: string
      paymentGateway: PaymentGateway
      paymentGatewayData?: Record<string, string>
    }
  | undefined

export const startTransaction = createAsyncThunk<Returned, void, { state: RootState }>(
  'checkout/transaction/start',
  async (_, { dispatch, getState }) => {
    const state = getState()
    const userData = AuthSelectors.getUserData()(state)

    const token = AuthSelectors.getToken()(state)

    const auth = CheckoutSelectors.getAuth()(state)
    const email = auth && auth.type === CheckoutAuthType.fastCheckout ? auth.email : userData?.email

    if (email && email.trim() !== '') {
      return CheckoutController.prepare({ email, checkout: state.pages.checkout, token: token })
    } else {
      dispatch(CheckoutActions.updateStep({ step: CheckoutStep.auth }))
    }
  }
)
