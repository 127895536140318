import css from './FiltersCity.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchParamValue } from 'meta/pages/search'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import { ButtonWhite } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useSearchCities, useSearchParams } from 'hooks/useSearch'

interface IPropsFiltersCity {
  closeFilters(): void
}

const FiltersCity: React.FC<IPropsFiltersCity> = (props) => {
  const { closeFilters } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()
  const cities = useSearchCities()

  const valuesToExclude = [SearchParamValue.everywhere.toString(), SearchParamValue.currentposition.toString()]
  const [inputValue, setInputValue] = useState<string | undefined>(() =>
    valuesToExclude.includes(searchParams?.city ?? '') ? '' : searchParams?.city
  )

  return (
    <div className={css.filtersCityContainer}>
      <div className={css.filtersCityInputContainer}>
        <Img src="/icons/city.svg" />
        <input
          onChange={(event) => {
            const { value } = event.target
            setInputValue(value)
            dispatch(SearchActions.findCities({ name: value }))
          }}
          placeholder={String(t('search:city_placeholder'))}
          value={inputValue}
        />
      </div>
      <Img src="/icons/point_selection.svg" />

      {cities && cities?.length > 0 && (
        <div className={css.filtersCitiesContainer}>
          {cities.map((city, idx) => (
            <ButtonWhite
              key={String(idx)}
              onClick={() => {
                setInputValue(city.name)
                dispatch(SearchActions.resetCities())

                const params = { ...searchParams, city: city.name, geoPoint: undefined }
                dispatch(SearchActions.updateParams(params))
                closeFilters()
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: city.match }} />
            </ButtonWhite>
          ))}
        </div>
      )}
    </div>
  )
}

export default FiltersCity
