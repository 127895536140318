import css from './Login.module.scss'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'

interface IProps {
  setShowLogin: Dispatch<SetStateAction<boolean>>
}

const Login: React.FC<IProps> = (props) => {
  const { setShowLogin } = props

  const { t } = useTranslation()

  return (
    <div className={css.checkout_authbox_login_container}>
      <div className={css.text}>
        <strong>{t('checkout:auth_login')}</strong>
      </div>
      <div className={css.button} data-testid={DataTestId.checkout.loginBtn} onClick={() => setShowLogin(true)}>
        {t('checkout:auth_login_button_label')}
      </div>
    </div>
  )
}

export default Login
