import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataDogLogType, LOGS_CHECKOUT_CANCEL } from 'meta/logger'
import { ErrorSource } from 'meta/pages/error'
import { QueryParams, Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { DataDogLogger } from 'helpers/debug/logger'

import { useIsAppInitialized } from 'hooks/useApp'
import { useQueryParams } from 'hooks/useQueryParams'

export const useCancelProcess = (): void => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const appInitialized = useIsAppInitialized()
  const paymentGatewayData = useQueryParams<QueryParams['CheckoutCancel']>()
  const { transactionCode, paymentGateway } = paymentGatewayData

  useEffect(() => {
    if (!appInitialized) return

    if (!transactionCode || !paymentGateway) {
      navigate(Routes.Error.generatePath())
      return
    }

    // log to datadog
    DataDogLogger(LOGS_CHECKOUT_CANCEL, DataDogLogType.info, { transactionCode, paymentGateway })

    // cancel process
    dispatch(CheckoutActions.cancelProcess({ transactionCode, paymentGateway, paymentGatewayData })).then(() => {
      // redirect to error page
      navigate(
        Routes.Error.generatePath(undefined, { comingFrom: ErrorSource.transaction, transactionCode, paymentGateway })
      )
    })
  }, [appInitialized])
}
