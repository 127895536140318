import css from './Checkout.module.scss'
import React, { useMemo, useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import classNames from 'classnames'

import { CheckoutStep, ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Auth from 'components/Checkout/Steps/Auth'
import External from 'components/Checkout/Steps/External'
import InitError from 'components/Checkout/Steps/InitError'
import Satispay from 'components/Checkout/Steps/Satispay'
import Select, { SelectSkeleton } from 'components/Checkout/Steps/Select'
import Timeout from 'components/Checkout/Steps/Timeout'
import Transaction from 'components/Checkout/Steps/Transaction'
import TransactionError from 'components/Checkout/Steps/TransactionError'
import Modal from 'components/Layout/Modal'
import { useCheckoutDetails, useCheckoutStep } from 'hooks/useCheckout'
import { useOnUpdate } from 'hooks/useOnUpdate'

import { useLoadStripe } from './hooks/useLoadStripe'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentSteps: Record<string, React.FC<any>> = {
  [CheckoutStep.auth]: Auth,
  [CheckoutStep.select]: Select,
  [CheckoutStep.external]: External,
  [CheckoutStep.transaction]: Transaction,
  [CheckoutStep.transactionError]: TransactionError,
  [CheckoutStep.initError]: InitError,
  [CheckoutStep.satispay]: Satispay,
  [CheckoutStep.timeoutError]: Timeout,
}

const Checkout: React.FC = () => {
  const details = useCheckoutDetails() as ICheckoutDetails

  const { insideIFrame } = details

  const dispatch = useAppDispatch()
  const step = useCheckoutStep()
  const stripe = useLoadStripe()

  const modalRef = useRef<HTMLElement>(null)

  useOnUpdate(() => {
    if (!insideIFrame && modalRef.current) {
      modalRef.current.scrollTop = 0
    }
  }, [step])

  // TODO ${transactionInProgress && css.transaction_in_progress}`}
  const Body = useMemo(() => {
    if (stripe) {
      return (
        <Elements stripe={stripe}>
          <div className={classNames(css.checkout_container, { [css.insideIframe]: step && insideIFrame })}>
            {step ? React.createElement(componentSteps[step], { modalRef }) : <SelectSkeleton />}
          </div>
        </Elements>
      )
    }

    return null
  }, [insideIFrame, step, stripe])

  if (insideIFrame) return Body

  return (
    <Modal
      handleClose={(event) => {
        const skipCloseElement = (event?.target as HTMLElement)?.closest('[data-modalclose="skip"]')
        if (!skipCloseElement) dispatch(CheckoutActions.close())
      }}
      show={true}
      grey
      overflowY
      hideCloseIcon
      fullscreenOnMobile
      ref={modalRef}
    >
      {Body}
    </Modal>
  )
}

export default Checkout
