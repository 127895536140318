import { BackendControlledComponents } from '../../structure'
import ComponentsAdapter from '../adapter/componentsAdapter'
import { ComponentTypeBackend, IComponentBackend } from '../component/component'

export interface IComponentsBackendResponseData {
  body: Array<IComponentBackend<ComponentTypeBackend>>
}

export interface IComponentsBackendResponse {
  data: IComponentsBackendResponseData
  status: number
}

export abstract class ComponentsBackendResponseAdapter {
  static convertResponse(data: IComponentsBackendResponseData): BackendControlledComponents {
    const componentsAdapter = new ComponentsAdapter()
    const components = componentsAdapter.convertComponents(data.body)

    return { components, metadata: componentsAdapter.metadata }
  }
}
