import { TicketPrice } from 'meta/pages/tickets/price'
import { Numbers } from 'utils/numbers'

const format = (price: TicketPrice): string => {
  return `${Numbers.format(Number(price.amount) / 100)} ${price.symbol}`
}

export const TicketPrices = {
  format,
}
