import css from './External.module.scss'
import React from 'react'
import { useDispatch } from 'react-redux'
import { usePost } from 'api'

import { LoginData, LoginProvider, LoginStep } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'
import { LoginActions } from 'store/pages/login'

import { IStepProps } from 'components/Auth/LoginV2/Steps/StepCommon'
import Img from 'components/Media/Img'
import { useCheckoutDetails } from 'hooks/useCheckout'
import { useOnUpdate } from 'hooks/useOnUpdate'

type Props = {
  dataTestId: string
  disabled?: boolean
  getLoginProviderData: () => Promise<any>
  getRegisterData: () => Promise<LoginData>
  icon: string
  provider: LoginProvider
  redirectToRegisterOnForbidden?: boolean
}

const External: React.FC<Props & IStepProps> = (props) => {
  const {
    dataTestId,
    disabled,
    getLoginProviderData,
    getRegisterData,
    icon,
    onSuccess,
    provider,
    redirectToRegisterOnForbidden,
  } = props

  const dispatch = useDispatch()
  const { response, error, fetch, fetching, status } = usePost()

  const details = useCheckoutDetails()
  const loginActionPath = details?.insideIFrame ? ApiEndpoint.users.loginFromIframe() : ApiEndpoint.users.login()

  const onClick = async () => {
    const providerData = await getLoginProviderData()
    //@ts-ignore
    fetch({
      urlResource: loginActionPath,
      body: { provider, providerData },
    })
  }

  // // user logged in
  useOnUpdate(() => {
    if (response) {
      const { token } = response.data
      onSuccess(token)
    }
  }, [response])

  // // user not existing, start register process
  useOnUpdate(() => {
    if (status === 404 || (status === 403 && redirectToRegisterOnForbidden)) {
      getRegisterData().then((loginData) => {
        dispatch(LoginActions.updateData({ data: { ...loginData, provider }, step: LoginStep.phone }))
      })
    }
  }, [error, status])

  return (
    <button className={css.login__external} data-testid={dataTestId} disabled={disabled || fetching} onClick={onClick}>
      <Img alt="" src={icon} />
    </button>
  )
}

export default External

export { Facebook } from './Facebook'
