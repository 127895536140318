import css from './SearchFilters.module.scss'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'

import { Breakpoints } from 'meta/layout/breakpoints'
import { ISearchEventCategory, SearchParamUtils, SearchParamValue } from 'meta/pages/search'
import { Objects } from 'utils/objects'

import { ActionColor, ButtonWhite } from 'components/Actions/Action'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ButtonSkeletonWrapper from 'components/Pages/Search/SearchForm/SearchFilters/ButtonSkeletonWrapper'
import FiltersCategories from 'components/Pages/Search/SearchForm/SearchFilters/FiltersCategories'
import FiltersWhen from 'components/Pages/Search/SearchForm/SearchFilters/FiltersWhen'
import FiltersWhere from 'components/Pages/Search/SearchForm/SearchFilters/FiltersWhere'
import { useSearchEventCategories, useSearchParams } from 'hooks/useSearch'

interface IShowFilters {
  eventCategories?: boolean
  when?: boolean
  where?: boolean
}

const SearchFilters: React.FC = () => {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const eventCategories = useSearchEventCategories()

  const eventCategory: ISearchEventCategory =
    eventCategories && searchParams.eventCategory
      ? (eventCategories.find(
          (eventCategory) => eventCategory.uuid === searchParams.eventCategory
        ) as ISearchEventCategory)
      : { uuid: '', id: undefined, name: '', status: '' }
  const datesToString = SearchParamUtils.datesToString(searchParams, t('dates:common:dayMonthYear'))
  const [showFilters, setShowFilters] = useState<IShowFilters>({})
  const closeFilters = (): void => setShowFilters({})

  const eventCategoriesRef = useRef<HTMLDivElement>(null)
  const whereRef = useRef<HTMLDivElement>(null)
  const whenRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <GridColumn
        className={css.searchFilterContainer}
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 7 }}
        ref={eventCategoriesRef}
      >
        <ButtonSkeletonWrapper>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              const node = event.target as Node
              if (!whenRef.current?.contains(node) && !whereRef.current?.contains(node)) closeFilters()
            }}
          >
            <ButtonWhite
              className={css.searchFilter}
              hover={ActionColor.grey}
              onClick={() => {
                setShowFilters((prev) => ({ eventCategories: !prev.eventCategories }))
              }}
            >
              <div>
                <Img src="/icons/tickets.svg" />
                <div>
                  <strong>{t('search:event_category')}: </strong>
                  {searchParams.eventCategory && <span>{eventCategory.name}</span>}
                </div>
              </div>
              <Img src={showFilters.eventCategories ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
            </ButtonWhite>

            {showFilters.eventCategories && <FiltersCategories closeFilters={closeFilters} />}
          </OutsideClickHandler>
        </ButtonSkeletonWrapper>
      </GridColumn>

      <GridColumn
        className={css.searchFilterContainer}
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 7 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 11 }}
        ref={whereRef}
      >
        <ButtonSkeletonWrapper>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              const node = event.target as Node
              if (!eventCategoriesRef.current?.contains(node) && !whenRef.current?.contains(node)) closeFilters()
            }}
          >
            <ButtonWhite
              className={css.searchFilter}
              hover={ActionColor.grey}
              onClick={() => {
                setShowFilters((prev) => ({ where: !prev.where }))
              }}
            >
              <div>
                <Img src="/icons/location_marker_black.svg" />
                <div>
                  <strong>{t('search:where')}: </strong>
                  {searchParams.city && (
                    <span>
                      {([SearchParamValue.everywhere, SearchParamValue.currentposition] as Array<string>).includes(
                        searchParams.city
                      )
                        ? t(`search:${searchParams.city}`)
                        : searchParams.city}
                    </span>
                  )}
                </div>
              </div>
              <Img src={showFilters.where ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
            </ButtonWhite>

            {showFilters.where && <FiltersWhere closeFilters={closeFilters} />}
          </OutsideClickHandler>
        </ButtonSkeletonWrapper>
      </GridColumn>

      <GridColumn
        className={css.searchFilterContainer}
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 11 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 15 }}
        ref={whenRef}
      >
        <ButtonSkeletonWrapper>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              const node = event.target as Node
              if (!eventCategoriesRef.current?.contains(node) && !whereRef.current?.contains(node)) closeFilters()
            }}
          >
            <ButtonWhite
              className={css.searchFilter}
              hover={ActionColor.grey}
              onClick={() => {
                setShowFilters((prev) => ({ when: !prev.when }))
              }}
            >
              <div>
                <Img src="/icons/clock.svg" />
                <div>
                  <strong>{t('search:when')}: </strong>
                  {searchParams.dates && (
                    <span>{Objects.isArray(searchParams.dates) ? datesToString : t(`search:${datesToString}`)}</span>
                  )}
                </div>
              </div>
              <Img src={showFilters.when ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
            </ButtonWhite>

            {showFilters.when && <FiltersWhen closeFilters={closeFilters} />}
          </OutsideClickHandler>
        </ButtonSkeletonWrapper>
      </GridColumn>
    </>
  )
}

export default SearchFilters
