import css from './ImageGallery.module.scss'
import React, { MouseEventHandler, useEffect, useMemo } from 'react'
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import classNames from 'classnames'

import { AssetType, IAsset } from '../../../meta/backendControlled/structure'
import Modal from '../../Layout/Modal'
import Img from '../Img'

type Props = {
  items: Array<IAsset>
  onClose: () => void
  startIndex: number
}

type IAssetRIGalleryItem = IAsset & Pick<ReactImageGalleryItem, 'original'>

const IMAGE_GALLERY_IMAGE_CONTAINER = 'image-gallery-image-container'

const ImageGallery: React.FC<Props> = (props) => {
  const { items, startIndex, onClose } = props

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLElement

    if (target.className === IMAGE_GALLERY_IMAGE_CONTAINER) {
      onClose()
    }
  }

  const renderLeftNav = (onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
    return (
      <button
        className={classNames('image-gallery-custom-left-nav', css.imageGallery__navLeft)}
        disabled={disabled}
        onClick={onClick}
      >
        <Img src="/checkout/map_nav_down.svg" />
      </button>
    )
  }

  const renderRightNav = (onClick: MouseEventHandler<HTMLElement>, disabled: boolean) => {
    return (
      <button
        className={classNames('image-gallery-custom-right-nav', css.imageGallery__navRight)}
        disabled={disabled}
        onClick={onClick}
      >
        <Img src="/checkout/map_nav_down.svg" />
      </button>
    )
  }

  const renderVideo = (item: IAssetRIGalleryItem) => (
    <div className={IMAGE_GALLERY_IMAGE_CONTAINER} onClick={handleOutsideClick}>
      <video className="image-gallery-image" controls>
        {item.src
          .filter((source) => source.mimeType?.startsWith('video'))
          .map((source) => (
            <source key={source.uuid} src={source.url} type={source.mimeType} />
          ))}
      </video>
    </div>
  )

  const renderImage = (item: IAssetRIGalleryItem) => (
    <div className={IMAGE_GALLERY_IMAGE_CONTAINER} onClick={handleOutsideClick}>
      <img alt="" className="image-gallery-image" src={item.src[0].url} />
    </div>
  )

  const galleryItems = useMemo(() => {
    return items
      .map((item) => ({ ...item, original: item.src[0]?.url ?? '' } as IAssetRIGalleryItem))
      .map((item) => ({
        ...item,
        renderItem: item.type === AssetType.video ? renderVideo : renderImage,
      }))
  }, [])

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent): any => {
      if (event.key === 'Escape') {
        event.preventDefault()

        onClose()
      }
    }

    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [onClose])

  return (
    <Modal handleClose={onClose} show={true} transparent={true} fullscreenOnMobile>
      <ReactImageGallery
        autoPlay={false}
        items={galleryItems}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showFullscreenButton={false}
        showPlayButton={false}
        startIndex={startIndex}
      />
    </Modal>
  )
}

export default ImageGallery
