import css from './VenueDetails.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn } from 'components/Layout/Grid'

export const VenueDetailsSkeleton: React.FC = (props) => {
  return (
    <Grid>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 13 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
        className={css.venueDetails}
      >
        <div className={css.venueDetails__content}>
          <div className={css.venueDetails__contentCardImg}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.venueDetails__contentName__skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'90%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.venueDetails__contentAddressContainer__skeleton}>
            <div className={css.venueDetails__contentAddress}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'100%'}
                width={'95%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={css.venueDetails__contentAddress}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'100%'}
                width={'80%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
          <div className={css.venueDetails__socialButtons__skeleton}>
            <div className={css.socialButton__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={40}
                height={'100%'}
                width={'100%'}
                className={css.socialButton__skeleton}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.socialButton__skeleton, css.socialButton__skeleton__sibling)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={40}
                height={'100%'}
                width={'100%'}
                className={css.socialButton__skeleton}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.socialButton__skeleton, css.socialButton__skeleton__sibling)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={40}
                height={'100%'}
                width={'100%'}
                className={css.socialButton__skeleton}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
          {/*<LocationSocial color={ComponentColor.white} {...props} />*/}
        </div>
      </GridColumn>
    </Grid>
  )
}
