import css from './ImgSkeleton.module.scss'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

interface IProps {
  src: string
  className: string
  alt: string
  onError?: () => void
}

const ImgSkeleton: React.FC<IProps> = (props) => {
  const { src, className, alt, onError } = props

  const [imgLoaded, setImgLoaded] = useState<boolean>(false)

  return (
    <>
      <img
        alt={alt}
        className={classNames(className, { [css.hidden]: !imgLoaded })}
        src={src}
        onError={() => {
          onError && onError()
        }}
        onLoad={() => setImgLoaded(true)}
      />

      {!imgLoaded && (
        <div className={classNames(className)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      )}
    </>
  )
}

export default ImgSkeleton
