import React, { useState } from 'react'
import { GoogleOAuthProvider } from 'google-oauth-gsi'

import DataTestId from 'meta/dataTestId'
import { LoginData, LoginProvider } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

import { useGoogleClientId } from 'hooks/useEnv'
import { useLoginData } from 'hooks/useLogin'

import { IStepProps } from '../../StepCommon'
import External from './External'

export const Google: React.FC<IStepProps> = (props) => {
  const loginData = useLoginData()

  const [loginCode, setLoginCode] = useState<string>('')

  const googleClientId = useGoogleClientId()

  const userDataActionPath = ApiEndpoint.users.signupData()

  const googleProvider = new GoogleOAuthProvider({
    clientId: googleClientId,
    onScriptLoadError: () => null,
    onScriptLoadSuccess: () => null,
  })

  const getLoginProviderData = (): Promise<any> =>
    new Promise((resolve) => {
      const login = googleProvider.useGoogleLogin({
        flow: 'auth-code',
        onSuccess: (codeResponse) => {
          setLoginCode(codeResponse.code)

          resolve({
            'code': codeResponse.code,
          })
        },
      })

      login()
    })

  const getRegisterData = (): Promise<LoginData> =>
    new Promise((resolve) => {
      HttpRequest.post<any>(userDataActionPath, {
        provider: LoginProvider.GOOGLE,
        providerData: { code: loginCode },
      }).then((data) => {
        resolve({
          ...loginData,
          google: {
            email: data?.data?.email,
            code: loginCode,
            firstName: data?.data?.firstName,
            lastName: data?.data?.lastName,
          },
          birthDate: '', // empty
          birthPlace: '', // empty
          email: data?.data?.email,
          firstName: data?.data?.firstName,
          lastName: data?.data?.lastName,
          provider: LoginProvider.GOOGLE,
        })
      })
    })

  return (
    <External
      {...props}
      dataTestId={DataTestId.login.btnGoogle}
      disabled={false}
      getLoginProviderData={getLoginProviderData}
      getRegisterData={getRegisterData}
      icon="/login/google.svg"
      provider={LoginProvider.GOOGLE}
    />
  )
}
