import css from 'components/Pages/PurchaseForm/Tickets/EventDetails/EventDetails.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import {
  TICKETSMS_COLORS_DARKGREY,
  TICKETSMS_COLORS_LIGHTGREY,
  TICKETSMS_COLORS_TICKET_BLACK,
} from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

const EventDetails: React.FC = () => {
  return (
    <GridColumn
      className={css.eventDetails}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
    >
      <div className={css.eventDetails__eventImg}>
        <Skeleton
          className={css.imgSkeleton}
          baseColor={TICKETSMS_COLORS_TICKET_BLACK}
          borderRadius={16}
          highlightColor={TICKETSMS_COLORS_DARKGREY}
        />
      </div>

      <div className={css.eventDetails__eventDesc}>
        <Skeleton
          className={css.dateSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_DARKGREY}
          inline
        />

        <Skeleton
          className={css.titleSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_DARKGREY}
          inline
        />

        <Skeleton
          className={css.locationSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_DARKGREY}
          inline
        />
      </div>
    </GridColumn>
  )
}

export default EventDetails
