import css from './Image.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

interface IProps {}

class ImageBox extends React.Component<IProps> {
  render() {
    return (
      <div className={css.checkout_fastcheckout_image_container}>
        <Img src="/checkout/ticket_flyer.gif" />
      </div>
    )
  }
}

export default ImageBox
