import { createAsyncThunk } from '@reduxjs/toolkit'

import { ICheckoutProcessData, ICheckoutProcessMetadata } from 'meta/pages/checkout'
import { CheckoutController } from 'controller/checkout'
import { RootState } from 'store/types'

type Params = {
  paymentGateway: string
  paymentGatewayData: Record<string, string>
  transactionCode: string
}

type Returned = {
  data: ICheckoutProcessData
  metadata: ICheckoutProcessMetadata
}

export const startProcess = createAsyncThunk<Returned, Params, { state: RootState }>(
  'checkout/process/start',
  CheckoutController.process
)
