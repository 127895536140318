import css from 'components/Pages/ProfileTransactions/ProfileTransactions.module.scss'
import React from 'react'
import classNames from 'classnames'

import { TransactionSkeleton } from 'components/Pages/ProfileTransactions/Transaction'

export const ProfileTransactionsSkeleton: React.FC = () => {
  return (
    <div className={css.profileTransactions__spinner}>
      {[0, 1, 2, 3, 4].map((item, index) => (
        <TransactionSkeleton key={index} className={classNames(index !== 0 && css.skeleton)} />
      ))}
    </div>
  )
}
