import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { ITicketChangeNameData, TicketChangeNameStep } from 'meta/pages/profile'
import { ITicketExtraField } from 'meta/pages/tickets'
import { ProfileActions } from 'store/pages/profile'
import { CookieClient, CookieName } from 'utils/cookie'

import { useTicketChangeNameData } from 'hooks/useProfile'

import NewHolderData from './NewHolderData'
import OldTicketData from './OldTicketData'
import Success from './Success'

const stepComponents = {
  [TicketChangeNameStep.oldTicketData]: OldTicketData,
  [TicketChangeNameStep.newHolderData]: NewHolderData,
  [TicketChangeNameStep.success]: Success,
}

const ChangeTicketName: React.FC = () => {
  const dispatch = useDispatch()
  const { step } = useTicketChangeNameData()
  const authToken = CookieClient.get(CookieName.TOKEN)

  const [additionalInfos, setAdditionalInfos] = useState<Array<ITicketExtraField>>([])

  const onSuccess = (data: ITicketChangeNameData) => {
    if (TicketChangeNameStep.newHolderData) {
      setAdditionalInfos(data.additionalInfos as ITicketExtraField[])
      dispatch(ProfileActions.setTicketChangeNameData({ data }))
    }

    if (step === TicketChangeNameStep.success) {
      dispatch(ProfileActions.resetTicketChangeName())
    }

    const currentStep = step === TicketChangeNameStep.success ? 0 : step + 1

    dispatch(ProfileActions.setTicketChangeNameStep({ step: currentStep }))
  }

  return React.createElement(stepComponents[step], { authToken, onSuccess, additionalInfos })
}

export default ChangeTicketName
