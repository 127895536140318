import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import {
  DEFAULT_HORIZONTAL_SCROLL_CARD_MARGIN_DESKTOP,
  DEFAULT_HORIZONTAL_SCROLL_CARD_MARGIN_MOBILE,
} from 'utils/constants/scroll'

export const getCardMarginSize = (): number => {
  if (!window) {
    return DEFAULT_HORIZONTAL_SCROLL_CARD_MARGIN_MOBILE
  } else {
    return window.innerWidth > BreakpointsMaxWidth.landscape
      ? DEFAULT_HORIZONTAL_SCROLL_CARD_MARGIN_DESKTOP
      : DEFAULT_HORIZONTAL_SCROLL_CARD_MARGIN_MOBILE
  }
}
