import css from './Voices.module.scss'
import React, { PropsWithChildren } from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import WithAppInitialized from 'components/common/WithAppInitialized'

import Desktop from './Desktop'
import Mobile, { MobileSkeleton } from './Mobile'

interface IProps {
  voices: string[]
  activeSection: string
  setActiveMenuVoiceCallback(voice: string): void
}

const Voices: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { voices, activeSection, setActiveMenuVoiceCallback, children } = props

  return (
    <div className={css.pagewithsubsections_voices_container}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <WithAppInitialized skeleton={<MobileSkeleton />}>
          <Mobile voices={voices} activeSection={activeSection} setActiveMenuVoiceCallback={setActiveMenuVoiceCallback}>
            {React.Children.toArray(children)}
          </Mobile>
        </WithAppInitialized>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Desktop voices={voices} activeSection={activeSection} setActiveMenuVoiceCallback={setActiveMenuVoiceCallback}>
          {React.Children.toArray(children)}
        </Desktop>
      </MediaQuery>
    </div>
  )
}

export default Voices
