import css from './Season.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'

interface Props {
  eventsNum: number
}

const Season: React.FC<Props> = (props) => {
  const { eventsNum } = props

  const { t } = useTranslation()

  return (
    <div className={css.checkout_eventdetails_description_season_container}>
      <span className={css.icon}>
        <Img src="/icons/checkout-season-header-icon.svg" />
      </span>
      <span className={css.text}>{t('checkout:description_header_season', { num: eventsNum })}</span>
    </div>
  )
}

export default Season
