import css from './Item.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

interface IProps {
  index: number
  indexblock: number
  question: string
  answer: string
  isOpen: boolean
  fullWidth?: boolean
  setActiveFAQ(index: number, indexblock: number): void
}

const Item: React.FC<IProps> = (props) => {
  const { fullWidth, index, indexblock, question, answer, isOpen, setActiveFAQ } = props

  return (
    <div className={`${css.faqItemContainer} ${fullWidth && css.fullWidth}`}>
      <div className={css.faqQuestionContainer} onClick={() => setActiveFAQ(index, indexblock)}>
        <div className={css.faqQuestion}>{question}</div>
        <Img className={css.faqQuestionIcon} src={isOpen ? '/faq/less.svg' : '/faq/more.svg'} />
      </div>
      {isOpen && <div className={css.faqAnswer}>{answer}</div>}
    </div>
  )
}

export default Item
