export enum ComponentTypeBackend {
  asset = 'asset',
  assetSource = 'assetSource',
  attribute = 'attribute',
  balloon = 'balloon',
  banner = 'banner',
  button = 'button',
  blog = 'blog',
  blogPost = 'blogPost',
  citySquare = 'citySquare',
  eventArtist = 'eventArtist',
  eventDetails = 'eventDetails',
  eventInfo = 'eventInfo',
  eventCollectionMenu = 'eventCollectionMenu',
  eventSquare = 'eventSquare',
  eventParentSquare = 'eventParentSquare',
  eventStory = 'eventStory',
  eventTicket = 'eventTicket',
  filter = 'filter',
  filterMenu = 'filterMenu',
  infoBox = 'infoBox',
  map = 'map',
  horizontalScroll = 'horizontalScroll',
  storyGallery = 'storyGallery',
  ticket = 'ticket',
  typeTicketCollectionTicket = 'typeTicketCollectionTicket',
  venueInfo = 'venueInfo',
  venueAttributes = 'venueAttributes',
  venueDetails = 'venueDetails',
  venueSquare = 'venueSquare',
}

export interface IComponentBackend<C extends ComponentTypeBackend> {
  componentType: C
  uuid: string
}

export interface IComponentNestedBackend<C extends ComponentTypeBackend> extends IComponentBackend<C> {
  list: Array<IComponentBackend<ComponentTypeBackend>>
}
