import css from './CovidAppointmentSlots.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { AppointmentSlotStatus, ITicketAppointmentCovidSlot } from 'meta/pages/tickets'
import { APPOINTMENTS_DIVIDER_TOKEN } from 'utils/constants/form'
import { Dates } from 'utils/dates'

import { ActionAlignment, ButtonWhite } from 'components/Actions/Action'
import { DatePicker } from 'components/Forms/DatePicker'
import { useFormField } from 'components/Forms/Form'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'

interface IProps {
  slots: ITicketAppointmentCovidSlot[]
  appointmentUUID: string
  hiddenFieldId: string
}

const CovidAppointmentSlots: React.FC<IProps> = (props) => {
  const { slots, appointmentUUID, hiddenFieldId } = props

  const { t } = useTranslation()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isDayBoxActive, setDayBoxActive] = useState<boolean>(false)
  const [isHourBoxActive, setHourBoxActive] = useState<boolean>(false)
  const [currentDay, setCurrentDay] = useState<Date | null>(null)
  const [currentTime, setCurrentTime] = useState<string | null>(null)
  const hiddenFieldStatus = useFormField(hiddenFieldId)

  const availableDates = slots
    .filter((sl) => sl.status === AppointmentSlotStatus.free)
    .map((sl) => {
      return sl.from
    })

  const minDate: Date = Dates.min(availableDates)
  const maxDate: Date = Dates.max(availableDates)
  const disabledDates = Dates.getDisabledDate(availableDates)

  const setAppointmentAndSlotUUIDToHiddenField = (date: Date) => {
    const match = slots.find((sl) => sl.from.valueOf() === date.valueOf())

    const uuids = `${appointmentUUID}${APPOINTMENTS_DIVIDER_TOKEN}${match?.uuid}`
    hiddenFieldStatus.setValue(uuids)
  }

  return (
    <div className={css.CovidAppointments_container}>
      {!isModalOpen && (
        <ButtonWhite
          onClick={() => {
            if (availableDates.length < 1) {
              alert(String(t('common:no_slots_available')))
            } else {
              setDayBoxActive(true)
              setModalOpen(true)
            }
          }}
          alignment={ActionAlignment.iconRight}
          className={css.modalButton}
        >
          <div className={css.text}>{currentTime ? currentTime : t('common:set_covid_appointment')}</div>
        </ButtonWhite>
      )}

      {isModalOpen && (
        <Modal
          handleClose={() => {
            setModalOpen(false)
            setDayBoxActive(false)
            setHourBoxActive(false)
          }}
          transparent
          show
        >
          <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
            {isDayBoxActive && (
              <div className={css.dayBox}>
                <h3 className={css.chooseDate}>{t('common:choose_date_day')}</h3>
                <DatePicker
                  date={minDate}
                  disabledDates={disabledDates}
                  minimumDate={minDate}
                  maximumDate={maxDate}
                  onChange={(date) => {
                    setCurrentDay(date)
                    setDayBoxActive(false)
                    setHourBoxActive(true)
                  }}
                  removePadding
                />
              </div>
            )}

            {isHourBoxActive && (
              <div className={css.hourBox}>
                <h3 className={css.chooseDate}>{t('common:choose_date_hour')}</h3>
                <div>
                  Scegli l'orario per la data{' '}
                  {currentDay ? `${Dates.format(currentDay, t('dates:common:dayMonthYearCovid'))}` : null}
                </div>
                <select
                  onChange={(event) => {
                    if (event.target.value !== '') {
                      const currentDate = new Date(parseInt(event.target.value))

                      setCurrentTime(
                        `${Dates.format(currentDate, t('dates:common:dayMonthYearCovid'))} - ${Dates.format(
                          currentDate,
                          t('dates:common:hoursMinutes')
                        )}`
                      )
                      setHourBoxActive(false)
                      setModalOpen(false)
                      setAppointmentAndSlotUUIDToHiddenField(currentDate)
                    }
                  }}
                >
                  <option selected={true} value="">{`${t('common:select_placeholder')}`}</option>

                  {availableDates
                    .filter((d) => {
                      return currentDay?.getDate() === d.getDate()
                    })
                    .map((date, key) => {
                      const label = Dates.format(date, t('dates:common:hoursMinutes'))

                      return (
                        <option key={key} value={date.valueOf()}>
                          {label}
                        </option>
                      )
                    })}
                </select>
              </div>
            )}
          </BalloonWrapper>
        </Modal>
      )}
    </div>
  )
}

export default CovidAppointmentSlots
