import css from './UsersEmailConfirm.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGet } from 'api'

import { RouteParams } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'

import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import Loader from 'components/Layout/Loader'

const UsersEmailConfirm: React.FC = () => {
  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()
  const { token } = useParams<RouteParams['UsersEmailConfirm']>()
  const { error, fetch, response } = useGet()

  const show = error || response

  useEffect(() => {
    fetch({ urlResource: ApiEndpoint.users.emailConfirm(String(token)) })
  }, [])

  return (
    <WithAppInitialized showChildren={namespacesLoaded}>
      {!show && <Loader />}

      {show && (
        <div className={css.content}>
          <div className={css.msg}>{response ? String(t('email_confirm:success')) : String(error)}</div>
          <Link to={`/`}>
            <div className={css.button}>{String(t('email_confirm:continue'))}</div>
          </Link>
        </div>
      )}
    </WithAppInitialized>
  )
}

const namespaces = ['profile']
export default withTranslation(namespaces)(UsersEmailConfirm)
