import css from './BannerTop.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { BannerTop as BannerTopType } from 'components/BackendControlled/types'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Img from 'components/Media/Img'

type Props = {
  banner: BannerTopType
}

const BannerTop: React.FC<Props> = (props) => {
  const { banner } = props
  const { desktop, laptop, mobile, tabletLandscape, tabletPortrait } = banner.imageURLs

  return (
    <WithAppInitialized
      skeleton={
        <Skeleton
          className={css.bannerTopSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={0}
          highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          inline
          duration={1}
        />
      }
    >
      <Img className={css.banner_top_mobile} src={mobile} />
      <Img className={css.banner_top_tabletPortrait} src={tabletPortrait} />
      <Img className={css.banner_top_tabletLandscape} src={tabletLandscape} />
      <Img className={css.banner_top_laptop} src={laptop} />
      <Img className={css.banner_top_desktop} src={desktop} />
    </WithAppInitialized>
  )
}

export default BannerTop
