import css from 'components/Pages/PurchaseForm/Tickets/Tickets.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

import EventDetails from './EventDetails'
import PFTicketSkeleton from './TicketSkeleton'

const TicketsSkeleton: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={css.ticketsContainer}>
      <GridContainer>
        <Grid className={css.tickets}>
          <GridColumn
            gridColumnStart={{
              [Breakpoints.mobile]: 4,
              [Breakpoints.tablet]: 1,
              [Breakpoints.laptop]: 1,
            }}
            gridColumnEnd={{
              [Breakpoints.mobile]: 14,
              [Breakpoints.tablet]: 17,
              [Breakpoints.laptop]: 17,
            }}
          >
            <h1>{t('tickets:title')}</h1>
          </GridColumn>

          <EventDetails />

          {['ticketSkel_1', 'ticketSkel_2'].map((ticket, idx) => (
            <PFTicketSkeleton key={String(idx)} idx={idx} />
          ))}
        </Grid>
      </GridContainer>
    </div>
  )
}

export default TicketsSkeleton
