import css from './Desktop.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange, { Range } from 'react-input-range'

import { CurrencySymbol } from 'meta/currency'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { convertAndFormat, convertCurrency, getCodeFromSymbol } from 'helpers/currency/converter'

import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutDetails } from 'hooks/useCheckout'

interface IProps {
  setPriceRangeCallback(range: any): void
  priceRangeInitial: Range
  priceRangeCurrent: Range
}

const Desktop: React.FC<IProps> = (props) => {
  const { priceRangeCurrent, priceRangeInitial, setPriceRangeCallback } = props

  const { t } = useTranslation()
  const { eventTicket } = useCheckoutDetails() as ICheckoutDetails
  const { activeCurrency } = useActiveCurrency()

  const eventCurrencyCode = eventTicket.data[0]
    ? getCodeFromSymbol(eventTicket.data[0].currency as CurrencySymbol)
    : activeCurrency

  return (
    <div className={css.checkout_ticketselection_filters_pricerange_desktop_container}>
      <div className={css.text}>{t('checkout:price_range')}</div>

      <div className={css.slider}>
        <InputRange
          draggableTrack
          maxValue={priceRangeInitial.max}
          minValue={priceRangeInitial.min}
          value={priceRangeCurrent}
          onChange={(range) => {
            setPriceRangeCallback(range)
          }}
        />
      </div>

      <div className={css.text}>
        {`${String(t('checkout:price_range_from'))} ${convertCurrency(
          priceRangeCurrent.min,
          eventCurrencyCode,
          activeCurrency
        )} ${String(t('checkout:price_range_to'))} ${convertAndFormat(
          priceRangeCurrent.max,
          eventCurrencyCode,
          activeCurrency,
          true
        )}`}
      </div>
    </div>
  )
}

export default Desktop
