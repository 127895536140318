import { IEventCollectionMenu, IEventDetails, IEventTicket } from 'meta/backendControlled/structure'
import { ComponentsAdapter } from 'meta/backendControlled/structureBackend'
import { ComponentTypeBackend, IComponentBackend } from 'meta/backendControlled/structureBackend/component/component'
import { IMapSeat } from 'meta/map'
import {
  CardGateway,
  CheckoutStep,
  CouponCodeStatus,
  ICheckoutDetails,
  ICheckoutExtraDetailsData,
  ICheckoutPayment,
  ICheckoutStripeData,
  PaymentMethod,
  TicketSelectionType,
} from 'meta/pages/checkout'
import { ITypeTicketCollection } from 'meta/pages/collection'
import { ApiEndpoint } from 'controller/endpoint'
import { DetailsParams } from 'store/pages/checkout/slice/types'
import { HttpRequest } from 'utils/http'

type Params = {
  codeUrl: string
  typeTicketCollectionId?: string
  initialTypeTicketId?: number
  insideIFrame?: boolean
  fromCollectionIframe?: boolean
  seats?: Array<IMapSeat>
}

export const getDetails = async (params: Params): Promise<DetailsParams> => {
  const { codeUrl, initialTypeTicketId, insideIFrame, fromCollectionIframe, seats, typeTicketCollectionId } = params

  const backendEndpoint = typeTicketCollectionId
    ? ApiEndpoint.checkout.seasonDetails(codeUrl, typeTicketCollectionId)
    : ApiEndpoint.checkout.details(codeUrl)

  const { data, metadata } = await HttpRequest.get<
    {
      event?: IComponentBackend<ComponentTypeBackend>
      eventCollection?: IComponentBackend<ComponentTypeBackend>
      typeTicketCollection?: ITypeTicketCollection
      extraDetails?: ICheckoutExtraDetailsData
      hasMap: boolean
      purchaseQuantityLimit: number
      isAuthenticationRequired: boolean
      paymentProviders: {
        cardGateway?: CardGateway
        payments: Array<PaymentMethod>
      }
      tickets?: IComponentBackend<ComponentTypeBackend>
    },
    { parentCodeUrl?: string }
  >(backendEndpoint)

  const {
    event,
    hasMap,
    isAuthenticationRequired,
    purchaseQuantityLimit,
    paymentProviders,
    tickets,
    eventCollection,
    typeTicketCollection,
    extraDetails,
  } = data

  const parentCodeUrl = metadata?.parentCodeUrl

  const componentsAdapter = new ComponentsAdapter()
  const eventDetails = event ? (componentsAdapter.convertComponent(event) as IEventDetails) : null
  const eventTicket = tickets ? (componentsAdapter.convertComponent(tickets) as IEventTicket) : null
  const collection = eventCollection
    ? (componentsAdapter.convertComponent(eventCollection) as IEventCollectionMenu)
    : null

  const details: ICheckoutDetails = {
    eventDetails: eventDetails as IEventDetails,
    eventTicket: eventTicket as IEventTicket,
    typeTicketCollection: typeTicketCollection ? { ...typeTicketCollection, id: codeUrl } : typeTicketCollection,
    hasMap,
    purchaseQuantityLimit,
    metadata: { parentCodeUrl: parentCodeUrl },
    eventCollection: collection as IEventCollectionMenu,
    initialSeats: eventTicket && eventTicket.map ? seats : undefined,
    initialTypeTicketId,
    insideIFrame: insideIFrame,
    fromCollectionIframe: fromCollectionIframe,
    needAuth: isAuthenticationRequired,
  }

  const payment: ICheckoutPayment = {
    couponCode: '',
    couponCodeStatus: CouponCodeStatus.unchecked,
    couponCodeError: '',
    couponCodeAmount: '0',
    cardGateway: paymentProviders.cardGateway,
    methodCurrent: PaymentMethod.none,
    methodsAvailable: paymentProviders.payments.filter((provider) => !['STRIPE', 'LIST'].includes(provider)),
  }

  const stripe: ICheckoutStripeData = {
    paymentIntent: undefined,
    paymentMethod: undefined,
  }

  return {
    details,
    payment,
    stripe,
    extraDetails,
    step: CheckoutStep.select,
    ticketSelection: {
      type: eventTicket && eventTicket.map ? TicketSelectionType.manual : TicketSelectionType.automatic,
    },
  }
}
