import css from './Story.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { StorySkeleton as StorySkeletonType } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn, GridColumnFraction } from 'components/Layout/Grid'

export const StorySkeleton: React.FC<IPropsComponent<StorySkeletonType>> = () => {
  return (
    <GridColumn
      gridColumnSpan={{
        [Breakpoints.laptop]: GridColumnFraction.oneSixth,
        [Breakpoints.tablet]: GridColumnFraction.oneSixth,
        [Breakpoints.mobile]: GridColumnFraction.oneFourth,
      }}
      className={classNames(css.storyContainer, css.skeleton)}
    >
      <Skeleton
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        height={'100%'}
        highlightColor={TICKETSMS_COLORS_WHITE}
      />
    </GridColumn>
  )
}
