import { CurrencySymbol } from 'meta/currency'
import { ICheckout, ICheckoutDetails, PaymentGateway } from 'meta/pages/checkout'
import { ApiEndpoint } from 'controller/endpoint'
import { getCodeFromSymbol } from 'helpers/currency/converter'
import { HttpRequest } from 'utils/http'

type Params = {
  checkout: ICheckout
  email: string
  token?: string
}

type Returned = {
  transactionCode: string
  paymentGateway: PaymentGateway
  paymentGatewayData?: Record<string, string>
}

export const prepare = async (params: Params): Promise<Returned> => {
  const { checkout, email, token } = params
  const { cart, details, eventCodeUrl, payment, recap } = checkout
  const { eventTicket } = details as ICheckoutDetails

  const eventCurrencyCode = getCodeFromSymbol(eventTicket.data[0].currency as CurrencySymbol)
  const body = {
    codeUrl: eventCodeUrl,
    cartTickets: cart?.tickets,
    price: {
      amount: ((recap?.total?.amount ?? 0) * 100).toFixed(),
      currency: eventCurrencyCode,
    },
    paymentProvider: payment?.methodCurrent,
    paymentProviderData: payment?.couponCode ? { code: payment.couponCode } : null,
    email,
  }

  const { data } = await HttpRequest.post<Returned>(ApiEndpoint.checkout.prepare(), body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}
