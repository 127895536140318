import css from './Desktop.module.scss'
import React from 'react'
import { Range } from 'react-input-range'

import PriceRange from 'components/Checkout/TicketSelection/Filters/PriceRange'

interface IProps {
  setPriceRangeCallback(range: any): void
  priceRangeInitial: Range
  priceRangeCurrent: Range
}

const Desktop: React.FC<IProps> = (props) => {
  const { priceRangeInitial, priceRangeCurrent, setPriceRangeCallback } = props

  return (
    <div className={css.checkout_ticketselection_filters_desktop_container}>
      {priceRangeInitial.min !== priceRangeInitial.max && (
        <PriceRange
          priceRangeCurrent={priceRangeCurrent}
          priceRangeInitial={priceRangeInitial}
          setPriceRangeCallback={setPriceRangeCallback}
        />
      )}
    </div>
  )
}

export default Desktop
