import { AssetType, BackendControlledOGImage, ComponentType, IAsset, IEventDetails } from '../../structure'
import { IEventDetailsBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class EventDetailsAdapter extends ComponentAdapter<IEventDetails, IEventDetailsBackend> {
  convert(): IEventDetails {
    const {
      codeUrl,
      canceled,
      dateEndSellTicket_tz,
      dateStartSellTicket_tz,
      datetime_tz,
      description,
      eventAsset,
      id,
      isRefundable,
      isPersonalDataRequired,
      infoPhone,
      fbPixel,
      location,
      name,
      policy,
      secondaryTicketing,
    } = this.componentBackend
    // @ts-ignore
    const { covers } = eventAsset
    const coverImg = covers && covers.length > 0 ? covers[0].src[0].url : null

    const eventDetails: IEventDetails = {
      asset: {
        covers: this.componentsAdapter.convertComponents(covers) as Array<IAsset>,
      },
      componentType: ComponentType.eventDetails,
      coverImg,
      codeUrl,
      dateEndSellTicket: dateEndSellTicket_tz,
      dateStartSellTicket: dateStartSellTicket_tz,
      datetime: datetime_tz,
      description,
      id,
      isCancelled: canceled,
      isRefundable: isRefundable,
      infoPhone,
      fbPixel,
      location: {
        city: location.city,
        common: location.common,
        latitude: Number.parseFloat(location.latitude),
        longitude: Number.parseFloat(location.longitude),
        mapsPlaceId: location.mapsPlaceId,
        name: location.name,
      },
      name,
      isPersonalDataRequired: isPersonalDataRequired,
      policy,
      secondaryTicketing,
    }

    this.parseMetadata(eventDetails)

    return eventDetails
  }

  parseMetadata(eventDetails: IEventDetails): void {
    eventDetails.asset?.covers?.forEach((cover) => {
      const url = cover?.src?.[0]?.url
      if (cover.type === AssetType.image && url) {
        const ogImage: BackendControlledOGImage = { url }
        this.componentsAdapter.metadata.og.images.push(ogImage)
      }
    })
  }
}
