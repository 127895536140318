import React from 'react'

import { Action, ActionColor, IPropsAction } from './Action'

export enum LinkTarget {
  blank = '_blank',
  self = '_self',
  parent = '_parent',
  top = '_top',
}

interface IPropsLink extends IPropsAction {
  href: string
  target?: LinkTarget
  title?: string
}

export const Link: React.FC<IPropsLink> = (props) => <Action element="a" {...props} />

Link.defaultProps = {
  borderColor: ActionColor.transparent,
}

// ==== Commonly used Link - First color background color, second border color
export const LinkBlack: React.FC<IPropsLink> = (props) => (
  <Link {...props} backgroundColor={ActionColor.black} borderColor={ActionColor.black} color={ActionColor.white} />
)

export const LinkGrey: React.FC<IPropsLink> = (props) => (
  <Link {...props} backgroundColor={ActionColor.grey} borderColor={ActionColor.grey} color={ActionColor.white} />
)

export const LinkRed: React.FC<IPropsLink> = (props) => (
  <Link {...props} backgroundColor={ActionColor.red} borderColor={ActionColor.red} color={ActionColor.white} />
)

export const LinkTransparentBlack: React.FC<IPropsLink> = (props) => (
  <Link
    {...props}
    backgroundColor={ActionColor.transparent}
    borderColor={ActionColor.black}
    color={ActionColor.black}
  />
)

export const LinkWhite: React.FC<IPropsLink> = (props) => (
  <Link
    {...props}
    backgroundColor={ActionColor.white}
    borderColor={ActionColor.transparent}
    color={ActionColor.black}
  />
)

export const LinkWhiteBlack: React.FC<IPropsLink> = (props) => (
  <Link {...props} backgroundColor={ActionColor.white} borderColor={ActionColor.black} color={ActionColor.black} />
)

export const LinkWhiteRed: React.FC<IPropsLink> = (props) => (
  <Link {...props} backgroundColor={ActionColor.white} borderColor={ActionColor.red} color={ActionColor.red} />
)
