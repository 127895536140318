import css from './Header.module.scss'
import React from 'react'
import classNames from 'classnames'

import AvatarBox from 'components/Auth/Profile/Header/AvatarBox'
import Img from 'components/Media/Img'
import { useUserData } from 'hooks/useAuth'

const Header: React.FC = () => {
  const userData = useUserData()

  return (
    <>
      <div className={css.avatarContainer}>
        <AvatarBox />
      </div>

      <div className={classNames(css.userDataContainer, css.profileHeaderDesktopContainer)}>
        <div className={css.name}>{`${userData?.firstName} ${userData?.lastName}`}</div>
        <div className={css.phone}>{`${userData?.phonePrefix} ${userData?.phone}`}</div>
        <div className={css.email}>{userData?.email}</div>
        <div className={css.birthPlace}>
          <Img src="/icons/location_small.png" />
          <div>{userData?.birthPlace}</div>
        </div>
      </div>
    </>
  )
}

export default Header
