export const getValidEmailRegex = (): RegExp => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
}

export const getValidPasswordRegex = (): RegExp => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/
}

export const getValidPhonePrefixRegex = (): RegExp => {
  return /^\++[0-9]{1,4}$/
}

export const getValidGenericInternationalPhoneRegex = (): RegExp => {
  return /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
}

export const getValidNameRegex = (): RegExp => {
  // eslint-disable-next-line no-useless-escape
  return /^[A-Za-zÀ-ÖØ-öø-ÿ]{1,}[\'\'’\s]?[\'\'’A-Za-zÀ-ÖØ-öø-ÿ\s]{1,}[\'\'’A-Za-zÀ-ÖØ-öø-ÿ]?$/
}

export const getValidBirthDateRegex = (): RegExp => {
  return /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
}

export const getValidDigitRegex = (): RegExp => {
  return /^[0-9]{1}$/
}

export const getValidFiscalSealRegex = (): RegExp => {
  return /^[0-9A-Z]{16}$/
}

export const getValidCountryCodeRegex = (): RegExp => {
  return /^[A-Z]{3}$/
}

export const getValidCityCodeRegex = (): RegExp => {
  return /^[0-9A-Z]{4}$/
}

export const getValidVoucherRegex = (): RegExp => {
  return /^[0-9A-Za-z]{8}$/
}

export const getValidFiscalCodeRegex = (): RegExp => {
  return /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
}

export const getValidTextAreaRegex = (): RegExp => {
  // eslint-disable-next-line no-useless-escape
  return /^[0-9A-Za-zÀ-ÖØ-öø-ÿ .,;:()''’\s€+*%@?!\"=\\/-]*$/
}
