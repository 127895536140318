import { MutableRefObject, useCallback, useEffect } from 'react'
import { timer } from 'rxjs'
import { repeatWhen, takeUntil } from 'rxjs/operators'

import { StoryProps } from '../props'
import { PlayDefaults } from './defaults'
import { Play } from './usePlay'

export type PlayActions = {
  playItem: (idx: number) => void
  playNextItem: () => void
  playPrevItem: () => void
}

export const usePlayActions = (
  props: StoryProps,
  idxActive: number,
  setIdxActive: (idx: number) => void,
  play: Play,
  progressRef: MutableRefObject<HTMLDivElement>,
  videoRef: MutableRefObject<HTMLVideoElement>
): PlayActions => {
  const { active, playNextStory, playPrevStory, story } = props
  const { gallery } = story

  const setProgressItem = useCallback((idx: number, progress: number): void => {
    if (progressRef.current) {
      const progressItem = progressRef.current.children[idx]
      const progressItemShow = progressItem.children[0] as HTMLDivElement
      progressItemShow.style.transform = `scaleX(${progress})`
    }
  }, [])

  const startTimer = (idx: number, duration: number = PlayDefaults.interval): void => {
    play.timerSubscription$.current = timer(0, PlayDefaults.period)
      .pipe(
        takeUntil(play.pauseEvent$.current),
        repeatWhen(() => play.playEvent$.current)
      )
      .subscribe((x) => {
        play.timeElapsed.current = x * PlayDefaults.period
        const timeLeft = play.interval.current - play.timeElapsed.current
        setProgressItem(idx, 1 - timeLeft / duration)

        if (timeLeft <= 0) {
          playItem(idx + 1)
        }
      })
  }

  const playItem = useCallback((idx: number) => {
    play.unsubscribe()

    if (idx >= gallery.length) {
      playNextStory()
      return
    }
    if (idx < 0) {
      playPrevStory()
      return
    }

    setIdxActive(idx)

    const video = videoRef.current
    if (video) {
      video.currentTime = 0
      video.muted = true
      video.play().then(() => {
        const duration = video.duration * 1000
        play.playingVideo.current = true
        play.interval.current = duration
        startTimer(idx, duration)
      })
    } else {
      startTimer(idx)
    }
  }, [])

  const playNextItem = useCallback(() => {
    setProgressItem(idxActive, 1)
    playItem(idxActive + 1)
  }, [idxActive])

  const playPrevItem = useCallback(() => {
    setProgressItem(idxActive, 0)
    playItem(idxActive - 1)
  }, [idxActive])

  // if active starts autoplay
  useEffect(() => {
    const video = videoRef.current
    if (video) video.pause()

    if (active) {
      playItem(idxActive)
    }

    return play.unsubscribe
  }, [active])

  return {
    playItem,
    playNextItem,
    playPrevItem,
  }
}
