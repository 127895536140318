import css from './FormField.module.scss'
import React from 'react'
import classNames from 'classnames'

import { FormFieldType } from 'meta/form'

import Checkbox from './Checkbox'
import FormFieldError from './FormFieldError'
import Hidden from './Hidden'
import Input from './Input'
import InputDate from './InputDate'
import LinkedDataList from './LinkedDataList'
import Phone from './Phone'
import { IFormFieldProps } from './props'
import Radio from './Radio'
import Select from './Select'
import TextArea from './TextArea'
import useFormField from './useFormField'

const ComponentsByType: Record<string, React.FC<IFormFieldProps>> = {
  [FormFieldType.checkbox]: Checkbox,
  [FormFieldType.date]: InputDate,
  [FormFieldType.hidden]: Hidden,
  [FormFieldType.linked]: LinkedDataList,
  [FormFieldType.number]: Input,
  [FormFieldType.password]: Input,
  [FormFieldType.phone]: Phone,
  [FormFieldType.radio]: Radio,
  [FormFieldType.select]: Select,
  [FormFieldType.text]: Input,
  [FormFieldType.textArea]: TextArea,
}

const excludeWrapper: Array<FormFieldType> = [FormFieldType.phone]

const FormField: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, type } = formField
  const { error, touched } = useFormField(name)
  const hasError = Boolean(touched && error)
  const Component = ComponentsByType[type]
  const element = React.createElement(Component, { formField, hasError })
  if (excludeWrapper.includes(type)) return element

  return (
    <div className={classNames(css.formField, css[`formField__${type}`], { [css.error]: hasError })}>
      {element}
      {hasError && <FormFieldError error={error!} name={name} />}
    </div>
  )
}

export default FormField
