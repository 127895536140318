import css from './SeatTooltip.module.scss'
import React, { CSSProperties, MutableRefObject, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IMap, IMapSeat, Map3DViewer, MapIDs, MapViewerFactory, MapViewerMediator } from 'meta/map'

import { useSectionLabel } from 'hooks/useEvent'
import { useIsMounted } from 'hooks/useIsMounted'

interface IProps {
  id: string
  map: IMap
  mapSeat: IMapSeat
  mapViewerMediator: MapViewerMediator
  modalRef?: MutableRefObject<HTMLElement>
}

let viewer: Map3DViewer | null = null
const initViewer = async (container: HTMLDivElement) => {
  if (!viewer) {
    viewer = await MapViewerFactory.createMap3DViewer(container)
  }
  return viewer
}

const SeatTooltip: React.FC<IProps> = (props) => {
  const { id, map, mapSeat, mapViewerMediator, modalRef } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()
  const seat3dRef = useRef<HTMLDivElement>(null)
  const modal: HTMLElement | null = modalRef && modalRef.current ? modalRef.current : null

  const { section, row, seat } = MapIDs.toSeatIDs(mapSeat.seatId)
  const sectionLabel = useSectionLabel(id, section)

  const { left, top, x, y, width } = mapSeat.clientRect as DOMRectReadOnly
  const cssLeft = `${left - 64 + width / 2}px`
  const cssTop = `${top - 144}px`
  const style: CSSProperties = {
    left: modal ? `calc(${cssLeft} - ((100vw - 1200px) / 2))` : cssLeft,
    top: modal ? `calc(${cssTop} + ${modal.scrollTop}px - 5vh)` : cssTop,
  }

  // @ts-ignore
  useLayoutEffect(() => {
    if (map.type === '3D' && seat3dRef.current) {
      initViewer(seat3dRef.current).then(() => {
        viewer?.getThumbnail(map, mapSeat).then((thumbnail) => {
          if (isMounted.current && seat3dRef.current) {
            seat3dRef.current.appendChild(thumbnail)
          }
        })
      })

      return () => viewer && viewer.reset()
    }
  }, [isMounted, map, mapSeat])

  return (
    <div className={classNames(css.seatTooltip, { [css.seat3d]: map.type === '3D' })} style={style}>
      <div className={css.seatTooltip__content}>
        <div
          className={css.seatTooltip__contentSeat}
          onClick={() => {
            mapViewerMediator.map2DViewer.selectSeat(mapSeat.seatId)
          }}
        >
          <strong>{sectionLabel}</strong>
          <div className={css.seatTooltip__contentSeparator} />
          <div>
            <div>{t('checkout:row', { row })}</div>
            <div>{t('checkout:seat', { seat })}</div>
          </div>
        </div>
        <div className={css.seatTooltip__contentSeat3d} ref={seat3dRef} />
      </div>
    </div>
  )
}

export default SeatTooltip
