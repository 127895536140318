import css from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CurrencyCode } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ITransaction } from 'meta/pages/profile'
import { convertAndFormat } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'

import { useActiveCurrency } from 'hooks/useActiveCurrency'

type Props = {
  data: ITransaction
}

const Mobile: React.FC<Props> = (props) => {
  const { data } = props
  const { currency, amount } = data.priceData
  const { onlySeasonTickets } = data

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()

  const convertedPrice = convertAndFormat(
    parseInt(amount) / 100,
    CurrencyCode[currency as CurrencyCode],
    activeCurrency
  )

  return (
    <>
      <div className={css.header}>
        <div className={css.eventName} data-testid={DataTestId.profile.transactionEventName}>
          {data.eventName}
        </div>
        <div>
          <span>{t('profile:event_date')} </span>
          <strong>{Dates.format(data.eventDate, t('dates:common:dayMonthYear'))}</strong>
          <span> / </span>
          <span>{t('profile:event_time')} </span>
          <strong>{Dates.format(data.eventDate, t('dates:common:hoursMinutes'))}</strong>
        </div>
      </div>

      <hr />

      <div className={css.transactionItem}>
        <div>{t('profile:status')}</div>
        <strong className={css.fcUpper}>{t(`profile:${data.status}`)}</strong>
      </div>
      <div className={css.transactionItem}>
        <div>{t('profile:payment_method')}</div>
        <strong>{t(`profile:${data.paymentMethod}`)}</strong>
      </div>
      <div className={css.transactionItem}>
        <div>{t('profile:price')}</div>
        <strong data-testid={DataTestId.profile.transactionPrice}>{convertedPrice}</strong>
      </div>
      <div className={css.ticketsNumberContainer}>
        <div className={css.transactionItem}>
          <div>{onlySeasonTickets ? t('profile:pass_qty') : t('profile:tickets_qty')}</div>
          <strong className={css.ticketsNumber}>{data.numTickets}</strong>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Mobile
