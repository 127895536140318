import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'

const _getState = (state: RootState) => state.pages.event

const getVisibleEventFeesCodeUrl = createSelector(_getState, (state) => state.fees.visibleEventCodeUrl)

const getVisibleEventFees = createSelector(
  [getVisibleEventFeesCodeUrl, (state) => _getState(state).fees.events],
  (codeUrl, eventFees) => (codeUrl ? eventFees[codeUrl] : undefined)
)

const getEventMetadata = createSelector(_getState, (state) => state.metadata)

export const EventSelectors = {
  getVisibleEventFeesCodeUrl,
  getVisibleEventFees,
  getEventMetadata,
}
