import { ISearchEventCategory } from 'meta/pages/search'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

export const getCategories = async (): Promise<Array<ISearchEventCategory>> => {
  const { data } = await HttpRequest.get<{ eventCategories: Array<ISearchEventCategory> }>(
    ApiEndpoint.event.categories()
  )
  return data.eventCategories
}
