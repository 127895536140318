import { IEventSeats } from 'meta/pages/event'
import { TicketFee } from 'meta/pages/tickets'

type FeesState = {
  events: Record<string, Array<TicketFee>> // record of ticket fees by code url
  visibleEventCodeUrl?: string // code url of event fees currently displayed
  visibleEventTypeTicketCollectionId?: string
}

export type EventState = {
  fees: FeesState
  seats: Record<string, IEventSeats>
  isEventFree: boolean
  isEventEnded?: boolean
  metadata?: object
}

export const initialState: EventState = {
  fees: { events: {} },
  seats: {},
  isEventFree: false,
  isEventEnded: undefined,
  metadata: undefined,
}
