import css from './LocationSocial.module.scss'
import React from 'react'

import { ComponentColor, IVenueDetails, IVenueInfo } from 'meta/backendControlled/structure'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { ButtonFacebook, ButtonHyperlink, ButtonInstagram, ButtonMap, ButtonPhone } from 'components/Social'

interface IPropsLocationSocial extends IPropsComponent<IVenueDetails | IVenueInfo> {
  color: ComponentColor
}

const LocationSocial: React.FC<IPropsLocationSocial> = (props) => {
  const { color, facebookLink, instagramLink, latitude, longitude, mapsPlaceId, phone, website } = props

  return (
    <div className={css.locationSocial}>
      <ButtonPhone color={color} phone={phone} />
      <ButtonFacebook color={color} facebookLink={facebookLink} />
      <ButtonInstagram color={color} instagramLink={instagramLink} />
      <ButtonHyperlink color={color} link={website} />
      <ButtonMap mapsPlaceId={mapsPlaceId} color={color} latitude={latitude} longitude={longitude} />
    </div>
  )
}

export default LocationSocial
