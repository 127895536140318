import css from './Cancelled.module.scss'
import React from 'react'

import { ComponentColor } from 'meta/backendControlled/structure'

import RoundedBox from 'components/TextBoxes/RoundedBox'

const CancelledBox = () => (
  <div
    className={css.boxContainer}
    onClick={() => (window.location.href = 'https://mag.ticketsms.it/rimborso-per-evento-annullato/')}
  >
    <RoundedBox bgColor={ComponentColor.yellow} infoType="warning">
      <b>ATTENZIONE: EVENTO ANNULLATO!</b>
      <div>Clicca qui per informazioni sulla procedura di rimborso.</div>
    </RoundedBox>
  </div>
)

export default CancelledBox
