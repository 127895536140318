import { LoginData, LoginStep } from 'meta/pages/login'
import { useAppSelector } from 'store'

export const useLoginStep = (): LoginStep => useAppSelector((state) => state.pages.login?.step)

export const useLoginData = (): LoginData => useAppSelector((state) => state.pages.login?.data)

export const useLoginShow = (): boolean => useAppSelector((state) => state.pages.login?.show)

export const useLoginPathname = (): string | undefined => useAppSelector((state) => state.pages.login?.pathname)
