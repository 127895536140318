import css from './TicketFormFields.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import CovidAppointmentSlots from '../CovidAppointmentSlots'
import { FormFieldType } from 'meta/form'
import { ITicket } from 'meta/pages/tickets'

import { cssForm, FormField } from 'components/Forms/Form'
import { linkCountriesAndCities } from 'components/Forms/Form/FormField/LinkedDataList/utils'

import { ITicketsFormFields } from '../../types'
import { getTicketFieldsKey } from '../../utils'

interface IPropsTicketFormFields {
  formFields: ITicketsFormFields
  idx: number
  ticket: ITicket
}

const TicketFormFields: React.FC<IPropsTicketFormFields> = (props) => {
  const { formFields, idx, ticket } = props
  const fieldsKey = getTicketFieldsKey(ticket, idx)
  const { t } = useTranslation()

  const commonFields = formFields.commonFields[fieldsKey]
  const extraFields = formFields.extraFields[fieldsKey]
  const covid19Fields = formFields.covid19Fields[fieldsKey]
  const personalDataFields = formFields.personalDataFields[fieldsKey]

  const [country, setCountry] = useState<string | null>(null)

  return (
    <div className={classNames(cssForm.form, css.ticketFormFields)}>
      {commonFields.map((field) => (
        <FormField key={field.name} formField={field} />
      ))}

      {personalDataFields.map((field) => {
        if (field.type === FormFieldType.linked) {
          field = linkCountriesAndCities(field, setCountry, country)
        }

        return <FormField key={field.name} formField={field} />
      })}

      {extraFields.map((field) => {
        const required = Boolean(field.validation?.required)
        return (
          <div key={field.name} className={css.ticketFormFields__extraField}>
            <div className={css.ticketFormFields__extraFieldLabel}>
              {field.fieldLabel} {!required && t('form:optional')}
            </div>
            <FormField formField={field} />
          </div>
        )
      })}

      {covid19Fields?.map((covid19Field) => {
        const { formField, hiddenFieldId, appointment } = covid19Field
        return (
          <div key={formField.name} className={css.ticketFormFields__extraField}>
            <div className={css.ticketFormFields__extraFieldLabel}>{appointment.name}</div>
            <div className={css.covidAppointments_wrapper}>
              <CovidAppointmentSlots
                hiddenFieldId={hiddenFieldId}
                appointmentUUID={appointment.uuid}
                slots={appointment.appointmentSlots}
              />
              <FormField formField={formField} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TicketFormFields
