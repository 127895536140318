import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CurrencyCode } from 'meta/currency'
import { initialState } from 'store/currency/state'

export const CurrencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    updateCurrency: (state, action: PayloadAction<{ code: CurrencyCode }>) => {
      const { code } = action.payload
      state.activeCurrency = code
    },
  },
})
