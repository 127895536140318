import css from 'components/Pages/Refund/Refund.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import DataTestId from 'meta/dataTestId'
import { IRefund, IRefundResponse } from 'meta/pages/refund'
import { RouteParams } from 'meta/routes'

import WithAppInitialized from 'components/common/WithAppInitialized'
import FormSubmissionConfirm from 'components/Forms/FormSubmissionConfirm'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import RefundForm from 'components/Pages/Refund/RefundForm'
import RefundSkeleton from 'components/Pages/Refund/RefundSkeleton'
import { useIsAppInitialized } from 'hooks/useApp'
import { useBackendApiBase } from 'hooks/useEnv'

const Refund: React.FC = () => {
  const { codeTransaction } = useParams<RouteParams['Refund']>()

  const { t } = useTranslation()
  const backendApiBase = useBackendApiBase()
  const appInitialized = useIsAppInitialized()
  const { namespacesLoaded } = useI18NContext()

  const [refund, setRefund] = useState<IRefund>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const onSuccess = () => setShowConfirm(true)

  useEffect(() => {
    if (!appInitialized || !backendApiBase || !codeTransaction) return

    axios
      .get(`${backendApiBase}/payments/refund/${codeTransaction}`)
      .then((response) => response.data)
      .then((response: IRefundResponse) => setRefund(response.data))
  }, [appInitialized, backendApiBase, codeTransaction])

  if (showConfirm) {
    return <FormSubmissionConfirm messageKey="refund:submitSuccess" />
  }

  return (
    <WithAppInitialized skeleton={<RefundSkeleton />} showChildren={namespacesLoaded}>
      <div className={css.refundBox}>
        <div data-testid={DataTestId.refund.title} className={css.title}>
          <Img src="/refund/header_refund.svg" />
          {t('refund:title')}
        </div>
        <div className={css.introduction}>
          <div>{t('refund:introduction1')}</div>
          <div>{t('refund:introduction2')}</div>
        </div>
        <hr />

        <div className={css.sectionHeader}>{t('refund:termsAndConditions')}</div>
        {refund && <div className={css.terms} dangerouslySetInnerHTML={{ __html: refund.paymentData.terms }} />}
        <hr />

        <div className={css.warningBox}>
          <div className={css.sectionHeader}>
            <Img src="/icons/alarm_red.svg" />
            {refund && <div>{t('refund:cancelTickets', { ticketCount: refund.ticketCount })}</div>}
          </div>
          <div className={css.irreversibleProcedure}>{t('refund:irreversibleProcedure')}</div>
        </div>

        {codeTransaction && refund && (
          <RefundForm onSuccess={onSuccess} refund={refund} codeTransaction={codeTransaction} />
        )}
      </div>
    </WithAppInitialized>
  )
}

const namespaces = ['refund']

export default withTranslation(namespaces)(Refund)
