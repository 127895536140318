import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import { useLoginPathname } from 'hooks/useLogin'

type Returned = {
  onClose: () => void
  onComplete: () => void
}

export const useLoginCallbacks = (): Returned => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pathname = useLoginPathname()

  const onClose = useCallback(() => {
    dispatch(LoginActions.reset())
  }, [dispatch])

  const onComplete = useCallback(() => {
    if (pathname) {
      navigate(pathname, { replace: true })
    }
    onClose()
  }, [navigate, onClose, pathname])

  return { onClose, onComplete }
}
