import Cookies, { CookieAttributes } from 'js-cookie'

import { CurrencyCode } from 'meta/currency'
import { getDecodedPath, getEncodedPath } from 'utils/auth'

import { CookieName, DEFAULT_PR_COOKIE_TIME, ICookie } from './types'

type ICookieClientParams = CookieAttributes

export const CookieClient: ICookie<ICookieClientParams> = {
  set: (name: CookieName, value: string, params?: ICookieClientParams) => {
    Cookies.set(name, value, params)
  },

  get: (name: CookieName): string => {
    return Cookies.get(name) as string
  },

  remove: (name: CookieName, params?: ICookieClientParams): void => {
    Cookies.remove(name, params)
  },
}

export const getPrObject = () => {
  let rawPrString: string = CookieClient.get(CookieName.PR_EVENT_OBJECT)
  let prObj = rawPrString ? JSON.parse(rawPrString) : {}

  return prObj
}

export const setPurchaseFormCookieString = async (transactionCode: string, transactionToken: string): Promise<void> => {
  const currentPath = `/purchase/form?transactionCode=${transactionCode}&transactionToken=${transactionToken}`
  const encodedPath = getEncodedPath(currentPath)

  CookieClient.set(CookieName.PURCHASE_FORM_URLENCODED_STRING, encodedPath)
}

export const getPurchaseFormCookieString = (): string => {
  const encodedPath = CookieClient.get(CookieName.PURCHASE_FORM_URLENCODED_STRING)
  return encodedPath ? getDecodedPath(encodedPath) : null
}

export const removePurchaseFormCookieString = async (): Promise<void> => {
  CookieClient.remove(CookieName.PURCHASE_FORM_URLENCODED_STRING)
}

export const updatePrObject = (obj: Object, cookieTime: number = DEFAULT_PR_COOKIE_TIME) => {
  CookieClient.set(CookieName.PR_EVENT_OBJECT, JSON.stringify(obj), { expires: 1 / cookieTime })
}

export const setCurrencyCookie = (code: CurrencyCode) => {
  CookieClient.set(CookieName.CURRENCY, code)
}

export const deleteCurrencyCookie = () => {
  CookieClient.remove(CookieName.CURRENCY)
}
