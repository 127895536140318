import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionAsset } from 'meta/pages/collection'

import Desktop from './Desktop'
import Mobile from './Mobile'

interface Props {
  images: CollectionAsset[]
  setVisible(num: number): void
}

const Images: React.FC<Props> = (props) => {
  const { images, setVisible } = props

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
        <Mobile images={images} setVisible={setVisible} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
        <Desktop images={images} setVisible={setVisible} />
      </MediaQuery>
    </>
  )
}

export default Images
