export const eventSkeleton = [
  {
    'color': 'white',
    'componentType': 'balloon',
    'size': 'big',
    'tipPosition': 'right',
    'list': [
      {
        'componentType': 'eventDetailsSkeleton',
        'uuid': 'f9e2abfe-f1b8-45e8-a1c8-25ea25439895',
      },
      {
        'componentType': 'horizontalScroll',
        'button': null,
        'layout': 'square',
        'scrollBehaviour': 'single',
        'category': {
          'componentType': 'categorySkeleton',
          'data': [
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a0bb',
            },
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a1bb',
            },
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a2bb',
            },
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a3bb',
            },
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a4bb',
            },
            {
              'componentType': 'assetSkeleton',
              'format': 'gallery',
              'type': 'image',
              'uuid': '0636cf20-66c6-4920-8f73-99225780a5bb',
            },
          ],
        },
        'showMode': 'gallery',
        'size': 's',
        'uuid': 'c27059e4-95b6-4c13-a1b1-84fffcbe4092',
      },
      {
        'componentType': 'menuSkeleton',
        'uuid': 'b0ea06f0-e983-4318-9b21-d3f6af55aa47',
      },
    ],
    'uuid': '31340ea9-a49e-4267-9910-dda12c973a54',
  },
  {
    'componentType': 'eventInfoSkeleton',
    'uuid': '31340ea9-a49e-4267-9910-dda12c973234',
  },
]
