import css from './Banner.module.scss'
import React from 'react'

import { IBanner } from 'meta/backendControlled/structure'
import { BannerContentType } from 'meta/backendControlled/structure/banner'

import Img from 'components/Media/Img'
import Video from 'components/Media/Video'

import { IPropsComponent } from '../index'
import { getComponentId } from '../utils'

const Banner: React.FC<IPropsComponent<IBanner>> = (props) => {
  const { uuid, mobileUrl, type, url, link } = props

  return (
    <div id={getComponentId(uuid)}>
      {(type === BannerContentType.image || !type) && (
        <a href={link} rel="noreferrer" target="_blank">
          <Img alt="" className={css.bannerMobile} src={`/banner/secondary_banner_home_mobile.png`} />
          <Img alt="" className={css.bannerTabletPortrait} src={`/banner/secondary_banner_home-tablet-portrait.png`} />
          <Img
            alt=""
            className={css.bannerTabletLandscape}
            src={`/banner/secondary_banner_home_tablet-landscape.png`}
          />
          <Img alt="" className={css.bannerLaptop} src={`/banner/secondary_banner_home_laptop.png`} />
          <Img alt="" className={css.bannerDesktop} src={`/banner/secondary_banner_home_desktop.png`} />
        </a>
      )}

      {type === BannerContentType.video && (
        <a href={link}>
          <div>
            <Video addUrlBase={false} className={css.bannerMobile} url={mobileUrl} />
          </div>
          <div>
            <Video addUrlBase={false} className={css.banner} url={url} />
          </div>
        </a>
      )}
    </div>
  )
}

export default Banner
