import React, { useState } from 'react'

import { ComponentSize, ICategory } from 'meta/backendControlled/structure'

import Header from 'components/BackendControlled/Component/Category/Header'
import Single from 'components/BackendControlled/Component/Category/Showcase/Single'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Grid, GridColumn } from 'components/Layout/Grid'

const Category: React.FC<IPropsComponent<ICategory>> = (props) => {
  const { button, data: dataProps, showMode, title, uuid, size } = props
  const [visibleIdx, setVisibleIdx] = useState<Array<number>>([])

  const data = dataProps.filter(Boolean)

  return (
    <Grid id={getComponentId(uuid)}>
      <GridColumn gridColumnEnd={17}>
        {title && <Header button={button} data={data} title={title} visibleIdx={visibleIdx} />}

        <Single data={data} setVisibleIdx={setVisibleIdx} showMode={showMode} size={size as ComponentSize} />
      </GridColumn>
    </Grid>
  )
}

Category.defaultProps = {
  size: ComponentSize.m,
}

export default Category
