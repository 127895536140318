import css from '../TicketCard/TicketCard.module.scss'
import React, { HTMLAttributes } from 'react'

import { TicketCardBaseSkeleton } from 'components/Pages/Wallet/WalletTicketMobile/TicketCard/Base'
import { useUrlCloudfront } from 'hooks/useEnv'

type CardFlipItemProps = HTMLAttributes<HTMLDivElement> & {
  className?: string
}

const TicketCardSkeleton: React.FC = () => {
  const urlCloudfront = useUrlCloudfront()

  const CardFlipItem: React.FC<CardFlipItemProps> = (props) => {
    const { children } = props
    return (
      <div>
        <div
          className={css.walletTicketCardSkeleton}
          style={{ backgroundImage: `url(${urlCloudfront}/images/wallet/ticket_grey.svg)` }}
        >
          {React.Children.toArray(children)}
        </div>
      </div>
    )
  }

  return (
    <div className={css.walletTicketCard__flipCard}>
      <div className={css.walletTicketCard__flipCardInner}>
        <CardFlipItem>
          <TicketCardBaseSkeleton />
        </CardFlipItem>
      </div>
    </div>
  )
}

export default TicketCardSkeleton
