import { ICheckoutSeasonItem } from 'meta/pages/checkout'

export const getSeasonItemsDividedByPage = (arr: ICheckoutSeasonItem[], k: number): ICheckoutSeasonItem[][] => {
  const dividedArray: ICheckoutSeasonItem[][] = []
  let i = 0

  while (i < arr.length) {
    dividedArray.push(arr.slice(i, i + k))
    i += k
  }

  return dividedArray
}

export const getSubsection = (array: number[], K: number, M: number): number[] => {
  const start = Math.max(0, Math.floor(K - M / 2))
  const end = Math.min(array.length - 1, Math.ceil(K + M / 2))
  return array.slice(start, end + 1)
}
