import css from './MenuDesktop.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { useIsInsideProfileRoute } from 'components/Layout/Header/hooks/useIsInsideProfileRoute'

export const MenuDesktopSkeleton: React.FC = () => {
  const insideProfile = useIsInsideProfileRoute()

  return (
    <div className={css.menuDesktop}>
      <Skeleton
        className={css.btnSkeleton}
        baseColor={TICKETSMS_COLORS_WHITE}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        inline
      />
      <Skeleton
        className={css.btnSkeleton}
        baseColor={TICKETSMS_COLORS_WHITE}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        inline
      />
      {!insideProfile && (
        <Skeleton
          className={css.btnSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          highlightColor={TICKETSMS_COLORS_WHITE}
          inline
        />
      )}
      <Skeleton
        className={css.btnSkeleton}
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_WHITE}
        inline
      />
    </div>
  )
}
