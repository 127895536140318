import css from './TicketsProgress.module.scss'
import React, { useLayoutEffect, useRef } from 'react'
import classNames from 'classnames'

import { IWalletUserTicket } from 'meta/pages/wallet'

import { useUrlCloudfront } from 'hooks/useEnv'

type Props = {
  activeCard: number
  setActiveCard: (cardNumber: number) => void
  userTickets: Array<IWalletUserTicket>
}

const TicketsProgress: React.FC<Props> = (props) => {
  const { activeCard, setActiveCard, userTickets } = props

  const urlCloudfront = useUrlCloudfront()
  const progressRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const progress = progressRef.current
    //@ts-ignore
    const child = progress.children[activeCard] as HTMLDivElement
    //@ts-ignore
    progress.scrollTo({
      //@ts-ignore
      left: Math.max(child.offsetLeft - progress.clientWidth / 2, 0),
      behavior: 'smooth',
      top: 0,
    })
  }, [activeCard])

  return (
    <div className={classNames(css.ticketsProgress, { [css.flexStart]: userTickets.length >= 7 })} ref={progressRef}>
      {userTickets.map((userTicket, idx) => (
        <button
          key={userTicket.id}
          className={classNames(css.ticketsProgress__item, { [css.active]: activeCard === idx })}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setActiveCard(idx)
          }}
          style={{
            backgroundImage: `url(${urlCloudfront}/images/wallet/${
              activeCard === idx ? 'ticket_red.svg' : 'ticket_black.svg'
            })`,
          }}
        >
          <h5>{idx + 1}</h5>
        </button>
      ))}
    </div>
  )
}

export default TicketsProgress
