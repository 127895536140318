import css from './AddToCalendarModal.module.scss'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, ActionFormat, ButtonWhite } from 'components/Actions/Action'
import {
  CalendarToSVG,
  CalendarType,
} from 'components/BackendControlled/Component/EventDetails/Infos/Countdown/AddToCalendarModal/AddToCalendarModal.types'
import { useButtonsClickListener } from 'components/BackendControlled/Component/EventDetails/Infos/Countdown/AddToCalendarModal/hooks/useButtonsClickListener'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'

type Props = {
  codeUrl: string
  onClose: () => void
}

const AddToCalendarModal: React.FC<Props> = (props) => {
  const { codeUrl, onClose } = props
  const { t } = useTranslation()

  const calendarList = [CalendarType.APPLE, CalendarType.GOOGLE, CalendarType.OUTLOOK, CalendarType.MICROSOFT365]
  if (!isMobile) {
    calendarList.push(CalendarType.ICAL)
  }

  useButtonsClickListener({ calendarList, codeUrl })

  return (
    <Modal handleClose={onClose} transparent show>
      <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
        <h4 className={css.title}>{t('event:add_to_calendar')}</h4>
        <div className={classNames(css.container, isMobile && css.containerMobile)}>
          {calendarList.map((calendarItem) => (
            <div key={calendarItem}>
              <ButtonWhite
                id={calendarItem}
                className={css.calendar__button}
                format={ActionFormat.fullWidth}
                hover={ActionColor.white}
              >
                <div className={classNames(css.icon__container)}>
                  <img alt={calendarItem} src={CalendarToSVG[calendarItem]} />
                </div>
                <div>{calendarItem}</div>
              </ButtonWhite>
            </div>
          ))}
        </div>
      </BalloonWrapper>
    </Modal>
  )
}

export default AddToCalendarModal
