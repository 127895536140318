import css from './Item.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const ItemSkeleton: React.FC = () => {
  return (
    <Skeleton
      className={css.itemSkeleton}
      baseColor={TICKETSMS_COLORS_WHITE}
      borderRadius={16}
      highlightColor={TICKETSMS_COLORS_LIGHTGREY}
      inline
    />
  )
}
