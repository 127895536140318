import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { FormBuilder, TextBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'
import { INPUT_IBAN_HOLDER_DEFAULT_MAXLENGTH, INPUT_NAME_DEFAULT_MAXLENGTH } from 'utils/constants/form'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Form, { FormSkeleton, IFormValues } from 'components/Forms/Form'
import { useI18NContext } from 'components/HOC/WithTranslation'
import ResaleModalConfirm from 'components/Pages/ProfileResale/ResaleForm/ModalConfirm'
import ResaleText from 'components/Pages/ProfileResale/ResaleForm/ResaleText'
import { useAuthToken } from 'hooks/useAuth'
import { useTicketResaleData } from 'hooks/useProfile'

type Props = {
  onSuccess(): void
}

const ResaleForm: React.FC<Props> = (props) => {
  const { onSuccess } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const authToken = useAuthToken()
  const data = useTicketResaleData()

  const { namespacesLoaded } = useI18NContext()

  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)

  const firstName = new TextBuilder('firstName')
    .addRequiredNameValidation()
    .addValue(data.firstName)
    .addPlaceholder('form:firstName')
    .addMaxLength(INPUT_NAME_DEFAULT_MAXLENGTH)
    .build()
  const lastName = new TextBuilder('lastName')
    .addRequiredNameValidation()
    .addValue(data.lastName)
    .addPlaceholder('form:lastName')
    .addMaxLength(INPUT_NAME_DEFAULT_MAXLENGTH)
    .build()
  const fiscalSeal = new TextBuilder('fiscalSeal')
    .addRequiredFiscalSealValidation()
    .addValue(data.fiscalSeal)
    .addPlaceholder('form:fiscalSeal')
    .build()
  const iban = new TextBuilder('iban').addRequiredIbanValidation().addPlaceholder('form:iban').build()

  const ibanFirstName = new TextBuilder('ibanHolderFirstName')
    .addRequiredNameValidation()
    .addPlaceholder('form:ibanHolderFirstName')
    .addMaxLength(INPUT_IBAN_HOLDER_DEFAULT_MAXLENGTH)
    .build()

  const ibanLastName = new TextBuilder('ibanHolderLastName')
    .addRequiredNameValidation()
    .addPlaceholder('form:ibanHolderLastName')
    .addMaxLength(INPUT_IBAN_HOLDER_DEFAULT_MAXLENGTH)
    .build()

  const formFields = new FormBuilder()
    .addField(firstName)
    .addField(lastName)
    .addField(fiscalSeal)
    .addField(iban)
    .addField(ibanFirstName)
    .addField(ibanLastName)
    .build()

  const onFormSuccess = useCallback(
    (
      values?: IFormValues,
      response?: {
        data: {
          event: { dateEvent: string; name: string }
          recap: { ticket: { amount: number }; resaleFee: { amount: number } }
          ticket: { locationName: string }
        }
      }
    ) => {
      if (!values || !response) return

      const { event, recap, ticket } = response.data
      dispatch(
        ProfileActions.setTicketResaleData({
          data: {
            amount: recap.ticket.amount / 100 - recap.resaleFee.amount / 100,
            eventDate: new Date(event.dateEvent),
            firstName: values.firstName,
            eventLocation: ticket.locationName,
            eventName: event.name,
            fiscalSeal: values.fiscalSeal,
            iban: values.iban,
            lastName: values.lastName,
            ibanHolderFirstName: values.ibanHolderFirstName,
            ibanHolderLastName: values.ibanHolderLastName,
          },
        })
      )
      setShowModalConfirm(true)
    },
    [dispatch]
  )
  const onResaleModalConfirmSuccess = useCallback(() => {
    dispatch(ProfileActions.resetTicketResale())
    onSuccess()
  }, [dispatch, onSuccess])
  const onResaleModalConfirmClose = useCallback(() => setShowModalConfirm(false), [])

  return (
    <>
      <WithAppInitialized
        skeleton={<ProfileTranslationsSkeleton withMultipleDescriptions />}
        showChildren={namespacesLoaded}
      >
        <h3 data-testid={DataTestId.profile.resaleHeader}>{t('profile:resale_header')}</h3>
        <ResaleText />
      </WithAppInitialized>
      <WithAppInitialized skeleton={<FormSkeleton items={6} />}>
        <Form
          action={ApiEndpoint.users.resell()}
          authToken={authToken}
          formFields={formFields}
          labelSubmit="register:submit_label"
          onSuccess={onFormSuccess}
        />
      </WithAppInitialized>

      {showModalConfirm && (
        <ResaleModalConfirm onClose={onResaleModalConfirmClose} onSuccess={onResaleModalConfirmSuccess} />
      )}
    </>
  )
}

export default ResaleForm
