import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { RouteParams, Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CollectionActions } from 'store/pages/collection'

import withTranslation from 'components/HOC/WithTranslation'
import { useIsAppInitialized } from 'hooks/useApp'

import Wrapper from '../Wrapper/Wrapper'

const CollectionIFrame: React.FC = () => {
  const { id } = useParams<RouteParams['Collection']>()

  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()

  useEffect(() => {
    if (appInitialized) {
      id && dispatch(CollectionActions.fetchData({ id }))
    }
  }, [id, dispatch, appInitialized])

  if (!id) {
    return <Navigate to={Routes.Home.generatePath()} />
  }

  return <Wrapper id={id} fromIframe />
}

const namespaces = ['collection', 'event']

export default withTranslation(namespaces)(CollectionIFrame)
