export interface IMap {
  mapId: string
  type: '3D' | '2D'
}

export interface IMapSection {
  orderCodeDescription?: string
  sectionId: string
  sectorUuid?: string
}

export interface IMapSeat {
  clientRect?: DOMRectReadOnly
  seatId: string
  sectionId: string
}

export interface IMapSector {
  color: string
  name: string
  maxPrice: string
}

export interface IMapSectors extends Record<string, IMapSector> {}

export interface IMapSectorsFilter extends Record<string, boolean> {}

export class MapIDs {
  static readonly RANDOM: string = '__RANDOM__'

  static toSeatIDs = (seatId: string): { section: string; row: string; seat: string } => {
    const [section, row, seat] = seatId.split('-')
    return {
      section,
      row,
      seat,
    }
  }
}
