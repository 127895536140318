import css from './Content.module.scss'
import React, { useState } from 'react'

import { IFaqSection } from 'meta/pages/faq'

import Menu from 'components/Pages/FAQ/Menu'
import Sections from 'components/Pages/FAQ/Sections'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IState {
  openItem?: number
  openSection: number
}

interface IProps {
  data: IFaqSection[]
  hideMenu?: boolean
  hideTitle?: boolean
  noMargin?: boolean
  fullWidth?: boolean
  onChange?: (state: IState) => void
}

const Content: React.FC<IProps> = (props) => {
  const { data, hideMenu, hideTitle, noMargin, fullWidth, onChange } = props
  const [state, setState] = useState<IState>({ openItem: undefined, openSection: 0 })
  const { openItem, openSection } = state

  const setActiveItem = (item: number, section: number) => {
    const { openItem } = state

    const stateUpdate = {
      openItem: openItem === item ? undefined : item,
      openSection: section,
    }
    setState(stateUpdate)
  }

  const setActiveSection = (title: string) => {
    const voices = data.map((elem) => elem.title)
    const sectionIndex = voices.findIndex((x) => x === title)

    setState({
      openItem: undefined,
      openSection: sectionIndex,
    })
  }

  useOnUpdate(() => {
    setState({ openItem: undefined, openSection: 0 })
  }, [data])

  useOnUpdate(() => {
    onChange && onChange(state)
  }, [state])

  return (
    <div className={css.faq_content_container}>
      {!hideMenu && <Menu data={data} openSection={openSection} setActiveSectionCallback={setActiveSection} />}

      <Sections
        openItem={openItem}
        openSection={openSection}
        setActiveItemCallback={setActiveItem}
        data={data}
        hideTitle={hideTitle}
        noMargin={noMargin}
        fullWidth={fullWidth}
      />
    </div>
  )
}

export default Content
