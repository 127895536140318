import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, Button } from 'components/Actions/Action'
import RoundedBox from 'components/TextBoxes/RoundedBox'
import { useTicketChangeNameData } from 'hooks/useProfile'

import { IPropsChangeTicketNameStep } from '../types'

const Success: React.FC<IPropsChangeTicketNameStep> = (props) => {
  const { onSuccess } = props

  const { t } = useTranslation()

  const { data } = useTicketChangeNameData()

  return (
    <>
      <RoundedBox bgColor={ComponentColor.white} infoType="success">
        <span data-testid={'profile-change-name-success'}>{t('profile:change_holder_success_msg')}</span>
      </RoundedBox>
      <Button
        //@ts-ignore
        onClick={() => onSuccess(data)}
        backgroundColor={ActionColor.white}
        hover={ActionColor.white}
        borderColor={ActionColor.white}
      >
        {t('profile:change_holder_click_here_msg')}
      </Button>
    </>
  )
}

export default Success
