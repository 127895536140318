import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApiEndpoint } from 'controller/endpoint'
import { CookieClient, CookieName } from 'utils/cookie'
import { getPurchaseFormCookieString, removePurchaseFormCookieString } from 'utils/cookie/client'

import OTPForm from 'components/Auth/OTPV3/Form'
import OTPFormHeader from 'components/Auth/OTPV3/Header'
import { IFormValues } from 'components/Forms/Form'
import ChangeDataForm from 'components/Pages/ProfileChangeData/ChangeDataForm'
import { useUserData } from 'hooks/useAuth'

interface IChangeData {
  otpToken: string
  phone: string
  phonePrefix: string
}

const ProfileChangeData: React.FC = () => {
  const navigate = useNavigate()
  const userData = useUserData()

  const [changeData, setChangeData] = useState<IChangeData>()

  const authToken = CookieClient.get(CookieName.TOKEN)
  const purchaseFormUrl = getPurchaseFormCookieString()

  const onOtpFormSuccess = useCallback(async () => {
    setChangeData(undefined)

    if (purchaseFormUrl) {
      await removePurchaseFormCookieString()
      navigate(purchaseFormUrl, { replace: true })
    } else {
      window && window.location.reload()
    }
  }, [navigate, purchaseFormUrl])

  const onChangeDataFormSuccess = useCallback((data?: IFormValues, json?: any) => {
    if (data) {
      setChangeData({
        phone: data.phone,
        phonePrefix: data.phonePrefix,
        otpToken: json.data.otpToken,
      })
    }
  }, [])

  return (
    <>
      {changeData ? (
        <>
          <OTPFormHeader phone={Number(changeData.phone)} phonePrefix={changeData.phonePrefix} />
          <OTPForm
            action={ApiEndpoint.users.otpConfirm()}
            actionResend={ApiEndpoint.users.otpResend()}
            authToken={authToken}
            onSuccess={onOtpFormSuccess}
            otpToken={changeData.otpToken}
            phone={Number(changeData.phone)}
            phonePrefix={changeData.phonePrefix}
          />
        </>
      ) : (
        <ChangeDataForm authToken={authToken} onSuccess={onChangeDataFormSuccess} userData={userData!} />
      )}
    </>
  )
}

export default ProfileChangeData
