import css from './LoginBox.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

interface IProps {
  setShowLogin(show: boolean): void
}

const LoginBox: React.FC<IProps> = (props) => {
  const { setShowLogin } = props

  const { t } = useTranslation()

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_loginbox_container}>
            <BalloonWrapper
              color={ComponentColor.black}
              size={BalloonSize.small}
              tipPosition={BalloonTipPosition.left}
              removePaddingOutside
            >
              <div className={css.content}>
                <div className={css.text}>{t('checkout:auth_login')}</div>
                <div className={css.button} onClick={() => setShowLogin(true)}>
                  {t('checkout:auth_login_button_label')}
                </div>
              </div>
            </BalloonWrapper>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default LoginBox
