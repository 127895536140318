import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ITicketChangeNameData, ITicketResaleData } from 'meta/pages/profile'
import { initialState } from 'store/pages/profile/state'

import { fetchTransactions } from '../actions/fetchTransactions'

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetTicketChangeName: (state) => {
      state.ticketChangeName = initialState.ticketChangeName
    },
    resetTicketResale: (state) => {
      state.ticketResaleData = initialState.ticketResaleData
    },
    resetTransactions: (state) => {
      state.transactionsData = initialState.transactionsData
    },
    setTicketChangeNameData: (state, action: PayloadAction<{ data: ITicketChangeNameData }>) => {
      const { data: ticketChangeNameData } = action.payload
      state.ticketChangeName.data = ticketChangeNameData
    },
    setTicketChangeNameStep: (state, action: PayloadAction<{ step: number }>) => {
      const { step } = action.payload
      state.ticketChangeName.step = step
    },
    setTicketResaleData: (state, action: PayloadAction<{ data: ITicketResaleData }>) => {
      const { data: ticketResaleData } = action.payload
      return { ...state, ticketResaleData }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.transactionsData.fetching = true
    })
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      const data = action.payload

      return {
        ...state,
        transactionsData: {
          currentPage: data.currentPage,
          fetching: data.fetching,
          hasMorePages: data.hasMorePages,
          transactions: [...state.transactionsData.transactions, ...data.transactions],
        },
      }
    })
  },
})
