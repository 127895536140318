import css from 'components/BackendControlled/Component/common/EventFeesButton/EventFeesButton.module.scss'
import React, { MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store'
import { EventActions } from 'store/pages/event'

import Img from 'components/Media/Img'
import { useCheckoutTypeTicketCollectionId } from 'hooks/useCheckout'

type Props = {
  codeUrl: string
}

const EventFeesButton: React.FC<Props> = (props) => {
  const { codeUrl } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation()
      if (codeUrl) {
        dispatch(EventActions.showEventFees({ codeUrl, typeTicketCollectionId }))
      }
    },
    [codeUrl, typeTicketCollectionId, dispatch]
  )

  return (
    <button className={css.buttonFees} onClick={onClick}>
      {`+ `}
      <div className={css.textFees}>{t('checkout:recap_commissions')}</div>
      <Img src="/icons/info_icon_outline.svg" />
    </button>
  )
}

export default EventFeesButton
