import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IUserData } from 'meta/auth'
import { login } from 'store/auth/actions/login'
import { logout } from 'store/auth/actions/logout'
import { initialState } from 'store/auth/state'

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<{ userData: IUserData }>) => {
      const { userData } = action.payload
      return { ...state, userData }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      const { token } = action.meta.arg
      return { ...state, token, pending: true }
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const { token, userData } = action.payload
      return { ...state, token, userData, pending: false }
    })
    builder.addCase(logout.pending, (state) => {
      return { ...state, pending: true }
    })
    builder.addCase(logout.fulfilled, () => {
      return { ...initialState, pending: false }
    })
  },
})
