import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

export const getBusySeats = async ({
  codeUrl,
  typeTicketCollectionId,
}: {
  codeUrl: string
  typeTicketCollectionId: string | number
}): Promise<Array<string>> => {
  const { data } = await HttpRequest.get<{ seats: Array<string> }>(
    ApiEndpoint.collection.mapSeatsBusy(codeUrl, String(typeTicketCollectionId))
  )
  return data.seats
}
