import css from '../Social.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'
import { addHttpsToUrlIfMissing } from 'utils/string'

import Img from 'components/Media/Img'

interface IPropsButtonHyperlink {
  color: ComponentColor
  link?: string
}

export const ButtonHyperlink: React.FC<IPropsButtonHyperlink> = (props) => {
  const { color, link } = props

  if (!link) return null

  return (
    <a
      href={addHttpsToUrlIfMissing(link)}
      className={classNames(css.socialLink, css[color])}
      target="_blank"
      rel="noreferrer"
    >
      <Img alt="" src={`/social/hyperlink_${color}.svg`} />
    </a>
  )
}
