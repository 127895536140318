import css from './Pt.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

const ResalePT: React.FC = () => {
  return (
    <div className={css.resale_pt_container}>
      <div className={css.static_page_title}>
        <Img src="/resale/resale.svg" />
        <div>Revenda</div>
      </div>
      <div className={css.static_page_text}>
        <p>
          Os ingressos adquiridos através do TicketSms são pessoais, intransferíveis, em particular, os de grandes shows
          com mais de 5000 espectadores, de acordo com{' '}
          <a
            target="_blank"
            href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
            rel="noreferrer"
          >
            Nova legislação
          </a>
          , A obrigação do <b>nome</b> e a verificação de identidade do titular do ingresso após a entrada. Razão pela
          qual no momento da entrada nos eventos em questão será obrigatório um documento de identificação válido para
          que o pessoas encarregadas possam verificar se a pessoa que está portando o ingresso é o titular da conta. No
          caso de incompatibilidade, você não poderá acessar o evento e não terá direito a reembolso. No site, dentro da
          página do evento, será indicado se o evento está sujeito à nomeação e portanto será <b>obrigatório</b>{' '}
          comparecer ao evento com um documento válido para poder entrar.
        </p>

        <p>
          Em caso de impossibilidade de participação em um evento, o procedimento de revenda do bilhete que você possui
          ou dos bilhetes que você comprou (também de um único ingresso no caso de compras múltiplas) acessando a seção
          apropriada do perfil do usuário ou do resumo da transação.
        </p>

        <p>
          <b>Para efetuar a revenda</b> será necessário indicar o Nome, Sobrenome e Documento que constam no seu bilhete
          original, do qual você é o comprador ou titular (estas informações estão indicadas no próprio bilhete). Além
          disso, será necessário indicar um IBAN para o qual será feita uma transferência bancária em caso de revenda.
        </p>

        <p>
          Assim que os dados e o direito de prosseguir forem verificados, um resumo será exibido da oferta e uma
          confirmação final será solicitada após a qual o bilhete será colocado de volta venda pelo mesmo preço do
          bilhete original dentro do próprio TicketSms.Quando um novo comprador vai querer comprar um bilhete do mesmo
          tipo que um bilhete reformado a venda terá prioridade sobre os ingressos normais.
        </p>

        <p>
          Se a revenda for bem-sucedida, o vendedor receberá, no prazo de 15 dias úteis, uma transferência igual ao
          valor do bilhete único revendido líquido de comissões e custo de serviço, que chega a € 1,50. Nenhuma comissão
          ou taxa de inserção é esperada, a comissão é aplicada apenas para revenda concluída.
        </p>

        <p>
          A função de revenda segue o <b>Mesmas regras de horário da venda clássica até o início do evento</b>, então
          será disponível durante todo o período de venda dos bilhetes normais até à abertura dos portões do evento,
          quando não será mais possível revender seus ingressos ou comprar bilhetes reemitidos por outros usuários.
        </p>

        <p>
          Se você deseja cancelar a revenda do seu ingresso, basta fazer o login para a seção apropriada do menu relista
          do seu perfil, onde será necessário reinsira os dados relativos ao tíquete (nome e selo fiscal). Lá, no
          entanto, o cancelamento só será possível enquanto o bilhete em questão não for adicionado ao carrinho de um
          novo comprador.
        </p>

        <p>
          Observe que o título de acesso original <b>não pode ser usado</b> enquanto permanecer no mercado de revenda,
          portanto, mesmo em caso de não revenda dentro dos prazos, para poder utilizar seu ingresso para acessar o
          evento será necessário implementar o procedimento de <b>cancelamento de revenda</b>.
        </p>

        <p>
          Por fim, observe que <b>não é possível revender os ingressos comprados com o 18APP</b>.
        </p>
      </div>
    </div>
  )
}

export default ResalePT
