import css from './B2c.module.scss'
import React from 'react'
import { isAndroid } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { ActionSize, LinkWhiteBlack } from 'components/Actions/Action'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlAppAndroid, useUrlAppIos, useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  uuid: string
}

const B2c: React.FC<IProps> = (props) => {
  const { uuid } = props

  const { t } = useTranslation(['infobox'])
  const appAndroid = useUrlAppAndroid()
  const appIos = useUrlAppIos()
  const deviceLink = isAndroid ? appAndroid : appIos

  const urlCloudfront = useUrlCloudfront()

  return (
    <Grid className={css.infoBoxContainer} id={getComponentId(uuid)}>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 2 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 11 }}
        className={css.infoBoxContainer__column}
      >
        <div className={css.infoBoxContent}>
          <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
            <ImgSkeleton
              alt={``}
              src={`${urlCloudfront}/images/banner/b2c-transparent-bg-comp.gif`}
              className={css.infoBoxPlaceholderImg}
            />
          </MediaQuery>

          <h2>{t(`infobox:b2c-header`)}</h2>

          <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
            <h4 className={css.description}>
              {t(`infobox:b2c-description-first`)}
              <br />
              {t(`infobox:b2c-description-second`)}
            </h4>
          </MediaQuery>

          <LinkWhiteBlack title={t(`infobox:b2c-appdownload`)} href={deviceLink} size={ActionSize.s}>
            <strong>{t(`infobox:b2c-appdownload`)}</strong>
          </LinkWhiteBlack>
        </div>
      </GridColumn>

      <GridColumn className={css.infoBoxPlaceholder} gridColumnStart={11} gridColumnEnd={16}>
        <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
          <div className={css.apps}>
            <Img alt="Google play store" className={css.google} src="/apps/google_play_store.png" />
            <Img alt="App store" className={css.apple} src="/apps/app_store.svg" />
          </div>
          <ImgSkeleton
            alt={``}
            src={`${urlCloudfront}/images/banner/b2c-transparent-bg-comp.gif`}
            className={css.infoBoxPlaceholderImg}
          />
          <Img className={css.scaricaApp} src="/infobox/scarica_app.png" />
        </MediaQuery>
      </GridColumn>
    </Grid>
  )
}

export default B2c
