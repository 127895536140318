import css from './Voice.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const VoiceSkeleton: React.FC = () => {
  return (
    <div className={`${css.pagewithsubsections_voices_voice_container__skeleton} `}>
      <div className={css.voice__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
    </div>
  )
}
