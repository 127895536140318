import css from './BannerVideo.module.scss'
import React from 'react'

import { BannerProps } from 'components/BackendControlled/Banner/props'
import Video from 'components/Media/Video'

const BannerVideo: React.FC<BannerProps> = (props) => {
  const { banner } = props

  return (
    <div className={css.banner_video}>
      <Video url={banner.imageUrl} />
    </div>
  )
}

export default BannerVideo
