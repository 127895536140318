import React from 'react'

import { HeaderSkeleton } from 'components/Auth/Profile/Header'
import { MenuSkeleton } from 'components/Auth/Profile/Menu'

export const ProfileSkeleton: React.FC = () => {
  return (
    <>
      <HeaderSkeleton />
      <MenuSkeleton />
    </>
  )
}
