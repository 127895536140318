import { ITicketExtraField } from '../tickets'

export enum TicketChangeNameStep {
  oldTicketData,
  newHolderData,
  success,
}

export interface ITicketChangeNameData {
  currentHolder: {
    firstName: string
    lastName: string
  }
  fiscalSeal: string
  additionalInfos?: Array<ITicketExtraField>
  maxTerm?: Date
  isPersonalDataRequired?: boolean
}

export interface ITicketChangeName {
  data?: ITicketChangeNameData
  step: TicketChangeNameStep
}
