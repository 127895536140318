import cssFieldError from '../FormFieldError/FormFieldError.module.scss'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Field } from 'formik'

import { useUrlCloudfront } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

import { IFormFieldProps } from '../props'
import useFormField from '../useFormField'

const Hidden: React.FC<IFormFieldProps> = (props) => {
  const { name } = props.formField

  const urlCloudfront = useUrlCloudfront()
  const { error } = useFormField(name)

  useOnUpdate(() => {
    const errorField = document.getElementById(`${name}-error`)
    if (errorField) {
      errorField.innerHTML = error
        ? ReactDOMServer.renderToStaticMarkup(
            <div data-testid={`${name}-error`} className={cssFieldError.formFieldError}>
              <img alt="" src={`${urlCloudfront}/images/form/error.svg`} />
              <div>{error}</div>
            </div>
          )
        : ''
    }
  }, [error])

  return <Field data-testid={name} id={name} name={name} type="hidden" />
}

export default Hidden
