import css from './TransactionError.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckoutStep } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import RoundedBox from 'components/TextBoxes/RoundedBox'

const Timeout: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <div className={css.checkout_steps_transactionerror_container}>
      <div className={css.transaction_error_body}>
        <div className={css.title}>{t('checkout:timeout_error')}</div>

        <RoundedBox infoType="error">
          <div>{String(t('checkout:timeout_error_message'))}</div>
        </RoundedBox>

        <div
          className={css.go_back}
          onClick={() => {
            dispatch(CheckoutActions.resetCart())
            dispatch(CheckoutActions.resetSeat())
            dispatch(CheckoutActions.updateStep({ step: CheckoutStep.select }))
          }}
        >
          {t('checkout:timeout_error_goback')}
        </div>
      </div>
    </div>
  )
}

export default Timeout
