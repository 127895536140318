export enum LoginMethod {
  email = 'email',
  fb = 'fb',
  none = 'none',
  phone = 'phone',
}

export enum LoginImgSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum LoginStep {
  none,
  email,
  phone,
  otp,
  who,
  whenWhere,
  almost,
  done,
  emailConfirm,
}

export enum AuthRequired {
  yes = 'yes',
  no = 'no',
  sometimes = 'sometimes',
}

export const registerSteps: Array<LoginStep> = [LoginStep.who, LoginStep.whenWhere, LoginStep.almost, LoginStep.done]

export enum LoginProvider {
  TICKETSMS = 'TICKETSMS',
  OTP = 'OTP',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE_IDENTITY',
  APPLE = 'APPLE',
}

export type LoginData = {
  apple?: {
    email: string
    firstName: string
    lastName: string
    token: string
  }
  existingEmail?: string
  facebook?: {
    accessToken: string
    accessTokenExpiration: number
    email: string
    id: string
  }
  google?: {
    code: string
    email: string
    firstName: string
    lastName: string
  }
  birthDate: string
  birthPlace: string
  email: string
  firstName: string
  lastName: string
  otpToken: string
  otp?: string
  phone: string
  phonePrefix: string
  termsAndConditions: boolean
  privacyPolicy: boolean
  marketingPolicy: boolean
  profilingPolicy: boolean
  hasUser: boolean
  provider?: LoginProvider
}

export interface ILoginData {
  apple?: {
    email: string
    firstName: string
    lastName: string
    token: string
  }
  existingEmail?: string
  facebook?: {
    accessToken: string
    accessTokenExpiration: number
    email: string
    id: string
  }
  google?: {
    code: string
    email: string
    firstName: string
    lastName: string
  }
  birthDate: string
  birthPlace: string
  email: string
  firstName: string
  lastName: string
  otpToken: string
  otp?: string
  phone: string
  phonePrefix: string
  termsAndConditions: boolean
  hasUser: boolean
  provider?: LoginProvider
}

export interface IGoogleUserData {
  email: string
  firstName: string
  lastName: string
}

export type Login = {
  data: LoginData
  pathname?: string
  provider?: LoginProvider
  step: LoginStep
  show: boolean
}
