import { ComponentType, IComponent } from './component'

export enum InfoBoxType {
  b2b = 'b2b',
  b2c = 'b2c',
}

export interface IInfoBox extends IComponent<ComponentType.asset> {
  type: InfoBoxType
}
