import css from './Balloon.module.scss'
import React from 'react'

import { ComponentType, IBalloon } from 'meta/backendControlled/structure'
import { doesListContainsGallery } from 'utils/array'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import ComponentList from 'components/BackendControlled/ComponentList'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { GridContainer } from 'components/Layout/Grid'

const Balloon: React.FC<IPropsComponent<IBalloon>> = (props) => {
  const { backgroundUrl, color, insideVerticalScroll, list, size, tipPosition } = props
  const containsShowcase = list && list.some((x) => x.componentType === ComponentType.horizontalScroll)
  const containsGallery = doesListContainsGallery(list)
  const containsMenu = Boolean(list && list.find((x) => x.componentType === ComponentType.menu))

  return (
    <GridContainer className={css.balloonGridContainer}>
      <BalloonWrapper
        backgroundUrl={backgroundUrl}
        color={color}
        containsMenu={containsMenu}
        containsShowcase={containsShowcase}
        containsGallery={containsGallery}
        insideVerticalScroll={insideVerticalScroll}
        size={size}
        tipPosition={tipPosition}
      >
        <ComponentList components={list} />
      </BalloonWrapper>
    </GridContainer>
  )
}

export default Balloon
