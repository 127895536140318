import css from './Desktop.module.scss'
import React from 'react'

import { CoverSkeleton } from 'components/Checkout/EventDetails/Cover'
import { DescriptionSkeleton } from 'components/Checkout/EventDetails/Description'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const DesktopSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid>
        <GridColumn gridColumnStart={2} gridColumnEnd={17}>
          <div className={css.checkout_eventdetails_desktop_container}>
            <div className={css.details}>
              <CoverSkeleton />
              <DescriptionSkeleton />
            </div>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}
