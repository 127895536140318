import css from './FormError.module.scss'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { LONG_ERROR_MESSAGE_SIZE } from 'utils/constants/form'
import { DOMs } from 'utils/doms'

import Img from 'components/Media/Img'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IPropsFormError {
  error?: Response | Error | string
}

const FormError: React.FC<IPropsFormError> = (props) => {
  const { error } = props
  const { t } = useTranslation()

  const errorRef = useRef<HTMLDivElement>(null)

  const isErrorMsgLong = Boolean(error) && String(error).length > LONG_ERROR_MESSAGE_SIZE

  useOnUpdate(() => {
    if (error) {
      //@ts-ignore
      const { top } = errorRef.current.getBoundingClientRect()
      if (top < 0) {
        //@ts-ignore
        const offset = DOMs.getCumulativeOffset(errorRef.current)

        document.documentElement.scrollTo({
          top: offset.top - 32,
          behavior: 'smooth',
        })
      }
    }
  }, [error])

  return (
    <div
      className={classNames(css.formError, { [css.show]: Boolean(error), [css.longError]: isErrorMsgLong })}
      ref={errorRef}
    >
      <Img alt="" src="/form/error.svg" />
      <div className={css.formError__message}>
        <strong>{`${t('form:error')} `}</strong>
        {error && error?.toString()}
      </div>
    </div>
  )
}

export default FormError
