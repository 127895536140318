import { CheckoutStep, ICheckoutDetails, ICheckoutPayment } from 'meta/pages/checkout'
import { setTransactionErrorMessage } from 'store/pages/checkout/actions/setTransactionErrorMessage'
import { startTransaction } from 'store/pages/checkout/actions/startTransaction'
import { CheckoutState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

export const setTransactionStarting: SliceUpdater<CheckoutState, ReturnType<typeof startTransaction.pending>> = (
  state
) => {
  return {
    ...state,
    details: {
      ...state.details,
      transactionStarted: true,
    } as ICheckoutDetails,
  }
}

export const setTransactionStarted: SliceUpdater<CheckoutState, ReturnType<typeof startTransaction.fulfilled>> = (
  state,
  action
) => {
  if (action.payload) {
    const { transactionCode, paymentGatewayData, paymentGateway } = action.payload
    return {
      ...state,
      details: {
        ...state.details,
        transactionCode,
      } as ICheckoutDetails,
      payment: {
        ...state.payment,
        gateway: paymentGateway,
        gatewayData: paymentGatewayData,
      } as ICheckoutPayment,
    }
  }
  return state
}

export const setTransactionError: SliceUpdater<
  CheckoutState,
  ReturnType<typeof setTransactionErrorMessage> | ReturnType<typeof startTransaction.rejected>
> = (state, action) => {
  const plainAction = action as ReturnType<typeof setTransactionErrorMessage>
  const thunkAction = action as ReturnType<typeof startTransaction.rejected>
  const errorMessage = plainAction.payload?.errorMessage
    ? plainAction.payload.errorMessage
    : `${thunkAction.error.message}${thunkAction.error.code ? thunkAction.error.code : ''}`

  return {
    ...state,
    details: {
      ...state.details,
      transactionErrorMessage: errorMessage,
      transactionStarted: false,
    } as ICheckoutDetails,
    step: CheckoutStep.transactionError,
  }
}
