import css from './Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionLocation, CollectionTag } from 'meta/pages/collection'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

interface Props {
  title: string
  tags: CollectionTag[]
  location: CollectionLocation
}

const Header: React.FC<Props> = (props) => {
  const { title, tags, location } = props

  const { t } = useTranslation()

  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 12 }}>
      <div className={css.headerContainer}>
        <p className={css.location}>
          <span className={css.marker}>
            <Img src="/icons/location_marker_transparent_white.svg" />
          </span>
          <span
            className={css.address}
          >{`${t('collection:collection_of')}${location.city} (${location.province})`}</span>
        </p>
        <h1 className={css.title}>{title}</h1>
        <div className={css.tags}>
          {tags.map((elem, key) => (
            <span key={key} className={css.tag}>
              {elem.tag}
            </span>
          ))}
        </div>
      </div>
    </GridColumn>
  )
}

export default Header
