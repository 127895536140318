import { createAsyncThunk } from '@reduxjs/toolkit'

import { CheckoutController } from 'controller/checkout'
import { RootState } from 'store/types'

type Params = {
  paymentGateway: string
  paymentGatewayData: Record<string, string>
  transactionCode: string
}

export const cancelProcess = createAsyncThunk<void, Params, { state: RootState }>(
  'checkout/process/cancel',
  CheckoutController.cancel
)
