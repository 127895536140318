import React, { memo, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'

// https://github.com/staylor/react-helmet-async

const Head: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  return <Helmet prioritizeSeoTags>{React.Children.toArray(children)}</Helmet>
}

export default memo(Head)
