import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import Desktop from './Desktop'
import Mobile from './Mobile'

const ItemsSkeleton: React.FC = () => {
  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
        <Mobile />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
        <Desktop />
      </MediaQuery>
    </>
  )
}

export default ItemsSkeleton
