import css from './Captions.module.scss'
import React from 'react'
import classNames from 'classnames'

import { IEventAttribute } from 'meta/pages/event'
import { chunkArrayInGroups } from 'utils/array'

export enum AlignCaption {
  left = 'left',
  right = 'right',
}

interface IProps {
  data: IEventAttribute[]
  itemsPerRow: number
  align: AlignCaption
}

const Captions: React.FC<IProps> = (props) => {
  const { data, itemsPerRow, align } = props

  const chunkedData = chunkArrayInGroups(data, itemsPerRow)

  return (
    <div className={css.event_body_caption_data_container}>
      {chunkedData.map((row: IEventAttribute[], rKey) => {
        return (
          <div key={rKey} className={classNames(css.row, css[align], css[`row_${itemsPerRow}`])}>
            {row.map((elem, key) => {
              return (
                <div key={key} className={css.caption_data}>
                  <div className={css.icon}>
                    <img src={elem.asset.url} />
                  </div>
                  <div className={css.description}>{elem.description}</div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Captions
