import { ComponentType, IEventTicket, ITicket } from '../../structure'
import { IEventTicketBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class EventTicketAdapter extends ComponentAdapter<IEventTicket, IEventTicketBackend> {
  convert(): IEventTicket {
    const { eventId, list, map, title } = this.componentBackend

    return {
      componentType: ComponentType.eventTicket,
      data: (this.componentsAdapter.convertComponents(list) as Array<ITicket>).reverse(),
      eventId,
      map,
      title,
    }
  }
}
