import css from './Description.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { useCheckoutTypeTicketCollectionId } from 'hooks/useCheckout'

export const DescriptionSkeleton: React.FC = () => {
  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()

  return (
    <div className={css.checkout_eventdetails_description_container}>
      <div className={css.daytimeSkeleton__container}>
        <Skeleton
          className={css.daytimeSkeleton}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
        />
        {!typeTicketCollectionId && <div>/</div>}
        {!typeTicketCollectionId && (
          <Skeleton
            className={css.daytimeSkeleton}
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        )}
      </div>

      <Skeleton
        className={css.nameSkeleton}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={8}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
      />

      <Skeleton
        className={css.locationSkeleton}
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={8}
        highlightColor={TICKETSMS_COLORS_WHITE}
      />

      <div className={css.roundBox} />
    </div>
  )
}
