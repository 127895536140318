import { joinParts } from './utils'

export const event = {
  categories: () => joinParts('events', 'categories'),
  components: (codeUrl: string) => joinParts('events', codeUrl),
  fees: (codeUrl: string) => joinParts('events', codeUrl, 'fees'),
  metadata: (codeUrl: string) => joinParts('events', codeUrl, 'meta'),
  mapSectionsEnabled: (eventId: string | number) => joinParts('events', String(eventId), 'map', 'sections', 'enabled'),
  mapSeatsBusy: (eventId: string | number) => joinParts('events', String(eventId), 'map', 'sections', 'seats', 'busy'),
  mapSeatTypeTickets: (eventId: number, codeUrl: string, sectionId: string, seatId: string) =>
    joinParts('events', String(eventId), 'map', 'sections', sectionId, 'seats', seatId, 'typeTickets', codeUrl),
  mapSeatSelect: (eventId: number, sectionId: string, seatId: string) =>
    joinParts('events', String(eventId), 'map', 'sections', sectionId, 'seats', seatId, 'select'),
  mapSeatDeselect: (eventId: number, sectionId: string, seatId: string) =>
    joinParts('events', String(eventId), 'map', 'sections', sectionId, 'seats', seatId, 'deselect'),
  mapSeatsDeselectAll: (eventId: number, sessionUuid: string) =>
    joinParts('events', String(eventId), 'map', 'sections', 'seats', 'deselectAllByUuid', sessionUuid),
}
