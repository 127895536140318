import { createAsyncThunk } from '@reduxjs/toolkit'

import { throttleFindNext } from 'store/pages/search/actions/throttleFindNext'
import { SearchSelectors } from 'store/pages/search/selectors'
import { AppDispatch, RootState } from 'store/types'

export const findNext = createAsyncThunk<boolean, void, { dispatch: AppDispatch; state: RootState }>(
  'search/result/findNext',
  async (_, { dispatch, getState }) => {
    const state = getState()
    const fetching = SearchSelectors.isFetching()(state)
    const params = SearchSelectors.getParams()(state)
    const result = SearchSelectors.getResult()(state)

    if (!fetching && result?.hasMorePages) {
      throttleFindNext({ dispatch, ...params })
      return true
    }

    return false
  }
)
