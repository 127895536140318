import css from './Footer.module.scss'
import React from 'react'
import classNames from 'classnames'

import Apps from './Apps'
import { Breakpoints } from 'meta/layout/breakpoints'

import WithAppInitialized from 'components/common/WithAppInitialized'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Logo from 'components/Layout/LogoV2'

import Company from './Company'
import Copyright from './Copyright'
import CurrencySelector, { CurrencySelectorSkeleton } from './CurrencySelector'
import Help from './Help'
import LanguageSelector, { LanguageSelectorSkeleton } from './LanguageSelector'
import Payments from './Payments'
import Social from './Social'

const RowSeparator: React.FC<{ noTablet?: boolean }> = ({ noTablet }) => (
  <GridColumn
    gridColumnStart={1}
    gridColumnEnd={17}
    className={classNames(css.footer__rowSeparator, { [css.noTablet]: Boolean(noTablet) })}
  />
)

const Footer: React.FC = () => {
  return (
    <footer className={css.footer}>
      <GridContainer>
        <Grid className={css.footer_grid}>
          <RowSeparator />
          <GridColumn
            gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1, [Breakpoints.laptop]: 1 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 8, [Breakpoints.tablet]: 5, [Breakpoints.laptop]: 4 }}
            className={css.footer_logo}
          >
            <Logo linkToHome={false} noTextMobile={false} />
          </GridColumn>

          <WithAppInitialized skeleton={<CurrencySelectorSkeleton />}>
            <CurrencySelector />
          </WithAppInitialized>
          <WithAppInitialized skeleton={<LanguageSelectorSkeleton />}>
            <LanguageSelector />
          </WithAppInitialized>

          <RowSeparator noTablet={true} />
          <Apps />

          <RowSeparator />

          <Help />

          <Company />

          <RowSeparator />
          <Payments />

          <RowSeparator noTablet={true} />
          <Social />

          <RowSeparator noTablet={true} />
          <Copyright />
        </Grid>
      </GridContainer>
    </footer>
  )
}

export default Footer
