export const PURCHASE_FORM_DIVIDER_TOKEN = '_'
export const APPOINTMENTS_DIVIDER_TOKEN = '@'
export const APPOINTMENTS_FIELD_IDENTIFIER = 'appointment'
export const LONG_ERROR_MESSAGE_SIZE = 200
export const DATALIST_SEARCH_MINIMUM_LENGTH = 2
export const DATALIST_COUNTRY_DEFAULT_VALUE = 'XXX'
export const DATALIST_CITY_DEFAULT_VALUE = 'XXXX'

export const DATALIST_DEFAULT_VALUES = [DATALIST_COUNTRY_DEFAULT_VALUE, DATALIST_CITY_DEFAULT_VALUE]

export const INPUT_NAME_DEFAULT_MAXLENGTH = 25
export const INPUT_IBAN_HOLDER_DEFAULT_MAXLENGTH = 50
