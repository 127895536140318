import css from './ButtonsHeader.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'
import { LoginStep } from 'meta/pages/login'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useLoginStep } from 'hooks/useLogin'

type Props = {
  fromModal?: boolean
  onClose(): void
}

const ButtonsHeader: React.FC<Props> = (props) => {
  const { fromModal, onClose } = props

  const dispatch = useAppDispatch()
  const step = useLoginStep()

  const stepsGoToNone: Array<LoginStep> = [LoginStep.email, LoginStep.phone, LoginStep.otp]
  const stepsBackAllowed: Array<LoginStep> = [
    LoginStep.email,
    LoginStep.phone,
    LoginStep.otp,
    LoginStep.whenWhere,
    LoginStep.almost,
  ]

  const showBack = stepsBackAllowed.includes(step)

  const onBack = () => {
    const stepBack: LoginStep = stepsGoToNone.includes(step) ? LoginStep.none : step - 1
    dispatch(LoginActions.updateStep({ step: stepBack }))
  }

  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 11 }} className={css.buttonsHeader}>
      {showBack ? (
        <button className={css.buttonsHeader__back} onClick={onBack}>
          <Img alt="" src="/icons/arrow_left_white.svg" />
        </button>
      ) : (
        <div />
      )}

      {!fromModal && (
        <button
          className={css.buttonsHeader__close}
          onClick={() => {
            dispatch(LoginActions.reset())
            onClose()
          }}
        >
          <Img alt="" src="/icons/cross_white.svg" />
        </button>
      )}
    </GridColumn>
  )
}

export default ButtonsHeader
