import css from './SeatSelector.module.scss'
import React, { MutableRefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IMapSeat, IMapSection, IMapSectorsFilter, Map2DAction, Map2DLevel, MapIDs, MapViewerMediator } from 'meta/map'

import { ButtonRed, ButtonWhite, ButtonWhiteBlack } from 'components/Actions/Action'
import SectorsLegend from 'components/Checkout/TicketSelection/TicketSeatSelector/SeatSelector/SectorsLegend'
import Img from 'components/Media/Img'
import { useEventSeats, useSectionLabel } from 'hooks/useEvent'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IProps {
  container2D: MutableRefObject<HTMLDivElement>
  container3D: MutableRefObject<HTMLDivElement>
  id: string
  layerLevel: Map2DLevel
  mapViewerMediator?: MapViewerMediator
  modalRef?: MutableRefObject<HTMLElement>
  seat3d?: IMapSeat
  section?: IMapSection
}

const SeatSelector: React.FC<IProps> = (props) => {
  const { container2D, container3D, id, layerLevel, mapViewerMediator, modalRef, seat3d, section } = props

  const { t } = useTranslation()
  const { sectors } = useEventSeats(id)
  const sectionLabel = useSectionLabel(id, section ? section.sectionId : '')

  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const [showSectorsLegend, setShowSectorsLegend] = useState<boolean>(false)
  const [sectorsFilter, setSectorsFilter] = useState<IMapSectorsFilter>({})
  const seatSelectorContainerRef = useRef<HTMLDivElement>(null)
  const seat3DActionsMobileRef = useRef<HTMLDivElement>(null)
  const modal = modalRef && modalRef.current

  const {
    section: sectionId,
    seat,
    row,
  } = seat3d ? MapIDs.toSeatIDs(seat3d.seatId) : { section: null, seat: null, row: null }
  const section3DLabel = useSectionLabel(id, sectionId)
  const showLegendButton = sectors && !seat3d

  const toggleFullScreen = () =>
    setFullScreen((prevState: boolean) => {
      const containerScroll = modal || document.body
      containerScroll.style.overflowY = prevState ? 'auto' : 'hidden'
      if (modal && seatSelectorContainerRef.current) {
        seatSelectorContainerRef.current.style.top = prevState ? `unset` : `${modal.scrollTop}px`
      }
      return !prevState
    })

  const moveUpRef = useRef<HTMLButtonElement>(null)
  const moveDownRef = useRef<HTMLButtonElement>(null)
  const moveLeftRef = useRef<HTMLButtonElement>(null)
  const moveRightRef = useRef<HTMLButtonElement>(null)
  const zoomResetRef = useRef<HTMLButtonElement>(null)
  const zoomIntRef = useRef<HTMLButtonElement>(null)
  const zoomOutRef = useRef<HTMLButtonElement>(null)

  useOnUpdate(() => {
    if (mapViewerMediator) {
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.MOVE_DOWN, moveDownRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.MOVE_LEFT, moveLeftRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.MOVE_RIGHT, moveRightRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.MOVE_UP, moveUpRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.ZOOM_RESET, zoomResetRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.ZOOM_IN, zoomIntRef.current as HTMLElement)
      mapViewerMediator.map2DViewer.bindAction(Map2DAction.ZOOM_OUT, zoomOutRef.current as HTMLElement)
    }
  }, [mapViewerMediator])

  useOnUpdate(() => {
    // trick to let safari iOS resize the map view
    if (fullScreen && mapViewerMediator) {
      const map = seat3d ? mapViewerMediator.map3DViewer : mapViewerMediator.map2DViewer
      map.hide()
      new Promise((resolve) => setTimeout(resolve, 1)).then(() => map.show())
    }

    if (fullScreen && seat3d && modal && seat3DActionsMobileRef.current) {
      seat3DActionsMobileRef.current.style.bottom = `${28 - modal.scrollTop}px`
    }
  }, [fullScreen, seat3d, mapViewerMediator])

  useOnUpdate(() => {
    if (layerLevel >= Map2DLevel.SECTION) setShowSectorsLegend(false)
  }, [layerLevel])

  useOnUpdate(() => {
    mapViewerMediator?.map2DViewer.filterSectors(sectorsFilter)
  }, [sectorsFilter])

  return (
    <>
      <div
        className={classNames(css.seatSelectorContainer, { [css.fullScreen]: fullScreen })}
        ref={seatSelectorContainerRef}
      >
        {layerLevel >= Map2DLevel.SECTION && (
          <button
            className={css.mapBackButton}
            onClick={() =>
              seat3d ? mapViewerMediator?.show2DViewer() : mapViewerMediator?.map2DViewer?.setLevel(Map2DLevel.SEAT)
            }
          >
            <Img src="/checkout/map_arrow_left_white.svg" />
            <strong className={css.text}>{t('checkout:go_back')}</strong>
          </button>
        )}

        {showLegendButton && (
          <>
            <button
              className={classNames(css.mapSectorsButton, { [css.active]: showSectorsLegend })}
              onClick={() => setShowSectorsLegend((prevState) => !prevState)}
            >
              <Img src="/checkout/map_sectors.svg" />
            </button>
            {showSectorsLegend && (
              <SectorsLegend sectors={sectors} sectorsFilter={sectorsFilter} setSectorsFilter={setSectorsFilter} />
            )}
          </>
        )}

        <button
          className={classNames(css.mapFullScreenButton, { [css.withLegend]: showLegendButton })}
          onClick={toggleFullScreen}
        >
          <Img src="/checkout/map_toggle_fullscreen.svg" />
        </button>

        {layerLevel === Map2DLevel.SECTION && !seat3d && sectionLabel && !showLegendButton && (
          <div className={css.seatSelector__section}>
            <Img src="/checkout/map_section_label.svg" />
            <strong className={css.label}>
              <div>{sectionLabel}</div>
            </strong>
          </div>
        )}

        <div className={css.mapContainer} ref={container2D}>
          <div className={css.mapControlsContainer}>
            <Img src="/checkout/map_controls_bg.svg" />
            <div className={css.mapControls}>
              <button className={css.mapControlButton} ref={zoomOutRef}>
                <Img src="/checkout/map_zoom_out.svg" />
              </button>
              <button className={css.mapControlButton} ref={zoomResetRef}>
                <Img src="/checkout/map_zoom_reset.svg" />
              </button>
              <button className={css.mapControlButton} ref={zoomIntRef}>
                <Img src="/checkout/map_zoom_in.svg" />
              </button>
            </div>
          </div>

          <button className={classNames(css.mapNavUp, css.mapControlButton)} ref={moveUpRef}>
            <Img src="/checkout/map_nav_down.svg" />
          </button>
          <button className={classNames(css.mapNavRight, css.mapControlButton)} ref={moveRightRef}>
            <Img src="/checkout/map_nav_down.svg" />
          </button>
          <button className={classNames(css.mapNavDown, css.mapControlButton)} ref={moveDownRef}>
            <Img src="/checkout/map_nav_down.svg" />
          </button>
          <button className={classNames(css.mapNavLeft, css.mapControlButton)} ref={moveLeftRef}>
            <Img src="/checkout/map_nav_down.svg" />
          </button>
        </div>

        <div className={css.mapContainer} ref={container3D} style={{ display: 'none' }}>
          <div className={css.map3dControls}>
            <div className={css.map3dControls__ticket}>
              <div className={css.section}>
                <Img className={css.mapLabel} src="/checkout/map_section_label.svg" />
                <div className={css.label}>
                  <div>{section3DLabel}</div>
                </div>
              </div>
              <Img src="/checkout/map_ticket_vertical.svg" />
              <div className={css.map3dControls__ticketText}>{seat3d && t('checkout:row_and_seat', { row, seat })}</div>
            </div>

            <ButtonWhite className={css.btnCancel} onClick={() => mapViewerMediator?.show2DViewer()}>
              <h5>{t('checkout:code_undo_button')}</h5>
            </ButtonWhite>

            <ButtonRed
              className={css.btnContinue}
              onClick={() => {
                mapViewerMediator?.map2DViewer?.selectSeat(seat3d!.seatId)
              }}
            >
              <h5>{t('checkout:code_ok_button')}</h5>
            </ButtonRed>
          </div>
        </div>
      </div>

      {seat3d && (
        <div
          className={classNames(css.map3dActionsMobile, { [css.fullScreen]: fullScreen })}
          ref={seat3DActionsMobileRef}
        >
          <ButtonWhiteBlack className={css.btnCancel} onClick={() => mapViewerMediator?.show2DViewer()}>
            <h5>{t('checkout:code_undo_button')}</h5>
          </ButtonWhiteBlack>

          <ButtonRed
            className={css.btnContinue}
            onClick={() => {
              mapViewerMediator?.map2DViewer?.selectSeat(String(seat3d?.seatId))
            }}
          >
            <h5>{t('checkout:code_ok_button')}</h5>
          </ButtonRed>
        </div>
      )}
    </>
  )
}

export default SeatSelector
