import React from 'react'

import Loader from 'components/Layout/Loader'
import { useHandleProcessComplete } from 'components/Pages/CheckoutProcess/hooks/useHandleProcessComplete'
import { useHandleProcessError } from 'components/Pages/CheckoutProcess/hooks/useHandleProcessError'
import { useStartProcess } from 'components/Pages/CheckoutProcess/hooks/useStartProcess'

const CheckoutProcess: React.FC = () => {
  useStartProcess()
  useHandleProcessComplete()
  useHandleProcessError()

  return <Loader />
}

export default CheckoutProcess
