import { Cashify } from 'cashify'

import { CurrencyCode, CurrencySymbol } from 'meta/currency'
import { swapObjectKeysAndValues } from 'utils/array'

// TODO: aggiornare tassi di conversione
const rates = {
  EUR: 1.0,
  USD: 1.14,
  HRK: 7.5,
  CHF: 1.0,
  DOP: 61.59,
  GBP: 0.88,
  NONE: 1.0,
}

export const getSymbolFromCode = (code: CurrencyCode) => {
  return CurrencySymbol[code]
}

export const getCodeFromSymbol = (symbol: CurrencySymbol) => {
  // @ts-ignore
  return swapObjectKeysAndValues(CurrencySymbol)[symbol]
}

/**
 * @deprecated
 */
export const convertCurrency = (value: number, from: CurrencyCode, to: CurrencyCode): number | string => {
  if (to === CurrencyCode.NONE) {
    return Number.isInteger(value) ? value : value.toFixed(2)
  }

  const cashify = new Cashify({ base: CurrencyCode.EUR, rates })
  const converted = cashify.convert(value, { from: from, to: to })

  return Math.round(converted * 100) / 100
}

/**
 * @deprecated
 */
export const convertAndFormat = (
  value: number,
  from: CurrencyCode,
  to: CurrencyCode,
  invert: boolean = false
): string => {
  const convertedCurrency = convertCurrency(value, from, to)
  const symbol = to === CurrencyCode.NONE ? getSymbolFromCode(from) : getSymbolFromCode(to)

  return invert ? `${convertedCurrency} ${symbol}` : `${symbol} ${convertedCurrency}`
}
