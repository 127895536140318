import css from './SectorsLegend.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IMapSectors, IMapSectorsFilter } from 'meta/map'

type Props = {
  sectors: IMapSectors
  sectorsFilter: IMapSectorsFilter
  setSectorsFilter: (sectorFilter: IMapSectorsFilter) => void
}

const SectorsLegend: React.FC<Props> = (props) => {
  const { sectors, sectorsFilter, setSectorsFilter } = props

  const { t } = useTranslation()

  return (
    <div className={css.sectors}>
      <div className={css.sectors__sector} onClick={() => setSectorsFilter({})}>
        <div className={css.sectors__all}>{t('checkout:all_sectors')}</div>
        <div
          className={classNames(css.sectors__checkbox, {
            [css.active]: Object.keys(sectorsFilter).length === 0,
          })}
        >
          <div />
        </div>
      </div>

      {Object.entries(sectors).map(([sectorUuid, sector]) => {
        const filterActive = sectorsFilter[sectorUuid]

        const toggleSectorFilter = () => {
          let filterUpdate = { ...sectorsFilter }

          if (filterActive) delete filterUpdate[sectorUuid]
          else filterUpdate[sectorUuid] = true

          setSectorsFilter(filterUpdate)
        }

        return (
          <div key={sectorUuid} className={css.sectors__sector} onClick={toggleSectorFilter}>
            <div className={css.sectors__icon} style={{ backgroundColor: sector.color }} />
            <div>{sector.name}</div>
            <div>{sector.maxPrice}</div>
            <div className={classNames(css.sectors__checkbox, { [css.active]: filterActive })}>
              <div />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SectorsLegend
