import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useVisibleEventFeesCodeUrl } from 'hooks/useEvent'
import { useOnUpdate } from 'hooks/useOnUpdate'

import { useClose } from './useClose'

export const useCloseOnRouteUpdate = (): void => {
  const codeUrl = useVisibleEventFeesCodeUrl()
  const { pathname } = useLocation()
  const close = useClose()
  const pathnameOnOpenRef = useRef<string>()

  // 1. keep track of pathname on open/close
  useOnUpdate(() => {
    if (codeUrl) {
      pathnameOnOpenRef.current = pathname
    } else {
      pathnameOnOpenRef.current = undefined
    }
  }, [codeUrl])

  // 2. close on path changed
  useOnUpdate(() => {
    const pathChanged = pathnameOnOpenRef.current !== pathname

    if (codeUrl && pathChanged) {
      close()
    }
  }, [close, codeUrl, pathname])
}
