import css from './Menu.module.scss'
import React, { useCallback, useRef, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { IMenu } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { RouteParams } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { ActionSize, ButtonWhiteRed } from 'components/Actions/Action'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { useMenuItemsScroll, useVisibleSection } from 'components/BackendControlled/Component/Menu/hooks'
import NavButtons from 'components/BackendControlled/Component/Menu/NavButtons'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Grid, GridContainer, GridType } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useIsEventFree } from 'hooks/useEvent'
import { useOnUpdate } from 'hooks/useOnUpdate'

const Menu: React.FC<IPropsComponent<IMenu>> = (props) => {
  const { insideBalloon, underlineFirst, list } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { codeUrl } = useParams<RouteParams['Event']>()

  const id = getComponentId(props.uuid)
  const [visibleSection, sticky] = useVisibleSection(list, id)

  const menuStickyRef = useRef<HTMLDivElement>(null)
  const menuStickyItemRefs = useRef<{ [key: string]: HTMLDivElement }>({})
  const [renderNavButtons, setRenderNavButtons] = useState<boolean>(false)
  const showNavButtons = useMenuItemsScroll(menuStickyItemRefs, menuStickyRef, visibleSection)

  const isEventFree = useIsEventFree()

  const openCheckout = useCallback(() => {
    dispatch(CheckoutActions.init({ codeUrl: codeUrl as string }))
  }, [codeUrl, dispatch])

  useOnUpdate(() => {
    if (menuStickyRef.current) {
      const { offsetWidth, scrollWidth } = menuStickyRef.current
      setRenderNavButtons(sticky && scrollWidth > offsetWidth)
    }
  }, [sticky])

  const getMenu = (sticky = false) => (
    <Grid
      type={GridType.flex}
      className={classNames(css.menu, { [css.insideBalloon]: insideBalloon, [css.underlineFirst]: underlineFirst })}
      id={sticky ? undefined : id}
      ref={sticky ? menuStickyRef : null}
    >
      {list.map((menuItem) => {
        const { anchor, title, uuid } = menuItem
        const anchorId = getComponentId(anchor)

        if (!title || title.trim() === '') return null

        return (
          <div
            key={uuid}
            datatype={sticky ? undefined : 'menu-item'}
            data-active={anchorId === visibleSection}
            className={css.menuItem}
            ref={sticky ? (divElement: HTMLDivElement) => (menuStickyItemRefs.current[anchorId] = divElement) : null}
          >
            <AnchorLink offset="100" href={`#${anchorId}`}>
              <strong>{title}</strong>
            </AnchorLink>
          </div>
        )
      })}
    </Grid>
  )

  return (
    <>
      {insideBalloon ? getMenu() : <GridContainer>{getMenu()}</GridContainer>}

      <div className={classNames(css.menuStickyContainer, { [css.active]: sticky })}>
        {renderNavButtons && (
          <NavButtons showNavButtons={showNavButtons} menuItems={list} visibleSection={visibleSection} />
        )}

        <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
          <a href="/">
            <Img className={css.menuStickyLogo} src="/logo/square-black.svg" />
          </a>
        </MediaQuery>

        <GridContainer className={css.menuStickyGridContainer}>
          {getMenu(true)}

          {codeUrl && (
            <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
              <div className={css.menuBuyTicketsContainerTablet}>
                <ButtonWhiteRed onClick={openCheckout} size={ActionSize.m}>
                  <strong>{isEventFree ? t('common:sign_up') : t('common:buy')}</strong>
                </ButtonWhiteRed>
              </div>
            </MediaQuery>
          )}
        </GridContainer>
      </div>
    </>
  )
}

export default Menu
