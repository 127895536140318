import css from './TicketSelection.module.scss'
import React, { MutableRefObject, useState } from 'react'
import { Range } from 'react-input-range'

import { Breakpoints } from 'meta/layout/breakpoints'
import { IMap, IMapSeat } from 'meta/map'
import { ICheckoutDetails, TicketSelectionType } from 'meta/pages/checkout'

import Automatic from 'components/Checkout/TicketSelection/Automatic'
import Filters from 'components/Checkout/TicketSelection/Filters'
import TicketSeatSelector from 'components/Checkout/TicketSelection/TicketSeatSelector'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useCheckoutDetails, useCheckoutTicketSelection } from 'hooks/useCheckout'

interface IProps {
  modalRef?: MutableRefObject<HTMLElement>
  onSelectSeat: (seats: Array<IMapSeat>, typeTicketId?: number) => void
}

const TicketSelection: React.FC<IProps> = (props) => {
  const { modalRef, onSelectSeat } = props

  const { typeTicketCollection, eventTicket } = useCheckoutDetails() as ICheckoutDetails
  const ticketSelection = useCheckoutTicketSelection()

  const automaticTicketSelection: boolean = ticketSelection?.type === TicketSelectionType.automatic
  const minPrice = Math.min(...eventTicket.data.map((x) => x.priceNumber))
  const maxPrice = Math.max(...eventTicket.data.map((x) => x.priceNumber))
  const priceRangeInitial: Range = { min: minPrice, max: maxPrice }
  const [priceRangeCurrent, setPriceRangeCurrent] = useState<Range>({
    min: minPrice,
    max: maxPrice,
  })

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_ticketselection_container}>
            {/* <div className={css.title}>
                                {doesEventHaveMap ? t('checkout:selection_type') : t('checkout:select_typeticket')}
                            </div> */}

            {/*{hasMap && <Switcher t={t} />}*/}

            {eventTicket.data.length > 1 && automaticTicketSelection && (
              <Filters
                priceRangeInitial={priceRangeInitial}
                priceRangeCurrent={priceRangeCurrent}
                setPriceRangeCallback={setPriceRangeCurrent}
                // setVisibleTypeTicketCallback={setVisibleTypeTicketCallback}
              />
            )}

            {automaticTicketSelection ? (
              <Automatic priceRangeCurrent={priceRangeCurrent} />
            ) : (
              <TicketSeatSelector
                eventId={eventTicket.eventId}
                id="checkout"
                map={eventTicket.map as IMap}
                modalRef={modalRef}
                onSeatSelect={onSelectSeat}
                persistSelectSeat={true}
                fromCollection={Boolean(typeTicketCollection)}
              />
            )}
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default TicketSelection
