import css from './EventListItem.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

const EventListItemSkeleton: React.FC = () => {
  return (
    <GridColumn
      className={css.eventListItemSkeletonContainer}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.laptop]: 1 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 17 }}
    >
      <Skeleton
        className={css.ticketSkeleton}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
      />
    </GridColumn>
  )
}

export default EventListItemSkeleton
