import css from './Tickets.module.scss'
import React, { useRef } from 'react'
import classNames from 'classnames'

import { CurrencySymbol } from 'meta/currency'
import { ICheckoutRecapTicket, TicketSelectionType } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { shortenNameIfLongerThan } from 'utils/string'

import TitleManualSelection from 'components/Checkout/RecapBox/Tickets/TitleManualSelection'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutTicketSeats, useCheckoutTicketSelection } from 'hooks/useCheckout'

interface IProps {
  data: ICheckoutRecapTicket[]
  doesEventHaveMap: boolean
}

const Tickets: React.FC<IProps> = (props) => {
  const { data } = props

  const { activeCurrency } = useActiveCurrency()

  const ticketsRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const ticketSelection = useCheckoutTicketSelection()
  const ticketSeats = useCheckoutTicketSeats()
  const automaticSelection = ticketSelection?.type === TicketSelectionType.automatic

  return (
    <div className={css.checkout_recapbox_tickets_container}>
      <div className={css.tickets} ref={ticketsRef}>
        {data.map((ticket, idx) => {
          const eventCurrencyCode = getCodeFromSymbol(ticket.symbol as CurrencySymbol)

          return (
            <div key={idx} className={css.ticket}>
              <div className={classNames(css.data, { [css.small]: !automaticSelection })}>
                {automaticSelection ? (
                  <div className={css.title}>{`${shortenNameIfLongerThan(ticket.title, 40)} ${ticket.quantity}`}</div>
                ) : (
                  <TitleManualSelection idx={idx} typeTicketId={ticket.typeTicketId as number} />
                )}

                <h5>{`${convertAndFormat(ticket.amount, eventCurrencyCode, activeCurrency)}`}</h5>
              </div>

              <div
                className={css.remove_tickets}
                onClick={() => {
                  automaticSelection
                    ? dispatch(CheckoutActions.removeFromCart({ id: ticket.typeTicketId as number, reset: true }))
                    : dispatch(CheckoutActions.deselectSeat({ seatId: ticketSeats[idx].seatId }))
                }}
              >
                <Img src={'/icons/close_icon_white.svg'} />
              </div>

              <div className={css.tip_corner}>
                <Img src={'/tickets/ticket_tip_grey.svg'} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tickets
