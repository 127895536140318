import css from './DatePicker.module.scss'
import React, { useCallback, useRef, useState } from 'react'
import { DateRange, DayPicker, DayPickerRangeProps } from 'react-day-picker'
import classNames from 'classnames'

import { Dates } from 'utils/dates'
import { Functions } from 'utils/functions'

import { useOnUpdate } from 'hooks/useOnUpdate'

import { useDayPickerProps } from './hooks/useDayPickerProps'

interface Props {
  dates?: Array<Date>
  maximumDate?: Date
  minimumDate?: Date
  onChange(date: Array<Date>): void
  removePadding?: boolean
}

const datesToRange = (dates?: Array<Date>): DateRange => ({
  from: dates && dates[0] ? dates[0] : undefined,
  to: dates && dates[1] ? dates[1] : undefined,
})

export const DateRangePicker: React.FC<Props> = (props) => {
  const { dates, maximumDate: toDate, minimumDate: fromDate, onChange, removePadding } = props

  const [range, setRange] = useState<DateRange>(() => datesToRange(dates))
  const initialRangeRef = useRef<DateRange>(range)

  useOnUpdate(() => {
    const withPreviousRange = initialRangeRef.current?.from && initialRangeRef.current?.to
    const initialFromDiffer =
      withPreviousRange && range.from ? !Dates.isSameDay(range.from, initialRangeRef.current.from!) : true
    const initialToDiffer =
      withPreviousRange && range.to ? !Dates.isSameDay(range.to, initialRangeRef.current.to!) : true
    if (range.from && range.to && !Dates.isSameDay(range.from, range.to) && (initialFromDiffer || initialToDiffer)) {
      Functions.delay(() => onChange([range.from!, range.to!]), 250)
    }
  }, [range])

  const onSelect = useCallback(
    (date?: DateRange) => {
      if (date) {
        if (range.from && range.to) {
          const from = date.from && !Dates.isSameDay(date.from, range.from) ? date.from : date.to
          setRange({ from, to: undefined })
        } else {
          setRange(date)
        }
      }
    },
    [range]
  )

  const dayPickerProps = useDayPickerProps<DayPickerRangeProps>({
    defaultMonth: range.from,
    fromDate,
    min: 2,
    mode: 'range',
    onSelect,
    selected: range,
    toDate,
  })

  return (
    <div className={classNames(css.calendarWrapper, { [css.removePadding]: Boolean(removePadding) })}>
      <DayPicker {...dayPickerProps} />
    </div>
  )
}
