import css from './Countdown.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Numbers } from 'utils/numbers'

import { useShowModal } from './hooks/useShowModal'
import { useStartInterval } from './hooks/useStartInterval'
import { useUtilities } from './hooks/useUtilities'
import AddToCalendarModal from './AddToCalendarModal'

type Props = {
  codeUrl: string
  datetime: Date
}

export const Countdown: React.FC<Props> = (props: Props) => {
  const { codeUrl, datetime } = props
  const { t } = useTranslation()

  const { showModal, toggleModal } = useShowModal()
  const { countDown } = useStartInterval({ datetime })

  const { color, onClick } = useUtilities({ datetime, toggleModal })

  return (
    <>
      <div className={classNames(css.eventDetailsCountdownContainer, css[color])} onClick={onClick}>
        <div className={css.secWrapper}>
          <h5>
            {Numbers.padStart(countDown.sec, 2)}
            &nbsp;
            <span className={css.text}>{`s`}</span>
          </h5>
        </div>
        <div className={css.minWrapper}>
          <h5>
            {Numbers.padStart(countDown.min, 2)}
            &nbsp;
            <span className={css.text}>{`m`}</span>
          </h5>
        </div>
        <div className={css.hoursWrapper}>
          <h5>
            {Numbers.padStart(countDown.hours, 2)}
            &nbsp;
            <span className={css.text}>{`h`}</span>
          </h5>
        </div>
        <div className={css.daysWrapper}>
          <h5 className={css.days}>
            {Numbers.padStart(countDown.days, 2)}
            &nbsp;
            {t('event:days_text')}
          </h5>
        </div>
      </div>
      {showModal && <AddToCalendarModal codeUrl={codeUrl} onClose={toggleModal} />}
    </>
  )
}
