import css from './Voice.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTBLACK, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

export const VoiceSkeleton: React.FC = () => {
  return (
    <div className={css.voiceSkeletonContainer}>
      <Skeleton
        className={css.voiceSkeleton}
        baseColor={TICKETSMS_COLORS_MEDIUMGREY}
        borderRadius={4}
        highlightColor={TICKETSMS_COLORS_LIGHTBLACK}
        inline
      />
    </div>
  )
}
