import { createAsyncThunk } from '@reduxjs/toolkit'

import { IMapSeat } from 'meta/map'
import { CheckoutController } from 'controller/checkout'
import { DetailsParams } from 'store/pages/checkout/slice/types'
import { RootState } from 'store/types'

type Params = {
  codeUrl: string
  typeTicketCollectionId?: string
  initialTypeTicketId?: number
  insideIFrame?: boolean
  fromCollectionIframe?: boolean
  // eventId?: number
  seats?: Array<IMapSeat>
}

export const init = createAsyncThunk<DetailsParams, Params, { state: RootState }>('checkout/init', async (params) => {
  const { codeUrl, typeTicketCollectionId, initialTypeTicketId, insideIFrame, fromCollectionIframe, seats } = params

  const details = await CheckoutController.getDetails({
    codeUrl,
    typeTicketCollectionId,
    seats,
    insideIFrame,
    fromCollectionIframe,
    initialTypeTicketId,
  })

  return details
})
