import css from 'components/Pages/ProfileTransactions/Transaction/Ticket/Ticket.module.scss'
import cssItems from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CurrencyCode } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ITransactionTicket } from 'meta/pages/profile'
import { convertAndFormat } from 'helpers/currency/converter'

import Img from 'components/Media/Img'
import Actions from 'components/Pages/ProfileTransactions/Transaction/Ticket/Actions'
import { useActiveCurrency } from 'hooks/useActiveCurrency'

type Props = {
  data: ITransactionTicket
  listPosition: string
}

const Ticket: React.FC<Props> = (props) => {
  const { data, listPosition } = props
  const { isSeasonTicket } = data
  const { currency, amount } = data.priceData

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()

  const convertedPrice = convertAndFormat(
    parseInt(amount) / 100,
    CurrencyCode[currency as CurrencyCode],
    activeCurrency
  )

  return (
    <div className={css.ticketBox} data-testid={DataTestId.profile.transactionTicket}>
      <div className={css.ticket}>
        <div className={classNames(cssItems.transactionItem, css.holderNameContainer)}>
          <div>{t('profile:holder_name')}</div>
          <strong className={css.holderName} data-testid={DataTestId.profile.transactionTicketHolderName}>
            {data.holderName}
          </strong>
        </div>
        <div className={cssItems.transactionItem}>
          <div>{t('profile:price')}</div>
          <strong>{`${convertedPrice} + ${t('profile:presale')}`}</strong>
        </div>
        {data.fiscalSeal && (
          <div className={cssItems.transactionItem}>
            <div>{t('profile:fiscal_seal')}</div>
            <strong data-testid={`transaction-ticket-fiscal-seal-${listPosition}`}>{data.fiscalSeal}</strong>
          </div>
        )}

        <div className={classNames(cssItems.transactionItem, css.holderPhone)}>
          <div>{t('profile:holder_phone')}</div>
          <strong data-testid={DataTestId.profile.transactionTicketHolderPhone}>{data.holderPhone}</strong>
        </div>
        {data.ticketType && (
          <div className={cssItems.transactionItem}>
            <div>{isSeasonTicket ? t('profile:pass_type') : t('profile:ticket_type')}</div>
            <strong>
              {data.typeTicketCollectionName
                ? `${data.ticketType} - ${data.typeTicketCollectionName}`
                : data.ticketType}
            </strong>
          </div>
        )}
        <div className={cssItems.transactionItem}>
          <div>{t('profile:ticket_status')}</div>
          <strong>{data.statusLabel}</strong>
        </div>

        <Actions data={data} />
      </div>

      <Img className={css.ticketTip} src="/profile/active_voice.svg" />
    </div>
  )
}

export default Ticket
