import { MIMETypesRecord } from 'utils/mimeTypes/mimeTypesRecord'

const getMIMEType = (props: { fileName: string }): string => {
  const { fileName } = props

  const parts = fileName.split('.')
  const extension = parts[parts.length - 1]

  return MIMETypesRecord[extension]
}

export const MIMETypes = {
  getMIMEType,
}
