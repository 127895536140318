import { IUserData } from 'meta/auth'
import { useAppSelector } from 'store'
import { AuthSelectors } from 'store/auth'

import { useIsAppInitialized } from 'hooks/useApp'

export const useUserData = (): IUserData | undefined => useAppSelector(AuthSelectors.getUserData())

export const useAuthToken = (): string | undefined => useAppSelector(AuthSelectors.getToken())

export const useAuthIsPending = (): boolean => {
  const pending = useAppSelector(AuthSelectors.isPending())
  const appInitialized = useIsAppInitialized()
  return !appInitialized || pending
}
