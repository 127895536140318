import css from './Voice.module.scss'
import React, { ButtonHTMLAttributes } from 'react'

import Img from 'components/Media/Img'

import { MenuMobileVoiceProps } from './props'

type Props = MenuMobileVoiceProps & {
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
}

export const MenuMobileButton: React.FC<Props> = (props) => {
  const { icon, label, onClick } = props

  return (
    <button className={css.menuVoice} onClick={onClick}>
      <div>
        <Img src={icon} />
        <h3>{label}</h3>
      </div>
    </button>
  )
}
