import css from './Desktop.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

import PaginatorSkeleton from '../PaginatorSkeleton/PaginatorSkeleton'

const Desktop: React.FC = () => {
  return (
    <>
      <div className={css.checkout_season_items_skeleton_desktop_container}>
        {[1, 2, 3].map((_elem, key) => (
          <GridContainer key={key}>
            <Grid>
              <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 1 }} gridColumnEnd={{ [Breakpoints.laptop]: 8 }}>
                <Skeleton
                  className={css.itemSkeleton}
                  baseColor={TICKETSMS_COLORS_MEDIUMGREY}
                  borderRadius={16}
                  highlightColor={TICKETSMS_COLORS_LIGHTGREY}
                />
              </GridColumn>
              <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 8 }} gridColumnEnd={{ [Breakpoints.laptop]: 15 }}>
                <Skeleton
                  className={css.itemSkeleton}
                  baseColor={TICKETSMS_COLORS_MEDIUMGREY}
                  borderRadius={16}
                  highlightColor={TICKETSMS_COLORS_LIGHTGREY}
                />
              </GridColumn>
            </Grid>
          </GridContainer>
        ))}
      </div>

      <PaginatorSkeleton />
    </>
  )
}

export default Desktop
