export let ProcessEnv = {
  activateLogger: import.meta.env.ACTIVATE_LOGGER,
  disableSatispay: import.meta.env.VITE_DISABLE_SATISPAY,
  eventName: import.meta.env.eventName,
  transactionCode: import.meta.env.transactionCode,
  transactionSubtotal: import.meta.env.transactionSubtotal,
  url: import.meta.env.VITE_ENV_URL,
  version: import.meta.env.VITE_APP_VERSION,
  voucher: import.meta.env.voucher,
}
