import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldRadioOption {
  value: string
  label: string
}

export interface IFormFieldRadio extends IFormField<FormFieldType.radio> {
  options: Array<IFormFieldRadioOption>
  description: string
  translateLabels: boolean
}

export class RadioBuilder extends FormFieldBuilder<FormFieldType.radio> {
  constructor(
    name: string,
    description?: string,
    options?: Array<IFormFieldRadioOption>,
    translateLabels: boolean = false,
    nameValidation?: string
  ) {
    super(name, FormFieldType.radio, nameValidation)

    if (options) {
      super.addField('options', options)
    }

    if (description) {
      super.addField('description', description)
    }

    super.addField('translateLabels', translateLabels)
  }
}
