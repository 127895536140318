import css from './B2b.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { Breakpoints } from 'meta/layout/breakpoints'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { ActionSize, LinkBlack } from 'components/Actions/Action'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlBusiness, useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  uuid: string
}

const B2b: React.FC<IProps> = (props) => {
  const { uuid } = props

  const { t } = useTranslation(['infobox'])
  const businessUrl = useUrlBusiness()

  const urlCloudfront = useUrlCloudfront()

  return (
    <Grid className={css.infoBoxContainer} id={getComponentId(uuid)}>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 2 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 11 }}
        className={css.infoBoxContainer__column}
      >
        <div className={css.infoBoxContent}>
          <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
            <ImgSkeleton
              alt={``}
              src={`${urlCloudfront}/images/banner/b2b-transparent-bg-comp.gif`}
              className={css.infoBoxPlaceholderImg}
            />
          </MediaQuery>

          <h2>{t(`infobox:b2b-header`)}</h2>

          <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
            <h3 className={css.description}>
              {t(`infobox:b2b-description-first`)}
              <br />
              {t(`infobox:b2b-description-second`)}
            </h3>
          </MediaQuery>

          <LinkBlack title={t(`infobox:b2b-findmore`)} href={businessUrl} size={ActionSize.s}>
            <strong>{t(`infobox:b2b-findmore`)}</strong>
          </LinkBlack>
        </div>
      </GridColumn>

      <GridColumn className={css.infoBoxPlaceholder} gridColumnStart={12} gridColumnEnd={16}>
        <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
          <ImgSkeleton
            alt={``}
            src={`${urlCloudfront}/images/banner/b2b-transparent-bg-comp.gif`}
            className={css.infoBoxPlaceholderImg}
          />
          <Img className={css.monitoraTempoReale} src="/infobox/monitora_tempo_reale.png" />
        </MediaQuery>
      </GridColumn>
    </Grid>
  )
}

export default B2b
