import css from './RadioButton.module.scss'
import React from 'react'
import classNames from 'classnames'

type Props = {
  email: string
  onClick: () => void
  selected: boolean
}

const RadioButton: React.FC<Props> = (props) => {
  const { email, onClick, selected } = props

  return (
    <button className={classNames(css.radioBtn, { [css.selected]: selected })} onClick={onClick} type="button">
      <div className={css.radioBtn__selector}>
        <div />
      </div>
      <div>{email}</div>
    </button>
  )
}

export default RadioButton
