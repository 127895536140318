import React from 'react'

import { EnvType } from 'meta/app'

import Head from 'components/common/Head'
import { useAppEnv, useDisableSatispay } from 'hooks/useEnv'

const Satispay: React.FC = () => {
  const appEnv = useAppEnv()
  const disableSatispay = useDisableSatispay()

  if (disableSatispay) return null

  return (
    <Head>
      <script
        async
        type="text/javascript"
        src={`https://${appEnv === EnvType.production ? '' : 'staging.'}online.satispay.com/web-button.js`}
      />
    </Head>
  )
}

export default Satispay
