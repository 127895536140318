import 'styles/_app.scss'
import React from 'react'

import { useInitApp } from 'App/hooks/useInitApp'

import Pages from 'components/Pages'

const App: React.FC = () => {
  useInitApp()

  return <Pages />
}

export default App
