import { useState } from 'react'

import { CookieClient, refreshAuthToken } from 'utils/cookie'

import { useBackendApiBase, useOTPConfigKey, useProxyApiBase } from 'hooks/useEnv'
import { useIsMounted } from 'hooks/useIsMounted'

import { callDelete, get, IFetch, IGetParams, IPostParams, IRequestParams, patch, post } from './common'

export interface IUseFetch<T extends IRequestParams> {
  error?: Response | Error | string
  fetch: IFetch<T>
  fetching: boolean
  response?: any
  status?: number
}

const useFetch = <T extends IRequestParams>(
  fetchFn: IFetch<T>,
  backendApiBase: string,
  proxyApiBase: string,
  otpConfigKey?: string
): IUseFetch<T> => {
  const isMounted = useIsMounted()
  const [{ error, response, status }, setState] = useState<{ error?: Error; response?: any; status?: number }>({})
  const [fetching, setFetching] = useState<boolean>(false)

  //@ts-ignore
  const fetch: IFetch<T> = async (params: T): Promise<Response> => {
    const { urlBase, useProxy } = params

    try {
      setFetching(true)
      // reset error before new ajax request
      setState((prevState) => ({ ...prevState, error: undefined }))
      const fetchResponse = await fetchFn({
        ...params,
        urlBase: Boolean(useProxy) ? proxyApiBase : urlBase ? urlBase : backendApiBase,
        otpConfigKey: otpConfigKey,
      })

      if (isMounted.current) {
        setFetching(false)
        const json = await fetchResponse.json()
        const { status } = json

        if (fetchResponse.ok) {
          refreshAuthToken(fetchResponse, CookieClient, { expires: 1 })
          setState({ response: json, status })
        } else setState({ error: json.errors instanceof Error ? json.errors : json.error, status })
      }

      return fetchResponse
    } catch (e) {
      if (isMounted.current) {
        setFetching(false)
        //@ts-ignore
        setState({ error: e })
      }
    }
  }

  return { error, fetch, fetching, response, status }
}

export const useGet = (): IUseFetch<IGetParams> =>
  useFetch<IGetParams>(get, useBackendApiBase(), useProxyApiBase(), useOTPConfigKey())

export const usePatch = (): IUseFetch<IPostParams> =>
  useFetch<IPostParams>(patch, useBackendApiBase(), useProxyApiBase(), useOTPConfigKey())

export const usePost = (): IUseFetch<IPostParams> =>
  useFetch<IPostParams>(post, useBackendApiBase(), useProxyApiBase(), useOTPConfigKey())

export const useDelete = (): IUseFetch<IPostParams> =>
  useFetch<IPostParams>(callDelete, useBackendApiBase(), useProxyApiBase(), useOTPConfigKey())
