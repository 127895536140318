import css from './OldTicketData.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import { FormBuilder, TextBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Form, { FormSkeleton } from 'components/Forms/Form'
import { useI18NContext } from 'components/HOC/WithTranslation'
import RoundedBox from 'components/TextBoxes/RoundedBox'
import { useBlogApiBase } from 'hooks/useEnv'
import { useTicketChangeNameData } from 'hooks/useProfile'

import { IPropsChangeTicketNameStep } from '../types'

const OldTicketData: React.FC<IPropsChangeTicketNameStep> = (props) => {
  const { authToken, onSuccess } = props

  const { t } = useTranslation()

  const blogBase = useBlogApiBase()

  //@ts-ignore
  const { data } = useTicketChangeNameData()

  const { namespacesLoaded } = useI18NContext()

  const firstName = new TextBuilder('firstName')
    .addRequiredNameValidation()
    //@ts-ignore
    .addValue(data.currentHolder.firstName)
    .addPlaceholder('form:firstName')
    .build()
  const lastName = new TextBuilder('lastName')
    .addRequiredNameValidation()
    //@ts-ignore
    .addValue(data.currentHolder.lastName)
    .addPlaceholder('form:lastName')
    .build()
  const fiscalSeal = new TextBuilder('fiscalSeal')
    .addRequiredFiscalSealValidation()
    //@ts-ignore
    .addValue(data.fiscalSeal)
    .addPlaceholder('form:fiscalSeal')
    .build()
  const formFields = new FormBuilder().addField(firstName).addField(lastName).addField(fiscalSeal).build()

  return (
    <>
      <WithAppInitialized
        skeleton={<ProfileTranslationsSkeleton withMultipleDescriptions />}
        showChildren={namespacesLoaded}
      >
        <h3 data-testid={'profile-change-name-oldticket'}>{t('profile:change_ticket_holder_header')}</h3>
        <div>{t('profile:change_ticket_can_change_name')}</div>
        <div>{t('profile:change_ticket_write_data')}</div>
        <div>{t('profile:change_ticket_is_free')}</div>

        <a className={css.guideLink} href={`${blogBase}/changename/`}>
          {t('profile:change_name_guide_link')}
        </a>

        <RoundedBox bgColor={ComponentColor.grey} infoType="info">
          <span data-testid={'profile-change-name-info'}>{t('profile:change_holder_info')}</span>
        </RoundedBox>
      </WithAppInitialized>

      <WithAppInitialized skeleton={<FormSkeleton items={3} />}>
        <Form
          action={ApiEndpoint.users.changeName()}
          authToken={authToken}
          formFields={formFields}
          labelSubmit="register:submit_label"
          onSuccess={(values, response) => {
            //@ts-ignore
            return onSuccess({
              ...data,
              //@ts-ignore
              fiscalSeal: values.fiscalSeal,
              additionalInfos: response.data.additionalInfos,
              maxTerm: new Date(response.data.event.dateEvent),
              isPersonalDataRequired: response.data.event.isPersonalDataRequired,
            })
          }}
        />
      </WithAppInitialized>
    </>
  )
}

export default OldTicketData
