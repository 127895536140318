import css from './Cover.module.scss'
import React from 'react'

import { IEventDetails } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import Card, { CardImageType } from 'components/Layout/Card'
import { GridColumn } from 'components/Layout/Grid'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const CoverBox: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const { coverImg, location, name } = props

  const urlCloudfront = useUrlCloudfront()
  const backgroundImage = coverImg
    ? coverImg
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.tablet]: 13, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={17}
      gridRowStart={1}
    >
      <Card imageType={CardImageType.cover}>
        <ImgSkeleton
          alt={`${name} ${location?.name} ${location?.common}`}
          src={backgroundImage}
          className={css.image_skeleton}
        />
      </Card>
    </GridColumn>
  )
}

export default CoverBox
