import { nanoid } from 'nanoid'

import { HttpRequest } from 'utils/http'

interface IAnalyticsBackendData {
  route: string
  fromOrganizer: boolean
  s2sToken: string
}

export enum AnalyticsEventType {
  Purchase = 'Purchase',
  ViewContent = 'ViewContent',
  InitiateCheckout = 'InitiateCheckout',
  RemoveFromCart = 'RemoveFromCart',
  AddToCart = 'AddToCart',
}

export const trackFbPixelEvent = async (
  name: AnalyticsEventType,
  eventData: object = {},
  backendData: IAnalyticsBackendData
) => {
  const { route, s2sToken } = backendData

  //@ts-expect-error fbq might not be defined
  if (window && window.fbq) {
    // generate uuid as eventId
    const eventId = nanoid()

    //@ts-expect-error fbq might not be defined
    window.fbq('track', name, eventData, {
      eventId: eventId,
    })

    await HttpRequest.post(
      route,
      {
        'facebook': {
          'facebookEvent': {
            'custom_data': eventData,
            'event_name': name,
            'event_id': eventId,
            'event_source_url': window.location.href,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Tsms-S2S-Token': s2sToken,
          'Ticket-Otp-Token': s2sToken,
        },
      }
    )
  }
}
