import css from './GridContainer.module.scss'
import React from 'react'
import classNames from 'classnames'

interface IPropsGridContainer extends React.HTMLAttributes<HTMLDivElement> {}

export const GridContainer: React.FC<IPropsGridContainer> = (props) => {
  const { className, children, ...rest } = props

  return (
    <div className={classNames(css.gridContainer, className)} {...rest}>
      {React.Children.toArray(children)}
    </div>
  )
}
