import React from 'react'
import { useTranslation } from 'react-i18next'

import { IUserData } from 'meta/auth'
import { ComponentColor } from 'meta/backendControlled/structure'
import { getPurchaseFormCookieString } from 'utils/cookie/client'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { FormSkeleton, IFormOnSuccess } from 'components/Forms/Form'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { ChangeDataFormFields } from 'components/Pages/ProfileChangeData/ChangeDataForm/ChangeDataFormFields'
import RoundedBox from 'components/TextBoxes/RoundedBox'

type Props = {
  onSuccess: IFormOnSuccess
  authToken: string
  userData: IUserData
}

const ChangeDataForm: React.FC<Props> = (props) => {
  const { onSuccess, authToken, userData } = props

  const { t } = useTranslation()

  const purchaseFormUrl = getPurchaseFormCookieString()

  const { namespacesLoaded } = useI18NContext()

  return (
    <>
      <WithAppInitialized
        showChildren={namespacesLoaded}
        skeleton={<ProfileTranslationsSkeleton withSingleDescription />}
      >
        <h3>{t('profile:change_name_header')}</h3>

        {purchaseFormUrl && (
          <RoundedBox bgColor={ComponentColor.yellow} infoType="warning">
            <div>{t('profile:missing_info_redirect')}</div>
          </RoundedBox>
        )}

        <RoundedBox bgColor={ComponentColor.grey} infoType="info">
          <div>{t('profile:change_phone')}</div>
        </RoundedBox>
      </WithAppInitialized>

      <WithAppInitialized skeleton={<FormSkeleton items={6} />} requireUser>
        <ChangeDataFormFields userData={userData} authToken={authToken} onSuccess={onSuccess} />
      </WithAppInitialized>
    </>
  )
}

export default ChangeDataForm
