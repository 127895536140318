import css from './GoogleMapDynamic.module.scss'
import React from 'react'
import GoogleMapReact from 'google-map-react'

import { TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useGoogleMapsApiKey } from 'hooks/useEnv'

interface IProps {
  lat: number
  long: number
  locationName?: string
}

const GoogleMapDynamic: React.FC<IProps> = (props) => {
  const { lat, long: lng } = props
  const key = useGoogleMapsApiKey()

  if (key === '') return null

  const MapPointer = () => (
    <div
      style={{
        color: TICKETSMS_COLORS_WHITE,
        background: 'black',
        padding: '5px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  )

  return (
    <GridContainer>
      <Grid>
        <GridColumn className={css.googleMapContainer} gridColumnEnd={17}>
          <GoogleMapReact bootstrapURLKeys={{ key }} center={{ lat, lng }} defaultZoom={15}>
            <MapPointer />
          </GoogleMapReact>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default GoogleMapDynamic
