import css from './MenuMobile.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const MenuMobileSkeleton: React.FC = () => {
  return (
    <Skeleton
      className={css.btnMenuSkeleton}
      baseColor={TICKETSMS_COLORS_LIGHTGREY}
      borderRadius={4}
      highlightColor={TICKETSMS_COLORS_WHITE}
      inline
    />
  )
}
