import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { fromEvent, merge, Observable, Subscription } from 'rxjs'

import { PlayDefaults } from './defaults'

export type TouchCoordinates = {
  x: number
  y: number
}

export type Play = {
  timerSubscription$: MutableRefObject<Subscription>
  playEvent$: MutableRefObject<Observable<any>>
  pauseEvent$: MutableRefObject<Observable<any>>
  playSubscription$: MutableRefObject<Subscription>
  pauseSubscription$: MutableRefObject<Subscription>
  clickStart: MutableRefObject<Date>
  touchStart: MutableRefObject<TouchCoordinates>
  interval: MutableRefObject<number>
  timeElapsed: MutableRefObject<number>
  playingVideo: MutableRefObject<boolean>
  unsubscribe: () => void
}

export const usePlay = (storyRef: MutableRefObject<HTMLDivElement>): Play => {
  const timerSubscription$ = useRef<Subscription>(null)
  const playEvent$ = useRef<Observable<any>>(null)
  const pauseEvent$ = useRef<Observable<any>>(null)
  const playSubscription$ = useRef<Subscription>(null)
  const pauseSubscription$ = useRef<Subscription>(null)
  const clickStart = useRef<Date>(null)
  const touchStart = useRef<TouchCoordinates>(null)
  const interval = useRef<number>(PlayDefaults.interval)
  const timeElapsed = useRef<number>(0)
  const playingVideo = useRef<boolean>(false)

  const unsubscribe = useCallback((): void => {
    timerSubscription$.current && timerSubscription$.current.unsubscribe()
    playSubscription$.current && playSubscription$.current.unsubscribe()
    pauseSubscription$.current && pauseSubscription$.current.unsubscribe()
    //@ts-ignore
    clickStart.current = null
    //@ts-ignore
    touchStart.current = null
    interval.current = PlayDefaults.interval
    timeElapsed.current = 0
    playingVideo.current = false
  }, [])

  // onMount initialize play and pause event
  useEffect(() => {
    const mouseUp = fromEvent(storyRef.current, 'mouseup')
    const mouseDown = fromEvent(storyRef.current, 'mousedown')
    const touchEnd = fromEvent(storyRef.current, 'touchend')
    const touchStart = fromEvent(storyRef.current, 'touchstart')

    //@ts-ignore
    playEvent$.current = merge(mouseUp, touchEnd)
    //@ts-ignore
    pauseEvent$.current = merge(mouseDown, touchStart)
  }, [])

  return {
    //@ts-ignore
    timerSubscription$,
    //@ts-ignore
    playEvent$,
    //@ts-ignore
    pauseEvent$,
    //@ts-ignore
    playSubscription$,
    //@ts-ignore
    pauseSubscription$,
    //@ts-ignore
    clickStart,
    //@ts-ignore
    touchStart,
    interval,
    timeElapsed,
    playingVideo,
    unsubscribe,
  }
}
