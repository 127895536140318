import css from './Help.module.scss'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { CheckoutActions } from 'store/pages/checkout'

interface IProps {
  timerComponent: ReactElement
}

const Help: React.FC<IProps> = (props) => {
  const { timerComponent } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSalesforce = () => {
    document.getElementById('helpButtonSpan') && document.getElementById('helpButtonSpan')?.click()
    dispatch(CheckoutActions.close())
  }

  return (
    <div className={css.checkout_recapbox_mobile_help_container}>
      <div className={css.hasMapWithHelp}>
        <div className={css.help} onClick={handleSalesforce}>
          {t('checkout:need_help')}
        </div>
        <div className={css.timer}>{timerComponent}</div>
      </div>
    </div>
  )
}

export default Help
