import React from 'react'

import { RequestMethod } from 'meta/api/requestMethod'
import { FormBuilder, HiddenBuilder, PasswordBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'

import Form from 'components/Forms/Form'
import { IPropsChangePasswordForm } from 'components/Pages/ProfileChangePassword/ChangePasswordForm/ChangePasswordForm.types'

const ChangePasswordFormFields: React.FC<IPropsChangePasswordForm> = (props) => {
  const { authToken, email, fromProfile, onSuccess } = props

  const password = new PasswordBuilder('password').addRequiredValidation().addPlaceholder('form:newPassword').build()
  const passwordConfirm = new PasswordBuilder('passwordConfirm')
    .addValidation({
      required: `form:errors_required_passwordConfirm`,
      validate: {
        message: 'form:errors_password_not_matching',
        //@ts-ignore
        test: (value) => {
          const passwordElement = document.getElementById('password') as HTMLInputElement
          return value === passwordElement.value
        },
      },
    })
    .addPlaceholder('form:newPasswordConfirm')
    .build()

  const formBuilder = new FormBuilder().addField(password).addField(passwordConfirm)

  if (!fromProfile) {
    const tokenField = new HiddenBuilder('token').addValue(authToken).addRequiredValidation().build()
    formBuilder.addField(tokenField)
  }

  if (email) {
    const emailField = new HiddenBuilder('email').addValue(email).addRequiredValidation().build()
    formBuilder.addField(emailField)
  }

  return (
    <Form
      action={fromProfile ? ApiEndpoint.users.profile() : ApiEndpoint.users.passwordUpdate()}
      authToken={authToken}
      id="changePassword-form"
      //@ts-ignore
      bodyParser={(fieldValues) => {
        return fromProfile
          ? {
              password: fieldValues.password,
            }
          : {
              email: fieldValues.email,
              password: fieldValues.password,
              token: fieldValues.token,
            }
      }}
      formFields={formBuilder.build()}
      labelSubmit="profile:edit"
      onSuccess={onSuccess}
      requestMethod={fromProfile ? RequestMethod.patch : RequestMethod.post}
    />
  )
}

export default ChangePasswordFormFields
