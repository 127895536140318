import css from './PaymentMethods.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'

import { Breakpoints, BreakpointsMaxWidth, BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const PaymentMethodsSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_paymentmethods_container}>
            <Skeleton
              className={css.titleSkeleton}
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={8}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />

            <div className={css.boxes_container}>
              <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
                {[0, 1, 2, 3].map((idx) => (
                  <Skeleton
                    key={idx}
                    className={css.paymentMethodSkeleton}
                    baseColor={TICKETSMS_COLORS_MEDIUMGREY}
                    borderRadius={8}
                    highlightColor={TICKETSMS_COLORS_LIGHTGREY}
                  />
                ))}
              </MediaQuery>
              <MediaQuery minWidth={BreakpointsMinWidth.laptop}>
                <Skeleton
                  className={css.paymentMethodSkeleton}
                  baseColor={TICKETSMS_COLORS_MEDIUMGREY}
                  borderRadius={8}
                  highlightColor={TICKETSMS_COLORS_LIGHTGREY}
                />
              </MediaQuery>
            </div>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}
