import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  CheckoutStep,
  ICheckoutAuth,
  ICheckoutCartTicket,
  ICheckoutDetails,
  ICheckoutPayment,
  ICheckoutRecap,
  ICheckoutRecapTicket,
  ICheckoutStripeData,
  ITicketSeat,
  ITicketSelectionManual,
} from 'meta/pages/checkout'
import { fetchRecap } from 'store/pages/checkout/actions/fetchRecap'
import { init } from 'store/pages/checkout/actions/init'
import { selectSeat } from 'store/pages/checkout/actions/selectSeat'
import { setTransactionErrorMessage } from 'store/pages/checkout/actions/setTransactionErrorMessage'
import { startProcess } from 'store/pages/checkout/actions/startProcess'
import { startTransaction } from 'store/pages/checkout/actions/startTransaction'
import { setInitialized, setInitializing } from 'store/pages/checkout/slice/reducers/init'
import { setSelectedSeat, setSelectingSeat } from 'store/pages/checkout/slice/reducers/map'
import { setProcessCompleted, setProcessError, setProcessStarted } from 'store/pages/checkout/slice/reducers/process'
import { setRecapFetched, setRecapFetchError } from 'store/pages/checkout/slice/reducers/recap'
import {
  setTransactionError,
  setTransactionStarted,
  setTransactionStarting,
} from 'store/pages/checkout/slice/reducers/transaction'
import { updateCart } from 'store/pages/checkout/slice/updateCart'
import { CheckoutState, initialState } from 'store/pages/checkout/state'

import { fetchSeasonEvents } from '../actions/fetchSeasonEvents'
import { setSeasonEventsCompleted } from './reducers/seasonEvents'

export const CheckoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    close: () => {
      return { ...initialState }
    },
    updateAuth: (state: Draft<CheckoutState>, action: PayloadAction<{ auth: ICheckoutAuth }>) => {
      const { auth } = action.payload
      return { ...state, auth }
    },
    updateDetails: (state: Draft<CheckoutState>, action: PayloadAction<{ details: ICheckoutDetails }>) => {
      const { details } = action.payload
      return { ...state, details }
    },
    updatePayment: (state: Draft<CheckoutState>, action: PayloadAction<{ payment: ICheckoutPayment }>) => {
      const { payment } = action.payload
      return { ...state, payment }
    },
    updateStep: (state: Draft<CheckoutState>, action: PayloadAction<{ step: CheckoutStep }>) => {
      const { step } = action.payload
      return { ...state, step }
    },
    updateStripe: (state: Draft<CheckoutState>, action: PayloadAction<{ stripe: ICheckoutStripeData }>) => {
      const { stripe } = action.payload
      return { ...state, stripe }
    },
    // cart
    addToCart: (state: Draft<CheckoutState>, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload
      return { ...state, cart: { tickets: updateCart([...(state.cart?.tickets ?? [])], id, 1), lastTypeTicket: id } }
    },
    removeFromCart: (state: Draft<CheckoutState>, action: PayloadAction<{ id: number; reset?: boolean }>) => {
      const { id, reset } = action.payload
      return {
        ...state,
        cart: { tickets: updateCart([...(state.cart?.tickets ?? [])], id, -1, undefined, reset), lastTypeTicket: id },
      }
    },
    resetCart: (state: Draft<CheckoutState>) => {
      return {
        ...state,
        cart: {
          tickets: [],
          lastTypeTicket: undefined,
        },
        recap: { ...initialState.recap },
      } as CheckoutState
    },
    // map
    deselectSeat: (state: Draft<CheckoutState>, action: PayloadAction<{ seatId: string }>) => {
      const { seatId } = action.payload
      const ticketSelection = state.ticketSelection as ITicketSelectionManual
      const ticketSeats = [...(ticketSelection.ticketSeats ?? [])]
      const idx = ticketSeats?.findIndex((ticketSeat) => ticketSeat.seatId === seatId) ?? -1
      if (ticketSeats && idx >= 0) {
        ticketSeats.splice(idx, 1)

        const tickets = [...(state.cart?.tickets ?? [])] as Array<ICheckoutCartTicket>
        tickets.splice(idx, 1)

        const recap = state.recap as ICheckoutRecap
        const recapTickets = [...recap.tickets] as Array<ICheckoutRecapTicket>
        recapTickets.splice(idx, 1)

        return {
          ...state,
          cart: { tickets, lastTypeTicket: state.cart?.lastTypeTicket },
          recap: { ...recap, tickets: [...recapTickets] },
          ticketSelection: { ...ticketSelection, ticketSeats: [...ticketSeats] },
        }
      }
      return state
    },
    selectSeatTypeTicket: (
      state: Draft<CheckoutState>,
      action: PayloadAction<{ idx: number; typeTicketId: number }>
    ) => {
      const { idx, typeTicketId } = action.payload
      const ticketSelection = { ...state.ticketSelection } as ITicketSelectionManual
      const ticketSeats = [...(ticketSelection.ticketSeats ?? [])]

      if (ticketSeats.length > 0) {
        const ticketSeat: ITicketSeat = ticketSeats[idx]
        ticketSeats[idx] = {
          ...ticketSeat,
          selectedTypeTicketId: typeTicketId,
        }

        const cartTickets = [...(state.cart?.tickets ?? [])] as Array<ICheckoutCartTicket>
        const cartTicketsItem = cartTickets[idx]
        cartTickets[idx] = { ...cartTicketsItem, typeTicketId }

        return {
          ...state,
          cart: { tickets: cartTickets, lastTypeTicket: state.cart?.lastTypeTicket },
          ticketSelection: { ...ticketSelection, ticketSeats: [...ticketSeats] },
        }
      }
      return state
    },
    resetSeat: (state: Draft<CheckoutState>) => {
      return {
        ...state,
        ticketSelection: {
          type: state.ticketSelection!.type,
          ticketSeats: [],
        } as ITicketSelectionManual,
      }
    },
  },
  extraReducers: (builder) => {
    // init
    builder.addCase(init.pending, setInitializing)
    builder.addCase(init.fulfilled, setInitialized)
    // recap
    builder.addCase(fetchRecap.fulfilled, setRecapFetched)
    builder.addCase(fetchRecap.rejected, setRecapFetchError)
    // map
    builder.addCase(selectSeat.pending, setSelectingSeat)
    builder.addCase(selectSeat.fulfilled, setSelectedSeat)
    // transaction
    builder.addCase(startTransaction.pending, setTransactionStarting)
    builder.addCase(startTransaction.fulfilled, setTransactionStarted)
    builder.addCase(startTransaction.rejected, setTransactionError)
    builder.addCase(setTransactionErrorMessage, setTransactionError)
    // process
    builder.addCase(startProcess.pending, setProcessStarted)
    builder.addCase(startProcess.fulfilled, setProcessCompleted)
    builder.addCase(startProcess.rejected, setProcessError)
    // fetch season events
    builder.addCase(fetchSeasonEvents.fulfilled, setSeasonEventsCompleted)
  },
})
