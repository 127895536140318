import css from 'components/Pages/SupportForm/SupportForm.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const SupportFormSkeleton: React.FC = () => {
  return (
    <div className={css.topics}>
      {[0, 1, 2].map((item, key) => {
        return (
          <div key={key} className={classNames(css.topic)}>
            <div className={classNames(css.image__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={50}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.topicTitle, css.topicTitle__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
