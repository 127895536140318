import React from 'react'

import { IEventDetails } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import Description from 'components/BackendControlled/Component/common/Description'
import CancelledBox from 'components/BackendControlled/Component/EventDetails/Infos/Cancelled'
import Header from 'components/BackendControlled/Component/EventDetails/Infos/Header'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'

import { Countdown } from './Countdown'

const Infos: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const { datetime, isCancelled, isRefundable, codeUrl } = props

  const showCancelledBox = isCancelled && isRefundable

  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}>
      <Header {...props} />
      {showCancelledBox ? <CancelledBox /> : <Countdown codeUrl={codeUrl} datetime={new Date(datetime)} />}
      <Description description={props.description} />
    </GridColumn>
  )
}

export default Infos
