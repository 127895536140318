export const DEFAULT_PR_COOKIE_TIME = 36

export enum CookieName {
  TOKEN = 'token',
  NEXT_LOCALE = 'NEXT_LOCALE',
  PR_EVENT_OBJECT = 'PR_EVENT_OBJECT',
  PURCHASE_FORM_URLENCODED_STRING = 'PURCHASE_FORM_URLENCODED_STRING',
  CURRENCY = 'CURRENCY',
  CSRF_TOKEN = 'CSRF_TOKEN',
  LAST_TRANSACTION = 'LAST_TRANSACTION',
}

export interface ICookie<P> {
  get(name: CookieName, params?: P): string
  set(name: CookieName, value: string, params?: P): void
  remove(name: CookieName, params?: P): void
}
