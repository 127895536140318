import css from 'components/Pages/Wallet/WalletTicketDesktop/UserTicket/UserTicket.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

export const UserTicketSkeleton: React.FC = () => {
  const urlCloudfront = useUrlCloudfront()
  return (
    <div className={classNames(css.userTicket, css.userTicket__skeleton)}>
      <div
        className={classNames(css.userTicket__oneRow, css.userTicket__count)}
        style={{ backgroundImage: `url(${urlCloudfront}/images/wallet/ticket_red_h.svg)` }}
      >
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
      <div>
        <div className={css.userTicket__infoTitle__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.userTicket__name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>
      <div>
        <div className={css.userTicket__infoTitle__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.userTicket__name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>

      <div className={classNames(css.userTicket__oneRow, css.userTicket__btnPrint, css.userTicket__btnPrint__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>

      <div>
        <div className={css.userTicket__infoTitle__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.userTicket__name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>
      <div>
        <div className={css.userTicket__infoTitle__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.userTicket__name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>
      <Img className={css.userTicket__imgCorner} src="/wallet/ticket_corner_grey.svg" />
    </div>
  )
}
