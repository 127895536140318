import css from './VenueAttributes.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'

import { ComponentType, IVenueAttributes } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import DescriptionReadMore from 'components/BackendControlled/Component/common/DescriptionReadMore'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import Captions from 'components/BackendControlled/Component/VenueAttributes/Captions'
import { Grid, GridColumn } from 'components/Layout/Grid'

const VenueAttributes: React.FC<IPropsComponent<IVenueAttributes>> = (props) => {
  const { attributes, description, title, uuid } = props

  return (
    <Grid id={getComponentId(uuid)}>
      <GridColumn className={css.venueAttributes} gridColumnEnd={17}>
        <div>
          <h2 className={css.venueAttributes__title}>{title}</h2>
          <DescriptionReadMore component={ComponentType.venueAttributes} description={description} />
        </div>

        <div className={css.venueAttributes__captions}>
          {attributes && (
            <>
              <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
                <Captions itemsPerRow={4} captions={attributes} />
              </MediaQuery>

              <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
                <Captions itemsPerRow={3} captions={attributes} />
              </MediaQuery>
            </>
          )}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default VenueAttributes
