import css from './Eng.module.scss'
import React from 'react'

import Head from 'components/common/Head'
import Img from 'components/Media/Img'

const ResaleEng: React.FC = () => {
  const meta_title = 'How to resell tickets previously bought on TicketSms'
  const meta_desc =
    'Cannot attend to an event? Get your money back: to resell tickets has never been so easy. More infos here!'

  return (
    <>
      <Head>
        <title>{meta_title}</title>

        <meta property="og:title" content={meta_title} />
        <meta name="description" content={meta_desc} />
        <meta property="og:description" content={meta_desc} />
      </Head>

      <div className={css.resale_eng_container}>
        <div className={css.static_page_title}>
          <Img src="/resale/resale.svg" />
          <div>Resale</div>
        </div>
        <div className={css.static_page_text}>
          <p>
            Tickets purchased through TicketSms are nominative, in particular, events with more than 5,000 spectators
            require, on the basis of the{' '}
            <a
              target="_blank"
              href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
              rel="noreferrer"
            >
              italian legislation
            </a>
            , the obligation of <b>named tickets</b> and checking of the identity of the ticket holder at the entrace.
            For this reason, upon entering the events above, a valid identity document will also be required to allow
            the staff in charge of checks to verify that the holder is the one to access with that specific ticket. In
            case of a mismatch, the event cannot be acessed and it won't be possible to require a refund. On our
            website, on the event page it will be indicated whether the event is subject to nomination and therefore it
            will be <b>mandatory</b> to show up at the event with a valid document in order to enter.
          </p>

          <p>
            If you can no longer attend an event, you can resell the ticket if you are the actual holder or if you have
            purchased the tickets yourself. In case of multiple purchases you can resell even just one of the tickets.
            You can start the resell procedure by accessing the specific section from your user profile or from the
            summary of transactions, if you are the buyer of the ticket.
          </p>

          <p>
            <b>During the procedure</b> you'll be required to enter the Name, Surname and Fiscal Seal on the original
            ticket of which you are the holder or the purchaser (this information is indicated on the ticket itself).
            It's also necessary to enter an IBAN so that if the ticket is resold you will recive a bank transfer.
          </p>

          <p>
            Once the data and the right to proceed have been verified a summary of the offer will be displayed and a
            final confirmation will be requested, after which the ticket will be put back for sale at the same price of
            the original ticket on our website. Every time a new customer will require a ticket of that kind, priority
            will be given to the tickets put back for sale.
          </p>

          <p>
            If the resale is successful, the seller will receive, within 15 working days, a bank transfer equal to the
            value of the single ticket resold net of commissions and service charge, which amounts to 10% of ticket
            price + presale. Our commission will be applied only if the resale is completed.
          </p>

          <p>
            <b>The resale function follows the same time rules as the sale</b>, so it will be available for the entire
            duration of the sale of ordinary tickets up to the opening of the event gates, when it will no longer be
            possible to resell your tickets or purchase tickets put back for sale by other customers.
          </p>

          <p>
            If you wish to cancel the resale of your ticket, you can do it by accessing the specific section from your
            profile where you will need to re-enter the data of the ticket (Name and Fiscal Seal). However, cancellation
            will only be possible until the ticket has been placed in the cart of a new customer.
          </p>

          <p>
            Please note that the original ticket <b> cannot be used </b> as long as it remains in the resale market,
            therefore, even in case of non-resale within the time limits, in order to use your ticket to access the
            event it will be necessary to activate the <b> resale cancellation</b> procedure.
          </p>
        </div>
      </div>
    </>
  )
}

export default ResaleEng
