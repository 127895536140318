import { RefObject, useCallback, useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

import { DOMs } from 'utils/doms'

export interface ISwiperEdge {
  node: HTMLDivElement
  idx: number
}
export interface ISwiperEdges {
  first?: ISwiperEdge
  last?: ISwiperEdge
}

const THRESHOLD = 100

const getElementProps = (
  element: HTMLDivElement,
  includesMargin: boolean = true
): { width: number; scrollLeft: number; offsetLeft: number } => {
  const elementWidth = DOMs.getPropertyPxValue(element, 'width')
  const elementMarginLeft = DOMs.getPropertyPxValue(element, 'margin-left')
  const elementMarginRight = DOMs.getPropertyPxValue(element, 'margin-right')
  const { scrollLeft, offsetLeft } = element
  let width = elementWidth
  if (includesMargin) width = width + elementMarginLeft + elementMarginRight
  return { width, scrollLeft, offsetLeft }
}

const getFirstElement = (elements: Array<HTMLDivElement>, swiper: HTMLDivElement): ISwiperEdge | undefined => {
  const elementsReverse = [...elements].reverse()
  const idxFirst = elementsReverse.findIndex((element) => {
    const elementProps = getElementProps(element)
    const swiperProps = getElementProps(swiper)
    return swiperProps.scrollLeft >= elementProps.offsetLeft + THRESHOLD
  })
  return idxFirst >= 0 ? { node: elementsReverse[idxFirst], idx: elementsReverse.length - idxFirst - 1 } : undefined
}

const getLastElement = (elements: Array<HTMLDivElement>, swiper: HTMLDivElement): ISwiperEdge | undefined => {
  const idxLast = elements.findIndex((element: HTMLDivElement) => {
    const elementProps = getElementProps(element)
    const swiperProps = getElementProps(swiper)

    return elementProps.offsetLeft + elementProps.width - THRESHOLD >= swiperProps.width + swiperProps.scrollLeft
  })
  return idxLast > 0 ? { node: elements[idxLast], idx: idxLast } : undefined
}

const getEdges = (swiper: HTMLDivElement): ISwiperEdges => {
  let elements: Array<HTMLDivElement> = []

  if (swiper) {
    elements = Array.from(swiper.children).filter(
      (element) => getElementProps(element as HTMLDivElement, false).width > 0
    ) as Array<HTMLDivElement>
  }

  const first = getFirstElement(elements, swiper)
  const last = getLastElement(elements, swiper)
  return { first, last }
}

const useSwiperScroll = (swiperRef: RefObject<HTMLDivElement>): ISwiperEdges => {
  const [offsets, setOffsets] = useState<ISwiperEdges>({ first: undefined, last: undefined })

  const onScroll = useCallback(
    throttle(() => {
      if (swiperRef.current) setOffsets(getEdges(swiperRef.current))
    }, 200),
    []
  )

  useEffect(() => {
    setTimeout(() => {
      if (swiperRef.current) setOffsets(getEdges(swiperRef.current))
    }, 1000)

    swiperRef?.current?.addEventListener('scroll', onScroll)

    return () => {
      swiperRef.current && swiperRef.current.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, swiperRef])
  return offsets
}

export default useSwiperScroll
