import { AppEnv } from 'meta/app'
import { SliceSelector } from 'store/types'

import { defaultEnv } from './defaultEnv'

const getEnv: SliceSelector<AppEnv> = () => (state) => state.app?.env ?? defaultEnv

const isInitialized: SliceSelector<boolean> = () => (state) => !!state.app.env

export const AppSelectors = {
  getEnv,
  isInitialized,
}
