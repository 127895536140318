import css from './TicketsSummary.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useCheckoutTicketSeats } from 'hooks/useCheckout'

import TicketSummary from './TicketSummary'

interface IProps {
  id: string
  summaryOpened: boolean
  toggleSummary(): void
}

const TicketsSummary: React.FC<IProps> = (props) => {
  const { id, summaryOpened, toggleSummary } = props

  const { t } = useTranslation()
  const ticketSeats = useCheckoutTicketSeats()

  return (
    <div className={classNames(css.ticketsSummaryContainer, { [css.summaryClosed]: !summaryOpened })}>
      <div className={css.ticketsSummaryHeader}>
        <div className={css.ticketsSummaryHeader__title}>
          <div className={css.seats}>
            <Img src="/checkout/map_ticket_black.svg" />
            <h3>
              {ticketSeats.length > 0
                ? t('checkout:tickets_selected', { count: ticketSeats.length })
                : t('checkout:no_tickets_selected')}
            </h3>
          </div>
          <div className={css.rate}>{t('checkout:select_rate')}</div>
        </div>

        {ticketSeats.length > 0 && (
          <ButtonTransparent
            className={css.ticketsSummaryHeader__btnToggle}
            format={ActionFormat.circle}
            onClick={toggleSummary}
          >
            <Img alt="" className={css.imgToggle} src="/checkout/ticket_summary_close.svg" />
            <Img alt="" className={css.imgCloseLaptop} src="/checkout/ticket_summary_open.svg" />
          </ButtonTransparent>
        )}
      </div>

      <div
        className={classNames(css.ticketsSummaryTickets, {
          [css.closed]: ticketSeats.length <= 0 || !summaryOpened,
        })}
      >
        {[...ticketSeats].reverse().map((_, idx) => (
          <TicketSummary key={String(idx)} id={id} idx={ticketSeats.length - 1 - idx} summaryOpened={summaryOpened} />
        ))}

        {ticketSeats.length > 1 && (
          <ButtonTransparent
            className={css.ticketsSummaryCloseMobile}
            format={ActionFormat.circle}
            onClick={toggleSummary}
          >
            <Img alt="" src="/checkout/ticket_summary_close_mobile.svg" />
          </ButtonTransparent>
        )}
      </div>
    </div>
  )
}

export default TicketsSummary
