import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Location } from '@remix-run/router'

export const useLocationEffect = (callback: (location?: Location) => any) => {
  const location = useLocation()

  useEffect(() => {
    callback(location)
  }, [location, callback])
}
