import css from './FastCheckoutBox.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import FormFastCheckout from 'components/Checkout/Steps/Auth/FastCheckoutBox/Form'
import ImageBox from 'components/Checkout/Steps/Auth/FastCheckoutBox/Image'
import { IFormOnSuccess } from 'components/Forms/Form'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

interface IProps {
  balloonColor?: ComponentColor
  tipPosition?: BalloonTipPosition
  onSuccess: IFormOnSuccess
}

const FastCheckoutBox: React.FC<IProps> = (props) => {
  const { balloonColor = ComponentColor.black, tipPosition = BalloonTipPosition.left, onSuccess } = props

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_fastcheckout_container}>
            <BalloonWrapper
              color={balloonColor}
              size={BalloonSize.small}
              tipPosition={tipPosition}
              removePaddingOutside
            >
              <div className={css.content}>
                <FormFastCheckout onSuccess={onSuccess} />
                <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
                  <ImageBox />
                </MediaQuery>
              </div>
            </BalloonWrapper>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default FastCheckoutBox
