import css from './Terms.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCheckoutExtraDetails } from 'hooks/useCheckout'

interface IProps {
  handleExtraTermsAndConditions(): void
}

const Terms: React.FC<IProps> = (props) => {
  const { handleExtraTermsAndConditions } = props

  const checkoutExtraDetails = useCheckoutExtraDetails()

  const { t } = useTranslation()

  return (
    <div>
      <div className={css.checkout_recapbox_mobile_terms_container}>
        <a href="/terms">
          <div className={css.terms}>{t('checkout:clicking_terms_and_condition')}</div>
        </a>
      </div>

      {checkoutExtraDetails && checkoutExtraDetails.additionalTermsAndConditions && (
        <div className={css.checkout_recapbox_mobile_terms_container}>
          <div className={css.termsWrapper}>
            <div className={css.additionalTerms}>
              {checkoutExtraDetails.additionalTermsAndConditions.isRequired && (
                <input onClick={(event) => handleExtraTermsAndConditions()} type={'checkbox'}></input>
              )}
              {checkoutExtraDetails.additionalTermsAndConditions.title &&
                checkoutExtraDetails.additionalTermsAndConditions.url && (
                  <a href={checkoutExtraDetails.additionalTermsAndConditions.url}>
                    <span className={css.title}>{checkoutExtraDetails.additionalTermsAndConditions.title}</span>
                  </a>
                )}
            </div>

            <div className={css.additionalTermsDescription}>
              {checkoutExtraDetails.additionalTermsAndConditions.description && (
                <span className={css.description}>{checkoutExtraDetails.additionalTermsAndConditions.description}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Terms
