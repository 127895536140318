import css from './SearchForm.module.scss'
import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Breakpoints } from 'meta/layout/breakpoints'
import { SearchParamUtils } from 'meta/pages/search'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import { ButtonType, ButtonWhite } from 'components/Actions/Action'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import SearchFilters from 'components/Pages/Search/SearchForm/SearchFilters'
import { useOnUpdate } from 'hooks/useOnUpdate'
import { useSearchParams } from 'hooks/useSearch'

const SearchForm: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()
  const { namespacesLoaded } = useI18NContext()
  const datesString = searchParams.dates && SearchParamUtils.datesToString(searchParams, t('dates:common:yearMonthDay'))

  const onSubmit = (event?: SyntheticEvent) => {
    event && event.preventDefault()
    navigate(SearchParamUtils.toUrl(searchParams))
  }

  useOnUpdate(() => {
    onSubmit(undefined)
  }, [searchParams.city, searchParams.eventCategory, datesString])

  return (
    <form className={css.searchFormContainer} onSubmit={onSubmit}>
      <GridContainer>
        <Grid className={css.searchForm}>
          <GridColumn gridColumnStart={1} gridColumnEnd={17}>
            <WithAppInitialized showChildren={namespacesLoaded}>{t('search:search_results')}</WithAppInitialized>
          </GridColumn>

          <GridColumn
            gridColumnStart={{ [Breakpoints.mobile]: 3, [Breakpoints.laptop]: 5 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 15, [Breakpoints.laptop]: 13 }}
          >
            <WithAppInitialized showChildren={namespacesLoaded}>
              <input
                className={css.searchFormInput}
                value={searchParams.query}
                onChange={(event) => {
                  const params = { ...searchParams, query: event.target.value }
                  dispatch(SearchActions.updateParams(params))
                }}
              />
            </WithAppInitialized>
          </GridColumn>

          <GridColumn
            className={css.searchFormButtonContainer}
            gridColumnStart={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 8 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 10 }}
          >
            <WithAppInitialized showChildren={namespacesLoaded}>
              <ButtonWhite type={ButtonType.submit}>
                <strong>{t('search:search_again')}</strong>
              </ButtonWhite>
            </WithAppInitialized>
          </GridColumn>

          <SearchFilters />
        </Grid>
      </GridContainer>
    </form>
  )
}

export default SearchForm
