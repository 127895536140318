import parsePhoneNumber from 'libphonenumber-js'

import { getValidPhonePrefixRegex } from 'utils/regex'

import { FormFieldType, IFormField, IFormFieldValidation } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

const DEFAULT_PREFIX = '+39'

export interface IFormFieldPhone extends IFormField<FormFieldType.phone> {
  namePrefix?: string
  valuePrefix?: string
  validationPrefix?: IFormFieldValidation
  disable?: boolean
}

export class PhoneBuilder extends FormFieldBuilder<FormFieldType.phone> {
  namePrefix: string
  namePrefixValidation: string

  constructor(name: string, namePrefix?: string, nameValidation?: string, namePrefixValidation?: string) {
    super(name, FormFieldType.phone, nameValidation)
    this.namePrefix = namePrefix || 'prefix'
    super.addField('namePrefix', this.namePrefix)
    this.addValuePrefix(DEFAULT_PREFIX)
    this.namePrefixValidation = namePrefixValidation || this.namePrefix
  }

  addRequiredValidation(): FormFieldBuilder<FormFieldType.phone> {
    const validation: IFormFieldValidation = {
      required: `form:errors_required_${this.nameValidation}`,
      validate: {
        message: `form:errors_invalid_${this.nameValidation}`,
        test: (value) => {
          const { value: valuePrefix } = document.getElementById(this.namePrefix) as HTMLSelectElement

          if (value.includes('-')) {
            return false
          }

          const valuePhone = `${valuePrefix}${value}`
          const phoneNumber = parsePhoneNumber(valuePhone)

          return Boolean(phoneNumber && phoneNumber.isValid())
        },
      },
    }
    const validationPrefix = {
      required: `form:errors_required_${this.namePrefixValidation}`,
      pattern: {
        value: getValidPhonePrefixRegex(),
        message: `form:errors_invalid_${this.namePrefixValidation}`,
      },
    }

    super.addValidation(validation)
    return super.addField('validationPrefix', validationPrefix)
  }

  //@ts-ignore
  addValuePrefix(value): FormFieldBuilder<FormFieldType.phone> {
    return super.addField('valuePrefix', value)
  }
}
