import css from 'components/Layout/Layout.module.scss'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Routes } from 'meta/routes'

const bgColors: Record<string, string> = {
  [Routes.Faq.parts[0]]: 'white',
  [Routes.LocationPhp.parts[0]]: 'white',
  [Routes.ProfileChangeData.parts[0]]: 'white',
  [Routes.ProfileChangePassword.parts[0]]: 'white',
  [Routes.ProfileChangeTicketName.parts[0]]: 'white',
  [Routes.ProfileCreditCards.parts[0]]: 'white',
  [Routes.ProfileResale.parts[0]]: 'white',
  [Routes.ProfileTransactions.parts[0]]: 'white',
  [Routes.ProfileUndoResale.parts[0]]: 'white',
  [Routes.Refund.parts[0]]: 'white',
  [Routes.Resale.parts[0]]: 'white',
  [Routes.Success.parts[0]]: 'black',
  [Routes.SupportConversation.parts[0]]: 'white',
  [Routes.SupportForm.parts[0]]: 'white',
  [Routes.Terms.parts[0]]: 'white',
  [Routes.Wallet.parts[0]]: 'white',
}

type Returned = { className: string }

export const useClassName = (): Returned => {
  const { pathname } = useLocation()

  return useMemo<Returned>(() => {
    const bgColor = bgColors[pathname.split('/')[1]]
    return { className: classNames(css.layout, bgColor && css[bgColor]) }
  }, [pathname])
}
