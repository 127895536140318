export enum ComponentType {
  asset = 'asset',
  assetSkeleton = 'assetSkeleton',
  assetSource = 'assetSource',
  artist = 'artist',
  balloon = 'balloon',
  banner = 'banner',
  blog = 'blog',
  blogPost = 'blogPost',
  button = 'button',
  category = 'category',
  categorySkeleton = 'categorySkeleton',
  city = 'city',
  eventDetails = 'eventDetails',
  eventDetailsSkeleton = 'eventDetailsSkeleton',
  eventCollectionMenu = 'eventCollectionMenu',
  faq = 'faq',
  eventInfo = 'eventInfo',
  eventInfoSkeleton = 'eventInfoSkeleton',
  eventTicket = 'eventTicket',
  infoBox = 'infoBox',
  horizontalScroll = 'horizontalScroll',
  map = 'map',
  menu = 'menu',
  menuSkeleton = 'menuSkeleton',
  menuItem = 'menuItem',
  parentSquare = 'parentSquare',
  square = 'square',
  squareSkeleton = 'squareSkeleton',
  story = 'story',
  storySkeleton = 'storySkeleton',
  ticket = 'ticket',
  title = 'title',
  venueAttributes = 'venueAttributes',
  venueDetails = 'venueDetails',
  venueDetailsSkeleton = 'venueDetailsSkeleton',
  venueInfo = 'venueInfo',
  venueSquare = 'venueSquare',
}

export enum ComponentColor {
  black = 'black',
  white = 'white',
  grey = 'grey',
  yellow = 'yellow',
  red = 'red',
  redLight = 'red_light',
  blue = 'blue',
}

export enum ComponentSize {
  s = 's',
  m = 'm',
  l = 'l',
}

export enum CallbackType {
  buyTickets = 'buyTickets',
  openCheckout = 'openCheckout',
}

export type ComponentCallback = {
  [key in CallbackType]?: () => void
}

export interface IComponent<T extends ComponentType> {
  componentType: T
  callback?: ComponentCallback
  insideBalloon?: boolean
  insideVerticalScroll?: boolean
  onClick?: () => void
  title?: string
  uuid?: string
}

export interface IComponentNested<T extends ComponentType> extends IComponent<T> {
  list: Array<IComponent<ComponentType>>
}
