import css from './LanguageSelector.module.scss'
import React, { useState } from 'react'

import { Languages } from 'meta/app/translate'
import DataTestId from 'meta/dataTestId'
import { Breakpoints } from 'meta/layout/breakpoints'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import { GridColumn } from 'components/Layout/Grid'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

import ModalSelector from './ModalSelector'

const LanguageSelector: React.FC = () => {
  const locale = useCurrentLanguage()
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 10, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
      className={css.languageSelector}
    >
      <ButtonWhiteBlack
        className={css.languageSelector__button}
        data-testid={DataTestId.translation.langSelectButton}
        data-value={Languages[locale].iso2}
        onClick={() => setShowModal(true)}
      >
        <strong className={css.label}>{Languages[locale].label}</strong>
        <strong className={css.shortLabel}>{Languages[locale].shortLabel}</strong>
      </ButtonWhiteBlack>

      {showModal && <ModalSelector onClose={() => setShowModal(false)} />}
    </GridColumn>
  )
}

export default LanguageSelector
