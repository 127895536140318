import css from './Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

const HeaderSkeleton: React.FC = () => {
  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 12 }}>
      <div className={css.headerContainer}>
        <div className={css.location_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'20%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.title_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'30%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.tags_skeleton}>
          <Skeleton
            className={css.tag}
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'24px'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
          <Skeleton
            className={css.tag}
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'24px'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
          <Skeleton
            className={css.tag}
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'24px'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>
    </GridColumn>
  )
}

export default HeaderSkeleton
