import {
  FormFieldType,
  IFormField,
  InputDateBuilder,
  LinkedDataListBuilder,
  PhoneBuilder,
  SelectBuilder,
  TextBuilder,
} from 'meta/form'

import { getCitiesData, getCountriesData } from 'components/Forms/Form/FormField/LinkedDataList/utils'
import { PersonalDataField } from 'components/Pages/PurchaseForm/Tickets/types'

export const getFirstName = (fieldName: string): IFormField<FormFieldType.text> => {
  return new TextBuilder(fieldName, 'firstName').addRequiredNameValidation().addPlaceholder('form:firstName').build()
}

export const getLastName = (fieldName: string): IFormField<FormFieldType.text> => {
  return new TextBuilder(fieldName, 'lastName').addRequiredNameValidation().addPlaceholder('form:lastName').build()
}

export const getPhone = (phoneFieldName: string, phonePrefixFieldName: string): IFormField<FormFieldType.phone> => {
  return new PhoneBuilder(phoneFieldName, phonePrefixFieldName, 'phone', 'phonePrefix')
    .addRequiredValidation()
    .addPlaceholder('form:phone')
    .build()
}

export const getBirthDate = (fieldName: string): IFormField<FormFieldType.date> => {
  return new InputDateBuilder(fieldName)
    .addRequiredBeforeNowValidation()
    .removeFutureDates()
    .addPlaceholder('form:birthDate')
    .build()
}

export const getGender = (fieldName: string): IFormField<FormFieldType.select> => {
  return new SelectBuilder(
    fieldName,
    [
      {
        value: 'male',
        label: 'form:gender_male',
      },
      {
        value: 'female',
        label: 'form:gender_female',
      },
    ],
    true,
    PersonalDataField.gender
  )
    .addRequiredValidation()
    .addPlaceholder('form:gender')
    .build()
}

export const getBirthplaceCountry = (fieldName: string, defaultValue?: string): IFormField<FormFieldType.linked> => {
  let options = {
    linkIdentifier: PersonalDataField.birthplaceCountry,
    defaultValue: defaultValue,
  }

  if (defaultValue) options.defaultValue = defaultValue

  return new LinkedDataListBuilder(fieldName, getCountriesData, options, PersonalDataField.birthplaceCountry)
    .addRequiredValidation()
    .addPlaceholder('form:birthplaceCountry')
    .build()
}

export const getBirthplaceCity = (fieldName: string, defaultValue?: string): IFormField<FormFieldType.linked> => {
  let options = {
    linkIdentifier: PersonalDataField.birthplaceCity,
    defaultValue: defaultValue,
  }

  if (defaultValue) options.defaultValue = defaultValue

  return new LinkedDataListBuilder(fieldName, getCitiesData, options, PersonalDataField.birthplaceCity)
    .addRequiredValidation()
    .addPlaceholder('form:birthplaceCity')
    .build()
}
