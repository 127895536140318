import css from 'components/Pages/SupportConversation/ConversationMessageForm/ConversationMessageForm.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormBuilder, HiddenBuilder, TextAreaBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'

import Form, { IFormValues } from 'components/Forms/Form'

type Props = {
  codeRequest: string
  onMessageSent: (text: string) => void
}

const ConversationMessageForm: React.FC<Props> = (props) => {
  const { codeRequest, onMessageSent } = props
  const { t } = useTranslation()

  const formBuilder = new FormBuilder()
    .addField(new HiddenBuilder('codeRequest').addValue(codeRequest).addRequiredValidation().build())
    .addField(new TextAreaBuilder('message').addRows(10).addRequiredValidation().build())

  const onSuccess = (values?: IFormValues) => values && onMessageSent(values.message)

  return (
    <div className={css.messageFormWrapper}>
      <div className={css.hr} />
      <div className={css.writeMessage}>{t('support-conversation:writeMessage')}</div>
      <Form
        action={ApiEndpoint.support.conversation()}
        formFields={formBuilder.build()}
        onSuccess={onSuccess}
        recaptcha={{ action: 'shop/support/conversation' }}
      />
    </div>
  )
}

export default ConversationMessageForm
