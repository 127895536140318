import css from 'components/Pages/ProfileTransactions/Transaction/Header/Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

export const HeaderSkeletonMobile: React.FC = () => {
  return (
    <div className={classNames(css.auth_profile_sections_transactions_transaction_header_container)}>
      <Grid className={css.grid__skeleton}>
        <GridColumn
          gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 15, [Breakpoints.tablet]: 9 }}
        >
          <div className={classNames(css.black_area)}>
            <div className={classNames(css.voice__container__skeleton)}>
              <div className={classNames(css.voice__wrapper__skeleton)}>
                <div className={classNames(css.voice__skeleton__left__mobile)}>
                  <Skeleton
                    baseColor={TICKETSMS_COLORS_LIGHTGREY}
                    borderRadius={16}
                    height={'100%'}
                    width={'80%'}
                    highlightColor={TICKETSMS_COLORS_WHITE}
                  />
                </div>
              </div>
              <div className={classNames(css.voice__wrapper__skeleton)}>
                <div
                  className={classNames(
                    css.voice__skeleton__right__mobile,
                    Breakpoints.tablet && css.voice__skeleton__right__tablet
                  )}
                >
                  <Skeleton
                    baseColor={TICKETSMS_COLORS_LIGHTGREY}
                    borderRadius={16}
                    height={'100%'}
                    width={'90%'}
                    highlightColor={TICKETSMS_COLORS_WHITE}
                  />
                </div>
              </div>
            </div>
          </div>
        </GridColumn>
        <GridColumn
          gridColumnStart={{ [Breakpoints.mobile]: 11, [Breakpoints.tablet]: 5 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 9 }}
        >
          <div className={classNames(css.tip_container, css.tip__skeleton)}>
            <Img className={css.img__skeleton} src="/profile/transaction_tip_white.svg" />
          </div>

          <div className={css.white_area}></div>
        </GridColumn>
      </Grid>
    </div>
  )
}
