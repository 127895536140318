import css from './Menu.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { MenuSkeleton as MenuSkeletonType } from 'meta/backendControlled/structure'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { Grid, GridContainer, GridType } from 'components/Layout/Grid'

export const MenuSkeleton: React.FC<IPropsComponent<MenuSkeletonType>> = () => {
  return (
    <GridContainer>
      <Grid type={GridType.flex} className={classNames(css.menu, css.insideBalloon)}>
        {[0, 1, 2, 3, 4].map((idx) => {
          return (
            <div key={`menuItem-${idx}`} className={classNames(css.menuItem, css.skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={6}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          )
        })}
      </Grid>
    </GridContainer>
  )
}
