import { ICheckoutProcessData, ICheckoutProcessMetadata } from 'meta/pages/checkout'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Params = {
  paymentGateway: string
  paymentGatewayData: Record<string, string>
  transactionCode: string
}

type Returned = {
  data: ICheckoutProcessData
  metadata: ICheckoutProcessMetadata
}

export const process = async (params: Params): Promise<Returned> => {
  const { data, metadata } = await HttpRequest.post<ICheckoutProcessData, Params, ICheckoutProcessMetadata>(
    ApiEndpoint.checkout.process(),
    params
  )

  return { data, metadata: metadata as ICheckoutProcessMetadata }
}
