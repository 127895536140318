export const TICKETSMS_COLORS_RED = '#ea0a2a'
export const TICKETSMS_COLORS_RED_LIGHT = '#feeaea'
export const TICKETSMS_COLORS_BLACK = '#2d2a26'
export const TICKETSMS_COLORS_TICKET_BLACK = '#393836'
export const TICKETSMS_COLORS_LIGHTBLACK = '#3b3b3b'
export const TICKETSMS_COLORS_WHITE = '#FFFFFF'
export const TICKETSMS_COLORS_LIGHTGREY = '#f2f2f2'
export const TICKETSMS_COLORS_MEDIUMGREY = '#c9c5c0'
export const TICKETSMS_COLORS_DARKGREY = '#969696'
export const TICKETSMS_COLORS_GREY = '#f5f5f5'
export const TICKETSMS_COLORS_ORANGE = '#ff7a00'
export const TICKETSMS_COLORS_CYAN = '#00b8e0'
export const TICKETSMS_COLORS_FUCSIA = '#ff3d70'
export const TICKETSMS_COLORS_PURPLE = '#8643eb'
export const TICKETSMS_COLORS_BLUE = '#1B8AF9'
