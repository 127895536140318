import css from '../Grid/Grid.module.scss'
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'

export enum GridColumnFraction {
  oneSixth = 'oneSixth',
  oneFourth = 'oneFourth',
  oneThird = 'oneThird',
}

type GridColumnOffset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | GridColumnFraction

type IGridColumnBreakpoints = {
  [key in Breakpoints]?: GridColumnOffset
}

interface IPropsGridColumn extends RefAttributes<HTMLDivElement>, React.HTMLAttributes<HTMLDivElement> {
  // Grid column properties
  gridColumnStart?: GridColumnOffset | IGridColumnBreakpoints
  gridColumnEnd?: GridColumnOffset | IGridColumnBreakpoints
  gridRowStart?: GridColumnOffset | IGridColumnBreakpoints
  gridRowEnd?: GridColumnOffset | IGridColumnBreakpoints
  // Flex column properties
  gridColumnSpan?: GridColumnOffset | IGridColumnBreakpoints
}

const getPositionClass = (position?: GridColumnOffset | IGridColumnBreakpoints, classNamePrefix?: string) => {
  if (!position) return []

  return ['number', 'string'].includes(typeof position)
    ? [css[`${classNamePrefix}_${Breakpoints.mobile}_${position}`]]
    : Object.entries(position).map(([key, value]) => css[`${classNamePrefix}_${key}_${value}`])
}

export const GridColumn: ForwardRefExoticComponent<IPropsGridColumn> = forwardRef((props, ref) => {
  const {
    className: classNameProps,
    gridColumnEnd,
    gridColumnSpan,
    gridColumnStart,
    gridRowEnd,
    gridRowStart,
    children,
    ...rest
  } = props

  const gridColumnStartClass = getPositionClass(gridColumnStart, 'gridColumnStart')
  const gridColumnEndClass = getPositionClass(gridColumnEnd, 'gridColumnEnd')
  const gridRowStartClass = getPositionClass(gridRowStart, 'gridRowStart')
  const gridRowEndClass = getPositionClass(gridRowEnd, 'gridRowEnd')
  const gridColumnSpanClass = getPositionClass(gridColumnSpan, 'gridColumnSpan')

  const className = classNames(
    css.gridCol,
    ...gridColumnStartClass,
    ...gridColumnEndClass,
    ...gridRowStartClass,
    ...gridRowEndClass,
    ...gridColumnSpanClass,
    classNameProps
  )
  return (
    <div className={className} ref={ref} {...rest}>
      {React.Children.toArray(children)}
    </div>
  )
})

GridColumn.defaultProps = {
  gridColumnStart: 1,
}
