import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Routes } from 'meta/routes'

export const useIsInsideProfileRoute = (): boolean => {
  const { pathname } = useLocation()
  const level1 = pathname.split('/')[1]
  return useMemo<boolean>(() => level1 === Routes.Profile.path || level1 === Routes.Wallet.path, [pathname])
}
