import CryptoJS from 'crypto-js'

import { ENCRYPTION_PASSPHRASE } from './constants/auth'

export const getEncodedPath = (word: string, key = ENCRYPTION_PASSPHRASE) => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}

export const getDecodedPath = (word: string, key = ENCRYPTION_PASSPHRASE) => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
  return JSON.parse(bytes)
}
