import css from './Es.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

const ResaleEs: React.FC = () => {
  return (
    <div className={css.resale_es_container}>
      <div className={css.static_page_title}>
        <Img src="/resale/resale.svg" />
        <div>Reventa</div>
      </div>
      <div className={css.static_page_text}>
        <p>
          Las entradas compradas a través de TicketSms son nominativas, en concreto, los espectáculos de Los grandes
          espectáculos con más de 5000 espectadores requieren, según el{' '}
          <a
            target="_blank"
            href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
            rel="noreferrer"
          >
            nueva legislación
          </a>
          , la obligación de <b>nombrar</b> y comprobar la identidad del titular del billete en el momento de la
          entrada. Por este motivo, también se exigirá un documento de identidad válido en el momento de la entrada a
          los eventos en cuestión, para que el personal pueda comprobar la identidad del titular de la entrada. también
          se exigirá un documento de identidad válido para que el personal encargado de los controles para verificar que
          el titular de la entrada es el que ha entrado con esa entrada específica. En caso de En caso de no coincidir,
          no podrás acceder al evento sin derecho a reembolso. En el sitio, dentro de la página del evento, se indicará
          si el evento está sujeto a entrada nominal y, por tanto, será por lo que será <b>obligatorio</b> presentarse
          al evento con un documento válido para poder entrar.
        </p>

        <p>
          Si no puedes asistir a un evento, se puede activar el procedimiento de reventa de la entrada. En caso de que
          no puedas asistir a un evento, el procedimiento de reventa de la entrada que tienes o de las entradas que has
          comprado (incluso una un solo billete en caso de compras múltiples), accediendo a la sección correspondiente
          desde el perfil del usuario perfil o del resumen de la transacción.
        </p>

        <p>
          <b>Para volver a poner la entrada a la venta</b> será necesario indicar el Nombre, el Apellido y el sello
          fiscal en el billete original que estás comprando o teniendo. (toda esta información se indica en el propio
          billete). Además, debes Además, tendrás que indicar un IBAN al que se hará una transferencia en caso de
          reventa.
        </p>

        <p>
          Una vez verificados los datos y el derecho a proceder se mostrará un resumen de la oferta y un resumen de la
          oferta y se te pedirá una confirmación final,tras la cual el billete se volverá a poner a la venta al mismo
          precio del billete original en el momento de la compra a la venta al mismo precio que el billete original
          dentro del propio TicketSms. Cuando un nuevo comprador desee adquirir un billete del mismo tipo que un billete
          que se ha vuelto a poner a la venta, se dará prioridad a este último. Cuando un nuevo comprador desee adquirir
          un billete del mismo tipo que un billete reemitido, se dará prioridad a este último sobre los billetes
          estándar.
        </p>

        <p>
          Si la reventa tiene éxito, el vendedor recibirá, en un plazo de 15 días laborables, un 15 días hábiles, una
          transferencia igual al valor del billete único revendido, neto de comisiones y gastos de servicio de servicio,
          que asciende a 1,50 euros. No está prevista ninguna comisión o cuota de inserción, el La comisión se aplica
          sólo cuando se completa la revenda está previsto, el La comisión sólo se cobrará una vez que se haya
          completado la reventa.
        </p>

        <p>
          a función de reventa sigue a la función{' '}
          <b>Las mismas reglas de tiempo que la venta clásica hasta el inicio del evento</b>, por lo que será disponible
          mientras dure la venta ordinaria de entradas hasta la apertura de las puertas del evento, cuando el apertura
          de las puertas del evento, cuando ya no podrás revender tus entradas o comprar entradas puestas a la venta por
          otros usuarios.
        </p>

        <p>
          Si quieres anular la reventa de una de tus entradas, sólo tienes que acceder al en el menú "Volver a poner en
          venta" de tu perfil, donde tendrás que introduce de nuevo los datos del billete (nombre y sello fiscal). Sin
          embargo, la anulación sólo será posible hasta que el billete en cuestión no insertado en el carrito de un
          nuevo comprador.
        </p>

        <p>
          Ten en cuenta que el billete de entrada original <b>no puede utilizarse</b> mientras permanezca en el mercado
          de reventa, por lo que aunque no revendas tu entrada en el plazo establecido, no podrás utilizarla para
          acceder al Por lo tanto, aunque no se revenda dentro del plazo, para poder utilizar tu entrada para acceder al
          evento deberás aplicar el procedimiento de <b>anulación de la reventa</b>.
        </p>

        <p>
          Ten en cuenta que <b>no puedes revender las entradas compradas con 18APP</b>.
        </p>
      </div>
    </div>
  )
}

export default ResaleEs
