import css from './AvatarBox.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const AvatarBoxSkeleton: React.FC = () => {
  return (
    <div className={css.auth_profile_menu_header_avatarbox_container}>
      <div className={css.img__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={200}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
      <div className={css.hello__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
    </div>
  )
}
