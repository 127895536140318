import css from './Desktop.module.scss'
import React, { PropsWithChildren } from 'react'

import { VoiceSkeleton } from 'components/PageWithSubsections/Voices/Voice'

interface IProps {
  voices: string[]
}

export const DesktopSkeleton: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { voices, children } = props

  return (
    <div className={css.pagewithsubsections_voices_desktop_container}>
      {voices.map((elem, key) => {
        return <VoiceSkeleton key={key} />
      })}

      {children}

      <div className={css.spacing_bottom}></div>
    </div>
  )
}
