import css from './UserTicket.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IWalletEvent, IWalletSeasonTicket, IWalletUserTicket } from 'meta/pages/wallet'
import { CookieClient, CookieName } from 'utils/cookie'

import { ButtonWhiteRed } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useTicketStatus } from 'components/Pages/Wallet/hooks/useTicketStatus'
import { useFunUrl, useUrlCloudfront } from 'hooks/useEnv'

type Props = {
  event: IWalletEvent
  idx: number
  userTicket: IWalletUserTicket & IWalletSeasonTicket
}

const UserTicket: React.FC<Props> = (props) => {
  const { event, idx, userTicket } = props
  const { t } = useTranslation()
  const urlCloudfront = useUrlCloudfront()
  const funUrl = useFunUrl()

  const status = useTicketStatus({ userTicket })

  const onPrint = async () => {
    const authToken = CookieClient.get(CookieName.TOKEN)
    const printUrl = `${funUrl}/pdf/index.php?e=${event.id}&s=${userTicket.secret}&token=${authToken}`
    window.open(printUrl, '_blank')
  }

  return (
    <div className={css.userTicket}>
      <div
        className={classNames(css.userTicket__oneRow, css.userTicket__count)}
        style={{ backgroundImage: `url(${urlCloudfront}/images/wallet/ticket_red_h.svg)` }}
      >
        <h4>{idx + 1}</h4>
      </div>
      <div>
        <h4 className={css.userTicket__infoTitle}>{t('wallet:name')}:</h4>
        <h4>{userTicket.buyerName ? userTicket.buyerName : '-'}</h4>
      </div>
      <div>
        <h4 className={css.userTicket__infoTitle}>{t('wallet:fiscalSeal')}:</h4>
        <h4>{userTicket.fiscalSeal ? userTicket.fiscalSeal : '-'}</h4>
      </div>
      {userTicket.secret ? (
        <ButtonWhiteRed className={classNames(css.userTicket__oneRow, css.userTicket__btnPrint)} onClick={onPrint}>
          <Img src={`/wallet/print.svg`} />
          <div>{t('wallet:print')}</div>
        </ButtonWhiteRed>
      ) : (
        <div className={classNames(css.userTicket__oneRow, css.userTicket__btnPrint)} />
      )}
      <div>
        <h4 className={classNames(css.userTicket__infoTitle, css.userTicket__infoRow)}>{t('wallet:ticketType')}:</h4>
        {!userTicket.seasonName && <h4>{userTicket.notes}</h4>}
        {userTicket.seasonName && <h4>{`${userTicket.notes} - ${userTicket.seasonName}`}</h4>}
      </div>
      <div>
        <h4 className={classNames(css.userTicket__infoTitle, css.userTicket__infoRow)}>{t('wallet:statusLabel')}:</h4>
        <h4>{status}</h4>
      </div>
      <Img className={css.userTicket__imgCorner} src="/wallet/ticket_corner_grey.svg" />
    </div>
  )
}

export default UserTicket
