import css from './SearchButtonMobile.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import Img from 'components/Media/Img'

type Props = {
  onClick: () => void
}

const SearchButtonMobile: React.FC<Props> = (props) => {
  const { onClick } = props

  const { t } = useTranslation()

  return (
    <div className={css.searchContainer}>
      <ButtonTransparent className={css.layoutHeaderBtnSearch} format={ActionFormat.circle} onClick={onClick}>
        <Img src="/icons/magnifier_grey.svg" />
      </ButtonTransparent>
      <div onClick={onClick} className={css.mobilePlaceholder}>
        {t('header:research')}
      </div>
    </div>
  )
}

export default SearchButtonMobile
