import css from 'components/BackendControlled/Component/Asset/Asset.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { AssetSkeleton as AssetSkeletonType } from 'meta/backendControlled/structure/asset'
import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'

export const AssetSkeleton: React.FC<IPropsComponent<AssetSkeletonType>> = (props) => {
  const { format, size } = props
  return (
    <GridColumn
      className={classNames(css.assetImageContainer, format && css[format], css[`size_${size}`], {})}
      gridColumnSpan={{ [Breakpoints.laptop]: 4, [Breakpoints.tablet]: 5, [Breakpoints.mobile]: 9 }}
    >
      <Skeleton
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={16}
        height={'100%'}
        highlightColor={TICKETSMS_COLORS_WHITE}
      />
    </GridColumn>
  )
}
