import React from 'react'

import Loader from 'components/Layout/Loader'
import { useCancelProcess } from 'components/Pages/CheckoutCancel/hooks/useCancelProcess'

const CheckoutCancel: React.FC = () => {
  useCancelProcess()

  return <Loader />
}

export default CheckoutCancel
