import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataDogLogType, LOGS_CHECKOUT_PROCESS_SUCCESS } from 'meta/logger'
import { QueryParams, Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { AuthActions } from 'store/auth'
import { DataDogLogger } from 'helpers/debug/logger'
import { CookieClient, CookieName } from 'utils/cookie'
import { Tokens } from 'utils/tokens'

import { useTrackProcess } from 'components/Pages/CheckoutProcess/hooks/useTrackProcess'
import { useIsAppInitialized } from 'hooks/useApp'
import { useCheckoutProcess } from 'hooks/useCheckout'
import { useQueryParams } from 'hooks/useQueryParams'

export const useHandleProcessComplete = () => {
  const appInitialized = useIsAppInitialized()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const checkoutProcess = useCheckoutProcess()
  const {
    authToken: authTokenFromQuery,
    paymentGateway,
    transactionCode,
  } = useQueryParams<QueryParams['CheckoutProcess']>()
  const trackProcess = useTrackProcess()
  const currentAuthToken = CookieClient.get(CookieName.TOKEN)

  useEffect(() => {
    if (!appInitialized || !checkoutProcess?.data || !checkoutProcess.data.cartItems) return

    const { data, metadata } = checkoutProcess

    trackProcess()

    // redirect after FBPixel Tracking
    let queryString = Routes.PurchaseForm.generatePath(undefined, {
      transactionCode,
      transactionToken: data?.transactionToken,
    })

    const token = String(authTokenFromQuery ?? metadata?.token)
    // workaround for iframe cookies not working properly
    if (Tokens.isValid(token) && !currentAuthToken) {
      dispatch(AuthActions.login({ token }))
      queryString += `&authToken=${token}`
    }

    DataDogLogger(LOGS_CHECKOUT_PROCESS_SUCCESS, DataDogLogType.info, {
      transactionCode,
      paymentGateway,
    })

    navigate(queryString)
  }, [
    appInitialized,
    authTokenFromQuery,
    checkoutProcess,
    currentAuthToken,
    dispatch,
    navigate,
    paymentGateway,
    trackProcess,
    transactionCode,
  ])
}
