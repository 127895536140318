import css from 'components/Pages/ProfileTransactions/Transaction/Transaction.module.scss'
import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'

import { ActionsSkeleton } from 'components/Pages/ProfileTransactions/Transaction/Actions'
import { HeaderSkeleton } from 'components/Pages/ProfileTransactions/Transaction/Header'
import { TransactionItemsSkeleton } from 'components/Pages/ProfileTransactions/Transaction/TransactionItems'

export const TransactionSkeleton: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className } = props
  return (
    <div className={classNames(css.transactionDataContainer, css.transactionDataContainer__skeleton, className)}>
      <HeaderSkeleton />

      <div className={classNames(css.transactionData, css.transactionData__skeleton)}>
        <TransactionItemsSkeleton />

        <ActionsSkeleton />
      </div>
    </div>
  )
}
