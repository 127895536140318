import { IProfile } from 'meta/pages/profile'

export type ProfileState = IProfile

export const initialState: ProfileState = {
  ticketChangeName: {
    data: {
      currentHolder: {
        firstName: '',
        lastName: '',
      },
      fiscalSeal: '',
    },
    step: 0,
  },
  ticketResaleData: {
    firstName: '',
    fiscalSeal: '',
    lastName: '',
  },
  transactionsData: {
    currentPage: -1,
    fetching: false,
    hasMorePages: true,
    transactions: [],
  },
}
