import css from '../Social.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'
import { addHttpsToUrlIfMissing } from 'utils/string'

import Img from 'components/Media/Img'

interface IPropsButtonInstagram {
  color: ComponentColor
  instagramLink?: string
}

export const ButtonInstagram: React.FC<IPropsButtonInstagram> = (props) => {
  const { color, instagramLink } = props

  if (!instagramLink) return null

  return (
    <a
      href={addHttpsToUrlIfMissing(instagramLink)}
      className={classNames(css.socialLink, css[color])}
      target="_blank"
      rel="noreferrer"
    >
      <Img alt="" src={`/social/instagram_${color}.svg`} />
    </a>
  )
}
