import css from './GoBack.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CheckoutStep } from 'meta/pages/checkout'

import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useCheckoutStep } from 'hooks/useCheckout'

interface Props {
  toggleContent(): void
}

const GoBack: React.FC<Props> = (props) => {
  const { toggleContent } = props

  const step = useCheckoutStep()

  if (step !== CheckoutStep.select) {
    return null
  }

  return (
    <Grid>
      <GridColumn
        gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.tablet]: 1, [Breakpoints.mobile]: 1 }}
        gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}
      >
        <div onClick={toggleContent} className={css.goBackContainer}>
          <Img className={css.icon} src={`/icons/goback_icon_white.svg`} />
        </div>
      </GridColumn>
    </Grid>
  )
}

export default GoBack
