import css from './Totals.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CurrencySymbol } from 'meta/currency'
import DataTestId from 'meta/dataTestId'
import { ICheckoutDetails, ICheckoutPayment, ICheckoutRecapAmount, PaymentMethod } from 'meta/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { formatCurrency } from 'utils/currency'

import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import {
  useCheckoutCartTickets,
  useCheckoutDetails,
  useCheckoutExtraDetails,
  useCheckoutPayment,
} from 'hooks/useCheckout'

interface IProps {
  subtotal: ICheckoutRecapAmount
  total: ICheckoutRecapAmount
  commission: ICheckoutRecapAmount
  continueCallback(): void
}

const Totals: React.FC<IProps> = (props) => {
  const { subtotal, total, commission, continueCallback } = props

  const { t } = useTranslation()
  const { methodCurrent, couponCode } = useCheckoutPayment() as ICheckoutPayment

  const cartTickets = useCheckoutCartTickets()
  const { eventTicket } = useCheckoutDetails() as ICheckoutDetails

  const checkoutExtraDetails = useCheckoutExtraDetails()

  const [freeTicketsOnly, setFreeTicketsOnly] = useState<boolean>(false)

  const [acceptExtraTermsAndConditions, setAcceptExtraTermsAndConditions] = useState<boolean>(false)

  const { activeCurrency } = useActiveCurrency()

  const eventCurrencyCode = getCodeFromSymbol(total.symbol as CurrencySymbol)

  const isCurrencyCodeLong = eventCurrencyCode.length > 1

  const isFreeEvent = !Boolean(eventTicket.data.find((x) => x.priceNumber > 0))

  if (!total.symbol) {
    debugger
  }

  const onlyFreeTicketsInCart = (): boolean => {
    let onlyFreeTickets = true

    cartTickets?.forEach((ticket) => {
      const price = eventTicket.data.find((x) => x.id == ticket.typeTicketId)?.priceNumber ?? 0

      if (price > 0) {
        onlyFreeTickets = false
      }
    })

    return onlyFreeTickets
  }

  const handleBuyAction = () => {
    if (
      checkoutExtraDetails &&
      checkoutExtraDetails.additionalTermsAndConditions &&
      checkoutExtraDetails.additionalTermsAndConditions.isRequired &&
      !acceptExtraTermsAndConditions
    ) {
      const alertMsg = String(t('checkout:accept_extra_terms_and_conditions'))

      alert(alertMsg)

      return
    }

    if ([PaymentMethod.voucher, PaymentMethod.diciottoApp].includes(methodCurrent as PaymentMethod) && !couponCode) {
      const alertMsg =
        methodCurrent == PaymentMethod.voucher
          ? String(t('checkout:insert_voucher_code_confirm'))
          : String(t('checkout:insert_18App_code'))

      alert(alertMsg)
    } else {
      continueCallback()
    }
  }

  useEffect(() => {
    setFreeTicketsOnly(Boolean(cartTickets && cartTickets.length > 0 && onlyFreeTicketsInCart()))
  }, [cartTickets?.length])

  return (
    <div
      className={classNames(css.checkout_recapbox_totals_container, {
        [css.longCC]: Boolean(isCurrencyCodeLong),
      })}
    >
      {checkoutExtraDetails && checkoutExtraDetails.additionalTermsAndConditions && (
        <div className={css.termsWrapper}>
          <a href="/terms">
            <div className={css.terms}>{t('checkout:clicking_terms_and_condition')}</div>
          </a>

          <div className={css.additionalTerms}>
            {checkoutExtraDetails.additionalTermsAndConditions.isRequired && (
              <input
                onClick={(event) => setAcceptExtraTermsAndConditions(!acceptExtraTermsAndConditions)}
                type={'checkbox'}
              ></input>
            )}
            {checkoutExtraDetails.additionalTermsAndConditions.title &&
              checkoutExtraDetails.additionalTermsAndConditions.url && (
                <a href={checkoutExtraDetails.additionalTermsAndConditions.url}>
                  <span className={css.title}>{checkoutExtraDetails.additionalTermsAndConditions.title}</span>
                </a>
              )}
          </div>

          <div className={css.additionalTermsDescription}>
            {checkoutExtraDetails.additionalTermsAndConditions.description && (
              <span className={css.description}>{checkoutExtraDetails.additionalTermsAndConditions.description}</span>
            )}
          </div>
        </div>
      )}

      {!checkoutExtraDetails && (
        <div className={css.termsWrapper}>
          <a href="/terms">
            <div className={css.terms}>{t('checkout:clicking_terms_and_condition')}</div>
          </a>
        </div>
      )}

      <div className={css.subtotal}>
        <div className={css.label}>{t('checkout:recap_subtotal')}</div>
        <div className={css.amount}>
          <h5 data-testid="checkout-recap-total">{`${convertAndFormat(
            subtotal.amount,
            eventCurrencyCode,
            activeCurrency
          )}`}</h5>
        </div>
      </div>

      <div className={css.commission}>
        <div className={css.label}>{t('checkout:recap_commissions')}</div>
        <h5 className={css.amount}>{`${convertAndFormat(commission.amount, eventCurrencyCode, activeCurrency)}`}</h5>
      </div>

      <div className={css.total}>
        <div className={css.balloon_container}>
          <Img src={'/tickets/ticket_quantity_selection_balloon.svg'} />
        </div>
        <div className={css.total_data}>
          <div className={css.label}>{t('checkout:recap_total')}</div>
          {!freeTicketsOnly && (
            <h3 className={css.amount}>{`${convertAndFormat(total.amount, eventCurrencyCode, activeCurrency)}`}</h3>
          )}
          {freeTicketsOnly && (
            <h3 className={css.amount}>{`${CurrencySymbol[activeCurrency]} ${formatCurrency(0)}`}</h3>
          )}
        </div>
      </div>
      <div
        className={classNames(css.total, css.buy)}
        data-testid={DataTestId.checkout.continueBtn}
        onClick={handleBuyAction}
      >
        <div className={css.balloon_container}>
          <Img src={'/checkout/checkout-mobile-buy-flip-large.svg'} />
        </div>
        <h3 className={css.text}>{isFreeEvent ? t('common:sign_up') : t('checkout:submit_label')}</h3>
      </div>
    </div>
  )
}

export default Totals
