import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'

import BalloonBoxModal from 'components/TextBoxes/BalloonBoxModal'

interface Props {
  messageKey: string
  paymentLabel: string
  onClose: () => void
}

const PaymentMethodDialogError: React.FC<Props> = (props) => {
  const { messageKey, paymentLabel, onClose } = props

  const { t } = useTranslation()

  return (
    <BalloonBoxModal
      bgColor={ComponentColor.redLight}
      onClose={onClose}
      infoType={'error'}
      title={`${t('common:error')}!`}
    >
      <div>{t(messageKey, { method: paymentLabel })}</div>
    </BalloonBoxModal>
  )
}

export default PaymentMethodDialogError
