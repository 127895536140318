import css from './CurrencySelector.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

export const CurrencySelectorSkeleton: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 11, [Breakpoints.laptop]: 11 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 8, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
      className={css.currencySelector}
    >
      <Skeleton
        className={css.btnSkeleton}
        baseColor={TICKETSMS_COLORS_WHITE}
        borderRadius={16}
        highlightColor={TICKETSMS_COLORS_LIGHTGREY}
      />
    </GridColumn>
  )
}
