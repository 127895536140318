import { useMemo } from 'react'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import { useStripeApiKey } from 'hooks/useEnv'

type Returned = PromiseLike<Stripe | null> | undefined

export const useLoadStripe = (): Returned => {
  const stripeApiKey = useStripeApiKey()

  return useMemo<Returned>(() => {
    if (stripeApiKey) {
      return loadStripe(stripeApiKey)
    }
    return undefined
  }, [stripeApiKey])
}
