import css from './Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'

const Header: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={css.faq_menu_header_container}>
      <div className={css.faq_header}>
        <div className={css.icon}>
          <Img src="/faq/icon.svg" />
        </div>
        <div className={css.title}>{t('faq:title')}</div>
      </div>
    </div>
  )
}

export default Header
