import css from 'components/Pages/PurchaseForm/Tickets/Tickets.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor, IEventDetails } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'
import { FormFieldType, IFormField } from 'meta/form'
import { Breakpoints } from 'meta/layout/breakpoints'
import { ITicket } from 'meta/pages/tickets'
import { Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'
import { setPurchaseFormCookieString } from 'utils/cookie/client'

import { ButtonRed } from 'components/Actions/Action'
import Form from 'components/Forms/Form'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import EventDetails from 'components/Pages/PurchaseForm/Tickets/EventDetails'
import { useFormFields, useTicketsForm } from 'components/Pages/PurchaseForm/Tickets/hooks'
import TicketsRecap from 'components/Pages/PurchaseForm/Tickets/Recap'
import Ticket from 'components/Pages/PurchaseForm/Tickets/Ticket'
import { ITicketFormItem } from 'components/Pages/PurchaseForm/Tickets/types'
import BalloonBoxModal from 'components/TextBoxes/BalloonBoxModal'
import BalloonBoxModalNoPortal from 'components/TextBoxes/BalloonBoxModalNoPortal'
import { useAuthToken } from 'hooks/useAuth'

interface IPropsTickets {
  transactionCode: string
  transactionToken: string
  tickets: Array<ITicket>
  event: IEventDetails
  missingUserInfos: boolean
}

const Tickets: React.FC<IPropsTickets> = (props) => {
  const { event, missingUserInfos, tickets, transactionCode, transactionToken } = props

  const authToken = useAuthToken()
  const { t } = useTranslation()
  const [showSecondaryTicketingBox, setShowSecondaryTicketingBox] = useState<boolean>(false)
  const [secondaryTickets, setSecondaryTickets] = useState<number[]>([])

  const handleMissingUserInfos = async () => {
    await setPurchaseFormCookieString(transactionCode, transactionToken)
    window.location.replace(Routes.ProfileChangeData.generatePath())
  }

  const checkSecondaryTicketingIssues = (items: Array<ITicketFormItem>): boolean => {
    let itemKeys: Array<string> = []
    let filteredItemKeys: Array<string> = []

    if (!event?.secondaryTicketing || Boolean(event.isPersonalDataRequired)) {
      return false
    }

    items.map((elem) => {
      itemKeys.push(
        `${elem.buyerData['firstName']}_${elem.buyerData['lastName']}_${elem.buyerData['phonePrefix']}_${elem.buyerData['phone']}`
      )
    })

    let tempSecondaryTickets: number[] = []

    filteredItemKeys = itemKeys.filter((item, index) => {
      const check = itemKeys.indexOf(item) === index

      if (!check) {
        tempSecondaryTickets.push(index)
      }

      return check
    })

    const areThereDuplicateTickets = itemKeys.length > filteredItemKeys.length

    if (areThereDuplicateTickets) {
      setShowSecondaryTicketingBox(true)
      setSecondaryTickets(tempSecondaryTickets)
    }

    return areThereDuplicateTickets
  }

  const { bodyParser, onSuccess } = useTicketsForm(transactionCode, transactionToken, checkSecondaryTicketingIssues)
  const formFields = useFormFields(tickets, {
    policy: event?.policy,
    isPersonalDataRequired: event?.isPersonalDataRequired,
  })

  const getFormattedFormFields = () => {
    let fields: IFormField<FormFieldType>[] = [
      ...Object.values(formFields.commonFields).flat(),
      ...Object.values(formFields.extraFields).flat(),
      ...Object.values(formFields.personalDataFields).flat(),
    ]
    if (Object.values(formFields.covid19Fields) !== undefined) {
      Object.values(formFields.covid19Fields)
        .flat()
        .map((covidField) => {
          if (covidField?.formField) {
            fields.push(covidField.formField)
          }
        })
    }

    return fields
  }

  // TODO: handle user.session
  // const { token: authTokenFromSession } = user.session

  return (
    <div className={css.ticketsContainer}>
      <Form
        action={ApiEndpoint.purchase.form()}
        //authToken={authTokenFromQueryString ? authTokenFromQueryString : authTokenFromSession}
        authToken={authToken}
        bodyParser={bodyParser}
        formFields={getFormattedFormFields()}
        onSuccess={onSuccess}
        renderFields={false}
      >
        <GridContainer>
          <Grid className={css.tickets}>
            <GridColumn
              gridColumnStart={{
                [Breakpoints.mobile]: 4,
                [Breakpoints.tablet]: 1,
                [Breakpoints.laptop]: 1,
              }}
              gridColumnEnd={{
                [Breakpoints.mobile]: 14,
                [Breakpoints.tablet]: 17,
                [Breakpoints.laptop]: 17,
              }}
            >
              <h1 data-testid={DataTestId.purchaseForm.title}>{t('tickets:title')}</h1>
            </GridColumn>

            {event && <EventDetails event={event} />}

            {tickets.map((ticket, idx) => (
              <Ticket key={String(idx)} formFields={formFields} idx={idx} ticket={ticket} />
            ))}

            <TicketsRecap tickets={tickets} secondaryTickets={secondaryTickets} />
          </Grid>
        </GridContainer>
      </Form>

      {showSecondaryTicketingBox && (
        <BalloonBoxModal
          bgColor={ComponentColor.redLight}
          onClose={() => {
            setShowSecondaryTicketingBox(false)
          }}
          infoType={'error'}
          title={`${t('common:error')}!`}
        >
          <div>{t('tickets:secondary_ticketing_msg')}</div>
        </BalloonBoxModal>
      )}

      {missingUserInfos && (
        <BalloonBoxModalNoPortal
          bgColor={ComponentColor.white}
          infoType={'warning'}
          title={`${t('tickets:missing_user_info')}`}
        >
          <div className={css.missingUserInfos}>
            <div>{t('tickets:missing_user_info_body')}</div>
            <ButtonRed className={css.missingUserInfos__btnConfirm} onClick={handleMissingUserInfos}>
              <span>{t('common:update')}</span>
            </ButtonRed>
          </div>
        </BalloonBoxModalNoPortal>
      )}
    </div>
  )
}

export default Tickets
