import { ITransaction, TransactionAdapter } from 'meta/pages/profile/transaction'
import { ApiEndpoint } from 'controller/endpoint'
import { FetchTransactionsParams, TransactionsState } from 'store/pages/profile/slice/types'
import { HttpRequest } from 'utils/http'

type UserPaymentsResponseData = {
  payments: Array<ITransaction>
}

type UserPaymentsResponseMetadata = {
  hasMorePages: boolean
  currentPage: number
}

export const fetchTransactions = async (params: FetchTransactionsParams): Promise<TransactionsState> => {
  const { page } = params

  const { data, metadata } = await HttpRequest.get<UserPaymentsResponseData, UserPaymentsResponseMetadata>(
    ApiEndpoint.users.payments(page)
  )

  const { currentPage, hasMorePages } = metadata
  const { payments } = data

  const convertedTransactions = TransactionAdapter.convertResponse(payments)

  return {
    currentPage: currentPage,
    hasMorePages: hasMorePages,
    transactions: convertedTransactions,
    fetching: false,
  }
}
