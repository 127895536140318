import css from 'components/Pages/Wallet/WalletTicketDesktop/WalletTicketDesktop.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

export const WalletTicketDesktopSkeleton: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.laptop]: 2 }}
      gridColumnEnd={{ [Breakpoints.laptop]: 16 }}
      className={classNames(css.walletTicket, css.walletTicket__skeleton)}
    >
      <div className={css.walletTicket__img__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>

      <div className={css.walletTicket__details}>
        <div className={css.date__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>

        <div className={css.name__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'90%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>

        <div className={css.location_container__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'80%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </div>

      <div className={css.walletTicket__seeTickets__skeleton}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
    </GridColumn>
  )
}
