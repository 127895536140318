import css from './Single.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionPerformer } from 'meta/pages/collection'

import { Grid, GridColumn } from 'components/Layout/Grid'

interface Props {
  data: CollectionPerformer
}

const Single: React.FC<Props> = (props) => {
  const { coverImages, name } = props.data

  return (
    <div className={css.singleArtistContainer}>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.laptop]: 1 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 2, [Breakpoints.laptop]: 2 }}
        >
          <img src={coverImages[0]?.url} className={css.image} />
        </GridColumn>
        <GridColumn
          className={css.nameContainer}
          gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.laptop]: 2 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 9 }}
        >
          <h3 className={css.name}>{name}</h3>
        </GridColumn>
      </Grid>
    </div>
  )
}

export default Single
