import css from './Description.module.scss'
import React from 'react'

import { ICheckoutDetails } from 'meta/pages/checkout'

import Img from 'components/Media/Img'
import { useCheckoutDetails } from 'hooks/useCheckout'

import DayTime from './DayTime/DayTime'
import Season from './Season/Season'

const Description: React.FC = () => {
  const { eventDetails, typeTicketCollection } = useCheckoutDetails() as ICheckoutDetails

  return (
    <div className={css.checkout_eventdetails_description_container}>
      {eventDetails && <DayTime eventDetails={eventDetails} />}

      {typeTicketCollection && <Season eventsNum={typeTicketCollection.numEvents} />}

      <h2 className={css.name} data-testid="checkout-event-name">
        {eventDetails && eventDetails.name}
        {typeTicketCollection && typeTicketCollection.eventCollectionName}
      </h2>

      <div className={css.location_container}>
        <div className={css.location_image}>
          <Img src="/icons/location_small.png" />
        </div>
        {eventDetails && (
          <h3 className={css.location_text}>{`${eventDetails.location?.name} / ${eventDetails.location?.common} `}</h3>
        )}
        {typeTicketCollection && (
          <h3
            className={css.location_text}
          >{`${typeTicketCollection.location[0]?.name} / ${typeTicketCollection.location[0]?.common} `}</h3>
        )}
      </div>

      <div className={css.roundBox}></div>
    </div>
  )
}

export default Description
