import cssForm from 'components/Forms/Form/Form.module.scss'
import cssRefund from 'components/Pages/Refund/Refund.module.scss'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import {
  CheckboxBuilder,
  FormBuilder,
  FormFieldType,
  HiddenBuilder,
  IFormField,
  PhoneBuilder,
  TextBuilder,
} from 'meta/form'
import { IRefund } from 'meta/pages/refund'
import { ApiEndpoint } from 'controller/endpoint'

import Form, { IFormValues } from 'components/Forms/Form'
import FormButtonSubmit from 'components/Forms/Form/FormButtonSubmit'
import FormField from 'components/Forms/Form/FormField'
import FormFields from 'components/Forms/Form/FormFields'

type Props = {
  onSuccess: () => void
  refund: IRefund
  codeTransaction: string
}

const RefundForm: React.FC<Props> = (props) => {
  const { onSuccess, refund, codeTransaction } = props
  const { paymentData, paymentGateway } = refund

  const { t } = useTranslation()

  const termsAndConditionsPlaceholder = ReactDOMServer.renderToStaticMarkup(
    <>
      {t('refund:accept')}
      <strong> {t('refund:termsAndConditionsUsage')}</strong>
    </>
  )
  const termsAndConditions = new CheckboxBuilder('termsAndConditions')
    .setUseT(false)
    .addRequiredValidation()
    .addPlaceholder(termsAndConditionsPlaceholder)
    .build()
  const paymentGatewayField = new HiddenBuilder('paymentGateway')
    .addRequiredValidation()
    .addValue(paymentGateway)
    .build()
  const transactionCodeField = new HiddenBuilder('transactionCode')
    .addRequiredValidation()
    .addValue(String(codeTransaction))
    .build()

  const formBuilder = new FormBuilder().addField(paymentGatewayField).addField(transactionCodeField)

  Object.keys(paymentData.requestData).forEach((name) => {
    if (name !== 'prefix') {
      const FieldBuilder = name === 'phone' ? PhoneBuilder : TextBuilder

      let fieldBuilder = new FieldBuilder(name).addPlaceholder(`form:${name}`)

      if (name == 'iban') {
        fieldBuilder = (fieldBuilder as TextBuilder).addRequiredIbanValidation()
      } else {
        if (name.indexOf('Name') > 0) fieldBuilder = (fieldBuilder as TextBuilder).addRequiredNameValidation()
        else fieldBuilder = fieldBuilder.addRequiredValidation()
      }

      formBuilder.addField(fieldBuilder.build())
    }
  })
  const formFields = formBuilder.build()
  const allFormFields: Array<IFormField<FormFieldType>> = [termsAndConditions, ...formFields]

  const bodyParser = (values: IFormValues): object => {
    const { paymentGateway, transactionCode, ...paymentData } = values
    return { paymentGateway, transactionCode, paymentData }
  }

  return (
    <Form
      action={ApiEndpoint.payments.refund()}
      bodyParser={bodyParser}
      formFields={allFormFields}
      renderFields={false}
      onSuccess={onSuccess}
    >
      <div className={cssRefund.acceptTermsAndConditionsBox}>
        <FormField formField={termsAndConditions} />
        <hr />
      </div>

      <div className={cssRefund.sectionHeader}>{t('refund:requestedInformation')}</div>
      <div data-testid={DataTestId.refund.form} className={cssForm.form}>
        <FormFields formFields={formFields} />
        <FormButtonSubmit />
      </div>
    </Form>
  )
}

export default RefundForm
