import css from './Mobile.module.scss'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchParamUtils } from 'meta/pages/search'

import { ActionFormat, ButtonTransparent, LinkRed } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IPropsSearchBarMobile {
  onClose(): void
  show: boolean
}

const SearchBarMobile: React.FC<IPropsSearchBarMobile> = (props) => {
  const { show, onClose } = props

  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  useOnUpdate(() => {
    //@ts-ignore
    if (show) inputRef.current.focus()
  }, [show])

  return (
    <div className={css.headerSearchbarMobile}>
      <input
        className={css.searchbar}
        placeholder={String(t('header:searchbar_placeholder'))}
        onChange={(event) => setQuery(event.target.value)}
        ref={inputRef}
      />

      <LinkRed href={SearchParamUtils.toUrl({ query })} format={ActionFormat.regular} onClick={() => setQuery('')}>
        <strong>{t('header:search')}</strong>
        <Img src="/icons/magnifier_white.svg" />
      </LinkRed>

      <ButtonTransparent onClick={onClose}>
        <Img src="/icons/close.svg" />
      </ButtonTransparent>
    </div>
  )
}

export default SearchBarMobile
