import css from './Cover.module.scss'
import React from 'react'

import { ICheckoutDetails } from 'meta/pages/checkout'

import { useCheckoutDetails } from 'hooks/useCheckout'
import { useUrlCloudfront } from 'hooks/useEnv'

const Cover: React.FC = () => {
  const { eventDetails, typeTicketCollection } = useCheckoutDetails() as ICheckoutDetails
  const urlCloudfront = useUrlCloudfront()

  const coverImg = typeTicketCollection ? typeTicketCollection.coverImages[0]?.url : eventDetails?.coverImg

  return (
    <div className={css.checkout_eventdetails_cover_container}>
      <div
        className={css.image}
        style={{
          backgroundImage: coverImg
            ? `url('${coverImg}')`
            : `url('${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg')`,
        }}
      ></div>
    </div>
  )
}

export default Cover
