import React from 'react'
import MediaQuery from 'react-responsive'

import { IEventCollectionMenu } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import Desktop from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/Desktop'
import Mobile from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList/Mobile'

interface IProps {
  onDateChange(codeUrl: string): void
  data: IEventCollectionMenu
  removeContainer?: boolean
  insideCheckout?: boolean
}

const EventDateList: React.FC<IProps> = (props) => {
  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.portrait}>
        <Mobile {...props} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.portrait}>
        <Desktop {...props} />
      </MediaQuery>
    </>
  )
}

EventDateList.defaultProps = {
  removeContainer: false,
  insideCheckout: false,
}

export default EventDateList
