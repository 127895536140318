import React from 'react'

import Head from 'components/common/Head'
import { useFacebookAppId, useFacebookPixelId } from 'hooks/useEnv'

const Facebook: React.FC = () => {
  const facebookAppId = useFacebookAppId()
  const fbPixelId = useFacebookPixelId()

  return (
    <Head>
      <script type="text/javascript">{`
         window.fbAsyncInit = function() {
            FB.init({
                appId: ${facebookAppId},
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v10.0'
            })
        }
        `}</script>
      <script async crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>

      {/* FB Pixel */}
      <script type="text/javascript">{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fbPixelId}');
        fbq('track', 'PageView');    
      `}</script>
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display: none;"
          src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"
        />
      `}
      </noscript>
    </Head>
  )
}

export default Facebook
