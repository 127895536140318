import css from './NoTickets.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

type Props = {
  messageKey: string
}

const NoTickets: React.FC<Props> = (props) => {
  const { messageKey } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <div className={css.checkout_steps_notickets_container}>
      <div className={css.notickets_error_body}>
        <div className={css.title}>{t(messageKey)}</div>

        <div className={css.go_back} onClick={() => dispatch(CheckoutActions.close())}>
          {t('common:notickets_close_checkout')}
        </div>
      </div>
    </div>
  )
}

export default NoTickets
