import css from './SearchResult.module.scss'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import Component from 'components/BackendControlled/Component'
import { Grid, GridColumn, GridContainer, GridType } from 'components/Layout/Grid'
import { useSearchResult } from 'hooks/useSearch'

const SearchResult: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const result = useSearchResult()
  const sentinelRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (result && result.hasMorePages) {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              dispatch(SearchActions.findNext())
            }
          })
        },
        { threshold: 1 }
      )
      sentinelRef.current && intersectionObserver.observe(sentinelRef.current)
      return () => intersectionObserver.disconnect()
    }
  }, [dispatch, result])

  if (!result) return null

  return (
    <GridContainer>
      <Grid type={GridType.flex}>
        <GridColumn
          className={css.searchResultTitle}
          gridColumnSpan={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 16, [Breakpoints.laptop]: 16 }}
        >
          <strong>{result.total} </strong>
          {t('search:tickets_found', { count: result.total })}
        </GridColumn>
      </Grid>

      <Grid className={css.searchResultGridComponents} type={GridType.flex}>
        {result.components &&
          result.components.map((component) => (
            <div key={component.uuid} className={css.searchResultComponentContainer}>
              <Component {...component} />
            </div>
          ))}
      </Grid>
      <div ref={sentinelRef} />
    </GridContainer>
  )
}

export default SearchResult
