import css from './TicketCard.module.scss'
import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

import { IWalletEvent, IWalletLocation, IWalletSeasonTicket, IWalletUserTicket } from 'meta/pages/wallet'

import CardFlipItem from 'components/Pages/Wallet/WalletTicketMobile/TicketCard/CardFlipItem'

import TicketCardBase from './Base'
import TicketCardDetails from './Details'

type Props = {
  event: IWalletEvent
  location: IWalletLocation
  userTicket: IWalletUserTicket & IWalletSeasonTicket
}

const TicketCard: React.FC<Props> = (props) => {
  const { userTicket } = props

  const [showDetails, setShowDetails] = useState<boolean>(false)

  const changeShowDetail = useCallback(() => {
    setShowDetails((prevState) => !prevState)
  }, [])

  return (
    <div className={css.walletTicketCard__flipCard}>
      <div className={classNames(css.walletTicketCard__flipCardInner, { [css.details]: showDetails })}>
        <CardFlipItem
          className={css.walletTicketCard__flipCardFront}
          changeShowDetail={changeShowDetail}
          userTicket={userTicket}
          showDetails={showDetails}
        >
          <TicketCardBase show={!showDetails} userTicket={userTicket} />
        </CardFlipItem>

        <CardFlipItem
          className={css.walletTicketCard__flipCardBack}
          changeShowDetail={changeShowDetail}
          userTicket={userTicket}
          showDetails={showDetails}
        >
          <TicketCardDetails show={showDetails} userTicket={userTicket} />
        </CardFlipItem>
      </div>
    </div>
  )
}

export default TicketCard
