import { IAssets } from './asset'
import { ComponentType, IComponent } from './component'

export enum EventPolicy {
  default = 'default',
  covid19 = 'covid-19',
}

export interface IEventDetailsLocation {
  city: string
  common: string
  latitude: number
  longitude: number
  mapsPlaceId: string
  name: string
}

export interface IEventDetails extends IComponent<ComponentType.eventDetails> {
  asset: IAssets
  codeUrl: string
  coverImg?: string
  dateEndSellTicket: string
  dateStartSellTicket: string
  datetime: string
  description: string
  id: number
  infoPhone?: string
  fbPixel?: string
  location?: IEventDetailsLocation
  name: string
  eventCollectionName?: string
  numEvents?: number
  policy?: EventPolicy
  secondaryTicketing?: boolean
  isCancelled?: boolean
  isRefundable?: boolean
  isPersonalDataRequired?: boolean
}

export interface EventDetailsSkeleton extends IComponent<ComponentType.eventDetailsSkeleton> {}
