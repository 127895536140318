import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { toast, ToastContainer as ToastifyContainer } from 'react-toastify'

import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

export const Toast = {
  error: (message: string) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        'backgroundColor': TICKETSMS_COLORS_RED,
      },
    })
  },
}

export const ToastContainer: React.FC = () => {
  return <ToastifyContainer />
}
