import css from './AuthBox.module.scss'
import React, { Dispatch, SetStateAction } from 'react'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import Login from 'components/Checkout/Steps/Auth/AuthBox/Login'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

interface IProps {
  setShowLogin: Dispatch<SetStateAction<boolean>>
}

const AuthBox: React.FC<IProps> = (props) => {
  const { setShowLogin } = props

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <div className={css.checkout_authbox_container}>
            <BalloonWrapper
              color={ComponentColor.white}
              size={BalloonSize.small}
              tipPosition={BalloonTipPosition.right}
              removePaddingOutside
              noPadding
            >
              <div className={css.content}>
                <Login setShowLogin={setShowLogin} />
              </div>
            </BalloonWrapper>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default AuthBox
