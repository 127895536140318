import { FormFieldType, IFormField } from './formField'

export class FormBuilder {
  formFields: Array<IFormField<FormFieldType>>

  constructor() {
    this.formFields = []
  }

  addField(formField: IFormField<FormFieldType>): FormBuilder {
    this.formFields.push(formField)
    return this
  }

  build(): Array<IFormField<FormFieldType>> {
    return this.formFields
  }
}
