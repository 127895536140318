import { TicketFee } from 'meta/pages/tickets'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Props = {
  codeUrl: string
  typeTicketCollectionId?: string
}

type Response = Array<TicketFee>

export const getFees = async (props: Props): Promise<Response> => {
  const { codeUrl, typeTicketCollectionId } = props

  const endpoint = typeTicketCollectionId
    ? ApiEndpoint.collection.seasonEventsTicketsFees(codeUrl, typeTicketCollectionId)
    : ApiEndpoint.event.fees(codeUrl)

  const { data } = await HttpRequest.get<Response>(endpoint)
  return data
}
