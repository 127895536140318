import css from './Ticket.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const TicketSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
        >
          <Skeleton
            className={css.ticketSkeleton}
            baseColor={TICKETSMS_COLORS_MEDIUMGREY}
            borderRadius={16}
            highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          />
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}
