import { CSSProperties, MutableRefObject, useCallback, useState } from 'react'

import { BreakpointsMinWidth } from '../../../../meta/layout/breakpoints'
import { StoriesProps } from '../props'

export type StoriesState = {
  idxActive: number
  laptop: boolean
  styles?: Array<CSSProperties>
}

export type UpdateIdxActive = {
  updateIdxActive: (value: number, useIncrement?: boolean) => void
}

const getStyleLaptop = (idxStory: number, idxActive: number): CSSProperties => {
  const width = window.innerWidth - 128
  const height = `${((width / 3) * 16) / 9 - 56}px`

  if (idxStory === idxActive)
    return {
      transform: `scale(1) translate(calc(((100vw - 128px) / 2) - 50%), -50%)`,
      height,
    }
  if (idxStory < idxActive)
    return {
      transform: `scale(0.4) translate(${Math.abs(idxStory - (idxActive - 1)) * -125 + 50}%, -125%)`,
      height,
    }
  return {
    transform: `scale(0.4) translate(${325 + 125 * (idxStory - idxActive)}%, -125%)`,
    height,
  }
}

export const useStories = (
  props: StoriesProps,
  storiesRef: MutableRefObject<HTMLDivElement>
): StoriesState & UpdateIdxActive => {
  const { onClose, startIndex, stories } = props
  const isLaptop = useCallback((): boolean => window.innerWidth >= BreakpointsMinWidth.laptop, [])
  const isMobileLandscape = useCallback((): boolean => window.innerWidth >= BreakpointsMinWidth.mobileLandscape, [])
  const [state, setState] = useState<StoriesState>({ idxActive: startIndex, laptop: isLaptop() })
  const theta = 360 / stories.length

  const getStyles = useCallback((idxActive: number, laptop: boolean): Array<CSSProperties> => {
    const radius = Math.round(window.innerWidth / 2 / Math.tan(Math.PI / stories.length))

    const transform = laptop ? null : `translateZ(${-radius}px) rotateY(${theta * idxActive * -1}deg)`

    //@ts-ignore
    storiesRef.current.style.transform = transform

    let width = `100vw`
    if (isMobileLandscape()) width = `${(window.innerHeight * 9) / 16}px`
    if (laptop) width = `100vw`

    //@ts-ignore
    storiesRef.current.parentElement.style.width = width

    return stories.map((_, i) => {
      if (laptop) return getStyleLaptop(i, idxActive)
      return { transform: `rotateY(${theta * i}deg) translateZ(${radius}px)` }
    })
  }, [])

  const updateIdxActive = useCallback(
    (value: number, useIncrement: boolean = true): void =>
      //@ts-ignore
      setState((prev) => {
        const laptopUpdate = isLaptop()
        const idxActive = useIncrement ? prev.idxActive + value : value

        if (idxActive < 0 || idxActive >= stories.length) {
          onClose()
          return
        }

        return {
          idxActive,
          laptop: laptopUpdate,
          styles: getStyles(idxActive, laptopUpdate),
        }
      }),
    []
  )

  return { ...state, updateIdxActive }
}
