import React, { useEffect, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'

import { I18NContext } from 'components/HOC/WithTranslation/context'

function withTranslation<P = {}>(namespaces: string[]) {
  return (Component: React.ComponentType) => {
    const HOCComponent: React.FC<P> = (props) => {
      const [, startTransition] = useTransition()

      const { i18n } = useTranslation()

      const [state, setState] = useState<{ namespacesLoaded: boolean }>({
        namespacesLoaded: namespaces.every((namespace) => i18n.hasLoadedNamespace(namespace)),
      })

      useEffect(() => {
        startTransition(() => {
          i18n.loadNamespaces(namespaces).then(() => {
            setState({ namespacesLoaded: true })
          })
        })
      }, [i18n])

      return (
        <I18NContext.Provider value={state}>
          {/* @ts-ignore */}
          <Component {...props} />
        </I18NContext.Provider>
      )
    }

    return HOCComponent
  }
}

export default withTranslation
