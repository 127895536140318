import css from 'components/Pages/ProfileTransactions/Transaction/Actions/Actions.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const ActionsSkeleton: React.FC = () => {
  return (
    <>
      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <div className={classNames(css.transactionActions)}>
          <div className={classNames(css.emptyPlaceholder)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        <GridContainer className={css.grid__skeleton}>
          <Grid>
            <GridColumn gridColumnStart={{ [Breakpoints.mobile]: 4 }} gridColumnEnd={{ [Breakpoints.mobile]: 11 }}>
              <div className={classNames(css.action__skeleton)}>
                <Skeleton
                  baseColor={TICKETSMS_COLORS_LIGHTGREY}
                  borderRadius={16}
                  height={'100%'}
                  width={'100%'}
                  highlightColor={TICKETSMS_COLORS_WHITE}
                />
              </div>
            </GridColumn>
          </Grid>
        </GridContainer>
      </MediaQuery>
    </>
  )
}
