import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Languages } from 'meta/app/translate'
import { Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'

import BackendControlled from 'components/BackendControlled'
import withTranslation from 'components/HOC/WithTranslation'

const Home: React.FC = () => {
  const { lang } = useParams<{ lang?: string }>()

  const withValidLang = !lang || Object.keys(Languages).includes(lang)

  if (!withValidLang) {
    return <Navigate to={Routes.NotFound.generatePath()} replace />
  }

  return (
    <BackendControlled
      // banner={{
      //   imageUrl: '/banner/banner_header_home.png',
      //   imageMobileUrl: '/banner/banner_header_home_mobile.png',
      //   type: BannerType.top,
      // }}
      bannerTop={{
        imageURLs: {
          desktop: `/banner/banner_2024_header_home_desktop.png`,
          laptop: `/banner/banner_2024_header_home_laptop.png`,
          mobile: `/banner/banner_2024_header_home_mobile.png`,
          tabletLandscape: `/banner/banner_2024_header_home_tablet_landscape.png`,
          tabletPortrait: `/banner/banner_2024_header_home_tablet_portrait.png`,
        },
      }}
      path={ApiEndpoint.home.components()}
    />
  )
}

const namespaces = ['infobox']

export default withTranslation(namespaces)(Home)
