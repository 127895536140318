import css from 'components/Pages/ProfileCreditCards/CardItem/CardItem.module.scss'
import React, { HTMLAttributes } from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const CardItemSkeleton: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className } = props

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <div className={classNames(css.cardItem, className)}>
          <div className={css.left}>
            <div className={css.card__brand__mobile__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                width={'90%'}
                highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
              />
            </div>
            <div className={css.card__number__mobile__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
          <div className={css.right}>
            <div className={css.expiration__mobile__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
              />
            </div>
            <div className={css.delete__mobile__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <div className={classNames(css.cardItem, className)}>
          <div className={css.left}>
            <div className={css.card__brand__desktop__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
              />
            </div>
            <div className={css.card__number__desktop__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
              />
            </div>
          </div>
          <div className={css.right}>
            <div className={css.expiration__desktop__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={css.delete__desktop__skeleton}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  )
}
