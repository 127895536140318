import css from './GoogleMapStatic.module.scss'
import React from 'react'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useGoogleMapsApiKey } from 'hooks/useEnv'

interface IProps {
  lat: number
  long: number
  mapsPlaceId: string
  locationName?: string
}

const GoogleMapStatic: React.FC<IProps> = (props) => {
  const { lat, long: lng, mapsPlaceId } = props
  const key = useGoogleMapsApiKey()

  if (key === '') return null

  return (
    <GridContainer>
      <Grid>
        <GridColumn className={css.googleMapContainer} gridColumnEnd={17}>
          <a href={`https://www.google.com/maps/place/?q=place_id:${mapsPlaceId}`}>
            <div
              className={css.image}
              style={{
                filter: 'saturate(0)',
                backgroundPosition: `center center`,
                backgroundImage: `url(https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=${lat},${lng}&size=${640}x${640}&scale=2&zoom=13&format=png&key=${key})`,
              }}
            />
          </a>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default GoogleMapStatic
