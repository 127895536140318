import css from './TicketSummary.module.scss'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import BeatLoader from 'react-spinners/BeatLoader'
import classNames from 'classnames'

import { CurrencySymbol } from 'meta/currency'
import { MapIDs } from 'meta/map'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

import { ActionFormat, ButtonTransparent } from 'components/Actions/Action'
import EventFeesButton from 'components/BackendControlled/Component/common/EventFeesButton'
import Img from 'components/Media/Img'
import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutDetails, useCheckoutEventCodeUrl, useCheckoutTicketSeat } from 'hooks/useCheckout'
import { useEventSeats, useSectionLabel } from 'hooks/useEvent'

interface IProps {
  id: string
  idx: number
  summaryOpened: boolean
}

const TicketSummary: React.FC<IProps> = (props) => {
  const { id, summaryOpened, idx } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { activeCurrency } = useActiveCurrency()
  const { eventDetails } = useCheckoutDetails() as ICheckoutDetails
  const ticketSeat = useCheckoutTicketSeat(idx)
  const { seatId, selectedTypeTicketId, typeTickets } = ticketSeat
  const { section, row, seat } = MapIDs.toSeatIDs(seatId)
  const sectionLabel = useSectionLabel(id, section)
  const { linkedSeats } = useEventSeats(id)

  const codeUrlFromCheckout = useCheckoutEventCodeUrl()

  const codeUrl = eventDetails && eventDetails.codeUrl ? eventDetails.codeUrl : codeUrlFromCheckout

  const selectTypeTicket = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(CheckoutActions.selectSeatTypeTicket({ idx, typeTicketId: Number(event.target.value) }))
  }
  const deleteSeat = () => {
    dispatch(CheckoutActions.deselectSeat({ seatId }))
    const linkedSeatIDs = linkedSeats?.[seatId]
    if (linkedSeatIDs) {
      linkedSeatIDs.forEach((linkedSeatID) => dispatch(CheckoutActions.deselectSeat({ seatId: linkedSeatID })))
    }
  }

  // const convertedPrice = convertAndFormat(priceNumber, eventCurrencyCode, activeCurrency)
  // const convertedPresale = convertAndFormat(presaleNumber, eventCurrencyCode, activeCurrency)

  const getCurrency = (currency: string) => (currency === 'EUR' ? '€' : currency)

  return (
    <div className={classNames(css.ticketSummary, { [css.summaryClosed]: !summaryOpened })}>
      <div className={css.ticketSummary__ticketNumber}>
        <Img className={css.imgNumber} src="/checkout/ticket_summary_number.svg" />
        <div className={css.number}>
          <h5>{idx + 1}</h5>
        </div>
        <div className={css.ticket}>{t('checkout:ticket')}</div>
      </div>

      <div className={css.ticketSummary__details}>
        <Img className={css.background} src="/checkout/ticket_summary_ticket_grey.svg" />
        <div className={css.ticketSummary__detailsContent}>
          <h3>{sectionLabel}</h3>
          <div>{t('checkout:row_and_seat', { row, seat })}</div>

          {selectedTypeTicketId ? (
            <>
              <select onChange={selectTypeTicket} value={selectedTypeTicketId}>
                {typeTickets.map((typeTicket) => {
                  const eventCurrencyCode = getCodeFromSymbol(getCurrency(typeTicket.price.currency) as CurrencySymbol)

                  return (
                    <option key={typeTicket.id} value={typeTicket.id}>
                      {typeTicket.description}
                      {convertAndFormat(
                        Number(typeTicket.price.amount) / 100,
                        eventCurrencyCode,
                        activeCurrency,
                        true
                      )}{' '}
                      +{' '}
                      {convertAndFormat(
                        Number(typeTicket.presale.amount) / 100,
                        eventCurrencyCode,
                        activeCurrency,
                        true
                      )}{' '}
                      ddp
                    </option>
                  )
                })}
              </select>
              {codeUrl && <EventFeesButton codeUrl={codeUrl} />}
            </>
          ) : (
            <div className={css.loader}>
              <BeatLoader color={TICKETSMS_COLORS_RED} size={7} />
            </div>
          )}
        </div>
      </div>

      <div className={css.ticketSummary__delete}>
        <Img className={css.imgBackground} src="/checkout/ticket_summary_trash_bg.svg" />
        <div className={css.btnDeleteContainer}>
          <ButtonTransparent disabled={!selectedTypeTicketId} format={ActionFormat.circle} onClick={deleteSeat}>
            <Img src="/checkout/ticket_summary_trash.svg" />
          </ButtonTransparent>
        </div>
      </div>
    </div>
  )
}

export default TicketSummary
