import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'

import { IMenuItem } from 'meta/backendControlled/structure'

import { getComponentId } from 'components/BackendControlled/Component/utils'

/**
 * Updates the active section on window scroll.
 */
export const useVisibleSection = (list: Array<IMenuItem>, id: string): [string | undefined, boolean] => {
  const [sticky, setSticky] = useState<boolean>(false)
  const [visibleSection, setVisibleSection] = useState<string>()

  const visibleSectionRef = useRef<boolean>()
  const sectionsRef = useRef<Array<HTMLElement>>()

  const updateVisibleSection = (section: string | undefined) => {
    visibleSectionRef.current = Boolean(section)
    setVisibleSection(section)
  }

  const onScroll = throttle(() => {
    // find visible section
    const sectionIdx = sectionsRef.current?.findIndex((section) => {
      const rect = section.getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight

      if (rect.bottom <= 0 || rect.top >= windowHeight) return false
      return rect.top + 200 >= 0 || rect.bottom - 100 >= 0
    })
    const section = sectionIdx ? sectionsRef.current?.[sectionIdx] : undefined

    if (section && visibleSection !== section.id) {
      updateVisibleSection(section.id)
    }
    if (!section) {
      updateVisibleSection(undefined)
    }

    // check whether menu is sticky
    const menu = document.getElementById(id)
    if (menu) {
      if (menu.getBoundingClientRect().top <= 0 && visibleSectionRef.current) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
  }, 150)

  useEffect(() => {
    sectionsRef.current = list.reduce<Array<HTMLElement>>((items, menuItem) => {
      const elementById = document.getElementById(getComponentId(menuItem.anchor))
      if (elementById) items.push(elementById)
      return items
    }, [])

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return [visibleSection, sticky]
}
