import css from './EventTicket.module.scss'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { IEventTicket } from 'meta/backendControlled/structure'
import { IMap, IMapSeat, MapIDs } from 'meta/map'
import { TicketSelectionType } from 'meta/pages/checkout'
import { RouteParams } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { EventActions } from 'store/pages/event'
import { Dates } from 'utils/dates'

import { useShowAll } from 'components/BackendControlled/Component/EventTicket/hooks/useShowAll'
import Ticket from 'components/BackendControlled/Component/EventTicket/Ticket'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import TicketSeatSelector from 'components/Checkout/TicketSelection/TicketSeatSelector'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import ShowMoreBox from 'components/Structure/ShowMoreBox'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useEventDetails } from 'hooks/useEvent'

const EventTicket: React.FC<IPropsComponent<IEventTicket>> = (props) => {
  const { data, eventId, map, title, uuid } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { codeUrl } = useParams<RouteParams['Event']>() as RouteParams['Event']
  const language = useCurrentLanguage()
  const eventDetails = useEventDetails({ codeUrl, language })

  const selectionType = useRef<TicketSelectionType>(map ? TicketSelectionType.manual : TicketSelectionType.automatic)
  const gridRef = useRef<HTMLDivElement>(null)
  const { showAll, toggleShowMore } = useShowAll({ gridRef })

  const saleAvailable = Boolean(
    eventDetails && Dates.isSameOrBefore(new Date(eventDetails.dateStartSellTicket), new Date())
  )
  const dataTickets = map ? data.filter((ticket) => ticket?.typeTicketDescription?.toUpperCase() === 'INTERO') : data
  const tickets = showAll ? dataTickets : dataTickets.slice(0, 4)
  const isEventFree = !tickets.find((x) => x.priceNumber > 0) && !map

  const openCheckout = (initialTypeTicketId?: number, orderCodeDescription?: string, seats?: Array<IMapSeat>) => {
    if (map) {
      if (!seats) seats = [{ seatId: MapIDs.RANDOM, sectionId: String(orderCodeDescription) }]
      dispatch(CheckoutActions.init({ codeUrl, initialTypeTicketId, seats }))
    } else {
      dispatch(CheckoutActions.init({ codeUrl, initialTypeTicketId }))
    }
  }

  useEffect(() => {
    dispatch(EventActions.setFreeEvent({ isEventFree }))
  })

  return (
    <GridContainer className={css.eventTicketsContainer} id={getComponentId(uuid)}>
      <Grid ref={gridRef}>
        <GridColumn gridColumnEnd={17}>
          <h2>{title}</h2>
        </GridColumn>

        {/*{map && (*/}
        {/*    <GridColumn gridColumnEnd={17}>*/}
        {/*        <div className={css.selection_switcher}>*/}
        {/*            <Switcher t={t} onChange={setSelectionType} type={selectionType} />*/}
        {/*        </div>*/}
        {/*    </GridColumn>*/}
        {/*)}*/}
      </Grid>

      {!saleAvailable && (
        <Grid>
          <GridColumn gridColumnEnd={17} className={css.eventTickets}>
            {t('checkout:sale_not_yet_available')}
          </GridColumn>
        </Grid>
      )}

      {saleAvailable && selectionType.current === TicketSelectionType.manual && (
        <>
          <div className={css.eventTickets} />
          <Grid>
            <GridColumn gridColumnEnd={17}>
              <TicketSeatSelector
                eventId={eventId}
                id="event"
                map={map as IMap}
                onSeatSelect={(seats) => openCheckout(undefined, undefined, seats)}
                persistSelectSeat={false}
                showTickets={false}
              />
            </GridColumn>
          </Grid>
        </>
      )}

      {saleAvailable && selectionType.current === TicketSelectionType.automatic && (
        <>
          <div className={css.eventTickets}>
            {tickets.map((elem, key) => {
              return <Ticket callback={openCheckout} key={key} data={elem} />
            })}
          </div>

          {data.length > 4 && (
            <div className={showAll ? css.eventTicketsShowLessContainer : css.eventTicketsShowMoreContainer}>
              <ShowMoreBox
                toggleCallback={toggleShowMore}
                showMoreText={String(t('checkout:show_all'))}
                showLessText={String(t('checkout:show_less'))}
                showAll={showAll}
              />
            </div>
          )}
        </>
      )}
    </GridContainer>
  )
}

export default EventTicket
