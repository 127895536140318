import React from 'react'

import { IHorizontalScroll } from 'meta/backendControlled/structure'

import Component, { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridContainer } from 'components/Layout/Grid'

const HorizontalScroll: React.FC<IPropsComponent<IHorizontalScroll>> = (props) => {
  const { category, insideBalloon } = props

  if (!insideBalloon) {
    return (
      <GridContainer>
        <Component {...category} />
      </GridContainer>
    )
  }

  return <Component {...category} />
}

HorizontalScroll.defaultProps = {
  insideBalloon: false,
}

export default HorizontalScroll
