import css from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn } from 'components/Layout/Grid'

export const MobileSkeleton: React.FC = () => {
  return (
    <Grid className={classNames(css.grid__skeleton, Breakpoints.tablet && css.grid__skeleton__tablet)}>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 3 }}
      >
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'50%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.fcUpper}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'70%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 7, [Breakpoints.tablet]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 11, [Breakpoints.tablet]: 6 }}
      >
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'50%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div data-testid={DataTestId.profile.transactionPrice}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'40%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 11, [Breakpoints.tablet]: 6 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 8 }}
      >
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'60%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'80%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5, [Breakpoints.tablet]: 3 }}
      >
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'60%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'80%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 7, [Breakpoints.tablet]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 11, [Breakpoints.tablet]: 6 }}
      >
        <div>
          <div className={css.transactionItem}>
            <div>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={16}
                height={'100%'}
                width={'60%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={css.ticketsNumber}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={16}
                height={'100%'}
                width={'30%'}
                highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
              />
            </div>
          </div>
        </div>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 11, [Breakpoints.tablet]: 6 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 8 }}
      >
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'50%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'80%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </GridColumn>
    </Grid>
  )
}
