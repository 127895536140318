import { ICheckoutAuth } from 'meta/pages/checkout'
import { SliceSelector } from 'store/types'

const getAuth: SliceSelector<ICheckoutAuth | undefined> = () => (state) => state.pages.checkout.auth

const getTypeTicketCollectionId: SliceSelector<string | undefined> = () => (state) =>
  state.pages.checkout.eventTypeTicketCollectionId

export const CheckoutSelectors = {
  getAuth,
  getTypeTicketCollectionId,
}
