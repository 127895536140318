import i18n from 'i18n'
import { Dates } from 'utils/dates'

import { ComponentType } from '../../structure'
import {
  IEventCollectionByDay,
  IEventCollectionMenu,
  IEventCollectionMenuElement,
} from '../../structure/eventCollectionMenu'
import { IEventCollectionMenuBackend, IEventCollectionMenuElementBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export const getAutobalancingSlice = (
  total: number,
  rawList: IEventCollectionMenuElementBackend[],
  current: IEventCollectionMenuElement
): IEventCollectionByDay => {
  const isEven = total % 2 === 0

  const evenBalancer = isEven ? 0 : 1

  const balance = isEven ? (total / 2) | 0 : ((total - 1) / 2) | 0

  let currentPos: number = 0
  const listByDay = mapCollectionByDay(rawList)

  const listAsObj = Object.values(listByDay)

  listAsObj.forEach((elem, key) => {
    elem.forEach((x) => {
      if (current.codeUrl === x.codeUrl) {
        currentPos = key
      }
    })
  })

  const howManyBef = listAsObj.slice(0, currentPos).length
  const howManyAft = listAsObj.slice(currentPos + 1, listAsObj.length).length

  let sliceAsArray
  let startsAt
  let endsAt

  const canSliceFitIntoTotal = total >= listAsObj.length

  if (canSliceFitIntoTotal) {
    sliceAsArray = listAsObj
  } else {
    if (howManyBef === howManyAft) {
      sliceAsArray = listAsObj.slice(
        currentPos - balance < 0 ? 0 : currentPos - balance,
        currentPos + balance + evenBalancer
      )
    } else if (howManyBef < howManyAft) {
      startsAt = currentPos - balance < 0 ? 0 : currentPos - balance
      endsAt = startsAt + currentPos + balance + (balance - howManyBef) + evenBalancer

      sliceAsArray = listAsObj.slice(startsAt, endsAt)
    } else {
      endsAt = currentPos + balance > listAsObj.length ? listAsObj.length : currentPos + balance + 1
      startsAt = listAsObj.length < total ? 0 : endsAt - balance - balance - evenBalancer

      sliceAsArray = listAsObj.slice(startsAt, endsAt)
    }
  }

  const flattenedSlice: IEventCollectionMenuElementBackend[] = []

  sliceAsArray.forEach((elem) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    elem.map((x) => flattenedSlice.push(x))
  })

  return mapCollectionByDay(flattenedSlice)
}

export const mapEventCollection = (x: IEventCollectionMenuElementBackend[]): IEventCollectionMenuElement[] => {
  const adaptedCollection = x.map((x) => {
    return {
      codeUrl: x.codeUrl,
      datetime: new Date(x.datetime_tz),
      fullpass: x.fullpass,
    }
  })

  return adaptedCollection
}

export const getFullPassElement = (x: IEventCollectionMenuElementBackend[]): IEventCollectionMenuElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return x.find((elem) => elem.fullpass === true)
}

export const mapCollectionByDay = (x: IEventCollectionMenuElementBackend[]): IEventCollectionByDay => {
  const mapByDayObj: IEventCollectionByDay = {}

  x.forEach((elem) => {
    const dateString = Dates.format(new Date(elem.datetime), i18n.t('dates:common:yearMonthDay'))

    if (!elem.fullpass && !mapByDayObj[dateString]) {
      mapByDayObj[dateString] = []
    }

    !elem.fullpass &&
      mapByDayObj[dateString].push({
        codeUrl: elem.codeUrl,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        datetime: elem.datetime.replace ? new Date(elem.datetime_tz) : elem.datetime,
        fullpass: elem.fullpass,
      })
  })

  return mapByDayObj
}

export default class EventCollectionMenuAdapter extends ComponentAdapter<
  IEventCollectionMenu,
  IEventCollectionMenuBackend
> {
  convert(): IEventCollectionMenu {
    const { list, current, uuid, title } = this.componentBackend

    return {
      componentType: ComponentType.eventCollectionMenu,
      uuid: uuid,
      title: title,
      list: mapEventCollection(list),
      fullpass: getFullPassElement(list),
      rawList: list,
      current: {
        codeUrl: current.codeUrl,
        datetime: new Date(current.datetime_tz),
        fullpass: current.fullpass,
      },
    }
  }
}
