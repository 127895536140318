import { ISearchCity, ISearchEventCategory, ISearchParams, ISearchResult } from 'meta/pages/search'
import { useAppSelector } from 'store'
import { SearchSelectors } from 'store/pages/search'

export const useSearchIsFetching = (): boolean => useAppSelector(SearchSelectors.isFetching())

export const useSearchParams = (): ISearchParams => useAppSelector(SearchSelectors.getParams())

export const useSearchResult = (): ISearchResult | undefined => useAppSelector(SearchSelectors.getResult())

export const useSearchCities = (): Array<ISearchCity> | undefined => useAppSelector(SearchSelectors.getCities())

export const useSearchEventCategories = (): Array<ISearchEventCategory> | undefined =>
  useAppSelector(SearchSelectors.getEventCategories())
