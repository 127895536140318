import React from 'react'

import { EventDetailsSkeleton as EventDetailsSkeletonType } from 'meta/backendControlled/structure/eventDetails'

import { ActionsSkeleton } from 'components/BackendControlled/Component/EventDetails/Actions'
import { CoverSkeleton } from 'components/BackendControlled/Component/EventDetails/Cover'
import { InfosSkeleton } from 'components/BackendControlled/Component/EventDetails/Infos/InfosSkeleton'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { Grid } from 'components/Layout/Grid'

export const EventDetailsSkeleton: React.FC<IPropsComponent<EventDetailsSkeletonType>> = () => {
  return (
    <Grid>
      <InfosSkeleton />
      <CoverSkeleton />
      <ActionsSkeleton />
    </Grid>
  )
}
