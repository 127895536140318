import { ComponentType, IBlogPost } from '../../structure'
import { IBlogPostBackend } from '../component/blog'
import ComponentAdapter from './componentAdapter'

export default class BlogPostAdapter extends ComponentAdapter<IBlogPost, IBlogPostBackend> {
  convert(): IBlogPost {
    const { excerpt, feature_image, title, url } = this.componentBackend

    return {
      componentType: ComponentType.blogPost,
      excerpt,
      imageUrl: feature_image,
      postUrl: url,
      title,
    }
  }
}
