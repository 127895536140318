import { get } from 'api'
import { PersonalDataField } from 'types/tickets'

import { IGetDataEnv, SelectSearchOption } from 'meta/form/linked'
import { ApiEndpoint } from 'controller/endpoint'
import { DATALIST_SEARCH_MINIMUM_LENGTH } from 'utils/constants/form'

export const getCountriesData = async (query: string = '', env: IGetDataEnv): Promise<SelectSearchOption[]> => {
  if (query.length < DATALIST_SEARCH_MINIMUM_LENGTH) {
    return []
  }

  const formattedQuery = formatQuery(query)

  const response = await get({
    urlBase: env.urlBase,
    urlResource: ApiEndpoint.search.findCountries(formattedQuery),
    locale: env.locale,
  })

  const responseJson = await response.json()

  if (!responseJson.data) {
    return []
  }

  return Object.values(responseJson.data['countries'])
    .map((elem) => {
      return {
        //@ts-ignore
        name: elem['name'],
        //@ts-ignore
        value: elem['code'],
        //@ts-ignore
        match: elem['match'],
      }
    })
    .filter((x) => x.name.startsWith(formattedQuery))
}

export const getCitiesData = async (query: string = '', env: IGetDataEnv): Promise<SelectSearchOption[]> => {
  if (query.length < DATALIST_SEARCH_MINIMUM_LENGTH) {
    return []
  }

  const formattedQuery = formatQuery(query)

  const response = await get({
    urlBase: env.urlBase,
    urlResource: ApiEndpoint.search.findCities(formattedQuery),
    locale: env.locale,
  })

  const responseJson = await response.json()

  if (!responseJson.data) {
    return []
  }

  return Object.values(responseJson.data['cities'])
    .map((elem) => {
      return {
        //@ts-ignore
        name: `${elem['name']} (${elem['province']})`,
        //@ts-ignore
        value: elem['fiscalCode'],
        //@ts-ignore
        match: elem['match'],
      }
    })
    .filter((x) => x.name.startsWith(formattedQuery))
}

//@ts-ignore
export const linkCountriesAndCities = (field, setCountry, country) => {
  if (field['options'] && field['options']['linkIdentifier'] == PersonalDataField.birthplaceCountry) {
    field['options']['onChange'] = setCountry
  }

  if (field['options'] && field['options']['linkIdentifier'] == PersonalDataField.birthplaceCity) {
    field['options']['disable'] = country !== 'ITA'
  }

  return field
}

const formatQuery = (query: string) => {
  return query.toLowerCase().charAt(0).toUpperCase() + query.toLowerCase().slice(1)
}
