import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { RouteParams } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { useIsAppInitialized } from 'hooks/useApp'

const IFrame: React.FC = () => {
  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()
  const { codeUrl } = useParams<RouteParams['Event']>()

  useEffect(() => {
    if (appInitialized) {
      dispatch(CheckoutActions.init({ codeUrl: String(codeUrl), insideIFrame: true }))
    }
  }, [appInitialized, codeUrl, dispatch])

  return null
}

export default IFrame
