import css from './StepCommon.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

import { StepColumn } from './StepColumn'

type Props = {
  src: string
}

export const StepImg: React.FC<Props> = (props) => {
  const { src } = props

  return (
    <StepColumn className={css.loginStepImg}>
      <Img alt="" src={src} />
    </StepColumn>
  )
}
