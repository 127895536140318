import css from './Voices.module.scss'
import React, { PropsWithChildren } from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { DesktopSkeleton } from './Desktop'
import { MobileSkeleton } from './Mobile'

interface IProps {
  voices: string[]
}

export const VoicesSkeleton: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { voices, children } = props

  return (
    <div className={css.pagewithsubsections_voices_container}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <MobileSkeleton></MobileSkeleton>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <DesktopSkeleton voices={voices}>{React.Children.toArray(children)}</DesktopSkeleton>
      </MediaQuery>
    </div>
  )
}
