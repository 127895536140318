export const searchResultSkeleton = [
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton1',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton2',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton3',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton4',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton5',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton6',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton7',
  },
  {
    'componentType': 'squareSkeleton',
    'uuid': 'squareSkeleton8',
  },
]
