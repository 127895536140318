import css from './ButtonSkeletonWrapper.module.scss'
import React, { PropsWithChildren } from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import WithAppInitialized from 'components/common/WithAppInitialized/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'

const ButtonSkeletonWrapper: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { namespacesLoaded } = useI18NContext()

  return (
    <WithAppInitialized
      showChildren={namespacesLoaded}
      skeleton={
        <Skeleton
          className={css.btnSkeleton}
          baseColor={TICKETSMS_COLORS_WHITE}
          borderRadius={16}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      }
    >
      {children}
    </WithAppInitialized>
  )
}

export default ButtonSkeletonWrapper
