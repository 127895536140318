import { createAsyncThunk } from '@reduxjs/toolkit'

import { AuthController } from 'controller/auth'
import { AuthParams, LoginParams } from 'store/auth/slice/types'
import { RootState } from 'store/types'

export const login = createAsyncThunk<LoginParams, AuthParams, { state: RootState }>(
  'auth/login',
  AuthController.fetchUserData
)
