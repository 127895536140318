import css from './EventDetails.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { DesktopSkeleton } from 'components/Checkout/EventDetails/Desktop'
import { MobileSkeleton } from 'components/Checkout/EventDetails/Mobile'

export const EventDetailsSkeleton: React.FC = () => {
  return (
    <div className={css.checkout_eventdetails_container}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <MobileSkeleton />
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <DesktopSkeleton />
      </MediaQuery>
    </div>
  )
}
