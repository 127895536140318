import { CookieClient, CookieName } from 'utils/cookie'
import { Tokens } from 'utils/tokens'

export const addAuthTokenToRoutePath = (routePath: string, token?: string): string => {
  if (!Tokens.isValid(token)) {
    return routePath
  }

  return `${routePath}&authToken=${token ? token : CookieClient.get(CookieName.TOKEN)}`
}
