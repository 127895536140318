import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useOnUpdate = (effect: EffectCallback, deps?: DependencyList): void => {
  const initialMount = useRef<boolean>(true)

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false
    } else {
      effect()
    }
  }, deps)
}
