import { IUserData } from 'meta/auth'
import { SliceSelector } from 'store/types'

const getToken: SliceSelector<string | undefined> = () => (state) => state.auth.token

const getUserData: SliceSelector<IUserData | undefined> = () => (state) => state.auth.userData

const isPending: SliceSelector<boolean> = () => (state) => state.auth.pending

export const AuthSelectors = {
  getToken,
  getUserData,
  isPending,
}
