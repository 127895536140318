import { CouponCodeStatus, ICheckoutPayment, PaymentMethod } from 'meta/pages/checkout'
import { fetchRecap } from 'store/pages/checkout/actions/fetchRecap'
import { CheckoutState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

export const setRecapFetched: SliceUpdater<CheckoutState, ReturnType<typeof fetchRecap.fulfilled>> = (
  state,
  action
) => {
  const { recap } = action.payload
  return {
    ...state,
    recap: recap,
    payment: {
      ...state.payment,
      couponCodeError: '',
      couponCodeStatus:
        state.payment && state.payment.methodCurrent === PaymentMethod.voucher && recap.extra
          ? recap.extra?.voucherDetails?.status
          : undefined,
      couponCodeAmount:
        recap.extra && recap.extra.voucherDetails
          ? recap.extra.voucherDetails.amountAvailable.formatted
          : state.payment?.couponCodeAmount,
    } as ICheckoutPayment,
  }
}

export const setRecapFetchError: SliceUpdater<CheckoutState, ReturnType<typeof fetchRecap.rejected>> = (
  state,
  action
) => {
  return {
    ...state,
    payment: {
      ...(state.payment as ICheckoutPayment),
      couponCodeError: `${action.error.code} ${action.error.message}`,
      couponCodeStatus:
        state.payment && state.payment.methodCurrent === PaymentMethod.voucher
          ? CouponCodeStatus.invalid
          : state.payment?.couponCodeStatus,
    },
  }
}
