import css from './Checkbox.module.scss'
import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface Props {
  checked: boolean
  id?: string
  label: ReactNode
  onChange: (checked: boolean) => void
}

const Checkbox: React.FC<Props> = (props) => {
  const { checked, id, label, onChange } = props

  return (
    <div className={css.checkboxWrapper} data-testid={id} id={id} onClick={() => onChange(!checked)}>
      <div className={classNames(css.checkbox, { [css.checked]: checked })}>
        <div />
      </div>
      <div>{label}</div>
    </div>
  )
}

export default Checkbox
