export interface ICardItem {
  id: string
  lastDigits: string
  expiry: string
  brand: string
}

export class CreditCardsAdapter {
  static convertResponse(data: Array<any>): Array<ICardItem> {
    if (!data) return []

    return data.map((ccData) => {
      return {
        id: ccData.paymentMethodId,
        lastDigits: ccData.lastDigits,
        expiry: `${('0' + ccData.expiryMonth).slice(-2)} / ${ccData.expiryYear}`,
        brand: ccData.brand,
      }
    })
  }
}
