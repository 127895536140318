import css from './DescriptionReadMore.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'

import { ComponentType } from 'meta/backendControlled/structure'
import { detectIfJSON } from 'utils/string'

import Img from 'components/Media/Img'

interface IPropsDescriptionReadMore {
  description: string
  component: ComponentType
}

const DescriptionReadMore: React.FC<IPropsDescriptionReadMore> = (props) => {
  const { component, description } = props

  const { t } = useTranslation()
  const [showReadMode, setShowReadMore] = useState<boolean>(false)
  const [showAllText, setShowAllText] = useState<boolean>(false)
  const descriptionRef = useRef<HTMLDivElement>(null)

  const isJSON = detectIfJSON(description)
  const __html = isJSON ? new QuillDeltaToHtmlConverter(JSON.parse(description).ops, {}).convert() : description

  useEffect(() => {
    if (descriptionRef.current) {
      const { clientHeight, scrollHeight } = descriptionRef.current
      if (clientHeight < scrollHeight) setShowReadMore(true)
    }
  }, [])

  return (
    <div className={classNames(css.descriptionReadMode, css[component])}>
      <div
        className={classNames(css.descriptionReadMode__text, { [css.showAll]: showAllText })}
        dangerouslySetInnerHTML={{ __html }}
        ref={descriptionRef}
      />

      {showReadMode && (
        <button className={css.descriptionReadMode__readMode} onClick={() => setShowAllText((prev) => !prev)}>
          <strong>
            {showAllText ? t('common:location_description_readless') : t('common:location_description_readmore')}
          </strong>
          <Img src={showAllText ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
        </button>
      )}
    </div>
  )
}

export default DescriptionReadMore
