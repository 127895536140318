import css from './SeasonListItem.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionFromIframe, CollectionTypeTicket } from 'meta/pages/collection'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { Dates } from 'utils/dates'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

const SeasonListItem: React.FC<CollectionTypeTicket & CollectionFromIframe> = (props) => {
  const {
    id,
    collectionId,
    coverImages,
    fromDate_tz,
    toDate_tz,
    numEvents,
    name,
    priceFrom,
    fromIframe,
    toggleContent,
  } = props

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const isDisabled = Boolean(priceFrom) == false

  const urlCloudfront = useUrlCloudfront()

  const placeholderImgUrl = `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  const openCheckout = () => {
    fromIframe && toggleContent && toggleContent()

    !isDisabled &&
      dispatch(
        CheckoutActions.init({
          codeUrl: collectionId,
          typeTicketCollectionId: String(id),
          insideIFrame: Boolean(fromIframe),
        })
      )
  }

  return (
    <div className={classNames(css.eventListItemContainer, { [css.disabled]: isDisabled })} onClick={openCheckout}>
      <div className={css.infoContainer}>
        <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
          <div className={css.mobileImgContainer}>
            {coverImages.length > 0 && <img src={coverImages[0].url} className={css.image} />}
            {coverImages.length > 0 && <div className={css.bgBlackOverlay} />}
            {coverImages.length == 0 && <img src={placeholderImgUrl} className={css.image} />}
            <div className={css.text}>
              <div className={css.from}>
                <span className={css.txt}>{t('collection:season_tickets_dates_from')}</span>
                <span className={css.dateFrom}>
                  {Dates.format(fromDate_tz, t('dates:common:collection_season_mobile_from'))}
                </span>
              </div>
              <div className={css.to}>
                <span className={css.txt}>{t('collection:season_tickets_dates_to')}</span>
                <span className={css.dateTo}>
                  {Dates.format(toDate_tz, t('dates:common:collection_season_mobile_to'))}
                </span>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
          {coverImages.length > 0 && <img src={coverImages[0].url} className={css.image} />}
          {coverImages.length == 0 && <img src={placeholderImgUrl} className={css.image} />}
        </MediaQuery>

        <div className={css.details}>
          <h3 className={css.name}>{name}</h3>
          <div className={css.seasonTicketsfor}>
            {t('collection:season_tickets_for')}
            <span>{t('collection:season_tickets_no_events', { num: numEvents })}</span>
          </div>
          <div className={css.detailed}>
            <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
              <span className={css.datesNo}>{t('collection:season_tickets_no_dates', { num: numEvents })}</span>
              {!isDisabled && (
                <>
                  {t('collection:starting_from')}
                  <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span>
                </>
              )}
              {isDisabled && (
                <>
                  <span className={css.notAvailable}>{t('collection:tickets')}</span>
                  <span className={css.priceFrom}>{t('collection:not_available')}</span>
                </>
              )}
            </MediaQuery>
            <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
              <span className={css.datesNo}>{t('collection:season_tickets_no_dates', { num: numEvents })}</span>
              {t('collection:season_tickets_dates_from')}
              <span className={css.dateFrom}>
                {Dates.format(fromDate_tz, t('dates:common:collection_season_from'))}
              </span>
              {t('collection:season_tickets_dates_to')}
              <span className={css.dateTo}>{Dates.format(toDate_tz, t('dates:common:collection_season_to'))}</span>
              {t('collection:season_tickets_dates_dot')}
              {!isDisabled && (
                <>
                  {t('collection:price_from')}
                  <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span>
                </>
              )}
              {isDisabled && (
                <>
                  {t('collection:tickets')}
                  <span className={css.priceFrom}>{t('collection:not_available')}</span>
                </>
              )}
            </MediaQuery>
          </div>
        </div>
      </div>

      {!isDisabled && (
        <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
          <div className={css.action}>
            <ButtonWhiteBlack className={css.button} onClick={openCheckout}>
              <h5>{fromIframe ? t('collection:purchase') : t('collection:see_season_ticket')}</h5>{' '}
              <Img alt="" src="/icons/arrow_right.svg" />
            </ButtonWhiteBlack>
          </div>
        </MediaQuery>
      )}
    </div>
  )
}

export default SeasonListItem
