import css from 'components/Pages/SupportConversation/ConversationMessage/ConversationMessage.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_RED, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

type Props = {
  isOperator: boolean
}

export const ConversationMessageSkeleton: React.FC<Props> = (props: Props) => {
  const { isOperator } = props
  return (
    <div className={classNames(css.messageBoxWrapper, isOperator && css.operator)}>
      <div className={css.messageBox}>
        <div className={css.dateBox}>
          <div className={css.dateMarker} />
          <div className={css.date__skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>

        <div className={css.messageContentBox}>
          <div className={css.messageUserBox}>
            <div className={classNames(css.img__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_RED}
                borderRadius={50}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_LIGHTGREY}
              />
            </div>
            <div className={css.messageUserNameBox}>
              {isOperator && (
                <div className={css.messageUserName__skeleton}>
                  <Skeleton
                    baseColor={TICKETSMS_COLORS_LIGHTGREY}
                    borderRadius={8}
                    height={'100%'}
                    highlightColor={TICKETSMS_COLORS_WHITE}
                  />
                </div>
              )}
              <div className={css.messageUserName__skeleton}>
                <div>
                  <Skeleton
                    baseColor={TICKETSMS_COLORS_LIGHTGREY}
                    borderRadius={8}
                    height={'100%'}
                    highlightColor={TICKETSMS_COLORS_WHITE}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.messageText}>
            <div className={classNames(css.messageText__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
            <div className={classNames(css.messageText__skeleton, css.messageText__sibling__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>

            <div className={classNames(css.messageText__skeleton, css.messageText__sibling__skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={8}
                height={'100%'}
                width={'80%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
          <div className={css.messageLogo}>
            {isOperator && (
              <div className={classNames(css.logo__skeleton)}>
                <Skeleton
                  baseColor={TICKETSMS_COLORS_LIGHTGREY}
                  borderRadius={8}
                  height={'100%'}
                  width={'100%'}
                  highlightColor={TICKETSMS_COLORS_WHITE}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={css.messageBoxTip} />
    </div>
  )
}
