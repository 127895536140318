import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { LanguageCode, Languages } from 'meta/app/translate'

import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useChangeLanguage } from 'hooks/useI18n'

export const useUrlParamLangUpdateListener = (): void => {
  const { lang: urlLanguage } = useParams<{ lang?: string }>()
  const currentLanguage = useCurrentLanguage()
  const changeLanguage = useChangeLanguage()
  const validLanguage = urlLanguage && Object.keys(Languages).includes(urlLanguage)

  useEffect(() => {
    if (urlLanguage && validLanguage) {
      changeLanguage(urlLanguage as LanguageCode)
    }
  }, [changeLanguage, urlLanguage, validLanguage])

  useEffect(() => {
    if (currentLanguage) {
      document.documentElement.lang = currentLanguage
    }
  }, [currentLanguage])
}
