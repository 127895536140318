import { startProcess } from 'store/pages/checkout/actions/startProcess'
import { CheckoutState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

export const setProcessStarted: SliceUpdater<CheckoutState, ReturnType<typeof startProcess.pending>> = (state) => {
  return { ...state, process: { ...state.process, processingStarted: true } }
}

export const setProcessCompleted: SliceUpdater<CheckoutState, ReturnType<typeof startProcess.fulfilled>> = (
  state,
  action
) => {
  const { data, metadata } = action.payload
  return { ...state, process: { ...state.process, data, metadata } }
}

export const setProcessError: SliceUpdater<CheckoutState, ReturnType<typeof startProcess.rejected>> = (
  state,
  action
) => {
  const { code, message } = action.error
  const error = `${code} ${message}`
  return { ...state, process: { ...state.process, error, status: 500 } }
}
