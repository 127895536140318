import css from './Voice.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'

interface IProps {
  name: string
  isActive: boolean
  setActiveMenuVoiceCallback(voice: string): void
}

const Voice: React.FC<IProps> = (props) => {
  const { name, isActive, setActiveMenuVoiceCallback } = props
  const { t } = useTranslation()
  return (
    <div
      className={`${css.pagewithsubsections_voices_voice_container} ${isActive && css.active}`}
      onClick={() => {
        setActiveMenuVoiceCallback(name)
      }}
    >
      <div className={css.name}>{t(String(`profile:${name}`))}</div>
      {isActive && (
        <div className={css.active_voice}>
          <Img src="/profile/active_voice.svg" />
        </div>
      )}
    </div>
  )
}

export default Voice
