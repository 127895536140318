import css from './CategorySkeleton.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { CategorySkeleton as CategorySkeletonType } from 'meta/backendControlled/structure'
import { TICKETSMS_COLORS_BLACK, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import ComponentList from 'components/BackendControlled/ComponentList'
import { Flexbox, Grid, GridColumn } from 'components/Layout/Grid'

export const CategorySkeleton: React.FC<IPropsComponent<CategorySkeletonType>> = (props) => {
  const { data, title, titleColor } = props

  const titleBaseColor = titleColor === 'black' ? TICKETSMS_COLORS_MEDIUMGREY : TICKETSMS_COLORS_WHITE
  const titleHighlightColor = titleColor === 'black' ? TICKETSMS_COLORS_BLACK : TICKETSMS_COLORS_WHITE

  return (
    <Grid>
      <GridColumn gridColumnEnd={17}>
        {/*{title && <Header button={button} data={data} title={title} visibleIdx={visibleIdx} />}*/}
        {title && (
          <div className={css.header}>
            <Skeleton
              baseColor={titleBaseColor}
              borderRadius={6}
              height={'100%'}
              highlightColor={titleHighlightColor}
            />
          </div>
        )}

        <Flexbox>
          <ComponentList components={data} />
        </Flexbox>
      </GridColumn>
    </Grid>
  )
}
