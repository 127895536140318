import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IFaqSection } from 'meta/pages/faq'

type StructuredData = {
  '@context': string
  '@type': string
  mainEntity: {
    '@type': string
    name: string
    acceptedAnswer: {
      '@type': string
      text: string
    }
  }
}

enum Section {
  method = 'method',
  events = 'events',
  entry = 'entry',
  ticket = 'ticket',
  resale = 'resale',
  change_name = 'change_name',
  voucher_covid = 'voucher_covid',
  refunds = 'refunds',
  bonus = 'bonus',
}

const sectionsMetadata: Record<Section, number> = Object.freeze({
  [Section.method]: 11,
  [Section.events]: 7,
  [Section.entry]: 7,
  [Section.ticket]: 19,
  [Section.resale]: 9,
  [Section.change_name]: 7,
  [Section.voucher_covid]: 4,
  [Section.refunds]: 7,
  [Section.bonus]: 7,
})

type Returned = {
  sections: IFaqSection[]
  structuredData: StructuredData[]
}

export const useGenerateFAQs = (): Returned => {
  const { t } = useTranslation()

  const sections = useMemo<IFaqSection[]>(
    () =>
      Object.entries(sectionsMetadata).map(([section, questions]) => ({
        title: t(`faq:${section}`),
        questions: Array.from(Array(questions).keys()).map((idx) => ({
          question: t(`faq:${section}_q${idx}`),
          answer: t(`faq:${section}_a${idx}`),
        })),
      })),
    [t]
  )

  const structuredData = useMemo<StructuredData[]>(
    () =>
      Object.entries(sectionsMetadata).reduce<StructuredData[]>((data, [section, questions]) => {
        if (section === Section.voucher_covid) return data
        return [
          ...data,
          ...Array.from(Array(questions).keys()).map((idx) => ({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: {
              '@type': 'Question',
              name: t(`faq:${section}_q${idx}`),
              acceptedAnswer: {
                '@type': 'Answer',
                'text': t(`faq:${section}_a${idx}`),
              },
            },
          })),
        ]
      }, []),
    [t]
  )

  return { sections, structuredData }
}
