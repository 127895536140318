import css from './ButtonStatus.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ITicket } from 'meta/pages/tickets'

import Img from 'components/Media/Img'

import { scrollToTicket } from '../../utils'

export enum TicketStatus {
  error = 'error',
  valid = 'valid',
}

interface IPropsTicketsStatus {
  idx: number
  highlight: boolean | null
  onMouseEnter(): void
  small: boolean
  status: TicketStatus | null
  ticket: ITicket
}

const ButtonStatus: React.FC<IPropsTicketsStatus> = (props) => {
  const { idx, highlight, onMouseEnter, small, status, ticket } = props

  return (
    <button
      key={String(idx)}
      className={classNames(css.ticketsRecapStatusBtn, status && css[status], {
        [css.small]: small,
        [css.highlight]: highlight,
      })}
      onClick={() => scrollToTicket(ticket, idx)}
      onMouseEnter={onMouseEnter}
      type="button"
    >
      <div className={css.ticketsRecapStatusBtn__ticket}>
        <Img alt="" className={css.valid} src={`/textboxes/textboxes_success.svg`} />
        <Img alt="" className={css.error} src={`/textboxes/textboxes_error.svg`} />
      </div>
      <div className={css.ticketsRecapStatusBtn__info}>
        <h5>{idx + 1}</h5>
        <div>{ticket.title}</div>
        {!small && <div>{ticket.details}</div>}
      </div>
    </button>
  )
}

export default ButtonStatus
