import css from './Mobile.module.scss'
import React, { useState } from 'react'

import { ICheckoutSeasonItem } from 'meta/pages/checkout'

import Item from '../Item'
import Paginator from '../Paginator/Paginator'
import { getSeasonItemsDividedByPage } from '../utils'

interface Props {
  data: ICheckoutSeasonItem[]
}

const Mobile: React.FC<Props> = (props) => {
  const { data } = props

  const MAX_ITEMS_PER_PAGE = 4

  const dividedItems = getSeasonItemsDividedByPage(data, MAX_ITEMS_PER_PAGE)

  const [currentPage, setCurrentPage] = useState<number>(0)

  return (
    <>
      <div className={css.checkout_season_items_mobile_container}>
        {dividedItems?.[currentPage]?.map((elem, key) => <Item key={key} {...elem} />)}
      </div>

      {dividedItems.length > 1 && (
        <Paginator total={dividedItems.length} current={currentPage} setCurrent={setCurrentPage} />
      )}
    </>
  )
}

export default Mobile
