import css from './Faq.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Head from 'components/common/Head'
import withTranslation from 'components/HOC/WithTranslation'
import Content from 'components/Pages/FAQ/Content'

import { useGenerateFAQs } from './hooks/useGenerateFAQs'

const Faq: React.FC = () => {
  const { t } = useTranslation()

  const { sections, structuredData } = useGenerateFAQs()

  return (
    <>
      <Head>
        <title>{String(t(`faq:meta_title`))}</title>

        <meta property="og:title" content={String(t(`faq:meta_title`))} />
        <meta name="description" content={String(t(`faq:meta_description`))} />
        <meta property="og:description" content={String(t(`faq:meta_description`))} />

        {structuredData.map((data, idx) => (
          <script key={idx} type="application/ld+json">
            {JSON.stringify(data)}
          </script>
        ))}
      </Head>

      <div className={css.faqContainer}>
        <div className={css.faqContentContainer}>
          <Content data={sections} />
        </div>
      </div>
    </>
  )
}

const namespaces = ['faq']

export default withTranslation(namespaces)(Faq)
