import css from './None.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { Breakpoints } from 'meta/layout/breakpoints'
import { LoginStep } from 'meta/pages/login'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

import { IStepProps, StepColumn } from '../StepCommon'
import { Apple, Google } from './External'
import Internal from './Internal'

const None: React.FC<IStepProps> = (props) => {
  const { fromModal, onSuccess } = props
  const { t } = useTranslation()

  return (
    <>
      {!fromModal && (
        <GridColumn className={css.login__logo} gridColumnEnd={17}>
          <Img alt="Ticket Sms" src="/login/logo-pittogramma-bianco.png" />
        </GridColumn>
      )}

      <StepColumn className={css.login__loginWith}>
        <h2>{t('loginV2:login_with_label')}</h2>
      </StepColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.laptop]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 9 }}
        className={css.login__externals}
      >
        {/* <Facebook onSuccess={onSuccess} /> */}
        <Google onSuccess={onSuccess} />
        <Apple onSuccess={onSuccess} />
      </GridColumn>

      <StepColumn className={css.login__loginOr}>
        <h2>{t('loginV2:or')}</h2>
      </StepColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.laptop]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.laptop]: 9 }}
        className={css.login__internals}
      >
        <Internal
          dataTestId={DataTestId.login.btnPhone}
          icon="/login/method_phone.svg"
          labelKey="loginV2:login_with_phone"
          step={LoginStep.phone}
        />
        <Internal
          dataTestId={DataTestId.login.btnEmail}
          icon="/login/method_email.svg"
          labelKey="loginV2:login_with_email"
          step={LoginStep.email}
        />
      </GridColumn>
    </>
  )
}

export default None
