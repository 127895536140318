import css from '../Images.module.scss'
import React, { useRef } from 'react'
import MediaQuery from 'react-responsive'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionAsset } from 'meta/pages/collection'

import { ActionFormat, ButtonWhite } from 'components/Actions/Action'
import useSwiperScroll from 'components/BackendControlled/Component/Category/Showcase/Single/useSwiperScroll'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

import Item from '../Item'

interface Props {
  images: CollectionAsset[]
  setVisible(num: number): void
}

const Desktop: React.FC<Props> = (props) => {
  const { images, setVisible } = props

  const swiperRef = useRef<HTMLDivElement>(null)
  const swiperEdges = useSwiperScroll(swiperRef)

  const scrollTo = (element: HTMLDivElement): void => {
    swiperRef.current?.scrollTo({ left: element.offsetLeft, behavior: 'smooth', top: 0 })
  }
  const onPrev = (): void => {
    if (swiperEdges.first?.node) scrollTo(swiperEdges.first.node)
  }

  const onNext = (): void => {
    if (swiperEdges.last?.node) scrollTo(swiperEdges.last.node)
  }

  return (
    <GridColumn
      className={css.gridContainer}
      gridColumnStart={{ [Breakpoints.tablet]: 10, [Breakpoints.mobile]: 1 }}
      gridColumnEnd={17}
    >
      <div className={css.galleryContainer} ref={swiperRef}>
        {images.map((elem, key) => (
          <Item setVisible={setVisible} key={key} num={key} url={elem.url} />
        ))}
      </div>

      <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
        <div className={css.btnContainer}>
          <>
            <ButtonWhite
              className={css.btnPrev}
              disabled={!swiperEdges.first}
              format={ActionFormat.circle}
              onClick={onPrev}
            >
              <Img src="/carousel/controls/prev.png" />
            </ButtonWhite>
            <ButtonWhite
              className={css.btnNext}
              disabled={!swiperEdges.last}
              format={ActionFormat.circle}
              onClick={onNext}
            >
              <Img src="/carousel/controls/next.png" />
            </ButtonWhite>
          </>
        </div>
      </MediaQuery>
    </GridColumn>
  )
}

export default Desktop
