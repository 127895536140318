import css from './ChangeName.module.scss'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import TermsSkeleton from 'components/Pages/Terms/TermsSkeleton'
import TransparentWrapper from 'components/Structure/TransparentWrapper'

const ChangeName: React.FC = () => {
  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()

  return (
    <>
      <Head>
        <title>{String(t('changeName:meta_title'))}</title>

        <meta property="og:title" content={String(t('changeName:meta_title'))} />
        <meta name="description" content={String(t('changeName:meta_desc'))} />
        <meta property="og:description" content={String(t('changeName:meta_desc'))} />
      </Head>

      <div className={css.container}>
        <TransparentWrapper>
          <WithAppInitialized showChildren={namespacesLoaded} skeleton={<TermsSkeleton />}>
            <div className={css.innerContainer}>
              <div className={css.static_page_title}>
                <Img src="/change_name/change_name.svg" />
                <div>{t('changeName:changeName')}</div>
              </div>
              <div className={css.static_page_text}>
                <p>
                  <Trans i18nKey="changeName:text1" components={{ a: <a />, b: <strong /> }} />
                </p>
                <p>
                  <Trans i18nKey="changeName:text2" components={{ b: <strong /> }} />
                </p>
                <p>
                  <Trans i18nKey="changeName:text3" components={{ b: <strong /> }} />
                </p>
                <p>
                  <Trans i18nKey="changeName:text4" components={{ b: <strong /> }} />
                </p>
                <p>
                  <Trans i18nKey="changeName:text5" components={{ b: <strong /> }} />
                </p>
                <p>
                  <Trans i18nKey="changeName:text6" components={{ b: <strong /> }} />
                </p>
              </div>
            </div>
          </WithAppInitialized>
        </TransparentWrapper>
      </div>
    </>
  )
}
const namespaces = ['changeName']
export default withTranslation(namespaces)(ChangeName)
