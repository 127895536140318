import css from './Form.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_RED, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

type Props = {
  items: number
}

export const FormSkeleton: React.FC<Props> = (props: Props) => {
  const { items } = props

  const list = Array.from({ length: items })

  return (
    <div className={classNames(css.form__container__skeleton)}>
      {list.map((item, index) => (
        <div className={classNames(css.form__element__skeleton, index !== 0 && css.form__element__margin__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_WHITE}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          />
        </div>
      ))}

      <div className={classNames(css.form__submit__skeleton, css.form__element__margin__skeleton)}>
        <Skeleton
          baseColor={TICKETSMS_COLORS_RED}
          borderRadius={16}
          height={'100%'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      </div>
    </div>
  )
}
