import { DVMMap3DViewer } from './dvm/map3DViewer'
import { DVMMapViewerMediator } from './dvm/mapViewerMediator'
import { IMap, IMapSectors } from './map'
import { Map3DViewer, MapViewerMediator } from './mapViewer'

export class MapViewerFactory {
  static async createMapViewerMediator(
    map: IMap,
    persistSelectSeat: boolean,
    container2D: HTMLDivElement,
    container3D?: HTMLDivElement,
    sectors?: IMapSectors
  ): Promise<MapViewerMediator> {
    const mediator = new DVMMapViewerMediator(map, persistSelectSeat, sectors)
    await mediator.init(container2D, container3D)
    return mediator
  }

  static async createMap3DViewer(container: HTMLDivElement): Promise<Map3DViewer> {
    const mapViewer = new DVMMap3DViewer()
    await mapViewer.init(container)
    return mapViewer
  }
}
