import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldCheckbox extends IFormField<FormFieldType.checkbox> {
  useT: boolean
}

export class CheckboxBuilder extends FormFieldBuilder<FormFieldType.checkbox> {
  constructor(name: string) {
    super(name, FormFieldType.checkbox)
    this.addField('useT', true)
  }

  setUseT(useT: boolean): FormFieldBuilder<FormFieldType.checkbox> {
    this.addField('useT', useT)
    return this
  }
}
