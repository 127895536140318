import css from './StripeBox.module.scss'
import React, { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { usePost } from 'api'
import classNames from 'classnames'

import { ICheckoutCC } from 'meta/pages/checkout'
import { ICardItem } from 'meta/pages/profile'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import { default as ErrorBox } from 'components/Checkout/PaymentMethodDialogError'
import CCList from 'components/Checkout/PaymentMethods/StripeBox/CCList'
import Checkbox from 'components/Forms/Checkbox'
import Img from 'components/Media/Img'
import { useAuthToken } from 'hooks/useAuth'

interface IProps {
  goBackCallback(): void
  setCCDataCallback(data: ICheckoutCC): void
  resetCCDataCallback(): void
  currentCCData: ICheckoutCC
  cardList: ICardItem[]
}

const StripeBox: React.FC<IProps> = (props) => {
  const { cardList, goBackCallback, setCCDataCallback, resetCCDataCallback, currentCCData } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authToken = useAuthToken()
  const userLoggedIn = Boolean(authToken)
  const { fetch: fetchP } = usePost()

  const [showCardList, setShowCardList] = useState<boolean>(cardList && cardList.length > 0)

  const [cardNumberOk, setCardNumberOk] = useState<boolean>(false)
  const [cardExpiryOk, setCardExpiryOk] = useState<boolean>(false)
  const [cardCvcOk, setCardCvcOk] = useState<boolean>(false)
  const [saveCreditCard, setSaveCreditCard] = useState<boolean>(false)

  const [formValid, setFormValid] = useState<boolean>(false)
  const [reviewInProgress, setReviewInProgress] = useState<boolean>(false)

  const [ccError, setCCError] = useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()

    setReviewInProgress(true)

    if (formValid) {
      if (!stripe || !elements) throw new Error('Stripe cannot be null')

      const pmData = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      })

      if (!pmData.error) {
        dispatch(
          CheckoutActions.updateStripe({ stripe: { paymentMethod: pmData.paymentMethod.id, paymentIntent: undefined } })
        )

        if (userLoggedIn && saveCreditCard) {
          await fetchP({ authToken, urlResource: ApiEndpoint.users.saveCard(pmData.paymentMethod.id) })
        }

        // chiamare la callback setCCDataCallback
        setCCDataCallback({
          description: `${pmData?.paymentMethod?.card?.brand?.toUpperCase()} ****${pmData?.paymentMethod?.card?.last4}`,
          expiry: `${pmData?.paymentMethod?.card?.exp_month}/${pmData?.paymentMethod?.card?.exp_year}`,
          brand: `${pmData?.paymentMethod?.card?.brand}`,
        })
      } else {
        setCCError(true)
      }
    }
  }

  const checkField = (
    event: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
    func: Dispatch<SetStateAction<boolean>>
  ) => {
    const check = event.complete && !event.error && !event.empty
    func(check)
  }

  useEffect(() => {
    setFormValid(cardNumberOk && cardExpiryOk && cardCvcOk)
  }, [cardNumberOk, cardExpiryOk, cardCvcOk])

  if (showCardList && cardList.length > 0 && !currentCCData) {
    return (
      <CCList
        goBackCallback={goBackCallback}
        setCCDataCallback={setCCDataCallback}
        setShowCardList={setShowCardList}
        cardList={cardList}
      />
    )
  }

  return (
    <div className={css.stripeBox_container}>
      <div className={css.header_wrapper}>
        <div
          className={css.goBackWrapper}
          onClick={() => {
            if (cardList && cardList.length > 0) {
              resetCCDataCallback()
              setShowCardList(true)
            } else {
              goBackCallback()
            }
          }}
        >
          <div className={css.goBack}>
            <Img alt="" src="/arrows/white-left.svg" />
          </div>
          <h3>{t('checkout:stripe_insert_card_details')}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={css.row}>
          <div
            className={classNames(
              css.inputWrapper,
              { [css.errorField]: !cardNumberOk && reviewInProgress },
              { [css.okField]: cardNumberOk }
            )}
          >
            <CardNumberElement
              className={css.inputBox}
              onChange={(event) => {
                checkField(event, setCardNumberOk)
              }}
            />
            {!cardNumberOk && reviewInProgress && (
              <span className={css.error}>{t('checkout:stripe_error_card_number')}</span>
            )}
          </div>
        </div>

        <div className={css.row}>
          <div
            className={classNames(
              css.inputWrapper,
              { [css.errorField]: !cardExpiryOk && reviewInProgress },
              { [css.okField]: cardExpiryOk }
            )}
          >
            <CardExpiryElement
              className={css.halfBoxWithMargin}
              onChange={(event) => {
                checkField(event, setCardExpiryOk)
              }}
            />
          </div>

          <div
            className={classNames(
              css.inputWrapper,
              { [css.errorField]: !cardCvcOk && reviewInProgress },
              { [css.okField]: cardCvcOk }
            )}
          >
            <CardCvcElement
              className={css.halfBox}
              onChange={(event) => {
                checkField(event, setCardCvcOk)
              }}
            />
          </div>
        </div>

        <div className={css.error_row}>
          {!cardExpiryOk && reviewInProgress && <span className={css.error}>{t('checkout:stripe_error_expiry')}</span>}

          {!cardCvcOk && reviewInProgress && <span className={css.error}>{t('checkout:stripe_error_cvc')}</span>}
        </div>

        {userLoggedIn && (
          <div className={`${css.row} ${css.saveCCInfo}`}>
            <Checkbox checked={saveCreditCard} label={t('checkout:save_cc_info')} onChange={setSaveCreditCard} />
          </div>
        )}

        <div className={css.row}>
          <div className={classNames(css.buttonWrapper)}>
            <button className={css.button} type="submit">
              {t('checkout:stripe_confirm_cc')}
            </button>
          </div>
        </div>

        {ccError && <ErrorBox messageKey="checkout:invalid_credit_card" onClose={() => setCCError(false)} />}
      </form>
    </div>
  )
}

export default StripeBox
