import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Params = {
  paymentGateway: string
  paymentGatewayData: Record<string, string>
  transactionCode: string
}

export const cancel = async (params: Params): Promise<void> => {
  await HttpRequest.post<void, Params>(ApiEndpoint.checkout.cancel(), params)
}
