import { getUA } from 'react-device-detect'

import i18n from 'i18n'
import { RequestMethod } from 'meta/api/requestMethod'
import { Dates } from 'utils/dates'

export interface IRequestParams {
  authToken?: string
  locale?: string
  urlBase?: string
  urlResource?: string
  userAgent?: string
  otpConfigKey?: string
  useProxy?: boolean
  extraHeaders?: object
}

export interface IGetParams extends IRequestParams {}

export interface IPostParams extends IRequestParams {
  body?: object
}

export interface IFetch<T extends IRequestParams> {
  //@ŧs-ignore
  (arg0: T): Promise<Response>
}

export const _fetch = (params: IRequestParams, method: RequestMethod): Promise<Response> => {
  const { authToken, locale, urlBase, urlResource, userAgent, extraHeaders } = params

  if (!urlBase) throw new Error('urlBase must be specified')

  const clientSideUA = getUA

  let headers = {
    'TimeZone': Dates.guessTimeZone(),
    'Content-Type': 'application/json',
    'Accept-Language': locale || i18n.resolvedLanguage || 'it',
    'Authorization': authToken ? `Bearer ${authToken}` : '',
    'User-Agent': userAgent ? userAgent : clientSideUA,
  }

  if (extraHeaders) {
    headers = {
      ...headers,
      ...extraHeaders,
    }
  }

  const input: RequestInfo = `${urlBase}${urlResource || ''}`
  const init: RequestInit = {
    method,
    headers: headers,
  }

  const { body } = params as IPostParams
  if (body) init.body = JSON.stringify(body)

  return fetch(input, init)
}

export const get: IFetch<IGetParams> = (params: IGetParams): Promise<Response> => _fetch(params, RequestMethod.get)
export const patch: IFetch<IGetParams> = (params: IPostParams): Promise<Response> => _fetch(params, RequestMethod.patch)
export const post: IFetch<IGetParams> = (params: IPostParams): Promise<Response> => _fetch(params, RequestMethod.post)
export const callDelete: IFetch<IGetParams> = (params: IPostParams): Promise<Response> =>
  _fetch(params, RequestMethod.delete)
