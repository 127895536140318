import css from './Desktop.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CheckoutStep } from 'meta/pages/checkout'

import Cover from 'components/Checkout/EventDetails/Cover'
import Description from 'components/Checkout/EventDetails/Description'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useCheckoutStep } from 'hooks/useCheckout'

interface IProps {
  goBackCallback(): void
  showCloseButton: boolean
  fromCollectionIframe?: boolean
}

const Desktop: React.FC<IProps> = (props) => {
  const { goBackCallback, showCloseButton, fromCollectionIframe } = props

  const step = useCheckoutStep()

  return (
    <GridContainer>
      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.tablet]: fromCollectionIframe ? 2 : 1 }}
          gridColumnEnd={17}
        >
          <div className={css.checkout_eventdetails_desktop_container}>
            <div className={css.details}>
              <Cover />
              <Description />
            </div>

            {showCloseButton && (
              <div className={css.goback} onClick={goBackCallback}>
                <Img
                  src={step === CheckoutStep.select ? '/icons/close_icon_white.svg' : '/icons/goback_icon_white.svg'}
                />
              </div>
            )}
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default Desktop
