import React from 'react'

import Modal from 'components/Layout/Modal'

import LoginGridContainer from './LoginGridContainer'
import { IPropsLogin } from './props'

const LoginV2: React.FC<IPropsLogin> = (props) => {
  const { fromModal, onClose } = props

  if (fromModal) return <LoginGridContainer {...props} />

  return (
    <>
      {/*@ts-ignore */}
      <Modal fullHeight={true} handleClose={onClose} hideCloseIcon show={true} transparent>
        <LoginGridContainer {...props} />
      </Modal>
    </>
  )
}

export default LoginV2
