import { IWalletTicket } from 'meta/pages/wallet'

export type WalletState = {
  tickets: {
    old: Array<IWalletTicket> | undefined
    new: Array<IWalletTicket> | undefined
  }
}

export const initialState: WalletState = {
  tickets: {
    old: undefined,
    new: undefined,
  },
}
