import css from './Mobile.module.scss'
import React from 'react'

import { CheckoutStep } from 'meta/pages/checkout'

import Description from 'components/Checkout/EventDetails/Description'
import Img from 'components/Media/Img'
import { useCheckoutStep } from 'hooks/useCheckout'

interface IProps {
  goBackCallback(): void
  showCloseButton: boolean
}

const Mobile: React.FC<IProps> = (props) => {
  const { goBackCallback, showCloseButton } = props

  const step = useCheckoutStep()

  return (
    <div className={css.checkout_eventdetails_mobile_container}>
      <div className={css.details}>
        <Description />
      </div>

      {showCloseButton && (
        <div className={css.goback} onClick={goBackCallback}>
          <Img src={step === CheckoutStep.select ? '/icons/close_icon_white.svg' : '/icons/goback_icon.svg'} />
        </div>
      )}
    </div>
  )
}

export default Mobile
