import { useNavigate } from 'react-router-dom'

import { Routes } from 'meta/routes'
import {
  APPOINTMENTS_FIELD_IDENTIFIER,
  DATALIST_DEFAULT_VALUES,
  PURCHASE_FORM_DIVIDER_TOKEN,
} from 'utils/constants/form'

import { IFormOnSuccess, IFormValues } from 'components/Forms/Form'

import {
  IPurchaseFormBuyerData,
  ITicketFormItem,
  ITicketsForm,
  PERSONAL_DATA_FIELDS,
  PersonalDataField,
} from '../types'

const getOrCreateTicketItem = (
  items: Array<ITicketFormItem>,
  ticketNo: number,
  typeTicketId: number
): ITicketFormItem => {
  let ticketFormItem: ITicketFormItem = items[ticketNo]

  if (!ticketFormItem) {
    ticketFormItem = {
      additionalInfos: [],
      appointments: [],
      buyerData: {
        quantity: 1,
        phonePrefix: '',
      },
      typeTicketId,
    }
    items[ticketNo] = ticketFormItem
  }
  return ticketFormItem
}

const getBodyItems = (values: IFormValues): Array<ITicketFormItem> =>
  Object.entries(values).reduce<Array<ITicketFormItem>>((items, [key, value]) => {
    const keySplit = key.split(PURCHASE_FORM_DIVIDER_TOKEN)
    const ticketNo = parseInt(keySplit[0])
    const typeTicketId = parseInt(keySplit[1])
    const fieldKey = keySplit[2]

    const ticketFormItem = getOrCreateTicketItem(items, ticketNo, typeTicketId)

    if (fieldKey === 'extra') {
      const extraFieldId = parseInt(keySplit[3])
      ticketFormItem.additionalInfos.push({
        id: extraFieldId,
        value,
      })
    } else if (fieldKey === APPOINTMENTS_FIELD_IDENTIFIER) {
      // TODO: gestire appuntamenti se dovessero essere necessari. Al momento non sono più in uso
      // const appointmentsValues = value.split(APPOINTMENTS_DIVIDER_TOKEN)
      //
      // ticketFormItem?.appointments?.push({
      //   uuid: appointmentsValues[0],
      //   appointmentSlotUuid: appointmentsValues[1],
      // })
    } else if (PERSONAL_DATA_FIELDS.includes(fieldKey as PersonalDataField)) {
      if (!ticketFormItem.personalData) {
        ticketFormItem.personalData = {}
      }

      if (!DATALIST_DEFAULT_VALUES.includes(value) && ticketFormItem.personalData) {
        ticketFormItem.personalData[fieldKey as PersonalDataField] = value
      }
    } else if (fieldKey === 'phone') {
      ticketFormItem.buyerData[fieldKey] = String(value).replace(/\D/g, '')
    } else if (ticketFormItem.buyerData) {
      // @ts-ignore
      ticketFormItem.buyerData[fieldKey as keyof IPurchaseFormBuyerData] = value
    }

    return items
  }, [])

export const useTicketsForm = (
  transactionCode: string,
  transactionToken: string,
  checkSecondaryTicketingIssues: (items: Array<ITicketFormItem>) => boolean
): ITicketsForm => {
  const navigate = useNavigate()

  const bodyParser = (fieldValues: IFormValues): object => {
    const items = getBodyItems(fieldValues)
    const areThereDuplicateTickets = checkSecondaryTicketingIssues(items)

    if (areThereDuplicateTickets) {
      return {}
    }

    return { transactionCode, transactionToken, items }
  }

  const onSuccess: IFormOnSuccess = () => {
    navigate(Routes.Success.generatePath())
  }

  return { bodyParser, onSuccess }
}
