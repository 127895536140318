import css from './Mobile.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const MobileSkeleton: React.FC = () => {
  return (
    <div className={classNames(css.pagewithsubsections_voices_mobile_container, css.skeleton)}>
      <Skeleton
        baseColor={TICKETSMS_COLORS_LIGHTGREY}
        borderRadius={32}
        height={'100%'}
        highlightColor={TICKETSMS_COLORS_WHITE}
      />
    </div>
  )
}
