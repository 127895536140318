import AppleSVG from 'assets/svg/calendar/apple.svg'
import GoogleSVG from 'assets/svg/calendar/google.svg'
import ICalSVG from 'assets/svg/calendar/ical.svg'
import Microsoft365SVG from 'assets/svg/calendar/microsoft365.svg'
import OutlookSVG from 'assets/svg/calendar/outlook.svg'

export enum CalendarType {
  APPLE = 'Apple',
  GOOGLE = 'Google',
  ICAL = 'iCal',
  OUTLOOK = 'Outlook.com',
  MICROSOFT365 = 'Microsoft365',
}

export const CalendarToSVG: Record<CalendarType, string> = {
  [CalendarType.APPLE]: AppleSVG,
  [CalendarType.GOOGLE]: GoogleSVG,
  [CalendarType.ICAL]: ICalSVG,
  [CalendarType.OUTLOOK]: OutlookSVG,
  [CalendarType.MICROSOFT365]: Microsoft365SVG,
}
