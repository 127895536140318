import css from 'components/Pages/ProfileTransactions/Transaction/Actions/Action/Action.module.scss'
import React from 'react'
import classNames from 'classnames'
import { TransactionActionButtonColors } from 'types/auth'

import DataTestId from 'meta/dataTestId'

import Img from 'components/Media/Img'

type Props = {
  color: TransactionActionButtonColors
  callback(): void
  text: string
  imgUrl: string
  paddingSmall?: boolean
  extraTestId?: string
}

const Action: React.FC<Props> = (props) => {
  const { color, imgUrl, extraTestId, paddingSmall, callback, text } = props
  return (
    <div data-testid={extraTestId ? extraTestId : undefined} className={css.transactionActionContainer}>
      <div className={css[color]}>
        <div
          className={classNames(css.button, { [css.paddingSmall]: Boolean(paddingSmall) })}
          onClick={callback}
          data-testid={DataTestId.profile.transactionTicketAction}
        >
          <div className={css.image}>
            <Img src={imgUrl} />
          </div>

          <div className={css.text}>{text}</div>
        </div>
      </div>
    </div>
  )
}

export default Action
