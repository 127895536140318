import cssModal from 'components/Layout/Modal/Modal.module.scss'
import { useEffect, useRef } from 'react'

import { useVisibleEventFeesCodeUrl } from 'hooks/useEvent'

export const useHandleBodyScroll = (): void => {
  const codeUrl = useVisibleEventFeesCodeUrl()
  const withinModalRef = useRef<boolean>(false)

  useEffect(() => {
    if (codeUrl) {
      if (document.body.classList.contains(cssModal.modal_active)) {
        withinModalRef.current = true
      } else {
        document.body.classList.add(cssModal.modal_active)
      }
    }

    if (!codeUrl) {
      if (!withinModalRef.current) {
        document.body.classList.remove(cssModal.modal_active)
      }
      withinModalRef.current = false
    }
  }, [codeUrl])
}
