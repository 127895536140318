import React, { PropsWithChildren } from 'react'

import { StepColumn } from './StepColumn'
import { StepImg } from './StepImg'
import { StepLabel } from './StepLabel'

type Props = {
  icon: string
  labelKey: string
}

export const Step: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, icon, labelKey } = props

  return (
    <>
      <StepImg src={icon} />
      <StepLabel labelKey={labelKey} />
      <StepColumn>{children}</StepColumn>
    </>
  )
}
