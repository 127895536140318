import css from './GoogleMapsInfoBox.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useGoogleMapsApiKey } from 'hooks/useEnv'

const GoogleMapsInfoBox: React.FC = () => {
  const { t } = useTranslation()
  const key = useGoogleMapsApiKey()

  if (key === '') return null

  return (
    <GridContainer>
      <Grid className={css.googleMapsInfoBox}>
        <GridColumn gridColumnEnd={17}>{t('common:find_event_on_map')}</GridColumn>
        <GridColumn gridColumnEnd={17}>
          <Img src="/icons/location_marker_white.svg" />
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default GoogleMapsInfoBox
