import { RefObject, useCallback, useState } from 'react'

type Props = {
  gridRef: RefObject<HTMLDivElement>
}

type Returned = {
  showAll: boolean
  toggleShowMore: () => void
}

export const useShowAll = (props: Props): Returned => {
  const { gridRef } = props

  const [showAll, setShowAll] = useState<boolean>(false)

  const toggleShowMore = useCallback(() => {
    setShowAll((showAllPrev) => {
      if (showAllPrev) gridRef.current?.scrollIntoView()
      return !showAllPrev
    })
  }, [gridRef])

  return { showAll, toggleShowMore }
}
