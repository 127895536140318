import css from './BackendControlled.module.scss'
import React from 'react'
import classNames from 'classnames'

import BannerCover from 'components/BackendControlled/Banner/BannerCover'
import BannerTop from 'components/BackendControlled/Banner/BannerTop'
import BannerVideo from 'components/BackendControlled/Banner/BannerVideo'
import ComponentList from 'components/BackendControlled/ComponentList'
import { useGetComponents } from 'components/BackendControlled/hooks/useGetComponents'
import { BannerTop as BannerTopType, BannerType, IBanner } from 'components/BackendControlled/types'
import { useBackendControlledComponents } from 'hooks/useBackendControlled'

type Props = {
  banner?: IBanner
  bannerTop?: BannerTopType
  path: string
}

const BackendControlled: React.FC<Props> = (props) => {
  const { banner, bannerTop, path } = props

  useGetComponents({ path })
  const components = useBackendControlledComponents({ path })

  return (
    <>
      {bannerTop && <BannerTop banner={bannerTop} />}
      {banner && banner.type === BannerType.cover && <BannerCover banner={banner} />}
      {banner && banner.type === BannerType.video && <BannerVideo banner={banner} />}

      <div
        className={classNames(css.backendControlledContainer, {
          [css.withBannerCover]: banner && [BannerType.cover, BannerType.video].includes(banner.type),
          [css.withBannerTop]: bannerTop,
        })}
      >
        <ComponentList components={components} />
      </div>
    </>
  )
}

export default BackendControlled
