import React, { useEffect } from 'react'

import { CollectionDetails } from 'meta/pages/collection'
import { ApiEndpoint } from 'controller/endpoint'
import { AnalyticsEventType, trackFbPixelEvent } from 'utils/analytics/track'

import { Grid } from 'components/Layout/Grid'
import { useFacebookPixelId, useS2SToken } from 'hooks/useEnv'

import CoverBox from './Coverbox'
import Header from './Header'

const CollectionDetails: React.FC<CollectionDetails> = (props) => {
  const { header, cover, fbPixel, name, id } = props

  const s2sToken = useS2SToken()

  const ticketsmsFbPixelId = useFacebookPixelId()

  const fbPixelCallback = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window && window.fbq) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fbPixel && window.fbq('init', fbPixel)
    }

    trackFbPixelEvent(
      AnalyticsEventType.ViewContent,
      {
        content_name: name,
        content_ids: [id],
        content_type: 'product',
        value: 1,
        currency: 'EUR',
      },
      {
        route: ApiEndpoint.analytics.event(id),
        fromOrganizer: fbPixel !== ticketsmsFbPixelId,
        s2sToken: s2sToken,
      }
    )
  }

  useEffect(() => {
    fbPixelCallback()
  }, [])

  return (
    <Grid>
      <Header {...header} />
      <CoverBox {...cover} />
    </Grid>
  )
}

export default CollectionDetails
