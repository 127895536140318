/* eslint-disable no-irregular-whitespace */
import css from './PrivacyPolicy.module.scss'
import React, { useRef } from 'react'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import TransparentWrapper from 'components/Structure/TransparentWrapper'

import PrivacyPolicySkeleton from './PrivacyPolicySkeleton'

const PrivacyPolicy: React.FC = () => {
  const { namespacesLoaded } = useI18NContext()

  const meta_title = 'Privacy policy di TicketSms'

  const navRef = useRef<HTMLDivElement>(null)
  const genRef = useRef<HTMLDivElement>(null)

  const scrollToNav = () => {
    if (navRef.current) {
      navRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToGen = () => {
    if (genRef.current) {
      genRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Head>
        <title>{meta_title}</title>
        <meta property="og:title" content={meta_title} />
      </Head>
      <div className={css.privacy_container}>
        <TransparentWrapper>
          <WithAppInitialized skeleton={<PrivacyPolicySkeleton />} showChildren={namespacesLoaded}>
            <div className={css.privacy_ita_container}>
              <div className={css.static_page_title}>
                <div>Privacy Policy</div>
              </div>
              <div className={css.static_page_text}>
                <p>
                  <a onClick={scrollToNav}>&#x2022; Informativa dati navigazione</a>
                  <br />
                  <br />
                  <a onClick={scrollToGen}>&#x2022; Informativa generale registrazione e acquisto</a>
                </p>
                <p>
                  <div ref={navRef}>
                    INFORMATIVA SUL TRATTAMENTO DEI DATI DI NAVIGAZIONE DEGLI UTENTI CHE CONSULTANO IL SITO DI TICKETSMS
                    EX ART. 13 REG. (UE) 2016/679
                  </div>
                  <br />

                  <div>
                    Ai sensi e per gli effetti del Regolamento (UE) 2016/679 (di seguito, per brevità,
                    &quot;Regolamento&quot;) e del d.lgs. 196/2003, TicketSms s.r.l., di seguito identificata, Le
                    fornisce le informazioni relative al trattamento dei Suoi dati personali nell&apos;ambito della
                    navigazione sul sito https://www.ticketsms.it.
                  </div>

                  <br />

                  <div>
                    <b>1. Titolare del trattamento dei dati personali</b>
                    <br />
                    <br />
                    Titolare del sito web e titolare del trattamento dei dati personali è la TicketSms s.r.l. con sede
                    in Bologna, Via Marsala, 28, 40126, P.I./C.F. 03653341200 (di seguito, “titolare del trattamento” o
                    “TicketSms”).
                    <br />
                    Il titolare del trattamento di dati personali può essere contattato all’indirizzo e-mail:
                    info@ticketsms.it.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>2. Finalità e base giuridica del trattamento</b>
                    <br />
                    <br />
                    Il trattamento dei Suoi dati di navigazione è effettuato per garantire il corretto funzionamento
                    tecnico del sito web di TicketSms sulla base dell’interesse legittimo di TicketSms a fornire un sito
                    funzionale e a garantire la sicurezza dei propri utenti ai sensi dell’art. 6, 1° comma, lett. f) del
                    Regolamento oltre che per finalità relative elaborazioni statistiche sul traffico del sito.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>3. Tipologia di dati trattati</b>
                    <br />
                    <br />
                    3.1. Dati di navigazione
                    <br />
                    I sistemi informatici e le procedure preposte al funzionamento del sito acquisiscono, nel corso del
                    loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei
                    protocolli di comunicazione di Internet.
                    <br />
                    Si tratta di informazioni che non sono raccolte per essere associate a soggetti interessati
                    identificati, ma che per la loro stessa natura, potrebbero, attraverso elaborazioni ed associazioni
                    con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria rientrano gli
                    indirizzi IP, i nomi di dominio utilizzati dagli utenti che si connettono al sito, gli indirizzi in
                    notazione URI delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre
                    la richiesta al server, la dimensione del file ottenuto ed altri parametri relativi al sistema
                    operativo e all’ambiente informatico dell’utente.
                    <br />
                    3.2. Cookie ed altri strumenti di tracciamento
                    <br />
                    Il sito di TicketSms utilizza cookie ed altri strumenti di tracciamento per garantire il
                    funzionamento del sito, aumentare la sicurezza, analizzare l’utilizzo e l’efficienza dei servizi.
                    Per maggiori informazioni si rimanda alla cookie policy, disponibile al seguente link{' '}
                    <a href="/cookie-policy">https://www.ticketsms.it/cookie-policy</a>.
                    <br />
                    3.3. Dati personali volontariamente comunicati dall’utente
                    <br />
                    L’invio facoltativo e volontario di messaggi attraverso i moduli on line (ad esempio, sezione
                    “aiuto”, “contattaci”, ecc.) comporta l’acquisizione dei dati di contatto dell’utente necessari a
                    rispondere alla richiesta formulata, nonché di tutti i dati personali inclusi nelle comunicazioni.
                    Salvo un consenso esplicito dell’utente, i citati dati vengono memorizzati ed utilizzati
                    esclusivamente ai fini dell’espletamento del servizio richiesto dall’utente, nel rispetto
                    dell’informativa generale ai sensi dell’art. 13 Reg. UE 679/2016 resa e pubblicata nelle pagine del
                    sito di TicketSms dedicate alle citate sezioni di contatto o all’erogazione di servizi.
                  </div>

                  <br />

                  <div>
                    <b>4. Periodo di conservazione dei dati</b>
                    <br />
                    <br />
                    I dati di navigazione sono cancellati da TicketSms dopo 1 anno (salve eventuali necessità di
                    accertamento di reati o altre richieste da parte dell’Autorità giudiziaria o comunque sottese
                    all’esercizio del diritto di difesa).
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>5. Responsabili del trattamento e comunicazione dei Suoi dati a terzi</b>
                    <br />
                    <br />
                    Sono designati responsabili del trattamento ai sensi dell’art. 28 del Regolamento e possono quindi
                    trattare e venire a conoscenza dei dati da Lei forniti, le seguenti categorie di soggetti di cui
                    TicketSms si serve:
                    <br />
                    a) per la gestione delle relazioni con i clienti e più in generale, con i terzi, anche per fini
                    contabili e amministrativi;
                    <br />
                    b) per la gestione delle comunicazioni, anche di natura promozionale e per l’erogazione di servizi
                    informativi e di promozione delle attività, nonché di profilazione (ad esempio, fornitori di
                    applicativi e gestionali informatici);
                    <br />
                    c) per la gestione, anche informatica, dei propri archivi e della propria fatturazione (ad esempio,
                    fornitori di server o servizi Cloud).
                  </div>

                  <br />

                  <div>
                    <b>6. Luogo del trattamento</b>
                    <br />
                    <br />
                    TicketSms e i Responsabili di trattamento designati effettuano il trattamento dei Suoi dati in
                    Italia o in ogni caso all’interno dell’Unione Europea o nel Regno Unito, dove, secondo la Decisione
                    di esecuzione (UE) 2021/1772 della Commissione europea del 28 giugno 2021, è assicurato un livello
                    di protezione adeguato nell’ambito di applicazione del Regolamento.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>7. Diritti degli interessati</b>
                    <br />
                    <br />
                    Fatte salve le limitazioni all’esercizio dei diritti degli interessati di cui agli artt. 2-undecies
                    e 2-duodecies del d.lgs. 196/2003 s.m.i. (“Codice in materia di protezione dei dati personali”),
                    l’utente, in quanto soggetto interessato, può esercitare i diritti riconosciuti ai sensi e nei
                    limiti degli artt. 15-21 del Regolamento, tra cui il diritto di chiedere al titolare l’accesso ai
                    propri dati personali, la rettifica o la cancellazione degli stessi, nonché la limitazione del
                    trattamento dei dati che lo riguarda, l’opposizione al trattamento e la portabilità dei propri dati.
                    <br />
                    Ove espresso, l’utente ha inoltre il diritto di revocare il consenso al trattamento dei propri dati
                    personali, in qualsiasi momento e senza che ciò pregiudichi la liceità del trattamento basata sul
                    consenso prima della revoca. In particolare, per modificare le preferenze sull’utilizzo dei cookie,
                    l’utente potrà utilizzare le impostazioni del proprio browser.
                    <br />
                    Infine, l’utente ha il diritto di proporre reclamo a un’autorità di controllo competente, ai sensi
                    dell’art. 77, 1° comma del Regolamento, che in Italia è il “Garante per la protezione dei dati
                    personali”.
                    <br />
                    L’utente può esercitare i diritti sopra indicati inviando una comunicazione scritta presso la sede
                    del titolare sopra indicata o all’indirizzo di posta elettronica info@ticketsms.it.
                  </div>

                  <br />
                </p>
                <p>
                  <div ref={genRef}>
                    INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI EX ART. 13 REG. (UE) 2016/679
                  </div>
                  <br />
                  <br />

                  <div>
                    Ai sensi del Regolamento (UE) 2016/679 (di seguito, per brevità, “Regolamento”) e di ogni successiva
                    normativa applicabile, desideriamo informarLa che TicketSms s.r.l. (di seguito anche indicata anche
                    come “Titolare” o “TicketSms”) procederà al trattamento dei Suoi dati per le finalità di seguito
                    indicate, nel rispetto della normativa in materia di protezione dei dati personali.
                  </div>

                  <br />
                  <br />

                  <div>
                    <b>1. Dati conferiti per l’esecuzione del contratto e la fruizione di un servizio</b>
                    <br />
                    TicketSms tratta i Suoi dati anagrafici e di contatto (nome, cognome, email, telefono e in alcuni
                    casi data di nascita), nonché, in caso di acquisto, i dati relativi al Suo strumento di pagamento,
                    al fine di dare esecuzione a misure precontrattuali da Lei richieste o al contratto di vendita di
                    biglietti o altri prodotti mediante la piattaforma TicketSms, nonché al contratto di fornitura di
                    servizi correlati.
                    <br />
                    A tali fini non occorre che Lei fornisca uno specifico consenso al trattamento dei dati citati al
                    precedente periodo, in quanto la base giuridica che ne legittima il trattamento è costituita da
                    adempimenti di natura precontrattuale, oppure dall’erogazione di un servizio in Suo favore da Lei
                    richiesto o comunque dall’esecuzione di un contratto di cui Lei è parte.
                    <br />
                    In ogni caso, un Suo eventuale rifiuto di fornire i predetti dati, determinerebbe l’impossibilità
                    per TicketSms di soddisfare la Sua richiesta e di fornire (o continuare a fornire) il servizio da
                    Lei richiesto.
                    <br />
                    I Suoi dati personali relativi alla prenotazione (nome, cognome, email e numero di telefono) saranno
                    comunicati ai terzi promotori od organizzatori degli eventi o dei servizi da Lei acquistati tramite
                    TicketSms, per i soli fini contrattuali, anche per l’assolvimento di adempimenti legali (ad esempio
                    disciplina SIAE, per ragioni di sicurezza e controllo degli accessi).
                    <br />
                    I citati terzi promotori od organizzatori sono autonomi titolari di trattamento ai sensi della
                    disciplina in materia di protezione dei dati personali, e sono tenuti a fornirle apposita
                    informativa ai sensi dell’art. 14 del Reg. UE 679/2016.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>1.1. Tempi di trattamento</b>
                    <br />
                    I dati da Lei conferiti per le finalità in precedenza descritte al punto 1, saranno trattati da
                    TicketSms per il tempo necessario all’esecuzione delle misure precontrattuali o del contratto o alla
                    fruizione del servizio richiesto.
                    <br />
                    Una volta eseguito il contratto o reso il servizio con previsione di un corrispettivo in denaro (ad
                    esempio, una volta terminato l’evento a pagamento a cui il suo biglietto si riferisce), i Suoi dati
                    potranno essere conservati sino a 10 anni dal giorno di pagamento, esclusivamente per assicurare gli
                    adempimenti fiscali, contabili e amministrativi richiesti dalla legge, nonché per esigenze di difesa
                    dei diritti in sede giudiziaria, salvo il caso di eventuali azioni giudiziarie che potrebbero
                    prolungare il trattamento per oltre 10 anni, nell’esercizio del diritto di difesa del titolare del
                    trattamento.
                    <br />
                    Nel caso in cui i predetti dati siano conferiti nella fase precontrattuale (ad esempio nel caso in
                    cui i dati siano forniti ma l’acquisto non vada a buon fine o il corrispettivo non sia pagato), il
                    trattamento per i fini di cui all’art. 1) durerà per 1 anno per tenere memoria di eventuali
                    acquisiti inseriti nel carrello ma non completati.
                  </div>

                  <br />

                  <div>
                    <b>2. Dati conferiti per finalità informativo- promozionali</b>
                    <br />
                    Se desidera rimanere in contatto con TicketSms per essere informato sugli eventi, sui concerti,
                    sugli spettacoli o servizi offerti in vendita tramite TicketSms, nonché sulle offerte commerciali e
                    promozionali commercializzate tramite TicketSms, anche attraverso la ricezione di apposita
                    newsletter informativa – o notifiche push e/o sms le chiediamo di concedere il Suo consenso al
                    trattamento dei dati anagrafici e di contatto per finalità informativo-promozionali.
                  </div>

                  <br />

                  <div>
                    <b>2.1. Modalità di contatto</b>
                    <br />
                    Per contattarLa a fini informativo-promozionali utilizzeremo modalità automatizzate come e-mail e
                    sms o notifiche push o, nel caso dell’applicazione, notifiche, previo consenso espresso in fase di
                    installazione.
                  </div>

                  <br />

                  <div>
                    <b>2.2. Cosa accade in caso di mancato consenso?</b>
                    <br />
                    In mancanza di Suo consenso per le suddette finalità informativo-promozionali, TicketSms non potrà
                    informarLa su eventi, concerti e spettacoli, sui servizi offerti o su eventuali offerte commerciali
                    e promozionali come sopra precisato.
                  </div>

                  <br />

                  <div>
                    <b>2.3. Tempi di trattamento relativi alle attività informativo-promozionali</b>
                    <br />
                    TicketSms tratterà i dati personali da Lei forniti per le finalità informative e commerciali sopra
                    descritte per il termine massimo di due anni decorrenti dal rilascio del Suo consenso. Il consenso
                    al presente trattamento è revocabile in ogni momento, fermo restando il Suo diritto di ottenere la
                    cancellazione dei Suoi dati come meglio indicato alla successiva sezione “Diritti dell’interessato”.
                  </div>

                  <br />

                  <div>
                    <b>3. Consenso alla profilazione per finalità informativo-promozionali</b>
                    <br />
                    Per profilazione si intende il trattamento automatizzato dei Suoi dati personali per valutare ed
                    esaminare le Sue preferenze personali ed i Suoi interessi.
                    <br />
                    Se lo desidera, può fornire il consenso al trattamento dei Suoi dati, ivi inclusi quelli relativi
                    alle preferenze personali e agli interessi eventualmente forniti a TicketSms, mediante profilazione
                    per le seguenti finalità:
                    <br />
                    - consentire a TicketSms di commercializzare eventi e servizi maggiormente rispondenti alle Sue
                    preferenze personali eventualmente manifestate;
                    <br />
                    - consentire a TicketSms di comprendere le caratteristiche e le esigenze dei propri utenti, al fine
                    di commercializzare eventi e servizi maggiormente rispondenti agli interessi di questi ultimi;
                    <br />
                    - consentire a TicketSms di inviarLe offerte commerciali dedicate, nonché offerte personalizzate
                    maggiormente rispondenti ai Suoi interessi.
                    <br />
                    In questo caso, TicketSms potrà contattarLa con le modalità in precedenza indicate al punto 2.1.
                  </div>

                  <br />

                  <div>
                    <b>3.1. Cosa accade in caso di mancato consenso alla profilazione?</b>
                    <br />
                    In mancanza di Suo specifico consenso al trattamento mediante profilazione, TicketSms non potrà
                    definire con maggior precisione i servizi ed eventi rispondenti e parametrati agli interessi dei
                    propri utenti, e non sarà in grado di riservarLe offerte personalizzate e più coerenti con le Sue
                    preferenze, ferma restando la facoltà di trattare i Suoi dati per finalità informativo-promozionali
                    (senza profilazione) nel caso in cui abbia dato il Suo consenso a tal fine.
                  </div>

                  <br />

                  <div>
                    <b>3.2. Tempi di trattamento relativi all’attività di profilazione</b>
                    <br />
                    TicketSms profilerà i Suoi dati per le finalità informativo-promozionali sopra descritte per il
                    termine massimo di un anno decorrente dal rilascio del Suo consenso, fermo restando il termine di
                    trattamento di due anni per finalità informativo-promozionali (senza profilazione) rispetto al quale
                    abbia eventualmente dato il Suo consenso.
                    <br />
                    Decorso tale termine i Suoi dati relativi alle preferenze espresse saranno cancellati, salvo che il
                    trattamento dei citati dati non sia legittimato per ulteriori finalità o che Lei non preferisca
                    rinnovare il Suo consenso al trattamento.
                  </div>

                  <br />

                  <div>
                    <b>3-bis. Modalità del trattamento</b>
                    <br />
                    Il trattamento dei dati personali è effettuato da TicketSms con modalità cartacee ed informatizzate.
                    <br />
                    Desideriamo informarLa che TicketSms non sottopone i Suoi dati a decisioni basate su trattamenti
                    automatizzati che producano effetti giuridici o che incidano in modo analogo significativamente
                    sulla Sua persona ai sensi dell’art. 22 del Regolamento.
                  </div>

                  <br />

                  <div>
                    <b>4. Titolare del trattamento</b>
                    <br />
                    Il Titolare del trattamento è TicketSms s.r.l. con sede in Bologna, Via Marsala, 28, 40126,
                    P.I./C.F. 03653341200, che può essere contattato all’indirizzo e-mail info@ticketsms.it.
                  </div>

                  <br />

                  <div>
                    <b>5. Responsabili del trattamento e comunicazione dei Suoi dati a terzi</b>
                    <br />
                    Sono designati responsabili del trattamento ai sensi dell’art. 28 del Regolamento e possono quindi
                    trattare e venire a conoscenza dei dati da Lei forniti, i soggetti o le categorie di soggetti il cui
                    elenco è disponibile su richiesta contattando il Titolare mediante i contatti indicati A tal fine si
                    segnala che sono designati responsabili di trattamento le seguenti categorie di soggetti di cui
                    TicketSms si serve:
                    <br />
                    a) per la gestione delle relazioni con i clienti e più in generale, con i terzi, anche per fini
                    contabili e amministrativi;
                    <br />
                    b) per la gestione delle comunicazioni, anche di natura promozionale e per l’erogazione di servizi
                    informativi e di promozione delle attività, nonché di profilazione (ad esempio, fornitori di
                    applicativi e gestionali informatici);
                    <br />
                    c) per la gestione, anche informatica, dei propri archivi e della propria fatturazione (ad esempio,
                    fornitori di server o servizi Cloud).
                    <br />
                    Come indicato al precedente art. 1, i Suoi dati personali relativi alla prenotazione (nome, cognome,
                    email e numero di telefono) saranno comunicati ai terzi promotori od organizzatori degli eventi o
                    dei servizi da Lei acquistati tramite TicketSms, per i soli fini contrattuali, anche per
                    l’assolvimento di adempimenti legali (ad esempio disciplina SIAE, per ragioni di sicurezza e
                    controllo degli accessi).
                  </div>

                  <br />

                  <div>
                    <b>6. Luogo del trattamento</b>
                    <br />
                    TicketSms e i Responsabili di trattamento designati effettuano il trattamento dei Suoi dati in
                    Italia o in ogni caso all’interno dell’Unione europea o nel Regno Unito, dove, secondo la Decisione
                    di esecuzione (UE) 2021/1772 della Commissione europea del 28 giugno 2021, è assicurato un livello
                    di protezione adeguato nell’ambito di applicazione del Regolamento.
                  </div>

                  <br />

                  <div>
                    <b>7. Diritti dell’interessato</b>
                    <br />
                    Lei può esercitare i diritti riconosciuti dagli artt. 15-21 del Regolamento, tra cui il diritto di
                    chiedere al titolare del trattamento l’accesso ai dati personali, la rettifica o la cancellazione
                    degli stessi, nonché la limitazione del trattamento dei dati che La riguardano.
                    <br />
                    Ove il trattamento di dati personali sia basato sul Suo consenso, Lei ha altresì il diritto di
                    revocare tale consenso al trattamento dei dati in qualsiasi momento, senza che ciò pregiudichi la
                    liceità del trattamento basata sul consenso prima della revoca.
                  </div>

                  <br />

                  <div>
                    <b>7.1. Diritto alla portabilità dei dati</b>
                    <br />
                    Lei ha altresì il diritto alla portabilità dei dati alle condizioni di cui all’art. 20 del
                    Regolamento 679/2016. Il diritto alla portabilità dei dati consiste nel diritto a ricevere da
                    TicketSms in formato strutturato, di uso comune e leggibile da dispositivo automatico i Suoi dati
                    personali il cui trattamento sia stato effettuato da TicketSms con mezzi automatizzati e sia stato
                    fondato su un contratto o sul consenso.
                  </div>

                  <br />

                  <div>
                    <b>7.2. Diritto di proporre reclamo</b>
                    <br />
                    Lei ha inoltre il diritto di proporre reclamo a un’autorità di controllo competente, ai sensi
                    dell’art. 77, 1° comma del Regolamento, che in Italia è il “Garante per la protezione dei dati
                    personali”.
                  </div>

                  <br />

                  <div>
                    <b>7.3. Modalità per l’esercizio dei diritti</b>
                    <br />
                    L’esercizio dei diritti può essere effettuato inviando una comunicazione presso la sede indicata al
                    precedente punto 4 (Titolare del trattamento) o all’indirizzo e-mail info@tickesms.it.
                  </div>

                  <br />
                </p>
              </div>
            </div>
          </WithAppInitialized>
        </TransparentWrapper>
      </div>
    </>
  )
}

export default PrivacyPolicy
