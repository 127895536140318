import css from './Mobile.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRange, { Range } from 'react-input-range'
import Switch from 'react-switch'

import { CurrencySymbol } from 'meta/currency'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { convertAndFormat, convertCurrency, getCodeFromSymbol } from 'helpers/currency/converter'
import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

import { useActiveCurrency } from 'hooks/useActiveCurrency'
import { useCheckoutDetails } from 'hooks/useCheckout'

interface IProps {
  setPriceRangeCallback(range: any): void
  priceRangeInitial: Range
  priceRangeCurrent: Range
}

const Mobile: React.FC<IProps> = (props) => {
  const { setPriceRangeCallback, priceRangeInitial, priceRangeCurrent } = props

  const { t } = useTranslation()
  const { eventTicket } = useCheckoutDetails() as ICheckoutDetails
  const { activeCurrency } = useActiveCurrency()

  const eventCurrencyCode = eventTicket.data[0]
    ? getCodeFromSymbol(eventTicket.data[0].currency as CurrencySymbol)
    : activeCurrency

  const isFilterActive = (): boolean => {
    return priceRangeInitial.min !== priceRangeCurrent.min || priceRangeInitial.max !== priceRangeCurrent.max
  }

  return (
    <div className={css.checkout_ticketselection_filters_pricerange_mobile_container}>
      <div className={css.filter_container}>
        <div className={css.text_container}>
          <div className={css.label}>{t('checkout:price_range')}</div>
          <div className={css.from_to}>
            {`${String(t('checkout:price_range_from'))} ${convertCurrency(
              priceRangeCurrent.min,
              eventCurrencyCode,
              activeCurrency
            )} ${String(t('checkout:price_range_to'))} ${convertAndFormat(
              priceRangeCurrent.max,
              eventCurrencyCode,
              activeCurrency,
              true
            )}`}
          </div>
        </div>

        <div className={css.slider_container}>
          <InputRange
            draggableTrack
            maxValue={priceRangeInitial.max}
            minValue={priceRangeInitial.min}
            value={priceRangeCurrent}
            onChange={(range) => {
              setPriceRangeCallback(range)
            }}
          />
        </div>
      </div>

      <div className={css.switch_container}>
        <Switch
          onChange={() => setPriceRangeCallback(priceRangeInitial)}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={TICKETSMS_COLORS_RED}
          checked={isFilterActive()}
        />
      </div>
    </div>
  )
}

export default Mobile
