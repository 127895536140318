import { createAsyncThunk } from '@reduxjs/toolkit'

import { EventController } from 'controller/event'
import { RootState } from 'store/types'

type Params = {
  codeUrl: string
}

type Returned = {
  metadata?: Object
}
export const fetchMetadata = createAsyncThunk<Returned, Params, { state: RootState }>(
  'event/metadata/fetch',
  async (params) => {
    const { codeUrl } = params

    const metadata = await EventController.getMetadata({ codeUrl })

    return { metadata }
  }
)
