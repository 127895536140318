import { useMemo } from 'react'
import { CaptionLayout, DayPickerRangeProps, DayPickerSingleProps, Formatters } from 'react-day-picker'

import { Dates } from 'utils/dates'

import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

const CALENDAR_CAPTION_LAYOUT: CaptionLayout = 'dropdown-buttons'
const CALENDAR_DATES_FORMAT_MONTH = 'LLLL'
const CALENDAR_DATES_FORMAT_WEEKDAY = 'EEE'
const CALENDAR_FIXED_WEEKS: boolean = true
const CALENDAR_SHOW_OUTSIDE_DAYS: boolean = true
const CALENDAR_YEAR_MAX_VALUE = 2044
const CALENDAR_YEAR_MIN_VALUE = 1926

const CALENDAR_FORMATTERS: Partial<Formatters> = {
  formatMonthCaption: (date) => Dates.format(date, CALENDAR_DATES_FORMAT_MONTH),
  formatWeekdayName: (date) => Dates.format(date, CALENDAR_DATES_FORMAT_WEEKDAY),
}

export const useDayPickerProps = <T extends DayPickerSingleProps | DayPickerRangeProps>(props: Partial<T>): T => {
  const currentLanguage = useCurrentLanguage()

  const locale = useMemo(() => Dates.locales[currentLanguage], [currentLanguage])

  return {
    captionLayout: CALENDAR_CAPTION_LAYOUT,
    fixedWeeks: CALENDAR_FIXED_WEEKS,
    formatters: CALENDAR_FORMATTERS,
    fromYear: CALENDAR_YEAR_MIN_VALUE,
    locale,
    showOutsideDays: CALENDAR_SHOW_OUTSIDE_DAYS,
    toYear: CALENDAR_YEAR_MAX_VALUE,
    ...props,
  } as T
}
