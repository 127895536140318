import css from './BalloonWrapper.module.scss'
import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { BalloonBgPosition, BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import {
  TICKETSMS_COLORS_BLACK,
  TICKETSMS_COLORS_BLUE,
  TICKETSMS_COLORS_RED,
  TICKETSMS_COLORS_RED_LIGHT,
} from 'utils/constants/colors'

import Img from 'components/Media/Img'

interface IPropsBalloon {
  backgroundUrl?: string
  backgroundPosition?: BalloonBgPosition
  className?: string
  color: ComponentColor
  containsMenu?: boolean
  insideVerticalScroll?: boolean
  size: BalloonSize
  tipPosition: BalloonTipPosition
  noPadding?: boolean
}

const BalloonWrapper: React.FC<PropsWithChildren<IPropsBalloon>> = (props) => {
  const {
    backgroundUrl,
    backgroundPosition,
    children,
    className,
    containsMenu,
    color,
    insideVerticalScroll,
    size,
    tipPosition,
    noPadding,
  } = props

  const getBgColor = (color: ComponentColor) => {
    switch (color) {
      case ComponentColor.black:
        return TICKETSMS_COLORS_BLACK
      case ComponentColor.blue:
        return TICKETSMS_COLORS_BLUE
      case ComponentColor.red:
        return TICKETSMS_COLORS_RED
      case ComponentColor.redLight:
        return TICKETSMS_COLORS_RED_LIGHT
      default:
        return ComponentColor.white
    }
  }

  const getExtension = (color: ComponentColor) => {
    switch (color) {
      case ComponentColor.blue:
      case ComponentColor.red:
      case ComponentColor.redLight:
        return 'svg'
      default:
        return 'png'
    }
  }

  const bgColor = getBgColor(color)
  const extension = getExtension(color)
  const background = `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${bgColor}aa 65%, ${bgColor} 100%), url('${backgroundUrl}') no-repeat`

  //TODO: check whether this can be calculated differently
  const contentId =
    insideVerticalScroll &&
    children &&
    // @ts-expect-error might not exist
    children[0] &&
    // @ts-expect-error might not exist
    children[0].props &&
    // @ts-expect-error might not exist
    children[0].props.children &&
    // @ts-expect-error might not exist
    children[0].props.children.props &&
    // @ts-expect-error might not exist
    children[0].props.children.props.data &&
    // @ts-expect-error might not exist
    children[0].props.children.props.data.title
      ? // @ts-expect-error might not exist
        children[0].props.children.props.data.title.toLowerCase()
      : null

  return (
    <div
      className={classNames(css.balloonContainer, className, {
        // [css.small]: size === BalloonSize.small,
        // [css.no_padding]: removePaddingOutside,
      })}
    >
      <div
        id={contentId}
        className={classNames(css.balloon, css[`balloonColor_${color}`], css[`balloonTip_${tipPosition}`], {
          [css.containsMenu]: containsMenu,
          [css.nopadding]: noPadding,
          [css.medium]: size === BalloonSize.medium,
        })}
        style={backgroundUrl ? { background, backgroundSize: 'cover', backgroundPosition } : undefined}
      >
        {/*<div*/}
        {/*    className={classNames(css.wrapped_content, {*/}
        {/*        [css.does_contain_showcase]: containsShowcase,*/}
        {/*        [css.does_contain_gallery]: containsGallery,*/}
        {/*    })}*/}
        {/*>*/}
        {React.Children.toArray(children)}
        {/*</div>*/}
      </div>

      <div
        className={classNames(css[`balloonTipContainer_${tipPosition}`], {
          [css.medium]: size === BalloonSize.medium,
          [css.small]: size === BalloonSize.small,
        })}
      >
        <Img src={`/baloon/tip_${color}_${tipPosition}.${extension}`} />
      </div>
    </div>
  )
}

BalloonWrapper.defaultProps = {
  backgroundUrl: undefined,
  containsShowcase: false,
  insideVerticalScroll: false,
  removePaddingOutside: false,
  noPadding: false,
}

export default BalloonWrapper
