import css from './ParentSquare.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { IParentSquare } from 'meta/backendControlled/structure/parentSquare'
import { Breakpoints } from 'meta/layout/breakpoints'
import { Routes } from 'meta/routes'
import { Dates } from 'utils/dates'

import { ButtonWhiteRed } from 'components/Actions/Action'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import Card from 'components/Layout/Card'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const ParentSquare: React.FC<IPropsComponent<IParentSquare>> = (props) => {
  const { codeUrl, common, city, toDate, fromDate, imageUrl, location, name } = props

  const { t } = useTranslation()
  const urlCloudfront = useUrlCloudfront()
  const navigate = useNavigate()

  const fromDateFormatted = useMemo<string>(
    () => Dates.format(new Date(fromDate), t('dates:common:collection_season_mobile_from')),
    [fromDate, t]
  )
  const toDateFormatted = useMemo<string>(
    () => Dates.format(new Date(toDate), t('dates:common:collection_season_mobile_from')),
    [toDate, t]
  )

  const fromDateTitle = Dates.format(new Date(fromDate), t('dates:common:dayMonthYear'))
  const toDateTitle = Dates.format(new Date(toDate), t('dates:common:dayMonthYear'))

  const imageSrc = imageUrl
    ? imageUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`
  const collectionPath = Routes.Collection.generatePath({ id: codeUrl })

  return (
    <GridColumn
      gridColumnSpan={{ [Breakpoints.laptop]: 3, [Breakpoints.tablet]: 4, [Breakpoints.mobile]: 8 }}
      className={css.squareContainer}
    >
      <Card>
        <Link
          title={`${name} - ${common} - ${location} - ${fromDateTitle} - ${toDateTitle} - TicketSms`}
          to={collectionPath}
        >
          <ImgSkeleton alt={`${name} ${location}`} src={imageSrc} className={css.image_skeleton} />
        </Link>

        <div className={css.squareContainerDetails}>
          <a href={collectionPath}>
            <div>
              <div className={css.dateContainer}>
                <span className={css.from}>{t('common:season_from')}</span>
                <span className={css.dateFrom}>{fromDateFormatted}</span>
                <span className={css.to}>{t('common:season_to')}</span>
                <span className={css.dateTo}>{toDateFormatted}</span>
              </div>

              <h5 className={css.name}>{name}</h5>

              <div className={css.location_container}>
                <Img src="/icons/location_marker.svg" />
                <div className={css.location_text}>{`${common} / ${city}`}</div>
              </div>
            </div>
          </a>

          <div className={css.buttonBuyContainer}>
            <ButtonWhiteRed
              onClick={() => {
                navigate(collectionPath)
              }}
            >
              <strong>{t('common:season_more')}</strong>
            </ButtonWhiteRed>
          </div>
        </div>
      </Card>
    </GridColumn>
  )
}

export default ParentSquare
