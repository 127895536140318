import { CurrencyCode, CurrencySymbol } from 'meta/currency'

import { ComponentType, IEventInfo } from '../../structure'
import { IEventInfoBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class EventInfoAdapter extends ComponentAdapter<IEventInfo, IEventInfoBackend> {
  convert(): IEventInfo {
    const {
      attributes,
      captionData,
      datetime_tz,
      description,
      endDatetime_tz,
      openDatetime_tz,
      facebookLink,
      longDescription,
      ticketsPriceMin,
      ticketsPriceMinObj,
      tagsArray,
      title,
      website,
      extra,
    } = this.componentBackend

    // @ts-ignore
    const attributesAsArray = attributes.map((el) => {
      return {
        title: el.title,
        description: el.description,
        asset: { url: el.asset.src[0].url },
      }
    })

    const startPriceObj = ticketsPriceMinObj
      ? ticketsPriceMinObj
      : {
          amount: '0',
          currency: CurrencyCode.EUR,
          formatted: `0 ${CurrencyCode.EUR}`,
          symbol: CurrencySymbol.EUR,
        }

    return {
      captionData,
      componentType: ComponentType.eventInfo,
      datetime: datetime_tz,
      description,
      endDatetime: endDatetime_tz,
      extra,
      facebookLink,
      longDescription,
      openDatetime: openDatetime_tz,
      startPrice: ticketsPriceMin,
      startPriceObj: startPriceObj,
      tags: tagsArray ? tagsArray.filter((el) => el !== '#') : [],
      attributes: attributesAsArray,
      title,
      website,
    }
  }
}
