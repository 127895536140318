import { ComponentType, IVenueAttributes } from '../../structure'
import { IVenueAttributesBackend } from '../component/venueAttributes'
import ComponentAdapter from './componentAdapter'

export default class VenueAttributesAdapter extends ComponentAdapter<IVenueAttributes, IVenueAttributesBackend> {
  convert(): IVenueAttributes {
    const { attributes, description, id, title } = this.componentBackend

    return {
      attributes: attributes
        ? attributes.map((attribute) => ({
            description: attribute.description,
            imgUrl: attribute.asset.src[0].url,
            title: attribute.title,
          }))
        : [],
      componentType: ComponentType.venueAttributes,
      description,
      id,
      title,
    }
  }
}
