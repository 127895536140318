import { useSearchParams } from 'react-router-dom'

import { QueryParams } from 'meta/routes'

export const useQueryParams = <Returned extends Record<string, string>>(): Returned => {
  const [searchParams] = useSearchParams()
  const returned: Record<string, string> = {}
  for (const [key, value] of searchParams.entries()) {
    returned[key] = value
  }
  return returned as Returned
}

export const useCartographerQueryParams = (): QueryParams['Cartographer'] => {
  const [searchParams] = useSearchParams()
  // @ts-ignore
  const params = useQueryParams<QueryParams['Cartographer']>()
  params.seatIds = searchParams.getAll('seatIds')

  return params
}
