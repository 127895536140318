import { DataDogLogType } from 'meta/logger'
import { ProcessEnv } from 'utils/env'

export const TSLogger = (data: any, activateClientSide: boolean = false) => {
  const activateServerSide = ProcessEnv.activateLogger === 'true'

  if (activateClientSide || activateServerSide) {
    // eslint-disable-next-line no-console
    console.log(`[LOGGER]: ${JSON.stringify(data)}`)
  }
}

interface IDDLoggerErrorDetails {
  fromTransaction?: boolean
  transactionCode?: string
  paymentGateway?: string
  sessionExpired?: any
  errorCode?: any
  errorMsg?: any
  calendarType?: string
  eventTitle?: string
  eventDate?: string
  eventCodeUrl?: string
}

export const DataDogLogger = (
  msg: string,
  type: DataDogLogType = DataDogLogType.info,
  details: IDDLoggerErrorDetails
) => {
  //@ts-ignore
  window.DD_LOGS && DD_LOGS.logger[type](msg, details)
}
