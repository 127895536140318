import css from './Square.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ISquare } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { Dates } from 'utils/dates'

import { ButtonWhiteRed } from 'components/Actions/Action'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import Card from 'components/Layout/Card'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const Square: React.FC<IPropsComponent<ISquare>> = (props) => {
  const { codeUrl, common, date, imageUrl, location, name } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const urlCloudfront = useUrlCloudfront()

  const dateFormatted = useMemo<string>(() => Dates.format(new Date(date), t('dates:common:square')), [date, t])
  const dateTitle = Dates.format(new Date(date), t('dates:common:dayMonthYear'))

  const imageSrc = imageUrl
    ? imageUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`
  const eventPath = Routes.Event.generatePath({ codeUrl })

  return (
    <GridColumn
      gridColumnSpan={{ [Breakpoints.laptop]: 3, [Breakpoints.tablet]: 4, [Breakpoints.mobile]: 8 }}
      className={css.squareContainer}
    >
      <Card>
        <Link title={`${name} - ${common} - ${location} - ${dateTitle} - TicketSms`} to={eventPath}>
          <ImgSkeleton alt={`${name} ${location}`} src={imageSrc} className={css.image_skeleton} />
        </Link>

        <div className={css.squareContainerDetails}>
          <a href={eventPath}>
            <div>
              <div className={css.date}>{dateFormatted}</div>

              <h5 className={css.name}>{name}</h5>

              <div className={css.location_container}>
                <Img src="/icons/location_marker.svg" />
                <div className={css.location_text}>{location}</div>
              </div>
            </div>
          </a>

          <div className={css.buttonBuyContainer}>
            <ButtonWhiteRed
              onClick={() => {
                dispatch(CheckoutActions.init({ codeUrl }))
              }}
            >
              <strong>{t('common:buy')}</strong>
            </ButtonWhiteRed>
          </div>
        </div>
      </Card>
    </GridColumn>
  )
}

export default Square
