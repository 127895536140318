import React from 'react'

import Head from 'components/common/Head'
import { useAppEnv, useDataDogClientToken } from 'hooks/useEnv'

const DataDog: React.FC = () => {
  const appEnv = useAppEnv()
  const datadogClientToken = useDataDogClientToken()

  return (
    <Head>
      <script type="text/javascript">{`
        window.DD_LOGS &&
        DD_LOGS.init({
            clientToken: '${datadogClientToken}',
            site: 'datadoghq.eu',
            forwardErrorsToLogs: false,
            sampleRate: 100,
            env: '${appEnv}',
            service: 'frontend-client-side-ticketsms-it-${appEnv}'
        })
        `}</script>
    </Head>
  )
}

export default DataDog
