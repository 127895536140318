import css from './CCAddNew.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'

interface IProps {
  onClick(): void
}

const CCAddNew: React.FC<IProps> = (props) => {
  const { onClick } = props

  const { t } = useTranslation()

  return (
    <div className={css.ccaddNew_container} onClick={onClick}>
      <div className={css.left}>
        <div className={css.plusIcon}>
          <Img src={'/tickets/ticket_select_plus.svg'} />
        </div>
        <h4 className={css.insert}>{t('checkout:add_new_cc')}</h4>
      </div>

      <div className={css.right}>
        <div className={css.cardIcon}>
          <Img src={'/checkout/cc_placeholder_black.svg'} />
        </div>
      </div>
    </div>
  )
}

export default CCAddNew
