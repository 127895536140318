import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import { CreditCardsAdapter, ICardItem } from 'meta/pages/profile'
import { ApiEndpoint } from 'controller/endpoint'

import { useAuthToken } from 'hooks/useAuth'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useBackendApiBase } from 'hooks/useEnv'

type ICardItemRAW = {
  brand: string
  country: string
  expiryMonth: number
  expiryYear: number
  funding: string
  lastDigits: string
  paymentMethodId: string
}
type FetchCreditCardsResponse = {
  status: number
  data: { cardList: Array<ICardItemRAW> }
}

const useCreditCards = () => {
  const urlBase = useBackendApiBase()
  const language = useCurrentLanguage()
  const authToken = useAuthToken()

  const [loading, setLoading] = useState<boolean>(false)
  const [cardList, setCardList] = useState<Array<ICardItem>>([])
  const [cardToDelete, setCardToDelete] = useState<ICardItem>()

  const fetchCreditCards = useCallback(async () => {
    if (!language || !authToken) return

    setLoading(true)
    try {
      const { data: response } = await axios.get(`${urlBase}${ApiEndpoint.users.cards()}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          'Authorization': `Bearer ${authToken}`,
        },
      })
      setLoading(false)
      const { data, status } = response as FetchCreditCardsResponse
      const convertedCardList = CreditCardsAdapter.convertResponse(data.cardList)
      setCardList(convertedCardList)
    } catch (e) {
      setLoading(false)
    }
  }, [authToken, language, urlBase])

  const deleteCreditCard = useCallback(async () => {
    if (!language || !authToken) return
    if (!cardToDelete) return

    setLoading(true)
    try {
      await axios.delete(`${urlBase}${ApiEndpoint.users.deleteCard(cardToDelete.id)}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          'Authorization': `Bearer ${authToken}`,
        },
      })
      setLoading(false)
      const refreshedCards = cardList.filter((card) => card.id !== cardToDelete.id)
      setCardList(refreshedCards)
      setCardToDelete(undefined)
    } catch (e) {
      setLoading(false)
    }
  }, [authToken, cardList, cardToDelete, language, urlBase])

  const onCreditCardDeleteRequest = useCallback((card: ICardItem) => setCardToDelete(card), [])

  const onCreditCardDeleteDismiss = useCallback(() => setCardToDelete(undefined), [])

  useEffect(() => {
    fetchCreditCards()
  }, [fetchCreditCards])

  return {
    cardList,
    cardToDelete,
    loading,
    onCreditCardDeleteRequest,
    onCreditCardDeleteDismiss,
    deleteCreditCard,
  }
}

export { useCreditCards }
