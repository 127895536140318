import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'
import { FormBuilder, TextBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { ProfileActions } from 'store/pages/profile'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Form, { FormSkeleton, IFormValues } from 'components/Forms/Form'
import { useI18NContext } from 'components/HOC/WithTranslation'
import ModalConfirm from 'components/Pages/ProfileUndoResale/UndoResaleForm/ModalConfirm'

type Props = {
  authToken: string
  onSuccess(): void
}

const UndoResaleForm: React.FC<Props> = (props) => {
  const { authToken, onSuccess } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { namespacesLoaded } = useI18NContext()

  const [showModalConfirm, setShowModalConfirm] = useState<boolean>()

  const firstName = new TextBuilder('firstName').addRequiredNameValidation().addPlaceholder('form:firstName').build()
  const lastName = new TextBuilder('lastName').addRequiredNameValidation().addPlaceholder('form:lastName').build()
  const fiscalSeal = new TextBuilder('fiscalSeal')
    .addRequiredFiscalSealValidation()
    .addPlaceholder('form:fiscalSeal')
    .build()
  const formFields = new FormBuilder().addField(firstName).addField(lastName).addField(fiscalSeal).build()

  const onFormSuccess = useCallback(
    (
      values?: IFormValues,
      response?: { data: { event: { name: string; dateEvent: string }; ticket: { locationName: string } } }
    ) => {
      if (!values || !response) return

      const { event, ticket } = response.data
      dispatch(
        ProfileActions.setTicketResaleData({
          data: {
            amount: undefined,
            eventDate: new Date(event.dateEvent),
            firstName: values.firstName,
            eventLocation: ticket.locationName,
            eventName: event.name,
            fiscalSeal: values.fiscalSeal,
            iban: undefined,
            lastName: values.lastName,
          },
        })
      )
      setShowModalConfirm(true)
    },
    [dispatch]
  )
  const onModalConfirmClose = useCallback(() => {
    setShowModalConfirm(false)
  }, [])
  const onModalConfirmSuccess = useCallback(() => {
    dispatch(ProfileActions.resetTicketResale())
    onSuccess()
  }, [dispatch, onSuccess])

  return (
    <>
      <WithAppInitialized
        skeleton={<ProfileTranslationsSkeleton withMultipleDescriptions />}
        showChildren={namespacesLoaded}
      >
        <h3 data-testid={DataTestId.profile.undoResaleHeader}>{t('profile:undo_resale_header')}</h3>
        <div>{t('profile:undo_resale_cancel_here')}</div>
        <div>{t('profile:undo_resale_instructions')}</div>
      </WithAppInitialized>
      <WithAppInitialized skeleton={<FormSkeleton items={3} />}>
        <Form
          action={ApiEndpoint.users.resellUndo()}
          authToken={authToken}
          formFields={formFields}
          labelSubmit="register:submit_label"
          onSuccess={onFormSuccess}
        />
      </WithAppInitialized>

      {showModalConfirm && (
        <ModalConfirm authToken={authToken} onClose={onModalConfirmClose} onSuccess={onModalConfirmSuccess} />
      )}
    </>
  )
}

export default UndoResaleForm
