import { createAsyncThunk } from '@reduxjs/toolkit'

import { Checkouts, ICheckoutRecap } from 'meta/pages/checkout'
import { CheckoutController } from 'controller/checkout'
import { initialState } from 'store/pages/checkout/state'
import { ThunkApiConfig } from 'store/types'

type Returned = { recap: ICheckoutRecap }

export const fetchRecap = createAsyncThunk<Returned, void, ThunkApiConfig>(
  'checkout/recap/fetch',
  async (_, { getState }) => {
    const state = getState()
    const { checkout } = state.pages

    if (Checkouts.getCartQuantity(checkout.cart) > 0) {
      const recap = await CheckoutController.fetchRecap({ checkout })
      return { recap }
    }

    return { recap: { ...initialState.recap } as ICheckoutRecap }
  }
)
