import css from './Ca.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

const ResaleCat: React.FC = () => {
  return (
    <div className={css.resale_ca_container}>
      <div className={css.static_page_title}>
        <Img src="/resale/resale.svg" />
        <div>Revenda</div>
      </div>
      <div className={css.static_page_text}>
        <p>
          Les entrades comprades a través de TicketSms són nominatives, en concret, els espectacles dels grans
          espectacles amb més de 5000 espectadors requereixen, segons el{' '}
          <a
            target="_blank"
            href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
            rel="noreferrer"
          >
            nova legislació
          </a>
          , a obligació de nomenar <b></b> i comprovar la identitat del titular del bitllet en el moment de l'entrada.
          Per aquest motiu, també s'exigirà un document d'identitat vàlid en el moment de l'entrada als esdeveniments en
          qüestió, perquè el personal pugui comprovar la identitat del titular de l'entrada. també s'exigirà un document
          d'identitat vàlid perquè el personal encarregat dels controls per a verificar que el titular de l'entrada és
          el que ha entrat amb aquesta entrada específica. En cas d'En cas de no coincidir, no podràs accedir a
          l'esdeveniment sense dret a reemborsament. En el lloc, dins de la pàgina de l'esdeveniment, s'indicarà si
          l/esdeveniment està subjecte a entrada nominal i, per tant, serà per la qual cosa serà <b>obligatori</b>{' '}
          presentar-se a l/esdeveniment amb un document vàlid per a poder entrar.
        </p>

        <p>
          Si no pots assistir a un esdeveniment, es pot activar el procediment de revenda de l'entrada. En cas que no
          puguis assistir a un esdeveniment, el procediment de revenda de l'entrada que tens o de les entrades que has
          comprat (fins i tot una un sol bitllet en cas de compres múltiples), accedint a la secció corresponent des del
          perfil de l'usuari perfil o del resum de la transacció..
        </p>

        <p>
          <b>llaura tornar a posar l'entrada a la venda</b> serà necessari indicar el Nom, el Cognom i el segell fiscal
          en el bitllet original que estàs comprant o tenint. (tota aquesta informació s'indica en el propi bitllet). A
          més, deus A més, hauràs d'indicar un IBAN al qual es farà una transferència en cas de revenda.
        </p>

        <p>
          Una vegada verificats les dades i el dret a conducta es mostrarà un resum de l/oferta i un resum de l'oferta i
          se't demanarà una confirmació final,després de la qual el bitllet es tornarà a posar a la venda al mateix preu
          del bitllet original en el moment de la compra a la venda al mateix preu que el bitllet original dins del
          propi TicketSms. Quan un nou comprador desitgi adquirir un bitllet del mateix tipus que un bitllet que s'ha
          tornat a posar a la venda, es donarà prioritat a aquest últim. Quan un nou comprador desitgi adquirir un
          bitllet del mateix tipus que un bitllet reemès, es donarà prioritat a aquest últim sobre els bitllets
          estàndard.
        </p>

        <p>
          Si la revenda té èxit, el venedor rebrà, en un termini de 15 dies laborables, un 15 dies hàbils, una
          transferència igual al valor del bitllet únic revenut, net de comissions i despeses de servei de servei, que
          ascendeix a 1,50 euros. No està prevista cap comissió o quota d/inserció, el La comissió s/aplica només quan
          es completa la revengui està previst, el La comissió només escobrarà una vegada que s/hagi completat la
          revenda.
        </p>

        <p>
          a funció de revenda segueix a la funció{' '}
          <b>Les mateixes regles de temps que la venda clàssica fins a l/inici de l/esdeveniment</b>, per la qual cosa
          serà disponible mentre duri la venda ordinària d/entrades fins a l'obertura de les portes de l/esdeveniment,
          quan l/obertura de les portes de l/esdeveniment, quan ja no podràs revendre les teves entrades o comprar
          entrades posades a la venda per altres usuaris.
        </p>

        <p>
          Si vols anul·lar la revenda d/una de les teves entrades, només has d/accedir a l/en el menú "Tornar a posar en
          venda" del teu perfil, on tindràs que introdueix de nou les dades del bitllet (nom i segell fiscal). No
          obstant això, l'anul·lació només serà possible fins que el bitllet en qüestió no inserit en el carret d'un nou
          comprador.
        </p>

        <p>
          Tingues en compte que el bitllet d/entrada original <b>no pot utilitzar-se</b> mentre romangui en el mercat de
          revenda, per la qual cosa encara que no revenguis la teva entrada en el termini establert, no podràs
          utilitzar-la per a accedir al Per tant, encara que no es revengui dins del termini, per a poder utilitzar la
          teva entrada per a accedir a l/esdeveniment hauràs d/aplicar el procediment d/anul·lació <b>de la revenda</b>.
        </p>

        <p>
          Tingues en compte que <b>no pots revendre les entrades comprades amb 18APP</b>.
        </p>
      </div>
    </div>
  )
}

export default ResaleCat
