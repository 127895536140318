import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import i18n from 'i18n'
import { LanguageCode } from 'meta/app/translate'
import { IEventDetails } from 'meta/backendControlled/structure'
import { getEventKey } from 'store/backendControlled/_utils'
import { getComponents } from 'store/backendControlled/actions/getComponents'

import { initialState } from '../state'

export const BackendControlledSlice = createSlice({
  name: 'backendControlled',
  initialState,
  reducers: {
    initEventDetails: (state, action: PayloadAction<{ eventDetails: IEventDetails; codeUrl: string }>) => {
      const { eventDetails, codeUrl } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getEventKey({ codeUrl, language })
      state[path] = { ...state[path], eventDetails }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComponents.fulfilled, (state, action) => {
      if (action.payload) {
        const { path, components, metadata } = action.payload

        state[path] = { ...state[path], components, metadata }
      }
    })
  },
})
