import css from './Grid.module.scss'
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import classNames from 'classnames'

export enum GridType {
  flex = 'flex',
  grid = 'grid',
}

interface IPropsGrid extends RefAttributes<HTMLDivElement>, React.HTMLAttributes<HTMLDivElement> {
  type?: GridType
  extraPadding?: boolean
}

type TypeGrid = ForwardRefExoticComponent<IPropsGrid>

export const Grid: TypeGrid = forwardRef<HTMLDivElement, IPropsGrid>((props, ref) => {
  const { className, children, extraPadding, type, ...rest } = props

  return (
    <div
      className={classNames(css.grid, css[`gridType_${type}`], { [css.extraPadding]: extraPadding }, className)}
      ref={ref}
      {...rest}
    >
      {React.Children.toArray(children)}
    </div>
  )
})

Grid.defaultProps = {
  type: GridType.grid,
}

// Flexbox is a shortcut to <Grid type={GridType.flex} />
export const Flexbox: TypeGrid = forwardRef<HTMLDivElement, IPropsGrid>((props, ref) => (
  <Grid {...props} ref={ref} type={GridType.flex} />
))
