import css from 'components/Layout/MaintenanceWrapper/Maintenance.module.scss'
import React, { PropsWithChildren } from 'react'

import Img from 'components/Media/Img'
import { useMaintenanceMode } from 'hooks/useEnv'

const MaintenanceWrapper: React.FC<PropsWithChildren> = (props) => {
  const { children } = props

  const maintenanceMode = useMaintenanceMode()

  if (maintenanceMode) {
    return (
      <div className={css.maintenance_container}>
        <div className={css.content}>
          <div className={css.image_success_container}>
            <Img src="/success/success.gif" />
          </div>

          <div className={css.text}>
            <h3>Il sito è in fase di manutenzione. Il servizio tornerà disponibile al più presto.</h3>
            <h5>Maintenance mode is active. Service will be available ASAP.</h5>
          </div>
        </div>
      </div>
    )
  }

  return <>{React.Children.toArray(children)}</>
}

export default MaintenanceWrapper
