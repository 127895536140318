import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'

import { ActionColor, Button } from 'components/Actions/Action'
import RoundedBox from 'components/TextBoxes/RoundedBox'

interface IProps {
  onClose(): void
}

const Success: React.FC<IProps> = (props) => {
  const { onClose } = props

  const { t } = useTranslation()

  return (
    <>
      <RoundedBox bgColor={ComponentColor.white} infoType="success">
        <span data-testid={'profile-change-password-success'}>{t('profile:change_password_success_msg')}</span>
      </RoundedBox>
      <Button
        onClick={onClose}
        backgroundColor={ActionColor.white}
        hover={ActionColor.white}
        borderColor={ActionColor.white}
      >
        {t('profile:change_password_click_here_msg')}
      </Button>
    </>
  )
}

export default Success
