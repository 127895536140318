import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { IEventDetails } from 'meta/backendControlled/structure'
import { RouteParams } from 'meta/routes'
import { useAppDispatch } from 'store'
import { BackendControlledActions } from 'store/backendControlled'

import Actions from 'components/BackendControlled/Component/EventDetails/Actions'
import Cover from 'components/BackendControlled/Component/EventDetails/Cover'
import EventHead from 'components/BackendControlled/Component/EventDetails/EventHead'
import Infos from 'components/BackendControlled/Component/EventDetails/Infos'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { Grid } from 'components/Layout/Grid'

const EventDetails: React.FC<IPropsComponent<IEventDetails>> = (props) => {
  const eventDetails = props as IEventDetails

  const { codeUrl } = useParams<RouteParams['Event']>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(BackendControlledActions.initEventDetails({ eventDetails, codeUrl: codeUrl as string }))
  }, [dispatch, eventDetails, codeUrl])

  return (
    <Grid>
      <EventHead {...props} />
      <Infos {...props} />
      <Cover {...props} />
      <Actions {...props} />
    </Grid>
  )
}

export default EventDetails
