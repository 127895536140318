import { ICheckoutCartTicket, ITicketSeat, ITicketSelectionManual } from 'meta/pages/checkout'
import { selectSeat } from 'store/pages/checkout/actions/selectSeat'
import { CheckoutState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

export const setSelectingSeat: SliceUpdater<CheckoutState, ReturnType<typeof selectSeat.pending>> = (state, action) => {
  const ticketSelection = state.ticketSelection as ITicketSelectionManual
  const { seatId, sectionId } = action.meta.arg.seat

  const ticketSeat: ITicketSeat = { seatId, sectionId, typeTickets: [] }
  const ticketSeats = [...(ticketSelection.ticketSeats || []), ticketSeat]

  return {
    ...state,
    ticketSelection: { ...ticketSelection, ticketSeats } as ITicketSelectionManual,
  }
}

export const setSelectedSeat: SliceUpdater<CheckoutState, ReturnType<typeof selectSeat.fulfilled>> = (
  state,
  action
) => {
  const ticketSelection = state.ticketSelection as ITicketSelectionManual
  // close has been called before this
  if (action.payload && ticketSelection?.ticketSeats) {
    const { ticketSeat } = action.payload
    const { seatId, selectedTypeTicketId, sectionId } = ticketSeat

    const ticketSeats = [...ticketSelection.ticketSeats]
    const index = ticketSeats.findIndex((ticketSeat) => ticketSeat.seatId === seatId)
    ticketSeats[index] = ticketSeat

    const cartTickets: Array<ICheckoutCartTicket> = [...(state.cart?.tickets ?? [])]
    cartTickets.push({ quantity: 1, typeTicketId: selectedTypeTicketId as number, seatId, mapSectionId: sectionId })

    return {
      ...state,
      cart: { tickets: cartTickets, lastTypeTicket: state.cart?.lastTypeTicket },
      ticketSelection: { ...ticketSelection, ticketSeats },
    }
  }

  return state
}
