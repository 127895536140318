import css from './Mobile.module.scss'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  voices: string[]
  activeSection: string
  setActiveMenuVoiceCallback(voice: string): void
}

const Mobile: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { voices, activeSection, setActiveMenuVoiceCallback } = props
  const { t } = useTranslation()
  return (
    <div className={css.pagewithsubsections_voices_mobile_container}>
      <select
        value={activeSection}
        onChange={(e) => {
          setActiveMenuVoiceCallback(e.target.value)
        }}
      >
        {voices.map((elem, key) => {
          return (
            <option key={key} value={elem}>
              {elem}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Mobile
