import { useCallback } from 'react'

import { useAppDispatch } from 'store'
import { EventActions } from 'store/pages/event'

type Returned = () => void

export const useClose = (): Returned => {
  const dispatch = useAppDispatch()

  return useCallback<Returned>(() => {
    dispatch(EventActions.closeEventFees())
  }, [dispatch])
}
