import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldHidden extends IFormField<FormFieldType.hidden> {}

export class HiddenBuilder extends FormFieldBuilder<FormFieldType.hidden> {
  constructor(name: string) {
    super(name, FormFieldType.hidden)
  }
}
