import { Navigate, useRouteError } from 'react-router-dom'

import { Routes } from 'meta/routes'

const ErrorWrapper: React.FC = () => {
  const msg = useRouteError()

  return <Navigate to={Routes.Error.generatePath()} state={{ msg }} />
}

export default ErrorWrapper
