import { ComponentType, ISquare } from '../../structure'
import { IEventSquareBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class SquareAdapter extends ComponentAdapter<ISquare, IEventSquareBackend> {
  convert(): ISquare {
    const { codeUrl, datetime_tz, eventAsset, location, name } = this.componentBackend

    const imageUrl =
      eventAsset && eventAsset.covers && eventAsset.covers.length > 0 ? eventAsset.covers[0].src[0].url : null

    return {
      componentType: ComponentType.square,
      codeUrl,
      date: datetime_tz,
      // @ts-ignore
      imageUrl,
      name,
      location: location.name,
      common: location.common,
    }
  }
}
