import css from './LinkedSeatsConfirmDialog.module.scss'
import React, { Dispatch, SetStateAction, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import { IMapSeat, MapIDs, MapViewerMediator } from 'meta/map'

import { ButtonRed, ButtonWhiteBlack } from 'components/Actions/Action'
import Checkbox from 'components/Forms/Checkbox'
import BalloonBox from 'components/TextBoxes/BalloonBox'

interface Props {
  linkedSeatsConfirm: Array<IMapSeat>
  mapViewerMediator: MapViewerMediator
  onSeatSelect: (seats: Array<IMapSeat>, typeTicketId?: number) => void
  setLinkedSeatsConfirm: Dispatch<SetStateAction<Array<IMapSeat> | undefined>>
}

const LinkedSeatsConfirmDialog: React.FC<Props> = (props) => {
  const { linkedSeatsConfirm, mapViewerMediator, onSeatSelect, setLinkedSeatsConfirm } = props

  const { t } = useTranslation()
  const [selfDeclaration, setSelfDeclaration] = useState<boolean>(false)

  return ReactDOM.createPortal(
    <div className={css.linkedSeatsConfirm__wrapper} data-modalclose="skip">
      <BalloonBox infoType={'info'}>
        <div className={css.linkedSeatsConfirm}>
          <div>{t('checkout:single_seat_not_available')}</div>
          <h5>
            {t('checkout:linked_seats_confirm', {
              seats: linkedSeatsConfirm.map((seat) => MapIDs.toSeatIDs(seat.seatId).seat).join(', '),
            })}
          </h5>
          <Checkbox
            checked={selfDeclaration}
            label={String(t('checkout:self_declaration_joint_people'))}
            onChange={setSelfDeclaration}
          />
          <div className={css.linkedSeatsConfirm__buttonBar}>
            <ButtonWhiteBlack
              onClick={() => {
                linkedSeatsConfirm.forEach((seat) => {
                  mapViewerMediator.map2DViewer.unselectSeat(seat.seatId)
                })
                setLinkedSeatsConfirm(undefined)
              }}
            >
              <h5 data-modalclose="skip">{t('checkout:code_undo_button')}</h5>
            </ButtonWhiteBlack>
            <ButtonRed
              disabled={!selfDeclaration}
              onClick={() => {
                linkedSeatsConfirm.forEach((seat) => {
                  mapViewerMediator.map2DViewer.selectSeat(seat.seatId, seat.sectionId, true)
                })
                onSeatSelect(linkedSeatsConfirm)
                setLinkedSeatsConfirm(undefined)
              }}
            >
              <h5 data-modalclose="skip">{t('checkout:voucher_confirm')}</h5>
            </ButtonRed>
          </div>
        </div>
      </BalloonBox>
    </div>,
    document.getElementById('root')!.children[0]
  )
}

export default LinkedSeatsConfirmDialog
