import { ComponentColor, ComponentType, IComponentNested } from './component'

export enum BalloonSize {
  big = 'big',
  medium = 'medium',
  small = 'small',
}

export enum BalloonTipPosition {
  left = 'left',
  right = 'right',
}

export enum BalloonBgPosition {
  top = 'top',
  center = 'center',
}

export interface IBalloon extends IComponentNested<ComponentType.balloon> {
  backgroundUrl?: string
  color: ComponentColor
  size: BalloonSize
  tipPosition: BalloonTipPosition
}
