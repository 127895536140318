import css from './WalletTicketMobile.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn } from 'components/Layout/Grid'

export const WalletTicketMobileSkeleton: React.FC = () => {
  return (
    <Grid className={classNames(css.walletTicket)}>
      <GridColumn
        gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 3 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 5 }}
      >
        <div className={classNames(css.walletTicket__img)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </GridColumn>

      <GridColumn
        className={css.walletTicket__details}
        gridColumnStart={{ [Breakpoints.mobile]: 5 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 13 }}
      >
        <div className={css.date}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'40%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.name}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'60%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>

        <div className={css.location_text__skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'30%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </GridColumn>

      <GridColumn
        className={css.walletTicket__tickets}
        gridColumnStart={{ [Breakpoints.mobile]: 13 }}
        gridColumnEnd={{ [Breakpoints.mobile]: 17 }}
      >
        <div className={classNames(css.walletTicket__ticketsImg__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            height={'100%'}
            width={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </GridColumn>
    </Grid>
  )
}
