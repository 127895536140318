import css from './Header.module.scss'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ComponentType, IButton, IComponent } from 'meta/backendControlled/structure'
import { SearchParamUtils } from 'meta/pages/search'
import { convertStringToSnakeCase } from 'utils/string'

import Img from 'components/Media/Img'
import { useOnUpdate } from 'hooks/useOnUpdate'

interface IPropsHeader {
  button?: IButton
  data: Array<IComponent<ComponentType>>
  title: string
  visibleIdx: Array<number>
}

const Header: React.FC<IPropsHeader> = (props) => {
  const { button, data, title, visibleIdx } = props
  const hasArtist = data.some((x) => x && x.componentType === ComponentType.artist)
  const progressBarRef = useRef<HTMLDivElement>(null)

  const itemsTotal = 20
  const items = new Array(itemsTotal).fill(0)

  useOnUpdate(() => {
    const firstVisibleIdx = visibleIdx[0]
    const progressBar = progressBarRef.current
    if (progressBar) {
      const element = progressBar.children[firstVisibleIdx] as HTMLDivElement
      if (element) {
        progressBar.scrollTo({ left: element.offsetLeft - element.clientWidth * 3, behavior: 'smooth', top: 0 })
      }
    }
  }, [visibleIdx])

  return (
    <div className={classNames(css.categoryHeader, { [css.artist]: hasArtist })}>
      <h2 className={css.categoryHeader__title} id={convertStringToSnakeCase(title)}>
        {title}
      </h2>

      <div className={css.categoryHeader__actions}>
        {button && button.url ? (
          <Link to={button.url.includes('http') ? button.url : SearchParamUtils.toUrl({ eventCategory: button.url })}>
            <div className={css.categoryHeader__showAll}>
              <h5>{button.title}</h5>
              <Img alt="" src="/icons/arrow_right.svg" />
            </div>
          </Link>
        ) : (
          <div />
        )}

        {visibleIdx.length > 0 && visibleIdx.length < data.length && (
          <div className={css.categoryHeader__progressBar} ref={progressBarRef}>
            {items.map((_, idx) => {
              const idxCorrespondent = Math.floor((idx * data.length) / itemsTotal)
              const active = visibleIdx.includes(idxCorrespondent)

              return (
                <div
                  key={String(idx)}
                  className={classNames(css.categoryHeader__progressItem, {
                    [css.active]: active,
                  })}
                  datatype="category-header-progress-item"
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
