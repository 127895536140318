import { ICheckout, ICheckoutRecap, PaymentMethod } from 'meta/pages/checkout'
import { APICheckoutRecapToRecapData } from 'controller/checkout/adapters'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

type Params = {
  checkout: ICheckout
}

export const fetchRecap = async (params: Params): Promise<ICheckoutRecap> => {
  const { checkout } = params

  const paymentProvider =
    checkout.payment?.methodCurrent !== PaymentMethod.none ? checkout.payment?.methodCurrent : undefined

  const body = {
    codeUrl: checkout.eventCodeUrl,
    cartTickets: checkout.cart?.tickets,
    paymentProvider: paymentProvider,
    paymentProviderData:
      paymentProvider === PaymentMethod.voucher
        ? {
            code: checkout.payment?.couponCode,
          }
        : undefined,
  }
  const { data } = await HttpRequest.post<any>(ApiEndpoint.checkout.recap(), body)

  return APICheckoutRecapToRecapData(data)
}
