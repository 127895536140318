import css from 'components/Pages/Resale/ResaleSkeleton/ResaleSkeleton.module.scss'
import React, { useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

const Paragraph: React.FC<{ size: number }> = ({ size }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.resale_skeleton__paragraph}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={4}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          className={css.resale_skeleton__line}
        />
      ))}
    </div>
  )
}

const ResaleSkeleton: React.FC = () => {
  return (
    <div className={css.resale_skeleton}>
      <div className={css.static_page_title}>
        <Skeleton
          className={css.resale_skeleton__img}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
        <Skeleton
          className={css.resale_skeleton__heading}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      </div>

      <div className={css.static_page_text}>
        <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
          <Paragraph size={7} />
          <Paragraph size={9} />
          <Paragraph size={11} />
          <Paragraph size={14} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
          <Paragraph size={6} />
          <Paragraph size={7} />
          <Paragraph size={9} />
          <Paragraph size={11} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait} maxWidth={BreakpointsMinWidth.tabletLandscape - 1}>
          <Paragraph size={4} />
          <Paragraph size={4} />
          <Paragraph size={5} />
          <Paragraph size={7} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletLandscape}>
          <Paragraph size={3} />
          <Paragraph size={3} />
          <Paragraph size={4} />
          <Paragraph size={5} />
        </MediaQuery>
      </div>
    </div>
  )
}

export default ResaleSkeleton
