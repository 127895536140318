import { createAsyncThunk } from '@reduxjs/toolkit'

import { TicketFee } from 'meta/pages/tickets'
import { EventController } from 'controller/event'

type Returned = Array<TicketFee>

type Props = {
  codeUrl: string
  typeTicketCollectionId?: string
}

export const showEventFees = createAsyncThunk<Returned, Props>('pages/event/fees/show', async (props) => {
  const { codeUrl, typeTicketCollectionId } = props
  return EventController.getFees({ codeUrl, typeTicketCollectionId })
})
