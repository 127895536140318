/* eslint-disable no-irregular-whitespace */
import css from './CookiePolicy.module.scss'
import React from 'react'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import TransparentWrapper from 'components/Structure/TransparentWrapper'

import CookiePolicySkeleton from './CookiePolicySkeleton'

const CookiePolicy: React.FC = () => {
  const { namespacesLoaded } = useI18NContext()

  const meta_title = 'Cookie Policy di TicketSms'

  return (
    <>
      <Head>
        <title>{meta_title}</title>
        <meta property="og:title" content={meta_title} />
      </Head>
      <div className={css.cookiepolicy_container}>
        <TransparentWrapper>
          <WithAppInitialized skeleton={<CookiePolicySkeleton />} showChildren={namespacesLoaded}>
            <div className={css.cookiepolicy_ita_container}>
              <div className={css.static_page_title}>
                <div>Cookie Policy</div>
              </div>
              <div className={css.static_page_text}>
                <p>
                  <div>
                    Ai sensi e per gli effetti del Regolamento (UE) 2016/679 (di seguito, per brevità, “Regolamento”),
                    della direttiva 2002/58/CE e del d.lgs. 196/2003 ss.mm.ii., TicketSms s.r.l., di seguito
                    identificata, Le fornisce le informazioni relative all’utilizzo dei cookie e altri strumenti di
                    tracciamento (tra cui, web beacon, web bug, clear GIF, ecc.) nell’ambito della navigazione del sito
                    https://www.ticketsms.it. Titolare del sito web e titolare del trattamento dei dati personali è la
                    TicketSms s.r.l. con sede in Bologna, Via Marsala, 28, 40126, P.I./C.F. 03653341200 (di seguito,
                    “titolare del trattamento” o “TicketSms”). Il titolare del trattamento di dati personali può essere
                    contattato all’indirizzo e-mail info@ticketsms.it.
                    <br />
                    <br />
                  </div>

                  <div>
                    <b>1. Che cosa sono i cookie?</b>
                    <br />
                    <br />
                    I cookie sono stringhe di testo di piccole dimensioni che i siti web (c.d. “prima parte”) visitati
                    dall’utente ovvero siti o web server diversi (c.d. “terze parti”) posizionano ed archiviano –
                    direttamente e indirettamente – all’interno del dispositivo dell’utente visitatore (ad esempio,
                    personal computer, un tablet, uno smartphone ed ogni altro dispositivo in grado di archiviare
                    informazioni).
                    <br />
                    Oltre ai cookie esistono altre tecnologie similari (a cui ci si riferisce con il termine di
                    “strumenti di tracciamento”) utilizzate per raccogliere informazioni sul comportamento dell’utente e
                    sull’utilizzo dei servizi, quali ad esempio web beacon, web bug, GIF trasparenti e tutte le forme di
                    storage locale introdotte con HTML5. Alcune delle finalità per le quali vengono impiegati cookie ed
                    altri strumenti di tracciamento potrebbero richiedere il consenso dell’utente. Se viene prestato il
                    consenso, esso può essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute
                    in questo documento.
                    <br />
                    Alcune delle finalità per le quali vengono impiegati cookie ed altri strumenti di tracciamento
                    potrebbero richiedere il consenso dell’utente. Se viene prestato il consenso, esso può essere
                    revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>2. Quali cookie e strumenti di tracciamento utilizza TicketSms?</b>
                    <br />
                    <br />
                    Il sito web di TicketSms utilizza i seguenti cookie e strumenti di tracciamento:
                    <br />
                    <br />
                    <ul>
                      <li>
                        - cookie e strumenti di tracciamento tecnici di terza parte, finalizzati a garantire il corretto
                        funzionamento del sito;
                      </li>
                      <li>
                        - cookie e strumenti di tracciamento analitici di terza parte per monitorare e analizzare il
                        traffico e l’utilizzo del sito;
                      </li>
                      <li>
                        - cookie e strumenti di tracciamento di profilazione di terza parte volti a creare profili
                        relativi all’utente e per promuovere offerte in linea con le preferenze manifestate dallo stesso
                        nell’ambito della navigazione in rete.
                      </li>
                    </ul>
                  </div>

                  <br />

                  <div>
                    <b>2.1. Cookie tecnici e strumenti di tracciamento strettamente necessari</b>
                    <br />
                    <br />
                    Il sito web di TicketSms utilizza cookie tecnici e altri strumenti di tracciamento indispensabili
                    per il corretto funzionamento del sito. Alcuni di questi sono utilizzati per gestire il login e
                    l’accesso alle funzioni riservate del sito; pertanto, la loro disattivazione potrebbe compromettere
                    l’utilizzo dei servizi accessibili da login.
                    <br />
                    <br />
                    Questa tipologia di strumenti, essendo strettamente necessaria, non richiede l’acquisizione del
                    consenso dell’utente e viene scaricata di default nel dispositivo dell’utente.
                    <div className={css.cookieBox}>
                      Nome:
                      <br />
                      Google reCAPTCHA
                      <br />
                      Descrizione:
                      <br />
                      Google reCAPTCHA è un servizio di protezione dallo SPAM fornito da Google Ireland
                      Limited.L&apos;utilizzo del sistema reCAPTCHA è soggetto alla privacy policy e ai termini di
                      utilizzo di Google.
                      <br />
                      Durata:
                      <br />
                      _GRECAPTCHA: durata della sessione
                      <br />
                      rc::a: non definita
                      <br />
                      rc::b: durata della sessione
                      <br />
                      rc::c: durata della sessione
                      <br />
                      Privacy Policy:
                      <br />
                      <a href="https://policies.google.com/privacy?hl=it">https://policies.google.com/privacy?hl=it</a>
                    </div>
                  </div>

                  <br />

                  <div>
                    <b>2.2. Cookie e strumenti di tracciamento di analisi e prestazioni</b>
                    <br />
                    <br />
                    Il sito web di TicketSms utilizza cookie analitici e altri strumenti di tracciamento volti a
                    raccogliere e analizzare il traffico e l’utilizzo del sito al fine di monitorare il sistema e
                    migliorarne le prestazioni e l’usabilità. La disattivazione di tali cookie può essere eseguita senza
                    alcuna perdita di funzionalità.
                    <div className={css.cookieBox}>
                      Nome:
                      <br />
                      Google Analytics
                      <br />
                      Descrizione:
                      <br />
                      Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google LLC oppure
                      da Google Ireland Limited, a seconda di come il Titolare gestisce il trattamento dei Dati,
                      (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare
                      l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da
                      Google.
                      <br />
                      Durata:
                      <br />
                      AMP_TOKEN: 1 ora
                      <br />
                      ga: 2 anni
                      <br />
                      gac*: 3 mesi
                      <br />
                      gat: 1 minuto
                      <br />
                      gid: 1 giorno
                      <br />
                      Privacy Policy:
                      <br />
                      <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    </div>
                    <div className={css.cookieBox}>
                      Nome:
                      <br />
                      Google Analytics 4
                      <br />
                      Descrizione:
                      <br />
                      Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google LLC oppure
                      da Google Ireland Limited, a seconda di come il Titolare gestisce il trattamento dei Dati,
                      (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare
                      l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da
                      Google.
                      <br />
                      Durata:
                      <br />
                      ga: 2 anni
                      <br />
                      ga_*: 2 anni
                      <br />
                      Privacy Policy:
                      <br />
                      <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    </div>
                  </div>

                  <br />

                  <div>
                    <b>2.3. Cookie e strumenti di tracciamento di profilazione</b>
                    <br />
                    <br />
                    Il sito web di TicketSms utilizza cookie e altri strumenti di tracciamento per individuare
                    specifiche azioni o schemi comportamentali ricorrenti nell’uso delle funzionalità offerte (pattern)
                    da parte dell’utente al fine di modulare la fornitura del servizio in modo sempre più
                    personalizzato, nonché inviare messaggi pubblicitari mirati, cioè in linea con le preferenze
                    manifestate dall’utente nell’ambito della navigazione in rete.
                    <div className={css.cookieBox}>
                      Nome:
                      <br />
                      Monitoraggio conversioni di Meta ads (pixel di Meta) (Meta Platforms, Inc.)
                      <br />
                      Descrizione:
                      <br />
                      Il monitoraggio conversioni di Meta ads (pixel di Meta) è un servizio di statistiche fornito da
                      Meta Platforms, Inc. che collega i dati provenienti dal network di annunci Meta con le azioni
                      compiute all&apos;interno di questo Sito Web. Il pixel di Meta monitora le conversioni che possono
                      essere attribuite alle inserzioni di Facebook, Instagram e Audience Network.
                      <br />
                      Durata:
                      <br />
                      _fbp: 3 mesi
                      <br />
                      fr: 3 mesi
                      <br />
                      Privacy Policy:
                      <br />
                      <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">
                        https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0
                      </a>
                    </div>
                    <div className={css.cookieBox}>
                      Nome:
                      <br />
                      Facebook Remarketing (Facebook, Inc.)
                      <br />
                      Descrizione:
                      <br />
                      Facebook Remarketing è un servizio di remarketing e behavioral targeting fornito da Facebook, Inc.
                      che collega l&apos;attività di questo Sito Web con il network di advertising Facebook.
                      <br />
                      Durata:
                      <br />
                      fbp: 3 mesi
                      <br />
                      Privacy Policy:
                      <br />
                      <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">
                        https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0
                      </a>
                    </div>
                  </div>

                  <br />

                  <div>
                    <b>3. Finalità e base giuridica</b>
                    <br />
                    <br />
                    L’uso dei cookie e degli altri strumenti di tracciamento, riportati sopra in dettaglio, è
                    finalizzato a:
                    <br />
                    <br />
                    <ul>
                      <li>
                        a. garantire il corretto funzionamento, la navigazione e la gestione del sito web, sulla base
                        dell’interesse legittimo di TicketSms a fornire un sito web efficiente e funzionale (cookie
                        tecnici);
                      </li>
                      <li>
                        b. analizzare il traffico e l’utilizzo del sito, sulla base del previo e libero consenso
                        manifestato dall’utente (cookie analitici);
                      </li>
                      <li>
                        c. individuare le preferenze dell’utente e proporre inserzioni o messaggi pubblicitari
                        personalizzati, sulla base del previo e libero consenso manifestato dall’utente (cookie di
                        profilazione).
                      </li>
                    </ul>
                  </div>

                  <br />

                  <div>
                    <b>4. Periodo di conservazione</b>
                    <br />
                    I dati personali raccolti tramite cookie e altri strumenti di tracciamento di prima parte sono
                    trattati per il periodo di tempo sopra indicato in relazione a ciascun cookie o strumento. Decorsi i
                    termini di conservazione, i dati saranno distrutti o resi anonimi, salve eventuali necessità di
                    accertamento di reati da parte dell’Autorità giudiziaria.
                    <br />
                    Per maggiori informazioni riguardo al periodo di conservazione di dati personali raccolti tramite
                    cookie o altri strumenti di tracciamento di terze parti, si prega di consultare le relative
                    informative consultabili attraverso le apposite sezioni.
                  </div>

                  <br />

                  <div>
                    <b>5. Gestione dei cookie</b>
                    <br />
                    <br />
                    Per impostazione predefinita, al momento del primo accesso al sito, nessun cookie o altro strumento,
                    diverso da quelli tecnici o equiparabili, viene posizionato all’interno del dispositivo dell’utente
                    né viene utilizzata alcuna diversa tecnica attiva o passiva di tracciamento.
                    <br />
                    I cookie e gli strumenti di tracciamento diversi da quelli tecnici o equiparabili sono trattati solo
                    previo consenso dell’utente. La consultazione del sito è garantita anche in caso di rifiuto di
                    questa tipologia di cookie.
                    <br />
                    L’utente può, in qualsiasi momento, modificare le proprie scelte relativamente all’uso dei cookie,
                    utilizzando le impostazioni del proprio browser.
                  </div>

                  <br />

                  <div>
                    <b>6. Destinatari dei dati</b>
                    <br />
                    <br />
                    I dati personali raccolti e trattati attraverso i cookie e gli altri strumenti di tracciamento
                    potrebbero essere comunicati alle seguenti categorie di soggetti:
                    <br />
                    <br />
                    - soggetti di cui TicketSms si avvale per lo svolgimento delle attività strumentali al
                    raggiungimento delle finalità di cui sopra, come, ad esempio, fornitori di servizi tecnici terzi,
                    hosting provider, società informatiche, agenzie di comunicazione;
                    <br />- fornitori di strumenti di terza parte che agiscono come titolari autonomi del trattamento di
                    dati personali.
                  </div>

                  <br />

                  <div>
                    <b>7. Luogo del trattamento</b>
                    <br />
                    <br />
                    TicketSms e i Responsabili del trattamento designati effettuano il trattamento dei Suoi dati in
                    Italia o in ogni caso all’interno dell’Unione Europea o nel Regno Unito, dove, secondo la Decisione
                    di esecuzione (UE) 2021/1772 della Commissione europea del 28 giugno 2021, è assicurato un livello
                    di protezione adeguato nell’ambito di applicazione del Regolamento.
                  </div>

                  <br />

                  <div>
                    <b>8. Diritti degli interessati</b>
                    <br />
                    <br />
                    Fatte salve le limitazioni all’esercizio dei diritti degli interessati di cui agli artt. 2-undecies
                    e 2-duodecies del d.lgs. 196/2003 s.m.i. (“Codice in materia di protezione dei dati personali”),
                    l’utente, in quanto soggetto interessato, può esercitare i diritti riconosciuti ai sensi e nei
                    limiti degli artt. 15-21 del Regolamento, tra cui il diritto di chiedere al titolare l’accesso ai
                    propri dati personali, la rettifica o la cancellazione degli stessi, nonché la limitazione del
                    trattamento dei dati che lo riguarda, l’opposizione al trattamento e la portabilità dei propri dati.
                    <br />
                    Ove espresso, l’utente ha inoltre il diritto di revocare il proprio consenso al trattamento dei
                    propri dati personali, in qualsiasi momento e senza che ciò pregiudichi la liceità del trattamento
                    basata sul consenso prima della revoca. Per modificare le preferenze sull’utilizzo dei cookie,
                    l’utente potrà utilizzare le impostazioni del proprio browser.
                    <br />
                    Infine, l’utente ha il diritto di proporre reclamo a un’autorità di controllo competente, ai sensi
                    dell’art. 77, 1° comma del Regolamento, che in Italia è il “Garante per la protezione dei dati
                    personali”.
                    <br />
                    L’utente può esercitare i diritti sopra indicati inviando una comunicazione scritta presso la sede
                    del titolare sopra indicata o all’indirizzo di posta elettronica info@ticketsms.it.
                  </div>

                  <br />

                  <div>
                    <b>9. Aggiornamento della cookie policy</b>
                    <br />
                    <br />
                    La presente informativa sull’uso dei cookie sarà costantemente aggiornata. Si consiglia pertanto di
                    controllare periodicamente questa pagina per prendere visione di eventuali cambiamenti.
                  </div>
                </p>
              </div>
            </div>
          </WithAppInitialized>
        </TransparentWrapper>
      </div>
    </>
  )
}

export default CookiePolicy
