import { useField } from 'formik'

type UseFormField = {
  /** Error message of the field */
  error?: string
  /** Has the field been visited? */
  touched: boolean
  /** Set the field's value */
  setValue: (value: any, shouldValidate?: boolean) => void
}

const useFormField = (name: string): UseFormField => {
  const [, { touched, error }, { setValue }] = useField(name)
  return { error, touched, setValue }
}

export default useFormField
