import { IWalletTicketFilter } from 'meta/pages/wallet'

import { joinParts } from './utils'

export const users = {
  emailConfirm: (token: string) => joinParts('users', 'email', 'confirm', token),

  login: () => joinParts('users', 'login'),
  loginFromIframe: () => joinParts('iframe', 'users', 'login'),

  csrfToken: () => joinParts('csrf', 'token'),

  otp: () => joinParts('users', 'otp'),
  otpVerify: () => joinParts('users', 'otp', 'verify'),

  signup: () => joinParts('users', 'signup'),
  signupData: () => joinParts('users', 'signup', 'data'),
  signupFromIframe: () => joinParts('iframe', 'users', 'signup'),

  signupSocial: () => joinParts('users', 'signup', 'social'),

  otpConfirm: () => joinParts('users', 'otp', 'confirm'),
  otpResend: () => joinParts('users', 'otp', 'resend'),

  passwordReset: () => joinParts('users', 'password', 'reset'),
  passwordUpdate: () => joinParts('users', 'password', 'update'),

  payments: (page: number) => `${joinParts('users', 'payments')}?page=${page}`,

  profile: () => joinParts('users'),

  logout: () => joinParts('users', 'logout'),

  changeName: () => joinParts('users', 'ticket', 'changeName'),
  changeNameConfirm: () => joinParts('users', 'ticket', 'changeNameConfirm'),

  resell: () => joinParts('users', 'ticket', 'resell'),
  resellConfirm: () => joinParts('users', 'ticket', 'resellConfirm'),

  resellUndo: () => joinParts('users', 'ticket', 'resellUndo'),
  resellUndoConfirm: () => joinParts('users', 'ticket', 'resellUndoConfirm'),

  walletTicket: (ticketId: number) => joinParts('users', 'wallet', 'tickets', String(ticketId)),

  walletEvents: () => joinParts('users', 'wallet', 'events'),
  walletEventsWithFilter: (filter: IWalletTicketFilter) => `${joinParts('users', 'wallet', 'events')}?filter=${filter}`,
  walletEventData: (codeUrl: string) => joinParts('users', 'wallet', 'events', String(codeUrl)),

  cards: () => joinParts('users', 'cards'),
  deleteCard: (cardId: string) => joinParts('users', 'cards', String(cardId)),
  saveCard: (cardId: string) => joinParts('users', 'cards', String(cardId)),

  dataRemoveStatus: () => joinParts('users', 'data', 'remove', 'status'),
  dataRemove: () => joinParts('users', 'data', 'remove'),
  authRemove: () => joinParts('users', 'data', 'remove', 'auth'),
}
