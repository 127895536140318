import { createAsyncThunk } from '@reduxjs/toolkit'

import { CheckoutController } from 'controller/checkout'
import { RootState } from 'store/types'

import { SeasonEventParams } from '../slice/types'

type Params = {
  codeUrl: string
  typeTicketCollectionId: string
}

type Returned = {
  data: SeasonEventParams
}

export const fetchSeasonEvents = createAsyncThunk<Returned, Params, { state: RootState }>(
  'checkout/fetchSeasonEvents',
  async (params) => {
    const { codeUrl, typeTicketCollectionId } = params

    const data = await CheckoutController.getSeasonEvents({ codeUrl, typeTicketCollectionId })

    return { data }
  }
)
