import cssProfile from 'components/Auth/Profile/Profile.module.scss'
import css from 'components/Pages/ProfileUndoResale/UndoResaleForm/ModalConfirm/ModalConfirm.module.scss'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { ApiEndpoint } from 'controller/endpoint'
import { Dates } from 'utils/dates'

import Form from 'components/Forms/Form'
import Modal from 'components/Layout/Modal'
import { useTicketResaleData } from 'hooks/useProfile'

type Props = {
  authToken: string
  onClose(): void
  onSuccess(): void
}

const ModalConfirm: React.FC<Props> = (props) => {
  const { authToken, onClose, onSuccess } = props

  const { t } = useTranslation()
  const data = useTicketResaleData()
  const { eventDate, eventLocation, eventName, firstName, fiscalSeal, lastName } = data

  const onFormSuccess = useCallback(() => {
    window && window.scrollTo(0, 0)
    onSuccess()
  }, [onSuccess])

  return (
    <Modal show={true} fullscreenOnMobile handleClose={onClose}>
      <div className={classNames(cssProfile.profileWrapper__section, css.undoResaleModalConfirm)}>
        <div data-testid={DataTestId.profile.undoResaleModal}>
          {t('profile:undo_resale_are_you_sure', {
            eventDate: Dates.format(eventDate ?? new Date(), t('dates:common:dayMonthYear')),
            eventName,
            eventLocation,
          })}
        </div>

        <Form
          action={ApiEndpoint.users.resellUndoConfirm()}
          authToken={authToken}
          bodyParser={() => ({ firstName, fiscalSeal, lastName })}
          formFields={[]}
          labelSubmit="common:confirm"
          onSuccess={onFormSuccess}
        />
      </div>
    </Modal>
  )
}

export default ModalConfirm
