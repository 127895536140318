import i18n from 'i18n'
import { ComponentType, IComponent } from 'meta/backendControlled/structure'
import { QueryParams, Routes } from 'meta/routes'
import { Dates } from 'utils/dates'
import { getCurrentPosition } from 'utils/geolocation'

export enum SearchParamName {
  city = 'city',
  dates = 'dates',
  eventCategory = 'eventCategory',
  geoPoint = 'geoPoint',
  page = 'page',
  size = 'size',
  k = 'k',
}

export enum SearchParamValue {
  //when
  always = 'always',
  nextweekend = 'nextweekend',
  pastevents = 'pastevents',
  //where
  currentposition = 'currentposition',
  everywhere = 'everywhere',
}

export interface ISearchParams {
  city?: string
  dates?: string | Array<Date>
  eventCategory?: string
  geoPoint?: Array<number>
  page?: number
  query: string
  size?: number
}

export interface ISearchResult {
  hasMorePages: boolean
  total: number
  components?: Array<IComponent<ComponentType>>
}

export interface ISearchCity {
  match: string
  name: string
}

export interface ISearchEventCategory {
  id?: number
  name: string
  status: string
  uuid: string
}

export interface ISearch {
  fetching: boolean
  params: ISearchParams
  result?: ISearchResult
  cities?: Array<ISearchCity>
  eventCategories?: Array<ISearchEventCategory>
}

export class SearchParamUtils {
  static async getGeoPointCurrentPosition(): Promise<Array<number>> {
    const crd = await getCurrentPosition()
    return [crd.latitude, crd.longitude]
  }

  static datesToString(searchParams: ISearchParams, dateFormat: string): string | null {
    const { dates } = searchParams

    if (!dates) return null

    if (dates.constructor === Array) {
      const from = Dates.format(dates[0], dateFormat)
      const to = Dates.format(dates[1], dateFormat)
      return `${from} - ${to}`
    }
    return dates as string
  }

  static toUrl(searchParams: Partial<ISearchParams>): string {
    const { city, dates, eventCategory, query } = searchParams

    const params: QueryParams['Search'] = {}

    if (query && query.trim().length > 0) params.k = query
    if (eventCategory && eventCategory.trim().length > 0) params.eventCategory = eventCategory
    if (city && city !== SearchParamValue.everywhere) params.city = city
    if (dates && dates !== SearchParamValue.always) {
      if (dates.constructor === Array) {
        const from = Dates.format(dates[0], i18n.t('dates:common:yearMonthDay'))
        const to = Dates.format(dates[1], i18n.t('dates:common:yearMonthDay'))
        params.dates = JSON.stringify([from, to])
      } else {
        params.dates = String(dates)
      }
    }

    return Routes.Search.generatePath(undefined, params)
  }

  static async fromUrl(): Promise<ISearchParams> {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const city = urlParams.get(SearchParamName.city) || SearchParamValue.everywhere
    let dates: string | Array<Date> = urlParams.get(SearchParamName.dates) || SearchParamValue.always
    if (dates.startsWith('[') && dates.endsWith(']')) {
      const datesArray = JSON.parse(dates)
      dates = [new Date(datesArray[0]), new Date(datesArray[1])]
    }
    const eventCategory = urlParams.get(SearchParamName.eventCategory)
    const geoPoint = city === SearchParamValue.currentposition ? await this.getGeoPointCurrentPosition() : undefined
    const query = urlParams.get(SearchParamName.k) || ''

    return { city, dates, eventCategory: eventCategory ?? undefined, geoPoint, page: 1, query, size: 30 }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static toPostBody(searchParams: ISearchParams): Record<string, any> {
    const { city, dates, eventCategory, geoPoint, page, query, size } = searchParams
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body: Record<string, any> = {}

    if (query && query.trim().length > 0) body[SearchParamName.k] = query
    if (city && ![SearchParamValue.everywhere.toString(), SearchParamValue.currentposition.toString()].includes(city))
      body[SearchParamName.city] = city
    if (dates && dates !== SearchParamValue.always) {
      body[SearchParamName.dates] =
        dates.constructor === Array
          ? [
              Dates.format(dates[0], i18n.t('dates:common:yearMonthDay')),
              Dates.format(dates[1], i18n.t('dates:common:yearMonthDay')),
            ]
          : dates
    }
    if (eventCategory && eventCategory.trim().length > 0) body[SearchParamName.eventCategory] = eventCategory
    if (geoPoint) body[SearchParamName.geoPoint] = { lat: geoPoint[0].toFixed(8), lon: geoPoint[1].toFixed(8) }

    body[SearchParamName.page] = page
    body[SearchParamName.size] = size

    return body
  }
}
