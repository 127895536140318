import cssField from '../FormField.module.scss'
import css from './Phone.module.scss'
import React, { useState } from 'react'
import { isSafari } from 'react-device-detect'
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Field } from 'formik'

import { IFormFieldPhone } from 'meta/form'
import { convertToFlagComponentFormat, getCodeFromPrefix, getPrefixFromCode } from 'utils/phoneCodes'

import { useOnUpdate } from 'hooks/useOnUpdate'

import { useFormContext } from '../../hooks'
import FormFieldError from '../FormFieldError'
import { IFormFieldProps } from '../props'
import useFormField from '../useFormField'

const Phone: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, namePrefix, placeholder, valuePrefix, disable } = formField as IFormFieldPhone

  const { t } = useTranslation()
  //@ts-ignore
  const prefixField = useFormField(namePrefix)
  const phoneField = useFormField(name)
  const hasPrefixError = Boolean(prefixField.touched && prefixField.error)
  const hasPhoneError = Boolean(phoneField.touched && phoneField.error)
  const formContext = useFormContext()

  //@ts-ignore
  const [prefixCC, setPrefixCC] = useState<string>(getCodeFromPrefix(valuePrefix))

  // on prefix value update, validate phone number as well
  useOnUpdate(() => {
    formContext.validateField(name)
    prefixField.setValue(getPrefixFromCode(prefixCC))
  }, [prefixCC])

  return (
    <div className={css.phoneContainer}>
      <div className={classNames(cssField.formField, { [css.safari]: isSafari }, { [cssField.error]: hasPrefixError })}>
        <Field
          type="hidden"
          value={getPrefixFromCode(prefixCC)}
          id={namePrefix}
          name={namePrefix}
          data-testid={namePrefix}
        />

        <ReactFlagsSelect
          selected={prefixCC}
          customLabels={convertToFlagComponentFormat()}
          searchable
          searchPlaceholder={String(t('header:search'))}
          onSelect={setPrefixCC}
          disabled={disable}
        />

        {/* @ts-ignore */}
        {hasPrefixError && <FormFieldError name={namePrefix} error={prefixField.error} />}
      </div>

      <div className={classNames(cssField.formField, { [cssField.error]: hasPhoneError })}>
        {disable && (
          <Field
            placeholder={t(`${placeholder}`)}
            type="phone"
            inputMode="numeric"
            pattern="[0-9]*"
            name={name}
            //@ts-ignore
            render={({ field }) => (
              <input {...field} data-testid={name} disabled={true} id={name} value={field.value ? field.value : null} />
            )}
          />
        )}

        {!disable && (
          <Field
            type="phone"
            data-testid={name}
            id={name}
            inputMode="numeric"
            name={name}
            pattern="[0-9]*"
            placeholder={t(`${placeholder}`)}
          />
        )}

        {/* @ts-ignore */}
        {hasPhoneError && <FormFieldError name={name} error={phoneField.error} />}
      </div>
    </div>
  )
}

export default Phone
