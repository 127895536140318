import React from 'react'
import { useTranslation } from 'react-i18next'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { FormSkeleton } from 'components/Forms/Form'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { IPropsChangePasswordForm } from 'components/Pages/ProfileChangePassword/ChangePasswordForm/ChangePasswordForm.types'
import ChangePasswordFormFields from 'components/Pages/ProfileChangePassword/ChangePasswordForm/ChangePasswordFormFields/ChangePasswordFormFields'

const ChangePasswordForm: React.FC<IPropsChangePasswordForm> = (props) => {
  const { authToken, email, fromProfile, onSuccess } = props

  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()

  return (
    <>
      <WithAppInitialized skeleton={<ProfileTranslationsSkeleton />} showChildren={namespacesLoaded}>
        {fromProfile ? <h3>{t('profile:change_password_header')}</h3> : <h4>{t('register:insert_new_password')}</h4>}
      </WithAppInitialized>
      <WithAppInitialized skeleton={<FormSkeleton items={2} />}>
        <ChangePasswordFormFields authToken={authToken} onSuccess={onSuccess} email={email} fromProfile={fromProfile} />
      </WithAppInitialized>
    </>
  )
}

export default ChangePasswordForm
