import React from 'react'

import { useEventCodeUrl } from 'hooks/useCheckout'

import Checkout from '../index'

const CheckoutConnected: React.FC = () => {
  const eventCodeUrl = useEventCodeUrl()

  if (!eventCodeUrl) return null

  return <Checkout />
}

export default CheckoutConnected
