import css from './RoundedBox.module.scss'
import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { TextBoxInfoTypes } from 'types/layout'

import Img from 'components/Media/Img'

interface IProps {
  bgColor?: string
  infoType: TextBoxInfoTypes
}

const RoundedBox: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { children, bgColor, infoType } = props

  return (
    <div
      className={classNames(css.textboxes_roundedbox, {
        [css.white]: bgColor === 'white',
        [css.black]: bgColor === 'black',
        [css.grey]: bgColor === 'grey',
        [css.yellow]: bgColor === 'yellow',
        [css.red]: bgColor === 'red',
        [css.green]: bgColor === 'green',
      })}
    >
      <div className={css.infoImg}>
        {infoType === 'info' && <Img src="/textboxes/textboxes_warning.svg" />}
        {infoType === 'warning' && <Img src="/textboxes/textboxes_warning.svg" />}
        {infoType === 'error' && <Img src="/textboxes/textboxes_error.svg" />}
        {infoType === 'success' && <Img src="/textboxes/textboxes_success.svg" />}
      </div>
      <div>{children}</div>
    </div>
  )
}

RoundedBox.defaultProps = {
  bgColor: 'white',
}

export default RoundedBox
