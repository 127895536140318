import css from './TitleBar.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

export const TitleBarSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid>
        <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }} gridColumnEnd={17}>
          <div className={css.checkout_titlebar_container}>
            <Skeleton
              className={css.titleSkeleton}
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={8}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}
