import css from './Header.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'

interface IProps {
  toggleOpen(): void
  isOpen: boolean
}

const Header: React.FC<IProps> = (props) => {
  const { isOpen, toggleOpen } = props

  const { t } = useTranslation()

  return (
    <div className={css.checkout_recapbox_mobile_header_container} onClick={toggleOpen}>
      <div className={css.label}>{t('checkout:recap_header')}</div>
      <div className={css.arrow}>
        {isOpen && <Img src="/arrows/black-up.svg" />}
        {!isOpen && <Img src="/arrows/black-down.svg" />}
      </div>
    </div>
  )
}

export default Header
