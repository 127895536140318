import css from 'components/Pages/ProfileCreditCards/ProfileCreditCards.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

import { ButtonWhiteBlack, ButtonWhiteRed } from 'components/Actions/Action'
import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'
import CardItem from 'components/Pages/ProfileCreditCards/CardItem'
import { useCreditCards } from 'components/Pages/ProfileCreditCards/hooks/useCreditCards'
import { ProfileCreditCardsSkeleton } from 'components/Pages/ProfileCreditCards/ProfileCreditCardsSkeleton'

const ProfileCreditCards: React.FC = () => {
  const { t } = useTranslation()

  const { cardList, cardToDelete, loading, onCreditCardDeleteRequest, onCreditCardDeleteDismiss, deleteCreditCard } =
    useCreditCards()

  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={css.profileCreditCards}>
      <WithAppInitialized skeleton={<ProfileTranslationsSkeleton />} showChildren={namespacesLoaded}>
        <h3 className={css.title}>{t('profile:see_creditcards_details')}</h3>
      </WithAppInitialized>

      {cardList.length === 0 && !loading && <h4 className={css.title}>{t('profile:no_cards_available')}</h4>}

      <WithAppInitialized skeleton={<ProfileCreditCardsSkeleton />} showChildren={!loading && cardList.length >= 0}>
        {cardList.map((card, idx) => {
          return (
            <CardItem
              key={idx}
              data={{
                description: `${card.brand.toUpperCase()} / ****${card.lastDigits}`,
                expiry: card.expiry,
                brand: card.brand,
              }}
              deleteCallback={() => onCreditCardDeleteRequest(card)}
            />
          )
        })}
      </WithAppInitialized>

      {cardToDelete && (
        <Modal handleClose={onCreditCardDeleteDismiss} hideCloseIcon transparent show>
          <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
            <div className={css.popupModal}>
              <h4 className={css.text}>
                {`${t('profile:card_ask_remove')} ${cardToDelete.brand} ${t('profile:card_ending_width')} ****${
                  cardToDelete.lastDigits
                }?`}
              </h4>
              {cardToDelete && loading && (
                <div className={css.profileCreditCards__spinner__delete}>
                  <ClipLoader color={TICKETSMS_COLORS_RED} />
                </div>
              )}
              <div className={css.buttons}>
                <ButtonWhiteBlack className={css.cancel} onClick={onCreditCardDeleteDismiss}>
                  <h5>{t('profile:card_undo')}</h5>
                </ButtonWhiteBlack>

                <ButtonWhiteRed className={css.remove} onClick={deleteCreditCard}>
                  <h5>{t('profile:card_remove')}</h5>
                </ButtonWhiteRed>
              </div>
            </div>
          </BalloonWrapper>
        </Modal>
      )}
    </div>
  )
}

export default ProfileCreditCards
