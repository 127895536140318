import css from 'components/BackendControlled/Component/EventDetails/Infos/Header/Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

export const InfosSkeleton: React.FC = () => {
  return (
    <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}>
      <div className={classNames(css.event_essentials_infos_header_container)}>
        <div className={classNames(css.daytime__skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.name_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.location_text_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'80%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.countdown_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={20}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.description_skeleton}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={4}
            height={'100%'}
            width={'90%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
          <div className={css.paragraph_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'90%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.paragraph_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'90%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.paragraph_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'90%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.paragraph_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={4}
              height={'100%'}
              width={'70%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>
      </div>
    </GridColumn>
  )
}
