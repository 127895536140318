import css from './Ticket.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import MediaQuery from 'react-responsive'

import { ITicket } from 'meta/backendControlled/structure'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { PaymentMethod } from 'meta/pages/checkout'
import { CheckoutActions } from 'store/pages/checkout'

import PaymentMethodDialogError from 'components/Checkout/PaymentMethodDialogError'
import Desktop from 'components/Checkout/TicketSelection/Automatic/Ticket/Desktop'
import Mobile from 'components/Checkout/TicketSelection/Automatic/Ticket/Mobile'
import {
  useCheckoutCartLastTypeTicket,
  useCheckoutCartTickets,
  useCheckoutDetails,
  useCheckoutPayment,
} from 'hooks/useCheckout'

const MAX_SELECTABLE_TICKETS = 10

interface IProps {
  data: ITicket
  analyticsCallback(typeTicketId: number, removeFromCart?: boolean): void
}

const Ticket: React.FC<IProps> = (props) => {
  const { data, analyticsCallback } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const checkoutDetails = useCheckoutDetails()
  const cartTickets = useCheckoutCartTickets()
  const lastTypeTicket = useCheckoutCartLastTypeTicket()
  const payment = useCheckoutPayment()
  const [stateVoucherError, setStateVoucherError] = useState<boolean>(false)

  const maxTickets =
    checkoutDetails?.needAuth &&
    checkoutDetails?.purchaseQuantityLimit &&
    checkoutDetails?.purchaseQuantityLimit < MAX_SELECTABLE_TICKETS
      ? checkoutDetails?.purchaseQuantityLimit
      : MAX_SELECTABLE_TICKETS

  const addTickets = () => {
    const cartTotal = cartTickets?.reduce<number>((total, { quantity }) => total + quantity, 0) ?? 0

    const isStateVoucherPMActive =
      payment &&
      payment?.methodCurrent &&
      [PaymentMethod.diciottoApp, PaymentMethod.cartaMeritoCultura].includes(payment.methodCurrent)

    if (isStateVoucherPMActive && cartTotal >= 1) {
      setStateVoucherError(true)
      return
    }

    if (cartTotal < maxTickets) {
      dispatch(CheckoutActions.addToCart({ id: data.id }))
      //analyticsCallback(data.id)
    } else {
      if (cartTotal < maxTickets) {
        dispatch(CheckoutActions.addToCart({ id: data.id }))
      } else {
        alert(String(t('checkout:max_num_tickets_alert', { maxTickets })))
      }
    }
  }

  const removeTickets = () => {
    analyticsCallback(data.id, true)
  }

  useEffect(() => {
    if (lastTypeTicket === data.id) {
      analyticsCallback(data.id)
    }
  }, [cartTickets])

  return (
    <div className={css.checkout_ticketselection_automatic_ticket_container} data-testid={`ticket-${data.id}`}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <Mobile data={data} addTickets={addTickets} removeTickets={removeTickets} />
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Desktop data={data} addTickets={addTickets} removeTickets={removeTickets} />
      </MediaQuery>

      {stateVoucherError && (
        <PaymentMethodDialogError
          paymentLabel={
            payment?.methodCurrent === PaymentMethod.diciottoApp
              ? t('checkout:diciotto_app')
              : t('checkout:carta_merito_cultura')
          }
          messageKey={'checkout:error_payment_method_change_payment'}
          onClose={() => setStateVoucherError(false)}
        />
      )}
    </div>
  )
}

export default Ticket
