import { ComponentTypeBackend } from '../component/component'
import ArtistAdapter from './artist'
import AssetAdapter from './asset'
import AssetSourceAdapter from './assetSource'
import BalloonAdapter from './balloon'
import BlogPostAdapter from './blogPost'
import CityAdapter from './city'
import EventCollectionMenuAdapter from './eventCollectionMenu'
import EventDetailsAdapter from './eventDetails'
import EventInfoAdapter from './eventInfo'
import EventTicketAdapter from './eventTicket'
import HorizontalScrollAdapter from './horizontalScroll'
import ParentSquareAdapter from './parentSquare'
import SquareAdapter from './square'
import StoryAdapter from './story'
import TicketAdapter from './ticket'
import { VenueDetailsAdapter, VenueInfoAdapter, VenueSquareAdapter } from './venue'
import VenueAttributesAdapter from './venueAttributes'

const adaptersMap = {
  [ComponentTypeBackend.asset]: AssetAdapter,
  [ComponentTypeBackend.assetSource]: AssetSourceAdapter,
  [ComponentTypeBackend.balloon]: BalloonAdapter,
  [ComponentTypeBackend.blogPost]: BlogPostAdapter,
  [ComponentTypeBackend.citySquare]: CityAdapter,
  [ComponentTypeBackend.eventArtist]: ArtistAdapter,
  [ComponentTypeBackend.eventCollectionMenu]: EventCollectionMenuAdapter,
  [ComponentTypeBackend.eventDetails]: EventDetailsAdapter,
  [ComponentTypeBackend.eventInfo]: EventInfoAdapter,
  [ComponentTypeBackend.eventSquare]: SquareAdapter,
  [ComponentTypeBackend.eventParentSquare]: ParentSquareAdapter,
  [ComponentTypeBackend.eventTicket]: EventTicketAdapter,
  [ComponentTypeBackend.horizontalScroll]: HorizontalScrollAdapter,
  [ComponentTypeBackend.storyGallery]: StoryAdapter,
  [ComponentTypeBackend.ticket]: TicketAdapter,
  [ComponentTypeBackend.typeTicketCollectionTicket]: EventTicketAdapter,
  [ComponentTypeBackend.venueAttributes]: VenueAttributesAdapter,
  [ComponentTypeBackend.venueDetails]: VenueDetailsAdapter,
  [ComponentTypeBackend.venueInfo]: VenueInfoAdapter,
  [ComponentTypeBackend.venueSquare]: VenueSquareAdapter,
}

export default adaptersMap
