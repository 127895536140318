import css from './EventList.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionEventList, CollectionFromIframe } from 'meta/pages/collection'

import { Grid, GridColumn } from 'components/Layout/Grid'
import { MockCollectionAnchorable } from 'components/Pages/Collection/mock'

import EventListItem from './EventListItem/EventListItem'

const EventList: React.FC<CollectionEventList & MockCollectionAnchorable & CollectionFromIframe> = (props) => {
  const { events, anchorId, fromIframe, toggleContent } = props

  const { t } = useTranslation()

  return (
    <Grid>
      <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
        <div className={css.eventListContainer}>
          <h2 className={css.title} id={anchorId}>
            {t('collection:event_title')}
          </h2>
          {events.map((elem, key) => (
            <EventListItem {...elem} key={key} fromIframe={fromIframe} toggleContent={toggleContent} />
          ))}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default EventList
