import React from 'react'

import { RequestMethod } from 'meta/api/requestMethod'
import { IUserData } from 'meta/auth'
import { FormBuilder, InputDateBuilder, PhoneBuilder, TextBuilder } from 'meta/form'
import { ApiEndpoint } from 'controller/endpoint'

import Form, { IFormOnSuccess } from 'components/Forms/Form'

type Props = {
  userData: IUserData
  onSuccess: IFormOnSuccess
  authToken: string
}

export const ChangeDataFormFields: React.FC<Props> = (props: Props) => {
  const { userData, onSuccess, authToken } = props

  const firstName = new TextBuilder('firstName')
    .addRequiredNameValidation()
    .addPlaceholder('form:firstName')
    .addValue(userData.firstName)
    .build()
  const lastName = new TextBuilder('lastName')
    .addRequiredNameValidation()
    .addPlaceholder('form:lastName')
    .addValue(userData.lastName)
    .build()
  const email = new TextBuilder('email')
    .addRequiredEmailValidation()
    .addValue(userData.email)
    .addPlaceholder('form:email')
    .build()
  const phone = new PhoneBuilder('phone', 'phonePrefix')
    .addValuePrefix(userData.phonePrefix)
    .addValue(userData.phone)
    .addRequiredValidation()
    .addPlaceholder('form:phone')
    .disable()
    .build()
  const birthDate = new InputDateBuilder('birthDate')
    .addRequiredBeforeNowValidation()
    .addValue(!userData.birthDate || userData.birthDate === '' ? new Date() : new Date(userData.birthDate))
    .addPlaceholder('form:birthDate')
    .build()
  const birthPlace = new TextBuilder('birthPlace')
    .addRequiredNameValidation()
    .addPlaceholder('form:birthPlace')
    .addValue(userData.birthPlace)
    .build()

  const formBuilder = new FormBuilder()
    .addField(firstName)
    .addField(lastName)
    .addField(email)
    .addField(phone)
    .addField(birthDate)
    .addField(birthPlace)

  return (
    <Form
      action={ApiEndpoint.users.profile()}
      authToken={authToken}
      formFields={formBuilder.build()}
      id="changeData-form"
      labelSubmit="profile:edit"
      onSuccess={onSuccess}
      requestMethod={RequestMethod.patch}
    />
  )
}
