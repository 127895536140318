import { createAsyncThunk } from '@reduxjs/toolkit'

import { IWalletTicket, IWalletTicketFilter } from 'meta/pages/wallet'
import { WalletController } from 'controller/wallet'
import { RootState } from 'store/types'

type Params = {
  eventsType: IWalletTicketFilter
}

export const getTickets = createAsyncThunk<
  { eventsType: IWalletTicketFilter; tickets: Array<IWalletTicket> },
  Params,
  { state: RootState }
>('wallet/tickets/get', async (params) => {
  const { eventsType } = params

  const tickets = await WalletController.getTickets({
    eventsType,
  })

  return { eventsType: eventsType, tickets: tickets }
})
