import css from './MenuDesktop.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import DataTestId from 'meta/dataTestId'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'
import { CookieClient, CookieName } from 'utils/cookie'

import { ButtonRed, LinkWhite } from 'components/Actions/Action'
import { useIsInsideProfileRoute } from 'components/Layout/Header/hooks/useIsInsideProfileRoute'
import Login from 'components/Layout/Header/Login'
import { useUrlBusiness } from 'hooks/useEnv'

type Props = {
  showLogin(): void
}

const MenuDesktop: React.FC<Props> = (props) => {
  const { showLogin } = props

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const insideProfile = useIsInsideProfileRoute()

  const userLoggedIn = CookieClient.get(CookieName.TOKEN)

  return (
    <div className={css.menuDesktop}>
      <LinkWhite title={t('header:create_event')} href={useUrlBusiness()}>
        <h5>{t('header:create_event')}</h5>
      </LinkWhite>
      <LinkWhite title={t('header:help')} href="/support/form">
        <h5 data-testid={DataTestId.support.header}>{t('header:help')}</h5>
      </LinkWhite>
      {!insideProfile && <Login showLogin={showLogin} />}
      {/* <AppDownload t={t} /> */}
      <ButtonRed
        onClick={() => {
          if (!userLoggedIn) {
            dispatch(LoginActions.show({ pathname: Routes.Wallet.generatePath() }))
          } else {
            navigate(Routes.Wallet.generatePath())
          }
        }}
      >
        <h5 title={t('header:tickets')}>{t('header:tickets')}</h5>
      </ButtonRed>
    </div>
  )
}

export default MenuDesktop
