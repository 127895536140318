import { RefObject, useEffect, useRef, useState } from 'react'

import { DOMs } from 'utils/doms'

import { useOnUpdate } from 'hooks/useOnUpdate'

export interface IShowNavButtons {
  showLeft: boolean
  showRight: boolean
}

/**
 * Scrolls to active section menu voice and checks whether to show left and right navigation buttons.
 */
export const useMenuItemsScroll = (
  menuItemRefs: RefObject<Record<string, HTMLDivElement>>,
  menuRef: RefObject<HTMLDivElement>,
  visibleSection?: string
): IShowNavButtons => {
  const menuItemPropsRefs = useRef<{ [key: string]: { width: number; x: number } }>({})
  const [showNavButtons, setShowNavButtons] = useState<IShowNavButtons>({ showLeft: false, showRight: true })

  const onScroll = () => {
    const values = Object.values(menuItemPropsRefs.current)
    const firstVoice = values[0]
    const lastVoice = values[values.length - 1]

    const scrollLeft = menuRef.current?.scrollLeft ?? 0
    const width = menuRef.current?.getBoundingClientRect()?.width ?? 0
    const showLeft = scrollLeft > firstVoice.width / 4
    const showRight = scrollLeft + width < lastVoice.x + lastVoice.width

    setShowNavButtons({ showLeft, showRight })
  }

  // store initial section link properties
  useEffect(() => {
    const entries = Object.entries(menuItemRefs.current as Record<string, HTMLDivElement>)
    entries.forEach(([section, divElement]) => {
      const { width, x } = divElement.getBoundingClientRect()
      const marginLeft = DOMs.getPropertyPxValue(divElement, 'margin-left')
      const marginRight = DOMs.getPropertyPxValue(divElement, 'margin-right')

      menuItemPropsRefs.current[section] = { width: width - marginLeft - marginRight, x }
    })

    menuRef.current?.addEventListener('scroll', onScroll)

    return () => {
      menuRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [])

  useOnUpdate(() => {
    if (visibleSection) {
      const itemProps = menuItemPropsRefs.current[visibleSection]
      if (itemProps) {
        const titlesMenu = menuRef.current
        const titlesMenuWidth = titlesMenu?.getBoundingClientRect()?.width ?? 0
        const { width, x } = itemProps

        const distanceToCenter = width / 2 - titlesMenuWidth / 2
        const left = x + distanceToCenter

        titlesMenu?.scrollTo({
          top: 0,
          left,
          behavior: 'smooth',
        })
      }
    }
  }, [visibleSection])

  return showNavButtons
}
