import css from './Radio.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { IFormFieldRadio, IFormFieldRadioOption } from 'meta/form'

import CheckboxComponent from 'components/Forms/Checkbox'

import FormFieldError from '../FormFieldError'
import { IFormFieldProps } from '../props'
import useFormField from '../useFormField'

const Radio: React.FC<IFormFieldProps> = (props) => {
  const { t } = useTranslation()

  const { formField } = props
  const { name, description, placeholder, options } = formField as IFormFieldRadio

  const radioField = useFormField(name)

  const initialValue = ''

  const [currentValue, setCurrentValue] = useState<string>(initialValue)

  return (
    <>
      <div className={css.radioGroupContainer} role="group" aria-labelledby={name}>
        {placeholder && <div className={css.placeholder}>{placeholder}</div>}

        <div className={css.optionsContainer}>
          {options.map((option: IFormFieldRadioOption, idx: number) => (
            <div key={idx} className={css.radioContainer}>
              <CheckboxComponent
                checked={currentValue == option.value}
                id={`${name}_${option.value}`}
                label={<div className={css.label} dangerouslySetInnerHTML={{ __html: String(t(option.label)) }} />}
                onChange={() => {
                  radioField.setValue(option.value, true)
                  setCurrentValue(option.value)
                }}
              />
            </div>
          ))}

          <Field id={`${name}_hidden`} name={name} placeholder={t(`${placeholder}`)} type="hidden" />
        </div>

        {description && <div className={css.description}>{String(t(description))}</div>}

        {radioField.touched && currentValue == initialValue && (
          <FormFieldError name={name} error={String(t(`form:errors_required_${name}`))} />
        )}
      </div>
    </>
  )
}

export default Radio
