import css from './Loader.module.scss'
import React, { PropsWithChildren } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

import { TICKETSMS_COLORS_RED } from 'utils/constants/colors'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

const Loader: React.FC<PropsWithChildren> = (props) => {
  return (
    <GridContainer style={{ alignSelf: 'center' }}>
      <Grid>
        <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.checkout__loader}>
          {props.children}
          <div className={css.beatloader_container}>
            <BeatLoader color={TICKETSMS_COLORS_RED} />
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default Loader
