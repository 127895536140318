import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'

import { ActionColor, Button } from 'components/Actions/Action'
import RoundedBox from 'components/TextBoxes/RoundedBox'

type Props = {
  onClose(): void
}

const Success: React.FC<Props> = (props) => {
  const { onClose } = props

  const { t } = useTranslation()

  return (
    <>
      <RoundedBox bgColor={ComponentColor.white} infoType="success">
        <span data-testid={DataTestId.profile.resaleSuccess}>{t('profile:resale_success_msg')}</span>
      </RoundedBox>
      <Button
        onClick={() => onClose()}
        backgroundColor={ActionColor.white}
        hover={ActionColor.white}
        borderColor={ActionColor.white}
      >
        {t('profile:resale_click_here_msg')}
      </Button>
    </>
  )
}

export default Success
