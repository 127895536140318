import { IMap, IMapSeat, IMapSection, IMapSectorsFilter } from './map'

export enum Map2DLevel {
  SEAT = 0,
  SECTION = 1,
}

export enum Map2DAction {
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
  ZOOM_RESET = 'zoom-reset',
  MOVE_UP = 'move-up',
  MOVE_DOWN = 'move-down',
  MOVE_LEFT = 'move-left',
  MOVE_RIGHT = 'move-right',
}

export enum MapEvent {
  LAYER_LEVEL_CHANGE = 'layer_level_change',
  SEAT_3D_PREVIEW_CHANGE = 'seat_3d_preview_change',
  SEAT_HIGHLIGHT = 'seat_highlight',
  SEAT_SELECT = 'seat_select',
  SECTION_SELECT = 'section_select',
}

export type MapEventSubscriber = (...args: any) => void

export interface MapViewerSubscriber {
  enableNotification(event: MapEvent): void

  disableNotification(event: MapEvent): void

  // @ts-ignore
  notify(event: MapEvent, ...args): void
  // @ts-ignore
  subscribe(event: MapEvent, subscriber: MapEventSubscriber)
}

export interface MapViewerMediator extends MapViewerSubscriber {
  readonly map2DViewer: Map2DViewer
  readonly map3DViewer: Map3DViewer

  init(container2D: HTMLDivElement, container3D?: HTMLDivElement): Promise<void>

  show2DViewer(resetZoom?: boolean): void

  show3DViewer(): void
}

export interface Map2DViewer {
  loadView(map: IMap, container: HTMLDivElement): Promise<void>

  hide(): void

  show(resetZoom?: boolean): void

  setAvailability(sectionsEnabled: Array<IMapSection>, seatsBusy: Array<string>): void

  filterSectors(sectorsFilter: IMapSectorsFilter): void

  selectSeat(seatId: string, sectionId?: string, disableNotification?: boolean): boolean

  selectRandomSeat(sectionId: string, typeTicketId?: number): void

  unselectSeat(seatId: string): void

  setLevel(level: Map2DLevel): void
  // @ts-ignore
  bindAction(action: Map2DAction, element: HTMLElement)
}

export interface Map3DViewer {
  init(container: HTMLDivElement): Promise<void>

  getThumbnail(map: IMap, seat: IMapSeat): Promise<any>

  loadView(map: IMap, seat: IMapSeat, container?: string | HTMLDivElement): Promise<void>

  hide(): void

  show(): void

  reset(): void
}
