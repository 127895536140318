import css from './PaginatorSkeleton.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

const PaginatorSkeleton: React.FC = () => {
  return (
    <div className={css.checkout_season_items_paginator_skeleton_container}>
      {[1, 2, 3].map((_elem, key) => (
        <Skeleton
          key={key}
          className={css.page}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={200}
          width={'32px'}
          height={'32px'}
          highlightColor={TICKETSMS_COLORS_WHITE}
        />
      ))}
    </div>
  )
}

export default PaginatorSkeleton
