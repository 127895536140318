import css from './Help.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'

import { LinkWhiteBlack } from 'components/Actions/Action'
import { GridColumn } from 'components/Layout/Grid'

interface Props {}

const Help: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 13, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
      gridRowStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      className={css.help}
    >
      <LinkWhiteBlack title={t('footer:help')} className={css.help__button} href="/support/form">
        <strong>{t('footer:help')}</strong>
      </LinkWhiteBlack>
    </GridColumn>
  )
}

export default Help
