import css from './Internal.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LoginStep } from 'meta/pages/login'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import Img from 'components/Media/Img'

type Props = {
  dataTestId: string
  icon: string
  labelKey: string
  step: LoginStep
}

const Internal: React.FC<Props> = (props) => {
  const { dataTestId, icon, labelKey, step } = props

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <button
      className={css.login__internal}
      data-testid={dataTestId}
      onClick={() => dispatch(LoginActions.updateStep({ step }))}
    >
      <div>
        <Img alt="" src={icon} />
        <h4>{t(labelKey)}</h4>
      </div>
    </button>
  )
}

export default Internal
