import css from './City.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ICity } from 'meta/backendControlled/structure'
import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { SearchParamUtils } from 'meta/pages/search'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { GridColumn } from 'components/Layout/Grid'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const City: React.FC<IPropsComponent<ICity>> = (props) => {
  const { events, imageUrl, locations, name, uuid } = props

  const { t } = useTranslation()
  const urlCloudfront = useUrlCloudfront()

  const cloudFrontPlaceHolder = `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.png`

  return (
    <GridColumn
      gridColumnSpan={{ [Breakpoints.mobile]: 8, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 3 }}
      id={getComponentId(uuid)}
      className={classNames(css.cityContainer, { [css.noImage]: !Boolean(imageUrl) })}
    >
      <ImgSkeleton alt={name} src={imageUrl ?? cloudFrontPlaceHolder} className={css.cityContainer__bg} />

      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <Link title={name} className={css.cityContainer__Link} to={SearchParamUtils.toUrl({ city: name })}>
          <div className={css.cityContainer__content}>
            <h2 className={css.cityContainer__contentHeader}>{name}</h2>
            <div className={css.cityContainer__contentText}>
              <div>
                <strong>{locations}</strong>
              </div>
              <div>{t('common:clubs')}</div>
            </div>
            <div className={css.cityContainer__contentText}>
              <div>
                <strong>{events}</strong>
              </div>
              <div>{t('common:events')}</div>
            </div>
          </div>
        </Link>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Link title={name} className={css.cityContainer__Link} to={SearchParamUtils.toUrl({ city: name })}>
          <div className={css.cityContainer__content}>
            <h4 className={css.cityContainer__contentHeader}>{name}</h4>
          </div>
        </Link>
      </MediaQuery>
    </GridColumn>
  )
}

export default City
