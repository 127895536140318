import { createSlice } from '@reduxjs/toolkit'

import { initialState } from 'store/pages/wallet/state'

import { getTickets } from '../actions/getTickets'

export const WalletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTickets.fulfilled, (state, action) => {
      const { tickets, eventsType } = action.payload
      state.tickets[eventsType] = tickets
    })
  },
})
