import css from './TicketInfo.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MapIDs } from 'meta/map'
import { ITicket } from 'meta/pages/tickets'

import Img from 'components/Media/Img'

interface IPropsTicketInfo {
  ticket: ITicket
}

const TicketInfo: React.FC<IPropsTicketInfo> = (props) => {
  const { ticket } = props
  const { t } = useTranslation()

  let seatIDs = null
  if (ticket.seatId) {
    seatIDs = MapIDs.toSeatIDs(ticket.seatId)
  }
  return (
    <div className={css.ticketInfo}>
      <div className={css.ticketInfo__imgTicket}>
        <Img src="/tickets/ticket_logo_placeholder.svg" />
      </div>

      <div className={css.ticketInfo__desc}>
        <h3 className={css.ticketInfo__descTitle}>{ticket.title}</h3>
        <div className={css.ticketInfo__descDetails}>
          {seatIDs ? t('checkout:row_and_seat', { row: seatIDs.row, seat: seatIDs.seat }) : ticket.details}
        </div>
      </div>
    </div>
  )
}

export default TicketInfo
