import css from './Mobile.module.scss'
import React from 'react'
import classNames from 'classnames'

import { DescriptionSkeleton } from 'components/Checkout/EventDetails/Description'

export const MobileSkeleton: React.FC = () => {
  return (
    <div className={classNames(css.checkout_eventdetails_mobile_container, css.skeleton)}>
      <DescriptionSkeleton />
    </div>
  )
}
