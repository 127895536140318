import { CurrencyCode, CurrencySymbol } from 'meta/currency'
import { ICheckout } from 'meta/pages/checkout'

export type CheckoutState = ICheckout

export const initialState: CheckoutState = {
  cart: {
    tickets: [],
    lastTypeTicket: undefined,
  },
  recap: {
    tickets: [],
    subtotal: {
      amount: 0,
      currency: CurrencyCode.EUR,
      symbol: CurrencySymbol.EUR,
    },
    commission: {
      amount: 0,
      currency: CurrencyCode.EUR,
      symbol: CurrencySymbol.EUR,
    },
    total: {
      amount: 0,
      currency: CurrencyCode.EUR,
      symbol: CurrencySymbol.EUR,
    },
  },
  process: {
    processingStarted: false,
  },
  season: {
    events: [],
  },
}
