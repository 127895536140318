import css from '../SearchFilters.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchParamUtils, SearchParamValue } from 'meta/pages/search'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import { ActionColor, ButtonWhite } from 'components/Actions/Action'
import { DateRangePicker } from 'components/Forms/DatePicker'
import Modal from 'components/Layout/Modal'
import Img from 'components/Media/Img'
import { useSearchParams } from 'hooks/useSearch'

interface IPropsFiltersWhen {
  closeFilters(): void
}

const FiltersWhen: React.FC<IPropsFiltersWhen> = (props) => {
  const { closeFilters } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const isDatesArray = searchParams.dates && searchParams.dates.constructor === Array

  const updateDates = (dates: string | Array<Date>): void => {
    const params = { ...searchParams, dates }
    dispatch(SearchActions.updateParams(params))
    closeFilters()
  }

  return (
    <div className={css.searchFilterOptions}>
      <ButtonWhite
        className={css.searchFilter}
        hover={ActionColor.grey}
        onClick={() => updateDates(SearchParamValue.always)}
      >
        <div>
          <span>{t('search:always')}</span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      <ButtonWhite
        className={css.searchFilter}
        hover={ActionColor.grey}
        onClick={() => updateDates(SearchParamValue.nextweekend)}
      >
        <div>
          <span>{t('search:nextweekend')}</span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      <ButtonWhite
        className={css.searchFilter}
        hover={ActionColor.grey}
        onClick={() => updateDates(SearchParamValue.pastevents)}
      >
        <div>
          <span>{t('search:pastevents')}</span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      <ButtonWhite className={css.searchFilter} hover={ActionColor.grey} onClick={() => setShowDatePicker(true)}>
        <div>
          <Img src="/icons/calendar.svg" />
          <span>
            {isDatesArray
              ? SearchParamUtils.datesToString(searchParams, t('dates:common:dayMonthYear'))
              : t('search:dates_placeholder')}
          </span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      {showDatePicker && (
        <Modal handleClose={() => setShowDatePicker(false)} show={showDatePicker}>
          <DateRangePicker
            dates={isDatesArray ? (searchParams.dates as Array<Date>) : undefined}
            onChange={(dates: Array<Date>) => updateDates(dates)}
          />
        </Modal>
      )}
    </div>
  )
}

export default FiltersWhen
