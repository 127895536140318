import css from './Desktop.module.scss'
import React, { PropsWithChildren } from 'react'

import Voice from 'components/PageWithSubsections/Voices/Voice'

interface IProps {
  voices: string[]
  activeSection: string
  setActiveMenuVoiceCallback(voice: string): void
}

const Desktop: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { voices, activeSection, setActiveMenuVoiceCallback, children } = props

  return (
    <div className={css.pagewithsubsections_voices_desktop_container}>
      {voices.map((elem, key) => {
        return (
          <Voice
            key={key}
            name={elem}
            isActive={elem === activeSection}
            setActiveMenuVoiceCallback={setActiveMenuVoiceCallback}
          />
        )
      })}

      {children}

      <div className={css.spacing_bottom}></div>
    </div>
  )
}

export default Desktop
