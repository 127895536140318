import { NavigateFunction } from 'react-router-dom'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { BackendControlledComponents } from 'meta/backendControlled/structure'
import { Routes } from 'meta/routes'
import { BackendControlledController } from 'controller/backendControlled'
import { BackendControlledSelectors } from 'store/backendControlled/selectors'
import { ThunkApiConfig } from 'store/types'

type Props = {
  path: string
  language: string
  navigate: NavigateFunction
}

type Returned = BackendControlledComponents & {
  path: string
}

export const getComponents = createAsyncThunk<Returned | undefined, Props, ThunkApiConfig>(
  'backendControlled/components/get',
  async (props, { getState }) => {
    const { language, navigate, path } = props

    const state = getState()
    const storeKey = `${path}/${language}`

    if (!BackendControlledSelectors.getComponents({ path: storeKey })(state)) {
      try {
        const response = await BackendControlledController.getComponents({ path })

        return { ...response, path: storeKey }
      } catch (error: any) {
        if (error?.status === 404) {
          navigate(Routes.NotFound.generatePath(), { replace: true })
        }
      }
    }
  }
)
