import { ICollection } from 'meta/pages/collection'
import { SliceSelector } from 'store/types'

import { getCollectionKey } from '../_utils'

const getCollectionDetails: SliceSelector<ICollection | undefined, { id: string; language: string }> =
  ({ id, language }) =>
  (state) =>
    state.pages.collection[getCollectionKey({ id, language })]?.details

export const CollectionSelectors = {
  getCollectionDetails,
}
