import css from '../NotFound.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

const NotFoundSkeleton: React.FC = () => {
  return (
    <>
      <div className={css.image_error_container}>
        <Skeleton
          className={css.notfound_skeleton__img}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      </div>

      <div className={css.error_text_container}>
        <Skeleton
          className={css.notfound_skeleton__heading}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
        <Skeleton
          className={css.notfound_skeleton__subheading}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        />
      </div>
    </>
  )
}

export default NotFoundSkeleton
