import css from './DateButton.module.scss'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { IEventCollectionMenuElement } from 'meta/backendControlled/structure/eventCollectionMenu'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { Dates } from 'utils/dates'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

interface IProps {
  onDateChange(codeUrl: string): void
  dateString: string
  isCurrent?: boolean
  isCurrentFullpass?: boolean
  currentCodeUrl: string
  eventList: IEventCollectionMenuElement[]
  isOpen: boolean
  setOpenButton(dateString: string | null): void
}

const DateButton: React.FC<IProps> = (props) => {
  const { onDateChange, dateString, currentCodeUrl, isCurrent, isCurrentFullpass, eventList, isOpen, setOpenButton } =
    props

  const fullpass = eventList[0] && eventList[0].fullpass

  const eventDate = eventList[0] && eventList[0].datetime ? eventList[0].datetime : new Date()
  const currentEvent = isCurrent ? eventList.filter((x) => x.codeUrl === currentCodeUrl)[0] : null
  const currentEventDate = isCurrent && currentEvent ? currentEvent.datetime : new Date()

  const { t } = useTranslation()

  const handleDateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onDateChange(event.target.value)
  }

  return (
    <GridColumn className={classNames(css.event_date_button_container, { [css.isSelectOpen]: isOpen })}>
      <div
        className={classNames(css.content, {
          [css.current]: isCurrent,
          [css.fullPass]: fullpass,
          [css.currentFullpass]: isCurrentFullpass,
        })}
        onClick={() => {
          if (eventList.length === 1 || fullpass) {
            onDateChange(eventList[0].codeUrl)
          } else if (eventList.length > 1) {
            !isOpen && setOpenButton(dateString)
            isOpen && setOpenButton(null)
          } else {
            // this should not happen
          }
        }}
      >
        {!fullpass && (
          <div className={css.dateBox}>
            <div className={css.dayName}>{`${Dates.format(new Date(eventDate), 'EEEE')}`}</div>
            <h3 className={css.dayNo}>{`${Dates.format(new Date(eventDate), 'd')}`}</h3>
            <div className={css.month}>{`${Dates.format(new Date(eventDate), 'MMMM')}`}</div>
          </div>
        )}

        {fullpass && (
          <div className={classNames(css.dateBox, css.fullPass)}>
            <span className={css.text}>{`${t('common:full_pass')}`}</span>
          </div>
        )}
      </div>

      {isOpen && (
        <div className={css.selectBox}>
          <div className={css.header}>
            <Img className={css.clock} src="/checkout/clock.svg" />
            <div className={css.text}>{`${t('common:select_hours')}`}</div>
          </div>
          <div className={css.formBox}>
            <select onChange={handleDateChange}>
              <option selected={true} value="">{`${t('common:select_placeholder')}`}</option>
              {eventList
                .sort(function (a, b) {
                  const aDate = a.datetime as Date
                  const bDate = b.datetime as Date
                  return aDate.getHours() - bDate.getHours()
                })
                .map((elem, key) => {
                  return (
                    <option key={key} value={elem.codeUrl}>
                      {Dates.format(new Date(elem.datetime), 'p')}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
      )}

      {isCurrent && !fullpass && !isCurrentFullpass && (
        <div className={css.hourBox}>
          <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
            <Img className={css.clock} src="/checkout/clock.svg" />
          </MediaQuery>
          <div className={css.text}>{`${t('common:hours')} ${Dates.format(currentEventDate, 'p')}`}</div>
        </div>
      )}
    </GridColumn>
  )
}

DateButton.defaultProps = {
  isCurrent: false,
  isCurrentFullpass: false,
}

export default DateButton
