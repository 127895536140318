import React, { useRef } from 'react'

import DataTestId from 'meta/dataTestId'
import { LoginData, LoginProvider } from 'meta/pages/login'

import { IStepProps } from 'components/Auth/LoginV2/Steps/StepCommon'
import { useLoginData } from 'hooks/useLogin'

import External from './External'

type AppleResponse = {
  authorization: {
    state: string
    code: string
    id_token: string
  }
  user?: {
    email: string
    name: {
      firstName: string
      lastName: string
    }
  }
}

export const Apple: React.FC<IStepProps> = (props) => {
  const loginData = useLoginData()
  const appleDataRef = useRef<AppleResponse>(null)

  const getLoginProviderData = async (): Promise<any> => {
    try {
      //@ts-ignore
      appleDataRef.current = await AppleID.auth.signIn()
      //@ts-ignore
      return { token: appleDataRef.current.authorization.id_token }
    } catch (error) {
      console.error(error)
    }
  }

  const getRegisterData = (): Promise<LoginData> =>
    new Promise((resolve) => {
      //@ts-ignore
      const { authorization, user } = appleDataRef.current
      const data: LoginData = {
        ...loginData,
        apple: {
          email: user ? user?.email : '',
          firstName: user ? user?.name?.firstName : '',
          lastName: user ? user?.name?.lastName : '',
          token: authorization.id_token,
        },
      }
      resolve(data)
    })

  return (
    <External
      {...props}
      dataTestId={DataTestId.login.btnApple}
      disabled={false}
      getLoginProviderData={getLoginProviderData}
      getRegisterData={getRegisterData}
      icon="/login/apple.svg"
      provider={LoginProvider.APPLE}
    />
  )
}
