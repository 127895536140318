import css from './Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import {
  TICKETSMS_COLORS_BLACK,
  TICKETSMS_COLORS_DARKGREY,
  TICKETSMS_COLORS_LIGHTBLACK,
  TICKETSMS_COLORS_LIGHTGREY,
  TICKETSMS_COLORS_MEDIUMGREY,
} from 'utils/constants/colors'

export const HeaderSkeleton: React.FC = () => {
  return (
    <div className={css.faq_menu_header_container}>
      <div className={css.faq_header}>
        <MediaQuery maxWidth={BreakpointsMinWidth.tabletPortrait}>
          <Skeleton
            className={css.imgSkeleton}
            baseColor={TICKETSMS_COLORS_BLACK}
            borderRadius={8}
            highlightColor={TICKETSMS_COLORS_DARKGREY}
            inline
          />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait + 1}>
          <Skeleton
            className={css.imgSkeleton}
            baseColor={TICKETSMS_COLORS_LIGHTBLACK}
            borderRadius={8}
            highlightColor={TICKETSMS_COLORS_DARKGREY}
            inline
          />
        </MediaQuery>
        <div className={css.title}>
          <Skeleton
            className={css.titleSkeleton}
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={8}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            inline
          />
        </div>
      </div>
    </div>
  )
}
