import css from './EventCollection.module.scss'
import React from 'react'

import { IEventCollectionMenu } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { ICheckoutDetails } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import EventDateList from 'components/BackendControlled/Component/EventCollectionMenu/EventDateList'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useCheckoutDetails } from 'hooks/useCheckout'

const EventCollection: React.FC = () => {
  const { eventCollection, insideIFrame } = useCheckoutDetails() as ICheckoutDetails
  const dispatch = useAppDispatch()

  if (!eventCollection) {
    return null
  }

  const onDateChange = (codeUrl: string) => {
    dispatch(CheckoutActions.init({ codeUrl, insideIFrame }))
  }

  const castData = eventCollection as IEventCollectionMenu

  return (
    <div className={css.event_collection_container}>
      <GridContainer>
        <Grid>
          <GridColumn
            gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
            gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
          >
            <h2>{eventCollection.title}</h2>
          </GridColumn>
        </Grid>
        <Grid>
          <GridColumn
            gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }}
            gridColumnEnd={{ [Breakpoints.laptop]: 16, [Breakpoints.mobile]: 17 }}
          >
            <EventDateList data={castData} onDateChange={onDateChange} insideCheckout removeContainer />
          </GridColumn>
        </Grid>
      </GridContainer>
    </div>
  )
}

export default EventCollection
