import { initSeats } from 'store/pages/event/actions/initSeats'
import { showEventFees } from 'store/pages/event/actions/showEventFees'
import { EventSlice } from 'store/pages/event/slice'

import { fetchMetadata } from './fetchMetadata'

export const EventActions = {
  ...EventSlice.actions,
  showEventFees,
  fetchMetadata,
  initSeats,
}
