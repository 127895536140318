import { BackendControlledMetadata, ComponentType, IComponent } from 'meta/backendControlled/structure'

import { ComponentTypeBackend, IComponentBackend } from '../component/component'
import ComponentAdapterFactory from './componentAdapterFactory'

export default class ComponentsAdapter {
  private readonly _metadata: BackendControlledMetadata

  constructor() {
    this._metadata = { og: { images: [] } }
  }

  get metadata(): BackendControlledMetadata {
    return this._metadata
  }

  public convertComponent(componentBackend: IComponentBackend<ComponentTypeBackend>) {
    const adapter = ComponentAdapterFactory.newComponentAdapter(componentBackend, this)
    const component = adapter.convert()
    const { uuid } = componentBackend

    return { ...component, uuid }
  }

  public convertComponents(
    componentsBackend: Array<IComponentBackend<ComponentTypeBackend>>
  ): Array<IComponent<ComponentType>> {
    return componentsBackend.map((componentBackend: IComponentBackend<ComponentTypeBackend>) =>
      this.convertComponent(componentBackend)
    )
  }
}
