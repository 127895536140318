import { Login, LoginStep } from 'meta/pages/login'

export const initialState: Login = {
  show: false,
  step: LoginStep.none,
  data: {
    birthDate: '',
    birthPlace: '',
    email: '',
    firstName: '',
    lastName: '',
    otpToken: '',
    otp: '',
    phone: '',
    phonePrefix: '+39',
    termsAndConditions: false,
    privacyPolicy: false,
    marketingPolicy: false,
    profilingPolicy: false,
    hasUser: false,
  },
}
