export enum BannerType {
  top = 'top',
  cover = 'cover',
  video = 'video',
}

export interface IBanner {
  imageUrl: string
  imageMobileUrl: string
  imageTabletUrl: string
  type: BannerType
}

export type BannerTop = {
  imageURLs: {
    desktop: string
    laptop: string
    mobile: string
    tabletLandscape: string
    tabletPortrait: string
  }
}
