import { useLocation, useParams } from 'react-router-dom'

import { RouteParams } from 'meta/routes'

const useBasePath = () => {
  const { pathname } = useLocation()
  const params = useParams<keyof RouteParams | {}>()

  if (Object.values(params).length < 1) {
    return pathname
  }

  return Object.values(params).reduce((path, param) => path.replace('/' + param, ''), pathname)
}

export { useBasePath }
