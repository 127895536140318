import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IWalletSeasonTicket, IWalletUserTicket } from 'meta/pages/wallet'
import { Dates } from 'utils/dates'

type Props = {
  userTicket: IWalletUserTicket & IWalletSeasonTicket
}

export const useTicketStatus = (props: Props) => {
  const { userTicket } = props
  const { t } = useTranslation()

  const getStatusByDate = useCallback(
    (date: string): string => {
      const validityDate = new Date(date)
      const validityDay = Dates.format(validityDate, t('dates:wallet:date'))
      const validityHours = Dates.format(validityDate, t('dates:common:hoursMinutes'))

      if (userTicket.seasonName) {
        return userTicket.statusLabel
      }

      return t(`wallet:ticket_${userTicket.isSeasonTicket ? 'season' : 'single'}_${userTicket.status}`, {
        time: validityHours,
        date: validityDay,
      })
    },
    [t, userTicket.isSeasonTicket, userTicket.status]
  )

  return useMemo<string>(() => {
    if (userTicket.status === 'valid' && userTicket.validityDate_tz) {
      return getStatusByDate(userTicket.validityDate_tz)
    }
    if (userTicket.status === 'checked' && userTicket.dateEntry_tz) {
      return getStatusByDate(userTicket.dateEntry_tz)
    }
    return userTicket.statusLabel
  }, [getStatusByDate, userTicket.dateEntry_tz, userTicket.status, userTicket.statusLabel, userTicket.validityDate_tz])
}
