import css from './LoginV2.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useUrlCloudfront } from 'hooks/useEnv'

import LoginGrid from './LoginGrid'
import { IPropsLogin } from './props'

const LoginGridContainer: React.FC<IPropsLogin> = (props) => {
  const { fromModal } = props
  const urlCloudfront = useUrlCloudfront()

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        <div className={css.height100}>
          <GridContainer
            className={css.loginContainer}
            style={fromModal ? {} : { backgroundImage: `url(${urlCloudfront}/images/login/login-bg.png)` }}
          >
            <LoginGrid {...props} dataTestId={DataTestId.login.container.mobile} />
          </GridContainer>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <div className={css.height100}>
          <GridContainer className={css.loginContainer}>
            <Grid className={css.height100}>
              <GridColumn
                gridColumnStart={4}
                gridColumnEnd={14}
                className={classNames(css.height100, { [css.fromModal]: fromModal })}
              >
                <BalloonWrapper
                  className={css.login__balloon}
                  color={ComponentColor.white}
                  tipPosition={BalloonTipPosition.right}
                  size={BalloonSize.small}
                >
                  <LoginGrid {...props} dataTestId={DataTestId.login.container.desktop} />
                </BalloonWrapper>
              </GridColumn>
            </Grid>
          </GridContainer>
        </div>
      </MediaQuery>
    </>
  )
}

export default LoginGridContainer
