import css from './TicketSeatSelector.module.scss'
import cssModal from 'components/Layout/Modal/Modal.module.scss'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { IMap, IMapSeat, IMapSection, MapEvent, MapIDs, MapViewerFactory, MapViewerMediator } from 'meta/map'
import { ITicketSeat } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { CollectionActions } from 'store/pages/collection'
import { EventActions } from 'store/pages/event'
import { TICKETSMS_COLORS_LIGHTGREY } from 'utils/constants/colors'

import LinkedSeatsConfirmDialog from 'components/Checkout/TicketSelection/TicketSeatSelector/LinkedSeatsConfirmDialog'
import SeatSelector from 'components/Checkout/TicketSelection/TicketSeatSelector/SeatSelector'
import SeatTooltip from 'components/Checkout/TicketSelection/TicketSeatSelector/SeatTooltip'
import TicketsSummary from 'components/Checkout/TicketSelection/TicketSeatSelector/TicketsSummary'
import Img from 'components/Media/Img'
import {
  useCheckoutDetails,
  useCheckoutEventCodeUrl,
  useCheckoutTicketSeats,
  useCheckoutTypeTicketCollectionId,
} from 'hooks/useCheckout'
import { useEventSeats } from 'hooks/useEvent'
import { useOnUpdate } from 'hooks/useOnUpdate'
import { usePrevious } from 'hooks/usePrevious'

interface IProps {
  eventId: number
  id: string
  map: IMap
  modalRef?: MutableRefObject<HTMLElement>
  onSeatSelect: (seats: Array<IMapSeat>, typeTicketId?: number) => void
  persistSelectSeat: boolean
  showTickets?: boolean
  fromCollection?: boolean
}

const TicketSeatSelector: React.FC<IProps> = (props) => {
  const { eventId, id, map, modalRef, onSeatSelect, persistSelectSeat, showTickets, fromCollection } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const details = useCheckoutDetails()
  const ticketSeats = useCheckoutTicketSeats()
  const { seatsBusy, linkedSeats, sectionsEnabled, sectors } = useEventSeats(id)
  const previousTicketSeats = usePrevious<Array<ITicketSeat>>([...ticketSeats])

  const typeTicketCollectionId = useCheckoutTypeTicketCollectionId()
  const codeUrl = useCheckoutEventCodeUrl()

  const [mapViewerMediator, setMapViewerMediator] = useState<MapViewerMediator>()
  const [layerLevel, setLayerLevel] = useState<number>(0)
  const [seat3d, setSeat3d] = useState<IMapSeat>()
  const [seatHighlight, setSeatHighlight] = useState<IMapSeat>()
  const [section, setSection] = useState<IMapSection>()
  const [summaryOpened, setSummaryOpened] = useState<boolean>(true)
  const [linkedSeatsConfirm, setLinkedSeatsConfirm] = useState<Array<IMapSeat>>()
  const [withSkeleton, setWithSkeleton] = useState<boolean>(true)
  const toggleSummary = () => setSummaryOpened((prevState) => !prevState)

  // @ts-ignore
  const container2D: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  // @ts-ignore
  const container3D: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const initialized = useRef<boolean>(false)

  useEffect(() => {
    if (fromCollection) {
      dispatch(
        CollectionActions.initSeats({
          codeUrl: String(codeUrl),
          typeTicketCollectionId: String(typeTicketCollectionId),
          id,
        })
      )
    } else {
      dispatch(EventActions.initSeats({ eventId, id }))
    }
  }, [dispatch, eventId, id, fromCollection])

  useOnUpdate(() => {
    if (map && sectionsEnabled && seatsBusy && linkedSeats && sectors && !mapViewerMediator && !initialized.current) {
      const scrollListener = modalRef && modalRef.current ? modalRef.current : window
      let onScroll = () => {}

      MapViewerFactory.createMapViewerMediator(
        map,
        persistSelectSeat,
        container2D.current,
        container3D.current,
        sectors
      ).then((mediator: MapViewerMediator) => {
        onScroll = () => setSeatHighlight(undefined)
        scrollListener.addEventListener('scroll', onScroll)

        setMapViewerMediator(mediator)
      })

      initialized.current = true

      return () => {
        scrollListener.removeEventListener('scroll', onScroll)
      }
    }
  }, [
    dispatch,
    id,
    linkedSeats,
    map,
    mapViewerMediator,
    modalRef,
    persistSelectSeat,
    seatsBusy,
    sectionsEnabled,
    sectors,
  ])

  useOnUpdate(() => {
    if (mapViewerMediator && sectionsEnabled && seatsBusy && linkedSeats) {
      mapViewerMediator.map2DViewer.setAvailability(sectionsEnabled, seatsBusy)

      const selectSeat = (seat: IMapSeat, typeTicketId?: number): void => {
        const seats: Array<IMapSeat> = [seat]
        const linkedSeatIDs = linkedSeats[seat.seatId]

        if (linkedSeatIDs) {
          linkedSeatIDs.forEach((seatId) => {
            seats.push({ seatId, sectionId: seat.sectionId })
          })
          setLinkedSeatsConfirm(seats)
        } else {
          // no linked seats
          onSeatSelect(seats, typeTicketId)
        }
      }

      mapViewerMediator.subscribe(MapEvent.LAYER_LEVEL_CHANGE, setLayerLevel)
      mapViewerMediator.subscribe(MapEvent.SEAT_3D_PREVIEW_CHANGE, setSeat3d)
      mapViewerMediator.subscribe(MapEvent.SEAT_SELECT, selectSeat)
      mapViewerMediator.subscribe(MapEvent.SEAT_HIGHLIGHT, setSeatHighlight)
      mapViewerMediator.subscribe(MapEvent.SECTION_SELECT, setSection)

      // map is opened in checkout
      if (details) {
        // select initial seat
        if (details.initialSeats) {
          details.initialSeats.forEach((initialSeat) => {
            const { seatId, sectionId } = initialSeat
            if (seatId === MapIDs.RANDOM) {
              const sectionRandomTicket = sectionsEnabled.find(
                (section) => section.orderCodeDescription === sectionId
              )?.sectionId
              if (sectionRandomTicket) {
                mapViewerMediator.map2DViewer.selectRandomSeat(sectionRandomTicket, details.initialTypeTicketId)
              }
            } else {
              mapViewerMediator.map2DViewer.selectSeat(seatId, sectionId, true)
              onSeatSelect([{ seatId, sectionId }])
            }
          })
          dispatch(CheckoutActions.updateDetails({ details: { ...details, initialSeats: undefined } }))
        }

        //navigating through checkout steps: when component is mounted selected seats must be marked as selected
        ticketSeats.forEach((ticketSeat) => {
          const { seatId } = ticketSeat
          const selected = mapViewerMediator.map2DViewer.selectSeat(seatId, undefined, true)
          if (!selected) {
            dispatch(CheckoutActions.deselectSeat({ seatId }))
          }
        })
      }

      // remove skeleton
      setWithSkeleton(false)
    }
  }, [mapViewerMediator, linkedSeats, sectionsEnabled, seatsBusy])

  // deselect seat from map
  useOnUpdate(() => {
    if (ticketSeats.length > (previousTicketSeats?.length ?? 0)) {
      setSummaryOpened(true)
    }

    previousTicketSeats?.forEach((prevTicketSeat) => {
      const prevSeatId = prevTicketSeat.seatId
      if (!ticketSeats.find((ticketSeat) => ticketSeat.seatId === prevSeatId)) {
        mapViewerMediator?.map2DViewer.unselectSeat(prevSeatId)
      }
    })
  }, [previousTicketSeats, ticketSeats])

  useOnUpdate(() => {
    if (linkedSeatsConfirm) document.body.classList.add(cssModal.modal_active)
    else if (id !== 'checkout') document.body.classList.remove(cssModal.modal_active)
  }, [linkedSeatsConfirm])

  return (
    <>
      <div
        className={classNames(css.ticketSeatSelectorContainer, {
          [css.seat3D]: Boolean(seat3d),
          [css.summary]: showTickets,
          [css.summaryOpened]: summaryOpened,
          [css.summaryClosed]: !summaryOpened,
          [css.tickets]: showTickets && ticketSeats.length > 0,
        })}
      >
        {withSkeleton && (
          <Skeleton
            baseColor={'transparent'}
            borderRadius={0}
            height={'100%'}
            width={'100%'}
            highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            className={css.skeleton}
          />
        )}

        <SeatSelector
          container2D={container2D}
          container3D={container3D}
          id={id}
          layerLevel={layerLevel}
          mapViewerMediator={mapViewerMediator}
          modalRef={modalRef}
          seat3d={seat3d}
          section={section}
        />

        <div className={css.seatsLegend}>
          {!sectors && (
            <div>
              <Img src="/checkout/map_seat_available.svg" />
              <div>{t('checkout:available')}</div>
            </div>
          )}
          <div>
            <Img src="/checkout/map_seat_not_available.svg" />
            <div>{t('checkout:not_available')}</div>
          </div>
          {ticketSeats.length > 0 && (
            <div>
              <Img src="/checkout/map_seat_selected.svg" />
              <div>{t('checkout:selected')}</div>
            </div>
          )}
        </div>

        {showTickets && <TicketsSummary id={id} summaryOpened={summaryOpened} toggleSummary={toggleSummary} />}

        {seatHighlight && (
          <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
            <SeatTooltip
              id={id}
              map={map}
              modalRef={modalRef}
              mapSeat={seatHighlight}
              // @ts-ignore
              mapViewerMediator={mapViewerMediator}
            />
          </MediaQuery>
        )}
      </div>

      {linkedSeatsConfirm && mapViewerMediator && (
        <LinkedSeatsConfirmDialog
          linkedSeatsConfirm={linkedSeatsConfirm}
          mapViewerMediator={mapViewerMediator}
          onSeatSelect={onSeatSelect}
          setLinkedSeatsConfirm={setLinkedSeatsConfirm}
        />
      )}
    </>
  )
}

TicketSeatSelector.defaultProps = {
  showTickets: true,
}

export default TicketSeatSelector
