import css from './Coverbox.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { GridColumn } from 'components/Layout/Grid'

const CoverBoxSkeleton: React.FC = () => {
  return (
    <GridColumn
      className={css.coverBoxGridContainer}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.laptop]: 13 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 17 }}
    >
      <div className={css.coverBoxContainer}>
        <div className={css.card}>
          <div className={css.image_skeleton}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>
      </div>
    </GridColumn>
  )
}

export default CoverBoxSkeleton
