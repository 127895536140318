import css from './RegisterStepper.module.scss'
import React from 'react'
import classNames from 'classnames'

import { registerSteps } from 'meta/pages/login'

import { useLoginStep } from 'hooks/useLogin'

import { StepColumn } from '../Steps/StepCommon'

const RegisterStepper: React.FC = () => {
  const step = useLoginStep()

  if (!registerSteps.includes(step)) return null

  return (
    <StepColumn className={css.registerStepper}>
      {registerSteps.map((elem) => (
        <div key={String(elem)} className={classNames(css.registerStepper__step, { [css.done]: elem <= step })} />
      ))}
    </StepColumn>
  )
}

export default RegisterStepper
