import { BalloonSize, BalloonTipPosition, ComponentColor, ComponentType, IBalloon } from '../../structure'
import { BalloonStyleResponse, IBalloonResponse } from '../component/balloon'
import ComponentAdapter from './componentAdapter'

export default class BalloonAdapter extends ComponentAdapter<IBalloon, IBalloonResponse> {
  public convert(): IBalloon {
    return {
      backgroundUrl: this.componentBackend.backgroundUrl,
      color: this.getColor(),
      componentType: ComponentType.balloon,
      // @ts-ignore
      size: BalloonSize[this.componentBackend.size] || BalloonSize.big,
      tipPosition: this.getTipPosition(),
      list: this.componentsAdapter.convertComponents(this.componentBackend.list).map((component) => ({
        ...component,
        insideBalloon: true,
      })),
    }
  }

  private getTipPosition(): BalloonTipPosition {
    switch (this.componentBackend.style) {
      case BalloonStyleResponse.ticketWhiteLeft:
      case BalloonStyleResponse.ticketBlackLeft:
        return BalloonTipPosition.left
      case BalloonStyleResponse.ticketBlackRight:
      case BalloonStyleResponse.ticketWhiteRight:
      default:
        return BalloonTipPosition.right
    }
  }

  private getColor(): ComponentColor {
    switch (this.componentBackend.style) {
      case BalloonStyleResponse.ticketBlackLeft:
      case BalloonStyleResponse.ticketBlackRight:
        return ComponentColor.black
      case BalloonStyleResponse.ticketBlueLeft:
      case BalloonStyleResponse.ticketBlueRight:
        return ComponentColor.blue
      case BalloonStyleResponse.ticketRedLeft:
      case BalloonStyleResponse.ticketRedRight:
        return ComponentColor.red
      case BalloonStyleResponse.ticketWhiteLeft:
      case BalloonStyleResponse.ticketWhiteRight:
      default:
        return ComponentColor.white
    }
  }
}
