import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'store'
import { BackendControlledActions } from 'store/backendControlled'

import { useIsAppInitialized } from 'hooks/useApp'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

type Props = {
  path: string
}

export const useGetComponents = (props: Props): void => {
  const { path } = props

  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()
  const language = useCurrentLanguage()

  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {
    if (appInitialized) {
      dispatch(BackendControlledActions.getComponents({ path, language, navigate }))
    }
  }, [appInitialized, dispatch, language, navigate, path])
}
