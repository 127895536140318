import css from './Terms.module.scss'
import React from 'react'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import TermsSkeleton from 'components/Pages/Terms/TermsSkeleton'
import TransparentWrapper from 'components/Structure/TransparentWrapper'

const Terms: React.FC = () => {
  const { namespacesLoaded } = useI18NContext()

  const meta_title = 'Acquisti Sicuri: Scopri Termini e Condizioni di TicketSms'
  const meta_desc =
    'Devi acquistare i biglietti per un evento? Leggi i nostri termini e condizioni: la tua garanzia per un acquisto sereno e senza sorprese. Scoprili subito, clicca qui.'

  return (
    <>
      <Head>
        <title>{meta_title}</title>

        <meta property="og:title" content={meta_title} />
        <meta name="description" content={meta_desc} />
        <meta property="og:description" content={meta_desc} />
      </Head>
      <div className={css.terms_container}>
        <TransparentWrapper>
          <WithAppInitialized skeleton={<TermsSkeleton />} showChildren={namespacesLoaded}>
            <div className={css.terms_ita_container}>
              <div className={css.static_page_title}>
                <Img src="/resale/resale.svg" />
                <div>Termini e condizioni di acquisto</div>
              </div>
              <div className={css.static_page_text}>
                <p>
                  <div>
                    <b>TICKETSMS S.R.L</b>
                    <br />
                    <b>TERMINI E CONDIZIONI GENERALI DI CONTRATTO PER L’UTILIZZO DEL SERVIZIO TICKETSMS</b>
                    <br />
                    <br />
                    <div>Ultimo aggiornamento: Gennaio 2024</div>
                    <br />
                    <div>CHI SIAMO</div>
                    <br />
                    TICKETSMS è la piattaforma dedicata alla vendita a distanza, alla compravendita non a distanza, per
                    quanto compatibile e non altrimenti derogata, alla rivendita di biglietti ed all’erogazione di
                    ulteriori servizi per eventi nazionali ed internazionali di spettacolo, intrattenimento culturale e
                    sportivo, effettuata da TicketSms S.r.l. in nome e per conto dell’organizzatore dell’evento nonché
                    il contratto di fornitura dei servizi, necessari ed accessori alla compravendita di tali biglietti,
                    prestati da TicketSms S.r.l.
                    <br />
                    <br />
                    Ai sensi e per gli effetti dell'art. 49 del Codice del Consumo (cd. Obblighi di informazione nei
                    contratti a distanza e nei contratti negoziati fuori dei locali commerciali) si comunica che il
                    fornitore del Servizio TICKETSMS nonché titolare della piattaforma TICKETSMS è la società TicketSms
                    S.r.l. con sede in Via Marsala n. 28 - 40126 - Bologna (BO) P.IVA / C.F. 03653341200 - Numero REA
                    BO-53618.
                    <br />
                    <br />
                    Di seguito sono specificate le condizioni generali di contratto (le “Condizioni generali di
                    contratto per l’utilizzo del servizio TICKETSMS”, da ora CGC) che regolano il Servizio TICKETSMS, la
                    cui accettazione integrale da parte del Cliente (come nel seguito definito) è condizione
                    indispensabile affinché lo stesso possa validamente e consapevolmente utilizzare il Servizio
                    TICKETSMS. Le CGC regolano i rapporti tra il Cliente e TICKETSMS nell'utilizzo e nella fruizione del
                    Servizio TICKETSMS.
                    <br />
                    <br />
                    <b>
                      CONDIZIONI GENERALI DI CONTRATTO PER L'UTILIZZO DEL SERVIZIO TICKETSMS
                      <br />
                      <br />
                      1) Definizioni Preliminari; <br />
                      2) Ambito di applicazione; <br />
                      3) Oggetto del servizio TicketSms; <br />
                      4) Acquisti sul Sito; <br />
                      5) Registrazione al Sito; <br />
                      6) Informazioni dirette alla conclusione del contratto; <br />
                      7) Disponibilità dei Titoli di Ingresso; <br />
                      8) Informazioni sui Titoli di Ingresso; <br />
                      9) Prezzo dei Titoli di Ingresso, Commissioni di Servizio, Oneri Aggiuntivi, Importo Totale;{' '}
                      <br />
                      10) Ordini di acquisto e casi di risoluzione di diritto del contratto con il cliente; <br />
                      11) Modalità di pagamento; <br />
                      12) Modalità di erogazione dei Titoli di Ingresso; <br />
                      13) Esclusione del diritto di recesso dal Contratto; <br />
                      14) Difformità dei Titoli di Ingresso ricevuti rispetto a quelli acquistati; <br />
                      15) Accesso agli eventi, allontanamento e annullamento di un Titolo emesso; <br />
                      16) Evento rinviato o annullato; <br />
                      17) Limitazioni di responsabilità e diritti di TicketSms; <br />
                      18) Divieti; <br />
                      19) Rivendita; <br />
                      20) Cambio nominativo; <br />
                      21) Assistenza e Reclami; <br />
                      22) Legge Applicabile e Foro Competente; <br />
                      23) Risoluzione alternativa delle controversie (c.d. Alternative Dispute Resolution/On Line
                      Dispute Resolution) e Conciliazione Paritetica Netcomm. <br />
                      <br />
                    </b>
                  </div>

                  <div>
                    <b>1. DEFINIZIONI PRELIMINARI</b>
                    <br />
                    <br />
                    Alle presenti CONDIZIONI GENERALI DI CONTRATTO PER L'UTILIZZO DEL SERVIZIO TICKETSMS si applicano le
                    seguenti definizioni, restando inteso che le definizioni al plurale si applicheranno al relativo
                    termine singolare e viceversa:
                    <br />
                    <br />
                    <b>1.1. Cliente:</b> indica il soggetto che, per effetto dei Servizi TicketSms, acquista il Titolo
                    di Ingresso dall’Organizzatore tramite il Sito, tramite l’App ovvero presso i Punti Vendita
                    TicketSms (non a distanza).
                    <br />
                    <b>1.2. Codice del Consumo:</b> norma della Repubblica italiana, emanata con decreto legislativo 6
                    settembre 2005, n. 206 e s.m.i. , in materia di diritti del consumatore (da definirsi come la
                    persona fisica che agisce per scopi estranei all'attività imprenditoriale o professionale
                    eventualmente svolta ed anche la persona fisica alla quale sono dirette le informazioni commerciali;
                    <br />
                    <b>1.3. Contratto di Servizi TicketSms:</b> indica, in via esclusiva, il contratto che si conclude
                    tra TicketSms ed il Cliente avente ad oggetto la prestazione dei Servizi TicketSms, disciplinato
                    dalle presenti CGC e dalle altre informazioni fornite sul Sito ai sensi e per gli effetti di cui
                    agli artt. 49 (Obblighi di informazione nei contratti a distanza e nei contratti negoziati fuori dei
                    locali commerciali) e ss. del Codice del Consumo.
                    <br />
                    <b>1.4. Contratto di Vendita:</b> indica il contratto che si conclude tra TicketSms ed il Cliente
                    avente ad oggetto la compravendita del Titolo di Accesso tramite i servizi offerti da TicketSms.
                    <br />
                    <b>1.5. Consumatore:</b> indica il Cliente persona fisica che utilizza il servizio TicketSms per
                    l’acquisto e/o la rivendita di Titoli di Accesso per scopi estranei all’attività professionale o
                    imprenditoriale eventualmente svolta.
                    <br />
                    <b>1.6. Contratto:</b> indica di volta in volta il Contratto di Servizi TicketSms o il Contratto di
                    Vendita (1.3 e 1.4).
                    <br />
                    <b>1.7. Evento:</b> indica la manifestazione, lo spettacolo o la rappresentazione a cui il Cliente
                    ha il diritto di accedere e a cui il Titolo di Accesso si riferisce.
                    <br />
                    <b>1.8. Festival:</b> indica l’evento in cui è prevista l’esibizione di più artisti nel medesimo
                    contesto.
                    <br />
                    <b>1.9. Luogo dell’evento:</b> indica l'insieme dei fabbricati e/o dei terreni che costituiscono i
                    luoghi di svolgimento di uno specifico evento.
                    <br />
                    <b>1.10. Organizzatore:</b> indica il soggetto che organizza l'Evento per il quale il Titolo di
                    Accesso è stato emesso. TicketSms nella vendita del Titolo di Accesso mediante il Servizio TicketSms
                    agisce in nome e per conto dell'Organizzatore. Ai sensi e per gli effetti dell'articolo 49 del
                    Codice del Consumo (Obblighi di informazione nei contratti a distanza e nei contratti negoziati
                    fuori dei locali commerciali), i dati ed i riferimenti dell'Organizzatore sono indicati al Cliente
                    prima del perfezionamento dell’acquisto e sono riportati sul Titolo di Accesso.
                    <br />
                    <b>1.11. Servizio o Servizi TicketSms:</b> indica l’insieme dei servizi di prenotazione, emissione,
                    messa a disposizione e consegna del Titolo di accesso al Cliente tramite la rete di vendita
                    TicketSms nonché gli ulteriori servizi accessori afferenti la vendita del Titolo di Ingresso, ove
                    richiesti dal Cliente. Il servizio TicketSms non comprende la fornitura della prestazione
                    consistente nell’evento che rimane atto e/o obbligo di esclusiva competenza e responsabilità
                    dell’Organizzatore, in nome e per conto del quale TicketSms agisce fornendo esclusivamente il
                    Servizio TicketSms.
                    <br />
                    <b>1.12. Titolare del Titolo d’Accesso/Ingresso:</b> è il soggetto che detiene legittimamente ed in
                    conformità alle presenti CGC un Titolo di Accesso con indicazione del proprio nome laddove il Titolo
                    di Accesso sia nominativo.
                    <br />
                    <b>1.13. Titolo d’Accesso/Titolo d’Ingresso:</b> indica il documento, anche in formato digitale,
                    avente valore fiscale, emesso da TicketSms ed acquistato dal cliente tramite il Servizio TicketSms
                    che legittima il titolare del Titolo di Accesso ad accedere all'Evento.
                    <br />
                    <b>1.14. Titolo di Ingresso Digitale:</b> indica i Titoli di Ingresso erogati in modalità differente
                    dalla stampa.
                    <br />
                    <b>1.15. Punti Vendita TicketSms:</b> indica la rete di vendita affiliata a TicketSms.
                    <br />
                    <b>1.16. Sito:</b> indica il sito TicketSms.it, di proprietà e gestito da TicketSms al quale è
                    possibile accedere mediante qualsiasi browser di ricerca.
                    <br />
                    <b>1.17. App/Applicazione:</b> indica l’applicazione TicketSms, di proprietà e gestita da TicketSms
                    alla quale è possibile accedere mediante download da App Store e Google Play. Il termine “App” è da
                    considerarsi assimilabile al termine “Sito” di cui all’1.16.
                    <br />
                    <b>1.18. Commissione di Servizio:</b> è la maggiorazione applicata da TicketSms sul Prezzo Facciale
                    dei Titoli di Accesso e costituisce remunerazione del Servizio TicketSms. E' indicata al Cliente
                    durante la procedura di acquisto del Titolo di Accesso e viene fatturata direttamente da TicketSms
                    al Cliente.
                    <br />
                    <b>1.19. Diritto di Prevendita:</b> è l'eventuale maggiorazione applicata dall'Organizzatore nel
                    caso di prevendita del Titolo di Accesso. È parte integrante del Prezzo Facciale del Titolo di
                    Accesso acquistato dal Cliente ed è indicato sul Titolo di Accesso separatamente dal Valore Nominale
                    del Titolo di Accesso.
                    <br />
                    <b>1.20. Valore Nominale:</b> è il prezzo del Titolo di Accesso al netto di Diritto di Prevendita e
                    della Commissione di Servizio.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>2. AMBITO DI APPLICAZIONE</b>
                    <br />
                    <br />
                    <b>2.1.</b> L'offerta e la vendita sul Sito e/o App dei Titoli di Ingresso e, più in generale, i
                    Servizi offerti da TicketSms costituiscono un contratto a distanza disciplinato dal Capo I, Titolo
                    III (artt. 45 e ss.) del Codice del Consumo e dal Decreto Legislativo 9 aprile 2003, n. 70,
                    contenente la disciplina del commercio elettronico.
                    <br />
                    <b>2.2.</b> Le presenti CGC potranno essere soggette a modifiche. Le stesse si considereranno
                    entranti in vigore dal momento della loro pubblicazione sul Sito e/o App nella sezione “Termini e
                    condizioni”, presente nel footer di ogni pagina del Sito. Alla luce di quanto sopra gli utenti
                    interessati sono invitati ad accedere regolarmente al Sito e/o App e a consultare, prima di
                    effettuare qualsivoglia acquisto, l’ultima versione delle CGC.
                    <br />
                    <b>2.3.</b> Le CGC applicabili sono quelle in vigore alla data di trasmissione dell'ordine di
                    acquisto di un Titolo di Ingresso. Prima di procedere alla conclusione del Contratto, l'utente è
                    tenuto a leggere integralmente, esaminare ed approvare le presenti CGC.
                    <br />
                    <b>2.4.</b> Le presenti CGC non disciplinano la vendita di prodotti e/o servizi da parte di soggetti
                    terzi diversi da TicketSms che siano eventualmente presenti sul Sito tramite link, banner o altri
                    collegamenti ipertestuali. Prima di effettuare transazioni commerciali con tali soggetti è
                    necessario verificare le loro condizioni di vendita che in alcun modo sono da considerarsi
                    assimilabili a quelle proposte da TicketSms. TicketSms non è in alcun modo responsabile per la
                    fornitura di servizi e/o per la vendita di prodotti da parte di tali soggetti terzi. Sui siti web
                    consultabili tramite click su tali collegamenti, TicketSms non effettua alcun controllo e/o
                    monitoraggio. TicketSms declina pertanto ogni responsabilità verso i contenuti di tali siti e verso
                    eventuali errori e/o omissioni e/o violazioni di legge da parte degli stessi.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>3. OGGETTO DEL SERVIZIO TICKETSMS</b>
                    <br />
                    <br />
                    Le presenti Condizioni Generali di Contratto disciplinano la fornitura e l'utilizzo del Servizio
                    TicketSms e, dunque, la fornitura al Cliente del servizio di vendita e rivendita del Titolo di
                    Accesso relativo all'Evento organizzato e gestito dall'Organizzatore.
                    <br />
                    <b>3.1.</b> Nell'ambito del Contratto di Vendita TicketSms agisce in nome e per conto
                    dell'Organizzatore fornendo esclusivamente il Servizio TicketSms, per cui non è ad essa imputabile
                    l'organizzazione, la gestione o l’esecuzione dell’Evento, né tanto meno la politica dei prezzi dei
                    Titoli di Accesso, della loro promozione e della distribuzione.
                    <br />
                    <b>3.2.</b> Il Servizio TicketSms fornito da TicketSms è un servizio di sola prenotazione, vendita e
                    rivendita di Titoli di Accesso. TicketSms, nella conclusione del Contratto di Vendita, così come
                    nell'attività di comunicazione relativa all'Evento, opera in nome e per conto dell'Organizzatore e
                    l'unico servizio dalla stessa prestata è quello di fornitura del Servizio TicketSms.
                    <br />
                    <b>3.3.</b> TicketSms si riserva il diritto di ottenere, riprodurre e/o pubblicare sull'App, sul
                    sito web, sui canali di social network e su altri materiali promozionali fotografie, audio o video
                    di tutti gli eventi per i quali TicketSms mette a disposizione il proprio servizio. Accedendo
                    all’evento, il Cliente acconsente all’utilizzo della propria immagine secondo le modalità sopra
                    indicate. Per ulteriori informazioni relative al trattamento dell’immagine e dei dati personali si
                    invita l’utente a consultare la nostra Privacy Policy.
                    <br />
                    <b>3.4.</b> Dal momento che il Servizio TicketSms non attiene in alcuna maniera e/o misura
                    all’organizzazione e/o alla gestione dell’Evento, TicketSms non potrà in alcun modo essere ritenuta
                    responsabile né per le modalità di gestione dello stesso, né per eventuali conseguenze
                    pregiudizievoli patite dal Cliente in occasione dello svolgimento dell’Evento stesso, ovvero in
                    ragione di possibili cause che possano in qualsiasi maniera impedire, ritardare o rovinare la
                    fruizione dell’Evento. Del pari, TicketSms non potrà in alcuna maniera essere ritenuta responsabile
                    per i contenuti dell’Evento, sui quali non ha alcun potere di influire.
                  </div>

                  <br />

                  <div>
                    <b>4. ACQUISTI SUL SITO</b>
                    <br />
                    <br />
                    <b>4.1.</b> L’acquisto dei Titoli di Ingresso sul Sito può avvenire solo previa registrazione al
                    Sito con le modalità di cui all’art. 5 che segue ed è consentito sia ad utenti che rivestano la
                    qualità di Consumatori sia ad utenti che non rivestano tale qualità. È consentito l’acquisto di un
                    numero massimo di 10 Titoli di Ingresso per transazione secondo quanto previsto dalla normativa
                    SIAE. Si riserva di limitare il numero di Titoli acquistabili per transazione, secondo quanto
                    eventualmente disposto dall’Organizzatore dell’Evento. Il Cliente che acquisti Titoli di Ingresso
                    multipli anche per conto di altri individui, mediante la stessa transazione avrà la possibilità di
                    visionarli tutti nella sezione “Transazioni” presente nella sua area personale sul Sito. Gli altri
                    individui a cui il Titolo è stato recapitato mediante inserimento, da parte dell’acquirente, del
                    proprio numero di telefono potranno visualizzare solo il titolo a loro riconducibile nel “Wallet”
                    sull’App o nella sezione “Biglietti” sul Sito sempre accedendo alla propria area personale.
                    <br />
                    <b>4.2.</b> L'utente è tenuto a verificare i dettagli dell'Evento prima di acquistare un biglietto,
                    con particolare riferimento all’artista, alla data, all'ora, al luogo, al limite di età e a
                    qualsiasi altra restrizione o prescrizione. Ne consegue che l’Utente non avrà diritto ad alcun
                    rimborso qualora eventuali restrizioni impediscano la sua partecipazione.
                    <br />
                    <b>4.3.</b> Con il metodo di pagamento 18App è possibile acquistare solo i Titoli di Ingresso
                    convenzionati ove è presente la dicitura 18App relativi ad un evento.
                    <br />
                    <b>4.4.</b> Il Cliente è tenuto a compilare integralmente tutti i campi necessari per la conclusione
                    della transazione in modo tale da ricevere correttamente il Titolo di Ingresso.
                    <br />
                    <b>4.5.</b> TicketSms si riserva il diritto di rifiutare o cancellare ordini che provengano a) da un
                    utente con cui essa abbia in corso un contenzioso legale; b) da un utente che abbia in precedenza
                    violato le presenti CGC e/o le condizioni e/o i termini del Contratto; c) da un utente che sia stato
                    coinvolto in frodi di qualsiasi tipo e, in particolare, in frodi relative a pagamenti con carta di
                    credito; d) da utenti che abbiano rilasciato dati identificativi falsi, inventati, di fantasia,
                    incompleti o comunque inesatti e/o in qualsiasi modo non corrispondenti al vero o riferiti a terze
                    persone ovvero che non abbiano inviato tempestivamente a TicketSms i documenti dalla stessa
                    richiesti nell'ambito della procedura di cui agli artt. 11.2 e 11.3 che seguono o che le abbiano
                    inviato documenti non validi; e) da un utente che abbia violato il divieto di cui all’art. 5.2
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>5. REGISTRAZIONE AL SITO</b>
                    <br />
                    <br />
                    <b>5.1.</b> Per registrarsi al Sito e creare il relativo account l’utente deve compilare l’apposito
                    modulo inserendo i propri dati personali richiesti oltre ad un indirizzo e-mail valido e ad un
                    numero di telefono mobile esistente. Altresì, si ravvisa la possibilità di accedere al sito mediante
                    account Facebook, Gmail e ID Apple. È assolutamente e severamente vietato all’utente l’inserimento,
                    ai fini della registrazione al Sito, di dati personali di terze persone, di dati falsi, inventati,
                    di fantasia e/o, in qualsiasi modo, non corrispondenti al vero; è assolutamente vietato creare
                    account per conto di terzi individui.
                    <br />
                    <b>5.2.</b> Ciascun utente può usufruire di una sola registrazione al Sito. È, pertanto,
                    assolutamente e severamente vietata la creazione di più account riferibili alla stessa persona,
                    fisica e/o giuridica, e/o a società e/o enti di qualsiasi tipo, anche mediante l’utilizzo di dati
                    corrispondenti al vero. Nel caso di violazione di tale divieto, TicketSms si riserva la facoltà di
                    chiudere tutti gli account riferibili alla stessa persona, fisica e/o giuridica, e/o alla stessa
                    società e/o allo stesso ente. L’utente si impegna inoltre a tenere TicketSms indenne e manlevata da
                    qualsiasi danno, obbligo risarcitorio e/o sanzione derivante da e/o in qualsiasi modo collegata alla
                    violazione da parte dell’utente del divieto di cui al presente articolo 5.2.
                    <br />
                    <b>5.3.</b> La registrazione al Sito consente all’utente di effettuare le seguenti attività: a)
                    verificare lo stato delle transazioni più recenti e visualizzare l’archivio di tutti gli ordini
                    effettuati; b) gestire i propri dati personali e modificarne alcuni in ogni momento; c) modificare
                    la propria password; d) usufruire dei servizi dedicati che possono essere di volta in volta attivati
                    da TicketSms (acquisto, rivendita e cambio nominativo dei Titoli di Ingresso).
                    <br />
                    <b>5.4.</b> Tra i vari metodi di accesso, le credenziali permettono all’utente di effettuare
                    acquisti sul Sito e di svolgere, tra le altre, le attività di cui all’art. 5.3 che precede. Esse
                    devono, pertanto, essere conservate con estrema cura e attenzione. Esse, inoltre, possono essere
                    utilizzate solo dall’utente e non possono essere cedute a terzi. L’utente si impegna a mantenerle
                    segrete e ad assicurarsi che nessun terzo vi abbia accesso. Egli si impegna, inoltre, a informare
                    immediatamente TicketSms, contattandola ai recapiti di cui all’art. 21, nel caso in cui sospetti o
                    venga a conoscenza di un uso indebito o di una indebita divulgazione delle stesse.
                    <br />
                    <b>5.5.</b> Durante la registrazione al Sito l’utente valida il proprio numero di telefono mediante
                    il codice di validazione One Time Password (OTP) trasmessogli tramite SMS. La validazione del numero
                    di telefono è prevista dalla Legge n. 145 del 20 dicembre 2018, modificante l’art. 1, comma 545 ss.
                    della Legge 232/2016 (Legge di Bilancio 2017), la quale richiede che l’utente che acquista titoli di
                    ingresso tramite canali online su siti ufficiali di biglietteria sia identificato tramite convalida
                    OTP.
                    <br />
                    <b>5.6.</b> L’utente garantisce che i dati personali forniti a TicketSms, durante la procedura di
                    registrazione al Sito o, in qualsiasi altro momento e/o occasione della sua relazione con TicketSms,
                    sono completi, veritieri e riferiti all’utente stesso e si impegna a tenere TicketSms indenne e
                    manlevata da qualsiasi danno, obbligo risarcitorio e/o sanzione derivante da e/o in qualsiasi modo
                    collegata alla violazione da parte dell’utente della garanzia di cui al presente articolo e/o alla
                    violazione delle regole sulla registrazione al Sito e/o sulla conservazione delle credenziali di
                    registrazione.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>6. INFORMAZIONI DIRETTE ALLA CONCLUSIONE DEL CONTRATTO</b>
                    <br />
                    <br />
                    <b>6.1.</b> In conformità al Decreto Legislativo 9 aprile 2003, n. 70 recante disposizioni in
                    materia di commercio elettronico, TicketSms informa l’utente che: a) per concludere il contratto di
                    acquisto di un Titolo di Ingresso sul Sito, lo stesso dovrà compilare un modulo d’ordine in formato
                    elettronico e trasmetterlo a TicketSms, in via telematica, seguendo le istruzioni che compariranno
                    di volta in volta sul Sito; b) se previsto, l’invio del modulo d’ordine dovrà avvenire entro il
                    tempo massimo indicato sul sito; c) il contratto sarà da considerarsi concluso dal momento in cui
                    l’utente avrà completato la procedura di inserimento di tutti i dati nel modulo d’ordine e dopo aver
                    selezionato la voce di conferma dell’ordine e di contestuale esecuzione del pagamento, queste ultime
                    chiaramente individuate mediante la dicitura “Completa l’ordine e paga” e quando questo sarà
                    pervenuto al server di TicketSms; d)prima di procedere alla trasmissione del modulo d’ordine,
                    l’utente potrà individuare e correggere eventuali errori di inserimento dei dati seguendo le
                    istruzioni presenti sul Sito nelle diverse fasi dell’acquisto; e) una volta registrato il modulo
                    d’ordine, TicketSms invierà al Cliente, all’indirizzo di posta elettronica indicato, la conferma
                    dell’ordine contenente: - le informazioni essenziali relative al Titolo di Ingresso acquistato; -
                    l’indicazione dettagliata del prezzo, del mezzo di pagamento utilizzato, nonché le informazioni
                    relative ai contatti del Servizio Clienti cui l’utente potrà rivolgersi per richiedere assistenza
                    e/o presentare reclami. L’e-mail di conferma d’ordine costituisce la conferma di conclusione del
                    contratto su un mezzo durevole ai sensi dell’art. 51, comma 7, Codice del Consumo; il Cliente prende
                    atto e riconosce che, inviata tale e-mail, TicketSms ha assolto a tutti gli obblighi di
                    documentazione sulla stessa incombenti e che, pertanto, il Cliente risulterà vincolato dal Contratto
                    e sarà tenuto al pagamento dell’Importo Totale, a prescindere dalla ricezione della e-mail di
                    conferma d’ordine dipendente da soggetti terzi e/o fattori che sono al di fuori della sfera di
                    controllo e gestione di TicketSms (es. provider di posta elettronica di cui si serve l’utente); f)
                    il modulo d'ordine sarà archiviato nella banca dati di TicketSms in accordo con i termini di legge.
                    L’utente avrà la possibilità di accedere al modulo d'ordine e/o ai dati allo stesso relativi tramite
                    il proprio account personale.
                    <br />
                    <b>6.2.</b> Le lingue a disposizione degli utenti per la conclusione del contratto sono l’italiano e
                    l’inglese. Il Servizio Clienti è in grado di comunicare con gli utenti nelle medesime lingue.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>7. DISPONIBILITÀ DEI TITOLI D’INGRESSO</b>
                    <br />
                    <br />
                    In merito ai Titoli di Ingresso acquistabili tramite il Sito, TicketSms segnala e l’utente prende
                    atto e riconosce che, in relazione a determinati tipologie di evento, quali, per esempio, gli Eventi
                    musicali:
                    <br />
                    a) il pacchetto dei Titoli di Ingresso acquistabili tramite il servizio è interamente determinato
                    dall’Organizzatore che stabilisce il numero, il prezzo e la tipologia dei Titoli di Ingresso che
                    devono essere messi in vendita sul Sito;
                    <br />
                    b) l’allotment potrà subire delle ricariche in considerazione della ulteriore disponibilità dei
                    Titoli di Ingresso che l’Organizzatore provvederà a comunicare a TicketSms;
                    <br />
                    c) l’Organizzatore, a sua discrezione, potrà chiedere a TicketSms di offrire in vendita un
                    determinato numero di Titoli di Ingresso (della tipologia e al prezzo indicati dall’Organizzatore)
                    ad alcuni cluster di utenti predeterminati (es: iscritti al fan club o al sito ufficiale
                    dell’artista; possessori di una specifica carta di credito) in una data anticipata (es: un giorno
                    prima) o anche contemporaneamente rispetto alla regolare data di apertura delle vendite; la
                    circostanza potrà essere pubblicizzata dall’Organizzatore;
                    <br />
                    d) non saranno acquistabili i Titoli di Ingresso in corrispondenza del cui evento compaia la
                    dicitura “Soldout”;
                    <br />
                    e) una pluralità di utenti potrà effettuare l’acquisto della stessa categoria di Titoli di Ingresso
                    in relazione allo stesso evento (“Medesimo Titolo di Ingresso”) contemporaneamente;
                    <br />
                    f) la pressione del bottone “Acquista” genererà una richiesta al server di TicketSms (“Server”);
                    <br />
                    g) tutte le richieste al Server generate dalla pressione del bottone “Acquista” e relative al
                    medesimo Titolo di Ingresso saranno processate in ordine cronologico, in base al momento di arrivo
                    della richiesta al Server;
                    <br />
                    h) l’acquisto della categoria di Titoli di Ingresso selezionata dall’utente mediante pressione del
                    bottone “Acquista”, potrà avvenire solo se, nel momento in cui la richiesta generata dalla pressione
                    di tale bottone perverrà al Server, vi sia ancora disponibilità della categoria di Titoli di
                    Ingresso richiesta, tenuto conto delle azioni compiute dagli altri utenti la cui richiesta relativa
                    al medesimo Titolo di Ingresso è arrivata al Server in un momento precedente a quella dell’utente;
                    <br />
                    i) il fatto che, nell’ambito dello stesso allotment, una categoria di Titoli di Ingresso prima
                    visualizzata dall’utente come “Non disponibile” sia successivamente visualizzata dall’utente come
                    inseribile nel carrello, mediante pressione del relativo bottone, dipende dalle azioni degli utenti
                    che hanno messo il Medesimo Titolo di Ingresso nel carrello e non hanno finalizzato l’acquisto (es:
                    per abbandono volontario del carrello o scadenza del tempo massimo per finalizzare l’acquisto,
                    durante il quale il Titolo di Ingresso è riservato all’utente che lo ha inserito nel carrello, o
                    transazione non andata a buon fine); in questo caso, infatti, il sistema rimette in vendita il
                    Titolo di Ingresso per il quale il procedimento di acquisto non è stato completato, secondo le
                    regole sopra illustrate. TicketSms declina sin da ora ogni responsabilità per eventuali pregiudizi
                    e/o disagi patiti dall’ utente, ivi compresa l’impossibilità di acquistare il Titolo di Ingresso
                    desiderato, in ragione delle fattispecie compendiate al presente art. 7
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>8. INFORMAZIONI SUI TITOLI D’INGRESSO</b>
                    <br />
                    <br />
                    <b>8.1.</b> Le categorie di Titoli di Ingresso relative a ciascun Evento e/o a ciascuna data
                    dell’evento sono accuratamente descritte nella pagina dedicata all’Evento. Ove applicabile, la
                    selezione del posto, nell’ambito della categoria desiderata, può avvenire tramite la funzione
                    “Acquista tramite “Mappa”. In questo caso, l’utente visualizzerà la mappa del luogo dell’evento e
                    sceglierà egli stesso, direttamente, tra i posti indicati come disponibili sulla mappa, quelli che
                    intende acquistare. Lo specifico Titolo di Ingresso o gli specifici Titoli di Ingresso che l’utente
                    inserirà nel carrello ed eventualmente acquisterà tramite pressione dei relativi bottoni saranno
                    quindi quelli risultanti dai meccanismi sopra illustrati di assegnazione o selezione. Tutte le
                    informazioni sugli specifici Titoli di Ingresso assegnati e/o selezionati (es: settore; fila; numero
                    del posto) sono fornite all’utente subito dopo l’assegnazione e/o selezione. L’utente, prima della
                    conclusione del Contratto, può modificare i posti che gli sono stati assegnati procedendo alla
                    selezione di un nuovo Titolo corrispondente al posto desiderato.
                    <br />
                    <b>8.2.</b> TicketSms, non costituendo rete esclusiva di distribuzione né il soggetto gestore delle
                    decisioni di vendita relative ad un determinato evento, non è responsabile della tipologia dei posti
                    messi in vendita dall’Organizzatore e non può garantire che i Titoli di Ingresso acquistabili
                    attraverso il Sito rappresentino, per ciascuna tipologia, i migliori disponibili in assoluto al
                    momento dell’acquisto da parte del Cliente o che posti migliori non siano messi in vendita
                    dall’Organizzatore in momenti successivi. Del pari, TicketSms non risponde dell’effettiva
                    organizzazione dell’Evento e della concreta disponibilità, presso il luogo dell’Evento stesso, dei
                    posti numerati e/o ordinati come da ordine effettuato, declinando sin da ora ogni possibile
                    responsabilità per il caso in cui fosse consentito assistere allo spettacolo in piedi e/o senza
                    rispettare i posti a sedere, dovendosi al riguardo ribadire l’estraneità di TicketSms alla concreta
                    gestione dell’Evento.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>9. PREZZO DEI TITOLI D’INGRESSO, COMMISSIONI DI SERVIZIO, ONERI AGGIUNTIVI, IMPORTO TOTALE</b>
                    <br />
                    <br />
                    <b>9.1.</b> Il Prezzo dei Titoli di Ingresso, fissato dall’Organizzatore e gli eventuali diritti di
                    prevendita fissati e applicati dall’Organizzatore stesso (“Diritto di Prevendita”), sono quelli
                    indicati sul Sito in relazione a ciascuna categoria di Titoli di Ingresso nella pagina di ciascun
                    evento (“Prezzo dei Titoli di Ingresso” o “Prezzo”). Il Prezzo è anche riportato sul Titolo di
                    Ingresso. L’utente può conoscere la composizione del Prezzo nel riepilogo presente nella pagina a
                    cui accede. La separata indicazione del Prezzo e della Prevendita è anche presente nella e-mail di
                    conferma d’ordine. Il Prezzo dei Titoli di Ingresso non include le Commissioni di Servizio e gli
                    eventuali Oneri Aggiuntivi, meglio definiti agli artt. 9.3 e 9.4, che sono, tuttavia, specificamente
                    indicati anche nel riepilogo dell’ordine che l’utente visualizza prima di procedere alla conclusione
                    del Contratto (“Riepilogo”), unitamente all’importo totale che l’utente è tenuto a pagare in
                    relazione a uno specifico ordine e che è costituito dal Prezzo dei Titoli di Ingresso, dalle
                    Commissioni di Servizio e dagli eventuali Oneri Aggiuntivi (“Prezzo Totale”).
                    <br />
                    <b>9.2.</b> Il Prezzo dei Titoli di Ingresso fissato dall’Organizzatore e indicato sul Sito in
                    relazione a ciascuna categoria di Titoli di Ingresso nella pagina dedicata all’evento, è l’ultimo
                    prezzo comunicato a TicketSms dall’Organizzatore. L’Organizzatore si riserva il diritto di
                    modificare il Prezzo dei Titoli di Ingresso in ogni momento, fermo restando che nel caso di acquisto
                    tramite il Sito il prezzo che sarà addebitato all’utente sarà quello indicato sul Sito nella pagina
                    di ciascun evento e nel Riepilogo dell’Ordine e che non si terrà conto di eventuali variazioni (né
                    in aumento né in diminuzione) successive alla trasmissione dell’ordine. In particolare, l’eventuale
                    minor prezzo che successivamente all’acquisto da parte del Cliente venisse applicato
                    dall’Organizzatore a Titoli di Ingresso della medesima tipologia ovvero con riguardo a eventi da
                    tenersi in una particolare data, non darà in alcun modo al Cliente diritto al rimborso della
                    differenza.
                    <br />
                    <b>9.3.</b> TicketSms applica su ogni transazione di vendita e quale remunerazione dei Servizi
                    TicketSms delle Commissioni di Servizio. Le Commissioni di Servizio dovute in relazione a ciascun
                    Titolo di Ingresso e/o a ciascun ordine sono specificamente indicate durante il procedimento di
                    acquisto e, segnatamente, nel Carrello e nel Riepilogo dell’Ordine, unitamente all’Importo Totale.
                    Nel caso di conclusione del Contratto, esse sono, inoltre, indicate nella e-mail di conferma
                    d’ordine.
                    <br />
                    <b>9.4.</b> Al Prezzo dei Titoli di Ingresso e alle Commissioni di Servizio possono aggiungersi, a
                    seconda delle scelte effettuate dall’utente, eventuali spese accessorie. L’importo di ciascuno degli
                    Oneri Aggiuntivi, se applicabile, è specificamente indicato all’utente nel Carrello e nel Riepilogo
                    dell’Ordine, unitamente all’Importo Totale. Nel caso di conclusione del Contratto, esso è inoltre
                    indicato nella e-mail di conferma d’ordine.
                    <br />
                    <b>9.5.</b> Il Prezzo, le Commissioni di Servizio e gli Oneri Aggiuntivi sono espressi in Euro e
                    devono considerarsi comprensivi di IVA.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>10. ORDINI DI ACQUISTO E CASI DI RISOLUZIONE DI DIRITTO DEL CONTRATTO CON IL CLIENTE</b>
                    <br />
                    <br />
                    <b>10.1.</b> Il Contratto è potrà considerarsi risolto di diritto e senza necessità di formalità
                    alcuna in caso di mancato pagamento dell'importo totale dovuto. Tale situazione comporterà la
                    conseguente ed automatica cancellazione dell’ordine e la conseguente nuova disponibilità sul Sito
                    del Titolo di Ingresso oggetto dell’ordine cancellato, che potrà immediatamente essere acquistato da
                    terzi. Di ciò l’utente sarà immediatamente avvisato subito dopo la trasmissione dell’ordine tramite
                    il sito.
                    <br />
                    <b>10.2.</b> La proprietà dei Titoli di Ingresso sarà trasferita all’utente al momento del
                    pagamento. Si raccomanda al Cliente di non inviare e/o diffondere e/o condividere con chicchessia il
                    proprio Titolo di Ingresso o QR Code, onde evitarne un uso illegittimo da parte di terzi. Si precisa
                    che in caso di smarrimento del QR Code, lo stesso sarà recuperabile dal Cliente accedendo alla
                    propria area personale sul sito www.ticketsms.it. In caso di vendita non a distanza del Titolo di
                    Ingresso, il rischio di perdita o danneggiamento dei Titoli sarà trasferito all’utente quando lo
                    stesso entrerà materialmente in possesso dei Titoli di Ingresso. TicketSms, pertanto, si riserva il
                    diritto di non procedere al rimborso dei Titoli di Ingresso smarriti dal Cliente.
                    <br />
                    <b>10.3.</b> Essendo TicketSms una piattaforma dedicata alla vendita a distanza e non di biglietti
                    per conto di terzi certificata SIAE, non è autorizzata ad effettuare il cambio dei Titolo di
                    Ingresso per eventi diversi da quello per cui il Titolo originario è stato acquistato.
                    <br />
                    <b>10.4.</b> Per poter inviare un ordine relativo a un Titolo di Ingresso tramite il Sito, l’utente
                    deve leggere attentamente e approvare, mediante continuazione del processo di vendita ovvero
                    mediante il click del tasto “Acquista” le presenti CGC.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>11. MODALITÀ DI PAGAMENTO</b>
                    <br />
                    <br />
                    Il pagamento dei Titoli di Ingresso acquistati tramite il Sito può essere effettuato mediante le
                    modalità di pagamento indicate nei paragrafi che seguono e/o quelle ulteriori eventualmente indicate
                    sul Sito stesso. TicketSms si riserva di applicare, per motivi di sicurezza, limitazioni ed
                    esclusioni agli strumenti di pagamento utilizzabili dal Cliente per il pagamento dei Titoli di
                    Ingresso. Gli strumenti di pagamento non disponibili in relazione a uno specifico ordine e/o a uno
                    specifico Titolo di Ingresso non saranno automaticamente visualizzabili e/o selezionabili
                    dall’utente durante il procedimento di acquisto. In caso di risoluzione del Contratto di Vendita e
                    in ogni altro caso di rimborso, a qualsiasi titolo, l’importo del rimborso sarà accreditato
                    unicamente mediante lo stesso mezzo di pagamento utilizzato dal Cliente per la transazione iniziale;
                    solo nel caso in cui ciò non sia possibile, il rimborso sarà effettuato mediante bonifico bancario.
                    TicketSms chiederà al Cliente le coordinate bancarie necessarie per effettuare il rimborso.
                    <br />
                    <br />
                    <b>11.1.</b> Il pagamento dei Titoli di Ingresso acquistati sul Sito può essere effettuato mediante
                    carta di credito appartenente ai circuiti MasterCard, Visa e American Express. Vi è inoltre la
                    possibilità di acquistare i Titoli di Ingresso mediante i seguenti metodi di pagamento: PayPal,
                    Voucher, 18App, Scalapay, Satispay e Apple Pay. L’addebito dell’importo totale dovuto dall’utente a
                    TicketSms è effettuato al momento della trasmissione dell’ordine, previa selezione del tasto che
                    indica la conclusione del contratto e il perfezionamento del pagamento “Concludi l’ordine e paga”.
                    Si precisa che, laddove il Titolo di Ingresso venga acquistato mediante Voucher, quest'ultimo potrà
                    essere utilizzato solo ed esclusivamente per l’acquisto di Titoli di Ingresso di importo pari o
                    inferiore allo stesso e non potrà essere integrato con altri metodi di pagamento. Per ulteriori
                    informazioni si prega di consultare la sezione “Metodi di pagamento” delle nostre FAQ.
                    <br />
                    <b>11.2.</b> Al fine di garantire la sicurezza dei pagamenti effettuati sul Sito e di proteggere
                    dall’eventuale utilizzo fraudolento dei dati della carta di credito, è applicata la procedura
                    3D-Secure introdotta dalla direttiva europea 2366/2015 (SPD2). Più specificamente, la procedura
                    3D-Secure prevede che i dati del browser, i dati della carta di credito, i dati dell’indirizzo di
                    fatturazione e dell’indirizzo e-mail, così come l’importo della transazione e la valuta, siano
                    trasferiti all’istituto di credito emittente della carta di credito. Tale istituto elabora i dati
                    ricevuti per determinare il rischio di frode della transazione, tenendo in considerazione l’importo
                    e il risultato del calcolo. Eventualmente, a seconda di quanto emerso, l’istituto di credito potrà
                    richiedere al titolare della carta di credito un’autenticazione della transazione di pagamento, ad
                    esempio tramite un numero di autenticazione della transazione ovvero tramite App.
                    <br />
                    <b>11.3.</b> Al fine di garantire la sicurezza dei pagamenti effettuati sul Sito e prevenire
                    eventuali frodi, TicketSms si riserva il diritto di chiedere all’utente, tramite e-mail, di inviare,
                    tramite lo stesso mezzo, una copia fronte/retro della propria carta d’identità e nel caso in cui
                    l’intestatario dell’ordine sia diverso dall’intestatario della carta, della carta d’identità di
                    quest’ultimo. Il documento dovrà essere in corso di validità e risultare pienamente leggibile. Nella
                    e- mail di richiesta sarà specificato il termine improrogabile entro il quale il documento deve
                    pervenire a TicketSms. Tale termine non sarà, in ogni caso, superiore a 5 giorni lavorativi a
                    decorrere dal ricevimento della richiesta da parte dell’utente. In attesa del documento richiesto,
                    l’ordine sarà sospeso. L’utente è tenuto all’invio dei documenti richiesti nel termine indicato.
                    <br />
                    <b>11.4.</b> Nel caso in cui TicketSms non riceva la documentazione di cui al punto 11.3. nel
                    termine specificato nella e-mail di richiesta ovvero riceva documenti scaduti, gravemente
                    illeggibili o non più in corso di validità il contratto si intenderà risolto di diritto ai sensi e
                    per gli effetti dell’art. 1456 c.c. , qualora TicketSms comunichi per iscritto all’Utente di volersi
                    avvalere della presente Clausola risolutiva espressa. In tale eventualità l’ordine verrà cancellato,
                    salvo il diritto di TicketSms al risarcimento di qualunque danno in cui la stessa possa incorrere a
                    causa del comportamento non conforme dell’utente. La risoluzione automatica del contratto, di cui
                    l’utente sarà avvisato tramite e-mail, entro e non oltre 5 giorni lavorativi dalla scadenza del
                    termine per l’invio dei documenti richiesti da TicketSms, comporterà la cancellazione dell’ordine
                    con conseguente rimborso dell’Importo Totale, qualora corrisposto, mediante riaccredito sullo stesso
                    mezzo di pagamento utilizzato. Nel caso di tempestivo ricevimento da parte di TicketSms della valida
                    documentazione richiesta, i termini di consegna applicabili all’ordine decorreranno dalla data di
                    ricevimento di tale documentazione.
                    <br />
                    <b>11.5.</b> TicketSms utilizza un servizio di pagamento sicuro che prevede l’utilizzo del
                    protocollo di sicurezza SSL. I dati riservati della carta di credito (numero della carta di credito,
                    intestatario della carta di credito, data di scadenza, codice di sicurezza) sono criptati e così
                    trasmessi al gestore dei pagamenti. TicketSms non ha quindi mai accesso e non memorizza i dati della
                    carta di credito ad esclusiva eccezione del dato relativo all’intestatario della carta utilizzata
                    per il pagamento (si veda 11.3 e 11.4).
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>12. MODALITÀ DI EROGAZIONE DEI TITOLI DI INGRESSO</b>
                    <br />
                    <br />
                    L’erogazione dei Titoli di Ingresso acquistati sul Sito è effettuata alternativamente: a) tramite
                    invio di sms o con App tramite notifica push; b) tramite autonoma stampa del Titolo di Ingresso
                    (c.d. “Print at home”);
                    <b>12.1.</b> Consegna tramite invio di sms o con App tramite notifica push Durante la procedura
                    d’acquisto, al Cliente verrà richiesto l’inserimento del proprio nome, cognome e di un numero di
                    telefono al quale verrà recapitato il Titolo di Ingresso tramite sms. Nel caso in cui il Cliente
                    proceda all’acquisto di più Titoli per soggetti diversi, lo stesso dovrà inserire nome, cognome e
                    numero di telefono di tali soggetti cui sarà recapitato il titolo mediante sms. Si precisa che, in
                    questo caso, l’acquirente è tenuto ad assicurarsi il consenso all’inserimento dei dati personali dei
                    soggetti per cui effettua l’acquisto dei Titoli. TicketSms, infatti, non si assume alcuna
                    responsabilità in merito al trattamento di tali dati. In ogni momento, l’acquirente potrà verificare
                    l’ordine eseguito nella sua area personale. TicketSms non è responsabile per il mancato invio del
                    Titolo di Ingresso tramite sms laddove lo stesso non sia stato recapitato a causa dell’errato
                    inserimento del numero di telefono, proprio o di altri, da parte del Cliente al momento
                    dell’acquisto. Altresì, il Cliente potrà procedere all’acquisto del Titolo di Ingresso mediante App
                    attraverso il proprio smartphone. Riceverà il Titolo di Ingresso mediante notifica push direttamente
                    dopo l’avvenuto acquisto.
                    <br />
                    <b>12.2.</b> Consegna tramite autonoma stampa del Titolo di Ingresso (c.d. “Print at home”) L’utente
                    può stampare immediatamente i Titoli di Ingresso acquistati con l’ausilio di una regolare stampante,
                    in bianco e nero o a colori. Nel caso in cui l’utente, ove disponibile, voglia avvalersi di tale
                    modalità, dovrà adottare per la stampa dei Titoli di Ingresso tutte le specifiche tecniche indicate
                    sul Sito. Si ricorda all’utente che, in ogni caso, per poter usufruire della modalità di consegna
                    “Stampa”, deve servirsi di un dispositivo su cui sia installato ed aggiornato il programma “Adobe
                    Acrobat Reader” alla versione 7.0.8 o superiore e su cui siano state disinstallate eventuali
                    versioni precedenti dello stesso programma. Il Cliente può procedere alla stampa dei Titoli di
                    Ingresso subito dopo l’acquisto degli stessi ovvero successivamente, accedendo in qualsiasi momento,
                    con le proprie credenziali, al proprio account personale. Il sistema di controllo accessi
                    autorizzerà l’ingresso di 1 (uno) solo Titolo di Ingresso equivalente ad 1 (uno) unico codice QR
                    code. Eventuali fotocopie e/o frodi derivanti da un utilizzo improprio dello stesso saranno
                    perseguibili a norma di legge con conseguente esonero di responsabilità da parte di TicketSms e
                    dell’Organizzatore. In nessun caso l’Organizzatore consentirà l’accesso di eventuali Titoli di
                    Ingresso duplicati. In alternativa alla stampa, l’utente può scaricare il biglietto digitale sul
                    proprio smartphone. In questo caso, il Cliente può accedere ai Titoli di Ingresso subito dopo
                    l’acquisto degli stessi ovvero successivamente, accedendo, con le proprie credenziali, all’app di
                    TicketSms. Il sistema di controllo accessi autorizzerà l’ingresso di 1 (uno) solo Titolo di Ingresso
                    equivalente ad 1 (uno) unico codice QR code. Eventuali fotocopie e/o frodi derivanti da un utilizzo
                    improprio dello stesso saranno perseguibili a norma di legge con conseguente esonero di
                    responsabilità da parte di TicketSms e dell’Organizzatore. In nessun caso l’Organizzatore consentirà
                    l’accesso di eventuali Titoli di Ingresso duplicati.
                    <br />
                  </div>

                  <div>
                    <b>13. ESCLUSIONE DEL DIRITTO DI RECESSO</b>
                    <br />
                    <br />
                    <b>13.1.</b>TicketSms informa che ai sensi dell'art. 59, comma 1 lett. n) del Codice del Consumo,
                    alle transazioni effettuate per il tramite del Servizio TicketSms non è applicabile il diritto di
                    recesso di cui agli artt. 52 e ss. dello stesso Codice del Consumo. Il Consumatore, difatti, prende
                    atto che avendo il Contratto di Servizio TicketSms (1.3) ed il Contratto di Vendita (1.4) ad oggetto
                    dei prodotti e servizi relativi al tempo libero da fornirsi ad una data o ad un periodo di
                    esecuzione specifici, ai sensi dell’art. 59, lett. n) del Codice del Consumo, non si applica il
                    diritto di recesso. Si esplicita inoltre che non è previsto in alcun caso il rimborso da parte di
                    TicketSms, né del Prezzo Facciale dei Titoli di Accesso né delle eventuali Commissioni di Servizio
                    e/o del Diritto di Prevendita, nel caso di arbitraria rinuncia da parte del Cliente ad accedere
                    all'Evento.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>14. DIFFORMITÀ DEI TITOLI DI INGRESSO RICEVUTI RISPETTO A QUELLI ACQUISTATI</b>
                    <br />
                    <br />
                    <b>14.1.</b> Fermo restando quanto previsto negli articoli che precedono, in tutti i casi di
                    difformità dei Titoli di Ingresso rispetto a quelli acquistati, il Cliente potrà contattare
                    TicketSms ai recapiti di cui al punto 21 che segue. TicketSms darà riscontro alla richiesta al più
                    tardi entro 15 giorni lavorativi dal ricevimento della stessa.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>15. ACCESSO AGLI EVENTI, ALLONTANAMENTO E ANNULLAMENTO DI UN TITOLO EMESSO</b>
                    <br />
                    <br />
                    <b>15.1.</b> Per accedere al luogo dell’evento tutti i clienti dovranno essere muniti di un valido
                    titolo di ingresso. Ai clienti si applicano le previsioni di cui alle presenti CGC nonché le
                    previsioni applicabili all’evento.
                    <br />
                    <b>15.2.</b> Il Titolo di Ingresso costituisce legittimo titolo idoneo all’accesso esclusivamente
                    per l’evento per il quale è stato emesso, nel giorno e nell’ora ivi indicati. L’Organizzatore ha la
                    facoltà di rifiutare l’accesso al Luogo dell’Evento per rilevate irregolarità del Titolo di Ingresso
                    e di effettuare controlli sulla persona per motivi di sicurezza e nel rispetto delle vigenti norme
                    di legge anche mediante l’ausilio delle autorità preposte alla tutela dell’ordine e della pubblica
                    sicurezza. Altresì, TicketSms non si assume alcuna responsabilità nel caso in cui venga negato al
                    Cliente l’accesso ad un evento per motivi attinenti scelte autonome dell’Organizzatore. Nel caso dai
                    controlli effettuati risulti che il Titolo di Ingresso sia stato rubato, duplicato o ottenuto in
                    contrasto con le presenti CGC e/o con le vigenti norme di legge, il Portatore del Titolo di Ingresso
                    potrà vedersi negato l’accesso al luogo dell’evento da parte dell’Organizzatore ovvero potrà essere
                    obbligato ad abbandonarlo anche, nel caso in cui se ne manifestasse la necessità, mediante l’ausilio
                    delle autorità preposte alla tutela dell’ordine e della pubblica sicurezza.
                    <br />
                    <b>15.3.</b> TicketSms si riserva in ogni momento la facoltà di annullare, su richiesta
                    dell’Organizzatore e/o delle autorità preposte alla tutela dell’ordine e della sicurezza pubblica,
                    per motivi tecnici e/o organizzativi diversi da quelli di cui all’art. 16, un Titolo di Ingresso già
                    emesso o un ordine di acquisto relativo a un Titolo di Ingresso già effettuato. In tal caso il
                    Cliente avrà diritto al rimborso dell’importo totale pagato al netto del Diritto di Prevendita in
                    misura pari al 15%, delle Commissioni di Servizio e degli Oneri Aggiuntivi.
                    <br />
                    <b>15.4.</b> Si fa presente che, ai sensi della normativa vigente, gli spettacoli di grandi
                    dimensioni (da intendersi come quelli con più di 5000 spettatori) richiedono l’obbligo della
                    nominatività e del controllo dell’identità del titolare del biglietto al momento dell’ingresso;
                    pertanto, in questi casi, i biglietti acquistati tramite TicketSms saranno nominativi. Negli eventi
                    in questione, al momento dell’ingresso, sarà richiesto un documento d’identità valido per permettere
                    al personale preposto ai controlli di verificare che ad accedere con quello specifico titolo sia
                    proprio ed inequivocabilmente l’intestatario dello stesso. In caso di mancata corrispondenza tra il
                    nominativo e quanto emergente dall’identificazione del soggetto intento ad accedere all’evento tale
                    accesso sarà negato senza alcun diritto al rimborso. Sul Sito, all’interno della pagina evento sarà
                    indicato se l’Evento è soggetto a nominatività e se quindi sarà obbligatorio presentarsi allo stesso
                    con un documento in corso di validità e utile all’identificazione per potervi accedere.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>16. EVENTO RINVIATO O ANNULLATO</b>
                    <br />
                    <br />
                    <b>16.1.</b> TicketSms, quale mera fornitrice dei Servizi TicketSms, nonché soggetto che agisce in
                    nome e per conto dell’Organizzatore in relazione alla vendita dei Titoli di Ingresso, non è, in
                    alcun caso, responsabile dell’eventuale rinvio o annullamento dell’Evento da parte
                    dell’Organizzatore. In caso di rinvio o annullamento dell’Evento da parte dell’Organizzatore,
                    TicketSms potrebbe comunicare al Cliente, in nome e per conto dell’Organizzatore, all’indirizzo e-
                    mail inserito in fase di acquisto le iniziative adottate in relazione al rimborso o alla
                    sostituzione dei Titoli di Ingresso relativi all’Evento rinviato o annullato. In ogni caso, si
                    invita il Cliente a tenere costantemente monitorati tutti i canali di comunicazione dell’Organizzare
                    al fine di rimanere aggiornato su qualsiasi eventuale iniziativa e/o modifica dell’evento.
                    <br />
                    <b>16.2.</b> In caso di annullamento dell’evento, TicketSms provvederà all’effettuazione del
                    rimborso al Cliente riservandosi il diritto di trattenere, oltre alle commissioni di servizio, il
                    diritto di prevendita in misura pari e non superiore al 15%. In particolare, TicketSms provvederà a
                    fornire sul proprio sito e su tutte le piattaforme digitali di cui dispone le informazioni inerenti
                    alla procedura volta alla formulazione dell’istanza di rimborso; essa detterà l’unica modalità di
                    presentazione della richiesta. Affinché l’istanza sia accolta ed il rimborso erogato, la procedura
                    indicata, che si sostanzierà nella presentazione della richiesta su apposita piattaforma online,
                    dovrà essere eseguita correttamente dai consumatori entro le tempistiche di volta in volta
                    comunicate da TicketSms. Pertanto, si invita il Cliente a monitorare costantemente il sito e le
                    piattaforme digitali utilizzate da TicketSms al fine di rimanere aggiornato. Formulata l’istanza ed
                    attivata la procedura di rimborso, la stessa è da ritenersi irrevocabile. Laddove l’evento duri più
                    di un giorno e sia annullato solo in parte, il Cliente avrà diritto ad ottenere un rimborso parziale
                    corrispondente alla porzione di evento oggetto di annullamento. In ogni caso, stante l’estraneità di
                    TicketSms all’organizzazione e alla gestione dell’Evento, si comunica che TicketSms non avrà alcuna
                    responsabilità a fronte di eventuali annullamenti, spostamenti ritardi o cancellazioni degli Eventi
                    acquistati tramite il Sito.
                    <br />
                    <b>16.3.</b> In caso di rinvio dell’Evento, il Cliente avrà diritto ad ottenere il rimborso del
                    Titolo al netto delle commissioni di servizio e del diritto di prevendita in misura pari e non
                    superiore al 15%. Tale richiesta, ai fini dell’accoglimento da parte di TicketSms, dovrà essere
                    presentata dal Cliente secondo le modalità indicate da TicketSms entro e non oltre 48 ore (salvo
                    diversa comunicazione) dalla ricezione della comunicazione di rinvio. Richieste di rimborso tardive
                    non verranno accolte.
                    <br />
                    <b>16.4.</b> In caso di variazione sostanziale dell’Evento, il Cliente, entro e non oltre i termini
                    comunicati da TicketSms, potrà confermare il Titolo originario o, in alternativa, richiedere il
                    rimborso del Titolo al netto delle commissioni di servizio e del diritto di prevendita in misura
                    pari e non superiore al 15% secondo le modalità indicate da TicketSms. Decorso tale termine, laddove
                    il Cliente non abbia dato conferma del Titolo o presentato istanza di rimborso, il Titolo si intende
                    valido per l’evento variato sostanzialmente e nessuna richiesta potrà più essere avanzata a
                    TicketSms. Si precisa che per variazione sostanziale si intende una variazione che renda l’evento
                    materialmente differente da quello che si aspetterebbero gli acquirenti del biglietto (ad es.
                    sostituzione e/o mancata partecipazione all’evento dell’artista principale; trasferimento
                    dell’evento in un luogo distante oltre 100 km da quello prestabilito pur conservando la medesima
                    data ed il medesimo orario).
                    <br />
                    <b>16.5.</b> Il rimborso verrà effettuato con le seguenti modalità: mediante riaccredito su carta di
                    credito, mediante riaccredito con il metodo di pagamento utilizzato dal Cliente in fase di acquisto.
                    Il rimborso verrà erogato entro 30 giorni dalla richiesta effettuata dal Cliente.
                    <br />
                    <b>16.6.</b> I Titoli di Ingresso relativi agli Eventi annullati o rinviati potranno essere
                    permutati con voucher spendibili sul sito di TicketSms.
                    <br />
                    <b>16.7.</b> Nel caso in cui venga richiesto dal Cliente il rimborso di un Titolo di Ingresso
                    acquistato con 18App, lo stesso avverrà mediante il rilascio di un voucher della validità di 12 mesi
                    che sarà inviato al Cliente all’indirizzo e-mail indicato in fase di acquisto. Il Voucher sarà
                    spendibile soltanto sugli eventi 18App per l’acquisto di un solo Titolo di Ingresso per evento fino
                    ad esaurimento dell’importo.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>17. LIMITAZIONI DI RESPONSABILITÀ E DIRITTI DI TICKETSMS</b>
                    <br />
                    <br />
                    <b>17.1.</b> Eccetto che nei casi di comprovato dolo o colpa grave, TicketSms in alcun modo potrà
                    essere ritenuta responsabile per le spese e per i danni, diretti e indiretti, di qualsivoglia
                    natura, subiti dal Cliente in relazione alla vendita di Titoli di Ingresso. Altresì, TicketSms in
                    alcun modo potrà essere ritenuta responsabile di qualsivoglia problematica e/o incidente
                    verificatosi ed inerente la gestione dell’Evento.
                    <br />
                    <b>17.2.</b> TicketSms si riserva il diritto di ritirare, modificare, sospendere o interrompere
                    qualsivoglia funzione o servizio dalla stessa erogato in relazione alla vendita dei Titoli di
                    Ingresso, ove ciò sia necessario per ragioni tecniche e/o organizzative o per motivi di legge, fermo
                    restando l’adempimento di qualsivoglia Contratto eventualmente già concluso.
                    <br />
                    <b>17.3.</b> In generale, TicketSms mette a disposizione il Sito e fornisce il proprio Servizio “as
                    is”, vale a dire così come è e come è disponibile al momento della sua fruizione da parte
                    dell’utente o del Cliente, nel limite massimo consentito dalla legge applicabile: TicketSms non
                    assume alcuna responsabilità riguardo alla fruizione, all’accuratezza, alla completezza,
                    all’affidabilità, al grado di aggiornamento e/o di gradimento e/o alla disponibilità del Servizio e
                    non garantisce che verrà prestato senza interruzioni, con puntualità, in modo sicuro e/o senza
                    errori, né che eventuali errori verranno corretti. È parimenti esclusa qualsivoglia garanzia di
                    commerciabilità, idoneità per scopi particolari, o di qualità, del Sito e del Servizio offerto sul o
                    in relazione al Sito, fatta salva l’idoneità dei Titoli di Ingresso ai fini dell’accesso agli
                    Eventi; in aggiunta, TicketSms non garantisce che il Sito sia libero da virus o altri componenti
                    dannosi, né si assume alcuna responsabilità per: (a) eventuale cattivo funzionamento o guasto
                    dell'hardware, del software, della rete internet o di qualsiasi altro cattivo funzionamento o guasto
                    che può interessare il computer o le reti di comunicazione; (b) eventuali errori di stampa o
                    tipografici presenti sui materiali condivisi sul Sito. Occasionalmente possono essere presenti sul
                    Sito o nel servizio fornito informazioni contenenti errori tipografici, inesattezze e omissioni
                    riguardanti descrizioni degli Eventi, prezzi, promozioni, offerte o disponibilità degli Eventi.
                    TicketSms si riserva il diritto di correggere eventuali errori, inesattezze e omissioni modificando
                    e aggiornando le informazioni o annullando gli ordini, se qualsiasi informazione nel Servizio o su
                    un sito web correlato dovesse essere inaccurata, in ogni momento (anche dopo l'invio dell'ordine) e
                    senza alcun preavviso. TicketSms non si assume alcun obbligo di aggiornare, correggere o chiarire le
                    informazioni nel Servizio o in qualsiasi sito web correlato, incluse senza limitazioni le
                    informazioni sui prezzi, salvo quanto previsto dalla legge. Nessuna data di aggiornamento
                    specificata nel Servizio o in qualsiasi sito web correlato dovrà essere interpretata come garanzia
                    che tutte le informazioni nel Servizio o in qualsiasi sito web correlato siano state corrette e
                    aggiornate.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>18. DIVIETI E GARANZIE</b>
                    <br />
                    <br />
                    <b>18.1.</b> I Titoli di Ingresso non possono essere rivenduti a titolo oneroso nell’ambito dello
                    svolgimento professionale di attività commerciale ancorché non organizzata sotto forma di impresa
                    senza il consenso espresso dell’Organizzatore e comunque nel caso in cui l’Organizzatore ne vieti
                    specificamente il trasferimento di titolarità in base a principi di nominatività.
                    <br />
                    <b>18.2.</b> I Titoli di Ingresso, inoltre, non possono costituire il premio di manifestazioni a
                    premio, rientranti o non rientranti nell’ambito di applicazione del DPR 430/2001 né di altre
                    iniziative premiali o promozionali (quali, a titolo esemplificativo, competizioni o lotterie), salvo
                    il caso di preventiva autorizzazione scritta rilasciata dall’Organizzatore o da TicketSms, se a ciò
                    autorizzata dall’Organizzatore. Il Cliente dichiara che non utilizzerà i Titoli di Ingresso in
                    violazione delle presenti disposizioni e si obbliga a tenere TicketSms indenne e manlevata da
                    qualsiasi danno che alla stessa possa derivare dalla violazione delle presenti garanzie.
                    <br />
                    <b>18.3.</b> Agli utenti è fatto divieto di utilizzare il Sito o il suo contenuto: (a) per scopi
                    illegali; (b) per indurre altri a compiere o partecipare ad atti illeciti; (c) per violare leggi e
                    regolamenti internazionali, federali, provinciali o statali, o ordinanze locali; (d) per ledere o
                    violare i diritti di proprietà intellettuale nostri o di terzi; (e) per molestare, abusare,
                    insultare, danneggiare, diffamare, calunniare, denigrare, intimidire o discriminare qualcuno in base
                    a sesso, orientamento sessuale, religione, etnia, età, paese di origine o disabilità; (f) per
                    fornire informazioni false o fuorvianti; (g) per caricare o trasmettere virus o qualsiasi altro tipo
                    di codice dannoso idoneo a influire sulla funzionalità o sul funzionamento del Servizio, di
                    qualsiasi sito web correlato, di altri siti web o di internet; (h) per raccogliere o monitorare le
                    informazioni personali di altri utenti; (i) per spam, phishing, pharming, pretexting, uso di spider,
                    crawling o scraping; (j) per qualsiasi scopo osceno o immorale; (k) per intralciare o aggirare le
                    funzionalità di sicurezza del Servizio o di qualsiasi sito web correlato, di altri siti web o di
                    internet. se in caso di violazione di una qualsiasi delle disposizioni sugli usi proibiti di cui
                    sopra TicketSms si riserva dunque di inibire al’Utente l’utilizzo del Servizio o di qualsiasi sito
                    web correlato.
                    <br />
                    <b>18.4.</b> TicketSms avrà ampia facoltà, senza avere tuttavia alcun obbligo al riguardo, di
                    monitorare, modificare e rimuovere contenuti che dovesse a propria insindacabile discrezione
                    ritenere illeciti, offensivi, minacciosi, calunniosi, diffamatori, pornografici, osceni o altrimenti
                    discutibili, o contenuti che violino la proprietà intellettuale di qualsiasi parte o le presenti
                    condizioni. Gli Utenti si impegnano a evitare interazioni o commenti che ledano i diritti di terze
                    parti, tra cui copyright, marchi commerciali, diritto alla privacy, diritti della personalità e
                    altri diritti reali o personali. Inoltre, accettano di non effettuare interazioni e di non inviare
                    commenti che contengano materiale diffamatorio o altrimenti illegale, offensivo od osceno, oppure
                    virus informatici o altri malware che rischiano di compromettere il funzionamento del Servizio o di
                    qualsiasi sito web correlato. L’Utente è l'unico responsabile delle iniziative assunte e della loro
                    accuratezza, rimanendo espressamente escluso qualsivoglia obbligo in capo a TicketSms di controllare
                    preventivamente il tenore di eventuali iniziative e/o commenti pubblicati sul Sito dagli Utenti.
                    <br />
                    <b>18.5.</b> TicketSms declina ogni responsabilità per qualsiasi utilizzo che gli Utenti dovessero
                    fare dei materiali pubblicati sul Sito, che peraltro è da intendersi in ogni caso indebito. Ciascun
                    Utente sarà, nel caso, responsabile per l’utilizzo fatto di detti materiali, e sarà parimenti
                    l’unico destinatario di eventuali azioni legali, impegnandosi in ogni caso a tenere indenne
                    TicketSms o gli eventuali altri danneggiati per qualsiasi pregiudizio dovessero subire a causa della
                    condotta illecita.
                    <br />
                    <b>18.6.</b> Qualora un Visitatore dovesse ravvisare una violazione di qualsiasi tipo, derivante dai
                    materiali pubblicati sul Sito e/o dai servizi e dalle attività svolte da TicketSms per mezzo del
                    Sito stesso, avrà la possibilità di segnalare il ritenuto abuso inviando un’email all’indirizzo
                    segnalazioni@ticketsms.it A fronte di tale segnalazione TicketSms si impegna a valutare la
                    conformità dei contenuti segnalati rispetto ai propri standard e al proprio regolamento interno, e,
                    in caso di valutazione negativa, a rimuoverli nel minor tempo possibile, ferma la massima libertà e
                    discrezionalità nella valutazione della segnalazione e nella gestione dei tempi necessari a
                    effettuare l’istruttoria interna. In caso di segnalazione di lesione di altrui diritti d’autore,
                    ovvero di diritti di proprietà industriale o intellettuale o in ogni caso di diritti di qualsiasi
                    natura, chi provvederà a segnalare l’eventuale abuso dovrà fornire a TicketSms materiale idoneo a
                    consentire la verifica dell’asserita violazione. TicketSms si impegna in ogni caso a rimuovere senza
                    indugio i materiali contestati qualora ciò gli venisse richiesto dalle autorità competenti,
                    rimanendo inteso che in assenza di simili ordini l’eventuale rimozione avverrà unicamente a propria
                    insindacabile discrezione, all’esito della procedura di segnalazione e di istruttoria interna.
                    <br />
                    <b>18.7.</b> Accedendo al Sito gli Utenti accettano in ogni caso il rischio di trovare alcuni
                    contenuti che potrebbero non essere di loro gradimento o che potrebbero considerare offensivi o in
                    qualsiasi modo inadeguati. Qualora dovesse ricorrere una simile ipotesi TicketSms declina fin d’ora
                    qualsiasi responsabilità e, qualora dette immagini o materiali dovessero essere ritenute conformi
                    agli scopi e agli obiettivi di TicketSms, chiunque, al contrario, non li ritenga idonei, ovvero si
                    senta offeso o minacciato dagli stessi, è pregato di interrompere la navigazione sul Sito, oppure di
                    limitarsi a visualizzare solo quanto di proprio gradimento.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>19. RIVENDITA</b>
                    <br />
                    <br />
                    <b>19.1.</b> Qualora il Cliente sia impossibilitato a partecipare ad un evento, potrà essere
                    attivata la procedura di rivendita del biglietto di cui si è intestatari o dei biglietti che si sono
                    acquistati (anche di un singolo biglietto nel caso di acquisti multipli) accedendo all’apposita
                    sezione dal profilo utente o dal riepilogo delle transazioni. Per effettuare la rimessa in vendita
                    sul sito www.ticketsms.it sarà necessario indicare il Nome, il Cognome ed il Sigillo fiscale
                    presenti sul biglietto originale di cui si è acquirenti o intestatari (queste informazioni sono
                    indicate all'interno del biglietto stesso). Inoltre, sarà necessario indicare le proprie generalità
                    ed un IBAN sul quale verrà effettuato il bonifico in caso di avvenuta rivendita. Una volta
                    verificati i dati e il diritto a procedere, verrà visualizzato un riepilogo dell’offerta e sarà
                    chiesta un’ultima conferma dopo la quale il biglietto sarà rimesso in vendita allo stesso prezzo del
                    biglietto originale all’interno di TicketSms stesso. Quando un nuovo acquirente vorrà acquistare un
                    biglietto dello stesso genere di un biglietto rimesso in vendita verrà data priorità a quest’ultimo
                    rispetto ai biglietti dello stesso genere ancora a tale momento ancora invenduti. La rimessa in
                    vendita può essere effettuata anche tramite App TicketSms accedendo dal proprio profilo alla sezione
                    “Le tue transazioni” ove sarà possibile visionare l’elenco delle transazioni effettuate. Cliccando
                    sulla transazione scelta sarà possibile visualizzare la totalità dei biglietti acquistati e decidere
                    quali rimettere in vendita. Per effettuare la rimessa in vendita sarà necessario inserire Nome,
                    Cognome ed IBAN dell’intestatario del biglietto. Cliccando su “Gestione rivendita” sarà possibile
                    monitorare la rivendita e visualizzarne i dettagli. In caso la rivendita avvenga con successo, il
                    venditore riceverà notifica di annullamento del biglietto per rivendita, mediante SMS inviato al
                    numero di telefono associato al Titolo di Ingresso ed entro 15 giorni lavorativi un bonifico pari al
                    valore del singolo biglietto rivenduto al netto delle commissioni e del costo di servizio, che
                    ammonta al 10% del prezzo del biglietto, oltre ai diritti di prevendita. Si precisa, quindi, che in
                    caso di mancata rivendita del Titolo, nessun accredito verrà effettuato e il denaro pagato dal
                    Cliente non verrà restituito né riutilizzato per altri eventi messi in vendita sul Sito. Non è
                    prevista alcuna commissione o tassa di inserimento. La commissione viene applicata solo a rivendita
                    ultimata. La funzione di rivendita segue le stesse regole temporali della vendita classica fino ad
                    inizio evento; quindi, sarà disponibile per tutta la durata della messa in vendita dei biglietti
                    ordinari fino all'apertura dei cancelli dell'evento, quando non sarà più possibile rivendere i
                    propri biglietti o acquistare i biglietti rimessi in vendita da altri utenti. Si precisa che è a
                    discrezione del solo Organizzatore la facoltà di poter limitare temporalmente e/o bloccare la
                    rivendita dei Titoli di Ingresso.
                    <br />
                    <b>19.2.</b> Qualora si desiderasse cancellare la rimessa in vendita di un proprio biglietto basterà
                    accedere all'apposita sezione dal menu di rimessa in vendita del proprio profilo, dove sarà
                    necessario inserire nuovamente i dati relativi al biglietto (Nominativo e Sigillo fiscale). Tale
                    cancellazione potrà essere effettuata anche attraverso App TicketSms. La cancellazione sarà tuttavia
                    possibile solo fin quando il biglietto in questione non sarà inserito nel carrello di un nuovo
                    acquirente. Si noti che il titolo di accesso originale non potrà essere utilizzato finché resterà
                    nel mercato di rivendita, pertanto, anche in caso di mancata rivendita entro i tempi limite, per
                    poter utilizzare il proprio biglietto per accedere all'evento sarà necessario attuare la procedura
                    di cancellazione della rivendita. Per ulteriori informazioni circa la procedura di cancellazione
                    della rivendita si rinvia all’apposita sezione assistenza sul sito www.ticketsms.it. Si precisa che
                    non è possibile rivendere i biglietti acquistati utilizzando 18App né quelli non soggetti alla
                    legislazione italiana della SIAE.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>20. CAMBIO NOMINATIVO</b>
                    <br />
                    <br />
                    È concessa gratuitamente all’acquirente e/o intestatario la facoltà di poter cambiare il nominativo
                    dei Titoli di Ingresso acquistati sul sito di TicketSms per Eventi per cui è previsto l’obbligo
                    della nominatività.
                    <br />
                    <br />
                    <b>20.1.</b> L’acquirente e/o intestatario ha, altresì, la facoltà, laddove sia stato effettuato
                    l’acquisto di più di un Titolo di Ingresso, di poter cambiare il nominativo anche di uno solo fra
                    questi.
                    <br />
                    <b>20.2.</b> Per effettuare il cambio nominativo dal sito www.ticketsms.it sarà necessario indicare:
                    nome, cognome e sigillo fiscale presenti sul Titolo originale. Dopo la verifica dei dati inseriti e
                    del diritto a procedere, potrà essere inserito il nuovo nominativo ed il numero di telefono a cui
                    inviare il nuovo Titolo di Ingresso. Per effettuare il cambio nominativo tramite App TicketSms sarà
                    necessario accedere dal proprio profilo alla sezione “Le tue transazioni” ove sarà possibile
                    visionare l’elenco delle transazioni effettuate. Cliccando sulla transazione scelta sarà possibile
                    visualizzare la totalità dei biglietti acquistati e decidere di quali cambiare il nominativo. Per
                    effettuare il cambio nominativo sarà necessario inserire Nome, Cognome e numero di telefono del
                    nuovo intestatario del biglietto. Il Titolo di Ingresso contenente il nominativo originario sarà
                    annullato, non sarà più valido per l’ingresso e non potrà essere in alcun modo utilizzato.
                    <br />
                    <b>20.3.</b> È possibile modificare il nominativo di un Titolo di Ingresso per tutta la durata della
                    messa in vendita dei Titoli di Ingresso ordinari che è sempre a discrezione dell’Organizzatore. Si
                    precisa, altresì, che il cambio nominativo può essere effettuato 1 sola volta per ciascun Titolo di
                    Ingresso.
                    <br />
                    <b>20.4.</b> Si precisa, inoltre, che è a discrezione del solo Organizzatore dell’evento la facoltà
                    di limitare temporalmente, bloccare e stabilire il numero massimo di cambi nominativi da poter
                    effettuare.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>21. ASSISTENZA E RECLAMI</b>
                    <br />
                    <br />
                    Per qualsiasi osservazione o reclamo relativi al servizio TicketSms si prega di utilizzare il modulo
                    presente sul sito raggiungibile al link https://www.ticketsms.it/support/form o scrivere
                    all’indirizzo e-mail info@ticketsms.it. Il servizio di assistenza clienti è attivo dal lunedì al
                    venerdì dalle 10.00 alle 19.00 . L’obiettivo primario di TicketSms è quello di essere sempre vicini
                    al cliente, pertanto i feedback degli utenti sono sempre utili in quanto preziose opportunità di
                    confronto che consentono di offrire un servizio sempre più conforme alle esigenze dei clienti.
                  </div>

                  <br />

                  <div>
                    <b>22. LEGGE APPLICABILE E FORO COMPETENTE</b>
                    <br />
                    <br />
                    <b>22.1.</b> Il Contratto è soggetto alla legge italiana. Per qualsiasi controversia concernente gli
                    acquisti on-line effettuati da un Cliente in qualità di consumatore utilizzando il Servizio
                    TicketSms è competente il giudice del luogo di residenza o domicilio elettivo del consumatore stesso
                    se residente o domiciliato sul territorio italiano. È fatta salva l’applicazione agli utenti
                    Consumatori che non abbiano la loro residenza abituale in Italia delle disposizioni eventualmente
                    più favorevoli e inderogabili previste dalla legge del paese in cui essi hanno la loro residenza
                    abituale. In caso di Cliente professionista sarà competente in via esclusiva il Foro di Bologna.
                  </div>

                  <br />

                  <div>
                    <b>23. LEGGE APPLICABILE E FORO COMPETENTE</b>
                    <br />
                    <br />
                    <b>23.1.</b> Ai sensi dell’art. 141-sexies, III comma, Codice del Consumo, TicketSms informa
                    l’utente Consumatore che, nel caso in cui egli abbia presentato un reclamo a TicketSms, a seguito
                    del quale, tuttavia, non sia stato possibile trovare risoluzione alla controversia insorta,
                    TicketSms gli fornirà le informazioni in merito all’organismo o agli organismi di Alternative
                    Dispute Resolution per la risoluzione extragiudiziale delle controversie relative ad obbligazioni
                    derivanti da un contratto concluso in base alle presenti CGU (cc.dd. Organismi ADR, come indicati
                    agli artt. 141-bis e ss. del Codice del Consumo), precisando se intenda avvalersi o meno di tali
                    organismi per risolvere la controversia stessa.
                    <br />
                    <b>23.2.</b> Sono fatti salvi in ogni caso i diritti degli utenti Consumatori di adire il giudice
                    ordinario competente per la controversia derivante dalle presenti CGC o dal Contratto, qualunque sia
                    l’esito della procedura di composizione stragiudiziale nonché la possibilità, ove ne ricorrano i
                    presupposti, di promuovere una risoluzione extragiudiziale delle controversie relative ai rapporti
                    di consumo mediante il ricorso alle procedure di cui alla Parte V, Titolo II-bis del Codice del
                    Consumo.
                    <br />
                    <b>23.3.</b> In casi straordinari di emergenze epidemiologiche TicketSms adegua alle direttive di
                    legge i propri processi di acquisto, di accesso ai luoghi degli eventi e di rimborso, che dunque
                    potrebbero subire limitazioni e vincoli, seguendo sia la dinamica normativa pro tempore vigente
                    adottata dal Governo/Legislatore per fronteggiare tali situazioni straordinarie, sia le decisioni
                    assunte dagli Organizzatori sulla base delle stesse. Di tali continui adeguamenti TicketSms informa
                    i consumatori mediante informative aggiornate pubblicate sul Sito nelle apposite sezioni, nonché
                    alla pagina di vendita del singolo evento di volta in volta coinvolto.
                    <br />
                    <b>23.4.</b> Laddove un evento programmato non dovesse tenersi in ragione delle disposizioni
                    normative adottate dal Governo/Legislatore per fronteggiare casi straordinari di emergenza
                    epidemiologica, l’Organizzatore dello stesso, ai sensi dell’art. 88 del Decreto Legge n.18 del 17
                    marzo 2020 (‘Decreto Cura Italia’), come successivamente modificato, potrà scegliere, a seconda dei
                    casi, di rimborsare i Titoli di Ingresso acquistati per l’evento non tenuto mediante voucher,
                    provvedendo autonomamente alle operazioni di rimborso ovvero per il tramite di canali di vendita
                    delegando, dunque, TicketSms al compimento delle stesse. Si evidenzia, pertanto, che TicketSms è un
                    mero soggetto delegato, non il soggetto debitore del rimborso individuabile esclusivamente nel
                    soggetto Organizzatore dell’evento per il quale si chiede il rimborso.
                    <br />
                  </div>

                  <br />

                  <div>
                    <b>
                      <i>DICHIARAZIONE</i>
                    </b>
                    <br />
                    <br />
                    Il sottoscritto, in qualità di Cliente consumatore ai fini di un corretto e consapevole utilizzo del
                    servizio fornito da TicketSms, dichiara esplicitamente di aver preso attenta ed integrale visione
                    delle condizioni generali di contratto e di approvare espressamente le seguenti clausole, ai sensi e
                    per gli effetti di cui agli artt. 1341 e 1342 c.c.: 4.2. (Casi di mancato rimborso); 4.5.(Rifiuto o
                    cancellazione verso utenti con cui ha un contenzioso, che abbia compiuto frodi, che abbia già
                    precedentemente violato le suddette condizioni generali di contratto); 5.2. (creazione di account
                    plurimi riferibili alla stessa persona); 9.2. (Eventuali e successive variazioni di prezzo); 10.
                    (Ordini di acquisto e casi di risoluzione di diritto del contratto con il cliente); 11. (restrizioni
                    relative alle modalità di pagamento); 13. (esclusione del diritto di recesso); 15.3. (casi di
                    annullamento unilaterale dei biglietti acquistati); 17. (limitazioni di responsabilità) 18. (divieti
                    e garanzie).
                  </div>
                </p>
              </div>
            </div>
          </WithAppInitialized>
        </TransparentWrapper>
      </div>
    </>
  )
}

export default Terms
