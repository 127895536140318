import iban from 'iban'

import {
  getValidEmailRegex,
  getValidFiscalCodeRegex,
  getValidFiscalSealRegex,
  getValidNameRegex,
  getValidVoucherRegex,
} from '../../utils/regex'
import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldText extends IFormField<FormFieldType.text> {
  autoComplete?: boolean
  maxLength?: number
}

export class TextBuilder extends FormFieldBuilder<FormFieldType.text> {
  constructor(name: string, nameValidation?: string) {
    super(name, FormFieldType.text, nameValidation)
  }

  addAutoCompleteOff(): FormFieldBuilder<FormFieldType.text> {
    return super.addField('autoComplete', false)
  }

  addRequiredNameValidation(): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidNameRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }
    return super.addValidation(validation)
  }

  addRequiredFiscalCodeValidation(labelKey?: string): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: labelKey || `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidFiscalCodeRegex(), message: `form:errors_invalid_fiscalCode` },
    }
    return super.addValidation(validation)
  }

  addRequiredEmailValidation(): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidEmailRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }
    return super.addValidation(validation)
  }

  addRequiredIbanValidation(): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      validate: {
        message: `form:errors_invalid_${this.nameValidation}`,
        //@ts-ignore
        test: (value) => {
          return iban.isValid(value)
        },
      },
    }
    return super.addValidation(validation)
  }

  addRequiredFiscalSealValidation(): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidFiscalSealRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }
    return super.addValidation(validation)
  }

  addRequiredVoucherValidation(): FormFieldBuilder<FormFieldType.text> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidVoucherRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }
    return super.addValidation(validation)
  }
}
