import { AxiosResponse as AxiosResponseType } from 'axios'

// TODO: check if Manifest is needed
// import { Manifest } from '@core/manifest'

export class ResponseError extends Error {
  private readonly _exceptions?: Array<Record<string, string>>

  constructor(message: string, exceptions?: Array<Record<string, string>>) {
    super(message)
    this._exceptions = exceptions
  }

  get exceptions() {
    return this._exceptions
  }
}

export type BackendResponse<Data, Metadata = Record<never, never> | undefined> = {
  data: Data
  error?: string
  exceptions?: Array<Record<string, string>>
  metadata: Metadata
  status: number
}

export type AxiosResponse<Data, Metadata = Record<never, never> | undefined> = AxiosResponseType<
  // BackendResponse<Data, Metadata & Manifest>
  BackendResponse<Data, Metadata>
>
