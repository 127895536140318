import { AssetFormat, AssetType, ComponentType, IAsset, IAssetSource } from '../../structure'
import { IAssetBackend } from '../component/asset'
import ComponentAdapter from './componentAdapter'

export default class AssetAdapter extends ComponentAdapter<IAsset, IAssetBackend> {
  convert(): IAsset {
    const { format, src, type } = this.componentBackend

    return {
      componentType: ComponentType.asset,
      // @ts-ignore
      format: AssetFormat[format],
      src: this.componentsAdapter.convertComponents(src) as Array<IAssetSource>,
      // @ts-ignore
      type: AssetType[type],
    }
  }
}
