import css from './DayTime.module.scss'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IEventDetails } from 'meta/backendControlled/structure'
import { Dates } from 'utils/dates'

interface Props {
  eventDetails: IEventDetails
}

const DayTime: React.FC<Props> = (props) => {
  const { eventDetails } = props

  const { t } = useTranslation()

  const formattedDate = useMemo<string>(
    () => Dates.format(new Date(eventDetails.datetime), t('dates:common:eventDate')),
    [eventDetails.datetime, t]
  )
  const formattedTime = useMemo<string>(
    () => Dates.format(new Date(eventDetails.datetime), t('dates:common:eventTime')),
    [eventDetails.datetime, t]
  )

  return (
    <div className={css.checkout_eventdetails_description_daytime_container}>
      <div className={css.daytime}>
        <span className={css.day}>{formattedDate}</span>
        <span className={css.slash}>{` / `}</span>
        <span className={css.time}>{formattedTime}</span>
      </div>
    </div>
  )
}

export default DayTime
