import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppEnv } from 'meta/app'
import { AppController } from 'controller/app'
import { HttpRequest } from 'utils/http'

export const init = createAsyncThunk<{ env: AppEnv }, void>('app/init', async (_) => {
  const env = await AppController.getEnv()

  HttpRequest.initBaseUrl(env.BACKEND_API_V1_BASE)
  return { env }
})
