import css from 'components/Pages/ProfileTransactions/Transaction/Header/Header.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import Img from 'components/Media/Img'

export const HeaderSkeletonDesktop: React.FC = () => {
  return (
    <div className={classNames(css.auth_profile_sections_transactions_transaction_header_container)}>
      <div className={classNames(css.black_area)}>
        <div className={classNames(css.voice__container__skeleton)}>
          <div className={classNames(css.voice__wrapper__skeleton)}>
            <div className={classNames(css.voice__skeleton__left)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={16}
                height={'100%'}
                width={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
          <div className={classNames(css.voice__wrapper__skeleton)}>
            <div className={classNames(css.voice__skeleton__right)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={16}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={css.tip_container}>
        <Img src="/profile/transaction_tip_white.svg" />
      </div>

      <div className={css.white_area}></div>
    </div>
  )
}
