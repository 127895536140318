import { cancelProcess } from 'store/pages/checkout/actions/cancelProcess'
import { fetchRecap } from 'store/pages/checkout/actions/fetchRecap'
import { fetchSeasonEvents } from 'store/pages/checkout/actions/fetchSeasonEvents'
import { init } from 'store/pages/checkout/actions/init'
import { selectSeat, selectSeatForSeasonEvent } from 'store/pages/checkout/actions/selectSeat'
import { setTransactionErrorMessage } from 'store/pages/checkout/actions/setTransactionErrorMessage'
import { startProcess } from 'store/pages/checkout/actions/startProcess'
import { startTransaction } from 'store/pages/checkout/actions/startTransaction'
import { CheckoutSlice } from 'store/pages/checkout/slice'

export const CheckoutActions = {
  ...CheckoutSlice.actions,
  cancelProcess,
  fetchRecap,
  fetchSeasonEvents,
  init,
  selectSeat,
  selectSeatForSeasonEvent,
  setTransactionErrorMessage,
  startProcess,
  startTransaction,
}
