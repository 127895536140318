export function formatCurrency(amount: number): String {
  let s = new String(amount)
  s = s.replace(/\./g, ',')

  if (s.indexOf(',') < 0) {
    s += ',00'
  }
  if (s.indexOf(',') === s.length - 2) {
    s += '0'
  }
  return s
}
