import { Dates } from 'utils/dates'

import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldInputDate extends IFormField<FormFieldType.date> {}

export class InputDateBuilder extends FormFieldBuilder<FormFieldType.date> {
  constructor(name: string) {
    super(name, FormFieldType.date)
  }

  addRequiredBeforeNowValidation(labelKey?: string): FormFieldBuilder<FormFieldType.date> {
    const validation = {
      required: labelKey || `form:errors_required_birthDate`,
      validate: {
        message: `form:errors_invalid_birthDate`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        test: (value) => {
          let date: Date
          if (value instanceof Date) {
            date = value
          } else if (typeof value === 'number') {
            date = new Date(value)
          } else if (typeof value === 'string') {
            date = Dates.parseISO(value)
          } else {
            return false
          }
          return Dates.isValid(date) && Dates.isSameOrBefore(date, new Date())
        },
      },
    }
    return super.addValidation(validation)
  }

  addRequiredDateValidation(labelKey?: string): FormFieldBuilder<FormFieldType.date> {
    const validation = {
      required: labelKey || `form:errors_required_birthDate`,
      validate: {
        message: `form:errors_invalid_date`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        test: (value) => {
          let date: Date
          if (value instanceof Date) {
            date = value
          } else if (typeof value === 'number') {
            date = new Date(value)
          } else if (typeof value === 'string') {
            date = Dates.parseISO(value)
          } else {
            return false
          }
          return Dates.isValid(date)
        },
      },
    }
    return super.addValidation(validation)
  }
}
