import React from 'react'

import DataTestId from 'meta/dataTestId'

import Form from 'components/Forms/Form'
import { useLoginData } from 'hooks/useLogin'

import { IStepProps, Step } from '../StepCommon'

const Done: React.FC<IStepProps> = (props) => {
  const { onSuccess } = props
  const data = useLoginData()

  return (
    <>
      <Step icon="/login/step_done.png" labelKey="registerV2:registration_complete">
        <div data-testid={DataTestId.login.registrationComplete} style={{ display: 'none' }} />
        <Form
          action={undefined}
          formFields={[]}
          labelSubmit="registerV2:continue"
          onSuccess={() => onSuccess(data.otpToken)}
        />
      </Step>
    </>
  )
}

export default Done
