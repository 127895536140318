import css from './UsersPasswordReset.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import { ApiEndpoint } from 'controller/endpoint'

import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import PasswordResetForm from 'components/Pages/UsersPasswordReset/PasswordResetForm'
import RoundedBox from 'components/TextBoxes/RoundedBox'

const UsersPasswordReset: React.FC = () => {
  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()

  const [success, setSuccess] = useState<boolean>(false)

  return (
    <WithAppInitialized showChildren={namespacesLoaded}>
      {success ? (
        <RoundedBox bgColor={ComponentColor.black} infoType="success">
          {t('password:email_sent_successfully_msg')}
        </RoundedBox>
      ) : (
        <div className={css.passwordReset}>
          <Img className={css.passwordReset__emailImg} src="/login/email.svg" />
          <h4 className={css.passwordReset__text}>{t('password:insert_email_text')}</h4>
          <div className={css.passwordReset__text}>{t('password:email_verified_text')}</div>
          <div className={css.passwordReset__text}>{t('password:email_not_verified_text')}</div>

          <div className={css.formContainer}>
            <PasswordResetForm action={ApiEndpoint.users.passwordReset()} onSuccess={() => setSuccess(true)} />
          </div>
        </div>
      )}
    </WithAppInitialized>
  )
}

export default UsersPasswordReset
