import React from 'react'

import { ComponentType } from 'meta/backendControlled/structure'

import { IPropsComponent } from 'components/BackendControlled/Component/index'

import Artist from './Artist'
import Asset, { AssetSkeleton } from './Asset'
import Balloon from './Balloon'
import Banner from './Banner'
import Blog from './Blog'
import BlogPost from './BlogPost'
import Category, { CategorySkeleton } from './Category'
import City from './City'
import EventCollectionMenu from './EventCollectionMenu'
import EventDetails, { EventDetailsSkeleton } from './EventDetails'
import EventFAQ from './EventFAQ'
import EventInfo, { EventInfoSkeleton } from './EventInfo'
import EventTicket from './EventTicket'
import HorizontalScroll from './HorizontalScroll'
import InfoBox from './InfoBox'
import Map from './Map'
import Menu, { MenuSkeleton } from './Menu'
import ParentSquare from './ParentSquare'
import Square, { SquareSkeleton } from './Square'
import Story, { StorySkeleton } from './Story'
import VenueAttributes from './VenueAttributes'
import VenueDetails, { VenueDetailsSkeleton } from './VenueDetails'
import VenueInfo from './VenueInfo'
import VenueSquare from './VenueSquare'

// @ts-expect-error some stuff might be missing
const componentsMap: Record<ComponentType, React.FC<IPropsComponent<object>>> = {
  [ComponentType.artist]: Artist,
  [ComponentType.asset]: Asset,
  [ComponentType.assetSkeleton]: AssetSkeleton,
  [ComponentType.balloon]: Balloon,
  [ComponentType.banner]: Banner,
  [ComponentType.blog]: Blog,
  [ComponentType.blogPost]: BlogPost,
  [ComponentType.category]: Category,
  [ComponentType.categorySkeleton]: CategorySkeleton,
  [ComponentType.city]: City,
  [ComponentType.eventDetails]: EventDetails,
  [ComponentType.eventDetailsSkeleton]: EventDetailsSkeleton,
  [ComponentType.eventCollectionMenu]: EventCollectionMenu,
  [ComponentType.eventInfo]: EventInfo,
  [ComponentType.eventInfoSkeleton]: EventInfoSkeleton,
  [ComponentType.eventTicket]: EventTicket,
  [ComponentType.faq]: EventFAQ,
  [ComponentType.horizontalScroll]: HorizontalScroll,
  [ComponentType.infoBox]: InfoBox,
  [ComponentType.map]: Map,
  [ComponentType.menu]: Menu,
  [ComponentType.menuSkeleton]: MenuSkeleton,
  [ComponentType.parentSquare]: ParentSquare,
  [ComponentType.square]: Square,
  [ComponentType.squareSkeleton]: SquareSkeleton,
  [ComponentType.story]: Story,
  [ComponentType.storySkeleton]: StorySkeleton,
  [ComponentType.venueAttributes]: VenueAttributes,
  [ComponentType.venueDetails]: VenueDetails,
  [ComponentType.venueDetailsSkeleton]: VenueDetailsSkeleton,
  [ComponentType.venueInfo]: VenueInfo,
  [ComponentType.venueSquare]: VenueSquare,
}

export default componentsMap
