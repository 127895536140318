import css from '../SearchFilters.module.scss'
import React from 'react'

import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import { ActionColor, ButtonWhite } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useSearchEventCategories, useSearchParams } from 'hooks/useSearch'

interface IPropsFiltersCategories {
  closeFilters(): void
}

const FiltersCategories: React.FC<IPropsFiltersCategories> = (props) => {
  const { closeFilters } = props

  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()
  const eventCategories = useSearchEventCategories()

  return (
    <div className={css.searchFilterOptions}>
      {eventCategories &&
        eventCategories.map((eventCategory) => (
          <ButtonWhite
            key={eventCategory.uuid}
            className={css.searchFilter}
            hover={ActionColor.grey}
            onClick={() => {
              const params = {
                ...searchParams,
                eventCategory: searchParams?.eventCategory === eventCategory.uuid ? undefined : eventCategory.uuid,
              }
              dispatch(SearchActions.updateParams(params))
              closeFilters()
            }}
          >
            <div>
              <span>{eventCategory.name}</span>
            </div>
            <Img src="/icons/point_selection.svg" />
          </ButtonWhite>
        ))}
    </div>
  )
}

export default FiltersCategories
