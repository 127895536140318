import css from './VenueDetails.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor, IVenueDetails } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import LocationSocial from 'components/BackendControlled/Component/common/LocationSocial'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import VenueHead from 'components/BackendControlled/Component/VenueDetails/VenueHead'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const VenueDetails: React.FC<IPropsComponent<IVenueDetails>> = (props) => {
  const { address, identification, imageUrl, name, common, city } = props

  const { t } = useTranslation()
  const urlCloudfront = useUrlCloudfront()

  const cardImageUrl = imageUrl
    ? imageUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`
  const title = `${name} - ${common} - ${city} - TicketSms`
  const description = t('common:head_description_location', { name, city: common })

  return (
    <>
      <VenueHead description={description} name={identification!.name} title={title} />

      <Grid>
        <GridColumn
          gridColumnStart={{ [Breakpoints.mobile]: 2, [Breakpoints.tablet]: 12, [Breakpoints.laptop]: 13 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
          className={css.venueDetails}
        >
          <div className={css.venueDetails__content}>
            <ImgSkeleton alt={name} src={cardImageUrl} className={css.venueDetails__contentCardImg} />
            <h1 className={css.venueDetails__contentName}>{name}</h1>
            <div className={css.venueDetails__contentAddressContainer}>
              <Img src="/icons/location_marker.svg" />
              <div className={css.venueDetails__contentAddress}>{address}</div>
            </div>
            <LocationSocial color={ComponentColor.white} {...props} />
          </div>
        </GridColumn>
      </Grid>
    </>
  )
}

export default VenueDetails
