import css from '../PaymentMethods.module.scss'
import React, { useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import classNames from 'classnames'

import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ICheckoutPayment, PaymentMethod } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import PaymentMethodDialogError from 'components/Checkout/PaymentMethodDialogError'
import Img from 'components/Media/Img'
import { useCheckoutCartTickets, useCheckoutPayment, useCheckoutRecap } from 'hooks/useCheckout'

export interface IPropsPayment {
  toggleVoucherActive(): void
  toggleCreditCardActive(): void
}

export const CreditCard: React.FC<IPropsPayment> = (props) => {
  const { toggleCreditCardActive } = props

  const { t } = useTranslation()
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const isSelected = payment?.methodCurrent === PaymentMethod.creditCard

  return (
    <div
      className={`${css.box} ${css.cart} ${css.pos} ${isSelected && css.selected}`}
      onClick={() => {
        toggleCreditCardActive()

        // questo va fatto solo quando la carta di credito è corretta
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.creditCard } }))
      }}
      data-testid={DataTestId.checkout.creditCard}
    >
      <Img src="/checkout/checkout_credit_card_nospace.svg" />
      {t(String(`checkout:credit_cart`))}
    </div>
  )
}
export const PayPal: React.FC<IPropsPayment> = () => {
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const isSelected = payment?.methodCurrent === PaymentMethod.payPal

  return (
    <div
      className={`${css.box} ${css.paypal} ${isSelected && css.selected}`}
      onClick={() => {
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.payPal } }))
      }}
      data-testid="checkout-payment-paypal"
    >
      <Img src="/payment_methods/paypal.png" />
    </div>
  )
}

export const Voucher: React.FC<IPropsPayment> = (props) => {
  const { toggleVoucherActive } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const payment = useCheckoutPayment() as ICheckoutPayment
  const isSelected = payment?.methodCurrent === PaymentMethod.voucher

  // const { couponCode } = payment

  return (
    <div
      className={`${css.box} ${css.voucher} ${isSelected && css.selected}`}
      onClick={() => {
        toggleVoucherActive()
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.voucher } }))
      }}
    >
      <Img src="/payment_methods/voucher.svg" />
      <div className={css.text}>
        <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>{t(String(`checkout:voucher_mobile`))}</MediaQuery>
        <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>{t(String(`checkout:voucher`))}</MediaQuery>
      </div>
    </div>
  )
}
export const ScalaPay: React.FC<IPropsPayment> = () => {
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const isSelected = payment?.methodCurrent === PaymentMethod.scalaPay

  return (
    <div
      className={`${css.box} ${css.scalapay} ${isSelected && css.selected}`}
      onClick={() => {
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.scalaPay } }))
      }}
      data-testid="checkout-payment-scalapay"
    >
      <Img src="/payment_methods/scalapay.png" />
    </div>
  )
}
export const SatisPay: React.FC<IPropsPayment> = () => {
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const isSelected = payment?.methodCurrent === PaymentMethod.satisPay

  return (
    <div
      className={`${css.box} ${css.satispay} ${isSelected && css.selected}`}
      onClick={() => {
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.satisPay } }))
      }}
      data-testid="checkout-payment-satispay"
    >
      <Img src="/payment_methods/satispay.svg" />
    </div>
  )
}
export const DiciottoApp: React.FC<IPropsPayment> = (props) => {
  const { toggleVoucherActive } = props

  const { t } = useTranslation()
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const cartTickets = useCheckoutCartTickets()
  const [error, setError] = useState<boolean>(false)
  const isSelected = payment?.methodCurrent === PaymentMethod.diciottoApp

  return (
    <>
      <div
        className={`${css.box} ${css.diciottoapp} ${isSelected && css.selected}`}
        onClick={() => {
          const cartTotal = cartTickets?.reduce<number>((total, { quantity }) => total + quantity, 0) ?? 0
          if (cartTotal > 1) {
            setError(true)
          } else {
            toggleVoucherActive()
            dispatch(
              CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.diciottoApp } })
            )
          }
        }}
      >
        <Img src="/payment_methods/18app.svg" />
      </div>

      {error && (
        <PaymentMethodDialogError
          paymentLabel={t('checkout:diciotto_app')}
          messageKey="checkout:error_payment_method_only_one"
          onClose={() => setError(false)}
        />
      )}
    </>
  )
}
export const CartaMeritoCultura: React.FC<IPropsPayment> = (props) => {
  const { toggleVoucherActive } = props

  const { t } = useTranslation()
  const payment = useCheckoutPayment() as ICheckoutPayment
  const dispatch = useAppDispatch()
  const cartTickets = useCheckoutCartTickets()
  const [error, setError] = useState<boolean>(false)
  const isSelected = payment?.methodCurrent === PaymentMethod.cartaMeritoCultura

  return (
    <>
      <div
        className={`${css.box} ${css.cartaMeritoCultura} ${isSelected && css.selected}`}
        onClick={() => {
          const cartTotal = cartTickets?.reduce<number>((total, { quantity }) => total + quantity, 0) ?? 0
          if (cartTotal > 1) {
            setError(true)
          } else {
            toggleVoucherActive()
            dispatch(
              CheckoutActions.updatePayment({
                payment: { ...payment, methodCurrent: PaymentMethod.cartaMeritoCultura },
              })
            )
          }
        }}
      >
        <Img src="/payment_methods/cartaMeritoCultura.svg" />
      </div>

      {error && (
        <PaymentMethodDialogError
          paymentLabel={t('checkout:carta_merito_cultura')}
          messageKey="checkout:error_payment_method_only_one"
          onClose={() => setError(false)}
        />
      )}
    </>
  )
}
export const ApplePay: React.FC<IPropsPayment> = () => {
  const payment = useCheckoutPayment() as ICheckoutPayment
  const recap = useCheckoutRecap()
  const dispatch = useAppDispatch()
  const selected = payment?.methodCurrent === PaymentMethod.applePay

  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  useEffect(() => {
    if (stripe) {
      const currency = (recap?.total.currency === '€' ? 'eur' : recap?.total.currency.toLowerCase()) ?? ''
      const amount = (recap?.total.amount ?? 0) > 0 ? Math.ceil((recap?.total.amount ?? 0) * 100) : 1
      const pr = stripe.paymentRequest({
        country: 'IT',
        currency,
        total: {
          label: 'Checkout',
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result && result.applePay) {
          setPaymentRequest(pr)
        }
      })
    } else {
      setPaymentRequest(undefined)
    }
  }, [stripe, recap])

  useEffect(() => {
    dispatch(CheckoutActions.updateStripe({ stripe: { paymentRequest: selected ? paymentRequest : undefined } }))
  }, [selected, paymentRequest])

  if (!isSafari || !paymentRequest) return null

  return (
    <div
      className={classNames(css.box, css.applepay, { [css.selected]: selected })}
      onClick={() => {
        dispatch(CheckoutActions.updatePayment({ payment: { ...payment, methodCurrent: PaymentMethod.applePay } }))
      }}
    >
      <Img src="/payment_methods/apple_pay.svg" />
    </div>
  )
}
