import { MAX_SHORT_DESCRIPTION_LENGTH } from 'utils/constants/descriptions'

export const convertStringToSnakeCase = (str: string): string => {
  return str.toLowerCase().trim().replace(/\s+/g, '_')
}

export const detectIfJSON = (str: string): boolean => {
  let isJson = true

  try {
    JSON.parse(str)
  } catch (e) {
    isJson = false
  }

  return isJson
}

export const shortenNameIfLongerThan = (name: string, size: number) => {
  if (!name) {
    return ''
  }

  return name.length > size ? name.substr(0, size - 1) + '...' : name
}

export const addHttpsToUrlIfMissing = (url: string) => {
  if (url.includes('http')) {
    return url
  }

  return `https://${url}`
}

export const replaceAll = (target: string, searchValue: string, replaceValue: string): string =>
  target.replace(new RegExp(searchValue, 'g'), replaceValue)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getShortDescription = (description: string): any => {
  if (!detectIfJSON(description)) {
    return description
  }

  const descriptionObj = JSON.parse(description)
  const shortDescription = { ops: [] }
  let shortDescriptionLength = 0

  for (const elem of descriptionObj.ops) {
    if (!elem.insert || elem.insert.length + shortDescriptionLength < MAX_SHORT_DESCRIPTION_LENGTH) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      shortDescription.ops.push(elem)
      shortDescriptionLength += elem.insert.length
      continue
    }

    elem.insert = `${elem.insert.substring(0, MAX_SHORT_DESCRIPTION_LENGTH)}...`
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    shortDescription.ops.push(elem)
    return JSON.stringify(shortDescription)
  }

  return JSON.stringify(shortDescription)
}

export const convertDotsToCommas = (str: string) => {
  return str.replace('.', ',')
}

export const getObjectValueFromSubstringMatch = (substr: string, obj: object) => {
  let val

  Object.keys(obj).forEach((key) => {
    if (key.includes(substr)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      val = obj[key]
    }
  })

  return val
}
