import css from './PriceRange.module.scss'
import React from 'react'
import { Range } from 'react-input-range'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import Desktop from 'components/Checkout/TicketSelection/Filters/PriceRange/Desktop'
import Mobile from 'components/Checkout/TicketSelection/Filters/PriceRange/Mobile'

interface IProps {
  setPriceRangeCallback(range: any): void
  priceRangeInitial: Range
  priceRangeCurrent: Range
}

const PriceRange: React.FC<IProps> = (props) => {
  const { priceRangeInitial, priceRangeCurrent, setPriceRangeCallback } = props

  return (
    <div className={css.checkout_ticketselection_filters_pricerange_container}>
      <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
        <Mobile
          priceRangeCurrent={priceRangeCurrent}
          priceRangeInitial={priceRangeInitial}
          setPriceRangeCallback={setPriceRangeCallback}
        />
      </MediaQuery>

      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <Desktop
          priceRangeInitial={priceRangeInitial}
          priceRangeCurrent={priceRangeCurrent}
          setPriceRangeCallback={setPriceRangeCallback}
        />
      </MediaQuery>
    </div>
  )
}

export default PriceRange
