import { joinParts } from './utils'

export const checkout = {
  cancel: () => joinParts('checkout', 'cancel'),
  seasonDetails: (codeUrl: string, collectionId: string) =>
    joinParts('checkout', 'details', codeUrl, 'typeTicketCollections', collectionId),
  details: (codeUrl: string) => joinParts('checkout', 'details', codeUrl),
  recap: () => joinParts('checkout', 'recap'),
  prepare: () => joinParts('checkout', 'prepare'),
  process: () => joinParts('checkout', 'process'),
}
