import {
  AssetType,
  BackendControlledOGImage,
  CategoryShowMode,
  ComponentType,
  IAsset,
  IButton,
  IHorizontalScroll,
} from '../../structure'
import { IHorizontalScrollBackend } from '../component/horizontalScroll'
import ComponentAdapter from './componentAdapter'

export default class HorizontalScrollAdapter extends ComponentAdapter<IHorizontalScroll, IHorizontalScrollBackend> {
  convert(): IHorizontalScroll {
    const { button: buttonBackend, list, showMode, size, title, uuid } = this.componentBackend
    // @ts-ignore
    const button: IButton = buttonBackend
      ? {
          componentType: ComponentType.button,
          style: buttonBackend.style,
          title: buttonBackend.title,
          url: buttonBackend.url,
        }
      : null

    const horizontalScroll: IHorizontalScroll = {
      category: {
        button,
        componentType: ComponentType.category,
        data: this.componentsAdapter.convertComponents(list),
        showMode,
        size,
        title,
        uuid,
      },
      componentType: ComponentType.horizontalScroll,
    }

    this.parseMetadata(horizontalScroll)

    return horizontalScroll
  }

  parseMetadata(horizontalScroll: IHorizontalScroll): void {
    const { category } = horizontalScroll
    if (category.showMode === CategoryShowMode.gallery) {
      category.data.forEach((component) => {
        if (component.componentType === ComponentType.asset) {
          const asset = component as IAsset
          const url = asset?.src?.[0]?.url
          if (asset.type === AssetType.image && url) {
            const ogImage: BackendControlledOGImage = { url }
            this.componentsAdapter.metadata.og.images.push(ogImage)
          }
        }
      })
    }
  }
}
