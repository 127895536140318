import { ISearch } from 'meta/pages/search'

export type SearchState = ISearch

export const initialState: SearchState = {
  fetching: false,
  params: {
    query: '',
  },
}
