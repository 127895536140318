import css from 'components/Pages/PurchaseForm/Tickets/Ticket/Ticket.module.scss'
import React from 'react'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { GridColumn } from 'components/Layout/Grid'

import TicketFormFields from './TicketFormFields'
import TicketInfo from './TicketInfo'

interface IPropsTicket {
  idx: number
}

const TicketSkeleton: React.FC<IPropsTicket> = (props) => {
  const { idx } = props

  return (
    <GridColumn
      className={css.ticket}
      id={`${idx}_${idx}`}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
    >
      <BalloonWrapper color={ComponentColor.white} tipPosition={BalloonTipPosition.right} size={BalloonSize.small}>
        <div className={css.ticket__content}>
          <TicketInfo />
          <TicketFormFields />
        </div>
      </BalloonWrapper>
    </GridColumn>
  )
}

export default TicketSkeleton
