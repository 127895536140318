import { getValidTextAreaRegex } from '../../utils/regex.ts'
import { FormFieldType, IFormField } from './formField'
import { FormFieldBuilder } from './formFieldBuilder'

export interface IFormFieldTextArea extends IFormField<FormFieldType.textArea> {
  maxLength?: number
  rows?: number
}

export class TextAreaBuilder extends FormFieldBuilder<FormFieldType.textArea> {
  constructor(name: string) {
    super(name, FormFieldType.textArea)
  }

  addRows(rows: number): FormFieldBuilder<FormFieldType.textArea> {
    return super.addField('rows', rows)
  }

  addTextAreaValidation(required: boolean): FormFieldBuilder<FormFieldType.textArea> {
    const validation = {
      required: required ? 'tickets:errors_form_required_extra_field' : undefined,
      pattern: { value: getValidTextAreaRegex(), message: 'tickets:errors_form_invalid_extra_field' },
    }
    return super.addValidation(validation)
  }
}
