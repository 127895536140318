import React, { useState } from 'react'

import { CollectionAsset } from 'meta/pages/collection'

import Images from './Images'
import ProgressBar from './ProgressBar'

interface Props {
  images: CollectionAsset[]
}

const Gallery: React.FC<Props> = (props) => {
  const { images } = props

  const [visibleItem, setVisibleItem] = useState<number>(0)

  return (
    <>
      <Images setVisible={setVisibleItem} images={images} />
      <ProgressBar visible={visibleItem} total={images.length} />
    </>
  )
}

export default Gallery
