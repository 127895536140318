import { Numbers } from 'utils/numbers'

import { IMap, IMapSeat, IMapSection, IMapSectors, IMapSectorsFilter } from '../map'
import { Map2DAction, Map2DLevel, Map2DViewer, MapEvent, MapViewerSubscriber } from '../mapViewer'
import { mapStyles } from './style'

const wcEvents: Record<string, Array<string>> = {
  'eu-it-10012-reserved': [
    'S_Platea-14-279bis',
    'S_Platea-15-295bis',
    'S_Platea-16-311bis',
    'S_Platea-14-280bis',
    'S_Platea-15-296bis',
    'S_Platea-16-312bis',
  ],
  'eu-it-10014-reserved': ['S_TruffautD-C-32Bis', 'S_TruffautD-C-1Bis', 'S_TruffautD-V-31Bis', 'S_TruffautD-V-1Bis'],
}

export class DVMMap2DViewer implements Map2DViewer {
  private readonly callbacks: any
  private readonly subscriber: MapViewerSubscriber
  // @ts-ignore
  private container: HTMLDivElement
  // @ts-ignore
  private map: IMap
  viewer: any
  // @ts-ignore
  private level: Map2DLevel
  private readonly sectors: IMapSectors
  // @ts-ignore
  private sectionsEnabled: Array<IMapSection>

  constructor(callbacks: any, subscriber: MapViewerSubscriber, sectors?: IMapSectors) {
    this.callbacks = callbacks
    this.subscriber = subscriber
    // @ts-ignore
    this.sectors = sectors
  }

  async loadView(map: IMap, container: HTMLDivElement): Promise<void> {
    this.map = map
    this.container = container
    // @ts-ignore
    this.viewer = await DVM.loadModule('map_viewer', { container, callbacks: this.callbacks })
    await this.viewer.loadMap({ venue_id: map.mapId })
    this.viewer.setStyles(mapStyles(this.sectors))

    // options
    this.viewer.flags.automatic_selection = false
    this.viewer.flags.fixed_aspect_ratio = false
    this.viewer.max_selection = 10
    this.viewer.scaling_factor = 2
  }

  focusOn(id: string): void {
    this.viewer.focusOn(id)
  }

  selectSeat(seatId: string, sectionId?: string, disableNotification?: boolean): boolean {
    if (this.getSelectedSeats().length === 10) {
      alert('È possibile selezionare un massimo di 10 posti.')
      // @ts-ignore
      return
    }

    disableNotification && this.subscriber.disableNotification(MapEvent.SEAT_SELECT)
    if (sectionId) {
      this.focusOn(sectionId)
      this.subscriber.notify(MapEvent.SECTION_SELECT, { sectionId })
    }
    const selected: boolean = this.viewer.select(seatId)
    disableNotification && this.subscriber.enableNotification(MapEvent.SEAT_SELECT)
    return selected
  }

  selectRandomSeat(sectionId: string, typeTicketId?: number): void {
    const nodes = this.viewer.getNodesByState('seat', 'available', sectionId)
    const node = nodes[Numbers.getRandomInRange(0, nodes.length)]
    this.selectSeat(node.id, sectionId, true)

    const mapSeat: IMapSeat = { seatId: node.id, sectionId: node.parent }
    this.subscriber.notify(MapEvent.SEAT_SELECT, mapSeat, typeTicketId)
  }

  unselectSeat(seatId: string): void {
    this.viewer.unselect(seatId)
  }

  setAvailability(sectionsEnabled: Array<IMapSection>, seatsBusy: Array<string>): void {
    this.sectionsEnabled = sectionsEnabled

    const labels = sectionsEnabled.reduce<{ [sectionId: string]: { text: string } }>(
      (labelsAgg, section) => ({
        ...labelsAgg,
        [section.sectionId]: {
          font: `'Roboto', sans-serif`,
          size: 5,
          // @ts-ignore
          text: this.sectors ? this.sectors[section.sectorUuid].name : section.orderCodeDescription,
        },
      }),
      {}
    )
    this.viewer.setLabels(labels)

    const wcSeats: Array<string> = wcEvents[this.map.mapId]
    if (wcSeats) {
      this.viewer.setNodesTag(wcSeats, 'wc')
    }

    if (this.sectors) {
      sectionsEnabled.forEach((section) => {
        const { sectionId, sectorUuid } = section
        this.viewer.setNodesTag(sectionId, sectorUuid)
      })
    }

    const sectionIds = sectionsEnabled.map((section) => section.sectionId)
    this.viewer.setAvailability('section', sectionIds)

    sectionsEnabled.forEach(({ sectionId, sectorUuid }) => {
      const seats: Array<{ id: string }> = this.viewer.getNodesByParent(sectionId)
      const availableSeats = seats.filter((seat) => !seatsBusy.includes(seat.id))

      if (this.sectors) {
        this.viewer.setNodesTag(availableSeats, sectorUuid)
        if (wcSeats) {
          this.viewer.setNodesTag(wcSeats, `${sectorUuid}-wc`)
        }
      }

      this.viewer.setAvailability('seat', availableSeats, sectionId)
    })
  }

  filterSectors(sectorsFilter: IMapSectorsFilter): void {
    // @ts-ignore
    const selectedSeatIDs = this.getSelectedSeats().map((seat) => seat.id)
    if (Object.keys(sectorsFilter).length > 0) {
      Object.entries(this.sectors).forEach(([sectorUuid]) => {
        const sections = this.viewer.getNodesByTag('section', sectorUuid)
        if (!sectorsFilter[sectorUuid]) {
          this.viewer.setUnavailable('section', sections)
          const seats = this.viewer
            .getNodesByTag('seat', sectorUuid)
            // @ts-ignore
            .filter((seat) => !selectedSeatIDs.includes(seat.id))
          this.viewer.setUnavailable('seat', seats)
        } else {
          this.viewer.setAvailable('section', sections)
          // @ts-ignore
          sections.forEach((section) => {
            const seats = this.viewer
              .getNodesByTag('seat', sectorUuid, section.id)
              // @ts-ignore
              .filter((seat) => !selectedSeatIDs.includes(seat.id))

            this.viewer.setAvailable('seat', seats)
          })
        }
      })
    } else {
      const sectionIds = this.sectionsEnabled.map((section) => section.sectionId)
      this.viewer.setAvailability('section', sectionIds)
      Object.entries(this.sectors).forEach(([sectorUuid]) => {
        // @ts-ignore
        const seats = this.viewer.getNodesByTag('seat', sectorUuid).filter((seat) => !selectedSeatIDs.includes(seat.id))

        this.viewer.setAvailable('seat', seats)
      })
    }
  }

  resetZoom() {
    this.viewer.scaleBy(1 / this.viewer.scaling_factor)
    this.setLevel(Map2DLevel.SEAT)
  }

  setLevel(level: Map2DLevel): void {
    if (level !== this.level) {
      this.level = level
      if (this.level === Map2DLevel.SEAT) this.resetZoom()
      this.subscriber.notify(MapEvent.LAYER_LEVEL_CHANGE, this.level)
    }
  }

  bindAction(action: Map2DAction, element: HTMLElement) {
    if (action === Map2DAction.ZOOM_RESET) {
      element.addEventListener('click', this.resetZoom.bind(this))
    } else {
      this.viewer.bindInterfaceAction(element, action)
    }
  }

  hide(): void {
    this.container.style.display = 'none'
  }

  show(resetZoom?: boolean): void {
    this.container.style.display = 'block'
    resetZoom && this.resetZoom()
  }

  getSelectedSeats(): any {
    return this.viewer.getNodesByState('seat', 'selected')
  }
}
