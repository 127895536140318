import css from 'components/Pages/PurchaseForm/Tickets/Recap/ButtonSubmit/ButtonSubmit.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BeatLoader from 'react-spinners/BeatLoader'

import DataTestId from 'meta/dataTestId'
import { ITicket } from 'meta/pages/tickets'
import { TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { useFormContext } from 'components/Forms/Form'

interface IPropsButtonSubmit {
  tickets: Array<ITicket>
}

const ButtonSubmit: React.FC<IPropsButtonSubmit> = (props) => {
  const { tickets } = props

  const { t } = useTranslation()

  const { submitForm, isSubmitting } = useFormContext()

  const [sentAlready, setSentAlready] = useState<boolean>(false)

  const doSubmitForm = async () => {
    if (!sentAlready) {
      await submitForm()
      setSentAlready(true)
    }
  }

  useEffect(() => {
    if (sentAlready) {
      const timer = setTimeout(() => {
        setSentAlready(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [sentAlready])

  return (
    <button
      className={css.buttonSubmit}
      data-testid={DataTestId.purchaseForm.btnTicketsAssign}
      disabled={isSubmitting}
      onClick={submitForm}
    >
      <div className={css.buttonSubmit__container}>
        <div className={css.buttonSubmit__ticket} />
        <div className={css.buttonSubmit__text}>{t('tickets:tickets_to_send', { count: tickets.length })}</div>
        <div className={css.buttonSubmit__send}>
          {isSubmitting ? <BeatLoader color={TICKETSMS_COLORS_WHITE} size={6} /> : <h3>{t('tickets:send')}</h3>}
        </div>
      </div>
    </button>
  )
}

export default ButtonSubmit
