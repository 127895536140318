import css from './Wallet.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { IWalletTicketFilter } from 'meta/pages/wallet'
import { useAppDispatch } from 'store'
import { WalletActions } from 'store/pages/wallet'

import withTranslation from 'components/HOC/WithTranslation'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import WalletTicketDesktop, { WalletTicketDesktopSkeleton } from 'components/Pages/Wallet/WalletTicketDesktop'
import WalletTicketMobile, { WalletTicketMobileSkeleton } from 'components/Pages/Wallet/WalletTicketMobile'
import { useIsAppInitialized } from 'hooks/useApp'
import { useAuthToken } from 'hooks/useAuth'
import { useUrlCloudfront } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'
import { useWalletTickets } from 'hooks/useWallet'

const Wallet: React.FC = () => {
  const { t } = useTranslation()

  const appInitialized = useIsAppInitialized()
  const urlCloudfront = useUrlCloudfront()
  const authToken = useAuthToken()

  const [eventsType, setEventsType] = useState<IWalletTicketFilter>(IWalletTicketFilter.new)

  const tickets = useWalletTickets(eventsType)

  const [eventActive, setEventActive] = useState<number | null>(null)

  const lastScrollY = useRef<number>(0)
  const backgroundImage = `url(${urlCloudfront}/images/wallet/header_bg${
    eventsType === IWalletTicketFilter.new ? '' : '_inv'
  }.svg)`

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (appInitialized && authToken) {
      setEventActive(null)
      dispatch(WalletActions.getTickets({ eventsType: eventsType }))
    }
  }, [appInitialized, authToken, eventsType])

  useOnUpdate(() => {
    if (eventActive) {
      lastScrollY.current = window.scrollY
      window.scrollTo({ behavior: 'smooth', top: 0 })
    } else {
      window.scrollTo({ behavior: 'smooth', top: lastScrollY.current })
    }
  }, [eventActive])

  return (
    <div className={css.wallet}>
      <GridContainer className={css.wallet__typeContainer}>
        <Grid>
          <GridColumn
            gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.laptop]: 6 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.laptop]: 12 }}
            className={css.wallet__type}
            style={{ backgroundImage }}
          >
            <button
              className={css.wallet__btnType}
              disabled={eventsType === IWalletTicketFilter.new || !appInitialized}
              onClick={() => setEventsType(IWalletTicketFilter.new)}
            >
              <h3>{t('wallet:future_events')}</h3>
            </button>
            <button
              className={css.wallet__btnType}
              disabled={eventsType === IWalletTicketFilter.old || !appInitialized}
              onClick={() => setEventsType(IWalletTicketFilter.old)}
            >
              <h3>{t('wallet:past_events')}</h3>
            </button>
          </GridColumn>

          {!tickets && (
            <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.wallet__flexCenter}>
              <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
                <div className={css.walletTicket__skeleton__container}>
                  {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                    <WalletTicketDesktopSkeleton key={item} />
                  ))}
                </div>
              </MediaQuery>

              <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
                <div>
                  {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                    <WalletTicketMobileSkeleton key={item} />
                  ))}
                </div>
              </MediaQuery>
            </GridColumn>
          )}

          {tickets && tickets.length === 0 && (
            <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.wallet__flexCenter}>
              <div>{t('wallet:no_tickets_found')}</div>
            </GridColumn>
          )}
        </Grid>
      </GridContainer>

      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        {tickets && (
          <GridContainer className={css.wallet__tickets} style={{ height: eventActive ? 652 : 120 * tickets.length }}>
            {tickets.map((ticket) => (
              <WalletTicketMobile
                key={ticket.event.id}
                onClick={(eventId) => {
                  setEventActive((prevState) => (prevState === null ? eventId : null))
                }}
                //@ts-ignore
                eventActive={eventActive}
                walletTicket={ticket}
              />
            ))}
          </GridContainer>
        )}
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <GridContainer>
          <Grid className={css.wallet__tickets}>
            {tickets && tickets.map((ticket) => <WalletTicketDesktop key={ticket.event.id} walletTicket={ticket} />)}
          </Grid>
        </GridContainer>
      </MediaQuery>
    </div>
  )
}

const namespaces = ['wallet']

export default withTranslation(namespaces)(Wallet)
