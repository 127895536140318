import { useLayoutEffect, useState } from 'react'
import useCountdown from '@bradgarropy/use-countdown'
import { usePost } from 'api'

import { ApiEndpoint } from 'controller/endpoint'
import { OTP_RESEND_TIMEOUT_MILLISECONDS } from 'utils/constants/auth'

import { useOnUpdate } from 'hooks/useOnUpdate'

export const useResendOtp = (
  action: string,
  authToken: string,
  otpTokenInitial: string,
  phone: number,
  phonePrefix: string,
  S2SToken: string
) => {
  const { error, fetch, response } = usePost()
  const [otpToken, setOtpToken] = useState<string>(otpTokenInitial)

  const [canResend, setCanResend] = useState(false)

  const enableResend = () => {
    setTimeout(() => {
      setCanResend(true)
    }, OTP_RESEND_TIMEOUT_MILLISECONDS)
  }

  const { formatted: timeLeft, reset } = useCountdown({
    minutes: OTP_RESEND_TIMEOUT_MILLISECONDS / 60000,
    autoStart: true,
    format: 'mm:ss',
  })

  const resend = async () => {
    setCanResend(false)
    await fetch({
      authToken,
      urlResource: ApiEndpoint.users.otp(),
      body: { phone, phonePrefix, backend_route: action },
      extraHeaders: {
        'Tsms-S2S-Token': S2SToken,
      },
    })
    enableResend()
    reset()
  }

  useLayoutEffect(() => {
    enableResend()
  }, [])

  useOnUpdate(() => {
    response && setOtpToken(response.data.otpToken)
  }, [response])

  return {
    canResend,
    error,
    otpToken,
    resend,
    timeLeft,
  }
}
