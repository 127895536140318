import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ISearchCity, ISearchParams, ISearchResult } from 'meta/pages/search'
import { findNext } from 'store/pages/search/actions/findNext'
import { init } from 'store/pages/search/actions/init'
import { initialState, SearchState } from 'store/pages/search/state'

export const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetCities: (state: Draft<SearchState>) => {
      state.cities = undefined
    },
    setCities: (state: Draft<SearchState>, action: PayloadAction<{ cities: Array<ISearchCity> | undefined }>) => {
      const { cities } = action.payload
      state.cities = cities
    },
    updateParams: (state: Draft<SearchState>, action: PayloadAction<ISearchParams>) => {
      state.params = action.payload
    },
    updateResult: (state: Draft<SearchState>, action: PayloadAction<ISearchResult>) => {
      const { hasMorePages, total, components: payloadComponents } = action.payload
      const components =
        state.params.page === 1
          ? payloadComponents
          : [...(state.result?.components ?? []), ...(payloadComponents ?? [])]

      state.fetching = false
      state.params = { ...state.params, page: (state.params.page ?? 0) + 1 }
      state.result = { components, hasMorePages, total }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(init.pending, (state, action) => {
      state.fetching = true
      state.params = action.meta.arg
      state.result = undefined
    })
    builder.addCase(init.fulfilled, (state, action) => {
      if (action.payload) {
        state.eventCategories = action.payload
      }
    })
    builder.addCase(init.rejected, (state) => {
      state.fetching = false
    })
    builder.addCase(findNext.fulfilled, (state, action) => {
      if (action.payload) {
        state.fetching = true
      }
    })
  },
})
