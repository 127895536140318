import React from 'react'

import { IAsset } from 'meta/backendControlled/structure'

import { IPropsComponent } from '../../index'

const Video: React.FC<IPropsComponent<IAsset>> = (props) => {
  const { src } = props

  return (
    <video controls>
      {src
        .filter((source) => source.mimeType?.startsWith('video'))
        .map((source) => (
          <source key={source.uuid} src={source.url} type={source.mimeType} />
        ))}
    </video>
  )
}

export default Video
