import { IEventAttribute } from 'meta/pages/event'

import { IAssets } from './asset'
import { ComponentType, IComponent } from './component'

export enum VenueMapType {
  googleStatic = 'googleStatic',
  googleDynamic = 'googleDynamic',
  openStreetMap = 'openStreetMap',
}

export interface IVenueIdentification {
  id: number
  name: string
}

export interface IVenueSquare extends IComponent<ComponentType.venueSquare> {
  address: string
  city: string
  common: string
  id: number
  identification?: IVenueIdentification
  latitude: string
  imageUrl?: string
  longitude: string
  name: string
}

export interface IVenue<C extends ComponentType.venueInfo | ComponentType.venueDetails> extends IComponent<C> {
  asset: IAssets
  address: string
  attributes?: IEventAttribute[]
  captionData?: Array<string>
  city: string
  common: string
  description: string
  email?: string
  facebookLink?: string
  fullDescription?: string
  id: number
  identification?: IVenueIdentification
  imageUrl?: string
  instagramLink?: string
  latitude: string
  longitude: string
  mapsPlaceId: string
  name: string
  phone: string
  title: string
  website?: string
}

export interface IVenueInfo extends IVenue<ComponentType.venueInfo> {}

export interface IVenueDetails extends IVenue<ComponentType.venueDetails> {}
