import css from './Modal.module.scss'
import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from 'classnames'

import Img from 'components/Media/Img'

interface IProps extends RefAttributes<HTMLElement> {
  handleClose(event?: any): void
  show: boolean
  children: any
  grey?: boolean
  fullHeight?: boolean
  overflowY?: boolean
  hideCloseIcon?: boolean
  fullscreenOnMobile?: boolean
  transparent?: boolean
  dark?: boolean
}

const Modal: ForwardRefExoticComponent<IProps> = forwardRef<HTMLElement, IProps>((props, ref) => {
  const {
    show,
    children,
    grey,
    dark,
    fullHeight,
    transparent,
    overflowY,
    handleClose,
    fullscreenOnMobile,
    hideCloseIcon,
  } = props

  useEffect(() => {
    return () => document.body.classList.remove(css.modal_active)
  }, [])

  useEffect(() => {
    if (show) document.body.classList.add(css.modal_active)
    else document.body.classList.remove(css.modal_active)
  }, [show])

  return (
    <div className={css.layout_modal}>
      <div
        className={classNames(css.modal, {
          [css.show]: show,
          [css.hide]: !show,
          [css.dark]: dark,
          [css.full_height]: fullHeight,
        })}
      >
        <OutsideClickHandler onOutsideClick={handleClose}>
          <section
            className={classNames(css.modal_main, {
              [css.overflowY]: overflowY,
              [css.grey]: grey,
              [css.fullscreenOnMobile]: fullscreenOnMobile,
              [css.transparent]: transparent,
              [css.full_height]: fullHeight,
            })}
            ref={ref}
          >
            {!hideCloseIcon && (
              <div className={css.close} onClick={handleClose}>
                <Img src="/icons/close_icon.svg" />
              </div>
            )}

            {children}
          </section>
        </OutsideClickHandler>
      </div>
    </div>
  )
})

export default Modal
