import { FormFieldType } from 'meta/form'

import { ITicket, ITicketCovidAppointment, ITicketExtraField } from './types'

export class TicketAdapter {
  static convertResponse(items: Array<any>): Array<ITicket> {
    return items?.reduce<Array<ITicket>>((tickets, ticketResp: any) => {
      for (let i = 0; i < ticketResp.quantity; i++) {
        const ticket = this.convertTicket(ticketResp)
        tickets.push(ticket)
      }
      return tickets
    }, [])
  }

  private static convertTicket(ticketResp: any): ITicket {
    const ticket: ITicket = {
      details: ticketResp.ticket.webDetails,
      extraFields: ticketResp.additionalInfo.map(this.convertExtraField),
      appointments: ticketResp.appointments ? ticketResp.appointments.map(this.convertAppointment) : null,
      id: ticketResp.ticket.id,
      seatId: ticketResp?.seatId,
      title: ticketResp.ticket.notes,
      typeTicketId: ticketResp.ticket.typeTicket,
    }
    return ticket
  }

  //@ts-ignore
  private static convertAppointment(info): ITicketCovidAppointment {
    const appointment: ITicketCovidAppointment = {
      uuid: info.uuid,
      name: info.name,
      status: info.status,
      //@ts-ignore
      appointmentSlots: info.appointmentSlots.map((slot) => {
        return {
          uuid: slot.uuid,
          from: new Date(slot.from_tz),
          status: slot.status,
        }
      }),
    }

    return appointment
  }

  //@ts-ignore
  private static convertExtraField(info): ITicketExtraField {
    const extraField: ITicketExtraField = {
      dataType: info.dataType,
      fieldType: info.fieldType === 0 ? FormFieldType.text : FormFieldType.select,
      id: info.id,
      label: info.fieldName,
      metadata: info.metadata,
      name: info.fieldName.replace(/[^0-9a-z]/gi, ''),
      placeholder: info.fieldDescription,
      required: info.isRequired,
    }
    if (extraField.fieldType === FormFieldType.select) {
      const fieldValues = Array.isArray(info.fieldValues) ? info.fieldValues : JSON.parse(info.fieldValues)
      //@ts-ignore
      extraField.options = fieldValues.map((value) => ({ value, label: value }))
    }

    return extraField
  }
}
