import css from './WalletTicketDesktop.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGet } from 'api'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { IWalletSeasonTicket, IWalletTicket, IWalletUserTicket } from 'meta/pages/wallet'
import { ApiEndpoint } from 'controller/endpoint'
import { Dates } from 'utils/dates'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useAuthToken } from 'hooks/useAuth'
import { useUrlCloudfront } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

import UserTicket, { UserTicketSkeleton } from './UserTicket'

type Props = {
  walletTicket: IWalletTicket
}

const WalletTicketDesktop: React.FC<Props> = (props) => {
  const { walletTicket } = props
  const { t } = useTranslation()

  const { event, location, userTicketsNumber } = walletTicket

  const urlCloudfront = useUrlCloudfront()
  const [showTickets, setShowTickets] = useState<boolean>(false)

  const authToken = useAuthToken()

  const [userTickets, setUserTickets] = useState<Array<IWalletUserTicket & IWalletSeasonTicket>>([])
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const { fetch, response } = useGet()

  const backgroundImage =
    event.eventAssets.length > 0
      ? event.eventAssets[0].src[0].url
      : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  const date = new Date(event.dateEvent_tz)

  useEffect(() => {
    if (showTickets) {
      setShowLoader(true)

      fetch({
        authToken: authToken,
        urlResource: ApiEndpoint.users.walletEventData(event.codeUrl),
      })
    }
  }, [showTickets])

  useOnUpdate(() => {
    if (response) {
      setShowLoader(false)
      setUserTickets(response.data)
    }
  }, [response])

  return (
    <>
      <GridColumn
        gridColumnStart={{ [Breakpoints.laptop]: 2 }}
        gridColumnEnd={{ [Breakpoints.laptop]: 16 }}
        className={css.walletTicket}
      >
        <div className={css.walletTicket__img}>
          <img src={backgroundImage} />
          <div className={css.walletTicket__ticketsCount}>
            <Img src={`/wallet/tickets_${userTicketsNumber >= 3 ? 3 : userTicketsNumber}.svg`} />
            <h5 className={classNames(css.count, { [css.multiple]: userTicketsNumber >= 2 })}>{userTicketsNumber}</h5>
          </div>
        </div>

        <div className={css.walletTicket__details}>
          {!event.isEventCollection && (
            <div className={css.date}>
              <h4>{Dates.format(date, t('dates:wallet:date'))}</h4>
              <h4 className={css.fontNormal}>&nbsp;/&nbsp;</h4>
              <h4 className={css.fontNormal}>{Dates.format(date, t('dates:common:hoursMinutes'))}</h4>
            </div>
          )}

          {event.isEventCollection && (
            <div className={css.walletTicket__eventCollection}>
              <span className={css.icon}>
                <Img src="/icons/checkout-season-header-icon.svg" />
              </span>
              <span className={css.text}>{t('wallet:seasonEvent')}</span>
            </div>
          )}

          <h2 className={css.name}>{event.name}</h2>

          <div className={css.location_container}>
            <Img src="/icons/location_marker.svg" />
            <h4 className={css.location_text}>{`${location.name} / ${location.city}`}</h4>
          </div>
        </div>

        <ButtonWhiteBlack
          className={css.walletTicket__seeTickets}
          onClick={() => setShowTickets((prevState) => !prevState)}
        >
          <Img src={showTickets ? `/wallet/ticket_hide.svg` : `/wallet/ticket_show.svg`} />
          <h5>{t(showTickets ? 'wallet:hideTickets' : 'wallet:seeTickets')}</h5>
        </ButtonWhiteBlack>
      </GridColumn>

      {showLoader && !response && (
        <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.walletTicket__flexCenter}>
          <UserTicketSkeleton />
        </GridColumn>
      )}

      {showTickets && (
        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 2 }}
          gridColumnEnd={{ [Breakpoints.laptop]: 16 }}
          className={css.walletTicket__tickets}
        >
          {userTickets.length > 0 &&
            userTickets.map((userTicket, idx) => (
              <React.Fragment key={userTicket.id}>
                <UserTicket idx={idx} event={event} userTicket={userTicket} />
                {idx !== userTicketsNumber - 1 && <div className={css.walletTicket__cover} />}
              </React.Fragment>
            ))}
        </GridColumn>
      )}
    </>
  )
}

export default WalletTicketDesktop
