import { useTranslation } from 'react-i18next'

import { FormFieldType, IFormFieldPhone, IFormFieldSelect } from 'meta/form'
import { Dates } from 'utils/dates'

import { IFormProps } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useInitialValues = (props: IFormProps): Record<string, any> => {
  const { t } = useTranslation()

  const { formFields } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return formFields.reduce<Record<string, any>>((valuesAgg, formField) => {
    const { value } = formField

    let fieldValue = value ?? ''
    if (fieldValue instanceof Date) {
      fieldValue = Dates.format(fieldValue, t('dates:common:yearMonthDay'))
    }

    const values = {
      ...valuesAgg,
      [formField.name]: fieldValue,
    }
    if (formField.type === FormFieldType.phone) {
      const phone = formField as IFormFieldPhone
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      values[phone.namePrefix] = phone.valuePrefix ?? ''
    }

    if (formField.type === FormFieldType.select && !formField.placeholder) {
      const select = formField as IFormFieldSelect

      values[select.name] = select.options?.[0]?.value
    }

    return values
  }, {})
}
