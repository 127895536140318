import { IMapSection, IMapSectors } from 'meta/map'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

export const getSectionsEnabled = async ({
  eventId,
}: {
  eventId: string | number
}): Promise<{
  sectionsEnabled: Array<IMapSection>
  linkedSeats: Record<string, Array<string>>
  sectors?: IMapSectors
}> => {
  const { data } = await HttpRequest.get<{
    sections: Array<IMapSection>
    linkedSeats?: Record<string, Array<string>>
    sectors?: IMapSectors
  }>(ApiEndpoint.event.mapSectionsEnabled(eventId))

  const { sectors, linkedSeats = {}, sections: sectionsEnabled } = data
  return { sectionsEnabled, linkedSeats, sectors }
}
