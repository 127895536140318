import css from './Story.module.scss'
import React from 'react'

import { AssetType, IStory } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import Card, { CardImageType } from 'components/Layout/Card'
import { GridColumn, GridColumnFraction } from 'components/Layout/Grid'
import ImgSkeleton from 'components/Media/ImgSkeleton'

const Story: React.FC<IPropsComponent<IStory>> = (props) => {
  const { name, gallery, onClick } = props
  const cover = gallery[0]
  const coverImageSrc =
    cover.type === AssetType.image
      ? cover.src[0].url
      : cover?.src?.find((source) => source.mimeType?.startsWith('image'))?.url
  return (
    <GridColumn
      gridColumnSpan={{
        [Breakpoints.laptop]: GridColumnFraction.oneSixth,
        [Breakpoints.tablet]: GridColumnFraction.oneSixth,
        [Breakpoints.mobile]: GridColumnFraction.oneFourth,
      }}
      className={css.storyContainer}
    >
      <Card title={name} imageType={CardImageType.background} onClick={onClick}>
        <ImgSkeleton alt={name} src={coverImageSrc ?? ''} className={css.storyContainer} />
      </Card>
    </GridColumn>
  )
}

export default Story
