import css from './TextArea.module.scss'
import React from 'react'
import classNames from 'classnames'
import { Field, useField } from 'formik'

import { IFormFieldTextArea } from 'meta/form'

import { IFormFieldProps } from '../props'

const TextArea: React.FC<IFormFieldProps> = (props) => {
  const { formField, hasError } = props
  const { name, placeholder, rows, maxLength } = formField as IFormFieldTextArea
  const [, { value }] = useField(name)

  return (
    <div className={classNames(css.container, { [css.hasError]: hasError })}>
      <Field
        as="textarea"
        className={css.textArea}
        id={name}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        rows={rows}
        style={{ resize: 'vertical' }}
      />
      {maxLength && (
        <p className={css.paragraph}>
          {value.length} / {maxLength}
        </p>
      )}
    </div>
  )
}

export default TextArea
