import { IButton } from './button'
import { ComponentSize, ComponentType, IComponent } from './component'

export enum CategoryShowMode {
  gallery = 'gallery',
  story = 'story',
}

export interface ICategory extends IComponent<ComponentType.category> {
  button?: IButton
  data: Array<IComponent<ComponentType>>
  showMode?: CategoryShowMode
  size?: ComponentSize
}

export interface CategorySkeleton extends IComponent<ComponentType.categorySkeleton> {
  // button?: IButton
  data: Array<IComponent<ComponentType>>
  titleColor?: string
  // showMode?: CategoryShowMode
  // size?: ComponentSize
}
