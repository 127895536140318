import { ComponentType, IAsset, IStory } from '../../structure'
import { IStoryGalleryBackend } from '../component/storyGallery'
import ComponentAdapter from './componentAdapter'
// import { IEventStoryBackend } from '../component/event'

// export default class StoryAdapter extends ComponentAdapter<IStory, IEventStoryBackend> {
//     convert(): IStory {
//         const { codeUrl, eventAsset, name, storyLink } = this.componentBackend
//         const imageUrl =
//             eventAsset && eventAsset.covers && eventAsset.covers.length > 0 ? eventAsset.covers[0].url : null
//
//         return {
//             componentType: ComponentType.story,
//             codeUrl,
//             imageUrl,
//             name,
//             storyLink,
//         }
//     }
// }

export default class StoryAdapter extends ComponentAdapter<IStory, IStoryGalleryBackend> {
  convert(): IStory {
    const { name, url, storyGalleryAsset } = this.componentBackend

    return {
      componentType: ComponentType.story,
      gallery: this.componentsAdapter.convertComponents(storyGalleryAsset.gallery) as Array<IAsset>,
      name,
      url,
    }
  }
}
