export enum Breakpoints {
  mobile = 'mobile',
  laptop = 'laptop',
  tablet = 'tablet',
}

export const BreakpointsMaxWidth = {
  zero: 0,
  fold: 289,
  portrait: 429,
  landscape: 819,
  mobile: 819,
  tablet: 991,
  laptop: 1199,
}

export const BreakpointsMinWidth = {
  zero: 0,
  mobilePortrait: 321,
  mobileLandscape: 480,
  tabletPortrait: 768,
  tabletLandscape: 1024,
  laptop: 1200,
}
