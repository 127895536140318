import css from './SeasonList.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionFromIframe, CollectionSeasonTicketsList } from 'meta/pages/collection'

import { Grid, GridColumn } from 'components/Layout/Grid'
import { MockCollectionAnchorable } from 'components/Pages/Collection/mock'

import SeasonListItem from './SeasonListItem'

const SeasonList: React.FC<CollectionSeasonTicketsList & MockCollectionAnchorable & CollectionFromIframe> = (props) => {
  const { collectionId, seasonTickets, anchorId, fromIframe, toggleContent } = props

  const { t } = useTranslation()

  return (
    <Grid>
      <GridColumn gridColumnStart={1} gridColumnEnd={{ [Breakpoints.tablet]: 17, [Breakpoints.mobile]: 17 }}>
        <div className={classNames(css.seasonListContainer, { [css.fromIframe]: fromIframe })}>
          <h2 className={css.title} id={anchorId}>
            {t('collection:season_tickets_title')}
          </h2>
          {seasonTickets.map((elem, key) => (
            <SeasonListItem
              {...elem}
              collectionId={collectionId}
              key={key}
              fromIframe={fromIframe}
              toggleContent={toggleContent}
            />
          ))}
        </div>
      </GridColumn>
    </Grid>
  )
}

export default SeasonList
