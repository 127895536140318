import { EventPolicy } from '../../backendControlled/structure'
import { FormDataType, FormFieldType, IFormFieldSelectOption } from '../../form'

export interface ExtraFieldMetadata {
  maxLength: number
  type: FormDataType
}

export interface ITicketExtraField {
  dataType?: FormDataType
  fieldType: FormFieldType
  id: number
  label: string
  metadata?: ExtraFieldMetadata
  name: string
  options?: Array<IFormFieldSelectOption>
  placeholder?: string
  required: boolean
}

export interface ISpecialEventOptions {
  policy?: EventPolicy
  isPersonalDataRequired?: boolean
}

export enum AppointmentStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum AppointmentSlotStatus {
  booked = 'booked',
  free = 'free',
  notAvailable = 'notAvailable',
}

export interface ITicketAppointmentCovidSlot {
  uuid: string
  from: Date
  status: AppointmentSlotStatus
}

export interface ITicketCovidAppointment {
  uuid: string
  name: string
  status: AppointmentStatus
  appointmentSlots: Array<ITicketAppointmentCovidSlot>
}

export interface ITicket {
  details: string
  extraFields: Array<ITicketExtraField>
  appointments?: Array<ITicketCovidAppointment>
  id: number
  seatId?: string
  title: string
  typeTicketId: string
}
