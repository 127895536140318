import css from './Fr.module.scss'
import React from 'react'

import Img from 'components/Media/Img'

const ResaleFr: React.FC = () => {
  return (
    <div className={css.resale_fr_container}>
      <div className={css.static_page_title}>
        <Img src="/resale/resale.svg" />
        <div>Revente</div>
      </div>
      <div className={css.static_page_text}>
        <p>
          Les billets achetés par TicketSms sont nominatifs, en particulier, les grands spectacles de plus de 5000
          spectateurs nécessitent, selon le{' '}
          <a
            target="_blank"
            href="http://www.agenziaentrate.gov.it/portale/web/guest/-/provvedimento-del-27-06-2019-pubblicato-il-27-06-2019"
            rel="noreferrer"
          >
            nouvelle réglementation
          </a>
          ,l'obligation de <b>nommer</b> et le contrôle de l'identité du détenteur du billet au moment de l'entrée.
          C'est pourquoi, au moment de l'entrée aux événements en question, il sera également demandé un document
          d'identité valide pour permettre au personnel chargé des contrôles de vérifier que l'accès avec ce billet
          spécifique est bien le titulaire. En cas de non-concordance, vous ne pourrez pas accéder à l'événement sans
          droit à un remboursement. Sur le site web, dans la page de l'événement, il sera indiqué si l'événement est
          soumis à la nominativité et donc <b>obligatoire</b> il sera nécessaire de se présenter à l'événement avec un
          document valide pour pouvoir entrer.
        </p>

        <p>
          Si vous ne pouvez pas participer à un événement, vous pouvez activer la procédure de revente du billet à votre
          nom ou des billets que vous avez achetés (même un seul billet en cas d'achats multiples), en accédant à la
          section appropriée de votre profil ou depuis le résumé de la transaction.
        </p>

        <p>
          <b>Pour effectuer la mise en revente</b> vous devez indiquer vos Prénom, Nom et Code fiscal tels qu'ils
          figurent sur le billet original dont vous êtes l'acheteur ou le détenteur (ces informations sont toutes
          indiquées sur le billet lui-même). En outre, il sera nécessaire d'indiquer un IBAN sur lequel un transfert
          sera effectué en cas de revente.
        </p>

        <p>
          Une fois les données et le droit de procéder vérifiés, un résumé de l'offre sera affiché et une confirmation
          finale sera demandée, après laquelle le billet sera remis en vente au même prix que le billet original dans
          TicketSms même. Lorsqu'un nouvel acheteur souhaite acheter un billet du même type qu'un billet remis en vente,
          ce dernier sera prioritaire par rapport aux billets standards.
        </p>

        <p>
          Si la revente est réussie, le vendeur recevra, dans un délai de 15 jours ouvrables, un virement égal à la
          valeur du billet unique revendu, net des commissions et des frais de service, soit 1,50 €.Aucune commission ou
          frais d'insertion n'est prévue, la commission n'est appliquée qu'une fois la revente effectuée.
        </p>

        <p>
          La fonction de revente suit le{' '}
          <b>mêmes règles horaires que la vente classique jusqu'au début de l'événement</b>, il sera donc disponible
          pendant la durée de la vente des billets ordinaires jusqu'à l'ouverture des portes de l'événement, moment où
          vous ne pourrez plus revendre vos billets ou acheter des billets mis en vente par d'autres utilisateurs.
        </p>

        <p>
          Si vous souhaitez annuler la revente d'un de vos billets, il vous suffit d'accéder à la section appropriée du
          menu de revente dans votre profil, où vous devrez saisir à nouveau les données relatives au billet (nom et
          numéro fiscal). Toutefois, l'annulation ne sera possible que lorsque le billet en question sera ajouté au
          panier d'un nouvel acheteur.
        </p>

        <p>
          Notez que le titre d'accès original <b>ne peut pas être utilisé</b> tant qu'il reste sur le marché de la
          revente, par conséquent, même en cas de non-revente dans les délais, afin d'utiliser votre billet pour accéder
          à l'événement, vous devrez mettre en œuvre la procédure de <b>annulation de la revente</b>.
        </p>

        <p>
          Enfin, il convient de noter que <b>il n'est pas possible de revendre des billets achetés via 18APP</b>.
        </p>
      </div>
    </div>
  )
}

export default ResaleFr
