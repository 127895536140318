import { joinParts } from './utils'

export const collection = {
  details: (id: string) => joinParts('eventCollections', id),
  seasonEventsList: (codeUrl: string, collectionId: string) =>
    joinParts('eventCollections', codeUrl, 'typeTicketCollections', collectionId, 'events'),
  seasonEventsTicketsFees: (codeUrl: string, collectionId: string) =>
    joinParts('eventCollections', codeUrl, 'typeTicketCollections', collectionId, 'fees'),
  mapSectionsEnabled: (codeUrl: string, collectionId: string) =>
    joinParts('eventCollections', codeUrl, 'typeTicketCollections', collectionId, 'map', 'sections', 'enabled'),
  mapSeatsBusy: (codeUrl: string, collectionId: string) =>
    joinParts('eventCollections', codeUrl, 'typeTicketCollections', collectionId, 'map', 'sections', 'seats', 'busy'),
  mapSeatTypeTickets: (codeUrl: string, collectionId: string, sectionId: string, seatId: string) =>
    joinParts(
      'eventCollections',
      codeUrl,
      'typeTicketCollections',
      collectionId,
      'map',
      'sections',
      'sections',
      sectionId,
      'seats',
      seatId,
      'typeTickets',
      codeUrl
    ),
}
