import { AnyAction, combineReducers, configureStore, Dispatch, isRejected, Middleware } from '@reduxjs/toolkit'

import { AppSlice } from 'store/app'
import { AuthSlice } from 'store/auth'
import { BackendControlledSlice } from 'store/backendControlled'
import { CurrencySlice } from 'store/currency'
import { CheckoutSlice } from 'store/pages/checkout'
import { CollectionSlice } from 'store/pages/collection'
import { EventSlice } from 'store/pages/event'
import { LoginSlice } from 'store/pages/login'
import { ProfileSlice } from 'store/pages/profile'
import { SearchSlice } from 'store/pages/search'

import { Toast } from 'components/common/Toast'

import { WalletSlice } from './pages/wallet'

const reducer = combineReducers({
  [AppSlice.name]: AppSlice.reducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [BackendControlledSlice.name]: BackendControlledSlice.reducer,
  [CurrencySlice.name]: CurrencySlice.reducer,
  pages: combineReducers({
    [CheckoutSlice.name]: CheckoutSlice.reducer,
    [CollectionSlice.name]: CollectionSlice.reducer,
    [EventSlice.name]: EventSlice.reducer,
    [LoginSlice.name]: LoginSlice.reducer,
    [ProfileSlice.name]: ProfileSlice.reducer,
    [SearchSlice.name]: SearchSlice.reducer,
    [WalletSlice.name]: WalletSlice.reducer,
  }),
})

const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action: AnyAction) => {
  if (isRejected(action)) {
    Toast.error(JSON.stringify(action.error.message))
  }

  return next(action)
}

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorHandlerMiddleware),
})
