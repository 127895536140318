import { ComponentType, ICity } from '../../structure'
import { ICitySquareBackend } from '../component/citySquare'
import ComponentAdapter from './componentAdapter'

export default class CityAdapter extends ComponentAdapter<ICity, ICitySquareBackend> {
  convert(): ICity {
    const { cityAsset, name, eventsCount, locationsCount } = this.componentBackend

    const covers = cityAsset?.covers
    const cover = covers && covers.find((cover) => cover.type === 'image' && cover.format === 'square')

    return {
      componentType: ComponentType.city,
      imageUrl: cover?.src?.[0]?.url,
      name,
      events: eventsCount,
      locations: locationsCount,
    }
  }
}
