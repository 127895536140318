import css from './CookiePolicySkeleton.module.scss'
import React, { useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'
import MediaQuery from 'react-responsive'
import cn from 'classnames'

import { BreakpointsMinWidth } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY } from 'utils/constants/colors'

const Paragraph: React.FC<{ size: number; className?: string | undefined }> = ({ size, className }) => {
  const generateArrayOfLength = useCallback((length: number) => Array.from({ length }, (v, i) => i), [])

  return (
    <div className={css.cookiepolicy_skeleton__paragraph}>
      {generateArrayOfLength(size).map((_, idx) => (
        <Skeleton
          key={idx}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={4}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          className={cn(css.cookiepolicy_skeleton__line, className)}
        />
      ))}
    </div>
  )
}

const CookiePolicySkeleton: React.FC = () => {
  return (
    <div className={css.cookiepolicy_skeleton}>
      <div className={css.static_page_title}>
        {/* <Skeleton
          className={css.cookiepolicy_skeleton__img}
          baseColor={TICKETSMS_COLORS_MEDIUMGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_LIGHTGREY}
          inline
        /> */}
        <div className={css.cookiepolicy_skeleton__heading}>
          <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
            {[1, 2].map((_, idx) => (
              <Skeleton
                key={idx}
                className={css.cookiepolicy_skeleton__heading__line}
                baseColor={TICKETSMS_COLORS_MEDIUMGREY}
                borderRadius={8}
                highlightColor={TICKETSMS_COLORS_LIGHTGREY}
              />
            ))}
          </MediaQuery>
          <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait}>
            <Skeleton
              className={css.cookiepolicy_skeleton__heading__line}
              baseColor={TICKETSMS_COLORS_MEDIUMGREY}
              borderRadius={8}
              highlightColor={TICKETSMS_COLORS_LIGHTGREY}
            />
          </MediaQuery>
        </div>
      </div>

      <div className={css.static_page_text}>
        <MediaQuery minWidth={BreakpointsMinWidth.zero} maxWidth={BreakpointsMinWidth.mobileLandscape - 1}>
          <Paragraph size={3} className={css.bold} />
          <Paragraph size={1} />
          <Paragraph size={1} />
          <Paragraph size={12} />
          <Paragraph size={9} />
          <Paragraph size={12} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.mobileLandscape} maxWidth={BreakpointsMinWidth.tabletPortrait - 1}>
          <Paragraph size={2} className={css.bold} />
          <Paragraph size={1} />
          <Paragraph size={1} />
          <Paragraph size={9} />
          <Paragraph size={7} />
          <Paragraph size={9} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletPortrait} maxWidth={BreakpointsMinWidth.tabletLandscape - 1}>
          <Paragraph size={1} className={css.bold} />
          <Paragraph size={1} />
          <Paragraph size={1} />
          <Paragraph size={6} />
          <Paragraph size={5} />
          <Paragraph size={5} />
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMinWidth.tabletLandscape}>
          <Paragraph size={1} className={css.bold} />
          <Paragraph size={1} />
          <Paragraph size={1} />
          <Paragraph size={4} />
          <Paragraph size={4} />
          <Paragraph size={4} />
        </MediaQuery>
      </div>
    </div>
  )
}

export default CookiePolicySkeleton
