export enum LanguageCode {
  ca = 'ca',
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  pt = 'pt',
}

export type Language = {
  iso2: string
  label: string
  locale: string
  shortLabel: string
}

export const Languages: Record<LanguageCode, Language> = {
  en: {
    iso2: 'en',
    label: 'English',
    locale: 'en_US',
    shortLabel: 'Eng',
  },
  it: {
    iso2: 'it',
    label: 'Italiano',
    locale: 'it_IT',
    shortLabel: 'Ita',
  },
  ca: {
    iso2: 'ca',
    label: 'Català',
    locale: 'ca_ES',
    shortLabel: 'Cat',
  },
  es: {
    iso2: 'es',
    label: 'Espanol',
    locale: 'es_ES',
    shortLabel: 'Esp',
  },
  fr: {
    iso2: 'fr',
    label: 'Francais',
    locale: 'fr_FR',
    shortLabel: 'Fra',
  },
  pt: {
    iso2: 'pt',
    label: 'Português',
    locale: 'pt_PT',
    shortLabel: 'Por',
  },
  de: {
    iso2: 'de',
    label: 'Deutsch',
    locale: 'de_DE',
    shortLabel: 'Deu',
  },
}
