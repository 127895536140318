import css from 'components/Pages/SupportConversation/ConversationMessage/ConversationMessage.module.scss'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ISupportConversationMessage } from 'meta/pages/support'
import { Dates } from 'utils/dates'

import Logo from 'components/Layout/LogoV2'
import Img from 'components/Media/Img'

type Props = {
  conversationMessage: ISupportConversationMessage
  firstName: string
  lastName: string
}

const htmlDecode = (input: string) => {
  const e = document.createElement('textarea')
  e.innerHTML = input
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
}

const ConversationMessage: React.FC<Props> = (props) => {
  const { conversationMessage, firstName, lastName } = props
  const { t } = useTranslation()

  const messageBoxRef = useRef<HTMLDivElement>(null)
  const messageTextRef = useRef<HTMLDivElement>(null)
  const { dateCreation, isOperator, isNew, text } = conversationMessage

  const pictureUrl = isOperator ? '/profile/avatar_4.jpg' : '/profile/user_avatar.svg'

  useEffect(() => {
    if (messageTextRef.current) {
      messageTextRef.current.innerHTML = htmlDecode(text) || ''
    }

    if (isNew)
      document.documentElement.scrollTo({
        top: messageBoxRef.current?.offsetTop,
        behavior: 'smooth',
      })
  }, [isNew, text])

  return (
    <div
      className={classNames(css.messageBoxWrapper, { [css.operator]: isOperator, [css.new]: isNew })}
      ref={messageBoxRef}
    >
      <div className={css.messageBox}>
        <div className={css.dateBox}>
          <div className={css.dateMarker} />
          <div className={css.date}>
            {Dates.format(new Date(dateCreation), t('dates:support-conversation:dateTime'))}
          </div>
        </div>

        <div className={css.messageContentBox}>
          <div className={css.messageUserBox}>
            <Img src={pictureUrl} />
            <div className={css.messageUserNameBox}>
              {isOperator && <div>{t('support-conversation:reply')}</div>}
              <div className={css.messageUserName}>
                <span>{isOperator ? 'Sara' : firstName}</span>
                <span> {isOperator ? 'M.' : `${lastName.substring(0, 1)}.`}</span>
              </div>
            </div>
          </div>
          <div className={css.messageText} ref={messageTextRef} />
          <div className={css.messageLogo}>{isOperator && <Logo linkToHome={false} />}</div>
        </div>
      </div>

      <div className={css.messageBoxTip} />
    </div>
  )
}

export default ConversationMessage
