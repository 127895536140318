import { getValidCityCodeRegex, getValidCountryCodeRegex } from 'utils/regex'

import { FormFieldBuilder } from './formFieldBuilder'
import { FormFieldType, IFormField } from '.'

interface ILinkedOptions {
  onChange?(value: string): void
  disable?: boolean
  linkIdentifier?: string
  defaultValue?: string
}

export interface IGetDataEnv {
  urlBase: string
  locale: string
}

export interface SelectSearchOption {
  name: string
  value: string
  match: string
}

export interface IFormFieldLinkedDataList extends IFormField<FormFieldType.linked> {
  getData(query: string, env: IGetDataEnv): Promise<SelectSearchOption[]>
  options?: ILinkedOptions
}

export class LinkedDataListBuilder extends FormFieldBuilder<FormFieldType.linked> {
  //@ts-ignore
  constructor(name: string, getData, options?: ILinkedOptions, nameValidation?: string) {
    super(name, FormFieldType.linked, nameValidation)
    super.addField('getData', getData)

    //@ts-ignore
    super.addField('options', options)
  }

  addRequiredCountryCodeValidation(): FormFieldBuilder<FormFieldType.linked> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidCountryCodeRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }

    return super.addValidation(validation)
  }

  addRequiredCityCodeValidation(): FormFieldBuilder<FormFieldType.linked> {
    const validation = {
      required: `form:errors_required_${this.nameValidation}`,
      pattern: { value: getValidCityCodeRegex(), message: `form:errors_invalid_${this.nameValidation}` },
    }

    return super.addValidation(validation)
  }
}
