import css from './InstaStories.module.scss'
import React, { MutableRefObject, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../Layout/Modal'
import InstaStory from './InstaStory'
import { StoriesProps } from './props'
import { useStoriesState } from './state'

const InstaStories: React.FC<StoriesProps> = (props) => {
  const { onClose, stories } = props
  const { t } = useTranslation()

  const storiesRef = useRef<HTMLDivElement>(null)
  const { laptop, idxActive, updateIdxActive, styles } = useStoriesState(
    props,
    storiesRef as MutableRefObject<HTMLDivElement>
  )

  useLayoutEffect(() => {
    // add transition a little after component is mount to avoid distortion on mobile carousel initialization
    //@ts-ignore
    setTimeout(() => storiesRef.current.classList.add(css.withStyles), 500)
    // prevent safari to load saved snapshot state with story opened.
    window.addEventListener('pagehide', onClose)
    return () => window.removeEventListener('pagehide', onClose)
  }, [])

  return (
    <Modal dark handleClose={onClose} fullHeight show={true} transparent>
      <div className={css.instaStoriesScene}>
        <div className={css.instaStories} ref={storiesRef}>
          {styles &&
            stories.map((story, idx) => (
              <InstaStory
                key={String(idx)}
                active={idx === idxActive}
                laptop={laptop}
                onClick={() => updateIdxActive(idx, false)}
                playNextStory={() => updateIdxActive(+1)}
                playPrevStory={() => updateIdxActive(-1)}
                story={story}
                style={styles[idx]}
              />
            ))}
        </div>
      </div>
    </Modal>
  )
}

export default InstaStories
