import css from './Video.module.scss'
import React from 'react'

import { useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  url: string
  className?: string
  addUrlBase?: boolean
}

const Video: React.FC<IProps> = (props) => {
  const { url, className, addUrlBase } = props
  const urlCloudfront = useUrlCloudfront()

  const processedUrl = addUrlBase ? `${urlCloudfront}/videos${url}` : url

  return (
    <video
      className={`${css.fitScreen} ${className ? className : null}`}
      autoPlay
      muted
      playsInline
      loop
      width={'100%'}
    >
      <source src={processedUrl} type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  )
}

Video.defaultProps = {
  addUrlBase: true,
}

export default Video
