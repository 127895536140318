import css from './Asset.module.scss'
import React from 'react'
import classNames from 'classnames'

import { AssetType, IAsset } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'

import Image from './Image'
import Video from './Video'

const componentsMap = {
  [AssetType.image]: Image,
  [AssetType.video]: Video,
}

const Asset: React.FC<IPropsComponent<IAsset>> = (props) => {
  const { format, onClick, size, type } = props
  const Component = componentsMap[type]

  if (!Component) return null

  return (
    <GridColumn
      className={classNames(css.assetImageContainer, format && css[format], css[`size_${size}`], {
        [css.pointer]: Boolean(onClick),
      })}
      gridColumnSpan={{ [Breakpoints.laptop]: 4, [Breakpoints.tablet]: 5, [Breakpoints.mobile]: 9 }}
      onClick={onClick}
    >
      {React.createElement(Component, props)}
    </GridColumn>
  )
}

export default Asset
