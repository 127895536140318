import { init } from 'store/pages/checkout/actions/init'
import { CheckoutState, initialState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

export const setInitializing: SliceUpdater<CheckoutState, ReturnType<typeof init.pending>> = (state, action) => {
  const { codeUrl, typeTicketCollectionId, insideIFrame } = action.meta.arg
  state = {
    eventCodeUrl: codeUrl,
    eventTypeTicketCollectionId: typeTicketCollectionId,
    // @ts-expect-error might not be compatible
    details: {
      ...state.details,
      insideIFrame,
    },
    ...initialState,
  }
  return state
}

export const setInitialized: SliceUpdater<CheckoutState, ReturnType<typeof init.fulfilled>> = (state, action) => {
  const { details, extraDetails, stripe, ticketSelection, step, payment } = action.payload
  return { ...state, details, extraDetails, stripe, ticketSelection, step, payment }
}
