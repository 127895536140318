import { AxiosRequestConfig } from 'axios'

import { axiosInstance } from 'utils/http/axiosInstance'
import { AxiosResponse, BackendResponse, ResponseError } from 'utils/http/response'

const responseBody = <Data, Metadata = Record<never, never>>(response: AxiosResponse<Data, Metadata>) => {
  const { data } = response
  if (data.error) {
    throw new ResponseError(data.error, data.exceptions)
  }
  return data
}

export const HttpRequest = {
  initBaseUrl: (baseUrl: string) => (axiosInstance.defaults.baseURL = baseUrl),

  get: <ResponseData, Metadata = Record<never, never> | undefined>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<BackendResponse<ResponseData, Metadata>> =>
    axiosInstance.get<ResponseData, AxiosResponse<ResponseData, Metadata>>(url, config).then(responseBody),

  post: <
    ResponseData = Record<never, never>,
    Body = Record<never, never>,
    ResponseMetadata = Record<never, never> | undefined
  >(
    url: string,
    body: Body,
    headers?: Object
  ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
    axiosInstance
      .post<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Body>(url, body, headers)
      .then(responseBody),

  patch: <
    ResponseData = Record<never, never>,
    Body = Record<never, never>,
    ResponseMetadata = Record<never, never> | undefined
  >(
    url: string,
    body: Body
  ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
    axiosInstance
      .patch<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Body>(url, body)
      .then(responseBody),

  /**
   * TODO: when dealing with delete request
   * verify why TS2345: Argument of type 'Data | undefined' is not assignable to parameter of type 'AxiosRequestConfig<Data> | undefined'.
   */
  // delete: <ResponseData = Record<never, never>, Data = Record<never, never>, ResponseMetadata = Record<never, never>>(
  //   url: string,
  //   data?: Data
  // ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
  //   axiosInstance
  // .delete<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Data>(url, data)
  // .then(responseBody),
}
