import { CookieName, ICookie } from './types'

const xTicketsmsAuthToken = 'x-ticketsms-auth-token'

export const refreshAuthToken = (
  response: Response,
  iCookie: ICookie<any>,
  params: any,
  setCookie: boolean = true
): void => {
  let authToken: string
  if (response['headers']) {
    //@ts-ignore
    authToken = response.headers.get(xTicketsmsAuthToken)
  } else if (
    //@ts-ignore
    response['xhr'] &&
    //@ts-ignore
    response.xhr.getAllResponseHeaders().indexOf(xTicketsmsAuthToken) >= 0
  ) {
    //@ts-ignore
    authToken = response.xhr.getResponseHeader(xTicketsmsAuthToken)
  }

  //@ts-ignore
  if (authToken !== null && authToken !== undefined) {
    if (authToken === '') {
      iCookie.remove(CookieName.TOKEN, params)
    } else {
      if (setCookie) {
        iCookie.set(CookieName.TOKEN, authToken, params)
      }
    }
  }
}
