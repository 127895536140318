import { getBusySeats } from 'controller/event/getBusySeats'
import { getCategories } from 'controller/event/getCategories'
import { getFees } from 'controller/event/getFees'
import { getSectionsEnabled } from 'controller/event/getSectionsEnabled'
import { getTypeTickets } from 'controller/event/getTypeTickets'

import { getMetadata } from './getMetadata'

export const EventController = {
  getBusySeats,
  getCategories,
  getFees,
  getSectionsEnabled,
  getTypeTickets,
  getMetadata,
}
