import css from '../Form.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'

import { useFormContext } from '..'

interface Props {
  labelSubmit?: string
}

const FormButtonSubmit: React.FC<Props> = (props) => {
  const { labelSubmit } = props

  const { t } = useTranslation()
  const { submitForm } = useFormContext()

  const [sentAlready, setSentAlready] = useState<boolean>(false)

  const doSubmitForm = () => {
    if (!sentAlready) {
      submitForm()

      setSentAlready(true)
    }
  }

  useEffect(() => {
    if (sentAlready) {
      const timer = setTimeout(() => {
        setSentAlready(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [sentAlready])

  return (
    <div className={css.form__buttonBar} datatype="form-buttonbar">
      <div
        onClick={doSubmitForm}
        data-testid={DataTestId.form.btnSubmit}
        id="submit"
        className={css.form__buttonSubmit}
      >
        {t(`${labelSubmit}`)}
      </div>
    </div>
  )
}

FormButtonSubmit.defaultProps = {
  labelSubmit: 'form:send',
}

export default FormButtonSubmit
