import React, { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useCallback, useLayoutEffect } from 'react'

import { FormFieldError, IFormStatus, useFormField } from 'components/Forms/Form'

type Props = {
  errorForm: IFormStatus['error']
  otpDigits: Array<string>
  setValues: Dispatch<SetStateAction<Array<string>>>
}

const OTPDigits: React.FC<Props> = (props) => {
  const { errorForm, otpDigits, setValues } = props

  const fieldStatuses = otpDigits.map((digit) => useFormField(digit)) // eslint-disable-line
  const errorOtp = fieldStatuses.some((status) => status.touched && status.error)

  const onChange = useCallback(
    (idx: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (value.length < 1) return

      fieldStatuses[idx].setValue(value)

      setValues((prevState) => {
        const valuesUpdate = [...prevState]
        valuesUpdate[idx] = value
        return valuesUpdate
      })
      const otpDigitNext = otpDigits[idx + 1]
      const element = document.getElementById(otpDigitNext || 'submitOTP')
      //@ts-ignore
      element.focus()
    },
    []
  )

  const onKeyUp = useCallback(
    (idx: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' && idx !== 0) {
        //@ts-ignore
        document.getElementById(otpDigits[idx - 1]).focus()
      }
    },
    []
  )

  useLayoutEffect(() => {
    //@ts-ignore
    document.getElementById(otpDigits[0]).focus()
  }, [])

  return (
    <>
      {otpDigits.map((otpDigit: string, idx: number) => (
        <input
          key={otpDigit}
          type="number"
          min={0}
          max={9}
          step={1}
          inputMode="numeric"
          pattern="[0-9]*"
          id={otpDigit}
          name={otpDigit}
          data-testid={otpDigit}
          maxLength={1}
          onChange={onChange(idx)}
          onKeyUp={onKeyUp(idx)}
        />
      ))}

      {(errorOtp || errorForm) && <FormFieldError error={String(errorOtp || errorForm)} name="otp" />}
    </>
  )
}

export default OTPDigits
