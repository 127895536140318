import { ComponentType, IComponent } from '../../structure'
import { ComponentTypeBackend, IComponentBackend } from '../component/component'
import adaptersMap from './adaptersMap'
import ComponentAdapter from './componentAdapter'
import ComponentsAdapter from './componentsAdapter'

class ComponentCloneAdapter extends ComponentAdapter<
  IComponent<ComponentType>,
  IComponentBackend<ComponentTypeBackend>
> {
  convert(): IComponent<ComponentType> {
    return { ...this.componentBackend } as unknown as IComponent<ComponentType>
  }
}

export default abstract class ComponentAdapterFactory {
  static newComponentAdapter(
    componentBackend: IComponentBackend<ComponentTypeBackend>,
    componentsAdapter: ComponentsAdapter
  ): ComponentAdapter<IComponent<ComponentType>, IComponentBackend<ComponentTypeBackend>> {
    // @ts-ignore
    const Adapter = adaptersMap[componentBackend.componentType] || ComponentCloneAdapter
    return new Adapter(componentBackend, componentsAdapter)
  }
}
