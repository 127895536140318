import {
  ICheckoutRecap,
  ICheckoutRecapAmount,
  ICheckoutRecapExtra,
  ICheckoutRecapTicket,
  ICheckoutRecapVoucherDetails,
} from 'meta/pages/checkout'

const convertRecapTicket = (data: any): ICheckoutRecapTicket => ({
  title: data.title,
  quantity: data.quantity,
  currency: data.total.currency === 'EUR' ? '€' : data.total.currency,
  symbol: data.total.symbol,
  amount: Number(data.total.amount) / 100,
  typeTicketId: data.typeTicketId,
})

const convertRecapAmount = (data: any): ICheckoutRecapAmount => ({
  amount: data.amount / 100,
  currency: data.currency === 'EUR' ? '€' : data.currency,
  symbol: data.symbol,
})

const convertRecapExtra = (data: any): ICheckoutRecapExtra => {
  const installments: Array<any> = data.installments ? data.installments : null
  const voucherDetails: ICheckoutRecapVoucherDetails = data.voucherDetails ? data.voucherDetails : null

  return {
    installments: data.installments
      ? installments.map(({ dueDate, installment }) => ({
          dueDate,
          installment: convertRecapAmount(installment),
        }))
      : undefined,
    voucherDetails: voucherDetails,
  }
}

export const APICheckoutRecapToRecapData = (data: any): ICheckoutRecap => {
  const { commission, extra, recapTickets, subTotal, total } = data
  return {
    tickets: recapTickets.map(convertRecapTicket),
    subtotal: convertRecapAmount(subTotal),
    commission: convertRecapAmount(commission),
    total: convertRecapAmount(total),
    extra: extra ? convertRecapExtra(extra) : undefined,
  }
}
