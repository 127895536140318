import { CheckoutState } from 'store/pages/checkout/state'
import { SliceUpdater } from 'store/types'

import { fetchSeasonEvents } from '../../actions/fetchSeasonEvents'

export const setSeasonEventsCompleted: SliceUpdater<CheckoutState, ReturnType<typeof fetchSeasonEvents.fulfilled>> = (
  state,
  action
) => {
  const { data } = action.payload
  const { events } = data

  return { ...state, season: { events: events } }
}
