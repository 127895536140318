import css from './Menu.module.scss'
import React from 'react'

import { IFaqSection } from 'meta/pages/faq'

import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Header, { HeaderSkeleton } from 'components/Pages/FAQ/Menu/Header'

import Voices from './Voices'

interface IProps {
  data: IFaqSection[]
  openSection: number
  setActiveSectionCallback(voice: string): void
}

const Menu: React.FC<IProps> = (props) => {
  const { data, openSection, setActiveSectionCallback } = props

  const { namespacesLoaded } = useI18NContext()

  const voices = data.map((elem) => elem.title)

  return (
    <div className={css.faq_menu_container}>
      <div className={css.faq_menu}>
        <WithAppInitialized showChildren={namespacesLoaded} skeleton={<HeaderSkeleton />}>
          <Header />
        </WithAppInitialized>

        <Voices
          setActiveMenuVoiceCallback={setActiveSectionCallback}
          activeSection={data[openSection].title}
          voices={voices}
        />
      </div>
    </div>
  )
}

export default Menu
