import { createSelector } from '@reduxjs/toolkit'

import {
  BackendControlledComponentsArray,
  BackendControlledMetadata,
  ComponentType,
  IEventDetails,
  IEventInfo,
  IVenueInfo,
} from 'meta/backendControlled/structure'
import { getEventKey } from 'store/backendControlled/_utils'
import { RootState, SliceSelector } from 'store/types'

const getComponents: SliceSelector<BackendControlledComponentsArray, { path: string }> =
  ({ path }) =>
  (state) => {
    return state.backendControlled[path]?.components
  }

const getComponentsMetadata: SliceSelector<BackendControlledMetadata | undefined, { path: string }> =
  ({ path }) =>
  (state) => {
    return state.backendControlled[path]?.metadata
  }

const getEventDetails: SliceSelector<IEventDetails | undefined, { codeUrl: string; language: string }> =
  ({ codeUrl, language }) =>
  (state) =>
    state.backendControlled[getEventKey({ codeUrl, language })]?.eventDetails

const getEventParentCodeUrl: SliceSelector<string | undefined, { codeUrl: string; language: string }> =
  ({ codeUrl, language }) =>
  (state) =>
    getComponentsMetadata({ path: getEventKey({ codeUrl, language }) })(state)?.parentCodeUrl

const getBackendControlled = (state: RootState) => state.backendControlled

const getEventVenueInfo = createSelector(
  [getBackendControlled, (backendControlled, apiPath: string) => apiPath],
  (backendControlled, apiPath) =>
    backendControlled[apiPath].components.filter(
      (component) => component.componentType === ComponentType.venueInfo
    )[0] as IVenueInfo
)

const getEventInfo = createSelector(
  [getBackendControlled, (backendControlled, apiPath: string) => apiPath],
  (backendControlled, apiPath) =>
    backendControlled[apiPath].components.filter(
      (component) => component.componentType === ComponentType.eventInfo
    )[0] as IEventInfo
)

export const BackendControlledSelectors = {
  getComponents,
  getComponentsMetadata,
  getEventDetails,
  getEventParentCodeUrl,
  getEventVenueInfo,
  getEventInfo,
}
