import { ITicket } from 'meta/pages/tickets'

export const getTicketFieldsKey = (ticket: ITicket, idx: number): string => `${ticket.id}_${idx}`

export const scrollToTicket = (ticket: ITicket, idx: number): void => {
  const ticketElement = document.getElementById(`${idx}_${ticket.id}`)
  document.documentElement.scrollTo({
    top: ticketElement?.offsetTop,
    behavior: 'smooth',
  })
}
