import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { IFormFieldText } from 'meta/form'

import { IFormFieldProps } from '../props'

const Input: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { t } = useTranslation()
  const { autoComplete, name, placeholder, type, maxLength } = formField as IFormFieldText
  const autoCompleteProps =
    autoComplete === false
      ? {
          autoComplete: 'off',
          onPaste: (event: ClipboardEvent) => {
            event.preventDefault()
          },
        }
      : {}

  return (
    <Field
      data-testid={name}
      id={name}
      name={name}
      placeholder={t(`${placeholder}`)}
      type={type}
      maxLength={maxLength}
      {...autoCompleteProps}
    />
  )
}

export default Input
