import { ComponentType, IArtist } from '../../structure'
import { IEventArtistBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class ArtistAdapter extends ComponentAdapter<IArtist, IEventArtistBackend> {
  convert(): IArtist {
    const { name, url } = this.componentBackend

    return {
      componentType: ComponentType.artist,
      name,
      imageUrl: url,
    }
  }
}
