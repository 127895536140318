import css from './Error.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { DataDogLogType, LOGS_INSIDE_ERROR_PAGE } from 'meta/logger'
import { ErrorSource } from 'meta/pages/error'
import { QueryParams } from 'meta/routes'
import { DataDogLogger, TSLogger } from 'helpers/debug/logger'

import { ButtonRed } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useIsAppInitialized } from 'hooks/useApp'
import { useCheckoutDetails } from 'hooks/useCheckout'
import { useQueryParams } from 'hooks/useQueryParams'

interface IProps {
  reload?: boolean
}

const Error: React.FC<IProps> = (props) => {
  const { reload } = props

  const { t } = useTranslation()
  const { state } = useLocation()
  const {
    errorCode,
    error,
    transactionCode: transactionCodeFromQueryString,
    paymentGateway,
    comingFrom,
  } = useQueryParams<QueryParams['Error']>()

  const appInitialized = useIsAppInitialized()
  const checkoutDetails = useCheckoutDetails()
  const fromTransaction = comingFrom === ErrorSource.transaction
  const sessionExpired = comingFrom === ErrorSource.sessionExpired

  useEffect(() => {
    if (appInitialized) {
      state?.msg && TSLogger(state?.msg, true)
      error && TSLogger(error, true)
      errorCode && TSLogger(error, true)

      const transactionCode = transactionCodeFromQueryString
        ? transactionCodeFromQueryString
        : checkoutDetails
        ? checkoutDetails.transactionCode
        : undefined

      DataDogLogger(LOGS_INSIDE_ERROR_PAGE, DataDogLogType.error, {
        fromTransaction: fromTransaction,
        transactionCode: transactionCode,
        paymentGateway: paymentGateway,
        sessionExpired: sessionExpired,
        errorCode: errorCode ? errorCode : '',
        errorMsg: error ? JSON.stringify(error) : '',
      })
    }
  }, [appInitialized])

  return (
    <div className={css.error_container}>
      <div className={css.image_error_container}>
        <Img src="/error/error_logo.svg" />
      </div>

      <div className={css.error_text_container}>
        {error && <h4 dangerouslySetInnerHTML={{ __html: error }}></h4>}

        {!sessionExpired && !fromTransaction && !error && (
          <div className={css.text}>
            <h4>{`${t('error:attention')} ${t('error:message')}`}</h4>
            <h5>{`${t('error:try_again_later')}`}</h5>
          </div>
        )}

        {Boolean(state?.msg) && <h5>{String(state?.msg)}</h5>}

        <div>
          {fromTransaction && !sessionExpired && <div>{t('error:fromTransaction')}</div>}
          {sessionExpired && <div>{t('error:sessionExpired')}</div>}
        </div>

        {sessionExpired && (
          <div className={css.error_box}>
            <Link to="/support/form">
              <a>{t('error:sessionExpired_link')}</a>
            </Link>
          </div>
        )}

        {reload && (
          <div className={css.reload}>
            <ButtonRed
              onClick={() => {
                window.location.reload()
              }}
            >
              <h5 data-modalclose="skip">{t('error:reload')}</h5>
            </ButtonRed>
          </div>
        )}
      </div>
    </div>
  )
}

export default Error
