export enum TransactionTicketStatus {
  valid = 'valid',
  checked = 'checked',
  cancelled = 'cancelled',
  blackList = 'blackList',
  old = 'old',
  refundInitialized = 'refundInitialized',
  refundCompleted = 'refundCompleted',
}

export interface ITransactionPrice {
  amount: string
  currency: string
  formatted: string
}

export interface ITransactionTicket {
  code: string
  eventId: number
  fiscalSeal: string
  firstName: string
  holderName: string
  holderPhone: string
  lastName: string
  price: string
  priceData: ITransactionPrice
  refundable: boolean
  seatId?: string
  secret: string
  status: TransactionTicketStatus
  statusLabel: string
  ticketType: string
  typeTicketCollectionName?: string
  isSeasonTicket?: boolean
  isChangeNameDisabled?: boolean
  isResaleDisabled?: boolean
}

export interface ITransaction {
  code: string
  completed: boolean
  date: Date
  eventDate: Date
  eventName: string
  isSeason?: boolean
  numberOfSeasonsEvents?: boolean
  numTickets: number
  paymentMethod: string
  price: string
  priceData: ITransactionPrice
  refundable: boolean
  onlySeasonTickets?: boolean
  status: string
  tickets?: ITransactionTicket[]
  token: string
}

export class TransactionAdapter {
  // eslint-disable-next-line
  static convertResponse(data: Array<any>): Array<ITransaction> {
    if (!data) return []

    return data.map((payment) => {
      const {
        dateCreation_tz,
        event,
        isRefundable,
        numTickets,
        paymentMethod,
        price,
        status,
        tickets,
        transactionCode,
        transactionToken,
        isSeason,
        numberOfSeasonsEvents,
      } = payment
      const completed = status == 'completed'
      const hasTickets = tickets && tickets.length > 0

      const onlySeasonTickets =
        hasTickets && tickets.filter((x: ITransactionTicket) => x.isSeasonTicket == true).length == tickets.length

      return {
        code: transactionCode,
        completed,
        date: new Date(dateCreation_tz),
        eventDate: new Date(event.datetime_tz),
        eventName: event.name,
        numTickets,
        onlySeasonTickets: onlySeasonTickets,
        paymentMethod,
        price: price.formatted,
        priceData: price,
        refundable: isRefundable,
        isSeason,
        numberOfSeasonsEvents,
        status,
        // @ts-expect-error ticket type might be unknown
        tickets: hasTickets ? tickets.map((ticket) => this.convertTicket(ticket, event.id, isRefundable)) : [],
        token: transactionToken,
      }
    })
  }

  // @ts-expect-error ticket type might be unknown
  private static convertTicket(ticket, eventId, refundable): ITransactionTicket {
    return {
      eventId,
      code: ticket.cod,
      firstName: ticket.firstName,
      fiscalSeal: ticket.fiscalSeal,
      holderName: ticket.fullName,
      holderPhone: `${ticket.prefix} ${ticket.phone}`,
      lastName: ticket.lastName,
      price: ticket.price.formatted,
      priceData: ticket.price,
      refundable,
      isChangeNameDisabled: ticket.isChangeNameDisabled,
      isResaleDisabled: ticket.isResaleDisabled,
      isSeasonTicket: ticket.isSeasonTicket,
      typeTicketCollectionName: ticket.typeTicketCollectionName,
      seatId: ticket.seatProgressive,
      secret: ticket.secret,
      status: ticket.status,
      statusLabel: ticket.statusLabel,
      ticketType: ticket.typeTicketDescription ? ticket.typeTicketDescription : ticket.notes,
    }
  }
}
