import React, { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { IEventDetails } from 'meta/backendControlled/structure'
import { DataDogLogType, LOGS_PURCHASE_FORM_LANDING } from 'meta/logger'
import { IPurchaseForm } from 'meta/pages/purchase'
import { ITicket } from 'meta/pages/tickets'
import { Routes } from 'meta/routes'
import { DataDogLogger } from 'helpers/debug/logger'
import { CookieClient, CookieName } from 'utils/cookie'

import withTranslation from 'components/HOC/WithTranslation'
import { useInitPurchaseForm } from 'components/Pages/PurchaseForm/hooks/useInitPurchaseForm'
import Tickets from 'components/Pages/PurchaseForm/Tickets'
import { useAuthToken } from 'hooks/useAuth'

import TicketsSkeleton from './TicketsSkeleton'

const TicketsPage = () => {
  const [searchParams] = useSearchParams()

  const authTokenFromQueryString = searchParams.get('authToken')
  const authTokenFromSession = useAuthToken()

  const authToken = authTokenFromQueryString ? authTokenFromQueryString : authTokenFromSession

  if (authTokenFromQueryString) {
    CookieClient.set(CookieName.TOKEN, authTokenFromQueryString)
  }

  const transactionCode = searchParams.get('transactionCode')
  const transactionToken = searchParams.get('transactionToken')

  const [purchaseFormData, setPurchaseFormData] = useState<IPurchaseForm>()
  const [tickets, setTickets] = useState<ITicket[]>()
  const [event, setEvent] = useState<IEventDetails>()
  const [withAuth, setWithAuth] = useState<boolean>(false)
  const [responseReceived, setResponseReceived] = useState<boolean>(false)

  useInitPurchaseForm({
    authToken,
    setEvent,
    setPurchaseFormData,
    setResponseReceived,
    setTickets,
    setWithAuth,
    transactionCode,
    transactionToken,
  })

  useEffect(() => {
    DataDogLogger(LOGS_PURCHASE_FORM_LANDING, DataDogLogType.info, {
      transactionCode: String(transactionCode),
    })
  }, [transactionCode])

  if (!transactionCode || !transactionToken) {
    return <Navigate to={Routes.Error.generatePath()} replace />
  }

  if (!responseReceived) {
    return <TicketsSkeleton />
  }

  return (
    <>
      {event && tickets ? (
        <Tickets
          transactionToken={String(transactionToken)}
          transactionCode={String(transactionCode)}
          event={event}
          missingUserInfos={Boolean(purchaseFormData?.userDataNotCompleted)}
          tickets={tickets}
        />
      ) : (
        <div style={{ minHeight: '30vh' }} />
      )}
    </>
  )
}

const namespaces = ['tickets']

export default withTranslation(namespaces)(TicketsPage)
