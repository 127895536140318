import React from 'react'

import Head from 'components/common/Head'
import { useAppleClientId, useUrlBase } from 'hooks/useEnv'

const Apple: React.FC = () => {
  const appleClientId = useAppleClientId()
  const urlBase = useUrlBase()

  return (
    <Head>
      <script type="text/javascript">{`
          AppleID.auth.init({
              clientId : '${appleClientId}',
              scope : 'name email',
              redirectURI: '${urlBase && encodeURI(urlBase)}',
              usePopup : true
          });
        `}</script>
    </Head>
  )
}

export default Apple
