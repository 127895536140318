import css from './Governance.module.scss'
import React from 'react'

import Head from 'components/common/Head'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import Img from 'components/Media/Img'
import TermsSkeleton from 'components/Pages/Terms/TermsSkeleton'
import TransparentWrapper from 'components/Structure/TransparentWrapper'

import PDFBox from './PDFBox'

const Governance: React.FC = () => {
  const { namespacesLoaded } = useI18NContext()

  const meta_title = 'Governance di TicketSms'

  return (
    <>
      <Head>
        <title>{meta_title}</title>
        <meta property="og:title" content={meta_title} />
      </Head>
      <div className={css.governance_container}>
        <TransparentWrapper>
          <WithAppInitialized skeleton={<TermsSkeleton />} showChildren={namespacesLoaded}>
            <div className={css.governance_ita_container}>
              <div className={css.static_page_title}>
                <Img src="/icons/governance-header.svg" />
                <div>Governance</div>
              </div>
              <div className={css.static_page_text}>
                <div>
                  <b>Modello di Organizzazione e Gestione ex D.Lgs 231/2001 e Codice Etico</b>
                  <br />
                  <br />

                  <div>
                    TicketSms s.r.l. ha adottato, ad Ottobre 2024, il modello di organizzazione e gestione di cui al
                    D.lgs. 231/01 (il &quot;Modello 231&quot;), in linea con i principi etici espressi nel Codice Etico
                    parimenti adottato dalla società.{' '}
                  </div>
                </div>

                <br />

                <div>
                  Il Modello 231 è volto a prevenire i reati che comportino la responsabilità per le persone giuridiche
                  nel cui interesse o a vantaggio il reato è stato commesso, come previsto dal D.Lgs. 231/2001.
                </div>

                <br />

                <div>
                  Il Modello 231 è continuamente suscettibile di ampliamento ed aggiornamento, in relazione alle nuove
                  fattispecie incluse nel perimetro di applicazione del D.Lgs. 231/2001 e in aderenza rispetto alle
                  evoluzioni della Società e delle attività di business svolte.
                </div>

                <br />

                <div>
                  Il Modello 231 adottato prevede un&apos;attività di informazione e formazione dei soggetti coinvolti
                  nei processi sensibili allo scopo di far prendere coscienza dei comportamenti vietati ed obbligatori,
                  creare consapevolezza in relazione ai comportamenti «etici» e promuovere una cultura del controllo e
                  della prevenzione nella gestione dei rischi aziendali.
                </div>

                <br />

                <div>
                  TicketSms s.r.l. si è, parimenti, dotato di un Organismo di Vigilanza che supervisiona la corretta
                  applicazione del Modello e svolge attività di controllo in maniera neutrale ed indipendente.
                </div>

                <br />
                <br />
                <br />

                <div>
                  <PDFBox
                    title="Codice etico TicketSms s.r.l"
                    filename="codice-etico.pdf"
                    link="/pdf/governance/Cod_Etico.pdf"
                  />

                  <PDFBox
                    title="Modello di Organizzazione, Gestione e Controllo ex D.Lgs 231/2001 - Parte Generale"
                    filename="MOG-parte-generale.pdf"
                    link="/pdf/governance/MOG_Parte_Generale.pdf"
                  />
                </div>
              </div>
            </div>
          </WithAppInitialized>
        </TransparentWrapper>
      </div>
    </>
  )
}

export default Governance
