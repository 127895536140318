import React from 'react'

import { CheckboxBuilder, FormBuilder, RadioBuilder, TextBuilder } from 'meta/form'
import { LoginData, LoginProvider } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'

import Form, { IFormValues } from 'components/Forms/Form'
import { useCheckoutDetails } from 'hooks/useCheckout'
import { useLoginData, useLoginStep } from 'hooks/useLogin'

import { IStepProps, Step } from '../StepCommon'

const Almost: React.FC<IStepProps> = () => {
  const dispatch = useAppDispatch()
  const data = useLoginData()
  const step = useLoginStep()

  const details = useCheckoutDetails()
  const signupActionPath = details?.insideIFrame ? ApiEndpoint.users.signupFromIframe() : ApiEndpoint.users.signup()

  const email = new TextBuilder('email')
    .addRequiredEmailValidation()
    .addValue(data.email)
    .addPlaceholder('form:email')
    .build()

  const termsAndConditions = new CheckboxBuilder('termsAndConditions')
    .addRequiredValidation()
    .addPlaceholder('form:termsAndConditions')
    .build()

  const privacyPolicy = new CheckboxBuilder('privacyPolicy')
    .addRequiredValidation()
    .addPlaceholder('form:privacyPolicy')
    .build()

  const marketingPolicy = new RadioBuilder(
    'marketingPolicy',
    'form:marketingPolicy',
    [
      { label: 'form:radio_accept', value: 'true' },
      { label: 'form:radio_reject', value: 'false' },
    ],
    true,
    'marketingPolicy'
  )
    .addRequiredValidation()
    .build()

  const profilingPolicy = new RadioBuilder(
    'profilingPolicy',
    'form:profilingPolicy',
    [
      { label: 'form:radio_accept', value: 'true' },
      { label: 'form:radio_reject', value: 'false' },
    ],
    true,
    'profilingPolicy'
  )
    .addRequiredValidation()
    .build()

  const formFields = new FormBuilder()
    .addField(email)
    .addField(termsAndConditions)
    .addField(privacyPolicy)
    .addField(marketingPolicy)
    .addField(profilingPolicy)
    .build()

  // see provider data https://github.com/TicketSms/backend-ticketsms-it/tree/develop/app/Http/DTOs/User
  const getProviderData = (values: IFormValues) => {
    const provider = data.provider || LoginProvider.TICKETSMS
    let providerData = null
    if (provider === LoginProvider.TICKETSMS) providerData = { email: values.email }
    if (provider === LoginProvider.APPLE) providerData = data.apple
    if (provider === LoginProvider.FACEBOOK) providerData = data.facebook
    if (provider === LoginProvider.GOOGLE) providerData = data.google

    return { provider, providerData }
  }

  const bodyParser = (values: IFormValues) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    phonePrefix: data.phonePrefix,
    phone: data.phone,
    birthDate: data.birthDate,
    birthPlace: data.birthPlace,
    email: values.email,
    otpToken: data.otpToken,
    termsAndConditions: values.termsAndConditions,
    privacyPolicy: values.privacyPolicy,
    marketingPolicy: values.marketingPolicy,
    profilingPolicy: values.profilingPolicy,
    ...getProviderData(values),
  })

  //@ts-expect-error values might not be set
  const onSuccess = (values, response) => {
    const { token } = response.data
    // login(token, false)
    // dispatch(authActions.login(token))

    const dataUpdate: LoginData = {
      ...data,
      email: values.email,
      otpToken: token,
      termsAndConditions: values.termsAndConditions,
      privacyPolicy: values.privacyPolicy,
    }
    dispatch(LoginActions.updateData({ data: dataUpdate, step: step + 1 }))
  }

  return (
    <>
      <Step icon="/login/step_email.svg" labelKey="registerV2:almost">
        <Form
          action={signupActionPath}
          bodyParser={bodyParser}
          formFields={formFields}
          labelSubmit="registerV2:complete_registration"
          onSuccess={onSuccess}
        />
      </Step>
    </>
  )
}

export default Almost
