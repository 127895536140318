import { ApiEndpoint } from 'controller/endpoint'
import { SeasonEventParams } from 'store/pages/checkout/slice/types'
import { HttpRequest } from 'utils/http'

type Params = {
  codeUrl: string
  typeTicketCollectionId: string
}

export const getSeasonEvents = async (params: Params): Promise<SeasonEventParams> => {
  const { codeUrl, typeTicketCollectionId } = params

  const { data } = await HttpRequest.get<SeasonEventParams>(
    ApiEndpoint.collection.seasonEventsList(codeUrl, typeTicketCollectionId)
  )

  return data
}
