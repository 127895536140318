import css from './Resale.module.scss'
import React from 'react'

import { LanguageCode } from 'meta/app/translate'

import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import ResaleSkeleton from 'components/Pages/Resale/ResaleSkeleton'
import TransparentWrapper from 'components/Structure/TransparentWrapper'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

import ResaleCat from './Ca'
import ResaleDe from './De'
import ResaleEng from './Eng'
import ResaleEs from './Es'
import ResaleFr from './Fr'
import ResaleIta from './Ita'
import ResalePT from './Pt'

const resaleComponents: Record<LanguageCode, React.FC> = {
  [LanguageCode.it]: ResaleIta,
  [LanguageCode.en]: ResaleEng,
  [LanguageCode.ca]: ResaleCat,
  [LanguageCode.es]: ResaleEs,
  [LanguageCode.fr]: ResaleFr,
  [LanguageCode.pt]: ResalePT,
  [LanguageCode.de]: ResaleDe,
}

const Resale: React.FC = () => {
  const currentLanguage = useCurrentLanguage()
  const Content = resaleComponents[currentLanguage]
  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={css.resale_container}>
      {/* @ts-ignore */}
      <TransparentWrapper>
        <WithAppInitialized skeleton={<ResaleSkeleton />} showChildren={namespacesLoaded}>
          <Content />
        </WithAppInitialized>
      </TransparentWrapper>
    </div>
  )
}

export default Resale
