import css from '../SearchFilters.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SearchParamUtils, SearchParamValue } from 'meta/pages/search'
import { useAppDispatch } from 'store'
import { SearchActions } from 'store/pages/search'

import { ActionColor, ButtonWhite } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import FiltersCity from 'components/Pages/Search/SearchForm/SearchFilters/FiltersWhere/FiltersCity'
import { useSearchParams } from 'hooks/useSearch'

interface IPropsFiltersWhere {
  closeFilters(): void
}

const FiltersWhere: React.FC<IPropsFiltersWhere> = (props) => {
  const { closeFilters } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchParams = useSearchParams()

  return (
    <div className={css.searchFilterOptions}>
      <ButtonWhite
        className={css.searchFilter}
        hover={ActionColor.grey}
        onClick={() => {
          const params = { ...searchParams, city: SearchParamValue.everywhere, geoPoint: undefined }
          dispatch(SearchActions.updateParams(params))
          closeFilters()
        }}
      >
        <div>
          <span>{t('search:everywhere')}</span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      <ButtonWhite
        className={css.searchFilter}
        hover={ActionColor.grey}
        onClick={async () => {
          const geoPoint = await SearchParamUtils.getGeoPointCurrentPosition()
          const params = { ...searchParams, city: SearchParamValue.currentposition, geoPoint }
          dispatch(SearchActions.updateParams(params))
          closeFilters()
        }}
      >
        <div>
          <span>{t('search:currentposition')}</span>
        </div>
        <Img src="/icons/point_selection.svg" />
      </ButtonWhite>

      <FiltersCity closeFilters={closeFilters} />
    </div>
  )
}

export default FiltersWhere
