import css from '../Form.module.scss'
import React from 'react'

import { FormFieldType, IFormField } from 'meta/form'

import FormField from '../FormField'

interface Props {
  formFields: Array<IFormField<FormFieldType>>
}

const FormFields: React.FC<Props> = (props) => {
  const { formFields } = props

  return (
    <>
      {formFields.map((formField: IFormField<FormFieldType>, idx: number) => {
        const { fieldLabel } = formField
        if (fieldLabel) {
          return (
            <div key={String(idx)} className={css.form__fieldWithLabel}>
              <div className={css.form__fieldLabel}>{fieldLabel}</div>
              <FormField formField={formField} />
            </div>
          )
        }

        return <FormField key={String(idx)} formField={formField} />
      })}
    </>
  )
}

export default FormFields
