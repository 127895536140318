import css from './TransparentWrapper.module.scss'
import React, { PropsWithChildren } from 'react'

interface Props {
  padding?: boolean
  removeRightPadding?: boolean
}

const TransparentWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, padding, removeRightPadding } = props

  return (
    <div className={`${css.home_transparentwrapper_container} ${padding ? css.with_padding : ''}`}>
      <div className={css.boxed}>
        <div className={`${css.wrapped_content} ${removeRightPadding ? css.remove_right_padding : ''}`}>{children}</div>
      </div>
    </div>
  )
}

TransparentWrapper.defaultProps = {
  padding: true,
  removeRightPadding: false,
}

export default TransparentWrapper
