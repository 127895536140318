import css from './Square.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { SquareSkeleton as SquareSkeletonType } from 'meta/backendControlled/structure/square'
import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'

export const SquareSkeleton: React.FC<IPropsComponent<SquareSkeletonType>> = () => {
  return (
    <GridColumn
      gridColumnSpan={{ [Breakpoints.laptop]: 3, [Breakpoints.tablet]: 4, [Breakpoints.mobile]: 8 }}
      className={css.skeletonContainer}
    >
      <div className={css.skeletonCardContainer}>
        <div className={classNames(css.image_skeleton, css.skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>

        <div className={css.squareContainerDetails}>
          <div>
            <div className={classNames(css.date, css.skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'50%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>

            <h5 className={css.name}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'40%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={4}
                height={'40%'}
                width={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </h5>

            <div className={css.location_container}>
              <div className={classNames(css.location_text, css.skeleton)}>
                <Skeleton
                  baseColor={TICKETSMS_COLORS_LIGHTGREY}
                  borderRadius={4}
                  height={'80%'}
                  highlightColor={TICKETSMS_COLORS_WHITE}
                />
              </div>
            </div>
          </div>

          <div className={classNames(css.buttonBuyContainer, css.skeleton)}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
        </div>
      </div>
    </GridColumn>
  )
}
