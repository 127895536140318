import css from './Desktop.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Breakpoints } from 'meta/layout/breakpoints'
import { CollectionPerformer } from 'meta/pages/collection'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

interface Props {
  data: CollectionPerformer[]
}

const MAX_SHOWN = 5

const ARTIST_ICON_SIZE = 64

const Desktop: React.FC<Props> = (props) => {
  const { data } = props

  const { t } = useTranslation()

  return (
    <div className={css.multipleArtistDesktopContainer}>
      <Grid>
        <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 1 }} gridColumnEnd={{ [Breakpoints.laptop]: 13 }}>
          <div className={css.leftSide}>
            <div
              style={{ 'minWidth': (data.slice(0, MAX_SHOWN).length - 1) * ARTIST_ICON_SIZE }}
              className={css.imgContainer}
            >
              {data.slice(0, MAX_SHOWN).map((elem, key) => (
                <img
                  style={{
                    'left': Number(key) * ARTIST_ICON_SIZE - Number(key) * 16,
                    'top': 0,
                    'zIndex': MAX_SHOWN - Number(key),
                  }}
                  key={key}
                  src={elem.coverImages[0].url}
                  className={css.image}
                />
              ))}
            </div>
            <div className={css.textContainer}>
              <span>
                {data
                  .slice(0, MAX_SHOWN)
                  .map((elem) => elem.name)
                  .join(' • ')
                  .substring(0, 100)
                  .concat('...')}
              </span>
            </div>
          </div>
        </GridColumn>
        {data.length > MAX_SHOWN && (
          <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 14 }} gridColumnEnd={{ [Breakpoints.laptop]: 16 }}>
            <div className={css.action}>
              <ButtonWhiteBlack className={css.button} onClick={() => {}}>
                <h5>{t('collection:other_artists', { num: data.length - MAX_SHOWN })}</h5>
                <Img src="/icons/arrow_right.svg" />
              </ButtonWhiteBlack>
            </div>
          </GridColumn>
        )}
      </Grid>
    </div>
  )
}

export default Desktop
