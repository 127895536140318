import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import css from './OpenStreetMaps.module.scss'
import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import { useGoogleMapsApiKey } from 'hooks/useEnv'

import 'leaflet-defaulticon-compatibility'

interface IProps {
  lat: number
  long: number
  mapsPlaceId: string
  locationName?: string
}

const OpenStreetMaps: React.FC<IProps> = (props) => {
  const { lat, long: lng, mapsPlaceId } = props
  const key = useGoogleMapsApiKey()

  if (key === '') return null

  return (
    <GridContainer>
      <Grid>
        <GridColumn className={css.googleMapContainer} gridColumnEnd={17}>
          <MapContainer
            center={[lat, lng]}
            zoom={12}
            scrollWheelZoom={true}
            style={{ height: '100%', width: '100%', filter: 'saturate(0)' }}
          >
            <TileLayer
              // @ts-ignore
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[lat, lng]}></Marker>
          </MapContainer>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default OpenStreetMaps
