import React from 'react'

import { ComponentType, IComponent } from 'meta/backendControlled/structure'

import Component from '../Component'

interface IPropsComponentList {
  components: Array<IComponent<ComponentType>>
}

const ComponentList: React.FC<IPropsComponentList> = (props) => {
  const { components } = props

  return (
    <>
      {components &&
        components.map((component: IComponent<ComponentType>, idx: number) => (
          <Component key={String(idx)} {...component} />
        ))}
    </>
  )
}

export default ComponentList
