import { analytics } from './analytics'
import { checkout } from './checkout'
import { collection } from './collection'
import { event } from './event'
import { home } from './home'
import { payments } from './payments'
import { promoter } from './promoter'
import { purchase } from './purchase'
import { search } from './search'
import { servertoserver } from './servertoserver'
import { support } from './support'
import { users } from './users'
import { venue } from './venue'

export const ApiEndpoint = {
  analytics,
  collection,
  checkout,
  event,
  home,
  payments,
  promoter,
  purchase,
  search,
  servertoserver,
  support,
  users,
  venue,
}
