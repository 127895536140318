import css from './AvatarBox.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Img from 'components/Media/Img'
import { useUserData } from 'hooks/useAuth'

const AvatarBox: React.FC = () => {
  const { t } = useTranslation()

  const userData = useUserData()

  return (
    <div className={css.auth_profile_menu_header_avatarbox_container}>
      <Img src="/profile/avatar.svg" />
      <div className={css.hello}>{`${String(t('profile:hello'))} ${userData?.firstName ?? ''}!`}</div>
    </div>
  )
}

export default AvatarBox
