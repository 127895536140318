import css from './EmailConfirm.module.scss'
import React, { useState } from 'react'

import { LoginProvider } from 'meta/pages/login'
import { ApiEndpoint } from 'controller/endpoint'

// import { useLoginData } from '../../../../../store/pages/login'
import Form from 'components/Forms/Form'
import { useLoginData } from 'hooks/useLogin'

import { IStepProps, Step } from '../StepCommon'
import RadioButton from './RadioButton'

const EmailConfirm: React.FC<IStepProps> = (props) => {
  const { onSuccess } = props

  const data = useLoginData()
  const [email, setEmail] = useState<string>(data.email)

  const bodyParser = () => {
    const provider = data.provider
    let providerData = null
    if (provider === LoginProvider.APPLE) providerData = data.apple
    if (provider === LoginProvider.FACEBOOK) providerData = data.facebook
    if (provider === LoginProvider.GOOGLE) providerData = data.google
    return {
      email,
      otpToken: data.otpToken,
      phone: data.phone,
      phonePrefix: data.phonePrefix,
      provider,
      providerData: { ...providerData, email },
    }
  }

  return (
    <>
      <Step icon="/login/step_emailconfirm.svg" labelKey="loginV2:email_select">
        <div className={css.emailConfirm}>
          {[data.email, data.existingEmail].map((option, i) => (
            <RadioButton
              key={String(i)}
              email={String(option)}
              onClick={() => setEmail(String(option))}
              selected={email === option}
            />
          ))}
        </div>
        <Form
          action={ApiEndpoint.users.signupSocial()}
          bodyParser={bodyParser}
          formFields={[]}
          labelSubmit="registerV2:complete_registration"
          onSuccess={(values, response) => {
            const { token } = response.data
            onSuccess(token)
          }}
        />
      </Step>
    </>
  )
}

export default EmailConfirm
