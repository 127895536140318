import { FormFieldType, IFormField, IFormFieldValidation } from './formField'

type PropValue = string | object | number | Date | boolean | FormFieldType | IFormFieldValidation
type Props = { [key: string]: PropValue }

export abstract class FormFieldBuilder<T extends FormFieldType> {
  props: Props = {}
  nameValidation = null

  protected constructor(name: string, type: T, nameValidation?: string) {
    this.addField('name', name)
    this.addField('type', type)
    //@ts-ignore
    this.nameValidation = nameValidation || name
  }

  protected addField(key: string, value: PropValue): FormFieldBuilder<T> {
    this.props[key] = value
    return this
  }

  addPlaceholder(placeholder: string): FormFieldBuilder<T> {
    return this.addField('placeholder', placeholder)
  }

  addValidation(validation: IFormFieldValidation): FormFieldBuilder<T> {
    return this.addField('validation', validation)
  }

  addRequiredValidation(labelKey?: string): FormFieldBuilder<T> {
    const validation = { required: labelKey || `form:errors_required_${this.nameValidation}` }
    return this.addValidation(validation)
  }

  addValue(value: string | number | Date | boolean): FormFieldBuilder<T> {
    return this.addField('value', value)
  }

  addLabel(value: string): FormFieldBuilder<T> {
    this.props['fieldLabel'] = value
    return this
  }

  removeFutureDates(): FormFieldBuilder<T> {
    return this.addField('removeFutureDates', true)
  }

  disable(): FormFieldBuilder<T> {
    return this.addField('disable', true)
  }

  addMaxLength(value: number): FormFieldBuilder<T> {
    return this.addField('maxLength', value)
  }

  public build(): IFormField<T> {
    return { ...this.props } as unknown as IFormField<T>
  }
}
