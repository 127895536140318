import css from '../Asset.module.scss'
import React, { useState } from 'react'
import classNames from 'classnames'

import { IAsset } from 'meta/backendControlled/structure'

import ImgSkeleton from 'components/Media/ImgSkeleton'

import { IPropsComponent } from '../../index'

const Image: React.FC<IPropsComponent<IAsset>> = (props) => {
  const { src, size, format } = props
  const [broken, setBroken] = useState<boolean>(false)

  // const onImgLoad = (target) =>
  //     target.target.naturalWidth / target.target.naturalHeight > 1 &&
  //     setState((statePrev) => ({ ...statePrev, format: AssetFormat.square }))

  if (broken) return null

  return (
    <ImgSkeleton
      onError={() => setBroken(true)}
      alt={``}
      src={src[0].url}
      className={classNames(css.assetImageContainer, format && css[format], css[`size_${size}`])}
    />
  )
}

export default Image
