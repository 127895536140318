import React from 'react'

import { IMap, VenueMapType } from 'meta/backendControlled/structure'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import GoogleMapDynamic from 'components/BackendControlled/Component/Map/GoogleMapDynamic'
import GoogleMapsInfoBox from 'components/BackendControlled/Component/Map/GoogleMapsInfoBox'
import GoogleMapStatic from 'components/BackendControlled/Component/Map/GoogleMapStatic'
import OpenStreetMaps from 'components/BackendControlled/Component/Map/OpenStreetMaps'
import { useVenueMapsType } from 'hooks/useEnv'

const Map: React.FC<IPropsComponent<IMap>> = (props) => {
  const venueMapsType = useVenueMapsType()

  return (
    <>
      <GoogleMapsInfoBox />

      {venueMapsType === VenueMapType.openStreetMap && (
        <OpenStreetMaps
          mapsPlaceId={props.mapsPlaceId}
          lat={Number.parseFloat(props.latitude)}
          long={Number.parseFloat(props.longitude)}
        />
      )}

      {venueMapsType === VenueMapType.googleDynamic && (
        <GoogleMapDynamic lat={Number.parseFloat(props.latitude)} long={Number.parseFloat(props.longitude)} />
      )}

      {venueMapsType === VenueMapType.googleStatic && (
        <GoogleMapStatic
          mapsPlaceId={props.mapsPlaceId}
          lat={Number.parseFloat(props.latitude)}
          long={Number.parseFloat(props.longitude)}
        />
      )}
    </>
  )
}

export default Map
