import css from './Logout.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import DataTestId from 'meta/dataTestId'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { AuthActions } from 'store/auth'

import Img from 'components/Media/Img'

const Logout: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const doLogout = async () => {
    navigate(Routes.Home.generatePath())
    dispatch(AuthActions.logout())
  }

  return (
    <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
      <div className={css.btnLogout} data-testid={DataTestId.profile.btnLogout} onClick={doLogout}>
        <Img src="/profile/avatar_red.svg" />
        <div className={css.text}>{t('profile:logout')}</div>
      </div>
    </MediaQuery>
  )
}

export default Logout
