import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'
import { CookieClient, CookieName } from 'utils/cookie'

import { useLoginShow } from 'hooks/useLogin'

type Returned = {
  routeAccessible: boolean
  showLogin: boolean
}

const routesReserved: Record<string, boolean> = {
  [Routes.Profile.path]: true,
  [Routes.Wallet.path]: true,
}

export const useAuthState = (): Returned => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const showLogin = useLoginShow()

  const authToken = CookieClient.get(CookieName.TOKEN)
  const routeReserved = Boolean(routesReserved[pathname.split('/')[1]])
  const routeAccessible = Boolean(authToken) || !routeReserved

  // show login when navigating to a protected route and user is not loggedin
  useEffect(() => {
    if (!routeAccessible && !showLogin) {
      dispatch(LoginActions.show({ pathname }))
    }
  }, [dispatch, pathname, routeAccessible, showLogin])

  return { showLogin, routeAccessible }
}
