import { MutableRefObject, useEffect } from 'react'

import { StoryProps } from '../props'
import { Play } from './usePlay'
import { PlayActions } from './usePlayActions'
import { getTouchCoordinates } from './utils'

export const usePlayEvents = (
  props: StoryProps,
  idxActive: number,
  play: Play,
  playActions: PlayActions,
  videoRef: MutableRefObject<HTMLVideoElement>
): void => {
  const { active, playNextStory, playPrevStory, onClick } = props

  // play/pause event handlers
  useEffect(() => {
    play.pauseSubscription$.current = play.pauseEvent$.current.subscribe((e: MouseEvent | TouchEvent) => {
      e.preventDefault()
      play.clickStart.current = new Date()
      play.touchStart.current = getTouchCoordinates(e)
      play.interval.current = play.interval.current - play.timeElapsed.current
      if (play.playingVideo.current) videoRef.current.pause()
    })

    play.playSubscription$.current = play.playEvent$.current.subscribe((e: MouseEvent | TouchEvent) => {
      e.preventDefault()

      if (play.clickStart.current) {
        const clickTimeDiff = new Date().getTime() - play.clickStart.current.getTime()
        if (clickTimeDiff <= 500) {
          if (active) {
            // check whether it's a swipe left/right
            const touchEnd = getTouchCoordinates(e)
            const touchDiffX = touchEnd?.x - play.touchStart.current?.x
            const touchDiffY = touchEnd?.y - play.touchStart.current?.y

            if (Math.abs(touchDiffX) > 10 && Math.abs(touchDiffY) <= 40) {
              // swipe right
              if (touchDiffX > 0) playPrevStory()
              // swipe left
              else playNextStory()
            } else {
              const clientX = touchEnd?.x || (e as MouseEvent).clientX
              if (clientX < window.innerWidth / 2) playActions.playPrevItem()
              else playActions.playNextItem()
            }
          } else {
            onClick()
          }
        } else {
          if (play.playingVideo.current) videoRef.current.play()
        }
      }
    })
  }, [active, idxActive])
}
