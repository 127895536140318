import css from './VoucherBox.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import { FormBuilder, TextBuilder } from 'meta/form'
import { CouponCodeStatus, ICheckoutPayment, PaymentMethod } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Form from 'components/Forms/Form'
import Img from 'components/Media/Img'
import BalloonBoxModal from 'components/TextBoxes/BalloonBoxModal'
import { useCheckoutCartTickets, useCheckoutPayment } from 'hooks/useCheckout'

interface IProps {
  goBackCallback(): void
  confirmCallback(couponCode: string): void
}

const VoucherBox: React.FC<IProps> = (props) => {
  const { goBackCallback, confirmCallback } = props
  const cartTickets = useCheckoutCartTickets()
  const dispatch = useAppDispatch()
  const payment = useCheckoutPayment() as ICheckoutPayment

  const { t } = useTranslation()
  const [showBalloon, setShowBalloon] = useState<boolean>(false)

  const { methodCurrent, couponCode, couponCodeStatus, couponCodeError } = payment

  const formFields = new FormBuilder()
    .addField(
      new TextBuilder('voucher')
        .addRequiredVoucherValidation()
        .addValue(couponCode as string)
        .addPlaceholder('form:voucher_insert_code')
        .build()
    )
    .build()

  useEffect(() => {
    if (couponCodeStatus === CouponCodeStatus.valid) {
      confirmCallback(couponCode as string)
    }
  }, [couponCodeStatus])

  useEffect(() => {
    if (
      cartTickets &&
      cartTickets.length > 0 &&
      couponCode &&
      couponCodeError !== '' &&
      couponCodeError?.includes('18App')
    ) {
      setShowBalloon(true)
    }
  }, [couponCodeStatus])

  useEffect(() => {
    const paymentUpdated = { ...payment, couponCode: undefined }
    dispatch(CheckoutActions.updatePayment({ payment: paymentUpdated }))
  }, [])

  return (
    <div className={css.voucherbox_container}>
      <div className={css.header_wrapper}>
        <div className={css.goBackWrapper} onClick={goBackCallback}>
          <div className={css.goBack}>
            <Img alt="" src="/arrows/white-left.svg" />
          </div>
          {methodCurrent === PaymentMethod.voucher && <h3>{t('checkout:voucher')}</h3>}
          {methodCurrent === PaymentMethod.diciottoApp && <h3>{t('checkout:diciotto_app')}</h3>}
          {methodCurrent === PaymentMethod.cartaMeritoCultura && <h3>{t('checkout:carta_merito_cultura')}</h3>}
        </div>
      </div>

      {cartTickets && cartTickets.length > 0 && (
        <Form
          action={undefined}
          formFields={formFields}
          labelSubmit="checkout:voucher_confirm"
          onSuccess={(values) => {
            const paymentUpdated = { ...payment, couponCode: values?.voucher }
            dispatch(CheckoutActions.updatePayment({ payment: paymentUpdated }))

            if (methodCurrent === PaymentMethod.voucher) {
              dispatch(CheckoutActions.fetchRecap())
            } else {
              confirmCallback(values?.voucher)
            }
          }}
        />
      )}

      {}

      {cartTickets && cartTickets.length === 0 && (
        <h4 className={css.no_tickets_selected}>{t('checkout:voucher_no_tickets_selected')}</h4>
      )}

      {cartTickets &&
        cartTickets.length > 0 &&
        couponCode &&
        couponCodeError !== '' &&
        !couponCodeError?.includes('ajax') &&
        !couponCodeError?.includes('18App') && (
          <div className={css.invalid_voucher_error}>{`* ${couponCodeError}`}</div>
        )}

      {showBalloon && (
        <BalloonBoxModal
          bgColor={ComponentColor.redLight}
          onClose={() => {
            setShowBalloon(false)
          }}
          infoType={'error'}
          title={`${t('common:error')}!`}
        >
          <div>{couponCodeError}</div>
        </BalloonBoxModal>
      )}
    </div>
  )
}

export default VoucherBox
