import { ComponentSize, ComponentType, IComponent } from './component'

export enum AssetType {
  image = 'image',
  video = 'video',
}

export enum AssetFormat {
  cover = 'cover',
  gallery = 'gallery',
  logo = 'logo',
  square = 'square',
  story = 'story',
}

export interface IAssetSource extends IComponent<ComponentType.assetSource> {
  mimeType: string
  url: string
}

export interface IAsset extends IComponent<ComponentType.asset> {
  name?: string
  format?: AssetFormat
  size?: ComponentSize
  src: Array<IAssetSource>
  type: AssetType
}

export interface AssetSkeleton extends IComponent<ComponentType.assetSkeleton> {
  name?: string
  format?: AssetFormat
  size?: ComponentSize
  src: Array<IAssetSource>
  type: AssetType
}

export interface IAssets {
  covers?: Array<IAsset>
  gallery?: Array<IAsset>
}
