import css from './EventDetails.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IEventDetails } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'
import { Breakpoints } from 'meta/layout/breakpoints'
import { Dates } from 'utils/dates'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

interface IPropsEventDetails {
  event: IEventDetails
}

const EventDetails: React.FC<IPropsEventDetails> = (props) => {
  const { event } = props
  const { coverImg, datetime, location, name, eventCollectionName, numEvents } = event

  const { t } = useTranslation()

  const urlCloudfront = useUrlCloudfront()

  const imageUrl = coverImg || `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`
  const date = Dates.format(new Date(datetime), t('dates:common:longDate'))
  const time = Dates.format(new Date(datetime), 'h:mm aa')

  return (
    <GridColumn
      className={css.eventDetails}
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 4 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 14, [Breakpoints.laptop]: 14 }}
    >
      <img
        alt={event.name}
        className={classNames(css.eventDetails__eventImg, { [css.withBorder]: !coverImg })}
        src={imageUrl}
      />

      <div className={css.eventDetails__eventDesc}>
        {!eventCollectionName && (
          <div>
            <strong>{date}</strong>
            {` / ${time}`}
          </div>
        )}

        {eventCollectionName && (
          <div className={css.eventCollection}>
            <span className={css.icon}>
              <Img src="/icons/checkout-season-header-icon-light.svg" />
            </span>
            <span className={css.text}>{t('tickets:seasonEvent', { num: numEvents })}</span>
          </div>
        )}

        <h2 data-testid={DataTestId.purchaseForm.eventName} className={css.eventDetails__eventName}>
          {eventCollectionName ?? name}
        </h2>

        <div className={css.eventDetails__eventLocation}>
          <Img src="/icons/location_marker.svg" />
          <div>{`${location?.name} / ${location?.common}`}</div>
        </div>
      </div>
    </GridColumn>
  )
}

export default EventDetails
