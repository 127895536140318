import css from './EventDetails.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CheckoutStep } from 'meta/pages/checkout'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'

import Desktop from 'components/Checkout/EventDetails/Desktop'
import Mobile from 'components/Checkout/EventDetails/Mobile'
import { useCheckoutStep } from 'hooks/useCheckout'

interface IProps {
  showCloseButton: boolean
}

const EventDetails: React.FC<IProps> = (props) => {
  const { showCloseButton } = props
  const dispatch = useAppDispatch()
  const step = useCheckoutStep()

  const goBackCallback = () => {
    if (step === CheckoutStep.select) dispatch(CheckoutActions.close())
    else dispatch(CheckoutActions.updateStep({ step: CheckoutStep.select }))
  }

  return (
    <div className={css.checkout_eventdetails_container}>
      <>
        <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
          <Mobile goBackCallback={goBackCallback} showCloseButton={showCloseButton} />
        </MediaQuery>

        <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
          <Desktop goBackCallback={goBackCallback} showCloseButton={showCloseButton} />
        </MediaQuery>
      </>
    </div>
  )
}

export default EventDetails
