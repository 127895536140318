import { useCallback } from 'react'

import { Dates } from 'utils/dates'

type Props = {
  datetime: Date
  toggleModal: () => void
}

export const useUtilities = (props: Props) => {
  const { datetime, toggleModal } = props

  const getColor = useCallback((endDate: Date) => {
    const oneWeekAfter = new Date()
    oneWeekAfter.setDate(oneWeekAfter.getDate() + 7)

    if (Dates.isBefore(endDate, new Date())) return 'red'

    if (Dates.isAfter(endDate, oneWeekAfter)) return 'green'

    return 'yellow'
  }, [])

  const color = getColor(datetime)

  const onClick = useCallback(() => {
    if (color !== 'red') {
      toggleModal()
    }
  }, [color, toggleModal])

  return { color, onClick }
}
