import css from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import DataTestId from 'meta/dataTestId'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_MEDIUMGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

export const DesktopSkeleton: React.FC = () => {
  return (
    <>
      <div className={css.transactionItem}>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div className={css.fcUpper}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'70%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
      <div className={css.transactionItem}>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div data-testid={DataTestId.profile.transactionPrice}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'40%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
      <div className={css.transactionItem}>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'60%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'80%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
      <div className={css.transactionItem}>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'60%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'80%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
      <div>
        <div className={css.transactionItem}>
          <div>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'60%'}
              highlightColor={TICKETSMS_COLORS_WHITE}
            />
          </div>
          <div className={css.ticketsNumber}>
            <Skeleton
              baseColor={TICKETSMS_COLORS_LIGHTGREY}
              borderRadius={16}
              height={'100%'}
              width={'30%'}
              highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
            />
          </div>
        </div>
      </div>
      <div className={css.transactionItem}>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'50%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
        <div>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={16}
            height={'100%'}
            width={'80%'}
            highlightColor={TICKETSMS_COLORS_MEDIUMGREY}
          />
        </div>
      </div>
    </>
  )
}
