import css from '../SearchResult/SearchResult.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { SquareSkeleton } from 'components/BackendControlled/Component/Square'
import { Grid, GridColumn, GridContainer, GridType } from 'components/Layout/Grid'
import { searchResultSkeleton } from 'components/Pages/Search/SearchSkeleton/searchResultSkeleton'

const SearchSkeleton: React.FC = () => {
  return (
    <GridContainer>
      <Grid type={GridType.flex}>
        <GridColumn
          className={css.searchResultTitle}
          gridColumnSpan={{ [Breakpoints.mobile]: 16, [Breakpoints.tablet]: 16, [Breakpoints.laptop]: 16 }}
        />
      </Grid>

      <Grid className={css.searchResultGridComponents} type={GridType.flex}>
        {searchResultSkeleton.map((component) => (
          <div key={component.uuid} className={css.searchResultComponentContainer}>
            <SquareSkeleton codeUrl={''} date={''} location={''} name={''} />
          </div>
        ))}
      </Grid>
    </GridContainer>
  )
}

export default SearchSkeleton
