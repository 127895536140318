import { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useGet, usePatch, usePost } from 'api'
import { FormikValues } from 'formik'
import { load } from 'recaptcha-v3'

import { RequestMethod } from 'meta/api/requestMethod'
import { CookieClient, CookieName } from 'utils/cookie'

import { useGoogleRecaptchaKey, useS2SToken } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

import { IFormOnSubmit, IFormProps } from '../types'

const tokenId = 'recaptcha-token'
const actionId = 'recaptcha-action'
const hostname = 'recaptcha-host'

const hookRequestMap = {
  [RequestMethod.get]: useGet,
  [RequestMethod.patch]: usePatch,
  [RequestMethod.post]: usePost,
}

export const useOnSubmit = (
  props: IFormProps
): { onSubmit: IFormOnSubmit; error?: Response | Error | string; response?: object } => {
  const { action, useProxy, authToken, bodyParser, onSuccess, recaptcha, requestMethod, setFormStatus } = props

  const googleRecaptchaKey = useGoogleRecaptchaKey()
  const TsmsS2SToken = useS2SToken()
  // const router = useRouter()
  //@ts-expect-error requestMethod might not be defined
  const { fetch, fetching, error, response, status } = hookRequestMap[requestMethod]()
  const bodyRef = useRef<object>(null)
  const recaptchaRef = useRef(null)

  const onSubmit: IFormOnSubmit = async (fieldValues: FormikValues): Promise<void> => {
    //@ts-expect-error bodyRef might not be defined
    bodyRef.current = bodyParser ? bodyParser(fieldValues) : fieldValues
    if (action && recaptcha) {
      //@ts-expect-error recaptchaRef might be null
      const token = await recaptchaRef.current.execute(recaptcha.action)
      //@ts-expect-error bodyRef might not be defined
      bodyRef.current = {
        ...bodyRef.current,
        [tokenId]: token,
        [actionId]: recaptcha.action,
        [hostname]: `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
      }
    }
    if (action) {
      await fetch({
        authToken,
        body: bodyRef.current,
        urlResource: action,
        useProxy: useProxy ? useProxy : null,
        extraHeaders: {
          'Tsms-S2S-Token': TsmsS2SToken,
        },
      })
    } else {
      onSuccess(bodyRef.current, {})
    }
  }

  useEffect(() => {
    if (recaptcha && googleRecaptchaKey) {
      ;(async () => {
        //@ts-expect-error recaptchaRef might be null
        recaptchaRef.current = await load(googleRecaptchaKey)
        //@ts-expect-error recaptchaRef might be null
        if (isMobile) recaptchaRef.current.hideBadge()
      })()
    }
  }, [googleRecaptchaKey, recaptcha])

  useOnUpdate(() => {
    response && onSuccess(bodyRef.current as FormikValues, response)
  }, [response])

  useOnUpdate(() => {
    if (authToken && status === 401) {
      CookieClient.remove(CookieName.TOKEN)
      // router.push(AppRoute.Profile.default)
    }
  }, [status])

  useOnUpdate(() => {
    if (setFormStatus) {
      setFormStatus({ fetching, error, response, status })
    }
  }, [fetching, error, response, status])

  return { onSubmit, error, response }
}
