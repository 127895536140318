import React, { MutableRefObject } from 'react'

import { useFormContext } from '../hooks'
import { IFormContext } from '../types'

type Props = {
  formContextRef: MutableRefObject<IFormContext<any>>
}

const FormContext: React.FC<Props> = (props) => {
  const { formContextRef } = props

  formContextRef.current = useFormContext()

  return null
}

export default FormContext
