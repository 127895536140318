import css from './Transaction.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'

import {
  DataDogLogType,
  LOGS_STRIPE_ERROR_CONFIRM,
  LOGS_STRIPE_ERROR_NO_CLIENT_SECRET,
  LOGS_STRIPE_SUCCESS_BEFORE_REDIRECT,
  LOGS_TRANSACTION_START,
} from 'meta/logger'
import {
  CheckoutAuthType,
  CheckoutStep,
  ICheckoutDetails,
  ICheckoutStripeData,
  PaymentGateway,
} from 'meta/pages/checkout'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { DataDogLogger } from 'helpers/debug/logger'

import { ButtonWhiteBlack, ButtonWhiteRed } from 'components/Actions/Action'
import Loader from 'components/Checkout/Steps/Loader'
import { addAuthTokenToRoutePath } from 'components/Checkout/Steps/utils'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useAuthToken, useUserData } from 'hooks/useAuth'
import { useCheckoutAuth, useCheckoutDetails, useCheckoutPayment, useCheckoutStripe } from 'hooks/useCheckout'
import { useUrlBase } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

const Transaction: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userData = useUserData()
  const authToken = useAuthToken()
  const details = useCheckoutDetails() as ICheckoutDetails
  const payment = useCheckoutPayment()
  const urlBase = useUrlBase()
  const { paymentMethod, paymentRequest } = useCheckoutStripe() as ICheckoutStripeData
  const auth = useCheckoutAuth()
  const stripe = useStripe()

  const [appleConfirm, setAppleConfirm] = useState<boolean>(false)
  const [showPopupMsg, setShowPopupMsg] = useState<boolean>(false)
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const transactionStartedRef = useRef<boolean>(false)
  const submitBtnRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    DataDogLogger(LOGS_TRANSACTION_START, DataDogLogType.info, {
      transactionCode: details.transactionCode,
      paymentGateway: payment?.gateway,
    })
  }, [])

  useEffect(() => {
    // if auth.type=login, startTransaction be dispatched after userDetails have been fetched in order to get user email from store
    if (
      (auth?.type !== CheckoutAuthType.login || userData) &&
      !details.transactionStarted &&
      !transactionStartedRef.current
    ) {
      // Important: transactionStartedRef is needed to avoid double startTransaction invocation in dev when strict mode is enabled. see more at https://react.dev/reference/react/StrictMode
      transactionStartedRef.current = true
      dispatch(CheckoutActions.startTransaction())
    }
  }, [auth?.type, details.transactionStarted, dispatch, userData])

  const handleRedirect = (checkoutProcessUrl: string) => {
    if (details.insideIFrame) {
      setShowPopupMsg(true)

      setRedirectUrl(
        userData
          ? `${urlBase}${addAuthTokenToRoutePath(checkoutProcessUrl, authToken)}`
          : `${urlBase}${checkoutProcessUrl}`
      )

      setTimeout(() => {
        submitBtnRef.current?.click()
      }, 2000)
    } else {
      navigate(checkoutProcessUrl)
      dispatch(CheckoutActions.close())
    }
  }

  const stripeSuccess = (clientSecret: string): void => {
    DataDogLogger(LOGS_STRIPE_SUCCESS_BEFORE_REDIRECT, DataDogLogType.info, {
      transactionCode: details.transactionCode,
      paymentGateway: payment?.gateway,
    })

    const checkoutProcessUrl = Routes.CheckoutProcess.generatePath(undefined, {
      transactionCode: details.transactionCode as string,
      paymentGateway: payment?.gateway as string,
      clientSecret,
    })

    handleRedirect(checkoutProcessUrl)
  }

  useOnUpdate(() => {
    if (payment && payment.gateway) {
      const { couponCode, gateway } = payment

      if (
        [
          PaymentGateway.voucher,
          PaymentGateway.diciottoApp,
          PaymentGateway.list,
          PaymentGateway.cartaMeritoCultura,
        ].includes(gateway)
      ) {
        const { transactionCode } = details
        const checkoutProcessUrl = Routes.CheckoutProcess.generatePath(undefined, {
          transactionCode: transactionCode as string,
          paymentGateway: gateway as string,
          code: couponCode,
        })
        handleRedirect(checkoutProcessUrl)
      } else if ([PaymentGateway.scalaPay, PaymentGateway.payPal, PaymentGateway.virtualPay].includes(gateway)) {
        dispatch(CheckoutActions.updateStep({ step: CheckoutStep.external }))
      } else if ([PaymentGateway.satisPay].includes(gateway)) {
        dispatch(CheckoutActions.updateStep({ step: CheckoutStep.satispay }))
      } else {
        const clientSecret = payment.gatewayData?.['clientSecret']

        if (clientSecret) {
          if (paymentRequest) {
            // apple pay
            ;(async () => {
              setAppleConfirm(true)
              // https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#react-complete-payment
              paymentRequest.on('paymentmethod', async (event: PaymentRequestPaymentMethodEvent) => {
                const { paymentIntent, error: confirmError } = await stripe!.confirmCardPayment(
                  clientSecret,
                  { payment_method: event.paymentMethod.id },
                  { handleActions: false }
                )
                if (confirmError) {
                  event.complete('fail')
                } else {
                  event.complete('success')
                  // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
                  if (paymentIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe!.confirmCardPayment(clientSecret)

                    if (error) {
                      // The payment failed -- ask your customer for a new payment method.
                      DataDogLogger(LOGS_STRIPE_ERROR_CONFIRM, DataDogLogType.info, {
                        transactionCode: details.transactionCode,
                        paymentGateway: payment.gateway,
                        errorMsg: error.message,
                      })

                      dispatch(CheckoutActions.setTransactionErrorMessage({ errorMessage: error.message as string }))
                    } else {
                      // The payment has succeeded.
                      stripeSuccess(clientSecret)
                    }
                  } else {
                    // The payment has succeeded.
                    stripeSuccess(clientSecret)
                  }
                }
              })
            })()
          } else {
            // credit card
            stripe!
              .confirmCardPayment(clientSecret, {
                payment_method: paymentMethod,
              })
              .then(function (result) {
                if (result.error) {
                  DataDogLogger(LOGS_STRIPE_ERROR_NO_CLIENT_SECRET, DataDogLogType.info, {
                    transactionCode: details.transactionCode,
                    paymentGateway: payment.gateway,
                    errorMsg: result.error.message,
                  })

                  dispatch(CheckoutActions.setTransactionErrorMessage({ errorMessage: result.error.message as string }))
                } else {
                  stripeSuccess(clientSecret)
                }
              })
          }
        }
      }
    }
  }, [payment])

  return appleConfirm ? (
    <GridContainer style={{ alignSelf: 'center' }}>
      <Grid>
        <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.checkout__applepayLabel}>
          {t('checkout:confirm_applepay')}
        </GridColumn>
        <GridColumn gridColumnStart={1} gridColumnEnd={17} className={css.checkout__applepay}>
          <ButtonWhiteBlack onClick={() => dispatch(CheckoutActions.close())}>
            <h5>{t('checkout:code_undo_button')}</h5>
          </ButtonWhiteBlack>
          <ButtonWhiteRed onClick={() => paymentRequest?.show()}>
            <h5>{t('checkout:stripe_confirm_cc')}</h5>
          </ButtonWhiteRed>
        </GridColumn>
      </Grid>
    </GridContainer>
  ) : (
    <Loader>
      <div className={css.checkout_steps_transaction_container}>
        {showPopupMsg && (
          <div className={css.enable_popup_warning}>
            <div>{t('checkout:enable_popup_warning')}</div>
            <Img src="/icons/new_popup_icon.png" />
          </div>
        )}
        <div className={css.form}>
          <a target={details.insideIFrame ? '_parent' : '_self'} ref={submitBtnRef} href={redirectUrl}></a>
        </div>
      </div>
    </Loader>
  )
}

export default Transaction
