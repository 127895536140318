import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ICheckoutSeasonItem } from 'meta/pages/checkout'

import Desktop from './Desktop'
import Mobile from './Mobile'

interface Props {
  data: ICheckoutSeasonItem[]
}

const Items: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
        <Mobile data={data} />
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
        <Desktop data={data} />
      </MediaQuery>
    </>
  )
}

export default Items
