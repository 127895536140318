import css from './WalletTicketMobile.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGet } from 'api'
import classNames from 'classnames'
import { EffectCoverflow } from 'swiper/modules'
// import SwiperCore, { EffectCoverflow, Swiper as SwiperClass } from 'swiper/'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

// import { SwiperClass } from 'swiper/swiper-react'
import { Breakpoints } from 'meta/layout/breakpoints'
import { IWalletSeasonTicket, IWalletTicket, IWalletUserTicket } from 'meta/pages/wallet'
import { ApiEndpoint } from 'controller/endpoint'
import { Dates } from 'utils/dates'

import { Grid, GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import TicketCard from 'components/Pages/Wallet/WalletTicketMobile/TicketCard'
import { useAuthToken } from 'hooks/useAuth'
import { useUrlCloudfront } from 'hooks/useEnv'
import { useOnUpdate } from 'hooks/useOnUpdate'

import TicketCardSkeleton from './TicketCardSkeleton'
import TicketsProgress from './TicketsProgress'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

type Props = {
  onClick: (eventId: number) => void
  eventActive: number
  walletTicket: IWalletTicket
}

const WalletTicketMobile: React.FC<Props> = (props) => {
  const { onClick, eventActive, walletTicket } = props
  const { event, location, userTicketsNumber } = walletTicket

  const { t } = useTranslation()

  const { fetch, response } = useGet()

  const urlCloudfront = useUrlCloudfront()

  // SwiperCore.use([EffectCoverflow])

  const [activeCard, setActiveCard] = useState<number>(0)
  const [showSwiper, setShowSwiper] = useState<boolean>(false)

  const swiperClassRef = useRef<SwiperClass>(null)

  const authToken = useAuthToken()

  const [userTickets, setUserTickets] = useState<Array<IWalletUserTicket & IWalletSeasonTicket>>([])
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const selected = eventActive === walletTicket.event.id
  const hidden = eventActive !== null && eventActive !== walletTicket.event.id
  const backgroundImage =
    event.eventAssets.length > 0
      ? event.eventAssets[0].src[0].url
      : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  useOnUpdate(() => {
    setShowSwiper(selected)
  }, [eventActive])

  useOnUpdate(() => {
    if (!showSwiper) setActiveCard(0)
  }, [showSwiper])

  useEffect(() => {
    if (showSwiper) {
      setShowLoader(true)
    }
  }, [showSwiper])

  useEffect(() => {
    if (showLoader) {
      fetch({
        authToken: authToken,
        urlResource: ApiEndpoint.users.walletEventData(event.codeUrl),
      })
    }
  }, [showLoader])

  useOnUpdate(() => {
    if (response) {
      setShowLoader(false)
      setUserTickets(response.data)
    }
  }, [response])

  const date = new Date(event.dateEvent_tz)

  return (
    <>
      <Grid
        className={classNames(css.walletTicket, {
          [css.selected]: selected,
          [css.hidden]: hidden,
        })}
        onClick={() => {
          if (!hidden) onClick(walletTicket.event.id)
        }}
      >
        <GridColumn
          gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 3 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 5 }}
        >
          <img className={css.walletTicket__img} src={backgroundImage} />
        </GridColumn>

        <GridColumn
          className={css.walletTicket__details}
          gridColumnStart={{ [Breakpoints.mobile]: 5 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 13 }}
        >
          {!event.isEventCollection && <div className={css.date}>{Dates.format(date, t('dates:common:square'))}</div>}

          {event.isEventCollection && (
            <div className={css.walletTicket__eventCollection}>
              <span className={css.icon}>
                <Img src="/icons/checkout-season-header-icon.svg" />
              </span>
              <span className={css.text}>{t('wallet:seasonEvent')}</span>
            </div>
          )}

          <h5 className={css.name}>{event.name}</h5>

          <div className={css.location_container}>
            <Img src="/icons/location_marker.svg" />
            <div className={css.location_text}>{`${location.name} / ${location.city}`}</div>
          </div>
        </GridColumn>

        <GridColumn
          className={css.walletTicket__tickets}
          gridColumnStart={{ [Breakpoints.mobile]: 13 }}
          gridColumnEnd={{ [Breakpoints.mobile]: 17 }}
        >
          <div className={classNames(css.walletTicket__ticketsImg, { [css.shown]: !selected })}>
            <Img src={`/wallet/tickets_${userTicketsNumber >= 3 ? 3 : userTicketsNumber}.svg`} />
            <h5 className={css.count}>{userTicketsNumber}</h5>
          </div>
          <div className={classNames(css.walletTicket__ticketsImg, { [css.shown]: selected })}>
            <Img src={`/wallet/tickets_back.svg`} />
          </div>
        </GridColumn>
      </Grid>

      {showSwiper && (
        <>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 10,
              depth: 300,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[EffectCoverflow]}
            pagination={false}
            className={css.walletTicket__ticketCards}
            onSlideChange={(swiper) => setActiveCard(swiper.activeIndex)}
            onAfterInit={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              swiperClassRef.current = swiper
            }}
          >
            {!showLoader &&
              userTickets.length > 0 &&
              userTickets.map((userTicket) => (
                <SwiperSlide key={userTicket.id}>
                  <TicketCard event={event} location={location} userTicket={userTicket} />
                </SwiperSlide>
              ))}
            {showLoader && (
              <>
                <SwiperSlide>
                  <TicketCardSkeleton />
                </SwiperSlide>
                {userTicketsNumber > 1 && (
                  <SwiperSlide>
                    <TicketCardSkeleton />
                  </SwiperSlide>
                )}
              </>
            )}
          </Swiper>
          {!showLoader && userTickets.length > 0 && (
            <TicketsProgress
              activeCard={activeCard}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              setActiveCard={(cardNumber: number) => swiperClassRef.current.slideTo(cardNumber)}
              userTickets={userTickets}
            />
          )}
        </>
      )}
    </>
  )
}

export default WalletTicketMobile
