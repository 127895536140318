import React, { memo } from 'react'

import { Languages } from 'meta/app/translate'

import HeadCommon from 'components/common/Head'
import { useTitle } from 'components/Layout/Head/hooks/useTitle'
import MetaI18N from 'components/Layout/Head/MetaI18N'
import Apple from 'components/Layout/Head/Scripts/Apple'
import Chat from 'components/Layout/Head/Scripts/Chat'
import DataDog from 'components/Layout/Head/Scripts/DataDog'
import Facebook from 'components/Layout/Head/Scripts/Facebook'
import Iubenda from 'components/Layout/Head/Scripts/Iubenda'
import Satispay from 'components/Layout/Head/Scripts/Satispay'
import TikTok from 'components/Layout/Head/Scripts/TikTok'
import SmartLook from 'components/Layout/Head/SmartLook'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'
import { useFacebookDomainVerification, useTikTokPixelId } from 'hooks/useEnv'

import { useDisableCookieBanner } from './hooks/useDisableCookieBanner'
import { useInitFromCookies } from './hooks/useInitFromCookies'
import { useInitReactGa4 } from './hooks/useInitReactGa4'
import { useRobotsContent } from './hooks/useRobotsContent'

const LanguageEntries = Object.entries(Languages)

const Head: React.FC = () => {
  const fbDomainVerificationKey = useFacebookDomainVerification()
  const tiktokPixelId = useTikTokPixelId()
  const language = useCurrentLanguage()
  useInitFromCookies()
  useInitReactGa4()
  const { disableCookieBanner } = useDisableCookieBanner()
  const title = useTitle()
  const robotsContent = useRobotsContent()

  const pathParts = window.location.pathname.split('/').filter((part) => part && part.trim() !== '')
  if (LanguageEntries.find(([, { iso2 }]) => iso2 === pathParts?.[0])) pathParts.splice(0, 1)
  const pathname = `/${pathParts.join('/')}`

  return (
    <>
      <HeadCommon>
        <title>{title}</title>

        {tiktokPixelId}

        <meta name="robots" content={robotsContent} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />

        <meta property="og:url" content={`${window.location.origin}/${language}${pathname}`} />
        <meta property="og:site_name" content="TicketSms" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />

        {/* FB Pixel domain verification */}
        <meta name="facebook-domain-verification" content={fbDomainVerificationKey} />

        <link rel="stylesheet" type="text/css" href="/styles/salesforce.css" />
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />

        {/*https://developers.google.com/search/docs/specialty/international/localized-versions?hl=it#html*/}
        {/* <link rel="alternate" href={`${window.location.origin}${pathname}`} hrefLang="x-default" />
        {LanguageEntries.map(([key, { iso2 }]) => (
          <link key={key} rel="alternate" hrefLang={iso2} href={`${window.location.origin}/${iso2}${pathname}`} />
        ))} */}
      </HeadCommon>
      <MetaI18N />
      <SmartLook />
      <Facebook />
      <Apple />
      <Satispay />
      <Iubenda disableCookieBanner={disableCookieBanner} />
      <TikTok />
      <DataDog />
      <Chat />
      {/*<Salesforce />*/}
    </>
  )
}

export default memo(Head)
