import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Routes } from 'meta/routes'

import LoginV2 from 'components/Auth/LoginV2'
import EventFeesDialog from 'components/BackendControlled/Component/common/EventFeesDialog'
import CheckoutConnected from 'components/Checkout/Connected'
import { ToastContainer } from 'components/common/Toast'
import WithAppInitialized from 'components/common/WithAppInitialized'
import Footer from 'components/Layout/Footer'
import Header from 'components/Layout/Header'
import { useAuthState } from 'components/Layout/hooks/useAuthState'
import { useClassName } from 'components/Layout/hooks/useClassName'
import { useLocationUpdateListener } from 'components/Layout/hooks/useLocationUpdateListener'
import { useLoginCallbacks } from 'components/Layout/hooks/useLoginCallbacks'
import { useShowOnlyContent } from 'components/Layout/hooks/useShowOnlyContent'
import { useUrlParamLangUpdateListener } from 'components/Layout/hooks/useUrlParamLangUpdateListener'
import MaintenanceWrapper from 'components/Layout/MaintenanceWrapper'

import Head from './Head'

const Layout: React.FC = () => {
  const { routeAccessible, showLogin } = useAuthState()
  const { className } = useClassName()
  const { onlyContent } = useShowOnlyContent()
  const loginCallbacks = useLoginCallbacks()
  useLocationUpdateListener()
  useUrlParamLangUpdateListener()

  if (!routeAccessible) return <Navigate replace to={Routes.Home.generatePath()} />

  return (
    <>
      <WithAppInitialized>
        <Head />
      </WithAppInitialized>
      <div className={className}>
        <ToastContainer />
        <MaintenanceWrapper>
          <>
            {!onlyContent && <Header />}
            <Outlet />
            {!onlyContent && <Footer />}
            <CheckoutConnected />
            <EventFeesDialog />
            {showLogin && <LoginV2 onComplete={loginCallbacks.onComplete} onClose={loginCallbacks.onClose} />}
          </>
        </MaintenanceWrapper>
      </div>
    </>
  )
}

export default Layout
