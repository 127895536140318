import css from './Actions.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import { Breakpoints } from 'meta/layout/breakpoints'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import { Grid, GridColumn, GridType } from 'components/Layout/Grid'

export const ActionsSkeleton: React.FC = () => {
  return (
    <>
      <GridColumn gridColumnEnd={{ [Breakpoints.tablet]: 9, [Breakpoints.mobile]: 17 }}>
        <Grid type={GridType.flex} className={classNames(css.actions_container_skeleton)}>
          <GridColumn
            gridColumnSpan={{
              [Breakpoints.mobile]: 3,
              [Breakpoints.tablet]: 2,
              [Breakpoints.laptop]: 1,
            }}
          >
            <div className={classNames(css.action_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={50}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </GridColumn>

          <GridColumn
            gridColumnSpan={{
              [Breakpoints.mobile]: 3,
              [Breakpoints.tablet]: 2,
              [Breakpoints.laptop]: 1,
            }}
          >
            <div className={classNames(css.action_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={50}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </GridColumn>

          <GridColumn
            gridColumnSpan={{
              [Breakpoints.mobile]: 3,
              [Breakpoints.tablet]: 2,
              [Breakpoints.laptop]: 1,
            }}
          >
            <div className={classNames(css.action_skeleton)}>
              <Skeleton
                baseColor={TICKETSMS_COLORS_LIGHTGREY}
                borderRadius={50}
                height={'100%'}
                highlightColor={TICKETSMS_COLORS_WHITE}
              />
            </div>
          </GridColumn>
        </Grid>
      </GridColumn>
      <GridColumn
        gridColumnStart={{ [Breakpoints.tablet]: 13, [Breakpoints.mobile]: 1 }}
        gridColumnEnd={17}
        gridRowStart={2}
      >
        <div className={classNames(css.button_skeleton)}>
          <Skeleton
            baseColor={TICKETSMS_COLORS_LIGHTGREY}
            borderRadius={50}
            height={'100%'}
            highlightColor={TICKETSMS_COLORS_WHITE}
          />
        </div>
      </GridColumn>
    </>
  )
}
