import css from './ProfileTransactions.module.scss'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DataTestId from 'meta/dataTestId'

import ProfileTranslationsSkeleton from 'components/Auth/Profile/ProfileTranslationsSkeleton'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { ProfileTransactionsSkeleton } from 'components/Pages/ProfileTransactions/ProfileTransactionsSkeleton'
import Transaction from 'components/Pages/ProfileTransactions/Transaction'
import { useTransactionData } from 'hooks/useProfile'

import useTransactions from './hooks/useTransactions'

const ProfileTransactions: React.FC = () => {
  const { t } = useTranslation()

  const sentinelRef = useRef<HTMLDivElement>(null)

  useTransactions(sentinelRef)

  const { transactions, fetching } = useTransactionData()
  const [openTransaction, setOpenTransaction] = useState<string>()

  const { namespacesLoaded } = useI18NContext()

  return (
    <div className={css.profileTransactions} data-testid={DataTestId.profile.transactions}>
      <WithAppInitialized skeleton={<ProfileTranslationsSkeleton />} showChildren={namespacesLoaded}>
        <h3 className={css.title}>{t('profile:see_transaction_details')}</h3>
      </WithAppInitialized>

      <WithAppInitialized skeleton={<ProfileTransactionsSkeleton />} showChildren={!fetching}>
        {transactions.map((elem) => {
          return (
            <Transaction
              key={elem.code}
              data={elem}
              isOpen={elem.code === openTransaction}
              toggleOpenTransactionCallback={(code: string) =>
                setOpenTransaction((prev) => (prev === code ? undefined : code))
              }
            />
          )
        })}
      </WithAppInitialized>

      <div ref={sentinelRef} />
    </div>
  )
}

export default ProfileTransactions
