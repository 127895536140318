import css from './VenueSquare.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'

import { IVenueSquare } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import ImgSkeleton from 'components/Media/ImgSkeleton'
import { useUrlCloudfront } from 'hooks/useEnv'

const VenueSquare: React.FC<IPropsComponent<IVenueSquare>> = (props) => {
  const { address, common, imageUrl, name, identification, city } = props

  const urlCloudfront = useUrlCloudfront()
  const locationUrl = identification ? `/location/${identification.name}` : `/`

  const cardImageUrl = imageUrl
    ? imageUrl
    : `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  return (
    <GridColumn
      gridColumnSpan={{ [Breakpoints.mobile]: 8, [Breakpoints.tablet]: 4, [Breakpoints.laptop]: 3 }}
      className={css.venueSquareColumn}
    >
      <Link title={`${name} - ${common} - ${city} - TicketSms`} to={locationUrl}>
        <div className={css.venueSquare}>
          <ImgSkeleton alt={name} src={cardImageUrl} className={css.venueSquare__img} />
          <div className={css.venueSquare__details}>
            <h5 className={css.venueSquare__detailsName}>{name}</h5>
            <div className={css.venueSquare__detailsLocation}>
              <Img src="/icons/location_marker.svg" />
              <div>
                <div className={css.venueSquare__detailsLocationCity}>{common}</div>
                <div className={css.venueSquare__detailsLocationAddress}>{address}</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </GridColumn>
  )
}

export default VenueSquare
