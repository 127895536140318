import { IUserData } from 'meta/auth'
import { ApiEndpoint } from 'controller/endpoint'
import { AuthParams, LoginParams } from 'store/auth/slice/types'
import { HttpRequest } from 'utils/http'

export const fetchUserData = async (params: AuthParams): Promise<LoginParams> => {
  const { token } = params

  const { data } = await HttpRequest.get<IUserData>(ApiEndpoint.users.profile(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const userData = {
    ...data,
  }

  return { token: token, userData: userData }
}
