import css from './Paginator.module.scss'
import React from 'react'
import classNames from 'classnames'

import Img from 'components/Media/Img'

import { getSubsection } from '../utils'

interface Props {
  total: number
  current: number
  setCurrent(num: number): void
}

const Paginator: React.FC<Props> = (props) => {
  const { total, current, setCurrent } = props

  const MAX_PAGES = 4

  const pagesToShow = getSubsection(
    Array.from({ length: total }, (_, index) => index),
    current,
    MAX_PAGES
  )

  const handlePageChange = (num: number) => {
    if (num !== current) {
      setCurrent(num)
    }
  }

  return (
    <div className={css.checkout_season_items_paginator_container}>
      {total >= MAX_PAGES && (
        <div
          onClick={() => current > 0 && handlePageChange(current - 1)}
          className={classNames(css.page, css.prev, { [css.disabled]: current == 0 })}
        >
          <Img alt="" src="/icons/paginator-arrow-left.svg" />
        </div>
      )}

      {current >= MAX_PAGES && (
        <>
          <div onClick={() => handlePageChange(0)} className={classNames(css.page)}>
            {'1'}
          </div>
          <div className={classNames(css.dots)}>{'...'}</div>
        </>
      )}

      {pagesToShow.map((elem, key) => {
        return (
          <div
            onClick={() => handlePageChange(elem)}
            key={key}
            className={classNames(css.page, { [css.isCurrent]: elem == current })}
          >
            {elem + 1}
          </div>
        )
      })}

      {total >= MAX_PAGES && current < total - MAX_PAGES && (
        <>
          <div className={classNames(css.dots)}>{'...'}</div>
          <div onClick={() => handlePageChange(total - 1)} className={classNames(css.page)}>
            {`${total}`}
          </div>
        </>
      )}

      {total >= MAX_PAGES && (
        <div
          onClick={() => current < total - 1 && handlePageChange(current + 1)}
          className={classNames(css.page, css.next, { [css.disabled]: current >= total - 1 })}
        >
          <Img alt="" src="/icons/paginator-arrow-right.svg" />
        </div>
      )}
    </div>
  )
}

export default Paginator
