import css from '../Social.module.scss'
import React from 'react'
import classNames from 'classnames'

import { ComponentColor } from 'meta/backendControlled/structure'

import Img from 'components/Media/Img'

interface IPropsButtonPhone {
  color: ComponentColor
  phone?: string
}

export const ButtonPhone: React.FC<IPropsButtonPhone> = (props) => {
  const { color, phone } = props

  if (!phone) return null

  return (
    <a href={`tel:${phone}`} className={classNames(css.socialLink, css[color])}>
      <Img alt="" src={`/social/phone_${color}.svg`} />
    </a>
  )
}
