const getSelector = (dataTestId: string): string => `[data-testid="${dataTestId}"]`

const DataTestId = {
  getSelector,
  checkout: {
    btnVoucherEdit: 'btn-voucher-edit',
    btnTicketAdd: 'checkout-ticket-add',
    continueBtn: 'checkout-continue-btn',
    creditCard: 'btn-credit-card',
    editLabel: 'label-edit',
    recapLabel: 'label-recap',
    selectCreditCard: 'label-select-credit-card',
    loginBtn: 'checkout-login-btn',
  },
  header: {
    btnLogin: 'btn-login',
    btnLogged: 'btn-logged',
  },
  event: {
    btnBuy: 'btn-buy',
  },
  form: {
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    btnSubmit: 'submit',
  },
  login: {
    container: {
      mobile: 'login-container-mobile',
      desktop: 'login-container-desktop',
    },
    btnEmail: 'btn-login-email',
    btnPhone: 'btn-login-phone',
    btnFacebook: 'btn-login-fb',
    btnApple: 'btn-login-apple',
    btnGoogle: 'btn-login-google',
    registrationComplete: 'registration-complete',
    termsAndConditionError: 'termsAndConditions-error',
    privacyPolicyError: 'privacyPolicy-error',
    marketingPolicyError: 'marketingPolicy-error',
    profilingPolicyError: 'profilingPolicy-error',
  },
  profile: {
    btnLogout: 'btn-logout',
    changeNameOldTicket: 'profile-change-name-oldticket',
    changeNameNewTicketData: 'profile-change-name-newticketdata',
    changeNameSuccess: 'profile-change-name-success',
    resaleHeader: 'profile-resale-header',
    resaleModal: 'profile-resale-modal',
    resaleSuccess: 'profile-resale-success',
    transactions: 'transactions',
    transactionEventName: 'transaction-event-name',
    transactionPrice: 'transaction-price',
    transactionTicket: 'transaction-ticket',
    transactionTicketAction: 'transaction-ticket-action',
    transactionTicketChangeName: 'transaction-ticket-change-name',
    transactionTicketHolderName: 'transaction-ticket-holder-name',
    transactionTicketHolderPhone: 'transaction-ticket-holder-phone',
    transactionTicketResale: 'transaction-ticket-resale',
    transactionRefundAction: 'transaction-refund-action',
    undoResaleHeader: 'profile-undo-resale-header',
    undoResaleModal: 'profile-undo-resale-modal',
    undoResaleSuccess: 'profile-undo-resale-success',
  },
  purchaseForm: {
    btnTicketsAssign: 'btn-purchase-form-ticket-assign',
    title: 'purchase-form-title',
    eventName: 'purchase-form-event-name',
  },
  refund: {
    title: 'refund-page-title',
    form: 'refund-page-form',
    success: 'refund-success-box',
    termsAndConditions: 'termsAndConditions',
  },
  success: {
    successPurchase: 'success-purchase',
  },
  support: {
    header: 'header-help-text',
    formTestButton: 'support-form-test-button',
    successMsg: 'support-form-success-msg',
  },
  translation: {
    langSelectButton: 'lang-select-btn',
  },
}

export default DataTestId
