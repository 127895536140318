import css from './Apps.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useUrlAppAndroid, useUrlAppIos } from 'hooks/useEnv'

const Apps: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 5, [Breakpoints.laptop]: 7 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 14, [Breakpoints.tablet]: 13, [Breakpoints.laptop]: 11 }}
      gridRowStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 2, [Breakpoints.laptop]: 2 }}
      className={css.footerApps}
    >
      <a title={'App store'} className={css.appleLink} href={useUrlAppIos()} target="_blank" rel="noreferrer">
        <Img alt="App store" className={css.apple} src="/apps/app_store.svg" />
      </a>

      <a title="Google play store" href={useUrlAppAndroid()} target="_blank" rel="noreferrer">
        <Img alt="Google play store" className={css.google} src="/apps/google_play_store.png" />
      </a>
    </GridColumn>
  )
}

export default Apps
