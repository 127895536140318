import css from './BlogPost.module.scss'
import React from 'react'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'

import { IBlogPost } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'
import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { GridColumn } from 'components/Layout/Grid'
import { useUrlCloudfront } from 'hooks/useEnv'

const BlogPost: React.FC<IPropsComponent<IBlogPost>> = (props) => {
  const { imageUrl, postUrl, title, excerpt } = props

  const urlCloudfront = useUrlCloudfront()
  const backgroundImage = imageUrl
    ? `url('${imageUrl}')`
    : `url('${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.png')`

  return (
    <GridColumn
      className={css.blogPostContainer}
      gridColumnSpan={{ [Breakpoints.laptop]: 3, [Breakpoints.tablet]: 4, [Breakpoints.mobile]: 8 }}
      style={{ backgroundImage }}
      onClick={() => {
        window.location.replace(postUrl)
      }}
    >
      <Link title={title} to={postUrl}>
        <div className={css.blogPost}>
          <div className={css.title}>{title}</div>
          <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
            <div className={css.excerpt}>{excerpt}</div>
          </MediaQuery>
        </div>
      </Link>
    </GridColumn>
  )
}

export default BlogPost
