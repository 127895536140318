import css from './EventInfo.module.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { ComponentColor, IEventInfo } from 'meta/backendControlled/structure'
import { CurrencySymbol } from 'meta/currency'
import { Breakpoints, BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { SearchParamUtils } from 'meta/pages/search'
import { useAppDispatch } from 'store'
import { EventActions } from 'store/pages/event'
import { convertAndFormat, getCodeFromSymbol } from 'helpers/currency/converter'
import { Dates } from 'utils/dates'
import { replaceAll } from 'utils/string'

import Captions, { AlignCaption } from 'components/BackendControlled/Component/common/Captions'
import Description from 'components/BackendControlled/Component/common/Description'
import { IPropsComponent } from 'components/BackendControlled/Component/index'
import { getComponentId } from 'components/BackendControlled/Component/utils'
import { Flexbox, Grid, GridColumn, GridContainer, GridType } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { ButtonFacebook, ButtonHyperlink } from 'components/Social'
import { useActiveCurrency } from 'hooks/useActiveCurrency'

const EventInfo: React.FC<IPropsComponent<IEventInfo>> = (props) => {
  const {
    attributes,
    datetime,
    description,
    endDatetime,
    extra,
    facebookLink,
    longDescription,
    openDatetime,
    startPrice,
    startPriceObj,
    tags,
    title,
    uuid,
    website,
  } = props
  const [readMore, setReadMore] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { activeCurrency } = useActiveCurrency()

  const eventCurrencyCode = getCodeFromSymbol(startPriceObj.symbol as CurrencySymbol)

  const convertedPrice = convertAndFormat(Number(startPriceObj.amount) / 100, eventCurrencyCode, activeCurrency)

  const formatDate = useCallback((date: Date) => Dates.format(new Date(date), t('dates:common:eventDate')), [t])
  const formatTime = useCallback((date: Date) => Dates.format(new Date(date), t('dates:common:eventTime')), [t])

  const prepareTag = (tag: string): string => {
    return replaceAll(tag.replace('#', ''), '_', '')
  }

  useEffect(() => {
    dispatch(EventActions.setEndedEvent({ isEventEnded: Dates.isBefore(new Date(endDatetime), new Date()) }))
  }, [dispatch, endDatetime])

  return (
    <GridContainer id={getComponentId(uuid)}>
      <Grid>
        <GridColumn gridColumnEnd={17}>
          <h2>{title}</h2>
        </GridColumn>
      </Grid>

      <Grid>
        <GridColumn
          gridColumnEnd={{ [Breakpoints.laptop]: 10, [Breakpoints.mobile]: 17 }}
          className={css.eventInfoContent}
        >
          <Description description={description} />

          {longDescription && (
            <div className={css.eventInfoFullDescription}>
              {readMore && <Description description={longDescription} />}

              <div
                onClick={() => {
                  setReadMore(!readMore)
                }}
                className={css.readMore}
              >
                <h5>
                  {readMore ? t('event:location_description_readless') : t('event:location_description_readmore')}
                </h5>
                <Img src={readMore ? '/icons/arrow_up.svg' : '/icons/arrow.svg'} />
              </div>
            </div>
          )}

          <div className={css.eventInfoDescriptionDetails}>
            <div>
              <h5>{t('event:info_open_date')}:</h5>
              &nbsp;
              <span className={css.capitalize}>{`${formatDate(new Date(openDatetime))} / ${formatTime(
                new Date(openDatetime)
              )}`}</span>
            </div>

            <div>
              <h5>{t('event:info_start_date')}:</h5>
              &nbsp;
              <span
                className={css.capitalize}
              >{`${formatDate(new Date(datetime))} / ${formatTime(new Date(datetime))}`}</span>
            </div>

            <div>
              <h5>{t('event:info_end_date')}:</h5>
              &nbsp;
              <span className={css.capitalize}>{`${formatDate(new Date(endDatetime))} / ${formatTime(
                new Date(endDatetime)
              )}`}</span>
            </div>

            {startPrice && (
              <div>
                <h5>{t('event:info_prices')}:</h5>
                &nbsp;
                <span>{`${t('event:info_prices_starting_from')} ${convertedPrice}`}</span>
              </div>
            )}

            {extra &&
              Object.keys(extra).map((elem, key) => {
                return (
                  <div key={key}>
                    <span>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                      {/* @ts-ignore*/}
                      <b>{elem}</b>: {extra[elem]}
                    </span>
                  </div>
                )
              })}
          </div>

          <Flexbox className={css.eventInfoDescriptionActions}>
            {website && (
              <GridColumn gridColumnSpan={{ [Breakpoints.mobile]: 3, [Breakpoints.tablet]: 1 }}>
                <ButtonHyperlink color={ComponentColor.black} link={website} />
              </GridColumn>
            )}

            {facebookLink && (
              <GridColumn gridColumnSpan={{ [Breakpoints.mobile]: 3, [Breakpoints.tablet]: 1 }}>
                <ButtonFacebook color={ComponentColor.black} facebookLink={facebookLink} />
              </GridColumn>
            )}
          </Flexbox>
        </GridColumn>

        <GridColumn
          gridColumnStart={{ [Breakpoints.laptop]: 11, [Breakpoints.mobile]: 1 }}
          gridColumnEnd={17}
          className={css.eventInfoContent}
        >
          {attributes && (
            <>
              <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
                <Captions data={attributes} itemsPerRow={4} align={AlignCaption.left} />
              </MediaQuery>

              <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
                <Captions data={attributes} itemsPerRow={3} align={AlignCaption.left} />
              </MediaQuery>
            </>
          )}
        </GridColumn>
      </Grid>

      {tags && tags.length > 0 && (
        <Grid type={GridType.flex} className={css.eventInfoTagsContainer}>
          {tags.map((elem, idx) => {
            const tag = prepareTag(elem)
            return (
              <div key={String(idx)} className={css.eventInfoTags}>
                <a href={SearchParamUtils.toUrl({ query: tag })}>
                  <strong>{`#${tag}`}</strong>
                </a>
              </div>
            )
          })}
        </Grid>
      )}
    </GridContainer>
  )
}

export default EventInfo
