import { findCities } from 'store/pages/search/actions/findCities'
import { findNext } from 'store/pages/search/actions/findNext'
import { init } from 'store/pages/search/actions/init'
import { SearchSlice } from 'store/pages/search/slice'

export const SearchActions = {
  ...SearchSlice.actions,
  findCities,
  findNext,
  init,
}
