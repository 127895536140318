import css from './Voice.module.scss'
import React, { AnchorHTMLAttributes } from 'react'

import { LinkTarget } from 'components/Actions/Action'
import Img from 'components/Media/Img'

import { MenuMobileVoiceProps } from './props'

type Props = MenuMobileVoiceProps & {
  href: AnchorHTMLAttributes<HTMLAnchorElement>['href']
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
}

export const MenuMobileLink: React.FC<Props> = (props) => {
  const { href, icon, label, target } = props

  return (
    <a className={css.menuVoice} href={href} target={target}>
      <div>
        <Img src={icon} />
        <h3>{label}</h3>
      </div>
    </a>
  )
}

MenuMobileLink.defaultProps = {
  target: LinkTarget.self,
}
