import { ITicketChangeName, ITicketResaleData } from 'meta/pages/profile'
import { SliceSelector } from 'store/types'

import { TransactionsState } from '../slice/types'

const ticketResaleData: SliceSelector<ITicketResaleData> = () => (state) => state.pages.profile.ticketResaleData
const ticketChangeNameData: SliceSelector<ITicketChangeName> = () => (state) => state.pages.profile.ticketChangeName
const transactionsData: SliceSelector<TransactionsState> = () => (state) => state.pages.profile.transactionsData

export const ProfileSelectors = {
  ticketResaleData,
  ticketChangeNameData,
  transactionsData,
}
