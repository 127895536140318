import React from 'react'

import { LanguageCode, Languages } from 'meta/app/translate'

import Head from 'components/common/Head'
import { useI18NContext } from 'components/HOC/WithTranslation'
import { useCurrentLanguage } from 'hooks/useCurrentLanguage'

type Props = {
  disableCookieBanner?: boolean
}

const iubendaIds: Record<string, string> = {
  it: '32493600',
  en: '82181311',
  es: '83325097',
}

const Iubenda: React.FC<Props> = (props) => {
  const { disableCookieBanner } = props

  const { namespacesLoaded } = useI18NContext()
  const language = useCurrentLanguage()

  if (disableCookieBanner || !namespacesLoaded) return null

  const iubendaLangId = iubendaIds[language] ?? iubendaIds[LanguageCode.en]

  return (
    <Head>
      {Languages[language] && (
        <script type="text/javascript">{`
          var _iub = _iub || [];
          _iub.csConfiguration = {"cookiePolicyInOtherWindow":true,"siteId":1619800,"whitelabel":false,"cookiePolicyId":${iubendaLangId},"lang":"${Languages[language].iso2}","cookiePolicyUrl":"/cookie-policy", "banner":{ "acceptButtonCaptionColor":"white","acceptButtonColor":"#ea0a2a","acceptButtonDisplay":true, "rejectButtonDisplay":true, "backgroundColor":"white","closeButtonRejects":true,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"position":"float-bottom-center","textColor":"black" }};
          `}</script>
      )}
      <script type="text/javascript" src="//cs.iubenda.com/sync/1619800.js"></script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" async></script>
    </Head>
  )
}

export default Iubenda
