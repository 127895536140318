const createNode = (props: { attributes: Record<string, string>; name: string; parentNode: ParentNode }): void => {
  const { attributes, name, parentNode } = props

  const element = document.createElement(name)
  Object.entries(attributes).forEach(([qualifiedName, value]) => {
    element.setAttribute(qualifiedName, value)
  })
  parentNode.append(element)
}

const getPropertyPxValue = (element: Element, property: string): number => {
  const propertyValue = window.getComputedStyle(element).getPropertyValue(property)
  return Number(propertyValue.split('px')[0])
}

const getCumulativeOffset = (element: HTMLElement) => {
  let top = 0
  let left = 0
  let elementCurrent = element
  do {
    top += elementCurrent.offsetTop || 0
    left += elementCurrent.offsetLeft || 0
    elementCurrent = elementCurrent.offsetParent as HTMLElement
  } while (elementCurrent)

  return {
    top: top,
    left: left,
  }
}

const removeNodes = (props: { parentNode: ParentNode; selectors: string }): void => {
  const { parentNode, selectors } = props

  const nodes = parentNode.querySelectorAll(selectors)
  nodes.forEach((node) => {
    node.remove()
  })
}

const scrollTo = (options: ScrollToOptions = { top: 0, left: 0, behavior: 'smooth' }) => {
  const { documentElement } = document
  if (documentElement.scrollTo) {
    documentElement.scrollTo(options)
  } else {
    // Non Chromium based Edge version
    documentElement.scrollIntoView(true)
  }
}

export const DOMs = {
  createNode,
  getPropertyPxValue,
  getCumulativeOffset,
  removeNodes,
  scrollTo,
}
