import css from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/TransactionItems.module.scss'
import React from 'react'

import { ITransaction } from 'meta/pages/profile'

import Desktop from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/Desktop'
import Mobile from 'components/Pages/ProfileTransactions/Transaction/TransactionItems/Mobile'

type Props = {
  data: ITransaction
}

const TransactionItems: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <>
      <div className={css.transactionItemsMobile}>
        <Mobile data={data} />
      </div>
      <div className={css.transactionItems}>
        <Desktop data={data} />
      </div>
    </>
  )
}

export default TransactionItems
