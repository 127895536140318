import React from 'react'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'

import { GridContainer } from 'components/Layout/Grid'
import { HeaderSkeletonDesktop } from 'components/Pages/ProfileTransactions/Transaction/Header/HeaderSkeleton/Desktop'
import { HeaderSkeletonMobile } from 'components/Pages/ProfileTransactions/Transaction/Header/HeaderSkeleton/Mobile'

export const HeaderSkeleton: React.FC = () => {
  return (
    <>
      <MediaQuery maxWidth={BreakpointsMaxWidth.laptop}>
        <GridContainer>
          <HeaderSkeletonMobile />
        </GridContainer>
      </MediaQuery>
      <MediaQuery minWidth={BreakpointsMaxWidth.laptop}>
        <HeaderSkeletonDesktop />
      </MediaQuery>
    </>
  )
}
