import {
  format as _format,
  FormatOptions,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isValid,
  Locale,
  max,
  min,
  parseISO,
} from 'date-fns'
import { ca, de, enUS as en, es, fr, it, pt } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'

import i18n from 'i18n'
import { LanguageCode } from 'meta/app/translate'

const formatWithGuessedTimezone = (
  date: Date | number | string,
  formatStr: string,
  options?: FormatOptions
): string => {
  if (!date || !formatStr || formatStr.trim() === '') return ''
  const guessedTimeZone = guessTimeZone()
  const zonedDate = utcToZonedTime(date, guessedTimeZone)
  const currentLanguage = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
  const currentLocale = locales[currentLanguage]
  return _format(zonedDate, formatStr, { locale: currentLocale, ...options })
}

const getDisabledDate = (dates: Array<Date>): Array<Date> => {
  const minDate = Dates.min(dates)
  const maxDate = Dates.max(dates)
  const range: Array<Date> = []
  for (let dt = new Date(minDate); dt <= maxDate; dt.setDate(dt.getDate() + 1)) {
    range.push(new Date(dt))
  }
  const availableDatesTimesAtMidnight: Array<number> = dates.map((d) => {
    const dMidnight = new Date(d)
    return dMidnight.setUTCHours(0, 0, 0, 0).valueOf()
  })
  const disabledDates = range.reduce<Array<Date>>((datesAgg, date) => {
    const dMidnight = new Date(date)
    const midnight = dMidnight.setUTCHours(0, 0, 0, 0).valueOf()
    if (!availableDatesTimesAtMidnight.includes(midnight)) {
      datesAgg.push(dMidnight)
    }
    return datesAgg
  }, [])
  return disabledDates
}

const guessTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

const isSameOrBefore = (date: Date | number | string, dateToCompare: Date | number | string) => {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare)
}

const locales: Record<LanguageCode, Locale> = {
  [LanguageCode.ca]: ca,
  [LanguageCode.de]: de,
  [LanguageCode.en]: en,
  [LanguageCode.es]: es,
  [LanguageCode.fr]: fr,
  [LanguageCode.it]: it,
  [LanguageCode.pt]: pt,
}

export const Dates = {
  format: formatWithGuessedTimezone,
  getDisabledDate,
  guessTimeZone,
  isAfter,
  isBefore,
  isSameDay,
  isSameOrBefore,
  isValid,
  locales,
  max,
  min,
  parseISO,
}
