import css from './CCRecapItem.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { ICCBrand, ICheckoutCC } from 'meta/pages/checkout'

import Img from 'components/Media/Img'

interface IProps {
  data?: ICheckoutCC
  selectCallback?(): void
}

const CCRecapItem: React.FC<IProps> = (props) => {
  const { data, selectCallback } = props

  const { t } = useTranslation()

  const getBrand = () => {
    if (!data) return null

    return (
      <div className={`${css.brand} ${css[data.brand]}`}>
        {data.brand === ICCBrand.visa && <Img src="/payment_methods/footer/visa.svg" />}
        {data.brand === ICCBrand.mastercard && <Img src="/payment_methods/footer/mastercard.svg" />}
        {data.brand !== ICCBrand.visa && data.brand !== ICCBrand.mastercard && data.brand}
      </div>
    )
  }

  const getDescription = () => {
    return <h4 className={css.description}>{data?.description}</h4>
  }

  const getExpiry = () => {
    return (
      <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
        <div className={css.expiry}>
          <div className={css.label}>{t('checkout:cc_expiry')}</div>
          <div className={css.text}>{data?.expiry}</div>
        </div>
      </MediaQuery>
    )
  }

  return (
    <div className={css.ccrecapItem_container} onClick={selectCallback}>
      {getDescription()}
      {getExpiry()}
      {getBrand()}
    </div>
  )
}

export default CCRecapItem
