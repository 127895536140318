import cookie from 'js-cookie'

import { ILogoutData } from 'meta/auth'
import { ApiEndpoint } from 'controller/endpoint'
import { HttpRequest } from 'utils/http'

export const logoutUser = async (): Promise<ILogoutData> => {
  const { data } = await HttpRequest.post<ILogoutData>(ApiEndpoint.users.logout(), {})

  cookie.remove('token')
  window.localStorage.setItem('logout', String(Date.now()))

  return data
}
