import css from './LogoV2.module.scss'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Routes } from 'meta/routes'
import { TICKETSMS_COLORS_LIGHTGREY, TICKETSMS_COLORS_WHITE } from 'utils/constants/colors'

import Img from 'components/Media/Img'
import { useIsAppInitialized } from 'hooks/useApp'

interface IPropsLogo {
  linkToHome?: boolean
  noTextMobile?: boolean
}

const Logo: React.FC<IPropsLogo> = (props) => {
  const { linkToHome, noTextMobile } = props

  const appInitialized = useIsAppInitialized()

  if (!appInitialized) {
    return (
      <div className={classNames(css.logo, { [css.noTextMobile]: noTextMobile })}>
        <Skeleton
          className={css.logoSkeleton}
          baseColor={TICKETSMS_COLORS_LIGHTGREY}
          borderRadius={8}
          highlightColor={TICKETSMS_COLORS_WHITE}
          inline
        />
      </div>
    )
  }

  const element = (
    <div className={classNames(css.logo, { [css.noTextMobile]: noTextMobile })}>
      <Img alt="TicketSMS Logo" className={css.logo__img} src="/logo/TicketSms_logo.svg" />
      <Img alt="TicketSMS Logo" className={css.logo__imgNoText} src="/logo/TicketSms_logo_noText.svg" />
    </div>
  )

  if (linkToHome) {
    return (
      <Link title={'TicketSMS'} to={Routes.Home.generatePath()}>
        {element}
      </Link>
    )
  }

  return element
}

Logo.defaultProps = {
  linkToHome: true,
  noTextMobile: true,
}

export default Logo
