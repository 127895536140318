import css from './UsersPasswordUpdate.module.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import { QueryParams } from 'meta/routes'

import WithAppInitialized from 'components/common/WithAppInitialized'
import withTranslation, { useI18NContext } from 'components/HOC/WithTranslation'
import ChangePasswordForm from 'components/Pages/ProfileChangePassword/ChangePasswordForm'
import RoundedBox from 'components/TextBoxes/RoundedBox'
import { useQueryParams } from 'hooks/useQueryParams'

const UsersPasswordUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { namespacesLoaded } = useI18NContext()
  const { email, token } = useQueryParams<QueryParams['UsersPasswordUpdate']>()
  const [success, setSuccess] = useState<boolean>(false)

  return (
    <WithAppInitialized showChildren={namespacesLoaded}>
      {success ? (
        <RoundedBox bgColor={ComponentColor.black} infoType="success">
          {t('password:password_update_successfully_msg')}
        </RoundedBox>
      ) : (
        <div className={css.passwordUpdate}>
          <ChangePasswordForm authToken={token} email={email} fromProfile={false} onSuccess={() => setSuccess(true)} />
        </div>
      )}
    </WithAppInitialized>
  )
}

const namespaces = ['profile']
export default withTranslation(namespaces)(UsersPasswordUpdate)
