import css from './TitleBar.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'

interface IProps {
  title: string
}

const TitleBar: React.FC<IProps> = (props) => {
  const { title } = props

  return (
    <GridContainer>
      <Grid>
        <GridColumn gridColumnStart={{ [Breakpoints.laptop]: 2, [Breakpoints.mobile]: 1 }} gridColumnEnd={17}>
          <div className={css.checkout_titlebar_container}>
            <h2 className={css.title}>{title}</h2>
          </div>
        </GridColumn>
      </Grid>
    </GridContainer>
  )
}

export default TitleBar
