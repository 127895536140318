import {
  CheckoutStep,
  ICheckoutAuth,
  ICheckoutCart,
  ICheckoutCartTicket,
  ICheckoutDetails,
  ICheckoutExtraDetailsData,
  ICheckoutPayment,
  ICheckoutProcess,
  ICheckoutRecap,
  ICheckoutRecapExtra,
  ICheckoutStripeData,
  ITicketSeat,
  ITicketSelection,
  ITicketSelectionManual,
  TicketSelectionType,
} from 'meta/pages/checkout'
import { CollectionEvent } from 'meta/pages/collection'
import { useAppSelector } from 'store'

export const useEventCodeUrl = (): string | undefined => useAppSelector((state) => state.pages.checkout.eventCodeUrl)

export const useCheckoutAuth = (): ICheckoutAuth | undefined => useAppSelector((state) => state.pages.checkout.auth)

export const useCheckoutDetails = (): ICheckoutDetails | undefined =>
  useAppSelector((state) => state.pages.checkout.details)

export const useCheckoutRecap = (): ICheckoutRecap | undefined => useAppSelector((state) => state.pages.checkout.recap)

export const useCheckoutRecapExtra = (): ICheckoutRecapExtra | undefined =>
  useAppSelector((state) => state.pages.checkout.recap?.extra)

export const useCheckoutStep = (): CheckoutStep | undefined => useAppSelector((state) => state.pages.checkout.step)

export const useCheckoutCart = (): ICheckoutCart => useAppSelector((state) => state.pages.checkout.cart)

export const useCheckoutCartTickets = (): Array<ICheckoutCartTicket> | undefined =>
  useAppSelector((state) => state.pages.checkout.cart?.tickets)

export const useCheckoutCartLastTypeTicket = (): number | undefined =>
  useAppSelector((state) => state.pages.checkout.cart?.lastTypeTicket)

export const useCheckoutProcess = (): ICheckoutProcess | undefined =>
  useAppSelector((state) => state.pages.checkout.process)

export const useCheckoutExtraDetails = (): ICheckoutExtraDetailsData | undefined =>
  useAppSelector((state) => state.pages.checkout.extraDetails)

export const useCheckoutCartTicket = (typeTicketId: number): ICheckoutCartTicket | undefined =>
  useAppSelector((state) => state.pages.checkout?.cart?.tickets.find((item) => item.typeTicketId === typeTicketId))

export const useCheckoutPayment = (): ICheckoutPayment | undefined =>
  useAppSelector((state) => state.pages.checkout.payment)

export const useCheckoutStripe = (): ICheckoutStripeData | undefined =>
  useAppSelector((state) => state.pages.checkout.stripe)

export const useCheckoutTicketSelection = (): ITicketSelection<TicketSelectionType> | undefined =>
  useAppSelector((state) => state.pages.checkout.ticketSelection)

export const useCheckoutTicketSeats = (): Array<ITicketSeat> =>
  useAppSelector((state) => {
    const ticketSelection = state.pages.checkout.ticketSelection as ITicketSelectionManual
    return ticketSelection?.ticketSeats || []
  })

export const useCheckoutTicketSeat = (idx: number): ITicketSeat => useCheckoutTicketSeats()[idx]

export const useCheckoutTypeTicketCollectionId = (): string | undefined =>
  useAppSelector((state) => state.pages.checkout.eventTypeTicketCollectionId)

export const useCheckoutEventCodeUrl = (): string | undefined =>
  useAppSelector((state) => state.pages.checkout.eventCodeUrl)

export const useCheckoutSeasonEvents = (): CollectionEvent[] | undefined =>
  useAppSelector((state) => state.pages.checkout.season?.events)
