import css from './BannerCover.module.scss'
import React from 'react'

import { BannerProps } from 'components/BackendControlled/Banner/props'
import WithAppInitialized from 'components/common/WithAppInitialized'
import { useUrlCloudfront } from 'hooks/useEnv'

const BannerCover: React.FC<BannerProps> = (props) => {
  const { banner } = props

  const urlCloudfront = useUrlCloudfront()

  return (
    <WithAppInitialized>
      <div
        className={css.banner_cover}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(242,242,242,0.6) 70%, rgba(242,242,242,1) 100%), url("${urlCloudfront}/images${banner.imageUrl}")`,
        }}
      />
      <div
        className={css.banner_cover_mobile}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(242,242,242,0.6) 70%, rgba(242,242,242,1) 100%), url("${urlCloudfront}/images${banner.imageMobileUrl}")`,
        }}
      />
    </WithAppInitialized>
  )
}

export default BannerCover
