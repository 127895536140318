import css from './ModalShare.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import Modal from 'components/Layout/Modal'
import { useFacebookAppId } from 'hooks/useEnv'

interface IPropsModalShare {
  name: string
  onClose(): void
  shareLocation?: boolean
}

const ModalShare: React.FC<IPropsModalShare> = (props) => {
  const { name, onClose, shareLocation } = props

  const { t } = useTranslation()
  const facebookAppId = useFacebookAppId()
  const shareUrl = shareLocation ? `${window.location.host}/location/${name}` : window.location.href
  const iconSize = 36

  return (
    <Modal handleClose={onClose} show={true}>
      <BalloonWrapper color={ComponentColor.white} size={BalloonSize.small} tipPosition={BalloonTipPosition.right}>
        <div className={css.modalShare}>
          <h4 className={css.modalShare__title}>{t('event:share')}</h4>

          <WhatsappShareButton url={shareUrl} title={name} separator=" - " className="social_share-button">
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>

          <FacebookShareButton url={shareUrl} quote={name} className="social_share-button">
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>

          <FacebookMessengerShareButton url={shareUrl} appId={facebookAppId} className="social_share-button">
            <FacebookMessengerIcon size={iconSize} round />
          </FacebookMessengerShareButton>

          <TwitterShareButton url={shareUrl} title={name} className="social_share-button">
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>

          <TelegramShareButton url={shareUrl} title={name} className="social_share-button">
            <TelegramIcon size={iconSize} round />
          </TelegramShareButton>

          <EmailShareButton url={shareUrl} subject={name} body="Guarda qui: " className="social_share-button">
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </div>
      </BalloonWrapper>
    </Modal>
  )
}

export default ModalShare
