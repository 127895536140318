import { ComponentType, IParentSquare } from '../../structure'
import { IEventParentSquareBackend } from '../component/event'
import ComponentAdapter from './componentAdapter'

export default class ParentSquareAdapter extends ComponentAdapter<IParentSquare, IEventParentSquareBackend> {
  convert(): IParentSquare {
    const { codeUrl, fromDateTime_tz, toDateTime_tz, eventAsset, locations, name } = this.componentBackend

    const imageUrl =
      eventAsset && eventAsset.covers && eventAsset.covers.length > 0 ? eventAsset.covers[0].src[0].url : null

    return {
      componentType: ComponentType.parentSquare,
      codeUrl,
      fromDate: fromDateTime_tz,
      toDate: toDateTime_tz,
      // @ts-expect-error something might not be defined
      imageUrl,
      name,
      city: locations[0].city,
      location: locations[0].name,
      common: locations[0].common,
    }
  }
}
