import React from 'react'

import { paths } from 'components/Auth/Profile/Menu/paths'
import { VoicesSkeleton } from 'components/PageWithSubsections/Voices'

export const MenuSkeleton: React.FC = () => {
  return (
    <div>
      <VoicesSkeleton voices={paths} />
    </div>
  )
}
