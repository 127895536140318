import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

export const useButtonBuySticky = (buttonBuyId: string): boolean => {
  const [sticky, setSticky] = useState<boolean>(false)

  useEffect(() => {
    const button = document.getElementById(buttonBuyId)

    const onScroll = throttle(() => {
      if (button) {
        const { height, top } = button.getBoundingClientRect()
        setSticky(top <= -height)
      }
    })

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return sticky
}
