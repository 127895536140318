export enum DataDogLogType {
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export const LOGS_INSIDE_ERROR_PAGE = 'Log inside /error page'
export const LOGS_TRANSACTION_START = 'Transaction has started'
export const LOGS_STRIPE_SUCCESS_BEFORE_REDIRECT = 'Stripe payment has succeded... redirect to /checkout/process'
export const LOGS_STRIPE_ERROR_CONFIRM = 'Error on Stripe payment confirm'
export const LOGS_STRIPE_ERROR_NO_CLIENT_SECRET = 'Error on Stripe payment (no client secret)'
export const LOGS_CHECKOUT_PROCESS_START = 'Landed on /checkout/process'
export const LOGS_CHECKOUT_PROCESS_ERROR = 'Error inside /checkout/process (fetch)'
export const LOGS_CHECKOUT_PROCESS_SUCCESS = '/checkout/process completed. Redirecting to /purchase/form'
export const LOGS_CHECKOUT_CANCEL = 'Landed on /checkout/cancel'
export const LOGS_PURCHASE_FORM_LANDING = 'Landed on /purchase/form'
export const LOGS_EVENT_CALENDAR_ADD = 'Added event to calendar'
