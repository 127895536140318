import css from './SearchInfo.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BalloonSize, BalloonTipPosition, ComponentColor } from 'meta/backendControlled/structure'
import { Breakpoints } from 'meta/layout/breakpoints'

import BalloonWrapper from 'components/Layout/BalloonWrapper'
import { Grid, GridColumn, GridContainer } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

const SearchInfo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <GridContainer className={css.searchInfo}>
      <Img src="/search/balloon_background.png" className={css.searchInfo__imgBackground} />
      <Img src="/search/e_ticket_mobile.png" className={css.searchInfo__imgETicketMobile} />
      <Img src="/search/e_ticket.png" className={css.searchInfo__imgETicket} />

      <BalloonWrapper
        className={css.searchInfo__balloon}
        color={ComponentColor.white}
        tipPosition={BalloonTipPosition.right}
        size={BalloonSize.big}
      >
        <Grid className={css.searchInfo__content}>
          <GridColumn
            gridColumnStart={{ [Breakpoints.mobile]: 6, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 10 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 12, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
          >
            <h1>{t('search:power_of_e_ticket')}</h1>
          </GridColumn>

          <GridColumn
            className={css.searchInfo__contentSection}
            gridColumnStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 2, [Breakpoints.laptop]: 2 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 14, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 6 }}
          >
            <Img src="/search/digital.svg" />
            <h3 className={css.title}>{t('search:digital')}</h3>
            <div>{t('search:digital_desc')}</div>
          </GridColumn>

          <GridColumn
            className={css.searchInfo__contentSection}
            gridColumnStart={{ [Breakpoints.mobile]: 4, [Breakpoints.tablet]: 7, [Breakpoints.laptop]: 7 }}
            gridColumnEnd={{ [Breakpoints.mobile]: 14, [Breakpoints.tablet]: 11, [Breakpoints.laptop]: 11 }}
          >
            <Img src="/search/paperless.svg" />
            <h3 className={css.title}>{t('search:paperless')}</h3>
            <div>{t('search:paperless_desc')}</div>
          </GridColumn>

          <GridColumn
            className={css.searchInfo__contentSection}
            gridColumnStart={{
              [Breakpoints.mobile]: 4,
              [Breakpoints.tablet]: 12,
              [Breakpoints.laptop]: 12,
            }}
            gridColumnEnd={{ [Breakpoints.mobile]: 14, [Breakpoints.tablet]: 16, [Breakpoints.laptop]: 16 }}
          >
            <Img src="/search/contactless.svg" />
            <h3 className={css.title}>{t('search:contactless')}</h3>
            <div>{t('search:contactless_desc')}</div>
          </GridColumn>
        </Grid>
      </BalloonWrapper>
    </GridContainer>
  )
}

export default SearchInfo
