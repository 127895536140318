import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import { IFormFieldSelect, IFormFieldSelectOption } from 'meta/form'

import { IFormFieldProps } from '../props'

const Select: React.FC<IFormFieldProps> = (props) => {
  const { formField } = props
  const { name, options, placeholder, translateLabels } = formField as IFormFieldSelect

  const { t } = useTranslation()

  return (
    <>
      <Field as="select" data-testid={name} id={name} name={name} placeholder={t(`${placeholder}`)}>
        {placeholder && (
          <option value={placeholder} hidden>
            {translateLabels ? String(t(`${placeholder}`)) : placeholder}
          </option>
        )}

        {options.map((option: IFormFieldSelectOption, idx: number) => (
          <option key={String(idx)} value={option.value}>
            {translateLabels ? String(t(option.label)) : option.label}
          </option>
        ))}
      </Field>
    </>
  )
}

export default Select
