import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { IEventDetails } from 'meta/backendControlled/structure'
import { ComponentsAdapter } from 'meta/backendControlled/structureBackend'
import { IPurchaseForm } from 'meta/pages/purchase'
import { ITicket, TicketAdapter } from 'meta/pages/tickets'
import { Routes } from 'meta/routes'
import { ApiEndpoint } from 'controller/endpoint'
import { useAppDispatch } from 'store'
import { LoginActions } from 'store/pages/login'
import { HttpRequest } from 'utils/http'

import { useIsAppInitialized } from 'hooks/useApp'

type Props = {
  authToken: string | undefined
  setPurchaseFormData: Dispatch<SetStateAction<IPurchaseForm | undefined>>
  setResponseReceived: Dispatch<SetStateAction<boolean>>
  setWithAuth: Dispatch<SetStateAction<boolean>>
  setEvent: Dispatch<SetStateAction<IEventDetails | undefined>>
  setDetails: Dispatch<SetStateAction<IEventDetails | undefined>>
  setTickets: Dispatch<SetStateAction<ITicket[] | undefined>>
  transactionCode: string | null
  transactionToken: string | null
}
export const useInitPurchaseForm = (props: Props) => {
  const {
    authToken,
    setPurchaseFormData,
    setResponseReceived,
    setEvent,
    setTickets,
    setWithAuth,
    transactionCode,
    transactionToken,
  } = props

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appInitialized = useIsAppInitialized()

  useEffect(() => {
    if (!appInitialized) return
    ;(async () => {
      setResponseReceived(false)

      try {
        const { data } = await HttpRequest.get<IPurchaseForm>(
          `${ApiEndpoint.purchase.form()}?transactionCode=${transactionCode}&transactionToken=${transactionToken}`
        )

        setPurchaseFormData(data)
        const tickets = TicketAdapter.convertResponse(data.items)
        setTickets(tickets)
        const eventDetails = new ComponentsAdapter().convertComponent(data.event) as IEventDetails
        eventDetails.eventCollectionName = data.details?.eventCollectionName
        eventDetails.numEvents = data.details?.numEvents
        setEvent(eventDetails)
        setResponseReceived(true)
        //@ts-expect-error error type is unknown
      } catch (e: object) {
        const status = e.axiosError?.response?.status
        if (status === 401 && !authToken) {
          setWithAuth(true)
          setResponseReceived(true)
          dispatch(LoginActions.show())
          return
        }

        let redirectPath
        if ([403, 412].includes(status)) {
          redirectPath = Routes.ProfileTransactions.generatePath()
        }
        if ([400, 401, 404, 500].includes(status)) {
          redirectPath = Routes.Error.generatePath(undefined, { error: String(e.message), errorCode: status })
        }
        if (redirectPath) {
          navigate(redirectPath, { replace: true })
        }
      }
    })()
  }, [
    appInitialized,
    authToken,
    dispatch,
    navigate,
    setEvent,
    setPurchaseFormData,
    setResponseReceived,
    setTickets,
    setWithAuth,
    transactionCode,
    transactionToken,
  ])
}
