import css from './FormSubmissionConfirm.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ComponentColor } from 'meta/backendControlled/structure'
import DataTestId from 'meta/dataTestId'

import RoundedBox from 'components/TextBoxes/RoundedBox'

interface IPropsFormSubmissionConfirm {
  messageKey: string
}

const FormSubmissionConfirm: React.FC<IPropsFormSubmissionConfirm> = (props) => {
  const { messageKey } = props

  const { t } = useTranslation()

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className={css.confirmBox} data-testid={DataTestId.refund.success}>
      <RoundedBox bgColor={ComponentColor.grey} infoType="success">
        <div data-testid={DataTestId.support.successMsg} className={css.successMsg}>
          {t(messageKey)}
        </div>
      </RoundedBox>
      <a href="/">{t('form:goToHome')}</a>
    </div>
  )
}

export default FormSubmissionConfirm
